import {
  Box,
  Button,
  FormLabel,
  HStack,
  Link,
  Spinner,
  Stack,
  Switch,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiShow } from "react-icons/bi";
import { MdOutlineAdd } from "react-icons/md";
import { FormattedDate, FormattedTime } from "react-intl";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useCreateKbPage,
  useFetchKbPages,
  useUpdateKbPage,
} from "../../../api/knowledgeBase/kbPage";
import TipTapEditor from "../../../components/common/Editor/TipTapEditor";
import { InputField } from "../../../components/fields/InputField";
import { SelectField } from "../../../components/fields/SelectField";
import { SwitchField } from "../../../components/fields/SwitchField";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import KbTag from "../KbTag";
import { KbChildPagesList } from "../ViewKbPage/KbChildPagesList";

const EditKbPageForm = ({
  pageData,
  setShowViewersModal,
  setShowEditorsModal,
}) => {
  const { pageId } = useParams();
  const { accountId } = useAccountId();
  const updateKbPageMutation = useUpdateKbPage();
  const createKbPageMutation = useCreateKbPage();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const {
    data: allPagesData,
    isLoading: isLoadingAllKbPages,
    // isError: isErrorFetchingAllKbPages,
    // error: errorFetchingAllKbPages,
  } = useFetchKbPages(accountId || "");
  const navigate = useCustomDomainNavigate();
  const [isSavingKbPage, setIsSavingKbPage] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const focusOnTitle = searchParams.get("focusOnTitle");
  const titleInputRef = useRef(null);

  // Focus on title input if focusOnTitle query param is true
  useEffect(() => {
    if (focusOnTitle === "true" && titleInputRef.current) {
      titleInputRef.current.select();

      // Remove focusOnTitle query param
      searchParams.delete("focusOnTitle");
      setSearchParams(searchParams);
    }
  }, [focusOnTitle, history, searchParams]);

  function flattenPages(pages) {
    let result = [];

    function traversePages(pages) {
      for (let i = 0; i < pages.length; i++) {
        let page = pages[i];

        // Create a shallow copy of the current page without the 'children' property
        let pageWithoutChildren = { ...page };
        delete pageWithoutChildren.children;
        result.push(pageWithoutChildren);

        if (page.children && page.children.length > 0) {
          traversePages(page.children);
        }
      }
    }

    traversePages(pages);
    return result;
  }

  const allPagesFlat = flattenPages(allPagesData || []);

  const defaultValues = {
    title: pageData.title,
    content: pageData.content,
    isPublished: pageData.isPublished,
    parentId: pageData.parentId,
  };

  const form = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = form;

  const currentTitle = watch("title");

  const updateKbPage = async (data) => {
    try {
      await updateKbPageMutation.mutateAsync({
        accountId,
        data,
        pageId,
      });
      queryClient.invalidateQueries(["fetchKbPage", pageData.id]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating page" });
    }
  };

  const createChildKbPage = async () => {
    try {
      const pageResp = await createKbPageMutation.mutateAsync({
        accountId,
        data: {
          parentId: pageData.id,
          viewers: pageData.viewers.map((viewer) => viewer.id),
          editors: pageData.editors.map((editor) => editor.id),
        },
      });
      navigate(`/${accountId}/knowledge-base/${pageResp.id}/edit`);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating page" });
    }
  };

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  // Only submit if there are no errors
  const submitForm = (event) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateKbPage)(event);
    }
  };

  useEffect(() => {
    setIsSavingKbPage(updateKbPageMutation.isLoading);
  }, [updateKbPageMutation.isLoading]);

  const getEditorNames = () => {
    const editorNames = pageData.editors.map((editor) => editor.name);
    if (editorNames.length <= 3) {
      return editorNames.join(", ");
    }
    const othersCount = editorNames.length - 3;
    return `${editorNames.slice(0, 3).join(", ")} and ${othersCount} other${
      othersCount > 1 ? "s" : ""
    }`;
  };

  const getViewerNames = () => {
    const viewerNames = pageData.viewers.map((viewer) => viewer.name);
    if (viewerNames.length <= 3) {
      return viewerNames.join(", ");
    }
    const othersCount = viewerNames.length - 3;
    return `${viewerNames.slice(0, 3).join(", ")} and ${othersCount} other${
      othersCount > 1 ? "s" : ""
    }`;
  };

  const renderEditorText = () => {
    const { editors } = pageData;
    if (editors.length === 0) {
      return "Admins only";
    }
    if (editors.length === 1) {
      return "Admins and 1 person";
    }
    return `Admins and ${editors.length} other people`;
  };

  const renderViewerText = () => {
    const { viewers } = pageData;
    if (viewers.length === 0) {
      return "Everyone";
    }
    if (viewers.length === 1) {
      return "1 person";
    }
    return `${viewers.length} people`;
  };

  return (
    <Box>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(updateKbPage)} onChange={debouncedSubmit}>
          {/* Title */}
          <Box w={{ base: "100%", md: "70%" }}>
            <InputField
              ref={titleInputRef}
              field={{
                id: "title",
                label: "Title",
                validation: {
                  required: true,
                },
              }}
            />
          </Box>
          <Stack
            py={4}
            direction={{ base: "column", md: "row" }}
            align={{ base: "flex-start", md: "center" }}
          >
            <HStack>
              {/* Published tag */}
              <Box w={"100px"}>
                <KbTag pageData={pageData} />
              </Box>
              {/* Switch */}
              <Box w={"180px"}>
                <SwitchField
                  field={{
                    id: "isPublished",
                  }}
                  leftLabel={"Draft"}
                  rightLabel={"Published"}
                />
              </Box>
            </HStack>
            {/* Last saved */}
            <HStack w={"400px"}>
              <Text fontSize={"sm"} whiteSpace={"nowrap"}>
                Last saved {` `}
                <FormattedDate
                  value={pageData.updatedAt}
                  month="short"
                  day="2-digit"
                />
                {` at `}
                <FormattedTime value={pageData.updatedAt} />{" "}
              </Text>
              <Box w="20px">{isSavingKbPage && <Spinner size={"sm"} />}</Box>
              <Box w={"150px"} pl={4}>
                <Link
                  textDecoration={"none"}
                  onClick={() => {
                    navigate(
                      `/${accountId}/knowledge-base/${pageData.id}/view`
                    );
                  }}
                >
                  <HStack spacing={2} align="center">
                    {/* <EditIcon stroke={"green"} fill={"transparent"} /> */}
                    <BiShow size={18} />
                    <Text color={"primary"}>View Page</Text>
                  </HStack>
                </Link>
              </Box>
            </HStack>
            {/* View Page */}
          </Stack>

          <Stack direction={{ base: "column", md: "row" }}>
            <Box w={{ base: "100%", md: "70%" }} order={{ base: 1, md: 0 }}>
              {/* Editor */}
              <Box w={"100%"}>
                <TipTapEditor
                  defaultValue={pageData.content}
                  onChange={(htmlContent) => {
                    setValue("content", htmlContent);
                    debouncedSubmit();
                  }}
                  minHeight={600}
                  aiContext={{
                    context: `We are writing a knowlede base article titled '${currentTitle}'.`,
                  }}
                />
              </Box>
            </Box>
            {/* Details */}
            <Box
              px={{ base: 0, md: 4 }}
              pb={4}
              w={{ base: "100%", md: "30%" }}
              order={{ base: 0, md: 1 }}
            >
              <VStack alignItems={"flex-start"} spacing={6}>
                <HStack>
                  <VStack alignItems={"flex-start"}>
                    <SelectField
                      field={{
                        id: `parentId`,
                        label: "Parent Page",
                        placeholder: "Parent Page",
                      }}
                      options={allPagesFlat
                        ?.filter((page) => page.id !== pageId)
                        .map((page) => ({
                          value: page.id,
                          label: page.title,
                        }))}
                      isLoading={isLoadingAllKbPages}
                      isClearable={true}
                      onSelectionChange={debouncedSubmit}
                    />
                  </VStack>
                </HStack>
                <Box>
                  <FormLabel>Viewers</FormLabel>
                  <VStack alignItems={"flex-start"}>
                    <HStack>
                      <Switch
                        defaultChecked={pageData.everyoneCanView}
                        onChange={(e) => {
                          setValue("everyoneCanView", e.target.checked);
                        }}
                      />
                      <Text
                        color={!pageData.everyoneCanView && "gray.400"}
                        fontSize={"sm"}
                      >
                        Everyone can view
                      </Text>
                    </HStack>
                    {!pageData.everyoneCanView && (
                      <HStack>
                        <Link
                          fontSize={"sm"}
                          onClick={() => setShowViewersModal(true)}
                          fontWeight={"semibold"}
                        >
                          Edit
                        </Link>
                        <Tooltip
                          label={getViewerNames()}
                          placement="top"
                          isDisabled={pageData.editors.length === 0}
                        >
                          <Text fontSize={"sm"}>{renderViewerText()}</Text>
                        </Tooltip>
                      </HStack>
                    )}
                  </VStack>
                </Box>
                <HStack>
                  <VStack alignItems={"flex-start"}>
                    <FormLabel m={0}>Editors</FormLabel>
                    <HStack>
                      <Link
                        fontSize={"sm"}
                        onClick={() => setShowEditorsModal(true)}
                        fontWeight={"semibold"}
                      >
                        Edit
                      </Link>
                      <Tooltip
                        label={getEditorNames()}
                        placement="top"
                        isDisabled={pageData.editors.length === 0}
                      >
                        <Text fontSize={"sm"}>{renderEditorText()}</Text>
                      </Tooltip>
                    </HStack>
                  </VStack>
                </HStack>
                <HStack w={"100%"}>
                  <VStack alignItems={"flex-start"} w={"100%"}>
                    <Stack
                      direction={{
                        base: "column",
                        xl: "row",
                      }}
                      justifyContent={"space-between"}
                      w={"100%"}
                    >
                      <FormLabel m={0}>Child Pages</FormLabel>
                      <Button
                        size={"sm"}
                        variant={"outline"}
                        leftIcon={<MdOutlineAdd />}
                        isLoading={createKbPageMutation.isLoading}
                        onClick={() => createChildKbPage()}
                        bg={"white"}
                        maxW={"200px"}
                      >
                        Create Child Page
                      </Button>
                    </Stack>
                    {/* {pageData.children.length && (
                      <Box w={"100%"} minH={"100px"}>
                        <KbPages pages={pageData.children || []} />
                      </Box>
                    )} */}
                    <KbChildPagesList childPages={pageData.children} />
                  </VStack>
                </HStack>
              </VStack>
            </Box>
          </Stack>
        </form>
      </FormProvider>
    </Box>
  );
};

export default EditKbPageForm;
