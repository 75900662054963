import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { NumberField } from "../../../components/fields/NumberField";
import { CheckboxGroupField } from "../../../components/fields/CheckBoxGroupField";
import { useEffect, useState } from "react";
import { SelectField } from "../../../components/fields/SelectField";

export const intToWeekday = {
  0: "SU",
  1: "MO",
  2: "TU",
  3: "WE",
  4: "TH",
  5: "FR",
  6: "SA",
};

export const RecurrenceFields = ({ isUpdating }) => {
  const form = useFormContext();
  const [intervalToShow, setIntervalToShow] = useState("");
  const {
    watch,
    trigger,
    setValue,
    formState: { isDirty },
  } = form;

  const watchFrequency = watch("frequency");
  const watchStartDate = watch("startDate");

  useEffect(() => {
    if (watchFrequency === "WEEKLY") {
      setIntervalToShow("week");
      setValue("interval", 1);
      setDayOfWeek();
      trigger();
    } else if (watchFrequency === "MONTHLY") {
      setDayOfWeek();
      setValue("interval", 1);
      setValue("bySetPos", 1);
      setIntervalToShow("month");
    } else {
      setIntervalToShow("");
    }
  }, [watchFrequency]);

  const setDayOfWeek = () => {
    if (watchStartDate && isDirty) {
      const date = new Date(watchStartDate);
      const dayOfWeek = date.getDay();
      const dayOfWeekStr = intToWeekday[dayOfWeek];
      if (watchFrequency === "WEEKLY") {
        form.setValue("byWeekDay", [dayOfWeekStr]);
      } else if (watchFrequency === "MONTHLY") {
        form.setValue("byWeekDay", dayOfWeekStr);
      }
    }
  };

  // When startDate changes, update the byWeekDay to match
  useEffect(() => {
    if (isUpdating) return; // Don't update the day of week if we're updating
    setDayOfWeek();
  }, [watchStartDate]);

  return (
    <VStack
      spacing={4}
      minW={"100%"}
      alignItems={"flex-start"}
      display={"flex"}
    >
      <HStack w={"100%"} alignItems={"flex-end"}>
        <Box w={"300px"}>
          <SelectField
            field={{
              id: "frequency",
              validation: {
                required: false,
              },
              label: "Repeat",
            }}
            options={[
              {
                value: "WEEKLY",
                label: "Weekly",
              },
              {
                value: "MONTHLY",
                label: "Monthly",
              },
            ]}
            isClearable={true}
          />
        </Box>
        {intervalToShow === "month" && (
          <HStack w={"100%"} alignItems={"flex-end"}>
            <Text fontSize={16} fontWeight={500}>
              every
            </Text>
            <Box w={"150px"} paddingTop={"30px"}>
              <SelectField
                field={{
                  id: "interval",
                  validation: {
                    required: true,
                  },
                }}
                options={[
                  {
                    value: 1,
                    label: "1",
                  },
                  {
                    value: 2,
                    label: "2",
                  },
                  {
                    value: 3,
                    label: "3",
                  },
                  {
                    value: 4,
                    label: "4",
                  },
                  {
                    value: 5,
                    label: "5",
                  },
                  {
                    value: 6,
                    label: "6",
                  },
                  {
                    value: 7,
                    label: "7",
                  },
                  {
                    value: 8,
                    label: "8",
                  },
                  {
                    value: 9,
                    label: "9",
                  },
                  {
                    value: 10,
                    label: "10",
                  },
                  {
                    value: 11,
                    label: "11",
                  },
                  {
                    value: 12,
                    label: "12",
                  },
                ]}
              />
            </Box>
            <Text fontSize={16} fontWeight={500}>
              month(s)
            </Text>
          </HStack>
        )}
      </HStack>
      {intervalToShow === "week" && (
        <VStack w={"100%"} gap={4}>
          <HStack w={"100%"}>
            <Text fontSize={16} fontWeight={500}>
              every
            </Text>
            <Box w={"70px"}>
              <NumberField
                field={{
                  id: "interval",
                  validation: {
                    required: {
                      value: true,
                      message: "Required",
                    },
                    min: {
                      value: 1,
                      message: "At least 1",
                    },
                  },
                }}
              />
            </Box>
            <Text fontSize={16} fontWeight={500}>
              week(s)
            </Text>
          </HStack>
          <CheckboxGroupField
            direction={"wrap"}
            field={{
              id: `byWeekDay`,
              validation: {
                // required: true,
                validate: (value) => {
                  return (
                    (value && value.length > 0) ||
                    "Please select at least one day of the week."
                  );
                },
              },
              options: [
                {
                  value: "MO",
                  label: "Mon",
                },
                {
                  value: "TU",
                  label: "Tue",
                },
                {
                  value: "WE",
                  label: "Wed",
                },
                {
                  value: "TH",
                  label: "Thu",
                },
                {
                  value: "FR",
                  label: "Fri",
                },
                {
                  value: "SA",
                  label: "Sat",
                },
                {
                  value: "SU",
                  label: "Sun",
                },
              ],
            }}
          />
        </VStack>
      )}
      {intervalToShow === "month" && (
        <VStack gap={4} w={"100%"} alignItems={"flex-start"}>
          <HStack w={"410px"}>
            <Text fontSize={16} fontWeight={500} w={"150px"}>
              On the
            </Text>
            <SelectField
              field={{
                id: "bySetPos",
                validation: {
                  required: true,
                },
              }}
              options={[
                {
                  value: 1,
                  label: "First",
                },
                {
                  value: 2,
                  label: "Second",
                },
                {
                  value: 3,
                  label: "Third",
                },
                {
                  value: 4,
                  label: "Fourth",
                },
                {
                  value: -1,
                  label: "Last",
                },
              ]}
            />
            <SelectField
              field={{
                id: "byWeekDay",
                validation: {
                  required: true,
                },
              }}
              options={[
                {
                  value: "MO",
                  label: "Monday",
                },
                {
                  value: "TU",
                  label: "Tuesday",
                },
                {
                  value: "WE",
                  label: "Wednesday",
                },
                {
                  value: "TH",
                  label: "Thursday",
                },
                {
                  value: "FR",
                  label: "Friday",
                },
                {
                  value: "SA",
                  label: "Saturday",
                },
                {
                  value: "SU",
                  label: "Sunday",
                },
              ]}
            />
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};
