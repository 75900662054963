import { Box, Text } from "@chakra-ui/react";
import BarGraph from "../../../components/common/charts/BarGraph";

export const ByDepartmentChart = ({
  allEmployeeDepartmentData,
  timePeriod,
}) => {
  return (
    <Box
      overflow={"auto"}
      bg={"white"}
      w={"100%"}
      p={4}
      borderRadius={"md"}
      border={"1px "}
      borderColor={"gray.200"}
      maxW={"800px"}
      minW={"900px"}
    >
      <Box justifyContent={"center"}>
        <Text textAlign={"center"}>
          Team Alignment by Department for {timePeriod.label}
        </Text>
      </Box>
      {allEmployeeDepartmentData && (
        <BarGraph
          data={allEmployeeDepartmentData || []}
          // colors={colors}
          displayPercentages={false}
          xAxisProps={{
            tick: {
              fontSize: "12px",
            },
          }}
        />
      )}
    </Box>
  );
};
