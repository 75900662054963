import { useBreakpointValue } from "@chakra-ui/react";
import * as d3 from "d3";
import { OrgChart } from "d3-org-chart";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useFetchAccount } from "../../api/accounts/accounts";
import useAccountId from "../../hooks/customDomainHooks";
import { NodeButton } from "./NodeButton";
import ZoomButtons from "./ZoomButtons";

export const OrgChartPage = () => {
  const d3Container = useRef(null);
  const [data, setData] = useState(null);
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { accountId } = useAccountId();
  const { data: accountData } = useFetchAccount(accountId || "");
  const [chart, setChart] = useState(null);

  useEffect(() => {
    if (!accountData) return;

    const profilesWithNoReportsToId = [];
    let formattedData = [];

    accountData.profiles.forEach((profile) => {
      if (profile.isActive) {
        const formattedProfile = {
          ...profile,
          parentId: profile.reportsTo?.id || null,
        };

        if (!profile.reportsTo?.id) {
          profilesWithNoReportsToId.push(formattedProfile);
        } else {
          formattedData.push(formattedProfile);
        }
      }
    });

    const profileWithHighestReports = profilesWithNoReportsToId
      // .filter((profile) => profile._count.reports > 0)
      .sort((a, b) => b._count.reports - a._count.reports)
      .slice(0, 1);

    // rootsNotShown are the profiles with no reportsToId that are not the profile with the highest reports
    const rootsNotShown = profilesWithNoReportsToId.filter(
      (profile) => profile.id !== profileWithHighestReports.id
    );
    let profilesNotShown = [...rootsNotShown];

    // Remove all profiles from formattedData that are not children or distant child of profileWithHighestReports
    const isDescendant = (profile, parentId) => {
      if (profile.parentId === parentId) return true;
      if (!profile.parentId) return false;
      const parentProfile = formattedData.find(
        (p) => p.id === profile.parentId
      );
      return parentProfile ? isDescendant(parentProfile, parentId) : false;
    };

    // Filter formattedData to only include descendants of profileWithHighestReports
    // this ensures only 1 root parent and its descendants are shown
    formattedData = formattedData.filter((profile) => {
      const showProfile = isDescendant(
        profile,
        profileWithHighestReports[0].id
      );
      if (!showProfile) {
        profilesNotShown.push(profile);
      }
      return showProfile;
    });

    console.log(`${profilesNotShown.length} are not shown on the chart`);

    setData([...formattedData, ...profileWithHighestReports]);
  }, [accountData]);

  useEffect(() => {
    if (data && data.length > 0 && d3Container.current && !chart) {
      const newChart = new OrgChart();
      setChart(newChart); // Initialize chart once data and container are ready
    }
  }, [data, d3Container.current]);

  // We need to manipulate DOM
  useLayoutEffect(() => {
    if (chart && data && data.length > 0 && d3Container.current) {
      // if (!chart) {
      //   chart = new OrgChart();
      // }
      chart
        .container(d3Container.current)
        .data(data)
        .nodeWidth(() => 200)
        .nodeHeight(() => 200)
        .childrenMargin(() => 90)
        .compactMarginBetween(() => 65)
        .compactMarginPair(() => 80)
        .neighbourMargin(() => 50)
        .siblingsMargin(() => 100)
        .compact(!isDesktop)
        // .onNodeClick((d, i, arr) => {
        //   console.log(d, "Id of clicked node ");
        //   // props.onNodeClick(d);
        // })
        .buttonContent(({ node }) => {
          return ReactDOMServer.renderToStaticMarkup(
            <NodeButton node={node} />
          );
        })
        .nodeContent(function (d) {
          return ReactDOMServer.renderToStaticMarkup(<NodeContent d={d} />);
        })
        .nodeUpdate(function () {
          d3.select(this)
            .select(".tree_card")
            .on("click", (event) => {
              event.stopPropagation();
              const button = d3.select(this).select(".node-button-div").node();
              if (button) {
                const event = new MouseEvent("click", {
                  view: window,
                  bubbles: true,
                  cancelable: true,
                });
                button.dispatchEvent(event);
              } else {
                console.error("button not found");
              }
            });
        })
        .render()
        .fit();
    }
  }, [chart, data, d3Container.current, isDesktop]);

  function filterOrgChart(e) {
    // Get input value
    const value = e.target.value?.toLowerCase();

    // Clear previous higlighting
    chart.clearHighlighting();

    // Get chart nodes
    const data = chart.data();

    // Mark all previously expanded nodes for collapse
    data.forEach((d) => (d._expanded = false));

    // Loop over data and check if input value matches any name
    data.forEach((d) => {
      if (
        value != "" &&
        (d.name?.toLowerCase().includes(value) ||
          d.employeeId?.toLowerCase().includes(value) ||
          d.title?.toLowerCase().includes(value) ||
          d.email?.toLowerCase().includes(value) ||
          d.department?.name?.toLowerCase().includes(value))
      ) {
        d._highlighted = true;
        d._expanded = true;
      }
    });

    // Update data and rerender graph
    chart.data(data).render().fit();
  }

  return (
    <div
      style={{
        position: "relative",
        backgroundImage: "radial-gradient(#E2E8F0 1px, transparent 0)",
        backgroundSize: "12px 12px",
        backgroundPosition: "-19px -19px",
      }}
    >
      <ZoomButtons chart={chart} top={4} filter={filterOrgChart} />
      <div ref={d3Container} />
    </div>
  );
};

const NodeContent = (props) => {
  const { d } = props;

  const getInitials = (fullName) => {
    const nameArray = fullName.split(" ");
    const initials =
      nameArray[0].charAt(0) +
      (nameArray.length > 1 ? nameArray[nameArray.length - 1].charAt(0) : "");
    return initials.toUpperCase();
  };

  return (
    <div
      className="tree_card"
      style={{
        paddingTop: "30px",
        height: d.height + 20,
        width: d.width,
        overflow: "hidden", // Prevents content from spilling out
        boxSizing: "border-box", // Makes sure padding and border are included in the total width and height
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          // paddingBottom: "28px",
          height: d.height - 28,
          borderRadius: "8px",
          border: "1px solid lightgray",
          boxShadow: d.data._highlighted
            ? "0px 0px 14px 6px rgba(47,133,90,0.85)"
            : "0px 0px 4px 0px #00000052",
        }}
      >
        {d.data.user?.photoUrl ? (
          <img
            src={d.data.user?.photoUrl}
            style={{
              marginTop: "-30px",
              marginLeft: `${d.width / 2 - 30}px`,
              borderRadius: "100px",
              width: "60px",
              height: "60px",
            }}
          />
        ) : (
          <div
            style={{
              width: "60px",
              height: "60px",
              backgroundColor: "darkgray",
              margin: "auto",
              marginTop: "-30px",
              marginLeft: `${d.width / 2 - 30}px`,
              borderRadius: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "20px",
              color: "white",
            }}
          >
            <span>{getInitials(d.data.name)}</span>
          </div>
        )}
        <div
          style={{ padding: "10px", paddingTop: "16px", textAlign: "center" }}
        >
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>
            {d.data.name}
          </div>
          <div style={{ fontSize: "14px" }}>{d.data.employeeId}</div>
          <div style={{ color: "#404040", fontSize: "16px", marginTop: "4px" }}>
            {d.data.title}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <div>Manages: {d.data._directSubordinates} </div>
          <div>Oversees: {d.data._totalSubordinates} </div>
        </div>
      </div>
    </div>
  );
};

export default OrgChartPage;
