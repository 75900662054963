import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { CultureScoreFactor } from "./types";

export const useUpdateCultureScoreConfiguration = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId: string; cultureScoreConfigId: string; data: any }
  >
) =>
  useMutation({
    mutationKey: ["updateCultureScoreConfiguration"],
    mutationFn: async ({
      accountId,
      cultureScoreConfigId,
      data,
    }: {
      accountId: string;
      cultureScoreConfigId: string;
      data: any;
    }) => {
      const response = await api.put(
        `/${accountId}/cultureScoreConfiguration/${cultureScoreConfigId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useFetchCultureScoreFactors = (accountId: string) => {
  return useQuery<CultureScoreFactor>({
    queryKey: ["fetchCultureScoreFactors"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/cultureScoreFactor`);
      return response.data;
    },
  });
};
