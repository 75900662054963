import {
  Button,
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import useToastMessage from "../../../hooks/useToastMessage";
import { useState } from "react";
import EmojiPicker from "../../../components/common/EmojiPicker/EmojiPicker";
import {
  useCreateBannedReaction,
  useDeleteBannedReaction,
} from "../../../api/bannedReaction/bannedReaction";
import { DeleteSomethingModal } from "../../../components/modals/DeleteSomethingModal";
import useAccountId from "../../../hooks/customDomainHooks";

export const EditBannedReactions = ({ account }) => {
  const { accountId } = useAccountId();
  const createBannedReactionMutation = useCreateBannedReaction();
  const removeBannedReactionMutation = useDeleteBannedReaction();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const [isDeleteBannedReactionModalOpen, setIsDeleteBannedReactionModalOpen] =
    useState(false);
  const [emojiToRemove, setEmojiToRemove] = useState(null);
  const [selectedEmojis, setSelectedEmojis] = useState(
    account?.bannedReactionEmojis.map(
      (bannedReactionEmoji) => bannedReactionEmoji
    ) || []
  );
  const [showBannedEmojis, setShowBannedEmojis] = useState(false);

  const removeEmoji = async () => {
    setSelectedEmojis(
      selectedEmojis.filter((emoji) => emoji !== emojiToRemove)
    );
    const bannedReactionToRemove = account?.bannedReactionEmojis.find(
      (bannedReactionEmoji) => bannedReactionEmoji === emojiToRemove
    );
    if (!bannedReactionToRemove) return;
    try {
      await removeBannedReactionMutation.mutateAsync({
        accountId,
        emoji: bannedReactionToRemove,
      });
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (error) {
      showErrorToast({ message: `Error removing reaction` });
    }
  };

  const addEmoji = async (emoji) => {
    // Avoid duplicate emojis
    if (!selectedEmojis.find((e) => e === emoji.native)) {
      setSelectedEmojis([...selectedEmojis, emoji.native]);
      setShowBannedEmojis(true);
    } else {
      return;
    }
    try {
      await createBannedReactionMutation.mutateAsync({
        accountId,
        data: {
          reaction: emoji.native,
        },
      });
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (error) {
      showErrorToast({ message: `Error creating reaction` });
    }
  };

  return (
    <>
      <Text>Banned Reactions</Text>
      <DeleteSomethingModal
        isOpen={isDeleteBannedReactionModalOpen}
        isDeleting={removeBannedReactionMutation.isLoading}
        setIsOpen={setIsDeleteBannedReactionModalOpen}
        headerText={"Allow this reaction"}
        bodyText={`Are you sure you want to allow this reaction?`}
        deleteFunction={async () => {
          await removeEmoji();
        }}
        buttonText={"Allow Reaction"}
      />
      {selectedEmojis?.length > 0 && (
        <Button
          onClick={() => setShowBannedEmojis(!showBannedEmojis)}
          variant="outline"
          fontSize={"sm"}
        >
          {`${showBannedEmojis ? "Hide" : "View"} Banned Reactions`}
        </Button>
      )}
      {showBannedEmojis && (
        <Wrap>
          {selectedEmojis.map((emoji) => (
            <WrapItem key={emoji}>
              <HStack spacing={4}>
                <Tag
                  size={"lg"}
                  borderRadius="full"
                  variant="outline"
                  colorScheme={"red"}
                >
                  <TagLabel>{emoji}</TagLabel>
                  <TagCloseButton
                    onClick={() => {
                      setEmojiToRemove(emoji);
                      setIsDeleteBannedReactionModalOpen(true);
                    }}
                  />
                </Tag>
              </HStack>
            </WrapItem>
          ))}
        </Wrap>
      )}
      <EmojiPicker
        onEmojiSelect={addEmoji}
        buttonLabel={"Add Banned Reaction"}
      />
    </>
  );
};
