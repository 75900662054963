import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Textarea,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import get from "lodash/get";
import React, { useRef, useEffect } from "react";
import TipTapEditor, { AiContext } from "../common/Editor/TipTapEditor";
import { AiContextTask } from "../../context/TipTapAiContextComponent";

type InputFieldProps = {
  field: {
    id: string;
    defaultValue?: string;
    label?: string;
    placeholder?: string;
    aiPlaceholder?: string;
    validation?: {
      required?: any;
    };
    isReadOnly?: boolean;
  };
  leftAddon?: React.ReactNode;
  rightAddon?: React.ReactNode;
  multiLine?: boolean;
  inputStyle?: any;
  inputProps?: any;
  isFakeRequired?: boolean;
  useAi?: boolean;
  aiContext?: AiContext;
  aiContextTask?: AiContextTask;
  aiMultipleLines?: boolean;
};

export const InputField = React.forwardRef(
  (props: InputFieldProps, forwardedRef: any) => {
    const {
      field,
      leftAddon,
      rightAddon,
      multiLine,
      inputStyle,
      inputProps,
      isFakeRequired,
      useAi,
      aiContext,
      aiContextTask,
      aiMultipleLines,
    } = props;
    const form = useFormContext();
    const {
      formState: { errors },
      register,
      control,
    } = form;

    const localRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(
      null
    );

    // This effect ensures that if a forwarded ref is provided, it gets the current value of the localRef.
    useEffect(() => {
      if (forwardedRef) {
        forwardedRef.current = localRef.current;
      }
    }, [forwardedRef]);

    // Merge the refs, to be used with the input components.
    const mergedRef = (
      inputElement: HTMLInputElement | HTMLTextAreaElement
    ) => {
      localRef.current = inputElement;
      if (typeof forwardedRef === "function") {
        forwardedRef(inputElement);
      } else if (forwardedRef) {
        forwardedRef.current = inputElement;
      }
    };

    const inputRef = useRef<HTMLInputElement | null>(null); // Ref for the input field

    return (
      <Controller
        name={field.id}
        control={control}
        defaultValue={field.defaultValue ? field.defaultValue : ""}
        rules={field.validation?.required}
        render={({ field: { onChange, value, onBlur, ref } }) => (
          <FormControl
            isInvalid={!!get(errors, field.id)}
            isRequired={field.validation?.required}
          >
            {field.label && (
              <FormLabel htmlFor={field.id}>
                {" "}
                {field.label}
                {isFakeRequired && <span style={{ color: "#E53E3E" }}> *</span>}
              </FormLabel>
            )}
            <InputGroup>
              {leftAddon}
              {multiLine ? (
                <Textarea
                  {...register(field.id, field.validation)}
                  id={field.id}
                  name={field.id}
                  placeholder={field.placeholder}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value || ""}
                  isReadOnly={field.isReadOnly}
                  ref={(e) => {
                    if (!e) return;
                    ref(e); // react-hook-form's ref
                    mergedRef(e); // your own ref
                  }}
                  {...inputStyle}
                  {...inputProps}
                />
              ) : (
                <>
                  {useAi ? (
                    <Box {...inputStyle} {...inputProps}>
                      <TipTapEditor
                        defaultValue={value}
                        onChange={onChange}
                        aiContext={aiContext}
                        aiContextTask={aiContextTask}
                        isOneLine={true}
                        showTopBar={false}
                        variant={aiMultipleLines ? "unstyled" : "oneLine"}
                        editable={!field.isReadOnly}
                        placeholder={field.placeholder}
                        aiPlaceholder={field.aiPlaceholder}
                      />
                    </Box>
                  ) : (
                    <Input
                      {...register(field.id, field.validation)}
                      id={field.id}
                      visibility={useAi ? "hidden" : "visible"}
                      name={field.id}
                      placeholder={field.placeholder}
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ""}
                      onBlur={onBlur}
                      isReadOnly={field.isReadOnly}
                      ref={(e) => {
                        if (!e) return;
                        inputRef.current = e;
                        ref(e); // react-hook-form's ref
                        mergedRef(e); // your own ref
                      }}
                      {...inputStyle}
                      {...inputProps}
                    />
                  )}
                </>
              )}
              {rightAddon}
            </InputGroup>

            {!!get(errors, field.id) && (
              <FormErrorMessage>
                <>{get(errors, `${field.id}.message`)}</>
              </FormErrorMessage>
            )}
          </FormControl>
        )}
      />
    );
  }
);
