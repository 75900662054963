import {
  Center,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { BsCalendarPlus } from "react-icons/bs";
import AppleIcon from "../../../assets/icons/apple.svg?react";
import GoogleIcon from "../../../assets/icons/google.svg?react";
import OutlookIcon from "../../../assets/icons/outlook.svg?react";

function toGoogleCalendarDateFormat(dateString: string) {
  return dateString.replace(/-|:|\.\d\d\d/g, "");
}

export const createGoogleCalendarLink = (
  event: {
    title: string;
    startTime: string;
    endTime: string;
    location: string;
    description?: string;
    recurrence?: string;
  },
  invitees = []
) => {
  const start = encodeURI(toGoogleCalendarDateFormat(event.startTime));
  const end = encodeURI(toGoogleCalendarDateFormat(event.endTime));
  const title = encodeURI(event.title);
  const location = encodeURI(event.location);
  const description = encodeURI(event.description || "");

  let recurrence = "";
  if (event.recurrence) {
    const rrule = event.recurrence.split("RRULE:").pop();
    recurrence = `&recur=RRULE:${encodeURI(rrule || "")}`;
  }

  let attendees = "";
  if (invitees && invitees.length > 0) {
    attendees = invitees
      .map((email) => `&add=${encodeURIComponent(email)}`)
      .join("");
  }

  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${start}/${end}&details=${description}&location=${location}${recurrence}${attendees}`;
};

export const createICalData = (
  event: {
    title: string;
    startTime: string;
    endTime: string;
    location: string;
    description?: string;
    recurrence?: string;
  },
  invitees = []
) => {
  const formatDateTime = (dateTime: string) => {
    const date = new Date(dateTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return `TZID=${timezone}:${year}${month}${day}T${hours}${minutes}${seconds}`;
  };

  const start = formatDateTime(event.startTime);
  const end = formatDateTime(event.endTime);
  const title = event.title;
  const location = event.location;
  // TODO html description doesn't work in Apple Calendar. Need to use plain text.
  //const description = event.description;
  // \nDESCRIPTION:${description}

  let attendees = "";
  if (invitees && invitees.length > 0) {
    attendees = invitees
      .map((email) => `ATTENDEE:mailto:${encodeURIComponent(email)}`)
      .join("\n");
  }

  let recurrenceRule = "";
  if (event.recurrence) {
    // Assuming event.recurrence is a string like "FREQ=WEEKLY;COUNT=10"
    recurrenceRule = `\nRRULE:${event.recurrence}`;
  }

  return encodeURI(
    `BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nUID:${Math.random()}@google.com\nDTSTART;${start}\nDTEND;${end}\nSUMMARY:${title}\nLOCATION:${location}\n${attendees}${recurrenceRule}\nEND:VEVENT\nEND:VCALENDAR`
  );
};

export const AddToCalendarButton = ({
  googleLink,
  iCalLink,
  buttonProps,
}: {
  googleLink: string;
  iCalLink: string;
  buttonProps?: any;
}) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        variant="outline"
        size={"sm"}
        onClick={(e) => e.stopPropagation()}
        {...buttonProps}
      >
        <HStack>
          <BsCalendarPlus />
          <Text>Add to Calendar</Text>
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuItem
          href={googleLink}
          icon={
            <Center>
              <Icon as={GoogleIcon} fontSize={"2xl"} />
            </Center>
          }
          as={Link}
          isExternal
          style={{ textDecoration: "none", fontWeight: "bold" }}
        >
          Google
        </MenuItem>
        <MenuItem
          href={iCalLink}
          icon={
            <Center>
              <Icon as={OutlookIcon} fontSize={"2xl"} />
            </Center>
          }
          as={Link}
          isExternal
          style={{ textDecoration: "none", fontWeight: "bold" }}
        >
          Outlook
        </MenuItem>
        <MenuItem
          href={iCalLink}
          icon={
            <Center>
              <Icon as={AppleIcon} fontSize={"2xl"} />
            </Center>
          }
          as={Link}
          isExternal
          style={{ textDecoration: "none", fontWeight: "bold" }}
        >
          Apple
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
