import { Button, HStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useCreateQuestion } from "../../../api/survey/question";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import LayoutForm from "./LayoutForm";
import QuestionForm from "./QuestionForm";

const SurveyQuestions = ({ updateSurvey, questions, questionTags }) => {
  const createQuestionMutation = useCreateQuestion();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const { surveyId } = useParams();
  const { accountId } = useAccountId();
  const [orderedQuestions, setOrderedQuestions] = useState(questions);
  const [questionsOrder, setQuestionsOrder] = useState(
    questions.map((q) => q.id)
  );

  const createQuestion = async (data) => {
    try {
      await createQuestionMutation.mutateAsync({ accountId, data });
      queryClient.invalidateQueries(["fetchSurvey", surveyId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating question" });
    }
  };

  // When questionsOrder changes, update the questions to show the new order
  // also update the survey with the new order
  useEffect(() => {
    // Update questions with new order
    const newOrderedQuestions = questionsOrder.map((questionId) => {
      return questions.find((q) => q.id === questionId);
    });
    if (isEqual(newOrderedQuestions, orderedQuestions)) return;
    setOrderedQuestions(newOrderedQuestions);
    updateSurvey({ questionsOrder });
  }, [questionsOrder]);

  // const questionLinkedList = new List();
  // questionsOrder.forEach((questionId) => {
  //   const questionNode = new Item();
  //   questionNode.questionId = questionId;
  //   questionLinkedList.append(questionNode);
  // });

  const moveQuestion = useCallback(
    (dragIndex, hoverIndex) => {
      if (dragIndex === hoverIndex) return;

      const newQuestionsOrder = [...questionsOrder];
      const draggedQuestionId = newQuestionsOrder[dragIndex];

      newQuestionsOrder.splice(dragIndex, 1); // remove item at dragIndex
      newQuestionsOrder.splice(hoverIndex, 0, draggedQuestionId); // insert item at hoverIndex

      setQuestionsOrder(newQuestionsOrder);
    },
    [questionsOrder]
  );

  useEffect(() => {
    setOrderedQuestions(questions);
    setQuestionsOrder(questions.map((q) => q.id));
  }, [questions]);

  return (
    <>
      {orderedQuestions.map((question, index) => {
        if (!["HEADER", "PARAGRAPH"].includes(question.element.type)) {
          return (
            <QuestionForm
              key={question.id}
              question={question}
              index={index}
              moveQuestion={moveQuestion}
              questionsLength={orderedQuestions.length}
              questionTags={questionTags}
            />
          );
        } else {
          return (
            <LayoutForm
              key={question.id}
              question={question}
              index={index}
              moveQuestion={moveQuestion}
              questionsLength={orderedQuestions.length}
              questionTags={questionTags}
            />
          );
        }
      })}
      <HStack>
        <Button
          onClick={() => {
            createQuestion({
              prompt: "New Question",
              isRequired: true,
              surveyId,
              element: {
                type: "TEXT",
              },
            });
          }}
          leftIcon={<IoAddOutline />}
          isLoading={createQuestionMutation.isLoading}
        >
          Add Question
        </Button>
        <Button
          onClick={() => {
            createQuestion({
              surveyId,
              prompt: "My Header",
              element: {
                type: "HEADER",
              },
            });
          }}
          leftIcon={<IoAddOutline />}
          isLoading={createQuestionMutation.isLoading}
        >
          Add Layout
        </Button>
      </HStack>
    </>
  );
};

export default SurveyQuestions;
