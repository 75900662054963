import { Button } from "@chakra-ui/react";

const ExpandAllButton = ({ expandAll, setExpandAll }) => {
  return (
    <>
      {(expandAll === undefined || expandAll === false) && (
        <Button
          variant={"outline"}
          size={"xs"}
          onClick={() => setExpandAll(true)}
          w={"100px"}
        >
          Expand all
        </Button>
      )}
      {expandAll === true && (
        <Button
          variant={"outline"}
          size={"xs"}
          onClick={() => setExpandAll(false)}
          w={"100px"}
        >
          Collapse all
        </Button>
      )}
    </>
  );
};

export default ExpandAllButton;
