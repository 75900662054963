import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import SetEnterprisePlanForm from "../SetEnterprisePlanForm";

export const SetPlanModal = (props) => {
  const { isOpen, setIsOpen, partnerAccount, partnerData } = props;

  if (!partnerAccount) return null;
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"2xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Set Subscription for {partnerAccount?.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box pb={6}>
            <SetEnterprisePlanForm
              partnerAccount={partnerAccount}
              partnerData={partnerData}
              onDone={() => {
                setIsOpen(false);
              }}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
