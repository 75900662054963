import {
  Center,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useState } from "react";
import useAccountId from "../../../hooks/customDomainHooks";
import { useArchiveTemplate } from "../../../api/oneOnOne/oneOnOneTemplate";
import { useQueryClient } from "@tanstack/react-query";
import useToastMessage from "../../../hooks/useToastMessage";
import { DeleteSomethingModal } from "../../../components/modals/DeleteSomethingModal";
import { BiArchive, BiDotsVerticalRounded, BiDuplicate } from "react-icons/bi";

export const TemplateMenu = ({ templateId, duplicateTemplate, canDelete }) => {
  const { accountId } = useAccountId();
  const archiveTemplateMutation = useArchiveTemplate();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const [isArchiveTemplateModalOpen, setIsArchiveTemplateModalOpen] =
    useState(false);

  const archiveTemplate = async () => {
    try {
      await archiveTemplateMutation.mutateAsync({
        accountId,
        templateId,
      });
      queryClient.invalidateQueries(["fetchOneOnOneTemplates"]);
    } catch (error) {
      showErrorToast({ message: "Error archiving template" });
      console.log(error);
    }
  };

  return (
    <>
      <DeleteSomethingModal
        isOpen={isArchiveTemplateModalOpen}
        setIsOpen={setIsArchiveTemplateModalOpen}
        headerText={"Archive Template"}
        bodyText={`Are you sure you want to archive this template?`}
        deleteFunction={async () => {
          await archiveTemplate();
        }}
        buttonText={"Archive Template"}
      />
      <Menu>
        <MenuButton
          as={IconButton}
          variant="outline"
          icon={<BiDotsVerticalRounded size={22} />}
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></MenuButton>
        <MenuList>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              duplicateTemplate(templateId);
            }}
            icon={
              <Center>
                <Icon as={BiDuplicate} fontSize={"xl"} />
              </Center>
            }
          >
            Duplicate
          </MenuItem>
          {canDelete && (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setIsArchiveTemplateModalOpen(true);
              }}
              icon={
                <Center>
                  <Icon as={BiArchive} fontSize={"xl"} />
                </Center>
              }
              color={"red.500"}
            >
              Archive
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </>
  );
};
