import { Box, Center, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { InputField } from "../../../../components/fields/InputField";
import QuestionTypeSelector from "../QuestionTypeSelector";

const NPSQuestion = ({ questionIndex, onChange }) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <VStack w={"100%"} spacing={1}>
        <Stack
          w={"100%"}
          gap={4}
          spacing={0}
          flexDirection={{ base: "column", md: "row" }}
        >
          <InputField
            field={{
              id: `prompt`,
              placeholder: "Provide your question prompt",
              validation: {
                required: { value: true, message: "Prompt is required" },
              },
            }}
          />
          <QuestionTypeSelector
            questionIndex={questionIndex}
            onChange={onChange}
          />
        </Stack>
        <HStack w={"100%"} spacing={6}>
          <Box w={"28px"}>
            <Center>
              <Text fontWeight={"bold"}>0</Text>
            </Center>
          </Box>
          <InputField
            field={{
              id: `element.choices[0].label`,
              validation: {
                required: {
                  value: true,
                  message: "Label is required for first and last option",
                },
              },
            }}
            inputStyle={{ variant: "flushed" }}
          />
        </HStack>
        <Box alignSelf={"flex-start"} color="gray.400">
          <BiDotsVerticalRounded size={"28px"} />
        </Box>
        <HStack w={"100%"} spacing={6}>
          <Box w={"28px"}>
            <Center>
              <Text fontWeight={"bold"} alignSelf={"center"}>
                10
              </Text>
            </Center>
          </Box>
          <InputField
            field={{
              id: `element.choices[10].label`,
              validation: {
                required: {
                  value: true,
                  message: "Label is required for first and last option",
                },
              },
            }}
            inputStyle={{ variant: "flushed" }}
          />
        </HStack>
      </VStack>
    </DndProvider>
  );
};

export default NPSQuestion;
