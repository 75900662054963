import { useEffect, useRef, useState } from "react";
import {
  useConfirmSetupIntent,
  useCreateAccountSubscription,
} from "../../api/billing/billing";
import useToastMessage from "../../hooks/useToastMessage";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import LoadingBox from "../../components/common/LoadingBox";
import { useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const BillingRedirect = () => {
  // Call the backend to confirm setup intent
  // then, redirect to subscription page
  const confirmSetupIntentMutation = useConfirmSetupIntent();
  const createAccountSubscriptionMutation = useCreateAccountSubscription();
  const { showErrorToast } = useToastMessage();
  const [respData, setRespData] = useState(null);
  const { accountId } = useAccountId();
  const navigate = useCustomDomainNavigate();
  const [
    isErrorCreatingAccountSubscription,
    setIsErrorCreatingAccountSubscription,
  ] = useState(false);
  const searchParams = new URLSearchParams(useLocation().search);
  const planName = searchParams.get("planName");
  const billingPeriod = searchParams.get("billingPeriod");
  const didRun = useRef(false);
  const queryClient = useQueryClient();

  const createAccountSubscription = async () => {
    try {
      await createAccountSubscriptionMutation.mutateAsync({
        accountId,
        data: {
          planName,
          billingPeriod,
        },
      });
    } catch (error) {
      setIsErrorCreatingAccountSubscription(true);
      showErrorToast({ message: `Error creating account subscription.` });
    }
  };

  const confirmSetupIntent = async () => {
    try {
      const respData = await confirmSetupIntentMutation.mutateAsync({
        accountId,
      });
      console.log(respData);
      await createAccountSubscription();
      await queryClient.invalidateQueries(["fetchAccount", accountId]);
      setRespData(respData);
    } catch (error) {
      console.log(error);
      showErrorToast({
        message:
          "Something went wrong. Please contact support@thebestanswer.com",
      });
    }
  };

  useEffect(() => {
    if (!didRun.current) {
      confirmSetupIntent();
      didRun.current = true;
    }
  }, []);

  if (!respData || createAccountSubscriptionMutation.isLoading) {
    return <LoadingBox />;
  } else if (
    respData.status === "succeeded" ||
    !isErrorCreatingAccountSubscription
  ) {
    navigate(`/${accountId}/admin-dashboard`);
  } else if (
    respData.status === "canceled" ||
    respData.status === "requires_payment_method"
  ) {
    console.log(
      "canceled or requires_payment_method, redirecting to billing page"
    );
    navigate(`/${accountId}/setup/subscriptions`);
  } else {
    return (
      <p>Something went wrong. Please contact support@thebestanswer.com</p>
    );
  }
};

export default BillingRedirect;
