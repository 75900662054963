import { Box, Center, Text, VStack } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useFetchSurveyRunMetrics } from "../../../api/survey/survey";
import { useGetAllSurveyRunResponses } from "../../../api/survey/surveyRunResponse";
import LoadingBox from "../../../components/common/LoadingBox";
import CustomTable from "../../../components/table/CustomTableV2";
import useAccountId from "../../../hooks/customDomainHooks";
import {
  LinearScaleChart,
  NPSChart,
  NumericChart,
  StaticValuesChart,
  TextValues,
} from "../../CultureDashboard/widgets/view/WidgetCharts";
import { SurveysReportConfig } from "./SurveysReportConfig";

const SurveysReport = () => {
  const { accountId } = useAccountId();
  const [surveyRunId, setSurveyRunId] = useState(null);
  const {
    data: surveyRunMetrics,
    isLoading: isLoadingSurveyRunMetrics,
    fetchStatus,
  } = useFetchSurveyRunMetrics(accountId, surveyRunId);
  const [tenuresToFilter, setTenuresToFilter] = useState([]);
  const [departmentsToFilter, setDepartmentsToFilter] = useState([]);
  const [ranksToFilter, setRanksToFilter] = useState([]);
  const [viewMode, setViewMode] = useState("respondentTable");

  const getResponsesForQuestion = (questionId) => {
    const allResponsesForQuestion =
      surveyRunMetrics?.surveyRun.surveyRunResponses.map(
        (surveyRunResponse) => ({
          ...surveyRunResponse,
          responses: null,
          response: surveyRunResponse.responses.find(
            (response) => response.questionId === questionId
          ),
        })
      );
    const responsesFilteredByTenure = allResponsesForQuestion.filter(
      (response) => {
        if (tenuresToFilter.length === 0) return true;
        // loop over every tenure to filter and do an OR check. If any of the tenures match, return true
        return tenuresToFilter.some((tenure) => {
          const { minDays, maxDays } = tenure.value;
          const tenureAtTimeOfSurvey =
            response.respondent.employmentTenureDaysAtTimeOfSurvey;
          return (
            tenureAtTimeOfSurvey >= minDays && tenureAtTimeOfSurvey <= maxDays
          );
        });
      }
    );
    const responsesFilteredByDepartment = responsesFilteredByTenure.filter(
      (response) => {
        if (departmentsToFilter.length === 0) return true;
        // loop over every department to filter and do an OR check. If any of the departments match, return true
        return departmentsToFilter.some((department) => {
          return response.respondent?.department?.id === department.value;
        });
      }
    );
    const responsesFilteredByRanking = responsesFilteredByDepartment.filter(
      (response) => {
        if (ranksToFilter.length === 0) return true;
        // loop over every ranking to filter and do an OR check. If any of the rankings match, return true
        return ranksToFilter.some((ranking) => {
          return (
            response.respondent.employeeABCRanking?.ranking ===
            (ranking.value === "Unranked" ? null : ranking.value)
          );
        });
      }
    );

    return responsesFilteredByRanking;
  };

  return (
    <VStack alignItems={"flex-start"} spacing={8}>
      <VStack alignItems={"flex-start"} spacing={4}>
        <>
          <SurveysReportConfig
            tenuresToFilter={tenuresToFilter}
            setTenuresToFilter={setTenuresToFilter}
            departmentsToFilter={departmentsToFilter}
            setDepartmentsToFilter={setDepartmentsToFilter}
            ranksToFilter={ranksToFilter}
            setRanksToFilter={setRanksToFilter}
            setSurveyRunId={setSurveyRunId}
            surveyRunId={surveyRunId}
            surveyRunMetrics={surveyRunMetrics}
            setViewMode={setViewMode}
            viewMode={viewMode}
          />

          {isLoadingSurveyRunMetrics && fetchStatus !== "idle" && (
            <Box alignSelf={"center"}>
              <LoadingBox height={"600px"} />
            </Box>
          )}

          {viewMode === "graph" &&
            surveyRunMetrics?.surveyRun?.questions
              .filter(
                (q) =>
                  q.element?.type !== "HEADER" &&
                  q.element?.type !== "PARAGRAPH"
              )
              .map((question) => {
                return (
                  <Box
                    key={question.id}
                    overflow={"auto"}
                    bg={"white"}
                    w={"100%"}
                    p={4}
                    borderRadius={"md"}
                    border={"1px "}
                    borderColor={"gray.200"}
                  >
                    <QuestionResponsesChart
                      surveyRunLabel={surveyRunMetrics?.surveyRun?.label}
                      question={question}
                      questionNumber={
                        surveyRunMetrics?.surveyRun?.questionsOrder?.indexOf(
                          question.id
                        ) + 1
                      }
                      responses={getResponsesForQuestion(question.id).map(
                        (response) => response.response
                      )}
                    />
                  </Box>
                );
              })}
          {viewMode === "questionTable" && (
            <Box w={"100%"}>
              <ByQuestionDataTable
                surveyRunId={surveyRunId}
                departmentsToFilter={departmentsToFilter}
                tenuresToFilter={tenuresToFilter}
                ranksToFilter={ranksToFilter}
              />
            </Box>
          )}
          {viewMode === "respondentTable" && (
            <Box w={"100%"}>
              <ByRespondentDataTable
                surveyRunId={surveyRunId}
                departmentsToFilter={departmentsToFilter}
                tenuresToFilter={tenuresToFilter}
                ranksToFilter={ranksToFilter}
              />
            </Box>
          )}
        </>
      </VStack>
    </VStack>
  );
};

const QuestionResponsesChart = ({
  surveyRunLabel,
  question,
  questionNumber,
  responses,
  updateAdminWidget,
}) => {
  const { type } = question?.element || {};
  const hasResponses = responses?.length > 0 && responses[0];

  const renderChartByType = () => {
    switch (type) {
      case "CHECKBOX":
      case "RADIO":
      case "DROPDOWN":
        return <StaticValuesChart responses={responses} />;
      case "TEXT":
        return (
          <Box h={"300px"}>
            <TextValues responses={responses} />
          </Box>
        );
      case "LINEAR_SCALE":
        return <LinearScaleChart responses={responses} question={question} />;
      case "NUMBER":
        return <NumericChart responses={responses} question={question} />;
      case "NPS":
        return (
          <Box h={"300px"}>
            <NPSChart
              responses={responses}
              question={question}
              widgetData={{ data: { showDetails: true } }}
              updateAdminWidget={updateAdminWidget}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box w={"100%"}>
      <Text m={2} noOfLines={2}>
        {questionNumber}. {question?.prompt}
      </Text>
      <Text m={2} fontSize={"sm"} color={"gray.500"} pb={2}>
        ({responses.length} responses)
      </Text>
      {hasResponses ? (
        renderChartByType(type)
      ) : (
        <Center h={"100%"}>
          <Text align={"center"} color={"gray.500"}>
            No responses yet for Survey Run: {surveyRunLabel}
          </Text>
        </Center>
      )}
    </Box>
  );
};

const ByQuestionDataTable = ({
  departmentsToFilter,
  tenuresToFilter,
  ranksToFilter,
  surveyRunId,
}) => {
  const { accountId } = useAccountId();

  const { data: surveyRunMetrics } = useGetAllSurveyRunResponses(
    "application/json",
    accountId,
    surveyRunId,
    departmentsToFilter,
    tenuresToFilter,
    ranksToFilter
  );

  const dataColumns = useMemo(() => {
    return [
      {
        Header: "Respondent",
        accessor: "Respondent",
      },
      {
        Header: "Question Number",
        accessor: "Question Number",
      },
      {
        Header: "Question Text",
        accessor: "Question Text",
      },
      {
        Header: "Response",
        accessor: "Response",
      },
      {
        Header: "Department",
        accessor: "Department",
      },
      {
        Header: "Tags",
        accessor: "Tags",
      },
    ];
  }, [surveyRunMetrics]);

  const getCsvFileName = () => {
    if (!surveyRunMetrics || !surveyRunMetrics.length) return "survey_run";
    return `${surveyRunMetrics[0]?.Survey}_${surveyRunMetrics[0]?.Run}_by_question`
      .replaceAll(" ", "_")
      .toLowerCase();
  };
  return (
    <CustomTable
      boxStyle={{
        bg: "white",
        borderRadius: "10px",
        border: "1px",
        borderColor: "gray.200",
        p: 4,
        overflow: "auto",
      }}
      columns={dataColumns}
      data={surveyRunMetrics || []}
      initialState={{
        sortBy: [
          {
            id: "Question Number",
            desc: false,
          },
        ],
      }}
      doUsePagination={true}
      showSearchBar={true}
      showDownloadLink={true}
      csvFileName={getCsvFileName()}
    />
  );
};

const ByRespondentDataTable = ({
  departmentsToFilter,
  tenuresToFilter,
  ranksToFilter,
  surveyRunId,
}) => {
  const { accountId } = useAccountId();

  const { data: surveyRunMetrics } = useGetAllSurveyRunResponses(
    "application/json",
    accountId,
    surveyRunId,
    departmentsToFilter,
    tenuresToFilter,
    ranksToFilter
  );

  const [transformedData, setTransformedData] = useState(null);

  const transformData = (surveyRunMetrics) => {
    // This object will map question IDs to their respective text.
    const questionTexts = {};

    // First, we build a map so that we know all the unique question IDs and their corresponding texts.
    surveyRunMetrics.forEach((entry) => {
      const questionId = entry["Question Id"];
      const questionText = entry["Question Text"];
      questionTexts[questionId] = questionText;
    });

    // An object to hold the reorganized data.
    const restructuredData = {};

    // Process each entry in the original data.
    surveyRunMetrics.forEach((entry) => {
      const respondent = entry["Respondent"];

      // If this respondent is not already in the new structure, add them.
      if (!restructuredData[respondent]) {
        restructuredData[respondent] = {
          Respondent: respondent,
          Department: entry["Department"], // Assuming the same department for each entry
          Manager: entry["Manager"], // Assuming the same manager for each entry
          // ... other common fields
        };
      }

      const questionId = entry["Question Id"];
      const questionText = questionTexts[questionId]; // Get the text for this question ID
      const response = entry["Response"];

      // Now, we need to handle the possibility of multiple responses.
      // If the question text is already present, we append the new response; otherwise, we add it.
      // eslint-disable-next-line no-prototype-builtins
      if (restructuredData[respondent].hasOwnProperty(questionText)) {
        // Existing response - we will append the new one separated by a comma.
        const existingResponse = restructuredData[respondent][questionText];

        // Check if the response is already part of the existing responses, to avoid duplicates.
        if (!existingResponse.includes(response.toString())) {
          restructuredData[respondent][questionText] =
            existingResponse + ", " + response;
        }
      } else {
        // New response - add it normally.
        restructuredData[respondent][questionText] = response.toString(); // Convert all responses to string for consistency.
      }
    });

    // Convert the restructured object into an array of objects.
    const result = Object.values(restructuredData);

    return result;
  };

  useEffect(() => {
    if (surveyRunMetrics) {
      setTransformedData(transformData(surveyRunMetrics));
    }
  }, [surveyRunMetrics]);

  const labelFormatter = (label) => {
    if (label.length > 30) {
      return `${label.substring(0, 30)}...`;
    }
    return label;
  };

  const dataColumns = useMemo(() => {
    if (!transformedData || !transformedData.length) return [];
    return [
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...Object.keys(transformedData[0])?.map((dataLabel) => ({
        Header: () => {
          return (
            <Box w={"100%"}>
              <Text textAlign={"end"}>{labelFormatter(dataLabel)}</Text>
            </Box>
          );
        },
        accessor: dataLabel,
        Cell: ({ value }) => {
          return (
            <Box>
              <Text textAlign={"end"}> {value}</Text>
            </Box>
          );
        },
      })),
    ];
  }, [transformedData]);

  const getCsvFileName = () => {
    if (!surveyRunMetrics || !surveyRunMetrics.length) return "survey_run";
    return `${surveyRunMetrics[0]?.Survey}_${surveyRunMetrics[0]?.Run}_by_respondent`
      .replaceAll(" ", "_")
      .toLowerCase();
  };

  return (
    <>
      <Box w={"100%"}>
        <CustomTable
          boxStyle={{
            bg: "white",
            borderRadius: "10px",
            border: "1px",
            borderColor: "gray.200",
            p: 4,
            overflow: "auto",
          }}
          columns={dataColumns}
          data={transformedData || []}
          initialState={{
            sortBy: [
              {
                id: "Question Number",
                desc: false,
              },
            ],
          }}
          doUsePagination={true}
          showSearchBar={true}
          showDownloadLink={true}
          csvFileName={getCsvFileName()}
        />
      </Box>
    </>
  );
};

export default SurveysReport;
