import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { KeyResultHeader } from "./KeyResultHeader";
import { KeyResultOwner } from "./KeyResultOwner";
import KeyResultProgress from "./KeyResultProgress";
import KeyResultGoal from "./KeyResultGoal";
import { PROGRESS_OPTIONS } from "../common";
import { useContext, useMemo } from "react";
import { AccountContext } from "../../../context/AccountContextComponent";

export const KeyResultAccordion = ({
  keyResult,
  index,
  objective,
  dragAttributes,
  dragListeners,
  setAccordionIndex,
  accordionIndex,
  canEditObjective,
  setFocusedKeyResultId,
  focusedKeyResultId,
}) => {
  const bgColor = PROGRESS_OPTIONS[keyResult.progress].bg;

  const { currentProfile, accountData } = useContext(AccountContext);

  const canEditKeyResult = useMemo(() => {
    if (!currentProfile || !keyResult || !accountData) return false;

    // Check if the current user is the owner of the KR
    if (currentProfile.id === keyResult.ownerId) return true;

    // Check if the current user is the owner of the objective
    if (currentProfile.id === objective.ownerId) return true;

    // Check if the current user is an admin/owner
    if (["ADMIN", "OWNER"].includes(currentProfile.role)) return true;

    // Check if the current user is on the objective team
    if (objective.objectiveSet?.ownerType === "TEAM") {
      const isOnTeam = currentProfile.teams.some((team) => {
        return objective.objectiveSet?.teamId === team.teamId;
      });
      return isOnTeam;
    }

    // Check if the current user is in the objective department
    if (objective.objectiveSet?.ownerType === "DEPARTMENT") {
      const isInDepertment =
        currentProfile.departmentId === objective.objectiveSet?.departmentId;
      return isInDepertment;
    }

    return false;
  }, [currentProfile, keyResult, objective, accountData]);

  const toggleAccordion = () => {
    console.log("toggle", accordionIndex);
    setAccordionIndex(
      // add KR index to accordion array if not already in array
      accordionIndex.includes(index)
        ? accordionIndex.filter((i) => i !== index)
        : [...accordionIndex, index]
    );
  };

  const expandAccordion = () => {
    const newSet = new Set(accordionIndex);
    setAccordionIndex([...newSet, index]);
  };

  return (
    <AccordionItem border={0} onClick={expandAccordion} cursor={"pointer"}>
      {({ isExpanded }) => (
        <Box
          bg={
            isExpanded && `linear-gradient(to right, ${bgColor}, transparent)`
          }
        >
          <AccordionButton h={0} p={0} />
          <KeyResultHeader
            keyResult={keyResult}
            canEditKeyResult={canEditKeyResult}
            index={index}
            objective={objective}
            isExpanded={isExpanded}
            dragAttributes={dragAttributes}
            dragListeners={dragListeners}
            canEditObjective={canEditObjective}
            setAccordionIndex={setAccordionIndex}
            focusedKeyResultId={focusedKeyResultId}
            setFocusedKeyResultId={setFocusedKeyResultId}
            toggleAccordion={toggleAccordion}
          />
          <AccordionPanel
            onClick={(e) => {
              e.stopPropagation();
            }}
            cursor={"default"}
          >
            <Box pl={{ base: 0, md: "150px" }}>
              <Wrap>
                <WrapItem
                  h={"50px"}
                  alignContent={"center"}
                  alignItems={"center"}
                >
                  <KeyResultOwner
                    keyResult={keyResult}
                    objective={objective}
                    canEditKeyResult={canEditKeyResult}
                  />
                </WrapItem>
                <WrapItem
                  h={"51px"}
                  alignContent={"center"}
                  alignItems={"center"}
                >
                  <KeyResultProgress
                    keyResult={keyResult}
                    objective={objective}
                    canEditKeyResult={canEditKeyResult}
                  />
                </WrapItem>
                <WrapItem
                  h={"48px"}
                  alignContent={"center"}
                  alignItems={"center"}
                >
                  <Text fontWeight={500}>→ Goal Tracking →</Text>
                </WrapItem>
                <WrapItem
                  h={"50px"}
                  alignContent={"center"}
                  alignItems={"center"}
                >
                  <KeyResultGoal
                    keyResult={keyResult}
                    objective={objective}
                    canEditKeyResult={canEditKeyResult}
                  />
                </WrapItem>
              </Wrap>
            </Box>
          </AccordionPanel>
        </Box>
      )}
    </AccordionItem>
  );
};
