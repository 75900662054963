import { useParams } from "react-router-dom";
import PageShell from "../../layout/PageShell";
import {
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import LoadingBox from "../../components/common/LoadingBox";
import { useFetchSurvey } from "../../api/survey/survey";
import SurveyBuilder from "./BuildSurvey/SurveyBuilder";
import SurveyRuns from "./RunSurvey/SurveyRuns";
import PreviewSurvey from "./BuildSurvey/PreviewSurvey";
import { useQueryClient } from "@tanstack/react-query";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../hooks/customDomainHooks";
import useTabNavigation from "../../hooks/useTabNavigation";

const SurveyBuilderPage = () => {
  const { surveyId } = useParams();
  const { accountId } = useAccountId();
  const { isLoading: isLoadingAccount } = useContext(AccountContext);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const queryClient = useQueryClient();
  const {
    data: survey,
    isLoading: isLoadingSurvey,
    isError,
    error,
  } = useFetchSurvey(accountId, surveyId);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Surveys", href: `/${accountId}/surveys?tab=Manage+Surveys` },
    { name: survey?.title },
  ]);

  const allTabs = [
    {
      name: "Edit",
    },
    {
      name: "Preview",
    },
    {
      name: "Run",
    },
  ];

  const { tabIndex, handleTabsChange } = useTabNavigation(allTabs, "ManageSurveys");

  if (isLoadingAccount || isLoadingSurvey) {
    return <LoadingBox />;
  }

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      headerComponentRight={isLoadingForm && <Spinner />}
    >
      {isError && <Text>{error.message}</Text>}
      <Tabs
        index={tabIndex}
        onChange={(index) => {
          handleTabsChange(index);
          // When switching to preview tab, refetch survey data
          queryClient.invalidateQueries(["fetchSurvey", surveyId]);
        }}
      >
        <TabList>
          <Tab>Edit</Tab>
          <Tab>Preview</Tab>
          <Tab>Manage Runs</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <SurveyBuilder
              survey={survey}
              setIsLoadingForm={setIsLoadingForm}
            />
          </TabPanel>
          <TabPanel>
            <PreviewSurvey survey={survey} />
          </TabPanel>
          <TabPanel>
            <SurveyRuns survey={survey} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageShell>
  );
};

export default SurveyBuilderPage;
