import { Text } from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SelectField } from "../../../components/fields/SelectField";
import { allTenures } from "../../../constants";

export const TenureSelect = ({ selectedTenures, setSelectedTenures }) => {
  const form = useForm({
    mode: "onChange",
  });

  const tenureOptions = useMemo(() => {
    return allTenures;
  }, []);

  const hasATenure = selectedTenures?.length > 0;

  useEffect(() => {
    // clear out the value if selectedTenures is empty
    if (!selectedTenures || selectedTenures?.length === 0) {
      form.setValue("tenure", []);
    }
  }, [selectedTenures, form]);

  return (
    <FormProvider {...form}>
      <form
        key={selectedTenures?.length}
        // Prevent form submission on enter key
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        <SelectField
          field={{
            id: "tenure",
            placeholder: "+ Tenure Filter",
            isMulti: true,
          }}
          options={tenureOptions}
          onSelectionChange={(e) => {
            console.log(e);
            setSelectedTenures(e);
          }}
          isMulti={true}
          isClearable={false}
          selectProps={{
            variant: "unstyled",
            color: "#3182ce",
            components: !hasATenure
              ? {
                  DropdownIndicator: () => null,
                }
              : {
                  Control: ({ children, ...props }) => (
                    <chakraComponents.Control {...props}>
                      {hasATenure ? (
                        <Text fontWeight={"bold"} color={"#3182ce"} px={1}>
                          Tenure:
                        </Text>
                      ) : null}
                      {children}
                    </chakraComponents.Control>
                  ),
                },
          }}
          selectStyles={{
            multiValueLabel: (provided) => ({
              ...provided,
              color: "#3182ce",
            }),
            multiValue: (provided) => ({
              ...provided,
              backgroundColor: "#4285F414",
            }),
            multiValueRemove: (provided) => ({
              ...provided,
              color: "#3182ce",
              _hover: {
                color: "#3182ce",
                backgroundColor: "#4285F424",
              },
            }),
            control: (provided) => ({
              ...provided,
              cursor: "pointer",
              padding: "0px 4px 0px 4px",
              borderRadius: "20px",
              // border: "1px solid",
              _hover: {
                bg: "gray.100",
              },
              bg: hasATenure && "#4285F414",
              border: hasATenure && "1px solid #3182ce",
              color: "#3182ce",
              fontSize: "sm",
            }),
          }}
        />
      </form>
    </FormProvider>
  );
};
