import { useQuery } from "@tanstack/react-query";
import api from "../config";
import { Invoice } from "./types";

export const useFetchInvoice = (accountId: string, invoiceId: string) => {
  return useQuery<Invoice>({
    queryKey: ["fetchInvoice", accountId, invoiceId],
    enabled: !!invoiceId,
    queryFn: async (): Promise<Invoice> => {
      const response = await api.get(`/${accountId}/invoice/${invoiceId}`);
      return response.data;
    },
  });
};

export const useFetchInvoices = (accountId: string) => {
  return useQuery<Invoice[]>({
    queryKey: ["fetchInvoices", accountId],
    queryFn: async (): Promise<Invoice[]> => {
      const response = await api.get(`/${accountId}/invoice`);
      return response.data;
    },
  });
};