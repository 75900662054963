import { Box, Icon, Tooltip } from "@chakra-ui/react";
import {
  BsFillArrowDownSquareFill,
  BsFillArrowUpSquareFill,
} from "react-icons/bs";
import { AiFillMinusSquare } from "react-icons/ai";
import { isNaN } from "lodash";

const TrendingIcon = ({ current, previous }) => {
  if (
    current === null ||
    current === undefined ||
    previous === null ||
    previous === undefined ||
    isNaN(current) ||
    isNaN(previous)
  ) {
    return (
      <Tooltip label="Not enough data available">
        <Box>
          <Icon as={AiFillMinusSquare} color={"gray.300"} fontSize={"26px"} />
        </Box>
      </Tooltip>
    );
  }
  const roundedUpCurrentTo3Decimals = Math.round(current * 10) / 10;
  const roundedUpPreviousTo3Decimals = Math.round(previous * 10) / 10;
  const diff =
    ((roundedUpCurrentTo3Decimals - roundedUpPreviousTo3Decimals) /
      roundedUpPreviousTo3Decimals) *
    100;

  const label = diff > 0 ? `+${diff.toFixed(1)}%` : `${diff.toFixed(1)}%`;

  if (diff === 0)
    return (
      <Tooltip label="No change">
        <Box>
          <Icon as={AiFillMinusSquare} color={"yellow.300"} fontSize={"26px"} />
        </Box>
      </Tooltip>
    );

  if (diff > 0)
    return (
      <Tooltip label={label}>
        <Box>
          <Icon
            as={BsFillArrowUpSquareFill}
            color={"green.400"}
            fontSize={"24px"}
          />
        </Box>
      </Tooltip>
    );

  if (diff < 0)
    return (
      <Tooltip label={label}>
        <Box>
          <Icon
            as={BsFillArrowDownSquareFill}
            color={"red.400"}
            fontSize={"24px"}
          />
        </Box>
      </Tooltip>
    );
  return null;
};

export default TrendingIcon;
