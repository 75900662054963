import {
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  linkWithCredential,
  linkWithPopup,
  signInWithCredential,
  signInWithPopup,
  unlink,
} from "firebase/auth";
import { useState, createContext, useContext } from "react";
import { UserContext } from "../../../context/UserContextComponent";
import useToastMessage from "../../../hooks/useToastMessage";
import { ReAuthEmailPasswordModal } from "./ReAuthEmailPasswordModal";
import { sendVerificationCode } from "../../../auth/components/sendVerificationCode";
import { VerifySMSCodeModal } from "../../../auth/components/VerifySMSCodeModal";

export const UserAuthContext = createContext();

export const UserAuthContextComponent = ({ children }) => {
  const [lockState, setLockState] = useState(true);
  const [verificationId, setVerificationId] = useState(null);
  const [MFAResolver, setMFAResolver] = useState(null);
  const [showVerificationCodeInput, setShowVerificationCodeInput] =
    useState(false);
  const [showReAuthEmailPasswordModal, setShowReAuthEmailPasswordModal] =
    useState(false);
  const { firebaseUser, setFirebaseUser, logout } = useContext(UserContext);
  const { showSuccessToast, showErrorToast } = useToastMessage();

  const authTypeToName = {
    "google.com": "Google",
    "microsoft.com": "Microsoft",
    password: "Email/Password",
  };

  const handleAuthErrors = async (error) => {
    if (
      error.code === "auth/popup-closed-by-user" ||
      error.code === "auth/cancelled-popup-request"
    ) {
      return false;
    }
    if (error.code === "auth/wrong-password") {
      showErrorToast({
        message: "Current password is incorrect",
      });
      return false;
    }
    if (
      error.code === "auth/credential-already-in-use" ||
      error.code === "auth/email-already-in-use"
    ) {
      showErrorToast({
        message: `You've already linked this account to a different user. Please contact support for help.`,
      });
      return false;
    }
    if (error.code == "auth/requires-recent-login") {
      logout();
      return false;
    }
    if (error.code == "auth/multi-factor-auth-required") {
      try {
        console.log("require MFA", error);
        const { verificationId, resolver } = await sendVerificationCode(error);
        if (!verificationId || !resolver) {
          return false;
        }
        setVerificationId(verificationId);
        setMFAResolver(resolver);
        setShowVerificationCodeInput(true);
        return;
      } catch (error) {
        // TODO this is a weird bug I can't figure out.
        // The error is thrown when adding a provider with MFA enabled
        // The provider still gets added, throws this auth/multi-factor-auth-required error
        // but the signin still goes through and sendVerificationCode throws auth/argument-error
        if (error.code === "auth/argument-error") {
          const auth = getAuth();
          await auth.currentUser.reload();
          setFirebaseUser(auth.currentUser);
          return true;
        }
        console.log(error);
        showErrorToast({
          message: "Error on phone verification. Please try again.",
        });
        return false;
      }
    }
    console.error(error);
    showErrorToast({ message: `Error authenticating` });
    return false;
  };

  const reauthenticateProvider = async (provider, authEmails) => {
    try {
      const auth = getAuth();
      const result = await signInWithPopup(auth, provider);
      const signedInEmail = result.user.providerData.find(
        (p) => p.providerId === provider.providerId
      )?.email;
      if (signedInEmail !== authEmails[provider.providerId]) {
        showErrorToast({
          message: `The ${provider.providerId} account does not match the one currently linked to your user`,
        });
        return false;
      }
      return true;
    } catch (error) {
      return handleAuthErrors(error);
    }
  };

  const reauthenticateEmailPassword = async (email, password) => {
    const auth = getAuth();
    const emailAuthCredential = EmailAuthProvider.credential(email, password);
    try {
      await signInWithCredential(auth, emailAuthCredential);
      showSuccessToast(`Authenticated successfully`);
      return true;
    } catch (error) {
      // console.error(error);
      // showErrorToast({ message: `Error in re-authentication` });
      // return false;
      return handleAuthErrors(error);
    }
  };

  const reauthenticateUser = async () => {
    const authMethods = firebaseUser.providerData.map(
      (provider) => provider.providerId
    );
    const authEmails = firebaseUser.providerData.reduce((acc, provider) => {
      acc[provider.providerId] = provider.email;
      return acc;
    }, {});
    const selectedAuthMethod = authMethods[1];

    if (selectedAuthMethod === "password") {
      setShowReAuthEmailPasswordModal(true);
      return false;
    }

    if (selectedAuthMethod === "google.com") {
      return await reauthenticateProvider(new GoogleAuthProvider(), authEmails);
    }

    if (selectedAuthMethod === "microsoft.com") {
      return await reauthenticateProvider(
        new OAuthProvider("microsoft.com"),
        authEmails
      );
    }

    return true;
  };

  const linkEmailPasswordAuth = async (email, password) => {
    try {
      const auth = getAuth();
      const emailAuthCredential = EmailAuthProvider.credential(email, password);
      await linkWithCredential(auth.currentUser, emailAuthCredential);
      await signInWithCredential(auth, emailAuthCredential);
      //  Adding an email address makes the user unverified. Reload page to bring user to not-verifed page.
      window.location.reload();
    } catch (error) {
      return await handleAuthErrors(error);
    }
  };

  const linkSocialAuth = async (providerId) => {
    let authProvider;
    switch (providerId) {
      case "google.com":
        authProvider = new GoogleAuthProvider();
        break;
      case "microsoft.com":
        authProvider = new OAuthProvider("microsoft.com");
        break;
      default:
        break;
    }

    try {
      const auth = getAuth();
      const newUser = await linkWithPopup(auth.currentUser, authProvider);
      setFirebaseUser(newUser.user);
      showSuccessToast(`${authTypeToName[providerId]} authentication linked`);

      return true;
    } catch (error) {
      return await handleAuthErrors(error);
    }
  };

  // TODO handle error for E/P
  const unlinkAuth = async (providerId) => {
    try {
      const auth = getAuth();
      await unlink(auth.currentUser, providerId);
      showSuccessToast(`${authTypeToName[providerId]} authentication unlinked`);
      return true;
    } catch (error) {
      console.log(error);
      showErrorToast({
        message: `Error unlinking ${authTypeToName[providerId]} authentication`,
      });
      return false;
    }
  };

  const handleLockClick = async () => {
    if (!lockState) return setLockState(true);

    const isAuthenticated = await reauthenticateUser();
    if (isAuthenticated) setLockState(false);
  };

  return (
    <UserAuthContext.Provider
      value={{
        lockState,
        authTypeToName,
        handleLockClick,
        handleAuthErrors,
        linkEmailPasswordAuth,
        linkSocialAuth,
        unlinkAuth,
      }}
    >
      <ReAuthEmailPasswordModal
        isOpen={showReAuthEmailPasswordModal}
        setIsOpen={setShowReAuthEmailPasswordModal}
        authenticate={async (email, password) => {
          const isReAuthenticated = await reauthenticateEmailPassword(
            email,
            password
          );
          if (isReAuthenticated) {
            setShowReAuthEmailPasswordModal(false);
            setLockState(false);
          }
        }}
      />
      <VerifySMSCodeModal
        isOpen={showVerificationCodeInput}
        setIsOpen={setShowVerificationCodeInput}
        verificationId={verificationId}
        resolver={MFAResolver}
        onSuccess={() => {
          setVerificationId(null);
          setMFAResolver(null);
          setLockState(false);
        }}
      />
      {/* <div
        id="recaptcha-container-id"
        style={{ display: "flex", justifyContent: "center" }}
      ></div> */}
      {children}
    </UserAuthContext.Provider>
  );
};
