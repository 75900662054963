import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import api from "../config";

export const useAcceptTos = (
  props?: UseMutationOptions<any, Error, { accountId: string }>
) =>
  useMutation<any, Error, { accountId: string }>({
    mutationKey: ["acceptTos"],
    mutationFn: async ({ accountId }) => {
      const response = await api.post(`/${accountId}/tosAcceptance`);
      return response.data as any;
    },
    ...props,
  });
