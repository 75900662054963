/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import {
  BiDotsVerticalRounded,
  BiDuplicate,
  BiPencil,
  BiSend,
  BiShow,
  BiTrashAlt,
} from "react-icons/bi";
import { useParams } from "react-router-dom";
import {
  useCloneKbPage,
  useUpdateKbPage,
} from "../../api/knowledgeBase/kbPage";
import { DeleteSomethingModal } from "../../components/modals/DeleteSomethingModal";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import useToastMessage from "../../hooks/useToastMessage";

const KbPageMenu = ({ pageData, menuItems }) => {
  const navigate = useCustomDomainNavigate();
  const { pageId } = useParams();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const cloneKbPageMutation = useCloneKbPage();
  const updateKbPageMutation = useUpdateKbPage();
  const { currentProfile } = useContext(AccountContext);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const updateKbPage = async (data) => {
    try {
      await updateKbPageMutation.mutateAsync({
        accountId,
        data,
        pageId,
      });
      queryClient.invalidateQueries(["fetchKbPage", pageData.id]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating page" });
    }
  };

  const clonePage = async () => {
    try {
      const kbPageResp = await cloneKbPageMutation.mutateAsync({
        accountId,
        pageId: pageData.id,
      });
      navigate(`/${accountId}/knowledge-base/${kbPageResp.id}/edit`);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error cloning page" });
    }
  };

  const publishPage = async () => {
    const { children, viewers, editors, ...rest } = pageData;
    await updateKbPage({ ...rest, isPublished: true });
    queryClient.invalidateQueries(["fetchKbPage", pageData.id]);
  };

  const archivePage = async () => {
    const { children, viewers, editors, ...rest } = pageData;
    await updateKbPage({ ...rest, isDiscarded: true });
    queryClient.invalidateQueries(["fetchKbPage", pageData.id]);
    navigate(`/${accountId}/knowledge-base`);
  };

  const menuItemsComponents = {
    EDIT: (
      <MenuItem
        _hover={{ bg: "gray.100" }}
        onClick={() => {
          navigate(`/${accountId}/knowledge-base/${pageData.id}/edit`);
        }}
        icon={<BiPencil size={18} />}
      >
        Edit
      </MenuItem>
    ),
    VIEW: (
      <MenuItem
        _hover={{ bg: "gray.100" }}
        onClick={() => {
          navigate(`/${accountId}/knowledge-base/${pageData.id}/view`);
        }}
        icon={<BiShow size={18} />}
      >
        View
      </MenuItem>
    ),
    COPY: (
      <MenuItem
        _hover={{ bg: "gray.100" }}
        onClick={clonePage}
        icon={<BiDuplicate size={18} />}
      >
        Copy
      </MenuItem>
    ),
    PUBLISH: (
      <MenuItem
        _hover={{ bg: "gray.100" }}
        onClick={publishPage}
        icon={<BiSend size={18} />}
        isDisabled={
          pageData.isPublished ||
          !pageData.startTime ||
          !pageData.endTime ||
          !pageData.location
        }
      >
        Publish
      </MenuItem>
    ),
    DELETE: (
      <>
        <MenuDivider />
        <MenuItem
          _hover={{
            bg: "red.100",
          }}
          color={"red.500"}
          onClick={() => {
            setIsDeleteModalOpen(true);
          }}
          icon={<BiTrashAlt size={18} />}
        >
          Delete
        </MenuItem>
      </>
    ),
  };

  const canEditKbPage =
    ["ADMIN", "OWNER", "PARTNER"].includes(currentProfile?.role || "") ||
    pageData.creator?.id === currentProfile?.id ||
    pageData.editors?.some((member) => member.id === currentProfile?.id);
  const canCreateKbPage = ["ADMIN", "OWNER", "PARTNER"].includes(
    currentProfile?.role
  );

  const renderMenuItems = () => {
    let menuItemsToShow = [];
    if (canEditKbPage) {
      menuItemsToShow.push(
        menuItems.map((item, index) => {
          if (item === "COPY" && !canCreateKbPage) return;
          return React.cloneElement(menuItemsComponents[item], { key: index });
        })
      );
    }
    return menuItemsToShow;
  };

  if (!canEditKbPage) {
    return null;
  }

  if (renderMenuItems().length === 0) {
    return null;
  }

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          variant={"ghost"}
          icon={<BiDotsVerticalRounded size={22} />}
          isLoading={
            cloneKbPageMutation.isLoading || updateKbPageMutation.isLoading
          }
        ></MenuButton>
        <MenuList>{renderMenuItems()}</MenuList>
      </Menu>
      <DeleteSomethingModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        deleteFunction={archivePage}
        isDeleting={updateKbPageMutation.isLoading}
        bodyText={"Deleting a page cannot be undone."}
      />
    </>
  );
};

export default KbPageMenu;
