import { Text } from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";
import { useContext, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SelectField } from "../../../components/fields/SelectField";
import { AccountContext } from "../../../context/AccountContextComponent";

export const TerminationTagIdSelect = ({
  selectedTerminationTagIds,
  setSelectedTerminationTagIds,
}) => {
  const { accountData } = useContext(AccountContext);
  const form = useForm({
    mode: "onChange",
  });

  const terminationTagOptions = useMemo(() => {
    return accountData?.terminationTags?.map((reason) => ({
      label: reason.label,
      value: reason.id,
    }));
  }, [accountData]);

  const hasATenure = selectedTerminationTagIds?.length > 0;

  useEffect(() => {
    // clear out the value if selectedTerminationReasons is empty
    if (!selectedTerminationTagIds || selectedTerminationTagIds?.length === 0) {
      form.setValue("terminationTagId", []);
    }
  }, [selectedTerminationTagIds]);

  return (
    <FormProvider {...form}>
      <form
        key={selectedTerminationTagIds?.length}
        // Prevent form submission on enter key
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        <SelectField
          field={{
            id: "terminationTagId",
            placeholder: "+ Termination Reason Filter",
            isMulti: true,
          }}
          options={terminationTagOptions}
          onSelectionChange={(e) => {
            setSelectedTerminationTagIds(e);
          }}
          isMulti={true}
          isClearable={false}
          selectProps={{
            variant: "unstyled",
            color: "#3182ce",
            components: !hasATenure
              ? {
                  DropdownIndicator: () => null,
                }
              : {
                  Control: ({ children, ...props }) => (
                    <chakraComponents.Control {...props}>
                      {hasATenure ? (
                        <Text fontWeight={"bold"} color={"#3182ce"} px={1}>
                          Termination Reason:
                        </Text>
                      ) : null}
                      {children}
                    </chakraComponents.Control>
                  ),
                },
          }}
          selectStyles={{
            multiValueLabel: (provided) => ({
              ...provided,
              color: "#3182ce",
            }),
            multiValue: (provided) => ({
              ...provided,
              backgroundColor: "#4285F414",
            }),
            multiValueRemove: (provided) => ({
              ...provided,
              color: "#3182ce",
              _hover: {
                color: "#3182ce",
                backgroundColor: "#4285F424",
              },
            }),
            control: (provided) => ({
              ...provided,
              cursor: "pointer",
              padding: "0px 4px 0px 4px",
              borderRadius: "20px",
              // border: "1px solid",
              _hover: {
                bg: "gray.100",
              },
              bg: hasATenure && "#4285F414",
              border: hasATenure && "1px solid #3182ce",
              color: "#3182ce",
              fontSize: "sm",
            }),
          }}
        />
      </form>
    </FormProvider>
  );
};
