import {
  CartesianGrid,
  LabelList,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const CustomLineChart = ({
  data,
  lineProps,
  width = "100%",
  height = 450,
  yAxisDomain,
  yAxisProps,
  tooltipProps,
}: {
  data: any[];
  lineProps: any[];
  width?: string | number;
  height?: number;
  yAxisDomain?: number[];
  yAxisProps?: any;
  tooltipProps?: any;
}) => {
  // Define a formatter for X-axis labels that truncates labels that are too long
  const labelFormatter = (label: string) => {
    if (label.length > 12) {
      return `${label.substring(0, 12)}...`;
    }
    return label;
  };

  // Calculate width for X-axis based on the length of the longest label
  const xAxisWidth = Math.max(
    data.reduce(
      (max, curr) => Math.max(max, Math.min(curr.label?.length, 9)),
      0
    ) * 4,
    50
  );

  // Calculate the domain for the Y-axis dynamically to ensure all data points are visible.
  // This assumes data points are numbers. Adjust as necessary based on your actual data structure.
  const maxY = Math.max(
    ...data.map((entry) =>
      Math.max(...lineProps.map((line) => entry[line.dataKey]))
    )
  );
  const minY = Math.min(
    ...data.map((entry) =>
      Math.min(...lineProps.map((line) => entry[line.dataKey]))
    )
  );

  function round5(x: number) {
    return Math.ceil(x / 5) * 5;
  }

  // You might want to add some padding or a "buffer" so points don't appear exactly on the chart's edge
  const domainPadding = (maxY - minY) * 0.1;
  const cleanedYAxisDomain = yAxisDomain || [
    round5(minY - domainPadding),
    round5(maxY + domainPadding),
  ];

  return (
    <ResponsiveContainer width={width} height={height}>
      <LineChart
        height={450}
        data={data}
        margin={{ top: 24, right: 64, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="label"
          height={xAxisWidth}
          tickFormatter={labelFormatter}
          fontSize={12}
        />
        <YAxis
          allowDecimals={false}
          domain={cleanedYAxisDomain}
          scale={"linear"}
          {...yAxisProps}
        />
        <Tooltip isAnimationActive={false} {...tooltipProps} />
        {lineProps.map((lineProp, index) => (
          <Line
            key={index} // React needs unique keys for iterated elements
            isAnimationActive={false}
            strokeWidth={3}
            activeDot={{ r: 8 }}
            {...lineProp} // Spread the individual line props
          >
            {/* Don't show the label on the point when there is more than 1 line */}
            {lineProps?.length === 1 && (
              <LabelList
                dataKey={lineProp.dataKey}
                position={"insideBottomLeft"}
                fill={lineProp.stroke}
                offset={10}
                {...lineProp.labelListProps}
              />
            )}
          </Line>
        ))}
        <Legend />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomLineChart;
