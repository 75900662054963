import { Box, Icon } from "@chakra-ui/react";
import { MdDragIndicator } from "react-icons/md";
import "./styles.css";

const DragIndicator = ({ id, width, cursor = "grab" }:{
  id: string;
  width: string;
  cursor?: string;
}) => {
  return (
    <Box id={`drag-handle-${id}`} w={width} minW={width} cursor={cursor}>
      <Box className="drag-indicator">
        <Icon
          as={MdDragIndicator}
          id={`drag-handle-${id}`}
          fontSize={"2xl"}
          // _hover={{ cursor: "grab" }}
        />
      </Box>
    </Box>
  );
};

export default DragIndicator;
