export const pricingPlans = {
  Starter: {
    monthlyPrice: 99.95,
    yearlyPrice: 1079.46,
    includedAdmins: 3,
    includedEmployees: 7,
  },
  Professional: {
    monthlyPrice: 149.95,
    yearlyPrice: 1619.46,
    includedAdmins: 5,
    includedEmployees: 20,
    costPerAdditionalAdminMonthly: 10.00,
    costPerAdditionalEmployeeMonthly: 4.95,
    costPerAdditionalAdminYearly: 108.00,
    costPerAdditionalEmployeeYearly: 53.46,
  },
};

export const estimateInvoiceAmount = (
  plan,
  billingPeriod,
  numAdmins,
  numEmployees
) => {
  const selectedPlan = pricingPlans[plan];
  if (!selectedPlan) return 0;

  if (plan === "Starter") {
    return billingPeriod === "MONTHLY"
      ? selectedPlan.monthlyPrice
      : selectedPlan.yearlyPrice;
  } else if (plan === "Professional") {
    let total =
      billingPeriod === "MONTHLY"
        ? selectedPlan.monthlyPrice
        : selectedPlan.yearlyPrice;

    const additionalAdmins =
      numAdmins > selectedPlan.includedAdmins
        ? numAdmins - selectedPlan.includedAdmins
        : 0;
    const additionalEmployees =
      numEmployees > selectedPlan.includedEmployees
        ? numEmployees - selectedPlan.includedEmployees
        : 0;

    if (billingPeriod === "MONTHLY") {
      total += additionalAdmins * selectedPlan.costPerAdditionalAdminMonthly;
      total +=
        additionalEmployees * selectedPlan.costPerAdditionalEmployeeMonthly;
    } else {
      total +=
        additionalAdmins * selectedPlan.costPerAdditionalAdminYearly +
        additionalEmployees * selectedPlan.costPerAdditionalEmployeeYearly;
    }

    return total;
  }
};

export const getNewUsageMessageForCsvUpload = (
  subscriptionPlan,
  currentNumEmployees,
  numEmployeesToAdd
) => {
  const { includedEmployees, maxEmployees, planName } = subscriptionPlan || {};
  const newNumEmployees = currentNumEmployees + numEmployeesToAdd;
  let newPlanName = planName;

  if (numEmployeesToAdd === 0) return "No new employees will be added.";

  let message = `Uploading this csv will add ${numEmployeesToAdd} new employees for a total of ${newNumEmployees} employees.`;

  let isUpgradingToProfessional = false;
  // If being upgraded, tell them the limit they have passed
  // Your current plan allows up to ... you're adding .. you will be upgraded... and we will add X licenses
  if (maxEmployees && newNumEmployees > maxEmployees) {
    message =
      message +
      ` You will be automatically upgraded from the ${planName} to the Professional plan which includes ${pricingPlans.Professional.includedEmployees} 
      employees and ${pricingPlans.Professional.includedAdmins} admins.`;
    newPlanName = "Professional";
    isUpgradingToProfessional = true;
  }

  // For example, if the current plan includes 5 employees and the user is trying to add 10 employees when the account already has 4 employees, then the message should be:
  // Uploading this csv will add 10 users and will automatically add 9 employee license.
  // This is because they get 1 more included employee license.
  // show how many their plan includes to be helpful. You'll now have a total of X employees and Y admins.
  const includedEmployeesOnNewPlan = isUpgradingToProfessional
    ? pricingPlans.Professional.includedEmployees
    : includedEmployees;
  const excessEmployees = currentNumEmployees - includedEmployeesOnNewPlan;
  const newLicensesNeeded = numEmployeesToAdd + Math.min(excessEmployees, 0);

  return newLicensesNeeded > 0
    ? ` ${message} ${newLicensesNeeded} new employee licenses will be added to your account on the ${newPlanName} plan.`
    : message;
};

export const getNewUsageMessageForAddEmployee = (
  subscriptionPlan,
  currentNumEmployees
) => {
  const { includedEmployees, maxEmployees, planName } = subscriptionPlan || {};
  const newNumEmployees = currentNumEmployees + 1;
  let newPlanName = planName;

  let message = `Adding this new employee will bring the account to a total of ${newNumEmployees} employee${
    newNumEmployees > 1 ? "s" : ""
  }.`;

  // If being upgraded, tell them the limit they have passed
  // Your current plan allows up to ... you're adding .. you will be upgraded... and we will add X licenses
  if (maxEmployees && newNumEmployees > maxEmployees) {
    message =
      message +
      ` You will be automatically upgraded from the ${planName} to the Professional plan.`;
    newPlanName = "Professional";
  }

  const excessEmployees = currentNumEmployees - includedEmployees;
  const newLicensesNeeded = 1 + Math.min(excessEmployees, 0);

  return newLicensesNeeded > 0
    ? ` ${message} ${newLicensesNeeded} new employee licenses will be added to your account on the ${newPlanName} plan.`
    : message;
};

export const getNewUsageMessageForAddAdmin = (
  subscriptionPlan,
  currentNumAdmins
) => {
  const { includedAdmins, maxAdmins, planName } = subscriptionPlan || {};
  const newNumAdmins = currentNumAdmins + 1;
  let newPlanName = planName;

  let message = `Adding this new admin will bring the account to a total of ${newNumAdmins} admin${
    newNumAdmins > 1 ? "s" : ""
  }.`;

  if (maxAdmins && newNumAdmins > maxAdmins) {
    message =
      message +
      ` You will be automatically upgraded from the ${planName} to the Professional plan.`;
    newPlanName = "Professional";
  }

  const excessAdmins = currentNumAdmins - includedAdmins;
  const newLicensesNeeded = 1 + Math.min(excessAdmins, 0);

  return newLicensesNeeded > 0
    ? ` ${message} ${newLicensesNeeded} new admin licenses will be added to your account on the ${newPlanName} plan.`
    : message;
};
