import { useEffect, useRef, useState } from "react";
import { useConfirmSetupIntent } from "../../api/billing/billing";
import useToastMessage from "../../hooks/useToastMessage";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import LoadingBox from "../../components/common/LoadingBox";
import { useQueryClient } from "@tanstack/react-query";

const BillingRedirectCompanyInfo = () => {
  // Call the backend to confirm setup intent
  // then, redirect to subscription page
  const confirmSetupIntentMutation = useConfirmSetupIntent();
  const { showErrorToast } = useToastMessage();
  const [respData, setRespData] = useState(null);
  const { accountId } = useAccountId();
  const navigate = useCustomDomainNavigate();
  const didRun = useRef(false);
  const queryClient = useQueryClient();

  const confirmSetupIntent = async () => {
    try {
      const respData = await confirmSetupIntentMutation.mutateAsync({
        accountId,
      });
      console.log(respData);
      setRespData(respData);
    } catch (error) {
      console.log(error);
      showErrorToast({
        message:
          "Something went wrong. Please contact support@thebestanswer.com",
      });
    }
  };

  useEffect(() => {
    if (!didRun.current) {
      confirmSetupIntent();
      didRun.current = true;
    }
  }, []);

  if (!respData) {
    return <LoadingBox />;
  } else if (respData.status === "succeeded") {
    // Wait 5 seconds for the backed to get the webhook
    setTimeout(() => {
      queryClient.invalidateQueries(["fetchAccount", accountId]);
      navigate(`/${accountId}/company-information?tab=Billing`);
    }, 5000);
    return <LoadingBox />;
  } else if (
    respData.status === "canceled" ||
    respData.status === "requires_payment_method"
  ) {
    console.log(
      "canceled or requires_payment_method, redirecting to billing page"
    );
    navigate(`/${accountId}/setup/subscriptions`);
  } else {
    return (
      <p>Something went wrong. Please contact support@thebestanswer.com</p>
    );
  }
};

export default BillingRedirectCompanyInfo;
