import axios from "axios";
import { auth } from "../auth/firebase";

const instance = axios.create({
  baseURL: import.meta.env.VITE_BASE_API_URL,
});

instance.interceptors.request.use(async (config) => {
  const userToken = await auth.currentUser?.getIdToken();
  config.headers["Authorization"] = "Bearer " + userToken;

  return config;
});

export default instance;
