import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import {
  AiOutlineMail,
  AiOutlineUserAdd,
  AiOutlineUsergroupAdd,
} from "react-icons/ai";
import { BiArchive, BiDotsVerticalRounded, BiEdit } from "react-icons/bi";
import {
  useSendInviteEmail,
  useUpdateProfile,
} from "../../api/profiles/profiles";
import { AvatarWithName } from "../../components/common/AvatarWithName";
import CustomTable from "../../components/table/CustomTableV2";
import useAccountId from "../../hooks/customDomainHooks";
import useToastMessage from "../../hooks/useToastMessage";
import { ROLE_LABELS } from "../../types/common";
import { AddEmployeeModal } from "./modals/AddEmployeeModal";
import { ArchiveEmployeeModal } from "./modals/ArchiveEmployeeModal";
import { EditEmployeeModal } from "./modals/EditEmployeeModal";
import { ImportEmployeesModal } from "./modals/ImportEmployeesModal";

const ViewEmployees = ({ members, allowEdit }) => {
  const [isImportEmployeesModalOpen, setImportEmployeesModalOpen] =
    useState(false);
  const [isAddEmployeeModalOpen, setAddEmployeeModalOpen] = useState(false);
  const [isEditEmployeeModalOpen, setIsEditEmployeeModalOpen] = useState(false);
  const [isArchiveEmployeeModalOpen, setIsArchiveEmployeeModalOpen] =
    useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showArchived, setShowArchived] = useState(false);
  const [selectedRows, setSelectedRows] = useState(new Set([]));
  const updateProfileMutation = useUpdateProfile();
  const sendInviteEmailMutation = useSendInviteEmail();
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();

  const unarchiveMember = async (profileId) => {
    try {
      await updateProfileMutation.mutateAsync({
        accountId,
        data: { isActive: true },
        profileId,
      });
      queryClient.invalidateQueries(["fetchAccount", accountId]);
      showSuccessToast(
        "Member archived",
        "The member has been successfully unarchived."
      );
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error unarchiving member" });
    }
  };

  const sendInviteEmails = async (emails) => {
    try {
      await Promise.all(
        emails.map((email) =>
          sendInviteEmailMutation.mutateAsync({
            accountId,
            data: { email },
          })
        )
      );
      queryClient.invalidateQueries(["fetchAccount", accountId]);
      setSelectedRows(new Set([]));
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error sending invite email" });
    }
  };

  const editAndViewColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell }) => {
          const member = cell.row.original;
          return <AvatarWithName profile={member} />;
        },
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Reports to",
        accessor: "reportsTo.name",
      },
      {
        Header: "Department",
        accessor: "department.name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: ({ cell }) => {
          const member = cell.row.original;
          return <Text colorScheme="blue">{ROLE_LABELS[member.role]}</Text>;
        },
      },
      {
        Header: "Status",
        accessor: (row) => {
          if (!row.isActive) return "Archived";
          if (row.userId && row.invitedAt) return "Active";
          if (row.invitedAt) return "Invited";
          return "Not Invited";
        },
        Cell: ({ cell }) => {
          const member = cell.row.original;
          if (!member.isActive) {
            return <Tag colorScheme="red">Archived</Tag>;
          } else if (member.userId) {
            return <Tag colorScheme="green">Active</Tag>;
          } else if (member.invitedAt) {
            return <Tag colorScheme="blue">Invited</Tag>;
          } else {
            return (
              <Tag colorScheme="gray" w={"92px"}>
                Not Invited
              </Tag>
            );
          }
        },
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ cell }) => (
          <Box textAlign={"right"}>
            <Menu>
              <MenuButton
                as={IconButton}
                variant="outline"
                icon={<BiDotsVerticalRounded size={22} />}
              ></MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    setSelectedMember(cell.row.original);
                    setIsEditEmployeeModalOpen(true);
                  }}
                  icon={
                    <Center>
                      <Icon as={BiEdit} fontSize={"xl"} />
                    </Center>
                  }
                >
                  Edit
                </MenuItem>
                {cell.row.original.isActive ? (
                  <MenuItem
                    onClick={() => {
                      setSelectedMember(cell.row.original);
                      setIsArchiveEmployeeModalOpen(true);
                    }}
                    icon={
                      <Center>
                        <Icon as={BiArchive} fontSize={"xl"} />
                      </Center>
                    }
                    color={"red.500"}
                  >
                    Archive
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => {
                      unarchiveMember(cell.row.original.id);
                    }}
                    icon={
                      <Center>
                        <Icon as={BiArchive} fontSize={"xl"} />
                      </Center>
                    }
                    color={"red.500"}
                  >
                    Unarchive
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Box>
        ),
      },
    ],
    []
  );

  const viewOnlyColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell }) => {
          const member = cell.row.original;
          return <AvatarWithName profile={member} />;
        },
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Reports to",
        accessor: "reportsTo.name",
      },
      {
        Header: "Department",
        accessor: "department.name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
    ],
    []
  );

  const inviteButton = (
    <VStack alignItems={"flex-end"}>
      <Stack direction={{ base: "column", md: "row" }}>
        <Button
          w={"180px"}
          leftIcon={<AiOutlineMail />}
          onClick={() => {
            let emailsToSend = [];
            selectedRows.forEach((id) => {
              emailsToSend.push(
                members.find((member) => member.id === id).email
              );
            });
            sendInviteEmails(emailsToSend);
          }}
          isDisabled={selectedRows.size === 0}
          isLoading={sendInviteEmailMutation.isLoading}
        >
          Send Invite{selectedRows.size > 1 && "s"}{" "}
          {selectedRows.size !== 0 && `(${selectedRows.size})`}
        </Button>
        <Button
          w={"180px"}
          leftIcon={<AiOutlineUserAdd />}
          onClick={() => setAddEmployeeModalOpen(true)}
        >
          Add Employee
        </Button>
        <Button
          w={"180px"}
          leftIcon={<AiOutlineUsergroupAdd />}
          onClick={() => setImportEmployeesModalOpen(true)}
        >
          Import Employees
        </Button>
      </Stack>
      <FormControl
        display="flex"
        alignItems="center"
        gap={2}
        justifyContent={"flex-end"}
      >
        <Switch
          isChecked={showArchived}
          onChange={(e) => {
            setShowArchived(e.target.checked);
          }}
          id="showArchived"
        ></Switch>
        <FormLabel htmlFor="showArchived" mb={0} fontWeight={400}>
          Show Archived
        </FormLabel>
      </FormControl>
    </VStack>
  );

  const membersToShow = useMemo(() => {
    return members.filter((member) => {
      if (showArchived) {
        return !member.isActive;
      } else {
        return member.isActive;
      }
    });
  }, [members, showArchived]);

  return (
    <Box>
      <ImportEmployeesModal
        isOpen={isImportEmployeesModalOpen}
        setIsOpen={setImportEmployeesModalOpen}
      />
      <AddEmployeeModal
        isOpen={isAddEmployeeModalOpen}
        setIsOpen={setAddEmployeeModalOpen}
      />
      <EditEmployeeModal
        isOpen={isEditEmployeeModalOpen}
        setIsOpen={setIsEditEmployeeModalOpen}
        member={selectedMember}
      />
      <ArchiveEmployeeModal
        isOpen={isArchiveEmployeeModalOpen}
        setIsOpen={setIsArchiveEmployeeModalOpen}
        member={selectedMember}
      />
      <Box>
        <CustomTable
          selectionType={"checkbox"}
          doUsePagination={true}
          columns={allowEdit ? editAndViewColumns : viewOnlyColumns}
          data={membersToShow}
          rightElement={allowEdit && inviteButton}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          showCheckboxColumn={allowEdit}
          boxStyle={{
            bg: "white",
            borderRadius: "10px",
            border: "1px",
            borderColor: "gray.200",
            p: 4,
            overflow: "auto",
          }}
          tableStyle={{
            size: "sm",
          }}
          tableId={"viewEmployeesTable"}
        />
      </Box>
    </Box>
  );
};

export default ViewEmployees;
