import { Text } from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";
import { useContext, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SelectField } from "../../../components/fields/SelectField";
import { AccountContext } from "../../../context/AccountContextComponent";

export const DepartmentSelect = ({
  selectedDepartments,
  setSelectedDepartments,
}) => {
  const { accountData } = useContext(AccountContext);
  const form = useForm({
    mode: "onChange",
    defaultValues: {
      department: "",
    },
  });

  useEffect(() => {
    // clear out the value if selectedDepartments is empty
    if (!selectedDepartments || selectedDepartments?.length === 0) {
      form.setValue("department", []);
    }
  }, [selectedDepartments, form]);

  const departmentOptions = useMemo(() => {
    return accountData.departments?.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [accountData.departments]);

  const hasADepartment = selectedDepartments?.length > 0;

  return (
    <FormProvider {...form}>
      <form
        key={selectedDepartments?.length}
        // Prevent form submission on enter key
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        <SelectField
          field={{
            id: "department",
            placeholder: "+ Department Filter",
            isMulti: true,
          }}
          options={departmentOptions}
          onSelectionChange={(e) => {
            console.log(e);
            setSelectedDepartments(e);
          }}
          isMulti={true}
          isClearable={false}
          selectProps={{
            variant: "unstyled",
            color: "#3182ce",
            components: !hasADepartment
              ? {
                  DropdownIndicator: () => null,
                }
              : {
                  Control: ({ children, ...props }) => (
                    <chakraComponents.Control {...props}>
                      {hasADepartment ? (
                        <Text fontWeight={"bold"} color={"#3182ce"} px={1}>
                          Department:
                        </Text>
                      ) : null}
                      {children}
                    </chakraComponents.Control>
                  ),
                },
          }}
          selectStyles={{
            multiValueLabel: (provided) => ({
              ...provided,
              color: "#3182ce",
            }),
            multiValue: (provided) => ({
              ...provided,
              backgroundColor: "#4285F414",
            }),
            multiValueRemove: (provided) => ({
              ...provided,
              color: "#3182ce",
              _hover: {
                color: "#3182ce",
                backgroundColor: "#4285F424",
              },
            }),
            control: (provided) => ({
              ...provided,
              cursor: "pointer",
              padding: "0px 4px 0px 4px",
              borderRadius: "20px",
              // border: "1px solid",
              _hover: {
                bg: "gray.100",
              },
              bg: hasADepartment && "#4285F414",
              border: hasADepartment && "1px solid #3182ce",
              color: "#3182ce",
              fontSize: "sm",
            }),
          }}
        />
      </form>
    </FormProvider>
  );
};
