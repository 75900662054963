import { Box, Button, HStack, Progress, Text, VStack } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import { FormattedNumber } from "react-intl";
import InfoBox from "../../components/common/PageInfo/InfoBox";
import CustomTable from "../../components/table/CustomTableV2";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../hooks/customDomainHooks";
import PageShell from "../../layout/PageShell";
const CultureBudgetPage = () => {
  const { accountId } = useAccountId();
  const [pageInfoEditMode, setPageInfoEditMode] = useState(false);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Culture Budget" },
  ]);

  const mockData = [
    {
      id: 1,
      title: "Food & Beverage",
      amount: 12400,
      balance: 6400,
      timeFrame: "2023",
    },
    {
      id: 2,
      title: "Happy Hours",
      amount: 24000,
      balance: 1800,
      timeFrame: "2023",
    },
    {
      id: 3,
      title: "Team Building Events",
      amount: 50000,
      balance: 30000,
      timeFrame: "2023",
    },
    {
      id: 4,
      title: "Professional Development",
      amount: 30000,
      balance: 6000,
      timeFrame: "2023",
    },
    {
      id: 5,
      title: "Community Services",
      amount: 5000,
      balance: 2000,
      timeFrame: "2023",
    },
  ];
  const budgetColumns = useMemo(
    () => [
      {
        accessor: "title",
        Header: "Expense Title",
        Cell: ({ row }) => {
          return (
            <VStack alignItems={"flex-start"}>
              <Text>{row.original.title}</Text>
              <Text color={"textSecondary"}>
                <FormattedNumber
                  value={row.original.amount}
                  style="currency"
                  currency="USD"
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </Text>
            </VStack>
          );
        },
      },
      {
        accessor: "balance",
        Header: "Balance",
        Cell: ({ value }) => {
          return (
            <VStack alignItems={"flex-start"}>
              {/* <Text>Balance</Text> */}
              <Text color={"textSecondary"}>
                <FormattedNumber
                  value={value}
                  style="currency"
                  currency="USD"
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </Text>
            </VStack>
          );
        },
      },
      {
        id: "used",
        Header: "% Used",
        Cell: ({ row }) => {
          return (
            <VStack alignItems={"flex-start"}>
              {/* <Text>% Used</Text> */}
              <Progress
                borderRadius={"md"}
                w={"100px"}
                colorScheme="green"
                value={row.original.balance / row.original.amount}
                max={1}
              />
              <Text color={"textSecondary"}>
                {Math.round(
                  (row.original.balance / row.original.amount) * 100
                ).toFixed(0)}
                %
              </Text>
            </VStack>
          );
        },
      },
      {
        accessor: "timeFrame",
        Header: "Time Frame",
        Cell: ({ value }) => {
          return (
            <VStack alignItems={"flex-start"}>
              {/* <Text>Time Frame</Text> */}
              <Text color={"textSecondary"}>{value}</Text>
            </VStack>
          );
        },
      },
    ],
    [mockData]
  );

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      menuItems={[
        {
          type: "CREATE_PAGE_INFO",
          pageType: "CULTURE_BUDGET",
        },
      ]}
      setPageInfoEditMode={setPageInfoEditMode}
    >
      <InfoBox
        pageInfoType={"CULTURE_BUDGET"}
        setPageInfoEditMode={setPageInfoEditMode}
        pageInfoEditMode={pageInfoEditMode}
      />

      <Box>
        <CustomTable
          rightElement={
            <HStack spacing={4}>
              <Button size="sm" rightIcon={<IoAddOutline />}>
                Add Expense
              </Button>
              <Button size="sm" rightIcon={<IoAddOutline />}>
                New Category
              </Button>
            </HStack>
          }
          columns={budgetColumns}
          data={mockData}
        />
      </Box>
    </PageShell>
  );
};

export default CultureBudgetPage;
