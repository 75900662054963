import {
  Box,
  Center,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useCreatePageInfo } from "../api/pageInfo/pageInfo";
import PageBreadcrumbs from "../components/common/PageBreadcrumbs";
import { AccountContext } from "../context/AccountContextComponent";
import useAccountId from "../hooks/customDomainHooks";
import useToastMessage from "../hooks/useToastMessage";
import Wizards from "./Wizards/Wizards";

const Header = ({
  headerComponent,
  headerComponentRight,
  breadcrumbs,
  menuItems,
  setPageInfoEditMode,
}: {
  headerComponent: React.ReactNode;
  headerComponentRight: React.ReactNode;
  breadcrumbs: {
    name: string;
    href?: string | undefined;
    type?: string | undefined;
  }[];
  menuItems: any;
  setPageInfoEditMode: any;
}) => {
  return (
    <Stack
      justifyContent={"space-between"}
      alignItems={"center"}
      pb={4}
      direction={"row"}
      //direction={{ base: "column", md: "row" }}
    >
      <HStack spacing={0} alignSelf={"flex-start"}>
        <PageBreadcrumbs breadcrumbs={breadcrumbs} />
        {headerComponent}
      </HStack>
      <Stack
        direction={{ base: "column-reverse", sm: "row" }}
        alignItems={"flex-end"}
      >
        <Center h={"100%"}>
          <Box alignSelf={"flex-end"}>{headerComponentRight}</Box>
        </Center>
        {menuItems && (
          <PageMenu
            menuItems={menuItems}
            setPageInfoEditMode={setPageInfoEditMode}
          />
        )}
      </Stack>
    </Stack>
  );
};

const PageShell = ({
  children,
  breadcrumbs,
  headerComponent,
  headerComponentRight,
  menuItems,
  setPageInfoEditMode,
  id,
  maxWidth,
}: {
  children: React.ReactNode;
  breadcrumbs: any;
  headerComponent: React.ReactNode;
  headerComponentRight: any;
  menuItems: any;
  setPageInfoEditMode: any;
  id: any;
  maxWidth: any;
}) => {
  return (
    <Box position={"relative"} id={id}>
      <Wizards />
      <Box
        pt={4}
        px={4}
        w={"100%"}
        h={"100%"}
        justifyContent={"flex-start"}
        display={"flex"}
        mb={"50px"}
      >
        <Box maxW={maxWidth || "1600px"} w={"100%"} h={"100%"}>
          <Header
            headerComponent={headerComponent}
            breadcrumbs={breadcrumbs}
            headerComponentRight={headerComponentRight}
            menuItems={menuItems}
            setPageInfoEditMode={setPageInfoEditMode}
          />
          {children}
        </Box>
      </Box>
    </Box>
  );
};

const PageMenu = ({
  menuItems,
  setPageInfoEditMode,
}: {
  menuItems: any;
  setPageInfoEditMode: any;
}) => {
  const { showErrorToast } = useToastMessage();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const createPageInfoMutation = useCreatePageInfo();
  const {
    accountData,
    isLoading: isAccountDataLoading,
    currentProfile,
  } = useContext(AccountContext);

  const [menuItemsToShow, setMenuItemsToShow] = useState([]);

  useEffect(() => {
    // If the user is not an owner or admin,
    // or if the account already has a page info of that type,
    // remove the create page info menu item
    if (!isAccountDataLoading) {
      let filteredMenuItems = menuItems;
      if (
        !["OWNER", "ADMIN", "PARTNER"].includes(currentProfile?.role || "") ||
        menuItems.some(
          (menuItem: any) =>
            menuItem.type === "CREATE_PAGE_INFO" &&
            accountData?.pageInfos?.some(
              (pageInfo) => pageInfo.pageType === menuItem.pageType
            )
        )
      ) {
        filteredMenuItems = menuItems.filter(
          (menuItem: any) => menuItem.type !== "CREATE_PAGE_INFO"
        );
      }
      setMenuItemsToShow(filteredMenuItems);
    }
  }, [accountData, currentProfile, menuItems, isAccountDataLoading]);

  if (isAccountDataLoading) {
    return null;
  }

  const createPageInfo = async (pageType: string) => {
    try {
      await createPageInfoMutation.mutateAsync({
        accountId: accountId || "",
        data: { pageType },
      });
      setPageInfoEditMode(true);
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating page info" });
    }
  };

  const renderMenuItem = (menuItem: any) => {
    if (menuItem.type === "CREATE_PAGE_INFO") {
      return (
        <MenuItem
          key={menuItem.type}
          onClick={() => createPageInfo(menuItem.pageType)}
        >
          Add page header
        </MenuItem>
      );
    }
  };

  return (
    <>
      {menuItemsToShow?.length !== 0 && (
        <Box>
          <Menu>
            {() => (
              <>
                <MenuButton
                  isLoading={createPageInfoMutation.isLoading}
                  as={IconButton}
                  variant={"ghost"}
                  icon={<BiDotsVerticalRounded size={22} />}
                />
                <MenuList>
                  {menuItemsToShow.map((menuItem: any) =>
                    renderMenuItem(menuItem)
                  )}
                </MenuList>
              </>
            )}
          </Menu>
        </Box>
      )}
    </>
  );
};

export default PageShell;
