import { useState, useCallback, useRef } from "react";
import { AiTask } from "../../../api/ai/ai";
import { auth } from "../../../auth/firebase";
import useToastMessage from "../../../hooks/useToastMessage";
import { Editor } from "@tiptap/core";
import { Range } from "@tiptap/react";
import useAccountId from "../../../hooks/customDomainHooks";

interface KeyResult {
  result: string;
  type: "NUMBER" | "PERCENT" | "DONE_NOT_DONE" | "DATE";
  goal: string | number;
}

export interface ObjectiveCompletion {
  objective: string;
  keyResults: KeyResult[];
}

interface CompletionProps {
  context?: string;
  editor: Editor;
  previewEditor?: Editor;
  insertDirectlyIntoEditor?: boolean;
  onComplete?: (text: string | object, task: AiTask) => void;
}

const useAICompletion = ({
  context,
  editor,
  onComplete,
  previewEditor,
  insertDirectlyIntoEditor,
}: CompletionProps) => {
  const { accountId } = useAccountId();

  const [isLoading, setIsLoading] = useState(false);
  const { showErrorToast } = useToastMessage();
  const prev = useRef<string>("");
  const completionRef = useRef<string>("");

  const addTextToPreview = useCallback(
    (task: AiTask, aiResponse: string | object) => {
      if (previewEditor && aiResponse) {
        if (task === AiTask.AUTHOR_OKR || task === AiTask.EDIT_OKR) {
          const objectiveCompletion = aiResponse as string;
          previewEditor?.commands.setContent(objectiveCompletion, true);
        } else {
          previewEditor?.commands.insertContent(aiResponse as string);
        }
      }
    },
    [previewEditor]
  );

  const complete = useCallback(
    async ({
      task,
      rangeToDelete,
      selectedText,
      contextBefore,
      contextAfter,
      instructions,
    }: {
      task: AiTask;
      rangeToDelete: Range;
      selectedText?: string;
      contextBefore?: string;
      contextAfter?: string;
      instructions?: string;
    }) => {
      setIsLoading(true);
      completionRef.current = "";
      try {
        const url = `${import.meta.env.VITE_BASE_API_URL}/${accountId}/ai`;
        const userToken = await auth.currentUser?.getIdToken();
        const includeAdditionalContext =
          task !== AiTask.AUTHOR_OKR &&
          task !== AiTask.EDIT_OKR &&
          task !== AiTask.PARSE_OKR;

        const doStream = false;

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            task,
            selectedText,
            contextBefore,
            contextAfter,
            additionalContext: includeAdditionalContext ? context : "",
            instructions,
          }),
        });

        if (!doStream) {
          const data = await response.json();
          const newText = data.completion;
          completionRef.current = newText;
          if (task === AiTask.PARSE_OKR) {
            return;
          }
          if (previewEditor) {
            addTextToPreview(task, newText);
          } else if (insertDirectlyIntoEditor) {
            editor.chain().focus().deleteRange(rangeToDelete).run();

            editor.commands.insertContent(newText);
          }
        } else {
          const reader = response.body?.getReader();
          if (reader) {
            let readResult = await reader.read();
            if (insertDirectlyIntoEditor)
              editor.chain().focus().deleteRange(rangeToDelete).run();

            while (!readResult.done) {
              const text = new TextDecoder().decode(readResult.value, {
                stream: true,
              });
              completionRef.current += text;
              const diff = completionRef.current.slice(prev.current.length);
              prev.current = completionRef.current;

              if (previewEditor) {
                addTextToPreview(task, diff);
              } else if (insertDirectlyIntoEditor) {
                editor.commands.insertContent(diff);
              }
              readResult = await reader.read();
            }
          }
        }
      } catch (error) {
        console.error(error);
        showErrorToast({ message: "Error creating AI completion." });
        completionRef.current = "";
      } finally {
        if (insertDirectlyIntoEditor) {
          const completedTextRange: Range = {
            from: rangeToDelete.from,
            to: rangeToDelete.from + completionRef.current.length,
          };
          editor.chain().focus().setTextSelection(completedTextRange).run();
        }
        setIsLoading(false);
        onComplete?.(completionRef.current, task);
        completionRef.current = "";
      }
    },
    [
      accountId,
      context,
      showErrorToast,
      onComplete,
      editor,
      insertDirectlyIntoEditor,
      addTextToPreview,
      previewEditor,
    ]
  );

  return { isLoading, complete };
};

export default useAICompletion;
