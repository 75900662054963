import { Box, Text } from "@chakra-ui/react";
import { matchPath, useLocation } from "react-router-dom";
import { NavItem } from "../NavItem";

export type SidebarItemType = {
  type: "header" | "nav" | "externalLink" | "divider";
  label: string;
  href?: string;
  startElement?: React.ReactNode;
  mainElement?: React.ReactNode;
  endElement?: React.ReactNode;
  showEndElementOnHover?: boolean;
  show: boolean;
  id?: string;
  headerComponentRight?: React.ReactNode;
  childItems?: SidebarItemType[];
};

export const SidebarItem = ({
  sidebarItem,
}: {
  sidebarItem: SidebarItemType;
}) => {
  const location = useLocation();

  const pathMatch = (href: string) => {
    if (!href) return false;
    // remove query params
    href = href.split("?")[0];

    // accountId may be in the path, so we need to check for that
    return (
      !!matchPath(
        {
          path: "/:accountId/" + href,
          end: false,
        },
        location.pathname
      ) ||
      !!matchPath(
        {
          path: "/" + href,
          end: false,
        },
        location.pathname
      )
    );
  };

  return (
    <>
      {sidebarItem.type === "header" && sidebarItem.show && (
        <Text
          fontWeight="700"
          textTransform="uppercase"
          color="primary"
          letterSpacing={1.6}
          fontSize={"8px"}
          pl={4}
          pb={2}
        >
          {sidebarItem.label}
        </Text>
      )}
      {sidebarItem.type === "nav" && sidebarItem.show && (
        <NavItem
          active={pathMatch(sidebarItem.href || "")}
          startElement={sidebarItem.startElement}
          label={sidebarItem.label}
          href={sidebarItem.href}
          id={sidebarItem.id || ""}
        />
      )}
      {sidebarItem.type === "externalLink" && sidebarItem.show && (
        <NavItem
          startElement={sidebarItem.startElement}
          label={sidebarItem.label}
          externalLink={sidebarItem.href}
        />
      )}
      {sidebarItem.type === "divider" && sidebarItem.show && (
        <Box paddingY={4}>
          <Box borderTop={"1px"} color={"gray.200"} />
        </Box>
      )}
    </>
  );
};
