import {
  UseMutationOptions,
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { NotificationSettings } from "./types";

export const useGetMyNotificationSettings = (
  accountId: string
): UseQueryResult<NotificationSettings, unknown> => {
  return useQuery<NotificationSettings>({
    queryKey: ["getMyNotificationSettings", accountId],
    queryFn: async () => {
      const response = await api.get(`${accountId}/notificationSettings`);
      return response.data;
    },
  });
};

export const useUpdateMyNotificationSettings = (
  props?: UseMutationOptions<
    any,
    unknown,
    { accountId: string; data: any },
    unknown
  >
): UseMutationResult<
  NotificationSettings,
  unknown,
  { accountId: string; data: Partial<NotificationSettings> },
  unknown
> => {
  return useMutation({
    mutationKey: ["updateMyNotificationSettings"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.put(`${accountId}/notificationSettings`, data);
      return response.data;
    },
    ...props,
  });
};
