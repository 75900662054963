import { createContext, useEffect, useState } from "react";
import { AiContext } from "../components/common/Editor/TipTapEditor";

export enum AiContextTask {
  OKR_OBJECTIVE_TITLE = "OKR_OBJECTIVE_TITLE",
}

export const TipTapAiContext = createContext({
  context: {
    context: "",
  } as AiContext,
  contextTask: undefined as AiContextTask | undefined,
  setContext: null as any,
  openAiMenu: false,
  setOpenAiMenu: null as any,
  openOKRAiMenu: { isOpen: false, autofocus: false },
  setOpenOKRAiMenu: null as unknown as React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      autofocus: boolean;
    }>
  >,
  isOneLine: false,
  setKey: null as any,
  key: 0,
  useAi: true,
});

export const TipTapAiContextComponent = (props: {
  children: React.ReactNode;
  aiContext?: AiContext;
  contextTask?: AiContextTask;
  isOneLine: boolean;
  editor?: any;
  useAi: boolean;
}) => {
  const { children, aiContext, isOneLine, contextTask, editor, useAi } = props;
  const [openAiMenu, setOpenAiMenu] = useState(false);
  const [openOKRAiMenu, setOpenOKRAiMenu] = useState({
    isOpen: false,
    autofocus: false,
  });
  const [key, setKey] = useState<number>(0);

  const [context, setContext] = useState(aiContext || { context: "" });

  useEffect(() => {
    if (aiContext) {
      setContext(aiContext);
      // Adding the context directly to the editorProps so that it's accessible by the slash command
      editor?.setOptions({
        editorProps: {
          attributes: {
            useAi,
            aiContext: aiContext,
            setContext: setContext,
            openAiMenu: openAiMenu,
            setOpenAiMenu: setOpenAiMenu,
            openOKRAiMenu: openOKRAiMenu,
            setOpenOKRAiMenu: setOpenOKRAiMenu,
          },
        },
      });
    }
  }, [aiContext, editor, openAiMenu, openOKRAiMenu, useAi]);

  return (
    <TipTapAiContext.Provider
      value={{
        context,
        setContext,
        openAiMenu,
        setOpenAiMenu,
        openOKRAiMenu,
        setOpenOKRAiMenu,
        isOneLine,
        contextTask,
        setKey,
        key,
        useAi,
      }}
    >
      {children}
    </TipTapAiContext.Provider>
  );
};
