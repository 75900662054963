import { Box, Text, VStack } from "@chakra-ui/react";
import BarGraph from "../../../components/common/charts/BarGraph";
import { useContext } from "react";
import { AccountContext } from "../../../context/AccountContextComponent";

export const ByTenureChart = ({ allEmployeeTenureData, timePeriod }) => {
  const { aRankLabel, bRankLabel, cRankLabel } = useContext(AccountContext);

  // We will first convert the received data format into a more usable structure
  const rankingData = {};

  allEmployeeTenureData.forEach((tenureInfo) => {
    const tenureLabel = tenureInfo.label;

    // For each ranking in the tenureInfo, we create an entry in rankingData
    [aRankLabel, bRankLabel, cRankLabel, "Unranked"].forEach((ranking) => {
      if (!rankingData[ranking]) {
        rankingData[ranking] = {
          ranking: ranking,
        };
      }

      rankingData[ranking][tenureLabel] = tenureInfo[ranking];
    });
  });

  // Convert the modified map to the list format suitable for the graph
  const data = Object.values(rankingData);

  return (
    <VStack>
      <Box
        overflow={"auto"}
        bg={"white"}
        w={"100%"}
        p={4}
        borderRadius={"md"}
        border={"1px "}
        borderColor={"gray.200"}
        maxW={"900px"}
        minW={"900px"}
      >
        <Box justifyContent={"center"}>
          <Text textAlign={"center"}>
            Team Alignment by Tenure for {timePeriod.label}
          </Text>
        </Box>
        {data && (
          <BarGraph
            data={allEmployeeTenureData || []}
            // colors={colors}
            displayPercentages={false}
            xAxisProps={{
              tick: {
                fontSize: "12px",
              },
            }}
          />
        )}
      </Box>
    </VStack>
  );
};
