import {
  Box,
  Button,
  Center,
  HStack,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { createRef, useContext, useEffect, useRef, useState } from "react";
import { FormattedDate } from "react-intl";
import {
  useFetchMySurveyRunResponse,
  useUpdateSurveyRunResponse,
} from "../../../api/survey/surveyRunResponse";
import { AccountContext } from "../../../context/AccountContextComponent";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import SurveyLayout from "./SurveyLayout";
import SurveyQuestion from "./SurveyQuestion";

const TakeShortSurvey = ({ surveyRun }) => {
  const [formErrors, setFormErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const { accountId } = useAccountId();
  const { currentProfile } = useContext(AccountContext);
  const { showErrorToast } = useToastMessage();
  const updateSurveyRunResponseMutation = useUpdateSurveyRunResponse();
  const queryClient = useQueryClient();

  // When the user views this page with the short survey, get/create the
  // survey run response. This will track that they viewed the survey.
  const { data: surveyRunResponse, isLoading: isLoadingSurveyRunResponse } =
    useFetchMySurveyRunResponse(accountId, surveyRun.id, currentProfile.id);

  useEffect(() => {
    const newIsValid = Object.values(formErrors).every(
      (questionErrors) => Object.keys(questionErrors).length === 0
    );
    setIsFormValid(newIsValid);
  }, [formErrors]);

  const submitSurveyRunResponse = async () => {
    try {
      await updateSurveyRunResponseMutation.mutateAsync({
        accountId,
        surveyRunResponseId: surveyRunResponse.id,
        data: {
          isSubmitted: true,
        },
      });
      queryClient.invalidateQueries(["fetchMySurveyRuns", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error submitting survey response" });
    }
  };

  // Create a refs array for each SurveyQuestion
  const questionRefs = useRef(surveyRun.questions.map(() => createRef()));

  const validateAllQuestions = async () => {
    // Create an array to store updated formErrors
    const updatedFormErrors = { ...formErrors };

    // Create an array of promises to store all validation triggers
    const validationPromises = questionRefs.current.map(
      async (questionRef, index) => {
        // Return the promise from trigger() on each question form
        const isValid = await questionRef.current.trigger();
        const questionId = surveyRun.questions[index].id;

        if (isValid) {
          updatedFormErrors[questionId] = {};
        } else {
          updatedFormErrors[questionId] = { value: { message: "Error" } };
        }
      }
    );

    // Wait for all validations to finish
    await Promise.all(validationPromises);

    return updatedFormErrors;
  };

  const onSubmit = async () => {
    try {
      const updatedFormErrors = await validateAllQuestions();
      const newIsValid = Object.values(updatedFormErrors).every(
        (questionErrors) => Object.keys(questionErrors).length === 0
      );

      if (newIsValid) {
        submitSurveyRunResponse();
      } else {
        console.log("Some questions have errors");
      }
    } catch (error) {
      console.error("Error validating questions:", error);
    }
  };

  if (isLoadingSurveyRunResponse) {
    return (
      <Center w={"100%"}>
        <Box p={4} maxW={"1000px"} w={"100%"} h={"200px"}>
          <Spinner />
        </Box>
      </Center>
    );
  }
  return (
    <Center w={"100%"}>
      <Box p={4} maxW={"1000px"} w={"100%"}>
        <VStack alignItems={"flex-start"} spacing={4}>
          {surveyRun.questions.map((question, index) => (
            <Box key={question.id} w={"100%"}>
              {!["HEADER", "PARAGRAPH"].includes(question.element.type) ? (
                <SurveyQuestion
                  question={question}
                  questionIndex={index}
                  response={
                    surveyRunResponse.responses?.find((questionResponse) => {
                      return questionResponse.questionId === question.id;
                    })?.response || {}
                  }
                  key={question.id}
                  surveyRunResponseId={surveyRunResponse.id}
                  surveyRunId={surveyRun.id}
                  setFormErrors={setFormErrors}
                  ref={questionRefs.current[index]} // Pass the ref to each SurveyQuestion
                />
              ) : (
                <SurveyLayout question={question} />
              )}
            </Box>
          ))}
          <HStack w={"100%"} justifyContent={"space-between"}>
            {surveyRun.dueDate ? (
              <Text fontSize={"sm"} color={"textSecondary"}>
                Due: {` `}
                <FormattedDate
                  value={surveyRun.dueDate}
                  year="numeric"
                  month="short"
                  day="2-digit"
                />
              </Text>
            ) : (
              <Box />
            )}
            <Button
              isDisabled={!isFormValid}
              onClick={onSubmit}
              isLoading={updateSurveyRunResponseMutation.isLoading}
            >
              Submit
            </Button>
          </HStack>
        </VStack>
      </Box>
    </Center>
  );
};

export default TakeShortSurvey;
