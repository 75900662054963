import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import api from "../config";
import { ShoutoutComment } from "./types";

export const useCreateShoutoutComment = (
  props?: UseMutationOptions<any, Error, { accountId: string; data: any }>
) =>
  useMutation<ShoutoutComment, Error, { accountId: string; data: any }>({
    mutationKey: ["createShoutoutComment"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/shoutoutComment`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateShoutoutComment = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId: string; shoutoutCommentId: string; data: any }
  >
) =>
  useMutation<
    ShoutoutComment,
    Error,
    { accountId: string; shoutoutCommentId: string; data: any }
  >({
    mutationKey: ["updateShoutoutComment"],
    mutationFn: async ({ accountId, shoutoutCommentId, data }) => {
      const response = await api.put(
        `/${accountId}/shoutoutComment/${shoutoutCommentId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeleteShoutoutComment = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId: string; shoutoutCommentId: string }
  >
) =>
  useMutation<any, Error, { accountId: string; shoutoutCommentId: string }>({
    mutationKey: ["deleteShoutoutComment"],
    mutationFn: async ({ accountId, shoutoutCommentId }) => {
      const response = await api.delete(
        `/${accountId}/shoutoutComment/${shoutoutCommentId}`
      );
      return response.data;
    },
    ...props,
  });
