import { useQueryClient } from "@tanstack/react-query";
import { User } from "firebase/auth";
import { createContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Profile } from "../api/profiles/types";
import { AppUser } from "../api/user/types";
import { useGetMe, useUpdateUser } from "../api/user/user";
import { useLogout } from "../auth/firebase";
import useAuthState from "../components/auth/useAuthState";
import LoadingBox from "../components/common/LoadingBox";
import useAccountId, {
  useCustomDomainNavigate,
} from "../hooks/customDomainHooks";

type UserContextValue = {
  firebaseUser: User | null;
  setFirebaseUser: (user: User | null) => void;
  userData: AppUser;
  profiles: Profile[] | null;
  logout: (skipRedirect?: boolean) => void;
};

export const UserContext = createContext<UserContextValue>({
  firebaseUser: null,
  setFirebaseUser: () => {},
  userData: {} as any,
  profiles: null,
  logout: () => {},
});

export const UserContextComponent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user: firebaseUser, setUser: setFirebaseUser } = useAuthState();
  const {
    data: userData,
    isLoading: isLoadingUser,
    isError,
    error,
  } = useGetMe();
  const queryClient = useQueryClient();
  const { customDomain, accountId } = useAccountId();
  const navigate = useCustomDomainNavigate();
  const logout = useLogout();
  const updateUserMutation = useUpdateUser();
  const queryParams = new URLSearchParams(window.location.search);

  const updateUser = async (data: any) => {
    try {
      await updateUserMutation.mutateAsync(data);
      queryClient.invalidateQueries(["getMe"]);
    } catch (error) {
      console.log(error);
    }
  };

  // If firebase user changes, invalidate the getMe query
  useEffect(() => {
    if (!firebaseUser) return;
    queryClient.invalidateQueries(["getMe"]);
  }, [firebaseUser]); // eslint-disable-line react-hooks/exhaustive-deps

  // If the user has no profiles, redirect them to the new profile page
  // If the user has profiles, redirect them to the first profile
  useEffect(() => {
    if (!userData) return;
    const profiles = userData.profiles;
    const currentPath = window.location.pathname;
    if (!profiles?.length && currentPath !== "/dashboard/new") {
      navigate("/dashboard/new");
      return;
    }
    if (currentPath === "/" || currentPath === "") {
      // if we're using a customdomain match the domain if possible
      // or use the default first in the list
      const searchProfile = customDomain
        ? profiles.find((profile) => profile.accountId === accountId)
        : null;
      const newCurrentProfile = searchProfile ? searchProfile : profiles[0];

      const targetPath = ["ADMIN", "OWNER", "PARTNER"].includes(
        newCurrentProfile.role
      )
        ? `/${newCurrentProfile.accountId}/admin-dashboard`
        : `/${newCurrentProfile.accountId}/home`;

      // navigate to target path and include the query params
      navigate(targetPath + "?" + queryParams.toString());
    }
  }, [userData, navigate]);

  // Update the user's locale and timezone if they don't have one set
  useEffect(() => {
    if (!userData) return;
    // An async function to handle updating the user
    const handleUpdateUser = async () => {
      const updateData = {} as any;

      // If user doesn't have locale or timezone, set them from the browser
      if (!userData.locale) {
        updateData.locale = navigator.language;
      }

      if (!userData.timezone) {
        updateData.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      // Update user if there's any change
      if (Object.keys(updateData).length) {
        await updateUser(updateData);
      }
    };

    handleUpdateUser(); // Call the async function
  }, [firebaseUser, userData]);

  if (isError) {
    if ((error as any).response?.data?.error === "EMAIL_NOT_VERIFIED") {
      window.location.href = `/_auth/not-verified`;
      return;
    }
    console.error("Error fetching user data");
    logout();
  }

  if (isLoadingUser || !userData || !firebaseUser) {
    return <LoadingBox />;
  }

  return (
    <UserContext.Provider
      value={{
        firebaseUser,
        setFirebaseUser,
        userData,
        profiles: userData.profiles,
        logout,
      }}
    >
      {children || <Outlet />}
    </UserContext.Provider>
  );
};
