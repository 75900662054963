import { Button, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { useUpdateDepartment } from "../../../api/departments/department";
import { InputField } from "../../../components/fields/InputField";
import useAccountId from "../../../hooks/customDomainHooks";
import { useToastMessage } from "../../../hooks/useToastMessage";

const EditDepartment = ({ onSuccess, department }) => {
  const { accountId } = useAccountId();
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const updateDepartmentMutation = useUpdateDepartment();
  const queryClient = useQueryClient();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      name: department.name,
      code: department.code,
      description: department.description,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const updateDepartment = async (data) => {
    try {
      await updateDepartmentMutation.mutateAsync({
        accountId,
        departmentId: department.id,
        data,
      });
      queryClient.invalidateQueries(["fetchDepartment", department.id]);
      queryClient.invalidateQueries(["fetchDepartments", accountId]);
      showSuccessToast("Department updated");
      onSuccess();
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating department." });
    }
  };

  const onSubmit = async (data) => {
    console.log(data);
    await updateDepartment(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          <InputField
            field={{
              id: "name",
              placeholder: "Department Name",
              label: "Department Name",
              validation: {
                required: { value: true, message: "Name is required" },
              },
            }}
          />
          <InputField
            field={{
              id: "code",
              // placeholder: "Department Code",
              label: "Department Code",
              validation: {},
            }}
          />
          <InputField
            field={{
              id: "description",
              // placeholder: "Description",
              label: "Description",
              validation: {},
            }}
          />
          <Button type="submit" isLoading={isSubmitting}>
            Save
          </Button>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default EditDepartment;
