import { Box } from "@chakra-ui/react";
import { useMemo } from "react";
import { useFetchAccount } from "../../api/accounts/accounts";
import { MessageBox } from "../../components/common/MessageBox";
import { DualTables } from "../../components/table/DualTables";
import useAccountId from "../../hooks/customDomainHooks";

const AddTeamMembers = ({ currentMembers, setRightRowsSelected }) => {
  const { accountId } = useAccountId();
  const { data: accountData } = useFetchAccount(accountId || "");

  const profileColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Department",
        accessor: "department.name",
      },
    ],
    []
  );

  const profilesNotOnTeam = useMemo(() => {
    if (!accountData) return [];
    const currentMemberIds = currentMembers.map((row) => row.profile?.id);
    return accountData.profiles.filter((member) => {
      return member.isActive && !currentMemberIds.includes(member.id);
    });
  }, [currentMembers, accountData]);

  const profilesOnTeam = useMemo(() => {
    if (!accountData) return [];
    const currentMemberIds = currentMembers.map((row) => row.profile?.id);
    return accountData.profiles.filter((member) => {
      return member.isActive && currentMemberIds.includes(member.id);
    });
  }, [currentMembers, accountData]);

  return (
    <Box overflowX={"auto"}>
      <MessageBox
        message={"Select people to add or remove them from the team."}
        mb={4}
      />
      {accountData && (
        <DualTables
          columns={profileColumns}
          initialLeftData={profilesNotOnTeam}
          initialRightData={profilesOnTeam}
          onRightRowsChange={setRightRowsSelected}
        />
      )}
    </Box>
  );
};

export default AddTeamMembers;
