import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Stack,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  useGetMyNotificationSettings,
  useUpdateMyNotificationSettings,
} from "../../api/notificationSettings/notificationSettings";
import EmailIcon from "../../assets/icons/message.svg?react";
import LoadingBox from "../../components/common/LoadingBox";
import { SwitchField } from "../../components/fields/SwitchField";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId from "../../hooks/customDomainHooks";
import useToastMessage from "../../hooks/useToastMessage";
import { SlackIcon } from "../Account/Integrations/Slack/SlackIntegration";
import { LinkToSlackModal } from "./modals/LinkToSlackModal";

const SwitchItem = ({ label, labelIcon, id, size = "md", onSwitchChange }) => {
  return (
    <HStack w={"100%"} justifyContent={"space-between"}>
      <HStack>
        {labelIcon && labelIcon}
        <Text fontSize={size} w={"100%"}>
          {label}
        </Text>
      </HStack>
      <Box>
        <SwitchField
          field={{
            id: `${id}`,
          }}
          onSwitchChange={(val) => onSwitchChange(val.target?.checked)}
        />
      </Box>
    </HStack>
  );
};

export const NotificationSettings = () => {
  const { accountId } = useAccountId();
  const { accountData, isLoading, hasModuleTurnedOn, currentProfile } =
    useContext(AccountContext);
  const {
    data: notificationSettings,
    isLoading: isLoadingNotificationSettings,
    isError: isErrorNotificationSettings,
  } = useGetMyNotificationSettings(accountId || "");
  const updateMyNotificationSettingsMutation =
    useUpdateMyNotificationSettings();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const isManager = currentProfile?._count?.reports > 0;

  const hasSlackIntegration = accountData?.slackIntegration?.isActive;

  const formatFormResponse = (data) => {
    const formattedData = {
      notificationSettings: [],
      notificationDistributionSettings: [],
    };
    Object.keys(data).forEach((key) => {
      if (!key.includes("-")) {
        formattedData.notificationDistributionSettings.push({
          notificationDistributionType: key,
          enabled: data[key],
        });
        return;
      }
      const formNotificationDistributionType = key.split("-")[0];
      const formNotificationType = key.split("-")[1];
      formattedData.notificationSettings.push({
        notificationDistributionType: formNotificationDistributionType,
        notificationType: formNotificationType,
        enabled: data[key],
      });
    });
    return formattedData;
  };

  const updateNotificationSettings = async (data) => {
    try {
      const formattedData = formatFormResponse(data);
      await updateMyNotificationSettingsMutation.mutateAsync({
        accountId,
        data: formattedData,
      });
      queryClient.invalidateQueries(["getMyNotificationSettings", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: `Error updating Account` });
    }
  };

  const formatDefaultValues = () => {
    if (isLoadingNotificationSettings || isErrorNotificationSettings) return {};

    const initialValues = {
      EmailOnly: true, // This is a special case for all email notifications
      "EmailOnly-EventPublished": true,
      "EmailOnly-EventReminder": true,
      "EmailOnly-EventSignUpReminder": true,
      "EmailOnly-SurveyPublished": true,
      "EmailOnly-SurveyOverdue": true,
      "EmailOnly-SurveyReminder": true,
      "EmailOnly-ShoutoutPublished": true,
      "EmailOnly-OkrReminder": true,
      "EmailOnly-TeamAlignmentOpen": true,
      "EmailOnly-TeamAlignmentReminder": true,
      Slack: true,
      "Slack-EventPublished": true,
      "Slack-EventReminder": true,
      "Slack-EventSignUpReminder": true,
      "Slack-SurveyPublished": true,
      "Slack-SurveyOverdue": true,
      "Slack-SurveyReminder": true,
      "Slack-ShoutoutPublished": true,
      "Slack-OkrReminder": true,
      "Slack-TeamAlignmentOpen": true,
      "Slack-TeamAlignmentReminder": true,
    };

    Object.keys(initialValues).forEach((key) => {
      if (!key.includes("-")) {
        const foundSetting =
          notificationSettings.notificationDistributionSettings?.find(
            (n) => n.notificationDistributionType === key
          );
        initialValues[key] = foundSetting ? foundSetting.enabled : true;
        return;
      }
      const formNotificationDistributionType = key.split("-")[0];
      const formNotificationType = key.split("-")[1];

      const foundSetting = notificationSettings.notificationSettings?.find(
        (n) =>
          n.notificationType === formNotificationType &&
          n.notificationDistributionType === formNotificationDistributionType
      );
      initialValues[key] = foundSetting ? foundSetting.enabled : true;
    });

    return initialValues;
  };

  const form = useForm({
    mode: "onChange",
    defaultValues: formatDefaultValues(),
  });

  const {
    formState: { errors },
  } = form;

  const submitForm = async (data) => {
    if (Object.keys(errors).length === 0) {
      await updateNotificationSettings(data);
    }
  };

  const debouncedSubmit = debounce(submitForm, 1000);

  const handleFormChange = useCallback(() => {
    submitForm(form.getValues());
  }, [debouncedSubmit]);

  useEffect(() => {
    form.reset(formatDefaultValues());
  }, [notificationSettings, form]);

  const hasEventsModule = useMemo(() => {
    return hasModuleTurnedOn("EVENTS");
  }, [accountData]);
  const hasSurveysModule = useMemo(() => {
    return hasModuleTurnedOn("SURVEYS");
  }, [accountData]);
  const hasShoutoutsModule = useMemo(() => {
    return hasModuleTurnedOn("SHOUTOUTS");
  }, [accountData]);
  const hasOKRsModule = useMemo(() => {
    return hasModuleTurnedOn("OKRS");
  }, [accountData]);
  const hasTeamAlignmentModule = useMemo(() => {
    return hasModuleTurnedOn("TEAM_ALIGNMENT");
  }, [accountData]);

  if (!accountData) return null;
  if (isLoadingNotificationSettings || isErrorNotificationSettings || isLoading)
    return <LoadingBox />;

  return (
    <FormProvider {...form}>
      <form onChange={handleFormChange}>
        <VStack mt={8} alignItems={"flex-start"}>
          <Text fontSize="lg" fontWeight="bold">
            My Notification Settings
          </Text>
          <Stack
            borderTop={"1px solid"}
            borderColor={"gray.300"}
            spacing={0}
            direction={{ base: "column" }}
            alignItems={"flex-start"}
            w={"100%"}
          >
            <DistributionTypeSection
              ButtonContent={
                <Box maxW={240}>
                  <SwitchItem
                    label={"Email Notifications"}
                    labelIcon={<EmailIcon />}
                    id={"EmailOnly"}
                    size={"lg"}
                    onSwitchChange={(val) => {
                      if (!val) {
                        // set all form fields that start with "EmailOnly-" to false
                        Object.keys(form.getValues()).forEach((key) => {
                          if (key.startsWith("EmailOnly-")) {
                            form.setValue(key, false);
                          }
                        });
                      }
                    }}
                  />
                </Box>
              }
              PanelContent={
                <Wrap w={"100%"} spacing={"30px"}>
                  {hasEventsModule && (
                    <NotificationSection title={"Events"}>
                      <SwitchItem
                        label={"Event Published"}
                        id={"EmailOnly-EventPublished"}
                        onSwitchChange={(val) => {
                          if (val) {
                            form.setValue("EmailOnly", true);
                          }
                        }}
                      />
                      <SwitchItem
                        label={"Event Reminders"}
                        id={"EmailOnly-EventReminder"}
                        onSwitchChange={(val) => {
                          if (val) {
                            form.setValue("EmailOnly", true);
                          }
                        }}
                      />
                      <SwitchItem
                        label={"Event Sign Up Reminders"}
                        id={"EmailOnly-EventSignUpReminder"}
                        onSwitchChange={(val) => {
                          if (val) {
                            form.setValue("EmailOnly", true);
                          }
                        }}
                      />
                    </NotificationSection>
                  )}
                  {hasSurveysModule && (
                    <NotificationSection title={"Surveys"}>
                      <SwitchItem
                        label={"Survey Published"}
                        id={"EmailOnly-SurveyPublished"}
                        onSwitchChange={(val) => {
                          if (val) {
                            form.setValue("EmailOnly", true);
                          }
                        }}
                      />
                      <SwitchItem
                        label={"Survey Overdue"}
                        id={"EmailOnly-SurveyOverdue"}
                        onSwitchChange={(val) => {
                          if (val) {
                            form.setValue("EmailOnly", true);
                          }
                        }}
                      />
                      <SwitchItem
                        label={"Survey Reminders"}
                        id={"EmailOnly-SurveyReminder"}
                        onSwitchChange={(val) => {
                          if (val) {
                            form.setValue("EmailOnly", true);
                          }
                        }}
                      />
                    </NotificationSection>
                  )}
                  {hasShoutoutsModule && (
                    <NotificationSection title={"Shoutouts"}>
                      <SwitchItem
                        label={"Shoutout Published"}
                        id={"EmailOnly-ShoutoutPublished"}
                        onSwitchChange={(val) => {
                          if (val) {
                            form.setValue("EmailOnly", true);
                          }
                        }}
                      />
                    </NotificationSection>
                  )}
                  {hasOKRsModule && (
                    <NotificationSection title={"OKRs"}>
                      <SwitchItem
                        label={"OKR Reminders"}
                        id={"EmailOnly-OkrReminder"}
                        onSwitchChange={(val) => {
                          if (val) {
                            form.setValue("EmailOnly", true);
                          }
                        }}
                      />
                    </NotificationSection>
                  )}
                  {hasTeamAlignmentModule && isManager && (
                    <NotificationSection title={"Team Alignment"}>
                      <SwitchItem
                        label={"Team Alignment Open"}
                        id={"EmailOnly-TeamAlignmentOpen"}
                        onSwitchChange={(val) => {
                          if (val) {
                            form.setValue("EmailOnly", true);
                          }
                        }}
                      />
                      <SwitchItem
                        label={"Team Alignment Reminders"}
                        id={"EmailOnly-TeamAlignmentReminder"}
                        onSwitchChange={(val) => {
                          if (val) {
                            form.setValue("EmailOnly", true);
                          }
                        }}
                      />
                    </NotificationSection>
                  )}
                </Wrap>
              }
            />
            {hasSlackIntegration && currentProfile.slackUserId && (
              <DistributionTypeSection
                ButtonContent={
                  <Box maxW={240}>
                    <SwitchItem
                      label={"Slack Notifications"}
                      labelIcon={<SlackIcon />}
                      id={"Slack"}
                      size={"lg"}
                      onSwitchChange={(val) => {
                        if (!val) {
                          // set all form fields that start with "EmailOnly-" to false
                          Object.keys(form.getValues()).forEach((key) => {
                            if (key.startsWith("Slack-")) {
                              form.setValue(key, false);
                            }
                          });
                        }
                      }}
                    />
                  </Box>
                }
                PanelContent={
                  <Wrap w={"100%"} spacing={"30px"}>
                    {hasEventsModule && (
                      <NotificationSection title={"Events"}>
                        <SwitchItem
                          label={"Event Published"}
                          id={"Slack-EventPublished"}
                          onSwitchChange={(val) => {
                            if (val) {
                              form.setValue("Slack", true);
                            }
                          }}
                        />
                        <SwitchItem
                          label={"Event Reminders"}
                          id={"Slack-EventReminder"}
                          onSwitchChange={(val) => {
                            if (val) {
                              form.setValue("Slack", true);
                            }
                          }}
                        />
                        <SwitchItem
                          label={"Event Sign Up Reminders"}
                          id={"Slack-EventSignUpReminder"}
                          onSwitchChange={(val) => {
                            if (val) {
                              form.setValue("Slack", true);
                            }
                          }}
                        />
                      </NotificationSection>
                    )}
                    {hasSurveysModule && (
                      <NotificationSection title={"Surveys"}>
                        <SwitchItem
                          label={"Survey Published"}
                          id={"Slack-SurveyPublished"}
                          onSwitchChange={(val) => {
                            if (val) {
                              form.setValue("Slack", true);
                            }
                          }}
                        />
                        <SwitchItem
                          label={"Survey Overdue"}
                          id={"Slack-SurveyOverdue"}
                          onSwitchChange={(val) => {
                            if (val) {
                              form.setValue("Slack", true);
                            }
                          }}
                        />
                        <SwitchItem
                          label={"Survey Reminders"}
                          id={"Slack-SurveyReminder"}
                          onSwitchChange={(val) => {
                            if (val) {
                              form.setValue("Slack", true);
                            }
                          }}
                        />
                      </NotificationSection>
                    )}
                    {hasShoutoutsModule && (
                      <NotificationSection title={"Shoutouts"}>
                        <SwitchItem
                          label={"Shoutout Published"}
                          id={"Slack-ShoutoutPublished"}
                          onSwitchChange={(val) => {
                            if (val) {
                              form.setValue("Slack", true);
                            }
                          }}
                        />
                      </NotificationSection>
                    )}
                    {hasOKRsModule && (
                      <NotificationSection title={"OKRs"}>
                        <SwitchItem
                          label={"OKR Reminders"}
                          id={"Slack-OkrReminder"}
                          onSwitchChange={(val) => {
                            if (val) {
                              form.setValue("Slack", true);
                            }
                          }}
                        />
                      </NotificationSection>
                    )}
                    {hasTeamAlignmentModule && isManager && (
                      <NotificationSection title={"Team Alignment"}>
                        <SwitchItem
                          label={"Team Alignment Open"}
                          id={"Slack-TeamAlignmentOpen"}
                          onSwitchChange={(val) => {
                            if (val) {
                              form.setValue("Slack", true);
                            }
                          }}
                        />
                        <SwitchItem
                          label={"Team Alignment Reminders"}
                          id={"Slack-TeamAlignmentReminder"}
                          onSwitchChange={(val) => {
                            if (val) {
                              form.setValue("Slack", true);
                            }
                          }}
                        />
                      </NotificationSection>
                    )}
                  </Wrap>
                }
              />
            )}
            {hasSlackIntegration && !currentProfile.slackUserId && (
              <LinkToSlack />
            )}
          </Stack>
        </VStack>
      </form>
    </FormProvider>
  );
};

const DistributionTypeSection = ({ ButtonContent, PanelContent }) => {
  return (
    <Box
      border={"1px solid"}
      borderColor={"gray.300"}
      borderRadius={"10px"}
      mt={4}
      bg={"#ECF2FA80"}
      w={"100%"}
    >
      <Accordion allowToggle w={"100%"}>
        <AccordionItem border={0}>
          <AccordionButton w="100%" py={4} px={6}>
            <Box flex="1" textAlign="left">
              {ButtonContent}
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4}>{PanelContent}</AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

const NotificationSection = ({ title, children }) => {
  return (
    <WrapItem maxW={240} w={"100%"}>
      <VStack w={"100%"} alignItems={"flex-start"}>
        <Text fontSize={"lg"}>{title}</Text>
        {children}
      </VStack>
    </WrapItem>
  );
};

const LinkToSlack = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box
      border={"1px solid"}
      borderColor={"gray.300"}
      borderRadius={"10px"}
      mt={4}
      bg={"#ECF2FA80"}
      w={"100%"}
      py={4}
      px={6}
    >
      <LinkToSlackModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <VStack w={"100%"} alignItems={"flex-start"}>
        <Text>
          You have not linked your account to Slack. Link your account to
          receive notifications in Slack.
        </Text>
        <Button
          variant={"outline"}
          py={4}
          px={6}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <HStack>
            <SlackIcon />
            <Text fontWeight={"bold"}>Link to Slack</Text>
          </HStack>
        </Button>
      </VStack>
    </Box>
  );
};
