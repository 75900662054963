import {
  Box,
  Flex,
  HStack,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useContext, useState } from "react";
import { BiDownload, BiLinkExternal } from "react-icons/bi";
import { FormattedDate, FormattedNumber } from "react-intl";
import api from "../../api/config";
import { useFetchInvoices } from "../../api/invoice/invoice";
import LinkIcon from "../../assets/icons/link.svg?react";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId from "../../hooks/customDomainHooks";
import useToastMessage from "../../hooks/useToastMessage";
import { BillingForm } from "./BillingForm";
import BillingLayout from "./BillingLayout";

const CurrentBilling = () => {
  const [showBillingForm, setShowBillingForm] = useState(false);

  return (
    <VStack spacing={8} alignItems={"flex-start"}>
      <CurrentPaymentMethod setShowBillingForm={setShowBillingForm} />
      <InvoiceHistory />
      <Modal
        isOpen={showBillingForm}
        onClose={() => {
          setShowBillingForm(false);
        }}
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>{"Update Payment Method"}</ModalHeader>
          <ModalBody>
            <BillingLayout>
              {() => (
                <>
                  <Text fontSize={"2xl"} fontWeight={600} pb={4}></Text>
                  <CurrentBillingForm />
                </>
              )}
            </BillingLayout>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

const InvoiceHistory = () => {
  const { accountId } = useAccountId();
  const { data: invoices } = useFetchInvoices(accountId || "");

  const downloadFromUrl = async (url, name) => {
    // Fetch the resource
    const res = await fetch(url);

    // Check if the fetch is successful
    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    // Get the blob from the response
    const blob = await res.blob();

    // Create a link element
    const link = document.createElement("a");

    // Set the download attribute with a value (the filename)
    link.download = `${name}.pdf`;

    // Construct the URI
    link.href = window.URL.createObjectURL(blob);

    // Simulate the link click
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

    // Free up the used object URL
    window.URL.revokeObjectURL(link.href);
  };

  const downloadInvoicePdf = async (invoiceId, invoiceNumber) => {
    try {
      const response = await api.get(`/${accountId}/invoice/${invoiceId}`);
      console.log("response", response.data.pdfUrl);

      const pdfUrl = response.data.pdfUrl;
      downloadFromUrl(pdfUrl, `invoice-${invoiceNumber}`);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const downloadReceiptPdf = async (invoiceId, invoiceNumber) => {
    try {
      const response = await api.get(`/${accountId}/invoice/${invoiceId}`);
      console.log("response", response.data.pdfUrl);

      const pdfUrl = response.data.receipt.pdfUrl;
      downloadFromUrl(pdfUrl, `receipt-${invoiceNumber}`);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const renderReceipt = (invoice) => {
    if (invoice.receipt) {
      return (
        <Link
          w={"150px"}
          onClick={() => {
            downloadReceiptPdf(invoice.id, invoice.number);
          }}
          isExternal
          display={"inline"}
        >
          <Flex align="center">
            <Text color={"primary"} pr={1}>
              receipt
            </Text>
            <Icon as={BiDownload} boxSize="4" color={"primary"} />
          </Flex>
        </Link>
      );
    }
    if (!invoice.receipt && invoice.status === "open") {
      return (
        <Link
          w={"150px"}
          onClick={() => {
            window.open(invoice.hostedInvoiceUrl, "_blank");
          }}
          isExternal
          display={"inline"}
        >
          <Flex align="center">
            <Text color={"primary"} pr={1}>
              pay now
            </Text>
            <Icon as={BiLinkExternal} boxSize="4" color={"primary"} />
          </Flex>
        </Link>
      );
    }
    return <Box w={"150px"} />;
  };

  const InvoiceStatusLabel = ({ status }) => {
    const statusToLabel = {
      draft: <Tag>Draft</Tag>,
      open: <Tag colorScheme="blue">Open</Tag>,
      paid: <Tag colorScheme="green">Paid</Tag>,
      uncollectible: <Tag>Uncollectible</Tag>,
      void: <Tag>Void</Tag>,
    };
    return statusToLabel[status] || <Text>Unknown</Text>;
  };

  return (
    <Box
      bg={"white"}
      borderRadius={"xl"}
      border={"1px"}
      p={4}
      overflow={"auto"}
      shadow="base"
      borderWidth="1px"
      borderColor={"gray.500"}
      maxW={"850px"}
      minW={"900px"}
      alignContent={"center"}
    >
      <VStack spacing={2} alignItems={"flex-start"} divider={<StackDivider />}>
        <Text fontSize={"lg"}>Invoice History</Text>
        {!invoices?.length && <Text>No invoices found for this account.</Text>}
        {invoices?.map((invoice) => {
          return (
            <HStack spacing={8} w={"100%"} key={invoice.id}>
              <Text w={"150px"}>
                <FormattedDate
                  value={invoice.periodEnd}
                  year="numeric"
                  month="short"
                  day="2-digit"
                />
              </Text>
              <Text w={"150px"} textAlign={"right"} pr={6}>
                <FormattedNumber
                  value={invoice.total / 100}
                  style="currency"
                  currency="USD"
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Text>
              <Box w={"150px"}>
                <InvoiceStatusLabel status={invoice.status} />
              </Box>
              <Box w={"150px"}>
                <Link
                  onClick={() => {
                    downloadInvoicePdf(invoice.id, invoice.number);
                  }}
                >
                  <Flex align="center">
                    <Text color={"primary"} pr={1}>
                      invoice
                    </Text>
                    <Icon as={BiDownload} boxSize="4" color={"primary"} />
                  </Flex>
                </Link>
              </Box>
              {renderReceipt(invoice)}
            </HStack>
          );
        })}
      </VStack>
    </Box>
  );
};

const CurrentPaymentMethod = ({ setShowBillingForm }) => {
  const { accountData } = useContext(AccountContext);
  const stripeCustomer = accountData?.stripeCustomer;

  function toTitleCase(str) {
    if (!str) {
      return "";
    }
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const CardComponent = () => {
    return (
      <HStack spacing={4}>
        <Text>
          {toTitleCase(stripeCustomer?.defaultPaymentMethodCardBrand)}
        </Text>
        <Text>•••• {stripeCustomer?.defaultPaymentMethodCardLast4}</Text>
        <Text>
          Expires {stripeCustomer?.defaultPaymentMethodCardExpMonth}/
          {stripeCustomer?.defaultPaymentMethodCardExpYear}
        </Text>
      </HStack>
    );
  };

  const BankAccountComponent = () => {
    return (
      <HStack>
        <Text>{stripeCustomer?.defaultPaymentMethodBankName}</Text>;
        <Text>
          Account Number: •••• {stripeCustomer?.defaultPaymentMethodBankLast4}
        </Text>
      </HStack>
    );
  };

  const paymentTypeToLabel = {
    cashapp: <Text>Cash App</Text>,
    bancontact: <Text>Bancontact</Text>,
    ideal: <Text>iDEAL</Text>,
    link: <Icon as={LinkIcon} boxSize="8" />,
    null: <Text>No payment method configured.</Text>,
  };
  return (
    <Box
      bg={"white"}
      borderRadius={"xl"}
      border={"1px"}
      p={4}
      overflow={"auto"}
      shadow="base"
      borderWidth="1px"
      borderColor={"gray.500"}
      maxW={"850px"}
      minW={"900px"}
      alignContent={"center"}
    >
      <VStack spacing={2} alignItems={"flex-start"} divider={<StackDivider />}>
        <Text fontSize={"lg"}>Payment Method</Text>
        <HStack pt={4}>
          {stripeCustomer?.defaultPaymentMethodType === "card" && (
            <CardComponent />
          )}
          {stripeCustomer?.defaultPaymentMethodType === "us_bank_account" && (
            <BankAccountComponent />
          )}
          {!["card", "us_bank_account"].includes(
            stripeCustomer?.defaultPaymentMethodType
          ) && (
            <>{paymentTypeToLabel[stripeCustomer?.defaultPaymentMethodType]}</>
          )}
          <Link
            onClick={() => {
              setShowBillingForm(true);
            }}
          >
            change
          </Link>
        </HStack>
      </VStack>
    </Box>
  );
};

const CurrentBillingForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { showErrorToast } = useToastMessage();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const port = window.location.port;
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const path = window.location.pathname.split("/company-information")[0];
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url:
            protocol +
            "//" +
            hostname +
            ":" +
            port +
            path +
            `/setup/billing-redirect-company`,
        },
      });
      setIsLoading(false);
      // TODO test the case where 3DS auth fails
      if (error) {
        throw error;
      }
    } catch (error) {
      // Test for invalid_request_error, there wont be a error.message
      showErrorToast({
        message:
          error.message ||
          "Something went wrong. Please contact support@thebestanswer.com",
      });
      setIsLoading(false);
      console.log("error", error);
    }
  };

  return (
    <BillingForm
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      buttonLabel={"Update"}
    />
  );
};

export default CurrentBilling;
