import {
  Box,
  Button,
  HStack,
  Icon,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import HorizontalBarChart from "../../../../components/common/charts/HorizontalBarChart";
import HistogramChart from "../../../../components/common/charts/HistogramChart";
import { useState } from "react";
import { FaFrown, FaMeh, FaSmile } from "react-icons/fa";

export const SimpleNPSChart = ({ nps, widgetData, updateAdminWidget }) => {
  const [isUpdatingWidget, setIsUpdatingWidget] = useState(false);
  return (
    <VStack
      border={"1px"}
      borderColor={"gray.200"}
      borderRadius={"md"}
      p={4}
      m={4}
      h={"100%"}
    >
      <HStack w={"100%"} justifyContent={"space-between"}>
        <Text alignSelf={"flex-start"} fontSize={20}>
          eNPS
        </Text>
        <Button
          alignSelf={"flex-start"}
          size={"xs"}
          variant={"ghost"}
          isLoading={isUpdatingWidget}
          onClick={async () => {
            setIsUpdatingWidget(true);
            await updateAdminWidget(widgetData.id, {
              data: {
                ...widgetData.data,
                showDetails: true,
                widthSize: "MEDIUM",
              },
            });
            setIsUpdatingWidget(false);
          }}
        >
          Show details
        </Button>
      </HStack>
      <Text align={"center"} fontSize={50}>
        {nps.toFixed(0)}
      </Text>
    </VStack>
  );
};

export const DetailedNPSChart = ({
  nps,
  numDetractors,
  numPromoters,
  numPassives,
  responses,
  question,
  widgetData,
  updateAdminWidget,
}) => {
  const [isUpdatingWidget, setIsUpdatingWidget] = useState(false);

  const responseCounts = responses.reduce((counts, response) => {
    const value = response?.response?.value;
    if (counts[value] === undefined) {
      counts[value] = 0;
    }
    counts[value] += 1;
    return counts;
  }, {});

  const histogramData = question.element.choices.map((choice) => {
    return {
      name: choice.value,
      value: responseCounts[choice.value] || 0,
    };
  });

  let sum = 0;
  let totalResponses = 0;

  for (let key in responseCounts) {
    sum += key * responseCounts[key];
    totalResponses += Number(responseCounts[key]);
  }

  let average = sum / totalResponses;

  const green = "#48BB78";
  const yellow = "#FFD600";
  const red = "#F56565";

  const npsColors = {
    0: red,
    1: red,
    2: red,
    3: red,
    4: red,
    5: red,
    6: red,
    7: yellow,
    8: yellow,
    9: green,
    10: green,
  };

  return (
    <VStack p={0} m={0} h={"100%"} spacing={6}>
      <HStack w={"100%"} justifyContent={"space-between"}>
        <VStack>
          <Text alignSelf={"flex-start"} fontSize={"xs"}>
            Net Promoter Score
          </Text>
          <Box w={"250px"} px={5}>
            <Slider value={nps} isReadOnly max={100} min={-100}>
              <SliderMark
                value={-100}
                mt={"5"}
                fontSize={"sm"}
                color={"textPrimary"}
              >
                -100
              </SliderMark>
              <SliderMark
                value={100}
                mt={"5"}
                ml={-5}
                fontSize={"sm"}
                color={"textPrimary"}
              >
                100
              </SliderMark>
              <SliderTrack
                bgGradient="linear(to-r, blue.100, blue.600)"
                h={4}
                borderRadius={"md"}
              >
                <SliderFilledTrack bg="transparent" />
              </SliderTrack>
              <SliderThumb boxSize={10} bg="blue.400">
                <Box color="white">{nps.toFixed(0)}</Box>
              </SliderThumb>
            </Slider>
          </Box>
        </VStack>
        <VStack spacing={0}>
          <Text fontSize={"xs"}>Average Rating</Text>
          <Text fontSize={"lg"} fontWeight={"bold"}>
            {average.toFixed(1)}
          </Text>
          <Text fontSize={"xs"}>From {totalResponses} employees</Text>
        </VStack>
        <HStack spacing={2}>
          <VStack spacing={0}>
            <Text fontSize={"xs"}>Detractors</Text>
            <HStack>
              <Icon as={FaFrown} color={red} />
              <Text fontSize={"lg"} fontWeight={"bold"}>
                {numDetractors}
              </Text>
            </HStack>
            <Text fontSize={"xs"}>Employees</Text>
          </VStack>
          <VStack spacing={0}>
            <Text fontSize={"xs"}>Passives</Text>
            <HStack>
              <Icon as={FaMeh} color={yellow} />
              <Text fontSize={"lg"} fontWeight={"bold"}>
                {numPassives}
              </Text>
            </HStack>
            <Text fontSize={"xs"}>Employees</Text>
          </VStack>
          <VStack spacing={0}>
            <Text fontSize={"xs"}>Promoters</Text>
            <HStack>
              <Icon as={FaSmile} color={green} />
              <Text fontSize={"lg"} fontWeight={"bold"}>
                {numPromoters}
              </Text>
            </HStack>
            <Text fontSize={"xs"}>Employees</Text>
          </VStack>
        </HStack>
        <Button
          alignSelf={"flex-start"}
          size={"xs"}
          variant={"ghost"}
          isLoading={isUpdatingWidget}
          onClick={async () => {
            setIsUpdatingWidget(true);
            await updateAdminWidget(widgetData.id, {
              data: {
                ...widgetData.data,
                showDetails: false,
                widthSize: "SMALL",
              },
            });
            setIsUpdatingWidget(false);
          }}
        >
          Hide details
        </Button>
      </HStack>
      <HistogramChart
        data={histogramData}
        colorsBasedOnNames={npsColors}
        height={150}
      />
    </VStack>
  );
};

export const NPSChart = ({
  responses,
  question,
  widgetData,
  updateAdminWidget,
}) => {
  // Calculate NPS
  let points = 0;
  let numDetractors = 0;
  let numPromoters = 0;
  let numPassives = 0;
  responses.forEach((response) => {
    if (response?.response?.value >= 9) {
      points += 100;
      numPromoters += 1;
    } else if (response?.response?.value <= 6) {
      points -= 100;
      numDetractors += 1;
    } else {
      numPassives += 1;
    }
  });
  const nps = points / responses.length;

  if (widgetData.data?.showDetails) {
    return (
      <DetailedNPSChart
        nps={nps}
        numDetractors={numDetractors}
        numPromoters={numPromoters}
        numPassives={numPassives}
        responses={responses}
        question={question}
        updateAdminWidget={updateAdminWidget}
        widgetData={widgetData}
      />
    );
  }

  return (
    <SimpleNPSChart
      nps={nps}
      updateAdminWidget={updateAdminWidget}
      widgetData={widgetData}
    />
  );
};

export const NumericChart = ({ responses, question }) => {
  // Getting the count of responses for each numeric value
  const minAllowed = question.element.min || undefined;
  const maxAllowed = question.element.max || undefined;

  const responseCounts = responses.reduce((counts, response) => {
    const value = response?.response?.value;
    if (
      (minAllowed !== undefined && value < minAllowed) ||
      (maxAllowed !== undefined && value > maxAllowed)
    ) {
      return counts;
    }
    if (counts[value] === undefined) {
      counts[value] = 0;
    }
    counts[value] += 1;
    return counts;
  }, {});

  // Get the min value from the responses
  let min = Number.MAX_SAFE_INTEGER;
  for (let key in responseCounts) {
    if (key < min) {
      min = key;
    }
  }

  // Get the max value from the responses
  let max = Number.MIN_SAFE_INTEGER;
  for (let key in responseCounts) {
    if (key > max) {
      max = key;
    }
  }

  const histogramData = [];
  for (let i = min; i <= max; i++) {
    histogramData.push({
      name: i,
      value: responseCounts[i] || 0,
    });
  }

  // Calculating the average
  let sum = 0;
  let totalResponses = 0;

  for (let key in responseCounts) {
    sum += key * responseCounts[key];
    totalResponses += Number(responseCounts[key]);
  }

  let average = sum / totalResponses;

  return (
    <VStack h={"100%"}>
      <HistogramChart data={histogramData} maxBins={11} />
      <Text>Average: {average.toFixed(1)}</Text>
    </VStack>
  );
};

export const LinearScaleChart = ({ responses, question }) => {
  const responseCounts = responses.reduce((counts, response) => {
    const value = response.response.value;
    if (counts[value] === undefined) {
      counts[value] = 0;
    }
    counts[value] += 1;
    return counts;
  }, {});

  const histogramData = question.element.choices.map((choice) => {
    return {
      name: choice.value,
      value: responseCounts[choice.value] || 0,
    };
  });

  let sum = 0;
  let totalResponses = 0;

  for (let key in responseCounts) {
    sum += key * responseCounts[key];
    totalResponses += Number(responseCounts[key]);
  }

  let average = sum / totalResponses;

  return (
    <VStack h={"100%"}>
      <HistogramChart data={histogramData} />
      <Text>Average: {average.toFixed(1)}</Text>
    </VStack>
  );
};

export const TextValues = ({ responses }) => {
  return (
    <VStack
      divider={<StackDivider borderColor="gray.200" />}
      spacing={4}
      align="stretch"
      pb={4}
      pl={4}
    >
      {responses
        .filter((resp) => resp?.response?.value !== "")
        .map((r, index) => (
          <Text key={index}>
            {index + 1}. {r?.response?.value}
          </Text>
        ))}
    </VStack>
  );
};

export const StaticValuesChart = ({ responses }) => {
  // const data = [
  //   { label: "yes", value: 11 },
  //   { label: "no", value: 200 },
  //   { label: "Category 3 answer", value: 30 },
  //   { label: "Cheesebuger test 12344", value: 40 },
  // ];

  function transformResponses(responses) {
    // Flatten all response values into a single array
    let responseValues = [].concat.apply(
      [],
      responses.map((r) => r?.response?.value)
    );

    // Count each unique value in the array
    let counts = responseValues.reduce((acc, val) => {
      acc[val] = (acc[val] || 0) + 1;
      return acc;
    }, {});

    // Convert counts object to desired array format
    let data = Object.keys(counts).map((key) => ({
      label: key,
      value: counts[key],
    }));

    return data;
  }

  let transformedResponses = transformResponses(responses);

  return (
    <Box overflow={"hidden"} w={"100%"}>
      <HorizontalBarChart data={transformedResponses} />
    </Box>
  );
};
