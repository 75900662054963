import { Icon } from "@chakra-ui/react";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";

export const NodeButton = (props) => {
  const { node } = props;
  return (
    <div
      className="node-button-div"
      style={{
        display: "inline-flex",
        alignItems: "center",
        color: "white",
        backgroundColor: "black",
        borderRadius: "60px",
        paddingTop: "4px",
        paddingBottom: "4px",
        margin: "auto auto",
        paddingLeft: "12px",
        paddingRight: "12px",
      }}
    >
      <span
        style={{
          fontSize: "10px",
          fontWeight: "600",
          marginRight: "6px",
        }}
      >
        {node.data._directSubordinates}
      </span>
      {node.children ? <Icon as={BiCaretUp} /> : <Icon as={BiCaretDown} />}
    </div>
  );
};
