import { Box, Stack, Text, VStack } from "@chakra-ui/react";
import { InputField } from "../../../../components/fields/InputField";
import QuestionTypeSelector from "../QuestionTypeSelector";

const TextQuestion = ({ questionIndex, onChange }) => {
  return (
    <VStack w={"100%"} spacing={6}>
      <Stack
        w={"100%"}
        gap={4}
        spacing={0}
        flexDirection={{ base: "column", md: "row" }}
      >
        <InputField
          field={{
            id: `prompt`,
            placeholder: "Provide your question prompt",
            validation: {
              required: { value: true, message: "Prompt is required" },
            },
          }}
        />
        <QuestionTypeSelector
          questionIndex={questionIndex}
          onChange={onChange}
        />
      </Stack>
      <Box borderBottom={"1px"} w={"100%"} borderStyle={"dotted"}>
        <Text color={"textSecondary"}>Answer Text</Text>
      </Box>
    </VStack>
  );
};

export default TextQuestion;
