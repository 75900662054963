import { Button } from "@chakra-ui/react";
import { CreateOneOnOneScheduleModal } from "../modals/CreateOneOnOneScheduleModal";
import { useState } from "react";
import CalIcon from "../../../assets/icons/cal.svg?react";

const CreateOneOnOneButton = ({ profileId }) => {
  const [showCreateScheduleModal, setShowCreateScheduleModal] = useState(false);

  return (
    <>
      <CreateOneOnOneScheduleModal
        isOpen={showCreateScheduleModal}
        setIsOpen={setShowCreateScheduleModal}
        profileId={profileId}
      />
      <Button
        onClick={() => setShowCreateScheduleModal(true)}
        w={"200px"}
        leftIcon={<CalIcon fill={"white"} />}
      >
        Schedule 1-on-1
      </Button>
    </>
  );
};

export default CreateOneOnOneButton;
