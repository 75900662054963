import {
  Box,
  HStack,
  Spinner,
  Text,
  VStack,
  Button,
  Link,
} from "@chakra-ui/react";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../../../hooks/customDomainHooks";
import useOKRs from "../../../OKRV2/hooks/useOkrs";
import { ObjectiveSetTitle } from "../../../OKRV2/ObjectiveSetTitle";
import { ObjectiveLabel } from "../../../OKRV2/Objectives/ObjectiveLabel";
import { useContext, useEffect } from "react";
import { AccountContext } from "../../../../context/AccountContextComponent";

const CompanyOKRWidget = () => {
  const navigate = useCustomDomainNavigate();
  const { accountId } = useAccountId();
  const { currentTimePeriod } = useContext(AccountContext);

  const {
    objectiveSets,
    isLoadingObjectives,
    isErrorObjectives,
    setTimePeriodsToFilter,
  } = useOKRs("company", "COMPANY", "");

  // Only show the current time period for widgets
  useEffect(() => {
    if (currentTimePeriod) {
      setTimePeriodsToFilter([
        { value: currentTimePeriod.id, label: currentTimePeriod.label },
      ]);
    }
  }, [currentTimePeriod]);

  if (!isLoadingObjectives && !objectiveSets)
    return <Text>You have no OKRs.</Text>;
  if (isLoadingObjectives) return <Spinner />;
  if (isErrorObjectives) {
    return <Text>Error loading OKRs!</Text>;
  }

  const handleViewAllClick = () => {
    navigate(`/${accountId}/okrs`);
  };

  return (
    <Box w={"100%"}>
      {(objectiveSets?.length > 3
        ? objectiveSets.slice(0, 3)
        : objectiveSets
      ).map((objectiveSet, index) => (
        <ObjectiveSet objectiveSet={objectiveSet} key={index} />
      ))}
      {objectiveSets?.length > 3 && (
        <Button mt={4} w={"100%"} size={"sm"} onClick={handleViewAllClick}>
          View All OKRs
        </Button>
      )}
      {objectiveSets?.length === 0 && (
        <VStack>
          <Text color={"gray.500"}>
            No OKRs in {currentTimePeriod?.label} to show.
          </Text>
          <Button mt={4} w={"100%"} size={"sm"} onClick={handleViewAllClick}>
            Create an OKR
          </Button>
        </VStack>
      )}
    </Box>
  );
};

const ObjectiveSet = ({ objectiveSet }) => {
  const { accountId } = useAccountId();
  const navigate = useCustomDomainNavigate();
  const handleViewAllClick = () => {
    navigate(`/${accountId}/okrs`);
  };

  const filterObjectives = (objectiveSet) => {
    const objectives = objectiveSet.objectives.filter((objective) => {
      return objective.visibility === "PUBLIC";
    });
    return objectives?.length > 5 ? objectives.slice(0, 5) : objectives;
  };

  const handleViewObjectiveClick = (objectiveId) => {
    navigate(
      `/${accountId}/okrs/?tab=Company%20OKRs&objectiveId=${objectiveId}`
    );
  };

  return (
    <Box w={"100%"} py={0} px={0} key={objectiveSet.id}>
      <Box bg={"#4285F41F"} my={2}>
        <ObjectiveSetTitle objectiveSet={objectiveSet} />
      </Box>
      <VStack spacing={3}>
        {(filterObjectives(objectiveSet) || []).map((objective, index) => (
          <Box
            key={objective.id}
            w={"100%"}
            py={0}
            px={0}
            style={{
              fontWeight: 800,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <HStack>
              <ObjectiveLabel
                // Since draft objective is hidden, set the index to be the index of the objective in the list
                index={String(index + 1)}
                canExpand={false}
                objective={objective}
                onClick={() => {
                  handleViewObjectiveClick(objective.id);
                }}
              />
              <HStack justifyContent={"space-between"} w={"100%"} spacing={0}>
                <Box
                  w={"210px"}
                  color={"blue.500"}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Link
                    color={"blue.500"}
                    onClick={() => {
                      handleViewObjectiveClick(objective.id);
                    }}
                    style={{
                      fontWeight: 800,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    textDecoration="none"
                  >
                    {objective.title || "no title"}
                  </Link>
                </Box>
                <Link
                  color={"gray.500"}
                  fontSize={"sm"}
                  textDecoration="none"
                  onClick={() => {
                    handleViewObjectiveClick(objective.id);
                  }}
                >
                  {objective.keyResults.length} KR
                  {objective.keyResults.length === 1 ? "" : "s"}
                </Link>
              </HStack>
            </HStack>
          </Box>
        ))}
        {objectiveSet.objectives?.length > 3 && (
          <Button
            onClick={handleViewAllClick}
            size={"xs"}
            variant={"ghost"}
            alignSelf={"flex-end"}
          >
            View all
          </Button>
        )}
      </VStack>
    </Box>
  );
};

export default CompanyOKRWidget;
