import {
  UseMutationOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { Partner } from "./types";

export const useFetchPartnerAccounts = (): UseQueryResult<Partner[], Error> => {
  return useQuery<Partner[], Error>({
    queryKey: ["fetchPartnerAccounts"],
    queryFn: async () => {
      const response = await api.get(`/partner/partnerAccounts`);
      return response.data;
    },
  });
};

export const useFetchPartner = (): UseQueryResult<Partner, Error> => {
  return useQuery<Partner, Error>({
    queryKey: ["fetchPartner"],
    queryFn: async () => {
      const response = await api.get(`/partner`);
      return response.data;
    },
  });
};

export const useSetEnterprisePlan = (
  props?: UseMutationOptions<any, Error, { data: any }>
) =>
  useMutation({
    mutationKey: ["setEnterprisePlan"],
    mutationFn: async ({ data }) => {
      const response = await api.post(
        `/partner/putOnEnterpriseSubscriptionPlan`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const usePartnerCancelSubscription = (
  props?: UseMutationOptions<any, Error, { data: any }>
) =>
  useMutation({
    mutationKey: ["partnerCancelSubscription"],
    mutationFn: async ({ data }) => {
      const response = await api.post(`/partner/cancelSubscription`, data);
      return response.data;
    },
    ...props,
  });
