import { Box, Text, VStack } from "@chakra-ui/react";
import { useFetchMySurveyRuns } from "../../api/survey/surveyRun";
import NoSurveysToTakeIcon from "../../assets/icons/no_surveys.svg?react";
import LoadingBox from "../../components/common/LoadingBox";
import useAccountId from "../../hooks/customDomainHooks";
import SurveyWidget from "./TakeSurvey/SurveyWidget";

const MySurveys = () => {
  const { accountId } = useAccountId();
  const {
    data: mySurveyRuns,
    isLoading: isLoadingMySurveyRuns,
    isError: isErrorMySurveyRuns,
    error: errorMySurveyRuns,
  } = useFetchMySurveyRuns(accountId || "");

  if (isLoadingMySurveyRuns) {
    return <LoadingBox height={"200px"} />;
  }
  if (isErrorMySurveyRuns) {
    return <Text color={"red.500"}>Error: {errorMySurveyRuns.message}</Text>;
  }

  return (
    <VStack w={"100%"} gap={4} alignItems={"flex-start"}>
      {mySurveyRuns && mySurveyRuns.length === 0 && (
        <VStack justifyContent={"start"} alignItems={"flex-start"}>
          <NoSurveysToTakeIcon />
          <Text fontSize={"lg"}>No open surveys to take</Text>
          <Text fontSize={"lg"} fontWeight={"bold"} color={"primary"}>
            Check back later!
          </Text>
        </VStack>
      )}
      {mySurveyRuns.map((surveyRun) => {
        return (
          <Box
            key={surveyRun.id}
            bg={"white"}
            borderRadius={"md"}
            border={"1px"}
            borderColor={"gray.200"}
            p={4}
            w={"100%"}
            maxWidth={"700px"}
          >
            <SurveyWidget surveyRun={surveyRun} />
          </Box>
        );
      })}
    </VStack>
  );
};

export default MySurveys;
