import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import CreateRankingPeriod from "../CreateRankingPeriod";

export const CreateRankingPeriodModal = (props) => {
  const { isOpen, usedTimePeriods, handleClose } = props;
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        handleClose(false);
      }}
      size={"md"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Reporting Period</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CreateRankingPeriod
            onSuccess={() => {
              handleClose(true);
            }}
            usedTimePeriods={usedTimePeriods}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
