import { Box, Spinner, Text, VStack } from "@chakra-ui/react";
import { useFetchPastEvents } from "../../api/events/event";
import useAccountId from "../../hooks/customDomainHooks";
import EventCard from "./EventCard";

const PastEvents = () => {
  const { accountId } = useAccountId();
  const {
    data: pastEvents,
    isLoading: isLoadingPastEvents,
    isError: isErrorPastEvents,
    error: errorPastEvents,
  } = useFetchPastEvents(accountId || "");

  if (isLoadingPastEvents) {
    return <Spinner />;
  }
  if (isErrorPastEvents) {
    return <Text>{errorPastEvents.message}</Text>;
  }

  return (
    <Box>
      <VStack alignItems={"flex-start"} spacing={4}>
        {pastEvents.length === 0 && (
          <Box>
            <Text>No past events to show.</Text>
          </Box>
        )}
        {pastEvents.map((event) => (
          <EventCard
            key={event.id}
            event={event}
            eventMenuItems={["EDIT", "COPY", "PUBLISH", "DELETE"]}
          />
        ))}
      </VStack>
    </Box>
  );
};

export default PastEvents;
