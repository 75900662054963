import { Divider, Text, Stack } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { AccountContext } from "../../../context/AccountContextComponent";
import { FilterOneSelect } from "../../../components/fields/FilterOneSelect";

export const ObjectiveSortBy = ({ orderBy, setOrderBy }) => {
  return (
    <Stack direction={{ base: "column", md: "row" }}>
      <Text fontWeight={700} whiteSpace={"nowrap"}>
        Sort by:
      </Text>
      <ObjectiveSortBySelect
        orderBySelected={orderBy}
        onSelection={setOrderBy}
      />
      <Divider orientation="vertical" />
    </Stack>
  );
};

const ObjectiveSortBySelect = ({ onSelection, orderBySelected }) => {
  const { accountData } = useContext(AccountContext);

  const getOptions = (accountData) => {
    if (!accountData) return [];
    return [
      {
        value: "timePeriod.startDate.asc",
        label: "Oldest First",
      },
      {
        value: "timePeriod.startDate.desc",
        label: "Newest First",
      },
      {
        value: "owner.name.asc",
        label: "Owner A-Z",
      },
      {
        value: "owner.name.desc",
        label: "Owner Z-A",
      },
    ];
  };

  const timePeriodOptions = useMemo(
    () => getOptions(accountData),
    [accountData, orderBySelected]
  );

  return (
    <FilterOneSelect
      options={timePeriodOptions}
      value={orderBySelected}
      onChange={onSelection}
    />
  );
};
