import { Editor } from "@tiptap/core";
import {
  CheckCheck,
  ArrowDownWideNarrow,
  WrapText,
  CheckCheckIcon,
  ArrowDownNarrowWide,
  Pencil,
} from "lucide-react";
import CancelIcon from "../../../../assets/icons/cancel.svg?react";
import TryAgainIcon from "../../../../assets/icons/try_again.svg?react";
import MagicWandIcon from "../../../../assets/icons/magic_wand.svg?react";
import { MdUndo as UndoIcon, MdRedo as RedoIcon } from "react-icons/md";

import { FC, useMemo } from "react";
import { AiTask } from "../../../../api/ai/ai";
import { AiContextTask } from "../../../../context/TipTapAiContextComponent";

interface AiOption {
  value: AiTask;
  label: string;
  icon: FC;
}

interface AiOptionGroup {
  label: string;
  options: AiOption[];
}

export const useAiMenuOptions = ({
  contextTask,
  hasCompletion,
  isViewingOkrCompletion,
  redoCompletions,
  selectionIsEmpty,
  completions,
  editor,
  previousTask,
}: {
  editor: Editor | undefined;
  completions: string[];
  redoCompletions: string[];
  contextTask?: AiContextTask;
  hasCompletion: boolean;
  isViewingOkrCompletion: boolean;
  selectionIsEmpty: boolean;
  previousTask?: AiTask;
}) => {
  const optionsWithoutSelection = useMemo(
    () => [
      {
        value: AiTask.CONTINUE_WRITING,
        label: "Continue Writing",
        icon: Pencil,
      } as AiOption,
      ...(completions.length > 1
        ? [
            {
              value: AiTask.UNDO,
              label: "Undo",
              icon: UndoIcon,
            },
          ]
        : []),
      ...(redoCompletions.length > 0
        ? [
            {
              value: AiTask.REDO,
              label: "Redo",
              icon: RedoIcon,
            },
          ]
        : []),
    ],
    [completions, redoCompletions]
  );

  const optionsBeforeAiCompletion = useMemo(
    () =>
      [
        {
          value: AiTask.IMPROVE,
          label: "Improve writing",
          icon: MagicWandIcon,
        },
        {
          value: AiTask.GRAMMAR,
          label: "Fix grammar",
          icon: CheckCheck,
        },
        {
          value: AiTask.SHORTER,
          label: "Make shorter",
          icon: ArrowDownWideNarrow,
        },
        {
          value: AiTask.LONGER,
          label: "Make longer",
          icon: ArrowDownNarrowWide,
        },
      ] as AiOption[],
    []
  );

  const optionsAfterAiCompletion = useMemo(() => {
    const selectionIsEmpty =
      editor?.state.doc
        .textBetween(editor.state.selection.from, editor.state.selection.to)
        .trim().length === 0;
    const options = [
      {
        value: AiTask.REPLACE_SELECTION,
        label: selectionIsEmpty ? "Done" : "Replace Selection",
        icon: CheckCheckIcon,
      },
      {
        value: AiTask.CONTINUE_WRITING,
        label: "Continue Writing",
        icon: Pencil,
      },
      {
        value: AiTask.LONGER,
        label: "Make longer",
        icon: ArrowDownNarrowWide,
      },
      {
        value: AiTask.SHORTER,
        label: "Make shorter",
        icon: ArrowDownWideNarrow,
      },
      ...(previousTask
        ? [
            {
              value: AiTask.TRY_AGAIN,
              label: "Try again",
              icon: TryAgainIcon,
            },
          ]
        : []),
      ...(completions.length > 1
        ? [
            {
              value: AiTask.UNDO,
              label: "Undo",
              icon: UndoIcon,
            },
          ]
        : []),
      ...(redoCompletions.length > 0
        ? [
            {
              value: AiTask.REDO,
              label: "Redo",
              icon: RedoIcon,
            },
          ]
        : []),
    ];

    if (!selectionIsEmpty) {
      options.splice(1, 0, {
        value: AiTask.INSERT_BELOW,
        label: "Insert Below",
        icon: WrapText,
      });
    }

    return options as AiOption[];
  }, [editor, completions, redoCompletions]);

  const optionsAfterOkrCompletion = useMemo(() => {
    const options = [
      {
        value: AiTask.ACCEPT_OKR,
        label: "Accept & Create OKR",
        icon: CheckCheckIcon,
      },
      {
        value: AiTask.TRY_AGAIN,
        label: "Try again",
        icon: TryAgainIcon,
      },
      ...(completions.length > 1
        ? [
            {
              value: AiTask.UNDO,
              label: "Undo",
              icon: UndoIcon,
            },
          ]
        : []),
      ...(redoCompletions.length > 0
        ? [
            {
              value: AiTask.REDO,
              label: "Redo",
              icon: RedoIcon,
            },
          ]
        : []),
      {
        value: AiTask.CANCEL,
        label: "Cancel",
        icon: CancelIcon,
      },
    ];

    return options as AiOption[];
  }, [completions, redoCompletions]);

  const okrObjectiveTitleOptions = useMemo(
    () =>
      [
        {
          value: AiTask.AUTHOR_OKR,
          label: "Write the OKR for me",
          icon: Pencil,
        },
      ] as AiOption[],
    []
  );

  const dynamicOptions = useMemo(() => {
    const options = [] as AiOptionGroup[];

    // OKR AI Assistant
    if (contextTask === AiContextTask.OKR_OBJECTIVE_TITLE) {
      if (!hasCompletion) {
        options.push({
          label: "Writing OKRs",
          options: okrObjectiveTitleOptions,
        });
        if (!selectionIsEmpty) {
          options.push({
            label: "Modify Text",
            options: optionsBeforeAiCompletion,
          });
        } else {
          options.push({
            label: "Modify Text",
            options: optionsWithoutSelection,
          });
        }
      } else if (hasCompletion && isViewingOkrCompletion) {
        options.push({
          label: "Writing OKRs",
          options: optionsAfterOkrCompletion,
        });
      } else {
        options.push({
          label: "Edit or Review",
          options: optionsAfterAiCompletion,
        });
      }
      return options;
    }

    // Generic AI Assistant
    if (!hasCompletion && selectionIsEmpty) {
      options.push({
        label: "Modify Text",
        options: optionsWithoutSelection,
      });
    }
    if (hasCompletion) {
      options.push({
        label: "Edit or Review",
        options: optionsAfterAiCompletion,
      });
    } else {
      options.push({
        label: "Edit or Review",
        options: optionsBeforeAiCompletion,
      });
    }
    return options;
  }, [
    hasCompletion,
    optionsAfterAiCompletion,
    optionsBeforeAiCompletion,
    optionsWithoutSelection,
    contextTask,
    okrObjectiveTitleOptions,
    optionsAfterOkrCompletion,
    isViewingOkrCompletion,
    selectionIsEmpty,
  ]);

  return {
    dynamicOptions,
  };
};
