import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import api from "../config";
import { Shoutout } from "./types";

type ShoutoutData = any;

export const useCreateShoutout = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId: string; data: ShoutoutData }
  >
) =>
  useMutation<any, Error, { accountId: string; data: ShoutoutData }>({
    mutationKey: ["createShoutout"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/shoutout`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateShoutout = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId: string; shoutoutId: string; data: ShoutoutData }
  >
) =>
  useMutation<
    any,
    Error,
    { accountId: string; shoutoutId: string; data: ShoutoutData }
  >({
    mutationKey: ["updateShoutout"],
    mutationFn: async ({ accountId, shoutoutId, data }) => {
      const response = await api.put(
        `/${accountId}/shoutout/${shoutoutId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeleteShoutout = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId: string; shoutoutId: string }
  >
) =>
  useMutation<any, Error, { accountId: string; shoutoutId: string }>({
    mutationKey: ["deleteShoutout"],
    mutationFn: async ({ accountId, shoutoutId }) => {
      const response = await api.delete(`/${accountId}/shoutout/${shoutoutId}`);
      return response.data;
    },
    ...props,
  });

export const useFetchShoutouts = (accountId: string) => {
  return useInfiniteQuery<
    {
      pageInfo: {
        hasMore: boolean;
        page: number;
        totalCount: number;
        totalPages: number;
      };
      shoutouts: Shoutout[];
    },
    Error
  >(
    ["fetchShoutouts", accountId],
    async ({ pageParam = 1 }) => {
      const response = await api.get(
        `/${accountId}/shoutout?page=${pageParam}`
      );
      return response.data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.pageInfo?.hasMore ? pages.length + 1 : undefined;
      },
    }
  );
};
