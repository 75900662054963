import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import EditReportReviewers from "../EditReportReviewers";

export const ManageViewersModal = (props) => {
  const {
    isOpen,
    setIsOpen,
    rankingReport,
    canManageReviewers,
    canManageViewers,
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"md"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Manage Report Viewers</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EditReportReviewers
            onSuccess={() => {
              setIsOpen(false);
            }}
            rankingReport={rankingReport}
            canManageReviewers={canManageReviewers}
            canManageViewers={canManageViewers}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
