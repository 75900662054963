export default function Magic({ className }: { className: string }) {
  return (
    <svg
      width="394"
      height="415"
      viewBox="0 0 394 415"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M254.593 64.4214C251.279 65.6643 249 68.7714 249 72.5C249 76.2286 251.279 79.3357 254.593 80.5786L297.264 96.7357L313.421 139.407C314.664 142.721 317.771 145 321.5 145C325.229 145 328.336 142.721 329.579 139.407L345.736 96.7357L388.407 80.5786C391.721 79.3357 394 76.2286 394 72.5C394 68.7714 391.721 65.6643 388.407 64.4214L345.736 48.2643L329.579 5.59286C328.336 2.27857 325.229 0 321.5 0C317.771 0 314.664 2.27857 313.421 5.59286L297.264 48.2643L254.593 64.4214ZM11.2243 191.287C4.57286 193.781 0 200.017 0 207.5C0 214.983 4.57286 221.219 11.2243 223.713L96.8614 256.139L129.287 341.776C131.781 348.427 138.017 353 145.5 353C152.983 353 159.219 348.427 161.713 341.776L194.139 256.139L279.776 223.713C286.427 221.219 291 214.983 291 207.5C291 200.017 286.427 193.781 279.776 191.287L194.139 158.861L161.713 73.2243C159.219 66.5729 152.983 62 145.5 62C138.017 62 131.781 66.5729 129.287 73.2243L96.8614 158.861L11.2243 191.287ZM249.5 343C249.5 339.297 251.763 336.211 255.054 334.977L297.431 318.931L313.477 276.554C314.711 273.263 317.797 271 321.5 271C325.203 271 328.289 273.263 329.523 276.554L345.569 318.931L387.946 334.977C391.237 336.211 393.5 339.297 393.5 343C393.5 346.703 391.237 349.789 387.946 351.023L345.569 367.069L329.523 409.446C328.289 412.737 325.203 415 321.5 415C317.797 415 314.711 412.737 313.477 409.446L297.431 367.069L255.054 351.023C251.763 349.789 249.5 346.703 249.5 343Z"
        fill="#2F855A"
      />
    </svg>
  );
}
