import {
  Avatar,
  Box,
  Center,
  Flex,
  HStack,
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spinner,
  Text,
  useMenuButton,
  VStack,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import UpDownArrowsIcon from "../../assets/icons/up_down_arrows.svg?react";
import { AccountContext } from "../../context/AccountContextComponent";
import { UserContext } from "../../context/UserContextComponent";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import { UserModal } from "../../pages/User/UserModal";
import { ROLE_LABELS } from "../../types/common";

export const ProfileButton = ({
  hideCompanySettings,
}: {
  hideCompanySettings?: boolean;
}) => {
  const { logout } = useContext(UserContext);
  const { currentProfile } = useContext(AccountContext);
  const { userData } = useContext(UserContext);
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const navigate = useCustomDomainNavigate();
  const { accountId } = useAccountId();

  const canViewCompanySettings = ["OWNER", "ADMIN", "PARTNER"].includes(
    currentProfile?.role || ""
  );
  const isAPartner = !!userData?.partner;

  return (
    <Box id={"profile-button"}>
      <UserModal isOpen={isOpenUserModal} setIsOpen={setIsOpenUserModal} />
      <Menu>
        <ProfileMenuButton />
        <MenuList
          shadow="lg"
          color={"gray.600"}
          py="4"
          px="3"
          maxH={"50vh"}
          overflow="auto"
        >
          <MenuItem rounded="md" onClick={() => setIsOpenUserModal(true)}>
            My Profile
          </MenuItem>
          {canViewCompanySettings && !hideCompanySettings && (
            <MenuItem
              rounded="md"
              onClick={() => navigate(`/${accountId}/company-information`)}
            >
              Company Settings
            </MenuItem>
          )}
          {isAPartner && (
            <MenuItem
              rounded="md"
              onClick={() => navigate(`/partner/enterprise-plans`)}
            >
              Partner Settings
            </MenuItem>
          )}
          <MenuItem
            _hover={{ bg: "red.100" }}
            rounded="md"
            onClick={() => logout()}
          >
            Logout
          </MenuItem>

          {(currentProfile || isAPartner) && <ProfileList />}
        </MenuList>
      </Menu>
    </Box>
  );
};

const ProfileMenuButton = () => {
  const buttonProps = useMenuButton();
  const { userData } = useContext(UserContext);
  const { photoUrl, name } = userData;
  const { currentProfile } = useContext(AccountContext);

  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{ bg: "gray.200" }}
      _focus={{ shadow: "outline" }}
      borderColor={"green.800"}
      shadow={"box-shadow: 0px 1px 0px 0px #FFFFFF99"}
    >
      <HStack flex="1" spacing="4">
        <Avatar
          w="42px"
          h="42px"
          objectFit="cover"
          src={photoUrl}
          name={name}
        />
        <VStack align={"self-start"} spacing={0}>
          <Text color={"textPrimary"} fontWeight={600}>
            {name}
          </Text>
          <Flex display="flex" flexDirection={"row"} fontSize={"11px"}>
            {currentProfile &&
              ["OWNER", "ADMIN"].includes(currentProfile.role) && (
                <Text color={"#809FB8"} fontWeight={"600"}>
                  {ROLE_LABELS[currentProfile.role]}{" "}
                </Text>
              )}
            {currentProfile && ["PARTNER"].includes(currentProfile.role) && (
              <Text color={"#B51616"} fontWeight={"600"}>
                ⚠️ Customer: {currentProfile.account.name}
              </Text>
            )}
          </Flex>
        </VStack>
        <Box fontSize="lg" color="gray.400" ml={4}>
          <UpDownArrowsIcon />
        </Box>
      </HStack>
    </Flex>
  );
};

const ProfileList = () => {
  const { profiles } = useContext(UserContext);
  const { currentProfile } = useContext(AccountContext);
  const { customDomain } = useAccountId();

  if (!profiles) {
    return (
      <Center>
        <Spinner size={"sm"} />
      </Center>
    );
  }

  const alphabeticalProfiles = profiles.sort((a, b) => {
    const nameA = a.account.name.toUpperCase();
    const nameB = b.account.name.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0; // names must be equal
  });

  return (
    <>
      {alphabeticalProfiles?.length > 1 && (
        <>
          <MenuDivider />

          <MenuOptionGroup defaultValue={currentProfile?.id}>
            {alphabeticalProfiles?.map((profile) => {
              return (
                <MenuItemOption
                  iconSpacing={0}
                  value={profile.id}
                  key={profile.id}
                  fontWeight="semibold"
                  rounded="md"
                  onClick={() => {
                    if (customDomain) {
                      const protocol = window.location.protocol;
                      const fullCustomDomain = `${profile.account.id}.${
                        import.meta.env.VITE_CUSTOM_BASE_DOMAIN
                      }`;
                      const port = window.location.port;
                      window.location.href = `${protocol}//${fullCustomDomain}:${port}/home`;
                    } else {
                      window.location.href = `/${profile.account.id}/home`;
                    }
                  }}
                >
                  <HStack flex="1" spacing="3" pl={2}>
                    <Text>{profile.account.name}</Text>
                  </HStack>
                </MenuItemOption>
              );
            })}
          </MenuOptionGroup>
        </>
      )}
    </>
  );
};
