import { HStack, Spinner, Switch, Text } from "@chakra-ui/react";
import { useState } from "react";

const PercentageSwitch = ({ widgetData, updateAdminWidget }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <HStack>
      <Spinner size={"xs"} hidden={!isUpdating} />
      <Text minW={16}>Show %</Text>
      <Switch
        isChecked={widgetData.data?.displayPercentages || false}
        onChange={async (val) => {
          setIsUpdating(true);
          await updateAdminWidget(widgetData.id, {
            data: {
              ...widgetData.data,
              displayPercentages: val.target.checked,
            },
          });
          setIsUpdating(false);
        }}
      />
    </HStack>
  );
};

export default PercentageSwitch;
