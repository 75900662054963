import { RRule, Weekday } from "rrule";

const frequencyToRrule: { [key: string]: number } = {
  WEEKLY: RRule.WEEKLY,
  MONTHLY: RRule.MONTHLY,
};

const byweekdayToRrule: { [key: string]: Weekday } = {
  MO: RRule.MO,
  TU: RRule.TU,
  WE: RRule.WE,
  TH: RRule.TH,
  FR: RRule.FR,
  SA: RRule.SA,
  SU: RRule.SU,
};

export const intToWeekday: { [key: number]: string } = {
  0: "MO",
  1: "TU",
  2: "WE",
  3: "TH",
  4: "FR",
  5: "SA",
  6: "SU",
};

export const getRruleFromFormData = (data: {
  frequency: string;
  interval?: number;
  byWeekDay?: string[];
  bySetPos?: number[];
  startDate: Date;
  endDate?: Date;
}) => {
  let ruleStr;
  if (data.frequency === "WEEKLY") {
    ruleStr = new RRule({
      freq: frequencyToRrule[data.frequency],
      interval: data.interval,
      byweekday:
        data.byWeekDay && data.byWeekDay?.length > 0
          ? data.byWeekDay.map((day) => byweekdayToRrule[day])
          : null,
      dtstart: data.startDate, // TODO convert to UTC??
      until: data.endDate ? data.endDate : null,
    }).toString();
  } else if (data.frequency === "MONTHLY") {
    ruleStr = new RRule({
      freq: frequencyToRrule[data.frequency],
      interval: data.interval,
      bysetpos: data.bySetPos,
      byweekday: byweekdayToRrule[data.byWeekDay as any as string],
      dtstart: data.startDate, // TODO convert to UTC??
      until: data.endDate,
    }).toString();
  } else {
    ruleStr = null;
  }
  return ruleStr;
};
