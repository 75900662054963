import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import api from "../config";

interface CreateAccountUploadUrlVariables {
  accountId?: string;
  data: any;
}

export const useCreateAccountUploadUrl = (
  props?: UseMutationOptions<any, Error, CreateAccountUploadUrlVariables>
) =>
  useMutation<any, Error, CreateAccountUploadUrlVariables>({
    mutationKey: ["createAccountUploadUrl"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/storage/getAccountUploadUrl`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useCreatePublicUploadUrl = (
  props?: UseMutationOptions<
    any,
    Error,
    {
      data: any;
    }
  >
) =>
  useMutation({
    mutationKey: ["createPublicUploadUrl"],
    mutationFn: async ({ data }) => {
      const response = await api.post(`/storage/getPublicUploadUrl`, data);
      return response.data;
    },
    ...props,
  });
