import { useParams } from "react-router-dom";
import PageShell from "../../layout/PageShell";
import { Box, IconButton, Tag, Text } from "@chakra-ui/react";
import { useContext, useMemo, useState } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import { useFetchTeam } from "../../api/teams/team";
import LoadingBox from "../../components/common/LoadingBox";
import TeamMembers from "./TeamMembers";
import { EditTeamModal } from "./modals/EditTeamModal";
import { MdOutlineEdit } from "react-icons/md";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../hooks/customDomainHooks";

const TeamPage = () => {
  const { teamId } = useParams();
  const { accountId } = useAccountId();
  const { currentProfile, accountData } = useContext(AccountContext);
  const [isEditTeamModalOpen, setIsEditTeamModalOpen] = useState(false);

  const {
    data: team,
    isLoading: isLoadingTeam,
    isError: isErrorTeam,
    // error: errorTeam,
  } = useFetchTeam(accountId, teamId);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Teams", href: `/${accountId}/teams` },
    {
      name: team && (
        <>
          {team.name} {` `}
          {team.isArchived && <Tag colorScheme="red">Archived</Tag>}
        </>
      ),
    },
  ]);

  const canEdit = useMemo(() => {
    if (!currentProfile) return false;
    if (["ADMIN", "OWNER", "PARTNER"].includes(currentProfile.role))
      return true;
    return false;
  }, [currentProfile]);

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      headerComponent={
        canEdit && (
          <Box justifyContent={"center"} pl={2}>
            <IconButton
              icon={<MdOutlineEdit />}
              variant="ghost"
              fontSize={"md"}
              size={"xs"}
              onClick={() => {
                setIsEditTeamModalOpen(true);
              }}
            />
          </Box>
        )
      }
    >
      {(isLoadingTeam || !accountData) && <LoadingBox height={"100px"} />}
      {isErrorTeam && <Text color={"red"}>Error loading Team!</Text>}
      {!isLoadingTeam && !team && <Text color={"red"}>Team not found</Text>}
      {team && accountData && (
        <>
          <TeamMembers
            members={team.members}
            allowEdit={canEdit}
            isTeamArchived={team.isArchived}
          />
          <EditTeamModal
            isOpen={isEditTeamModalOpen}
            setIsOpen={setIsEditTeamModalOpen}
            team={team}
          />
        </>
      )}
    </PageShell>
  );
};

export default TeamPage;
