import { SelectField } from "../../../../components/fields/SelectField";
import { timeRangeOptions } from "../../../../constants";

const RetentionConfig = () => {
  return (
    <>
      <SelectField
        field={{
          id: "data.dateRange",
          label: "Time Range",
          validation: { required: true },
        }}
        options={timeRangeOptions}
      />
    </>
  );
};

export default RetentionConfig;
