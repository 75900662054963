import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  Text,
} from "@chakra-ui/react";
import { get } from "lodash";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import { Controller, useFormContext } from "react-hook-form";

export const NumberField = ({
  field,
  leftAddon,
  rightAddon,
  styleProps,
  inputProps,
}: {
  field: {
    label?: string;
    placeholder?: string;
    id: string;
    defaultValue?: string;
    validation?: any;
    type?: "currency" | "number" | "percent";
    min?: number;
    max?: number;
  };
  leftAddon?: any;
  rightAddon?: any;
  styleProps?: any;
  inputProps?: CurrencyInputProps | NumberInputProps | any;
}) => {
  const form = useFormContext();
  const {
    formState: { errors },
    register,
    control,
  } = form;

  const options = {
    "de-DE": {
      currency: "EUR",
      locale: "de-DE",
    },
    "en-US": {
      currency: "USD",
      locale: "en-US",
    },
    "ja-JP": {
      currency: "JPY",
      locale: "ja-JP",
    },
    "fr-FR": {
      currency: "EUR",
      locale: "fr-FR",
    },
  };

  return (
    <Controller
      name={field.id}
      control={control}
      defaultValue=""
      rules={field.validation}
      render={({ field: { onChange, value } }) => (
        <FormControl
          isInvalid={!!get(errors, field.id)}
          isRequired={field.validation?.required}
        >
          {field.label && <FormLabel>{field.label}</FormLabel>}
          <InputGroup>
            {leftAddon}
            {field.type === "currency" ? (
              <CurrencyInput
                id={field.id}
                name={field.id}
                placeholder={field.placeholder}
                decimalsLimit={2}
                intlConfig={options["en-US"]}
                value={(value / 100).toFixed(2)} // display value as a decimal with two decimal places
                onValueChange={(val) => {
                  let valInt = parseFloat(val || "") * 100; // convert value to an integer
                  valInt = Math.round(valInt); // round to the nearest integer to avoid floating point precision issues
                  onChange(isNaN(valInt) ? null : valInt); // update state with integer value
                }}
                style={{
                  backgroundColor: "white",
                  border: "1px solid #CBD5E0",
                  borderRadius: "6px",
                  width: "100%",
                  padding: "0.5rem 1rem",
                  outline: "none",
                  boxShadow: "sm",
                  ...styleProps,
                }}
                {...inputProps}
              />
            ) : (
              <NumberInput
                max={field.max !== undefined ? field.max : undefined}
                min={field.min !== undefined ? field.min : undefined}
                onChange={(valString) => {
                  if (
                    valString === "." ||
                    valString === "0." ||
                    valString === "-"
                  ) {
                    onChange(valString);
                    return;
                  }
                  const val = parseFloat(valString);

                  if (isNaN(val)) {
                    onChange(null);
                  } else {
                    onChange(val);
                  }
                }}
                value={value === undefined || value === null ? "" : value}
                borderRadius={"6px"}
                {...inputProps}
              >
                <NumberInputField
                  p={2}
                  id={field.id}
                  // name={field.id}
                  key={field.id}
                  placeholder={field.placeholder}
                  {...register(field.id, {
                    ...field.validation,
                    valueAsNumber: true,
                  })}
                  style={{ ...styleProps }}
                />
              </NumberInput>
            )}
            {field.type === "percent" && (
              <Text fontSize={"xl"} alignSelf={"center"} ml={2}>
                %
              </Text>
            )}
            {rightAddon}
          </InputGroup>

          <FormErrorMessage>
            <>{get(errors, field.id) && get(errors, `${field.id}.message`)}</>
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};
