import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useMemo, useState } from "react";
import {
  BiArchive,
  BiDotsVerticalRounded,
  BiEdit,
  BiShow,
} from "react-icons/bi";
import { IoAddOutline } from "react-icons/io5";
import {
  useFetchDepartments,
  useUpdateDepartment,
} from "../../../api/departments/department";
import LoadingBox from "../../../components/common/LoadingBox";
import { DeleteSomethingModal } from "../../../components/modals/DeleteSomethingModal";
import CustomTable from "../../../components/table/CustomTableV2";
import { AccountContext } from "../../../context/AccountContextComponent";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import { CreateDepartmentModal } from "./modals/CreateDepartmentModal";
import { EditDepartmentModal } from "./modals/EditDepartmentModal";

const DepartmentsTable = ({ setDepartmentId }) => {
  const { accountId } = useAccountId();
  const { currentProfile } = useContext(AccountContext);
  const {
    data: departments,
    isLoading: isLoadingDepartments,
    isError: isErrorDepartments,
    error: errorDepartments,
  } = useFetchDepartments(accountId || "");
  const [showCreateDepartmentModal, setShowCreateDepartmentModal] =
    useState(false);
  const [isEditDepartmentModalOpen, setIsEditDepartmentModalOpen] =
    useState(false);
  const [isArchiveDepartmentModalOpen, setIsArchiveDepartmentModalOpen] =
    useState(false);
  const [departmentToEditId, setDepartmentToEditId] = useState(null);
  const [showArchived, setShowArchived] = useState(false);
  const updateDepartmentMutation = useUpdateDepartment();
  const queryClient = useQueryClient();
  const { showErrorToast, showSuccessToast } = useToastMessage();

  const canAddDepartment = useMemo(() => {
    if (!currentProfile) return false;
    if (["ADMIN", "OWNER", "PARTNER"].includes(currentProfile.role))
      return true;
    return false;
  }, [currentProfile]);

  const departmentsColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell }) => {
          const department = cell.row.original;
          return (
            <Link
              onClick={() => {
                setDepartmentId(department.id);
              }}
            >
              <Text>
                {department.name}
                {department.isArchived && (
                  <Tag ml={4} colorScheme="red">
                    Archived
                  </Tag>
                )}
              </Text>
            </Link>
          );
        },
      },
      {
        Header: "Code",
        accessor: "code",
        Cell: ({ value }) => {
          return <Text>{value}</Text>;
        },
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ value }) => {
          return <Text>{value}</Text>;
        },
      },
      {
        Header: "Members",
        accessor: (row) => row._count.profiles,
        Cell: ({ cell }) => {
          const numMembers = cell.row.original._count.profiles;
          const members = cell.row.original.profiles;
          return (
            <HStack>
              <Text w={"40px"} textAlign={"right"} pr={2}>
                {numMembers}
              </Text>
              <AvatarGroup
                size="md"
                max={4}
                // spacing={"-7px"}
              >
                {members.map((member) => {
                  return (
                    <Avatar
                      name={member.name}
                      src={member?.user?.photoUrl}
                      key={member.id}
                    />
                  );
                })}
              </AvatarGroup>
            </HStack>
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ cell }) => (
          <Box textAlign={"right"}>
            <Menu>
              <MenuButton
                isLoading={updateDepartmentMutation.isLoading}
                as={IconButton}
                variant="outline"
                icon={<BiDotsVerticalRounded size={22} />}
              ></MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    setDepartmentId(cell.row.original.id);
                  }}
                  icon={
                    <Center>
                      <Icon as={BiShow} fontSize={"xl"} />
                    </Center>
                  }
                >
                  View Members
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsEditDepartmentModalOpen(true);
                    setDepartmentToEditId(cell.row.original.id);
                  }}
                  icon={
                    <Center>
                      <Icon as={BiEdit} fontSize={"xl"} />
                    </Center>
                  }
                >
                  Edit
                </MenuItem>
                {cell.row.original.isArchived ? (
                  <MenuItem
                    onClick={async () => {
                      await updateDepartment(cell.row.original.id, {
                        isArchived: false,
                      });
                    }}
                    icon={
                      <Center>
                        <Icon as={BiArchive} fontSize={"xl"} />
                      </Center>
                    }
                    color={"red.500"}
                  >
                    Unarchive
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => {
                      setDepartmentToEditId(cell.row.original.id);
                      setIsArchiveDepartmentModalOpen(true);
                    }}
                    icon={
                      <Center>
                        <Icon as={BiArchive} fontSize={"xl"} />
                      </Center>
                    }
                    color={"red.500"}
                  >
                    Archive
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Box>
        ),
      },
    ],
    [departments]
  );

  const departmentsToShow = useMemo(() => {
    return departments?.filter((department) => {
      if (showArchived) {
        return department.isArchived;
      } else {
        return !department.isArchived;
      }
    });
  }, [departments, showArchived]);

  const updateDepartment = async (departmentId, data) => {
    try {
      await updateDepartmentMutation.mutateAsync({
        accountId,
        departmentId,
        data,
      });
      queryClient.invalidateQueries(["fetchDepartment", departmentId]);
      queryClient.invalidateQueries(["fetchDepartments", accountId]);
      showSuccessToast("Department updated");
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating department." });
    }
  };

  return (
    <>
      <CreateDepartmentModal
        isOpen={showCreateDepartmentModal}
        setIsOpen={setShowCreateDepartmentModal}
      />
      <EditDepartmentModal
        isOpen={isEditDepartmentModalOpen}
        setIsOpen={setIsEditDepartmentModalOpen}
        department={departments?.find(
          (department) => department.id === departmentToEditId
        )}
      />
      <DeleteSomethingModal
        isOpen={isArchiveDepartmentModalOpen}
        setIsOpen={setIsArchiveDepartmentModalOpen}
        headerText={"Archive Department"}
        bodyText={`Are you sure you want to archive this department?`}
        deleteFunction={async () => {
          await updateDepartment(departmentToEditId, { isArchived: true });
        }}
        buttonText={"Archive Department"}
      />
      <Box>
        {isLoadingDepartments && <LoadingBox height={"200px"} />}
        {isErrorDepartments && (
          <Text color={"red.500"}>Error: {errorDepartments.message}</Text>
        )}
        {departments && (
          <Box>
            <CustomTable
              columns={departmentsColumns}
              data={departmentsToShow}
              rightElement={
                <HStack spacing={4}>
                  <FormControl
                    display="flex"
                    alignItems="center"
                    gap={2}
                    justifyContent={"flex-end"}
                  >
                    <Switch
                      isChecked={showArchived}
                      onChange={(e) => {
                        setShowArchived(e.target.checked);
                      }}
                      id="showArchived"
                    ></Switch>
                    <FormLabel htmlFor="showArchived" mb={0} fontWeight={400}>
                      Show Archived
                    </FormLabel>
                  </FormControl>
                  {canAddDepartment && (
                    <Button
                      leftIcon={<IoAddOutline />}
                      onClick={() => setShowCreateDepartmentModal(true)}
                      minW={"180px"}
                    >
                      Add Department
                    </Button>
                  )}
                </HStack>
              }
              doUsePagination={true}
              selectionType={"link"}
              boxStyle={{
                bg: "white",
                borderRadius: "10px",
                border: "1px",
                borderColor: "gray.200",
                p: 4,
                overflow: "auto",
              }}
              tableStyle={{
                size: "md",
              }}
              tableId={"viewDepartmentsTable"}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default DepartmentsTable;
