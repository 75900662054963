import { Box } from "@chakra-ui/react";
import React from "react";
import { useDrag, useDrop } from "react-dnd";

const DraggableItem = ({
  id,
  index,
  moveItem,
  children,
  setDrag,
  styleProps,
}) => {
  const ref = React.useRef(null);

  const [{ handlerId }, drop] = useDrop({
    type: "choice",
    accept: "choice",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position relative to the item
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Determine mouse position relative to the item
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      // Dragging downwards
      if (
        dragIndex < hoverIndex &&
        hoverClientY < hoverMiddleY &&
        hoverClientX < hoverMiddleX
      ) {
        return;
      }

      // Dragging upwards
      if (
        dragIndex > hoverIndex &&
        hoverClientY > hoverMiddleY &&
        hoverClientX > hoverMiddleX
      ) {
        return;
      }

      // Time to actually perform the action if the item being hovered over is the topmost
      if (!monitor.isOver({ shallow: true })) {
        return;
      }

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: "choice", id, index },
    type: "choice",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  React.useEffect(() => {
    setDrag(drag);
  }, [setDrag, drag]);

  drop(ref);
  preview(ref);

  return (
    <Box
      ref={ref}
      opacity={isDragging ? 0.5 : 1}
      data-handler-id={handlerId}
      p={0}
      m={0}
      {...styleProps}
    >
      {children()}
    </Box>
  );
};

export { DraggableItem };
