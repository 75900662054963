import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tag,
  Text,
  VStack,
  Wrap,
  WrapItem,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiDotsVerticalRounded, BiSend } from "react-icons/bi";
import { FormattedDate } from "react-intl";
import { useDeleteShoutout } from "../../api/shoutouts/shoutout";
import { useCreateShoutoutComment } from "../../api/shoutouts/shoutoutComment";
import {
  useCreateShoutoutReaction,
  useDeleteShoutoutReaction,
} from "../../api/shoutouts/shoutoutReaction";
import EmojiPicker from "../../components/common/EmojiPicker/EmojiPicker";
import { InputField } from "../../components/fields/InputField";
import { DeleteSomethingModal } from "../../components/modals/DeleteSomethingModal";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId from "../../hooks/customDomainHooks";
import useToastMessage from "../../hooks/useToastMessage";
import { Comments } from "./Comments";

export const ShoutoutCard = ({ shoutout, size }) => {
  const queryClient = useQueryClient();
  const { accountId } = useAccountId();
  const { currentProfile, accountData, hasModuleTurnedOn } =
    useContext(AccountContext);
  const createShoutoutReactionMutation = useCreateShoutoutReaction();
  const deleteShoutoutReactionMutation = useDeleteShoutoutReaction();
  const { showErrorToast } = useToastMessage();

  const optimisticallyAddReaction = (reactionEmoji) => {
    // Construct the new reaction
    const foundReaction = shoutout.formattedReactions.find(
      (reaction) => reaction.reaction === reactionEmoji
    );
    if (foundReaction) {
      foundReaction.count += 1;
    } else {
      shoutout.formattedReactions.push({
        reaction: reactionEmoji,
        count: 1,
        reactors: [
          {
            id: currentProfile?.id,
            name: currentProfile?.name,
          },
        ],
      });
    }
  };

  const optimisticallyRemoveReaction = (reactionEmoji) => {
    // Construct the new reaction
    if (!reactionEmoji) return;
    const foundReaction = shoutout.formattedReactions.find(
      (reaction) => reaction.reaction === reactionEmoji
    );
    if (foundReaction) {
      foundReaction.count -= 1;
      foundReaction.reactors = foundReaction.reactors.filter(
        (reactor) => reactor.id !== currentProfile?.id
      );
    }
  };

  const createShoutoutReaction = async (reactionEmoji) => {
    try {
      optimisticallyAddReaction(reactionEmoji);
      await createShoutoutReactionMutation.mutateAsync({
        accountId,
        data: {
          shoutoutId: shoutout.id,
          reaction: reactionEmoji,
        },
      });
      queryClient.invalidateQueries(["fetchShoutouts"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error reacting to shoutout." });
    }
  };

  const removeShoutoutReaction = async (existingShoutoutReaction) => {
    try {
      optimisticallyRemoveReaction(
        existingShoutoutReaction?.reaction?.reaction
      );
      const shoutoutReactionId = existingShoutoutReaction?.id;
      if (!shoutoutReactionId) return;
      await deleteShoutoutReactionMutation.mutateAsync({
        accountId,
        shoutoutReactionId,
      });
      queryClient.invalidateQueries(["fetchShoutouts"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error removing shoutout reaction." });
    }
  };

  const reactToShoutout = async (reactionEmoji) => {
    if (myReactions.includes(reactionEmoji)) {
      const existingReaction = shoutout.reactions.find(
        (reaction) =>
          reaction.reaction.reaction === reactionEmoji &&
          reaction.creator.id === currentProfile?.id
      );
      await removeShoutoutReaction(existingReaction);
    } else {
      await createShoutoutReaction(reactionEmoji);
    }
  };

  const myReactions = shoutout.formattedReactions
    .filter((reaction) =>
      reaction.reactors.some((reactor) => reactor.id === currentProfile?.id)
    )
    .reduce((acc, reaction) => {
      return [...acc, reaction.reaction];
    }, []);

  const canEditShoutout =
    shoutout.creator.id === currentProfile?.id ||
    ["OWNER", "ADMIN", "PARTNER"].includes(currentProfile?.role || "");

  const canCommentOnShoutout = accountData?.modules?.find(
    (module) =>
      module.moduleName === "SHOUTOUTS_COMMENTS" && module.status == "DISABLED"
  )
    ? false
    : true;

  const canReactToShoutout = hasModuleTurnedOn("SHOUTOUTS_REACTIONS");

  return (
    <Card
      maxW={size || "sm"}
      w={"100%"}
      // overflow="hidden"
      // position="relative"
      mb={4}
      shadow={"md"}
    >
      <CardBody w={"100%"}>
        <Stack spacing="4">
          <HStack w={"100%"} justifyContent={"space-between"}>
            <HStack alignItems={"flex-start"}>
              <Avatar
                size="sm"
                src={shoutout.creator.user?.photoUrl}
                name={shoutout.creator.name}
              />
              <VStack alignItems={"flex-start"}>
                <Text>
                  <strong>{shoutout.creator.name}</strong>
                  {` `}posted a shoutout
                </Text>
                <Text color={"textSecondary"}>
                  <FormattedDate
                    value={shoutout.createdAt}
                    year="numeric"
                    month="short"
                    day="2-digit"
                  />
                </Text>
              </VStack>
            </HStack>
            {canEditShoutout && <EditShoutoutMenu shoutout={shoutout} />}
          </HStack>
          <Wrap>
            {shoutout.valueTags.map((tag) => {
              return (
                <WrapItem key={tag.id}>
                  <Tag size={"xs"} p={2} bg={"gray.50"} color={"textPrimary"}>
                    {tag.label}
                  </Tag>
                </WrapItem>
              );
            })}
          </Wrap>
          <Text>{shoutout.text}</Text>
          <Text fontSize={"18px"} fontWeight={"bold"}>
            Shoutout to
          </Text>
          <Wrap>
            {shoutout.recipients.map((recipient) => {
              return (
                <WrapItem pl={4} key={recipient.id}>
                  <Box
                    border={"1px"}
                    borderColor={"gray.200"}
                    borderRadius={"md"}
                    minWidth={"100px"}
                    maxWidth={"max-content"}
                    p={2}
                    bg={"gray.50"}
                  >
                    <HStack>
                      <Avatar
                        size="sm"
                        src={recipient.user?.photoUrl}
                        name={recipient.name}
                      />
                      <Text>{recipient.name} </Text>
                    </HStack>
                  </Box>
                </WrapItem>
              );
            })}
          </Wrap>
        </Stack>
      </CardBody>
      {(canCommentOnShoutout || canReactToShoutout) && (
        <CardFooter borderTop={"1px"} borderColor={"gray.200"} py={2}>
          <VStack w={"100%"}>
            {canReactToShoutout && (
              <HStack w={"100%"} justifyContent={"space-between"}>
                <Wrap>
                  {shoutout.formattedReactions.map((reaction) => (
                    <WrapItem key={reaction.reaction}>
                      <Button
                        size="sm"
                        variant="outline"
                        onClick={() => {
                          reactToShoutout(reaction.reaction);
                        }}
                        borderRadius={"full"}
                        bg={
                          myReactions.includes(reaction.reaction)
                            ? "blue.50"
                            : "white"
                        }
                      >
                        {reaction.reaction} {reaction.count}
                      </Button>
                    </WrapItem>
                  ))}
                </Wrap>
                <Box>
                  <EmojiPicker
                    onEmojiSelect={(emoji) => {
                      reactToShoutout(emoji.native);
                    }}
                    buttonLabel={"React"}
                  />
                </Box>
              </HStack>
            )}
            {canCommentOnShoutout && <Comments comments={shoutout.comments} />}
            {canCommentOnShoutout && <WriteCommentBox shoutout={shoutout} />}
          </VStack>
        </CardFooter>
      )}
    </Card>
  );
};

const EditShoutoutMenu = ({ shoutout }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isArchiveShoutoutModalOpen, setIsArchiveShoutoutModalOpen] =
    useState(false);
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const deleteShoutoutMutation = useDeleteShoutout();

  const deleteShoutout = async () => {
    try {
      await deleteShoutoutMutation.mutateAsync({
        accountId,
        shoutoutId: shoutout.id,
      });
      queryClient.invalidateQueries(["fetchShoutouts"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error removing shoutout." });
    }
  };

  return (
    <Box>
      <DeleteSomethingModal
        isOpen={isArchiveShoutoutModalOpen}
        setIsOpen={setIsArchiveShoutoutModalOpen}
        headerText={"Delete Shoutout"}
        bodyText={`Are you sure you want to delete this shoutout?`}
        deleteFunction={async () => {
          await deleteShoutout();
        }}
        buttonText={"Delete Shoutout"}
      />
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton
          as={IconButton}
          icon={<BiDotsVerticalRounded fontSize={20} color="gray" />}
          size="sm"
          variant="ghost"
          aria-label="Options"
          onClick={onOpen}
          _hover={{ background: "gray.100" }}
          borderRadius={"full"}
          isLoading={deleteShoutoutMutation.isLoading}
        />
        <MenuList>
          <MenuItem
            onClick={() => {
              setIsArchiveShoutoutModalOpen(true);
            }}
          >
            Delete shoutout
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

const WriteCommentBox = ({ shoutout }) => {
  const queryClient = useQueryClient();
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const createShoutoutCommentMutation = useCreateShoutoutComment();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      text: null,
    },
  });

  const createShoutoutComment = async (text) => {
    try {
      await createShoutoutCommentMutation.mutateAsync({
        accountId,
        data: {
          shoutoutId: shoutout.id,
          text,
        },
      });
      queryClient.invalidateQueries(["fetchShoutouts"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error commenting on shoutout." });
    }
  };

  return (
    <FormProvider {...form}>
      <form
        style={{ width: "100%" }}
        onSubmit={async (e) => {
          e.preventDefault();
          const text = e.target.text.value;
          await createShoutoutComment(text);
          form.reset();
        }}
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") e.preventDefault();
        // }}
      >
        <HStack w={"100%"} justifyContent={"space-between"}>
          <InputField
            field={{
              id: "text",
              placeholder: "Write a comment...",
              validation: {
                required: {
                  value: true,
                  message: "Comment is required.",
                },
              },
            }}
          />
          <IconButton
            size="sm"
            variant="ghost"
            icon={<BiSend size={18} />}
            isLoading={createShoutoutCommentMutation.isLoading}
            type="submit"
          ></IconButton>
        </HStack>
      </form>
    </FormProvider>
  );
};
