import { Box, Center, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import Div100vh from "react-div-100vh";
import GridIcon from "../../assets/icons/grid.svg?react";
import tbaFrame from "../../assets/tba-frame.png";
import { HelpButton } from "../../components/common/Buttons/HelpButton";
import { SidebarSection } from "./SidebarSection";

const PartnerDashboardSidebar = () => {
  const sidebarSections = useMemo(() => {
    return [
      {
        id: "company-section",
        items: [
          {
            type: "header",
            label: "Clients",
            show: true,
          },
          {
            type: "nav",
            label: "Enterprise Plans",
            href: "enterprise-plans",
            startElement: <GridIcon />,
            show: true,
          },
        ],
      },
    ] as SidebarSection[];
  }, []);

  return (
    <Box
      w="64"
      bg={"offWhite"}
      borderRight={"1px solid"}
      borderColor={"gray.200"}
      fontSize="sm"
      minH="100vh"
      maxH="100vh"
      position="fixed"
      height="100vh"
    >
      <Div100vh>
        <Flex h="full" direction="column">
          <Center py={"8px"}>
            <Image src={tbaFrame} alt="TBA Frame" h={"50px"} />
          </Center>
          <Stack flex="1" overflow="auto" p="20px" mt={"5px"}>
            {sidebarSections.map((sidebarSection, index) => {
              return (
                <SidebarSection
                  sidebarSection={sidebarSection}
                  key={index}
                />
              );
            })}
          </Stack>
          <Stack
            spacing="2"
            p="10px"
            borderTop={"1px"}
            borderColor={"gray.100"}
          >
            <HelpButton />
            <Text fontSize="xs" color="textSecondary">
              Powered by The Best Answer
            </Text>
          </Stack>
        </Flex>
      </Div100vh>
    </Box>
  );
};
export default PartnerDashboardSidebar;
