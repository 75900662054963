import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Tooltip,
} from "@chakra-ui/react";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import { get } from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import "./styles.css";

//
// IMPORTANT: don't forget to add styles.css and check index.css!
// react-datepicker-popper
//

export const DateField = ({
  field,
  leftAddon,
  onDateChange,
  showTimeSelect,
  dateFormat,
  isFakeRequired,
  datePickerProps,
}: {
  field: {
    id: string;
    label?: string;
    validation?: any;
    tooltipId?: string;
  };
  leftAddon?: JSX.Element;
  onDateChange?: (date: Date) => void;
  showTimeSelect?: boolean;
  dateFormat?: string;
  isFakeRequired?: boolean;
  datePickerProps?: any;
}) => {
  const form = useFormContext();
  const {
    formState: { errors },
    register,
    control,
  } = form;
  const intl = useIntl();

  // Get the user's timezone
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneShort = Intl.DateTimeFormat("en", { timeZoneName: "short" })
    ?.formatToParts()
    ?.find((p) => p.type === "timeZoneName")?.value;

  const isValidDate = (d: Date | null) => {
    if (!d) return false;
    const date = new Date(d);
    return date instanceof Date && !isNaN(date.getTime());
  };

  return (
    <Controller
      name={field.id}
      control={control}
      defaultValue={null}
      rules={field.validation || {}}
      render={({ field: { onChange, value } }) => (
        <FormControl
          isInvalid={!!get(errors, field.id)}
          isRequired={field.label && field.validation?.required}
        >
          {(field.label || showTimeSelect) && (
            <Tooltip
              label={
                field.tooltipId &&
                intl.formatMessage({
                  id: field.tooltipId,
                })
              }
            >
              <FormLabel>
                {field.label} {showTimeSelect && `(${timezoneShort})`}{" "}
                {isFakeRequired && <span style={{ color: "#E53E3E" }}>*</span>}
              </FormLabel>
            </Tooltip>
          )}
          <InputGroup>
            {leftAddon}
            <DatePicker
              {...register(field.id, {
                ...field.validation,
                valueAsNumber: false,
              })}
              wrapperClassName="datePicker"
              autoComplete="off"
              portalId="root-portal"
              selected={
                value && isValidDate(value)
                  ? toZonedTime(new Date(value), timezone)
                  : null
              }
              onChange={async (date) => {
                if (!date || !isValidDate(date)) {
                  console.log("Invalid date");
                  onChange(null);
                  return;
                }
                // If the previous date value was null, set the time to the top of the next hour from now
                if (!value) {
                  const now = new Date();
                  now.setHours(now.getHours() + 1, 0, 0, 0);
                  date.setHours(
                    now.getHours(),
                    now.getMinutes(),
                    now.getSeconds()
                  );
                }
                // Convert selected date back to UTC
                const utcDate = fromZonedTime(date, timezone);
                onChange(utcDate);
                // Need to wait for errors to be updated
                await form.trigger(field.id);
                if (!get(errors, field.id) && onDateChange) {
                  onDateChange(utcDate);
                }
              }}
              onBlur={() => {
                form.trigger(field.id);
              }}
              dateFormat={dateFormat || (showTimeSelect ? "Pp" : "P")}
              showTimeSelect={showTimeSelect}
              customInput={<Input placeholder="yyyy-MM-dd" />}
              {...datePickerProps}
            />
          </InputGroup>
          {get(errors, field.id) && get(errors, `${field.id}.message`) && (
            <FormErrorMessage>
              <>{get(errors, `${field.id}.message`)}</>
            </FormErrorMessage>
          )}
        </FormControl>
      )}
    />
  );
};
