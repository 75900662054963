import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { KbPage } from "./types";

export const useCreateKbPage = (
  props?: UseMutationOptions<
    KbPage,
    unknown,
    { accountId: string; data: any },
    unknown
  >
) =>
  useMutation<KbPage, unknown, { accountId: string; data: any }, unknown>({
    mutationKey: ["createKbPage"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post<KbPage>(`/${accountId}/kbPage`, data);
      return response.data;
    },
    ...props,
  });

export const useCloneKbPage = (
  props?: UseMutationOptions<
    KbPage,
    unknown,
    { accountId: string; pageId: string },
    unknown
  >
) =>
  useMutation<KbPage, unknown, { accountId: string; pageId: string }, unknown>({
    mutationKey: ["cloneKbPage"],
    mutationFn: async ({ accountId, pageId }) => {
      const response = await api.post<KbPage>(
        `/${accountId}/kbPage/${pageId}/clone`
      );
      return response.data;
    },
    ...props,
  });

export const useUpdateKbPage = (
  props?: UseMutationOptions<
    KbPage,
    unknown,
    { accountId: string; pageId: string; data: any },
    unknown
  >
) =>
  useMutation<
    KbPage,
    unknown,
    { accountId: string; pageId: string; data: any },
    unknown
  >({
    mutationKey: ["updateKbPage"],
    mutationFn: async ({ accountId, pageId, data }) => {
      const response = await api.put<KbPage>(
        `/${accountId}/kbPage/${pageId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeleteKbPage = (
  props?: UseMutationOptions<
    unknown,
    unknown,
    { accountId: string; kbPageId: string },
    unknown
  >
) =>
  useMutation<
    unknown,
    unknown,
    { accountId: string; kbPageId: string },
    unknown
  >({
    mutationKey: ["deleteKbPage"],
    mutationFn: async ({ accountId, kbPageId }) => {
      const response = await api.delete<KbPage>(
        `/${accountId}/kbPage/${kbPageId}`
      );
      return response.data;
    },
    ...props,
  });

export const useFetchKbPages = (
  accountId: string,
  options?: UseQueryOptions<KbPage[], unknown>
) => {
  return useQuery<KbPage[], unknown>({
    queryKey: ["fetchKbPages"],
    queryFn: async () => {
      const response = await api.get<KbPage[]>(`/${accountId}/kbPage`);
      return response.data;
    },
    ...options,
  });
};

export const useFetchKbPage = (
  accountId: string,
  pageId: string,
  options?: UseQueryOptions<KbPage, unknown>
) => {
  return useQuery<KbPage, unknown>({
    queryKey: ["fetchKbPage", pageId],
    queryFn: async () => {
      const response = await api.get<KbPage>(`/${accountId}/kbPage/${pageId}`);
      return response.data;
    },
    ...options,
  });
};
