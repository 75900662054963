import { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { ObjectiveOwner } from "./ObjectiveOwner";
import { ObjectiveTitle } from "./ObjectiveTitle";
import { ObjectiveTimeperiod } from "./ObjectiveTimeperiod";
import { ViewOkrTreeButton } from "./ViewOkrTreeButton";
import { ObjectiveKRParent } from "./ObjectiveKRParent";

export const ObjectiveHeader = ({
  objective,
  isObjectiveExpanded,
  canEditObjective,
  setNewlyCreatedObjectiveId
}) => {
  const [accordionIndex, setAccordionIndex] = useState([-1]);

  return (
    <Accordion allowToggle w={"100%"} index={accordionIndex} cursor={"default"}>
      <AccordionItem border={0} maxW={"100%"}>
        {({ isExpanded }) => (
          <Box borderBottom={isExpanded && "solid 1px #3181CE"}>
            <Flex w="100%" direction={{ base: "column", md: "row" }}>
              <Box flex="1" minW="0" flexGrow={1} flexShrink={1}>
                <ObjectiveTitle
                  isExpanded={isExpanded}
                  objective={objective}
                  setAccordionIndex={setAccordionIndex}
                  accordionIndex={accordionIndex}
                  canEditObjective={canEditObjective}
                  setNewlyCreatedObjectiveId={setNewlyCreatedObjectiveId}
                />
              </Box>

              {!isObjectiveExpanded && (
                <Box minW="75px" maxW="75px">
                  {/* Ensuring both min and max width to guarantee the space */}
                  <ViewOkrTreeButton objective={objective} />
                </Box>
              )}
            </Flex>

            <AccordionPanel>
              {isExpanded && (
                <ObjectiveDetails
                  objective={objective}
                  canEditObjective={canEditObjective}
                />
              )}
            </AccordionPanel>
          </Box>
        )}
      </AccordionItem>
    </Accordion>
  );
};

const ObjectiveDetails = ({ objective, canEditObjective }) => {
  return (
    <Box>
      <Wrap>
        <WrapItem alignItems={"center"}>
          <Text fontWeight={800} pr={1}>
            Owner:
          </Text>
          <ObjectiveOwner
            objective={objective}
            canEditObjective={canEditObjective}
          />
        </WrapItem>
        <WrapItem alignItems={"center"}>
          <Text fontWeight={800} pr={1}>
            Time Period:
          </Text>
          <ObjectiveTimeperiod
            objective={objective}
            canEditObjective={canEditObjective}
          />
        </WrapItem>
        <WrapItem alignItems={"center"}>
          <Text fontWeight={800} pr={1}>
            Parent:
          </Text>
          <ObjectiveKRParent
            objective={objective}
            canEditObjective={canEditObjective}
          />
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default ObjectiveHeader;
