import { Box, Checkbox } from "@chakra-ui/react";

export const ViewMyKrsOnlyCheckbox = ({
  onlyShowMyKrs,
  setOnlyShowMyKrs,
  setExpandAll,
  expandAll,
}) => {
  return (
    <Box>
      <Checkbox
        onChange={(e) => {
          setOnlyShowMyKrs(e.target.checked);
          if (expandAll === undefined || expandAll === false) {
            setExpandAll(true);
          }
        }}
        isChecked={onlyShowMyKrs}
        style={{
          whiteSpace: "nowrap",
          textDecoration: "underline",
          fontWeight: 700,
        }}
        size={"sm"}
      >
        View My KRs Only
      </Checkbox>
    </Box>
  );
};
