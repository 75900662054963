import { useState } from "react";
import DepartmentsTable from "./DepartmentsTable";
import Department from "./Department";

const Departments = () => {
  const [departmentId, setDepartmentId] = useState(null);

  if (!departmentId) {
    return <DepartmentsTable setDepartmentId={setDepartmentId} />;
  } else {
    return (
      <Department
        departmentId={departmentId}
        setDepartmentId={setDepartmentId}
      />
    );
  }
};

export default Departments;
