import { Text, VStack } from "@chakra-ui/react";
import { useContext, useMemo, useState } from "react";
import InfoBox from "../../components/common/PageInfo/InfoBox";
import CustomTable from "../../components/table/CustomTableV2";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import PageShell from "../../layout/PageShell";

const ReportsPage = () => {
  const { accountId } = useAccountId();
  const { hasModuleTurnedOn } = useContext(AccountContext);
  const [pageInfoEditMode, setPageInfoEditMode] = useState(false);
  const navigate = useCustomDomainNavigate();
  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Reports" },
  ]);

  const getSystemReports = () => {
    const systemReports = [
      {
        name: "Employee Tenure",
        description:
          "Employee tenure for all employees or filtered by department or team alignment category",
        type: "System",
        href: `/${accountId}/reports/employee-tenure`,
      },
      {
        name: "Employee Turnover",
        description:
          "Employee turnover for all employees or filtered by department, employee tenure or team alignment category",
        type: "System",
        href: `/${accountId}/reports/employee-turnover`,
      },
    ];

    if (hasModuleTurnedOn("ABC_RANKING")) {
      systemReports.push({
        name: "Team Alignment Report",
        description:
          "Track team alignment by date or over time (last 6 reports), and analyze team assembly by manager and department",
        type: "System",
        href: `/${accountId}/reports/ta`,
      });
    }

    if (hasModuleTurnedOn("SURVEY")) {
      systemReports.push({
        name: "Surveys",
        description:
          "Report on named or anonymous surveys results, by survey and survey run (date)",
        type: "System",
        href: `/${accountId}/reports/surveys`,
      });
      systemReports.push({
        name: "eNPS",
        description:
          "Employee Net Promoter score for all surveyed employees or filtered by department, employee tenure or team alignment category",
        type: "System",
        href: `/${accountId}/reports/enps`,
      });
    }

    return systemReports;
  };

  const allReports = getSystemReports();

  const reportsColumns = useMemo(
    () => [
      {
        accessor: "name",
        Header: "Name",
        Cell: ({ value }) => {
          return (
            <VStack alignItems={"flex-start"}>
              <Text>{value}</Text>
            </VStack>
          );
        },
      },
      {
        accessor: "description",
        Header: "Description",
        Cell: ({ value }) => {
          return (
            <VStack alignItems={"flex-start"}>
              <Text>{value}</Text>
            </VStack>
          );
        },
      },
      {
        accessor: "type",
        Header: "Report Type",
        Cell: ({ value }) => {
          return (
            <VStack alignItems={"flex-start"}>
              <Text>{value}</Text>
            </VStack>
          );
        },
      },
    ],
    [allReports]
  );

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      menuItems={[
        {
          type: "CREATE_PAGE_INFO",
          pageType: "REPORTS",
        },
      ]}
      setPageInfoEditMode={setPageInfoEditMode}
    >
      <InfoBox
        pageInfoType={"REPORTS"}
        setPageInfoEditMode={setPageInfoEditMode}
        pageInfoEditMode={pageInfoEditMode}
      />

      <CustomTable
        // rightElement={
        //   <HStack spacing={4}>
        //     <Button size="sm" rightIcon={<IoAddOutline />}>
        //       Add Report
        //     </Button>
        //   </HStack>
        // }
        columns={reportsColumns}
        data={allReports}
        onRowClick={(row) => {
          console.log(row.original.href);
          navigate(row.original.href);
        }}
        tableStyle={{ size: "md" }}
        // doUsePagination={false}
        boxStyle={{
          bg: "white",
          borderRadius: "10px",
          border: "1px",
          borderColor: "gray.200",
          p: 4,
          overflow: "auto",
        }}
      />
    </PageShell>
  );
};

export default ReportsPage;
