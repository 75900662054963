import { Box, Button, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RRule } from "rrule";
import { useUpdateOneOnOneMeeting } from "../../../api/oneOnOne/oneOnOneMeeting";
import { useUpdateOneOnOneSchedule } from "../../../api/oneOnOne/oneOnOneSchedule";
import { useFetchOneOnOneTemplates } from "../../../api/oneOnOne/oneOnOneTemplate";
import { CreatableSelectField } from "../../../components/fields/CreatableSelectField";
import { DateField } from "../../../components/fields/DateField";
import { SelectField } from "../../../components/fields/SelectField";
import useAccountId from "../../../hooks/customDomainHooks";
import { useToastMessage } from "../../../hooks/useToastMessage";
import { getRruleFromFormData, intToWeekday } from "../../../utils/getRrule";
import { ThisOrAllMeetingsModal } from "../modals/ThisOrAllMeetingsModal";
import { RecurrenceFields } from "./RecurrenceFields";

const ruleFrequencyToFrequency = {
  2: "WEEKLY",
  1: "MONTHLY",
};

const UpdateOneOnOneSchedule = ({ onSuccess, meetingData }) => {
  const { accountId } = useAccountId();
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const updateOneOnOneScheduleMutation = useUpdateOneOnOneSchedule();
  const updateOneOnOneMeetingMutation = useUpdateOneOnOneMeeting();
  const [isThisOrAllMeetingsModalOpen, setIsThisOrAllMeetingsModalOpen] =
    useState(false);
  const queryClient = useQueryClient();
  const { data: templates, isLoading: isLoadingTemplates } =
    useFetchOneOnOneTemplates(accountId || "");

  let rule = undefined;
  if (meetingData?.oneOnOneSchedule?.recurrence?.rrule) {
    rule = RRule.fromString(meetingData?.oneOnOneSchedule?.recurrence?.rrule);
  }

  const defaultValues = {
    durationMinutes: meetingData?.durationMinutes,
    frequency: ruleFrequencyToFrequency[rule?.options?.freq],
    interval: rule?.options?.interval,
    byWeekDay: rule?.options?.byweekday?.map((day) => intToWeekday[day]),
    bySetPos: rule?.options?.bysetpos,
    startDate: new Date(meetingData.startDate),
    templateId: meetingData?.templateId,
  };

  const form = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = form;

  // When meetingData changes, update the form default values
  useEffect(() => {
    form.reset(defaultValues);
  }, [meetingData]);

  const updateSchedule = async (data, updateType) => {
    try {
      let ruleStr = getRruleFromFormData(data);

      if (updateType === "thisAndFollowingMeetings") {
        const submitData = {
          durationMinutes: parseInt(data.durationMinutes),
          rrule: ruleStr,
          startDate: data.startDate,
          templateId: data.templateId,
        };
        await updateOneOnOneScheduleMutation.mutateAsync({
          accountId,
          data: submitData,
          oneOnOneScheduleId: meetingData?.oneOnOneScheduleId,
          fromThisMeetingId: meetingData?.id,
        });
      } else {
        const submitData = {
          durationMinutes: parseInt(data.durationMinutes),
          startDate: data.startDate,
          templateId: data.templateId,
        };
        await updateOneOnOneMeetingMutation.mutateAsync({
          accountId,
          data: submitData,
          meetingId: meetingData?.id,
        });
      }

      showSuccessToast("1-on-1 schedule updated");
      await queryClient.invalidateQueries(
        ["fetchOneOnOneMeeting", meetingData.id],
        { refetchInactive: true }
      );
      await queryClient.invalidateQueries(["fetchOneOnOneMeetings"]);
      queryClient.invalidateQueries(["fetchMyTeam"]);
      onSuccess();
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating schedule." });
    }
  };

  const onSubmit = async (data, updateType) => {
    await updateSchedule(data, updateType);
  };

  const recurrenceFieldsHaveChanged = () => {
    const defaultValues = form.getValues();
    const ruleStr = getRruleFromFormData(defaultValues);
    // remove start time from ruleStr
    const originalRuleStr = meetingData?.oneOnOneSchedule?.recurrence?.rrule;
    return (
      ruleStr.split("RRULE:").pop() !== originalRuleStr.split("RRULE:").pop()
    );
  };

  const templateOptions = useMemo(() => {
    if (!templates) return [];
    return templates.map((template) => ({
      label: template.title,
      value: template.id,
    }));
  }, [templates]);

  return (
    <>
      <ThisOrAllMeetingsModal
        isOpen={isThisOrAllMeetingsModalOpen}
        setIsOpen={setIsThisOrAllMeetingsModalOpen}
        onSubmit={async (data) => {
          await onSubmit(form.getValues(), data.updateType);
          setIsThisOrAllMeetingsModalOpen(false);
        }}
      />
      <FormProvider {...form}>
        <form>
          <VStack spacing={4} alignItems={"flex-start"} display={"flex"}>
            <Stack
              w={"100%"}
              direction={{
                base: "column",
                sm: "row",
              }}
            >
              <DateField
                field={{
                  id: "startDate",
                  label: "Start Date",
                  validation: {
                    required: true,
                  },
                }}
                dateFormat={"MMMM d, yyyy h:mm aaaa"}
                showTimeSelect={true}
                datePickerProps={{
                  minDate: new Date(),
                }}
              />
              <HStack w={"100%"} alignItems={"flex-end"} display={"flex"}>
                <Box w={"130px"}>
                  <CreatableSelectField
                    field={{
                      id: "durationMinutes",
                      validation: {
                        required: true,
                      },
                      label: "Duration",
                    }}
                    options={[
                      { value: 15, label: "15" },
                      { value: 30, label: "30" },
                      { value: 45, label: "45" },
                      { value: 60, label: "60" },
                    ]}
                  />
                </Box>
                <Text fontSize={16} fontWeight={500}>
                  minutes
                </Text>
              </HStack>
            </Stack>
            <SelectField
              field={{
                id: "templateId",
                label: "Template",
                validation: {
                  required: false,
                },
              }}
              options={templateOptions}
              isLoading={isLoadingTemplates}
              isClearable={true}
            />
            <RecurrenceFields isUpdating={true} />
            <Button
              isLoading={isSubmitting}
              isDisabled={!isValid}
              onClick={() => {
                // If there was no recurrence before, just save the schedule
                if (!meetingData?.oneOnOneSchedule?.recurrence?.rrule) {
                  handleSubmit(onSubmit)("thisAndFollowingMeetings");
                  return;
                }
                // If updating recurrence, it should always be thisAndFollowingMeetings
                if (recurrenceFieldsHaveChanged()) {
                  handleSubmit(onSubmit)("thisAndFollowingMeetings");
                  return;
                }
                setIsThisOrAllMeetingsModalOpen(true);
              }}
            >
              Save
            </Button>
          </VStack>
        </form>
      </FormProvider>
    </>
  );
};

export default UpdateOneOnOneSchedule;
