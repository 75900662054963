import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { getAuth, updatePassword } from "firebase/auth";
import useToastMessage from "../../../hooks/useToastMessage";
import { UserAuthContext } from "./UserAuthContextComponent";
import PasswordRequirements from "../../../components/common/PasswordRequirements";

export const ChangePasswordModal = (props) => {
  const { isOpen, setIsOpen } = props;
  const { showErrorToast, showSuccessToast } = useToastMessage();
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const { handleAuthErrors } = useContext(UserAuthContext);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const passwordsMatch = password === confirmPassword;
  const passwordCriteria = {
    minLength: password.length >= 8,
    hasNumber: /\d/.test(password),
    hasUppercase: /[A-Z]/.test(password),
    hasLowercase: /[a-z]/.test(password),
    // eslint-disable-next-line no-useless-escape
    hasSpecialCharacter: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
      password
    ),
  };

  useEffect(() => {
    setIsPasswordValid(
      passwordCriteria.minLength &&
        passwordCriteria.hasNumber &&
        passwordCriteria.hasUppercase &&
        passwordCriteria.hasLowercase &&
        passwordCriteria.hasSpecialCharacter &&
        passwordsMatch
    );
  }, [password, confirmPassword, ]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!passwordsMatch || !isPasswordValid) {
      showErrorToast({
        message: !passwordsMatch
          ? "The passwords do not match. Please try again."
          : "Password doesn't meet the requirements. Please try again.",
      });
      return;
    }

    try {
      setIsChangingPassword(true);
      const auth = getAuth();
      // Since we require user to re-authenticate before changing password, we can use the currentUser object
      await updatePassword(auth.currentUser, password);
      showSuccessToast("Password changed successfully");
      setIsOpen(false);
      setIsChangingPassword(false);
      // TODO In dev, emulator I believe there is a firebase bug where the idToken that is returned
      // from updatePassword is not valid valid.
      // window.location.reload();
    } catch (error) {
      setIsChangingPassword(false);
      handleAuthErrors(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>{"Change Password"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={{ base: 2, md: 5 }}>
              {/* <FormControl isRequired>
                <FormLabel htmlFor="currentPassword">
                  Current Password
                </FormLabel>
                <Input id="currentPassword" type="password" />
              </FormControl> */}
              <FormControl isRequired>
                <FormLabel htmlFor="newPassword">New Password</FormLabel>
                <Input
                  id="newPassword"
                  type="password"
                  minLength="8"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <PasswordRequirements
                  password={password}
                  passwordCriteria={passwordCriteria}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="newPasswordConfirm">
                  Confirm New Password
                </FormLabel>
                <Input
                  id="newPasswordConfirm"
                  type="password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              onClick={() => {
                setIsOpen(false);
              }}
              variant="outline"
            >
              Close
            </Button>
            <Button
              variant="solid"
              isLoading={isChangingPassword}
              type="submit"
              isDisabled={!isPasswordValid}
            >
              Change Password
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
