import { useQuery } from "@tanstack/react-query";
import { createContext } from "react";
import api from "../api/config";

export interface Locale {
  value: string;
  label: string;
}

export const LocaleContext = createContext<{ locales: Locale[] }>({
  locales: [],
});
export const LocaleContextComponent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data, isLoading } = useGetLocales();

  const locales = isLoading ? [] : data;

  return (
    <LocaleContext.Provider value={{ locales }}>
      {children}
    </LocaleContext.Provider>
  );
};

const useGetLocales = () => {
  return useQuery({
    queryKey: ["getLocales"],
    queryFn: async () => {
      const response = await api.get("/users/locales");
      return response.data;
    },
  });
};
