import { Box, HStack, Text } from "@chakra-ui/react";
import { BsCaretRightFill } from "react-icons/bs";
import { Link as RouterLink } from "react-router-dom";

export const NavItem = ({
  active,
  startElement,
  children,
  label,
  endElement,
  href,
  externalLink,
  id,
}: {
  active?: boolean;
  startElement?: React.ReactNode;
  children?: React.ReactNode;
  label?: string;
  endElement?: React.ReactNode;
  href?: string;
  externalLink?: string;
  id?: string;
}) => {
  const activeBgColor = "#2F855A1F";
  const activeColor = "primary";

  const Item = () => {
    return (
      <HStack
        w="full"
        p={"6px 12px 6px 16px"}
        mb={2}
        cursor="pointer"
        userSelect="none"
        rounded="md"
        transition="all 0.2s"
        color={active ? activeColor : "textPrimary"}
        borderWidth={"1px"}
        borderColor={active ? activeColor : "transparent"}
        borderRadius={"8px"}
        bg={active ? activeBgColor : "offWhite"}
        _hover={{ bg: activeBgColor }}
        _active={{ bg: activeBgColor, color: activeColor }}
        spacing={4}
        id={id}
      >
        <Box style={{ width: "24px", height: "22px" }}>{startElement}</Box>
        <Box flex="1" fontWeight="inherit">
          <Text
            style={{
              fontWeight: active ? "800" : "500",
            }}
            color={active ? activeColor : "textPrimary"}
            fontSize={"13px"}
          >
            {label}
          </Text>
        </Box>
        {endElement && !children && <Box>{endElement}</Box>}
        {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
      </HStack>
    );
  };

  function getComponent() {
    if (externalLink) {
      // add https:// if not present
      let formattedExternalLink = externalLink;
      if (!externalLink.match(/^https?:\/\//i)) {
        formattedExternalLink = "https://" + externalLink;
      }
      return (
        <Box
          onClick={() => {
            window.open(formattedExternalLink, "_blank", "noopener,noreferrer");
          }}
        >
          <Item />
        </Box>
      );
    } else {
      return (
        <RouterLink
          to={href ? href : "/dashboard"}
          style={{
            textDecoration: "none",
          }}
        >
          <Item />
        </RouterLink>
      );
    }
  }

  return getComponent();
};
