import React from "react";
import { useLocation } from "react-router-dom";
import { Button, Stack, Text } from "@chakra-ui/react";
import AuthLayout from "../../components/AuthLayout";
import axios from "axios";

const ErrorOnAcceptingInvite = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const email = searchParams.get("email")?.replace(" ", "+");
  const accountId = searchParams.get("accountId");
  const [isResending, setIsResending] = React.useState(false);
  const [hasSentEmail, setHasSentEmail] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const resendInviteEmail = async () => {
    try {
      setIsResending(true);
      await axios.post(
        `${
          import.meta.env.VITE_BASE_API_URL
        }/public/requestSendInviteEmail/${accountId}`,
        {
          email: email,
        }
      );
      setIsResending(false);
      setHasSentEmail(true);
    } catch (e) {
      setIsResending(false);
      setIsError(true);
      console.log(e);
    }
  };

  return (
    <AuthLayout>
      <Text fontSize={"2xl"} fontWeight={600} pb={4}>
        Link Expired
      </Text>
      <Stack spacing="6">
        <Text>Your invite link has expired.</Text>
        {!isError && (
          <Button
            isLoading={isResending}
            onClick={resendInviteEmail}
            isDisabled={hasSentEmail}
          >
            Resend Invite Email
          </Button>
        )}
        {isError && (
          <Text>
            We couldn't resend the invite email. This may be because you are not
            invited into the account. Ask your account admin to invite you
            again.
          </Text>
        )}
        {hasSentEmail && (
          <Text>
            We have sent you an email with a new invite link. Please check your
            inbox.
          </Text>
        )}
      </Stack>
    </AuthLayout>
  );
};

export default ErrorOnAcceptingInvite;
