import { HStack, Stack, VStack } from "@chakra-ui/react";
import { InputField } from "../../../../components/fields/InputField";
import QuestionTypeSelector from "../QuestionTypeSelector";
import { NumberField } from "../../../../components/fields/NumberField";

const NumberQuestion = ({ questionIndex, onChange }) => {
  // const { watch } = useFormContext();
  // const minValue = watch(`element.min`);
  // const maxValue = watch(`element.max`);

  return (
    <VStack w={"100%"} spacing={6}>
      <Stack
        w={"100%"}
        gap={4}
        spacing={0}
        flexDirection={{ base: "column", md: "row" }}
      >
        <InputField
          field={{
            id: `prompt`,
            placeholder: "Provide your question prompt",
            validation: {
              required: { value: true, message: "Prompt is required" },
            },
          }}
        />
        <QuestionTypeSelector
          questionIndex={questionIndex}
          onChange={onChange}
        />
      </Stack>
      <HStack w={"100px"} alignSelf={"flex-start"}>
        <NumberField
          field={{
            id: `element.min`,
            label: "Minimum",
          }}
        />
        <NumberField
          field={{
            id: `element.max`,
            label: "Maximum",
          }}
        />
      </HStack>
    </VStack>
  );
};

export default NumberQuestion;
