import { Center } from "@chakra-ui/react";
import CheckIcon from "../../assets/icons/check.svg?react";
import FlagIcon from "../../assets/icons/flag.svg?react";
import TrendDownIcon from "../../assets/icons/trend_down.svg?react";

export const VISIBILITIES = {
  DRAFT: { label: "Draft", color: "#809FB8" },
  PUBLIC: { label: "Public", color: "#33D182" },
  PRIVATE: { label: "Private", color: "#9747FF" },
};

export const PROGRESS_OPTIONS = {
  DONE: {
    label: "Done",
    bg: "#33D18233",
    color: "#33D182",
    icon: <CheckIcon stroke="white" />,
  },
  ON_TARGET: {
    label: "On Track",
    bg: "#33D18233",
    color: "#33D182",
  },
  AT_RISK: {
    label: "At Risk",
    bg: "#F3B50033",
    color: "#F3B500",
    icon: <FlagIcon />,
  },
  OFF_TRACK: {
    label: "Off Track",
    bg: "#EF000033",
    color: "#EF0000",
    icon: <TrendDownIcon />,
  },
};

export const KrLabel = ({ index, width }) => {
  return (
    <div
      style={{
        borderRadius: "6px",
        padding: "8px",
        marginRight: "10px",
        minWidth: width || "75px",
        height: "30px",
        backgroundColor: "#3181CE2E",
      }}
    >
      <Center h={"100%"}>
        <p
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            textAlign: "center",
            color: "#3181CE",
          }}
        >
          KR{String(index + 1)}
        </p>
      </Center>
    </div>
  );
};

export const ObjectiveLabelLong = () => {
  return (
    <div
      style={{
        backgroundColor: "black",
        padding: "10px",
        borderRadius: "6px",
        width: "100px",
      }}
    >
      <p
        style={{
          fontSize: "14px",
          fontWeight: "700",
          textAlign: "center",
          color: "white",
        }}
      >
        Objective
      </p>
    </div>
  );
};
