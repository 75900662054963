import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { Survey } from "./types";

export const useFetchSurveys = (accountId: string) => {
  return useQuery<Survey[]>({
    queryKey: ["fetchSurveys", accountId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/survey`);
      return response.data;
    },
  });
};

export const useFetchSurvey = (accountId: string, surveyId: string) => {
  return useQuery<Survey>({
    queryKey: ["fetchSurvey", surveyId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/survey/${surveyId}`);
      return response.data;
    },
  });
};

export const useCreateSurvey = (
  props?: UseMutationOptions<
    Survey,
    unknown,
    { accountId: string; data: any },
    unknown
  >
) =>
  useMutation({
    mutationKey: ["createSurvey"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/survey`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateSurvey = (
  props?: UseMutationOptions<
    Survey,
    unknown,
    { accountId: string; surveyId: string; data: any },
    unknown
  >
) =>
  useMutation({
    mutationKey: ["updateSurvey"],
    mutationFn: async ({ accountId, surveyId, data }) => {
      const response = await api.put(`/${accountId}/survey/${surveyId}`, data);
      return response.data;
    },
    ...props,
  });

export const useFetchLatestSurveyRunMetrics = (
  accountId: string,
  surveyId: string
) => {
  return useQuery<any>({
    queryKey: ["fetchLatestSurveyrunMetrics", surveyId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/survey/${surveyId}/latest-survey-run-metrics`
      );
      return response.data;
    },
  });
};

export const useFetchSurveyRunMetrics = (
  accountId: string,
  surveyId: string
) => {
  return useQuery<any>({
    enabled: !!surveyId,
    queryKey: ["fetchSurveyrunMetrics", surveyId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/survey/${surveyId}/survey-run-metrics`
      );
      return response.data;
    },
  });
};

export const useDeleteSurvey = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId: string; surveyId: string },
    unknown
  >
) =>
  useMutation({
    mutationKey: ["deleteSurvey"],
    mutationFn: async ({ accountId, surveyId }) => {
      const response = await api.delete(`/${accountId}/survey/${surveyId}`);
      return response.data;
    },
    ...props,
  });
