import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { TerminationTag } from "./types";

export const useCreateTerminationTag = (
  props?: UseMutationOptions<any, Error, { accountId: string; data: any }>
) =>
  useMutation<any, Error, { accountId: string; data: any }>({
    mutationKey: ["createTerminationTag"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/terminationTag`, data);
      return response.data;
    },
    ...props,
  });

export const useDeleteTerminationTag = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId?: string; valueTagId: string }
  >
) =>
  useMutation<any, Error, { accountId?: string; valueTagId: string }>({
    mutationKey: ["deleteTerminationTag"],
    mutationFn: async ({ accountId, valueTagId }) => {
      const response = await api.delete(
        `/${accountId}/terminationTag/${valueTagId}`
      );
      return response.data;
    },
    ...props,
  });

export const useFetchTerminationTags = (accountId: string) => {
  return useQuery<TerminationTag[]>({
    queryKey: ["fetchTerminationTags"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/terminationTag`);
      return response.data;
    },
  });
};
