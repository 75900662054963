import { VStack } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { MessageBox } from "../../../components/common/MessageBox";
import { DualTables } from "../../../components/table/DualTables";
import { AccountContext } from "../../../context/AccountContextComponent";

const EditKbPageViewers = ({ currentViewers, setRightRowsSelected }) => {
  const { accountData } = useContext(AccountContext);

  const profileColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Department",
        accessor: "department.name",
      },
    ],
    [accountData]
  );

  const profilesNotViewers = useMemo(() => {
    if (!accountData) return [];
    const currentViewerIds = currentViewers.map((row) => row.id);
    return accountData.profiles.filter((member) => {
      return member.isActive && !currentViewerIds.includes(member.id);
    });
  }, [currentViewers, accountData.profiles]);

  const profilesCanView = useMemo(() => {
    if (!accountData) return [];
    const currentEditorIds = currentViewers.map((row) => row.id);
    return accountData.profiles.filter((member) => {
      return member.isActive && currentEditorIds.includes(member.id);
    });
  }, [currentViewers, accountData.profiles]);

  return (
    <VStack spacing={4}>
      <MessageBox message={"Select people to add them as viewers."} mb={4} />
      {accountData && (
        <DualTables
          columns={profileColumns}
          initialLeftData={profilesNotViewers}
          initialRightData={profilesCanView}
          onRightRowsChange={setRightRowsSelected}
        />
      )}
    </VStack>
  );
};

export default EditKbPageViewers;
