import {
  Box,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import {
  BiChevronsDown,
  BiChevronsUp,
  BiDotsVerticalRounded,
  BiDuplicate,
} from "react-icons/bi";

const QuestionMenu = ({
  moveQuestion,
  removeQuestion,
  duplicateQuestion,
  questionIndex,
  questionsLength,
  isLoading,
}) => {
  return (
    <Box alignSelf={"flex-start"}>
      <Menu>
        <MenuButton
          as={IconButton}
          variant={"outline"}
          icon={<BiDotsVerticalRounded size={22} />}
          isLoading={isLoading}
        ></MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => {
              moveQuestion(questionIndex, questionIndex - 1);
            }}
            isDisabled={questionIndex == 0}
          >
            <HStack justifyContent={"space-between"} w={"100%"}>
              <Text>Move Up</Text>
              <Icon boxSize={4} as={BiChevronsUp} color={"textSecondary"} />
            </HStack>
          </MenuItem>
          <MenuItem
            onClick={() => {
              moveQuestion(questionIndex, questionIndex + 1);
            }}
            isDisabled={questionIndex == questionsLength - 1}
          >
            <HStack justifyContent={"space-between"} w={"100%"}>
              <Text>Move Down</Text>
              <Icon boxSize={4} as={BiChevronsDown} color={"textSecondary"} />
            </HStack>
          </MenuItem>
          <MenuItem
            onClick={() => {
              duplicateQuestion();
            }}
          >
            <HStack justifyContent={"space-between"} w={"100%"}>
              <Text>Duplicate</Text>
              <Icon boxSize={4} as={BiDuplicate} color={"textSecondary"} />
            </HStack>
          </MenuItem>
          <MenuDivider />
          <MenuItem
            _hover={{ bg: "red.100" }}
            onClick={() => {
              removeQuestion(questionIndex);
            }}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default QuestionMenu;
