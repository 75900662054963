import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";

export const useCreateSlackIntegration = (
  props?: UseMutationOptions<any, unknown, { accountId: string; data: any }>
) =>
  useMutation<any, unknown, { accountId: string; data: any }>({
    mutationKey: ["createSlackIntegration"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/integrations/slack/accessToken`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useUninstallSlackIntegration = (
  props?: UseMutationOptions<any, unknown, { accountId: string }>
) =>
  useMutation<any, unknown, { accountId: string }>({
    mutationKey: ["uninstallSlackIntegration"],
    mutationFn: async ({ accountId }) => {
      const response = await api.delete(
        `/${accountId}/integrations/slack/uninstall`
      );
      return response.data;
    },
    ...props,
  });

export const useListSlackChannels = (
  {
    accountId,
    enabled,
  }: {
    accountId: string;
    enabled?: boolean;
  },
  options?: UseQueryOptions<any[]>
) =>
  useQuery<any[]>({
    queryKey: ["listSlackChannels", accountId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/integrations/slack/channels`
      );
      return response.data;
    },
    enabled,
    ...options,
  });

export const useUpdateSlackIntegration = (
  props?: UseMutationOptions<any, unknown, { accountId: string; data: any }>
) =>
  useMutation<any, unknown, { accountId: string; data: any }>({
    mutationKey: ["updateSlackIntegration"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.put(`/${accountId}/integrations/slack`, data);
      return response.data;
    },
    ...props,
  });

export const useBindSlackUser = (
  props?: UseMutationOptions<any, unknown, { accountId: string; data: any }>
) =>
  useMutation<any, unknown, { accountId: string; data: any }>({
    mutationKey: ["bindSlackUser"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/integrations/slack/bindSlackUser`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useSendSlackWelcomeMessage = (
  props?: UseMutationOptions<any, unknown, { accountId: string; data: any }>
) =>
  useMutation<any, unknown, { accountId: string; data: any }>({
    mutationKey: ["sendSlackWelcomeMessage"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/integrations/slack/sendWelcomeMessage`,
        data
      );
      return response.data;
    },
    ...props,
  });
