import {
  Box,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import get from "lodash/get";
import { Controller, useFormContext } from "react-hook-form";

export const CheckboxGroupField = ({
  field,
  direction = "column",
}: {
  field: any;
  direction?: "row" | "column" | "wrap";
}) => {
  const form = useFormContext();
  const {
    formState: { errors },
    control,
  } = form;

  // const renderFields = field.options.map((option) => (
  //   <Box key={option.value}>
  //     <Checkbox
  //       isDisabled={option.disabled}
  //       key={option.value}
  //       value={option.value}
  //       colorScheme="blue"
  //     >
  //       {option.label}
  //     </Checkbox>
  //   </Box>
  // ));

  return (
    <Controller
      name={field.id}
      control={control}
      rules={field.validation}
      render={({ field: { value, onChange } }) => (
        <FormControl
          isInvalid={!!get(errors, field.id)}
          isRequired={field.validation?.required}
        >
          <FormLabel htmlFor={field.id}>{field.label}</FormLabel>
          <CheckboxGroup value={value} onChange={onChange}>
            {(direction === "row" || direction === "column") && (
              <Stack spacing={3} direction={direction}>
                {field.options.map((option: any) => (
                  <Box key={option.value}>
                    <Checkbox
                      isDisabled={option.disabled}
                      key={option.value}
                      value={option.value}
                      colorScheme="blue"
                    >
                      {option.label}
                    </Checkbox>
                  </Box>
                ))}
              </Stack>
            )}
            {direction === "wrap" && (
              <Wrap spacing={3}>
                {field.options.map((option: any) => (
                  <WrapItem key={option.value}>
                    <Checkbox
                      isDisabled={option.disabled}
                      key={option.value}
                      value={option.value}
                      colorScheme="blue"
                    >
                      {option.label}
                    </Checkbox>
                  </WrapItem>
                ))}
              </Wrap>
            )}
          </CheckboxGroup>
          <FormErrorMessage>
            <>{!!get(errors, field.id) && get(errors, `${field.id}.message`)}</>
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};
