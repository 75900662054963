import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import api from "../config";

export const useCreateBannedReaction = (
  props: UseMutationOptions<
    any,
    Error,
    {
      accountId: string;
      data: any;
    }
  >
) =>
  useMutation<any, Error, { accountId: string; data: any }>({
    mutationKey: ["createBannedReaction"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/bannedReaction`, data);
      return response.data;
    },
    ...props,
  });

interface UseDeleteBannedReactionProps
  extends UseMutationOptions<
    void,
    Error,
    { accountId: string; emoji: string }
  > {}

export const useDeleteBannedReaction = (props: UseDeleteBannedReactionProps) =>
  useMutation<void, Error, { accountId: string; emoji: string }>({
    mutationKey: ["deleteBannedReaction"],
    mutationFn: async ({ accountId, emoji }) => {
      const response = await api.delete(
        `/${accountId}/bannedReaction/${emoji}`
      );
      return response.data;
    },
    ...props,
  });
