import { useParams } from "react-router-dom";
import PageShell from "../../layout/PageShell";
import { useContext, useEffect, useMemo, useState } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import EditAbcRankingReport from "./EditAbcRankingReport";
import { useFetchAbcRankingReport } from "../../api/abcRankings/abcRankingReport";
import ViewAbcRankingReport from "./ViewAbcRankingReport";
import LoadingBox from "../../components/common/LoadingBox";
import { Box, Text } from "@chakra-ui/react";
import { FormattedDate, FormattedTime } from "react-intl";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../hooks/customDomainHooks";

const AbcRankingsReportPage = () => {
  const { reportId } = useParams();
  const { accountId } = useAccountId();
  const { currentProfile, isLoading } = useContext(AccountContext);
  const [viewMode, setViewMode] = useState(undefined);
  const [isSavingReport, setIsSavingReport] = useState(false);

  const {
    data: rankingReport,
    isLoading: isLoadingAbcRankingReport,
    isError: isErrorAbcRankingReport,
    error: errorAbcRankingReport,
  } = useFetchAbcRankingReport(accountId, reportId);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Team Alignment", href: `/${accountId}/ta` },
    { name: rankingReport?.rankingPeriod?.period?.label },
  ]);

  const canEdit = useMemo(() => {
    if (rankingReport?.rankingPeriod?.isOpen === false) return false;

    // Managers cannot edit if they have already submitted
    if (
      rankingReport?.managerId === currentProfile?.id &&
      rankingReport?.submittedOn !== null
    )
      return false;

    // Managers can edit if they have not submitted
    if (
      rankingReport?.managerId === currentProfile?.id &&
      rankingReport?.submittedOn === null
    )
      return true;
    return rankingReport?.viewableBy?.some((viewableBy) => {
      return (
        viewableBy.profile.id === currentProfile?.id && viewableBy.canReview
      );
    });
  }, [isLoading, currentProfile, rankingReport]);

  const canManageReviewers = useMemo(() => {
    if (currentProfile?.role === "OWNER") return true;
    return false;
  }, [isLoading, currentProfile]);

  const canManageViewers = useMemo(() => {
    if (currentProfile?.role === "OWNER") return true;
    if (rankingReport?.managerId === currentProfile?.id) return true;
    return false;
  }, [isLoading, rankingReport, currentProfile]);

  // Show EDIT mode if the report hasn't been submitted and the user can edit
  useEffect(() => {
    if (!rankingReport) return;
    if (viewMode === "VIEW") return; // if already in view mode, don't change
    if (rankingReport?.submittedOn === null && canEdit) {
      setViewMode("EDIT");
    } else {
      setViewMode("VIEW");
    }
  }, [
    rankingReport?.submittedOn,
    rankingReport?.rankingPeriod?.isOpen,
    canEdit,
  ]);

  if (isLoadingAbcRankingReport) {
    return <LoadingBox />;
  }

  // If not open, always show view mode
  // If can review and open, show edit mode
  // If manager and isSubmitter, show view mode
  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      headerComponentRight={
        <Box>
          <Text fontSize={"sm"}>
            {isSavingReport && "Saving... "}
            Last saved {` `}
            <FormattedDate
              value={rankingReport.updatedAt}
              month="short"
              day="2-digit"
            />
            {` at `}
            <FormattedTime value={rankingReport.updatedAt} />
          </Text>
        </Box>
      }
    >
      {!isLoading && viewMode === "EDIT" && canEdit && (
        <EditAbcRankingReport
          rankingReport={rankingReport}
          isLoadingAbcRankingReport={isLoadingAbcRankingReport}
          isErrorAbcRankingReport={isErrorAbcRankingReport}
          errorAbcRankingReport={errorAbcRankingReport}
          setViewMode={setViewMode}
          viewMode={viewMode}
          canManageReviewers={canManageReviewers}
          canManageViewers={canManageViewers}
          setIsSavingReport={setIsSavingReport}
        />
      )}
      {!isLoading && viewMode === "VIEW" && (
        <ViewAbcRankingReport
          rankingReport={rankingReport}
          isLoadingAbcRankingReport={isLoadingAbcRankingReport}
          isErrorAbcRankingReport={isErrorAbcRankingReport}
          errorAbcRankingReport={errorAbcRankingReport}
          canEdit={canEdit}
          setViewMode={setViewMode}
          viewMode={viewMode}
          canManageReviewers={canManageReviewers}
          canManageViewers={canManageViewers}
        />
      )}
    </PageShell>
  );
};

export default AbcRankingsReportPage;
