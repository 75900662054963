import {
  Box,
  Center,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { IoAddSharp } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import { useUpdateAdminDashboardLayout } from "../../api/adminDashboard/adminDashboardLayout";
import {
  useDeleteAdminWidget,
  useUpdateAdminWidget,
} from "../../api/adminDashboard/adminWidget";
import DragIndicator from "../../components/common/Draggable/DragIndicator";
import { DraggableItem } from "../../components/common/Draggable/DraggableItem";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import useToastMessage from "../../hooks/useToastMessage";
import { CultureScoreIndicator } from "../CultureScore/CultureScoreIndicator";
import AddWidgetForm from "./AddWidgetForm";
import CompanyOKRWidget from "./widgets/view/CompanyOKRWidget";
import CultureScoreSeriesWidget from "./widgets/view/CultureScoreSeriesWidget";
import EmployeeRetentionWidget from "./widgets/view/EmployeeRetentionWidget";
import EmployeeTurnoverByReasonWidget from "./widgets/view/EmployeeTurnoverByReasonWidget";
import LatestAbcRankingsWidget from "./widgets/view/LatestAbcRankingsWidget";
import {
  LatestSingleQuestionSurveyResponsesWidget,
  LatestSurveyQuestionResponsesWidget,
} from "./widgets/view/LatestSurveyQuestionResponsesWidget";
import ObjectiveWidget from "./widgets/view/ObjectiveWidget";
import SurveyParticipationWidget from "./widgets/view/SurveyParticipationWidget";
import TimeSeriesAbcRankingsWidget from "./widgets/view/TimeSeriesAbcRankingsWidget";
import TimeSeriesSurveyQuestionResponsesWidget from "./widgets/view/TimeSeriesSurveyQuestionResponsesWidget";

const SMALL_WIDGET_SIZE = {
  width: "380px",
  height: "380px",
};

const MEDIUM_WIDGET_SIZE = {
  width: "776px",
  height: "380px",
};

const WIDGET_SIZE_MAP = {
  SMALL: SMALL_WIDGET_SIZE,
  MEDIUM: MEDIUM_WIDGET_SIZE,
  COMPANY_OKRS: SMALL_WIDGET_SIZE,
  OKRS: SMALL_WIDGET_SIZE,
  ABC_RANKING_LATEST: SMALL_WIDGET_SIZE,
  ABC_RANKING_TIME_SERIES: MEDIUM_WIDGET_SIZE,
  SURVEY_QUESTION_LATEST: SMALL_WIDGET_SIZE,
  SURVEY_QUESTION_SERIES: MEDIUM_WIDGET_SIZE,
  SINGLE_QUESTION_SURVEY_LATEST: SMALL_WIDGET_SIZE,
  SURVEY_PARTICIPATION: SMALL_WIDGET_SIZE,
  CULTURE_SCORE: SMALL_WIDGET_SIZE,
  CULTURE_SCORE_SERIES: MEDIUM_WIDGET_SIZE,
  SURVEY_RUN_PARTICIPATION_LATEST: SMALL_WIDGET_SIZE,
  EMPLOYEE_TURNOVER_BY_REASON: SMALL_WIDGET_SIZE,
  EMPLOYEE_RETENTION: MEDIUM_WIDGET_SIZE,
};

const CultureDashboardLayout = ({ adminDashboardLayout }) => {
  const [widgets, setWidgets] = useState(adminDashboardLayout.adminWidgets);
  const updateAdminDashboardLayoutMutation = useUpdateAdminDashboardLayout();
  const [adminWidgetsOrder, setAdminWidgetsOrder] = useState(
    adminDashboardLayout.adminWidgets.map((widget) => widget.id)
  );
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const previousWidgetsOrderRef = useRef(adminWidgetsOrder);

  const moveWidget = (dragIndex, hoverIndex) => {
    const widget = widgets[dragIndex];
    const newWidgets = [...widgets];
    newWidgets.splice(dragIndex, 1);
    newWidgets.splice(hoverIndex, 0, widget);
    setWidgets(newWidgets);
  };

  const updateAdminDashboardLayout = async (data) => {
    try {
      await updateAdminDashboardLayoutMutation.mutateAsync({
        accountId,
        adminDashboardLayoutId: adminDashboardLayout.id,
        data,
      });
      // queryClient.invalidateQueries([accountId, "fetchAdminDashboardLayout"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating admin dashboard layout" });
    }
  };

  // When widgets move around, update the adminWidgetsOrder state
  useEffect(() => {
    const newAdminWidgetsOrder = widgets.map((widget) => widget.id);
    setAdminWidgetsOrder(newAdminWidgetsOrder);
  }, [widgets]);

  // When adminWidgetsOrder changes, update the admin dashboard layout
  useEffect(() => {
    if (
      JSON.stringify(previousWidgetsOrderRef.current) !==
      JSON.stringify(adminWidgetsOrder)
    ) {
      previousWidgetsOrderRef.current = adminWidgetsOrder;
      updateAdminDashboardLayout({ adminWidgetsOrder });
    }
  }, [adminWidgetsOrder]);

  // When adminDashboardLayout changes, update the widgets state
  useEffect(() => {
    setWidgets(adminDashboardLayout.adminWidgets);
  }, [adminDashboardLayout.adminWidgets]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Wrap spacing={4} direction={"row"} overflowX={"auto"} pr={4}>
        {widgets.map((widget, index) => {
          return (
            <DraggableItem
              key={widget.id}
              id={widget.id}
              index={index}
              moveItem={(dragIndex, hoverIndex) => {
                moveWidget(dragIndex, hoverIndex);
              }}
              setDrag={(drag) => {
                const dragHandle = document.getElementById(
                  `drag-handle-${widget.id}`
                );
                if (dragHandle) {
                  drag(dragHandle);
                }
              }}
            >
              {() => {
                return <WidgetItem widget={widget} />;
              }}
            </DraggableItem>
          );
        })}
        <AddWidgetButton adminDashboardLayout={adminDashboardLayout} />
      </Wrap>
    </DndProvider>
  );
};

const WidgetItem = ({ widget }) => {
  const [widgetTitle, setWidgetTitle] = useState("");
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const updateAdminWidgetMutation = useUpdateAdminWidget();

  const updateAdminWidget = async (adminWidgetId, data) => {
    try {
      await updateAdminWidgetMutation.mutateAsync({
        accountId,
        adminWidgetId,
        data,
      });
      queryClient.invalidateQueries([accountId, "fetchAdminDashboardLayout"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating widget" });
    }
  };

  return (
    <WrapItem
      key={widget.id}
      className="draggableThing"
      bg={"white"}
      borderRadius={"md"}
      boxShadow={"sm"}
      border={"1px"}
      borderColor={"gray.200"}
      w={
        WIDGET_SIZE_MAP[widget.data?.widthSize]?.width ||
        WIDGET_SIZE_MAP[widget.type].width
      }
      h={WIDGET_SIZE_MAP[widget.type].height}
    >
      <Box p={4} w={"100%"} h={"100%"} overflow={"auto"} id={widget.type}>
        <WidgetTitle widgetData={widget} widgetTitle={widgetTitle} />
        {widget.type === "COMPANY_OKRS" && (
          <CompanyOKRWidget widgetData={widget} />
        )}
        {widget.type === "OKRS" && <ObjectiveWidget widgetData={widget} />}
        {widget.type === "ABC_RANKING_LATEST" && (
          <LatestAbcRankingsWidget
            widgetData={widget}
            updateAdminWidget={updateAdminWidget}
          />
        )}
        {widget.type === "ABC_RANKING_TIME_SERIES" && (
          <TimeSeriesAbcRankingsWidget
            widgetData={widget}
            updateAdminWidget={updateAdminWidget}
          />
        )}
        {widget.type === "SURVEY_QUESTION_LATEST" && (
          <LatestSurveyQuestionResponsesWidget
            widgetData={widget}
            setWidgetTitle={setWidgetTitle}
            updateAdminWidget={updateAdminWidget}
          />
        )}
        {widget.type === "SURVEY_QUESTION_SERIES" && (
          <TimeSeriesSurveyQuestionResponsesWidget
            widgetData={widget}
            setWidgetTitle={setWidgetTitle}
            updateAdminWidget={updateAdminWidget}
          />
        )}
        {widget.type === "SINGLE_QUESTION_SURVEY_LATEST" && (
          <LatestSingleQuestionSurveyResponsesWidget
            widgetData={widget}
            setWidgetTitle={setWidgetTitle}
            updateAdminWidget={updateAdminWidget}
          />
        )}
        {widget.type === "CULTURE_SCORE" && (
          <Center w={"100%"} h={"300px"} p={4}>
            <Tooltip>
              <CultureScoreIndicator size={"small"} isClickable={true} />
            </Tooltip>
          </Center>
        )}
        {widget.type === "CULTURE_SCORE_SERIES" && <CultureScoreSeriesWidget />}
        {widget.type === "SURVEY_RUN_PARTICIPATION_LATEST" && (
          <SurveyParticipationWidget
            widgetData={widget}
            setWidgetTitle={setWidgetTitle}
          />
        )}
        {widget.type === "EMPLOYEE_TURNOVER_BY_REASON" && (
          <EmployeeTurnoverByReasonWidget
            widgetData={widget}
            setWidgetTitle={setWidgetTitle}
          />
        )}
        {widget.type === "EMPLOYEE_RETENTION" && (
          <EmployeeRetentionWidget
            widgetData={widget}
            setWidgetTitle={setWidgetTitle}
          />
        )}
      </Box>
    </WrapItem>
  );
};

const WidgetTitle = ({ widgetData, widgetTitle }) => {
  const { accountId } = useAccountId();
  const navigate = useCustomDomainNavigate();

  const getTitleUrl = () => {
    switch (widgetData.type) {
      case "COMPANY_OKRS":
        return `/okrs?tab=Company+OKRs`;
      case "OKRS":
        return `/okrs?objectiveId=${widgetData.data?.objective}`;
      case "ABC_RANKING_LATEST":
        return `/ta`;
      case "ABC_RANKING_TIME_SERIES":
        return `/ta`;
      case "CULTURE_SCORE":
        return `/culture-score`;
      case "CULTURE_SCORE_SERIES":
        return `/culture-score`;
      case "EMPLOYEE_TURNOVER_BY_REASON":
        return `/reports/employee-turnover`;
      case "EMPLOYEE_RETENTION":
        return `/reports/employee-turnover`;
      default:
        return "/home";
    }
  };
  return (
    <HStack w={"100%"} justifyContent={"space-between"}>
      <Box w={"46px"}>
        <DragIndicator id={widgetData.id} width={"15px"} />
      </Box>
      <Box alignSelf={"center"} textAlign={"center"} noOfLines={2}>
        {widgetData.type === "SURVEY_QUESTION_LATEST" ||
        widgetData.type === "SURVEY_QUESTION_SERIES" ||
        widgetData.type === "SINGLE_QUESTION_SURVEY_LATEST" ||
        widgetData.type === "SURVEY_RUN_PARTICIPATION_LATEST" ? (
          <>{widgetTitle}</>
        ) : (
          <Link
            fontWeight={"bold"}
            onClick={() => {
              navigate(`/${accountId}${getTitleUrl()}`);
            }}
            color={"black"}
          >
            {widgetData.title}
          </Link>
        )}
      </Box>
      <Box>
        <ThreeDotsMenu widgetData={widgetData} />
      </Box>
    </HStack>
  );
};

const ThreeDotsMenu = ({ widgetData }) => {
  const { showErrorToast } = useToastMessage();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const deleteAdminWidgetMutation = useDeleteAdminWidget();

  const deleteAdminWidget = async (adminWidgetId) => {
    try {
      await deleteAdminWidgetMutation.mutateAsync({
        accountId,
        adminWidgetId,
      });
      queryClient.invalidateQueries([accountId, "fetchAdminDashboardLayout"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error deleting widget" });
    }
  };
  return (
    <Box>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              isLoading={deleteAdminWidgetMutation.isLoading}
              as={IconButton}
              variant={"ghost"}
              size={"sm"}
              icon={<BiDotsVerticalRounded size={22} />}
            ></MenuButton>
            <MenuList
              style={{
                display: !isOpen ? "none" : "", // <-- Without this, draggable items will select entire screen
              }}
            >
              <MenuItem
                _hover={{ bg: "red.100" }}
                onClick={() => {
                  deleteAdminWidget(widgetData.id);
                }}
              >
                Remove
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  );
};

const AddWidgetButton = ({ adminDashboardLayout }) => {
  const [isHovered, setIsHovered] = useState(true);
  const [showAddWidgetForm, setShowAddWidgetForm] = useState(false);

  return (
    <WrapItem
      key={"create-new-widget"}
      border={"1px dashed"}
      borderColor={"gray.400"}
      w={SMALL_WIDGET_SIZE.width}
      h={SMALL_WIDGET_SIZE.height}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      overflow={"auto"}
      id={"create-new-widget"}
    >
      {showAddWidgetForm ? (
        <HStack p={4} justifyContent={"space-between"} w={"100%"}>
          <Box w={"100%"}>
            <AddWidgetForm
              onSuccess={() => {
                setShowAddWidgetForm(false);
              }}
              adminDashboardLayout={adminDashboardLayout}
            />
          </Box>
          <Box alignSelf={"flex-start"}>
            <IconButton
              icon={<MdClose size={"24px"} />}
              variant="ghost"
              onClick={() => {
                setShowAddWidgetForm(false);
              }}
            />
          </Box>
        </HStack>
      ) : (
        <IconButton
          aria-label="Add widget"
          icon={
            isHovered && (
              <Icon as={IoAddSharp} w={"160px"} h={"160px"} color="gray.600" />
            )
          }
          onClick={() => setShowAddWidgetForm(true)}
          variant={"ghost"}
          w={"100%"}
          h={"100%"}
        />
      )}
    </WrapItem>
  );
};

export default CultureDashboardLayout;
