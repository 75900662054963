import {
  Box,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Wrap,
} from "@chakra-ui/react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useQueryClient } from "@tanstack/react-query";
import { memo, useContext, useEffect, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { IoAddSharp } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import { useUpdateCultureHubLayout } from "../../api/cultureHub/cultureHubLayout";
import {
  useDeleteCultureHubWidget,
  useUpdateCultureHubWidget,
} from "../../api/cultureHub/cultureHubWidget";
import DragAndDrop from "../../components/common/Draggable/BetterDragAndDrop";
import DragIndicator from "../../components/common/Draggable/DragIndicator";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import useToastMessage from "../../hooks/useToastMessage";
import CompanyOKRWidget from "../CultureDashboard/widgets/view/CompanyOKRWidget";
import AddCultureHubWidgetForm from "./AddCultureHubWidgetForm";
import MyOKRsWidget from "./widgets/MyOKRsWidget";
import ShoutoutsWidget from "./widgets/ShoutoutsWidget";
import TopSurveysWidget from "./widgets/TopSurveysWidget";
import UpcomingEventsWidget from "./widgets/UpcomingEventsWidget";
import UsefulLinksWidget from "./widgets/UsefulLinksWidget";

const Container = ({ id, items, itemsData, columnIndex }) => {
  const { currentProfile } = useContext(AccountContext);
  const canEdit = ["ADMIN", "OWNER", "PARTNER"].includes(
    currentProfile?.role || ""
  );

  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <>
      <Box
        ref={setNodeRef}
        w={{ base: "380px", "2xl": "420px" }}
        minHeight={"500px"}
      >
        <SortableContext
          id={id}
          items={items}
          strategy={verticalListSortingStrategy}
        >
          {items.map((itemId) => {
            const data = itemsData.find((item) => item.id === itemId);
            if (!data) return null;
            return (
              <SortableItem key={itemId} id={itemId}>
                {({ attributes, listeners, isDragging }) => (
                  <Widget
                    id={itemId}
                    data={data}
                    attributes={attributes}
                    listeners={listeners}
                    isDragging={isDragging}
                  />
                )}
              </SortableItem>
            );
          })}
        </SortableContext>
        {canEdit && <AddWidgetButton columnIndex={columnIndex} />}
      </Box>
    </>
  );
};

const SortableItem = ({ id, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    // zIndex: isDragging ? 100 : "auto", // Ensure the dragging item is above other elements
    // cursor: isDragging ? "grabbing" : "grab",
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children({ attributes, listeners, isDragging })}
    </div>
  );
};

const Widget = memo(({ id, data, attributes, listeners, isDragging }) => {
  const [isSavingWidget, setIsSavingWidget] = useState(false);
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const updateCultureHubWidgetMutation = useUpdateCultureHubWidget();
  const { currentProfile } = useContext(AccountContext);
  const canEdit = ["ADMIN", "OWNER", "PARTNER"].includes(
    currentProfile?.role || ""
  );
  const [editMode, setEditMode] = useState(false);
  const queryClient = useQueryClient();

  const updateCultureHubWidget = async (cultureHubWidgetId, data) => {
    try {
      setIsSavingWidget(true);
      await updateCultureHubWidgetMutation.mutateAsync({
        accountId,
        cultureHubWidgetId,
        data,
      });
      setIsSavingWidget(false);
      queryClient.invalidateQueries(["fetchCultureHubLayout"]);
    } catch (error) {
      setIsSavingWidget(false);
      console.log(error);
      showErrorToast({ message: "Error updating widget" });
    }
  };

  return (
    <Box pb={4}>
      <Box
        className="draggableThing"
        bg={"white"}
        borderRadius={"md"}
        boxShadow={"sm"}
        border={"1px"}
        borderColor={"gray.200"}
        width={"100%"}
        height="100%"
        p={4}
        id={data.type} // needed for wizard to work
      >
        <WidgetTitle
          widgetData={data}
          attributes={attributes}
          listeners={listeners}
          isSavingWidget={isSavingWidget}
          isDragging={isDragging}
        />
        {data.type === "COMPANY_OKRS" && <CompanyOKRWidget widgetData={data} />}
        {data.type === "MY_OKRS" && <MyOKRsWidget widgetData={data} />}
        {data.type === "UPCOMING_EVENTS" && (
          <UpcomingEventsWidget widgetData={data} />
        )}
        {data.type === "SHOUTOUTS" && <ShoutoutsWidget widgetData={data} />}
        {data.type === "OPEN_SURVEYS" && <TopSurveysWidget widgetData={data} />}
        {data.type === "USEFUL_LINKS" && (
          <UsefulLinksWidget
            widgetData={data}
            updateCultureHubWidget={(data) => {
              updateCultureHubWidget(id, data);
            }}
            editMode={editMode}
            setEditMode={setEditMode}
            canEdit={canEdit}
            showViewButton={true}
          />
        )}
      </Box>
    </Box>
  );
});

const CultureHubLayout = ({ cultureHubLayout }) => {
  const updateCultureHubLayoutMutation = useUpdateCultureHubLayout();
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const [containersList, setContainersList] = useState([[], [], []]);

  const updateCultureHubLayout = async (data) => {
    try {
      await updateCultureHubLayoutMutation.mutateAsync({
        accountId,
        cultureHubLayoutId: cultureHubLayout.id,
        data,
      });
      // queryClient.invalidateQueries(["fetchCultureHubLayout"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating admin dashboard layout" });
    }
  };

  useEffect(() => {
    let newContainersList = cultureHubLayout?.cultureHubWidgetsOrder || [];

    // Make sure containersList has atleast 3 lists
    if (newContainersList.length < 3) {
      const diff = 3 - newContainersList.length;
      for (let i = 0; i < diff; i++) {
        newContainersList.push([]);
      }
    }

    setContainersList(newContainersList);
  }, [cultureHubLayout]);

  return (
    <Wrap spacing={4} direction={"row"} overflowX={"auto"} pr={4}>
      <DragAndDrop
        onUpdate={(updatedItems) => {
          updateCultureHubLayout({ cultureHubWidgetsOrder: updatedItems });
        }}
        containersList={containersList}
      >
        {({ id, items, index }) => (
          <Box key={id}>
            <Container
              id={id}
              items={items}
              key={id}
              itemsData={cultureHubLayout.cultureHubWidgets}
              columnIndex={index}
            />
          </Box>
        )}
      </DragAndDrop>
    </Wrap>
  );
};

const WidgetTitle = ({
  widgetData,
  attributes,
  listeners,
  isSavingWidget,
  isDragging,
}) => {
  const { currentProfile } = useContext(AccountContext);
  const navigate = useCustomDomainNavigate();
  const { accountId } = useAccountId();

  const canEdit = ["ADMIN", "OWNER", "PARTNER"].includes(currentProfile.role);

  const getTitleUrl = () => {
    switch (widgetData.type) {
      case "COMPANY_OKRS":
        return `/okrs?tab=Company+OKRs`;
      case "MY_OKRS":
        return `/okrs?tab=My+OKRs`;
      case "UPCOMING_EVENTS":
        return `/events`;
      case "OPEN_SURVEYS":
        return `/surveys`;
      case "USEFUL_LINKS":
        return `/knowledge-base`;
      case "SHOUTOUTS":
        return `/shoutouts`;
      default:
        return "";
    }
  };

  const style = {
    zIndex: isDragging ? 100 : "auto", // Ensure the dragging item is above other elements
    cursor: isDragging ? "grabbing" : "grab",
  };

  return (
    <HStack w={"100%"} justifyContent={"space-between"} pb={2}>
      {canEdit ? (
        <Box
          {...attributes}
          {...listeners}
          {...style}
          // the role was button which was overwriting the cursor
          role="group"
        >
          <DragIndicator
            id={widgetData.id}
            width={"15px"}
            cursor={style.cursor}
          />
        </Box>
      ) : (
        <Box />
      )}
      <Link
        fontWeight={"bold"}
        onClick={() => {
          navigate(`/${accountId}${getTitleUrl()}`);
        }}
        color={"black"}
      >
        {widgetData.title}
      </Link>
      {/* <Text fontWeight={"bold"}>{widgetData.title}</Text> */}
      {canEdit ? (
        <ThreeDotsMenu
          widgetData={widgetData}
          isSavingWidget={isSavingWidget}
        />
      ) : (
        <Box />
      )}
    </HStack>
  );
};

const ThreeDotsMenu = ({ widgetData, isSavingWidget }) => {
  const { showErrorToast } = useToastMessage();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const deleteCultureHubWidgetMutation = useDeleteCultureHubWidget();

  const deleteCultureHubWidget = async (cultureHubWidgetId) => {
    try {
      await deleteCultureHubWidgetMutation.mutateAsync({
        accountId,
        cultureHubWidgetId,
      });
      queryClient.invalidateQueries(["fetchCultureHubLayout"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error deleting widget" });
    }
  };

  return (
    <Box>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              isLoading={
                deleteCultureHubWidgetMutation.isLoading || isSavingWidget
              }
              as={IconButton}
              variant={"ghost"}
              icon={<BiDotsVerticalRounded size={22} />}
            ></MenuButton>
            <MenuList
              style={{
                display: !isOpen ? "none" : "", // <-- Without this, draggable items will select entire screen
              }}
            >
              <MenuItem
                _hover={{ bg: "red.100" }}
                onClick={() => {
                  deleteCultureHubWidget(widgetData.id);
                }}
              >
                Remove
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  );
};

const AddWidgetButton = ({ columnIndex }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showAddWidgetForm, setShowAddWidgetForm] = useState(false);

  return (
    <Box
      key={"create-new-widget"}
      border={"1px dashed"}
      borderColor={"gray.400"}
      w="100%"
      h={showAddWidgetForm ? "200px" : "100px"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      overflow={"auto"}
    >
      {showAddWidgetForm ? (
        <HStack p={4} w={"100%"} justifyContent={"space-between"}>
          <Box w={"100%"}>
            <AddCultureHubWidgetForm
              onSuccess={() => {
                setShowAddWidgetForm(false);
              }}
              columnIndex={columnIndex}
            />
          </Box>
          <Box alignSelf={"flex-start"}>
            <IconButton
              icon={<MdClose size={"24px"} />}
              variant="ghost"
              onClick={() => {
                setShowAddWidgetForm(false);
              }}
            />
          </Box>
        </HStack>
      ) : (
        <IconButton
          aria-label="Add widget"
          icon={
            isHovered && (
              <Icon as={IoAddSharp} w={"160px"} h={"70px"} color="gray.600" />
            )
          }
          onClick={() => setShowAddWidgetForm(true)}
          variant={"ghost"}
          w={"100%"}
          h={"100%"}
        />
      )}
    </Box>
  );
};

export default CultureHubLayout;
