import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import {
  useBulkCreateProfiles,
  useSendInviteEmail,
} from "../../api/profiles/profiles";
import useAccountId from "../../hooks/customDomainHooks";
import useToastMessage from "../../hooks/useToastMessage";
import CSVMapping from "./CSVMapping";

const TEMPLATE_HEADERS = [
  {
    label: "Name",
    id: "name",
    required: true,
    smart_mappings: ["name", "full_name", "full name", "fullname"],
  },
  {
    label: "Email",
    id: "email",
    required: true,
    smart_mappings: ["email", "email address", "email_address"],
  },
  {
    label: "Title",
    id: "title",
    required: true,
    smart_mappings: ["title", "job_title", "position", "job title", "jobtitle"],
  },
  {
    id: "phoneNumbers",
    label: "Phone Numbers (separated by comma)",
    required: false,
    smart_mappings: [
      "phoneNumbers",
      "phone",
      "phone number",
      "phone numbers",
      "number",
      "numbers",
      "phone_numbers",
      "phonenumbers",
      "tel",
      "telephone",
      "tel number",
      "tel_number",
      "telnumber",
      "telephone number",
      "telephone_number",
      "telephonenumber",
      "#",
    ],
  },
  {
    id: "departmentName",
    label: "Department",
    required: false,
    smart_mappings: [
      "department",
      "dept",
      "department name",
      "department_name",
      "departmentname",
      "org",
      "organization",
      "organization name",
      "organization_name",
      "organizationname",
    ],
  },
  {
    id: "reportsToEmployeeId",
    label: "Reports To (Employee ID)",
    required: false,
    smart_mappings: [
      "manager",
      "boss",
      "reports_to",
      "reports to",
      "reportsto",
      "report",
    ],
  },
  {
    id: "reportsToEmail",
    label: "Reports To (Email)",
    required: false,
    smart_mappings: [
      "manager email",
      "boss email",
      "reportstoemail",
      "reports to email",
    ],
  },
  {
    label: "Employee Id",
    id: "employeeId",
    required: false,
    smart_mappings: ["eid", "employee id", "id", "employee_id", "employeeid"],
  },
  {
    label: "Hired on (YYYY-MM-DD)",
    id: "startDate",
    required: false,
    smart_mappings: [
      "startdate",
      "start date",
      "start_date",
      "starting date",
      "starting_date",
      "startingdate",
      "hire date",
      "hiredate",
      "hire_date",
      "hired_on",
      "hired on",
    ],
  },
];

const ImportEmployees = ({ onDone }) => {
  const [step, setStep] = useState("IMPORT_EMPLOYEES");
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const bulkCreateProfilesMutation = useBulkCreateProfiles();
  const sendInviteEmailMutation = useSendInviteEmail();
  const { showSuccessToast, showErrorToast } = useToastMessage();

  const onDoneClick = () => {
    queryClient.invalidateQueries(["fetchAccount", accountId]);
    onDone();
  };

  const bulkCreateProfiles = async (data) => {
    try {
      await bulkCreateProfilesMutation.mutateAsync({
        accountId,
        data: {
          profiles: data,
        },
      });
      queryClient.invalidateQueries(["fetchAccount", accountId]);
      showSuccessToast(`Members added`);
      setStep("IMPORT_EMPLOYEES_SUCCESS");
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error adding members" });
    }
  };

  const sendInviteEmails = async (emails) => {
    try {
      emails.forEach(async (email) => {
        await sendInviteEmailMutation.mutateAsync({
          accountId,
          data: { email },
        });
      });
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error sending invite emails" });
    }
  };
  if (step === "IMPORT_EMPLOYEES") {
    return (
      <CSVMapping
        templateHeaders={TEMPLATE_HEADERS}
        handleSubmit={async (jsonData, sendInvite) => {
          // remove any empty rows
          jsonData = jsonData.filter((row) => {
            return row.name && row.email;
          });
          await bulkCreateProfiles(jsonData);
          if (sendInvite) {
            console.log("send invites");
            const emails = jsonData.map((row) => row.email);
            await sendInviteEmails(emails);
          }
        }}
        isSubmitting={bulkCreateProfilesMutation.isLoading}
        onCancelClick={onDone}
      />
    );
  } else if (step === "IMPORT_EMPLOYEES_SUCCESS") {
    return (
      <VStack spacing={8} pt={8}>
        <Text fontSize={"xl"}>Members created! </Text>
        <HStack spacing={4}>
          <Button
            onClick={() => {
              setStep("IMPORT_EMPLOYEES");
            }}
            variant={"outline"}
          >
            Add more members
          </Button>
          <Button
            onClick={() => {
              onDoneClick();
            }}
            colorScheme="green"
          >
            Done
          </Button>
        </HStack>
      </VStack>
    );
  }
};

export default ImportEmployees;
