import {
  StackDivider,
  Text,
  VStack,
  Switch,
  Box,
  HStack,
  Button,
  Stack,
} from "@chakra-ui/react";
import {
  GoogleIcon,
  MicrosoftIcon,
} from "../../../auth/components/ProviderIcons";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserContextComponent";
import { DeleteSomethingModal } from "../../../components/modals/DeleteSomethingModal";
import { LinkEmailPasswordModal } from "./LinkEmailPasswordModal";
import { ChangePasswordModal } from "./ChangePasswordModal";
import { TwoFactorAuthModal } from "./TwoFactorAuthModal";
import { UserAuthContext } from "./UserAuthContextComponent";
import { getAuth, multiFactor } from "firebase/auth";
import useToastMessage from "../../../hooks/useToastMessage";
import EmailIcon from "../../../assets/icons/message.svg?react";
import LockIcon from "../../../assets/icons/locked.svg?react";
import UnlockIcon from "../../../assets/icons/unlocked.svg?react";

export const EditUserAuth = () => {
  const [typeToUnlink, setTypeToUnlink] = useState(undefined);
  const [isUnlinkModalOpen, setIsUnlinkModalOpen] = useState(false);
  const [showTwoFactorAuthModal, setShowTwoFactorAuthModal] = useState(false);
  const [showEmailPasswordModal, setShowEmailPasswordModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const { firebaseUser } = useContext(UserContext);
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const {
    lockState,
    authTypeToName,
    handleLockClick,
    linkEmailPasswordAuth,
    linkSocialAuth,
    unlinkAuth,
  } = useContext(UserAuthContext);
  const getDefaultValues = () => {
    return {
      "google.com": firebaseUser.providerData.some(
        (provider) => provider.providerId === "google.com"
      ),
      "microsoft.com": firebaseUser.providerData.some(
        (provider) => provider.providerId === "microsoft.com"
      ),
      password: firebaseUser.providerData.some(
        (provider) => provider.providerId === "password"
      ),
    };
  };
  const [switchState, setSwitchState] = useState(getDefaultValues());
  const auth = getAuth();
  const hasMFAPhone = multiFactor(auth.currentUser).enrolledFactors?.length > 0;
  const [has2FA, setHas2FA] = useState(hasMFAPhone);
  const authenticationItems = [
    {
      id: "password",
      icon: <EmailIcon style={{ width: "22px", height: "18px" }} />,
      boxSize: undefined,
      providerId: "password",
    },
    {
      id: "google.com",
      icon: <GoogleIcon boxSize={6} />,
      boxSize: 8,
      providerId: "google.com",
    },
    {
      id: "microsoft.com",
      icon: <MicrosoftIcon boxSize={6} />,
      boxSize: 8,
      providerId: "microsoft.com",
    },
  ];

  useEffect(() => {
    setSwitchState(getDefaultValues());
  }, [firebaseUser]);

  const handleAuthSwitchChange = (id) => async () => {
    // Check if at least one switch is checked
    const checkedValues = Object.values(switchState).filter(Boolean);
    if (checkedValues.length === 1 && switchState[id]) {
      return;
    }
    if (lockState) {
      return;
    }
    if (switchState[id]) {
      setTypeToUnlink(id);
      setIsUnlinkModalOpen(true);
    } else {
      if (id === "password") {
        setShowEmailPasswordModal(true);
        return;
      }
      const didLink = await linkSocialAuth(id);
      if (didLink) {
        setSwitchState((prevState) => ({
          ...prevState,
          [id]: true,
        }));
      }
    }
  };

  const handle2FASwitchChange = async (e) => {
    if (lockState) {
      return;
    }
    if (e.target.checked) {
      setShowTwoFactorAuthModal(true);
    } else {
      await disableAllPhone2FA();
    }
  };

  const getEnrolledPhoneFactors = () => {
    const enrolledPhoneFactors = [];
    multiFactor(auth.currentUser).enrolledFactors.forEach(async (factor) => {
      if (factor.factorId == "phone") {
        enrolledPhoneFactors.push(factor);
      }
    });
    return enrolledPhoneFactors;
  };

  const disableAllPhone2FA = async () => {
    try {
      const enrolledPhoneFactors = getEnrolledPhoneFactors();
      for (const factor of enrolledPhoneFactors) {
        await multiFactor(auth.currentUser).unenroll(factor.uid);
      }
      showSuccessToast("2FA successfully removed");
      setHas2FA(multiFactor(auth.currentUser).enrolledFactors?.length > 0);
    } catch (error) {
      console.log("error", error);
      if (error?.code === "auth/requires-recent-login") {
        showErrorToast({
          message: "Requires recent login. Please log out and login again",
        });
      } else {
        await showErrorToast({
          message: "Error removing 2FA",
        });
      }
    }
  };

  return (
    <>
      <DeleteSomethingModal
        isOpen={isUnlinkModalOpen}
        setIsOpen={setIsUnlinkModalOpen}
        headerText={`Unlink ${authTypeToName[typeToUnlink]} Authentication`}
        bodyText={`Are you sure you want to unlink ${authTypeToName[typeToUnlink]} authentication?`}
        buttonText={`Remove ${authTypeToName[typeToUnlink]}`}
        deleteFunction={async () => {
          const didUnlink = await unlinkAuth(typeToUnlink);
          if (didUnlink) {
            setSwitchState((prevState) => ({
              ...prevState,
              [typeToUnlink]: false,
            }));
          }
        }}
      />
      <LinkEmailPasswordModal
        isOpen={showEmailPasswordModal}
        setIsOpen={setShowEmailPasswordModal}
        linkAuth={linkEmailPasswordAuth}
      />
      <ChangePasswordModal
        isOpen={showChangePasswordModal}
        setIsOpen={setShowChangePasswordModal}
      />
      <TwoFactorAuthModal
        isOpen={showTwoFactorAuthModal}
        setIsOpen={setShowTwoFactorAuthModal}
        onSuccess={() => {
          setHas2FA(true);
        }}
      />
      <VStack my={8} alignItems={"flex-start"}>
        <HStack w={"100%"} justifyContent={"space-between"}>
          <Text fontSize="lg" fontWeight="bold">
            Authentication
          </Text>
          <Button
            leftIcon={lockState ? <LockIcon /> : <UnlockIcon />}
            variant={"ghost"}
            onClick={handleLockClick}
            size={"sm"}
            color={"gray.600"}
          >
            Modify
          </Button>
        </HStack>
        <VStack spacing={2} w={"100%"} alignItems={"flex-start"}>
          <Box w={"100%"}>
            <Stack
              divider={<StackDivider />}
              borderTop={"1px solid"}
              borderColor={"gray.300"}
              spacing={6}
              alignItems={"center"}
              direction={{ base: "column", md: "row" }}
            >
              <Box
                border={"1px solid"}
                borderColor={"gray.300"}
                borderRadius={"10px"}
                py={4}
                px={6}
                mt={4}
                bg={"#ECF2FA80"}
                h={"250px"}
                w={"100%"}
              >
                <VStack alignItems={"flex-start"} spacing={4}>
                  {authenticationItems.map(({ id, icon, providerId }) => (
                    <VStack alignItems={"flex-start"} spacing={1} key={id}>
                      <HStack>
                        <Switch
                          id={id}
                          isChecked={switchState[id]}
                          onChange={handleAuthSwitchChange(id)}
                          isDisabled={lockState}
                        />
                        {icon}
                        {providerId === "password" &&
                          switchState["password"] && (
                            <Button
                              onClick={() => setShowChangePasswordModal(true)}
                              isDisabled={lockState}
                              variant={"outline"}
                            >
                              Edit Password
                            </Button>
                          )}
                      </HStack>
                      {switchState[id] && (
                        <Text fontSize={"sm"}>
                          {
                            firebaseUser.providerData.find(
                              (provider) => provider.providerId == providerId
                            )?.email
                          }
                        </Text>
                      )}
                    </VStack>
                  ))}
                </VStack>
              </Box>
              <Box
                border={"1px solid"}
                borderColor={"gray.300"}
                borderRadius={"10px"}
                py={4}
                px={6}
                mt={4}
                bg={"#ECF2FA80"}
                h={"250px"}
                w={"100%"}
              >
                <VStack alignItems={"flex-start"} spacing={1}>
                  <Stack direction={{ md: "row" }}>
                    <Switch
                      id={"2fa"}
                      isChecked={has2FA}
                      onChange={handle2FASwitchChange}
                      isDisabled={lockState}
                    />
                    <VStack alignItems={"flex-start"}>
                      <Text fontWeight={"600"} fontSize={"14px"}>
                        Two-factor authentication
                      </Text>
                      {has2FA && (
                        <HStack>
                          <Button
                            variant={"outline"}
                            isDisabled={lockState}
                            onClick={() => setShowTwoFactorAuthModal(true)}
                          >
                            Change Number
                          </Button>

                          <Text fontSize={"sm"}>
                            {(auth.currentUser &&
                              multiFactor(
                                auth.currentUser
                              ).enrolledFactors.find(
                                (factor) => factor.factorId == "phone"
                              )?.displayName) ||
                              ""}
                          </Text>
                        </HStack>
                      )}
                      <Text fontSize={"12px"}>
                        2FA is an ideal extra layer of protection for both your
                        credentials and the resources accessible to your
                        account.
                      </Text>
                    </VStack>
                  </Stack>
                </VStack>
              </Box>
            </Stack>
          </Box>
        </VStack>
      </VStack>
    </>
  );
};
