import { Text, VStack } from "@chakra-ui/react";
import { useMemo } from "react";
import { useFetchSurveys } from "../../../../api/survey/survey";
import { SelectField } from "../../../../components/fields/SelectField";
import useAccountId from "../../../../hooks/customDomainHooks";

const SurveyWidgetConfig = () => {
  const { accountId } = useAccountId();
  const {
    data: surveys,
    isLoading: isLoadingSurveys,
    isError: isErrorSurveys,
    error: errorSurveys,
  } = useFetchSurveys(accountId || "");

  const surveyOptions = useMemo(() => {
    if (!surveys) return [];
    return surveys.map((survey) => ({
      label: survey.title,
      value: survey.id,
    }));
  }, [surveys]);

  if (isErrorSurveys) {
    console.log(errorSurveys);
    return <Text>Error loading surveys</Text>;
  }

  return (
    <VStack>
      <SelectField
        field={{
          id: "data.surveyId",
          label: "Survey",
          validation: { required: true },
        }}
        isLoading={isLoadingSurveys}
        options={surveyOptions}
      />
    </VStack>
  );
};

export default SurveyWidgetConfig;
