import { Button, VStack } from "@chakra-ui/react";
import Papa from "papaparse";
import { useMemo } from "react";
import { MdFileDownload } from "react-icons/md";
import CustomTable from "../../../components/table/CustomTableV2";

const ViewSignups = ({ currentSignups }) => {
  const profileColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "profile.name",
      },
      {
        Header: "Title",
        accessor: "profile.title",
      },
      {
        Header: "Department",
        accessor: "profile.department.name",
      },
    ],
    [currentSignups]
  );

  const downloadCSV = () => {
    const csv = Papa.unparse(
      currentSignups.map((signup) => ({
        name: signup.profile.name,
        email: signup.profile.email,
        department: signup.profile.department,
        employeeId: signup.profile.employeeId,
        title: signup.profile.title,
      }))
    );
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "signups.csv";
    link.click();
  };

  return (
    <VStack spacing={4} alignItems={"flex-start"}>
      {currentSignups && (
        <CustomTable
          columns={profileColumns}
          data={currentSignups}
          doUsePagination
        />
      )}
      <Button
        leftIcon={<MdFileDownload />}
        onClick={downloadCSV}
        alignSelf={"flex-end"}
      >
        Download CSV
      </Button>
    </VStack>
  );
};

export default ViewSignups;
