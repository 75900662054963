import { Box, Spinner, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFetchUpcomingEvents } from "../../api/events/event";
import NoUpcomingEvents from "../../assets/icons/no_events.svg?react";
import useAccountId from "../../hooks/customDomainHooks";
import EventCard from "./EventCard";

const UpcomingEvents = ({ maxEvents, onlyPublished = false }) => {
  const { accountId } = useAccountId();
  const {
    data: upcomingEvents,
    isLoading: isLoadingUpcomingEvents,
    isError: isErrorUpcomingEvents,
    error: errorUpcomingEvents,
  } = useFetchUpcomingEvents(accountId || "");
  const [eventsToShow, setEventsToShow] = useState([]);

  useEffect(() => {
    if (!upcomingEvents) return;
    let newEventsToShow = upcomingEvents;
    if (onlyPublished) {
      newEventsToShow = newEventsToShow.filter((event) => event.isPublished);
    }
    if (maxEvents) {
      newEventsToShow = newEventsToShow.slice(0, maxEvents);
    }
    setEventsToShow(newEventsToShow);
  }, [upcomingEvents]);

  if (isLoadingUpcomingEvents) {
    return <Spinner />;
  }
  if (isErrorUpcomingEvents) {
    console.error(errorUpcomingEvents);
    return <Text>Error loading events!</Text>;
  }

  return (
    <Box>
      <VStack alignItems={"flex-start"} spacing={4}>
        {eventsToShow.length === 0 && (
          <VStack justifyContent={"start"} alignItems={"flex-start"}>
            <NoUpcomingEvents />
            <Text fontSize={"lg"}>No upcoming events</Text>
            <Text fontSize={"lg"} fontWeight={"bold"} color={"primary"}>
              Check back later!
            </Text>
          </VStack>
        )}

        {eventsToShow.map((event) => (
          <EventCard
            key={event.id}
            event={event}
            eventMenuItems={["EDIT", "COPY", "PUBLISH", "DELETE"]}
          />
        ))}
      </VStack>
    </Box>
  );
};

export default UpcomingEvents;
