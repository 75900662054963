import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { KeyResult } from "./types";

export const useFetchKeyResults = (accountId: string) => {
  return useQuery<KeyResult[]>({
    queryKey: ["fetchKeyResults"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/objective/keyresult`);
      return response.data;
    },
  });
};

export const useCreateKeyResult = (
  props?: UseMutationOptions<
    any,
    unknown,
    { accountId: string; data: any },
    unknown
  >
) =>
  useMutation<
    KeyResult,
    unknown,
    { accountId: string; objectiveId: string; data: any }
  >({
    mutationKey: ["createKeyResult"],
    mutationFn: async ({ accountId, objectiveId, data }) => {
      const response = await api.post(
        `/${accountId}/objective/${objectiveId}/keyresult`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useUpdateKeyResult = (
  props?: UseMutationOptions<
    any,
    unknown,
    { accountId: string; data: any },
    unknown
  >
) =>
  useMutation<
    KeyResult,
    unknown,
    {
      accountId: string;
      objectiveId: string;
      keyResultId: string;
      data: any;
    }
  >({
    mutationKey: ["updateKeyResult"],
    mutationFn: async ({ accountId, objectiveId, keyResultId, data }) => {
      const response = await api.put(
        `/${accountId}/objective/${objectiveId}/keyresult/${keyResultId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeleteKeyResult = (
  props?: UseMutationOptions<
    any,
    Error,
    {
      accountId?: string;
      keyResultId: string;
      objectiveId: string;
    }
  >
) =>
  useMutation({
    mutationKey: ["deleteKeyResult"],
    mutationFn: async ({ accountId, objectiveId, keyResultId }) => {
      const response = await api.delete(
        `/${accountId}/objective/${objectiveId}/keyresult/${keyResultId}`
      );
      return response.data;
    },
    ...props,
  });
