import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

export const SubscriptionChangeModal = (props) => {
  const {
    isOpen,
    setIsOpen,
    selectedPlan,
    selectedBillingPeriod,
    onConfirm,
    isCreatingSubscription,
    currentPlan,
    currentBillingPeriod,
    estimatedCurrentInvoiceAmount,
    estimatedNewInvoiceAmount,
    numAdmins,
    numEmployees,
  } = props;

  // Going from monthly to yearly, billing will take effect immediately
  // Going from yearly to monthly, billing will take effect at the end of the current billing period
  const billingPeriodChange = () => {
    if (
      currentBillingPeriod === "MONTHLY" &&
      selectedBillingPeriod === "YEARLY"
    ) {
      return "immediately";
    } else if (
      currentBillingPeriod === "YEARLY" &&
      selectedBillingPeriod === "MONTHLY"
    ) {
      return "at the end of the current billing period";
    } else {
      return "immediately";
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"md"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change Subscription</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          You're about to change your subscription plan from{" "}
          <strong>
            {" "}
            {currentPlan} (
            {selectedBillingPeriod && currentBillingPeriod?.toLowerCase()}){" "}
          </strong>{" "}
          to{" "}
          <strong>
            {selectedPlan} (
            {selectedBillingPeriod && selectedBillingPeriod?.toLowerCase()})
          </strong>
          .
          <br />
          <br />
          Current invoice estimate:{" "}
          <strong>
            $
            {Number(estimatedCurrentInvoiceAmount).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </strong>
          {currentBillingPeriod === "YEARLY" ? "/year" : "/month"}
          <br />
          New invoice estimate:{" "}
          <strong>
            $
            {Number(estimatedNewInvoiceAmount).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </strong>
          {selectedBillingPeriod === "YEARLY" ? "/year" : "/month"}
          <br />
          <Text fontStyle={"italic"} fontSize={"sm"}>
            (Estimates based on {numAdmins} admins and {numEmployees} employees)
          </Text>
          <Text fontSize={"sm"} pt={2}>
            Changing user count and roles may affect your invoice amount. Your
            actual invoice may include proration charges and taxes where
            applicable.
          </Text>
          <br />
          This change will take effect {billingPeriodChange()}.
        </ModalBody>
        <ModalFooter>
          <Button
            variant={"outline"}
            mr={3}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            colorScheme={"primary"}
            isLoading={isCreatingSubscription}
            onClick={() => {
              onConfirm();
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
