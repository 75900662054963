import {
  Box,
  HStack,
  Stack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContext } from "react";
import { AccountContext } from "../../context/AccountContextComponent";
import { PHONE_NUMBER_LABELS } from "../../types/common";
import { formatPhoneNumber } from "react-phone-number-input";

export const ViewProfileInfo = () => {
  const { currentProfile, accountData, isLoading } = useContext(AccountContext);

  if (isLoading) return null;

  const ProfileElement = ({ label, value, defaultValue }) => (
    <HStack alignItems={"flex-start"} w={"100%"} spacing={2}>
      <Text fontWeight={"bold"}>•</Text>
      <Text fontWeight={"bold"}>{label}:</Text>
      {value ? (
        <Text>{value}</Text>
      ) : (
        <Text color={"gray.400"}>{defaultValue}</Text>
      )}
    </HStack>
  );

  const getDepartmentName = (departmentId) => {
    const department = accountData.departments.find(
      (department) => department.id === departmentId
    );
    return department?.name;
  };

  const getManagerName = (managerId) => {
    const manager = accountData.profiles.find(
      (profile) => profile.id === managerId
    );
    return manager?.name;
  };

  return (
    <VStack mt={8} alignItems={"flex-start"}>
      <Text fontSize="lg" fontWeight="bold">
        {accountData.name} Profile
      </Text>
      <Box w={"100%"}>
        <Stack
          divider={<StackDivider />}
          borderTop={"1px solid"}
          borderColor={"gray.300"}
          spacing={6}
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
        >
          <Box px={6} mt={4}>
            <VStack spacing={2} w={"100%"}>
              <ProfileElement label={"Name"} value={currentProfile?.name} />
              <ProfileElement label={"Email"} value={currentProfile?.email} />
              <ProfileElement
                label={"Title"}
                value={currentProfile?.title}
                defaultValue={"No title"}
              />
              <ProfileElement
                label={"Department"}
                value={getDepartmentName(currentProfile?.departmentId)}
                defaultValue={"No department"}
              />
              <ProfileElement
                label={"Manager"}
                value={getManagerName(currentProfile?.reportsToId)}
                defaultValue={"No manager"}
              />
            </VStack>
          </Box>
          <Box px={6} mt={4}>
            {currentProfile?.phoneNumbers.map((phoneNumber, index) => (
              <ProfileElement
                key={index}
                label={PHONE_NUMBER_LABELS[phoneNumber.label]}
                value={formatPhoneNumber(phoneNumber.number)}
              />
            ))}
          </Box>
        </Stack>
      </Box>
    </VStack>
  );
};
