import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";

export const useCreateProfile = (
  props?: UseMutationOptions<any, Error, { accountId: string; data: any }>
) =>
  useMutation({
    mutationKey: ["createProfile"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/profiles`, data);
      return response.data;
    },
    ...props,
  });

export const useBulkCreateProfiles = (
  props?: UseMutationOptions<any, Error, { accountId: string; data: any }>
) =>
  useMutation({
    mutationKey: ["bulkCreateProfiles"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/profiles/bulk`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateProfile = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId: string; profileId: string; data: any }
  >
) =>
  useMutation({
    mutationKey: ["updateProfile"],
    mutationFn: async ({ accountId, profileId, data }) => {
      const response = await api.put(
        `/${accountId}/profiles/${profileId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useUpdateMyProfile = (
  props?: UseMutationOptions<any, Error, { accountId: string; data: any }>
) =>
  useMutation({
    mutationKey: ["updateMyProfile"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.put(`/${accountId}/profiles/me`, data);
      return response.data;
    },
    ...props,
  });

export const useArchiveProfile = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId: string; profileId: string; data: any }
  >
) =>
  useMutation({
    mutationKey: ["archiveProfile"],
    mutationFn: async ({ accountId, profileId, data }) => {
      const response = await api.put(
        `/${accountId}/profiles/archive/${profileId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useSendInviteEmail = (
  props?: UseMutationOptions<any, Error, { accountId: string; data: any }>
) =>
  useMutation({
    mutationKey: ["sendInviteEmail"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/profiles/sendInviteEmail`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useFetchTenureMetrics = (
  accountId: string,
  departments: Array<{ label: string }>,
  ranksToFilter: Array<{ value: string }>
) =>
  useQuery({
    queryKey: ["fetchTenureMetrics", accountId, departments, ranksToFilter],
    queryFn: async () => {
      const departmentQuery = departments
        .map((department) => `&department=${department.label}`)
        .join("&");

      const rankQuery = ranksToFilter
        .map((rank) => `&ranking=${rank.value}`)
        .join("&");

      const response = await api.get(
        `/${accountId}/metrics/profiles/tenure?${departmentQuery}${rankQuery}`
      );
      return response.data;
    },
  });

export const useFetchTurnoverMetrics = (
  accountId: string,
  startDate: string,
  endDate: string,
  tenuresToFilter: Array<{ label: string }>,
  departments: Array<{ label: string }>,
  ranksToFilter: Array<{ value: string }>,
  terminationTagIdsToFilter: Array<{ value: string }>
) =>
  useQuery({
    queryKey: [
      "fetchTurnoverMetrics",
      accountId,
      startDate,
      endDate,
      tenuresToFilter,
      departments,
      ranksToFilter,
      terminationTagIdsToFilter,
    ],
    queryFn: async () => {
      const departmentQuery = departments
        .map((department) => `&department=${department.label}`)
        .join("&");

      const rankQuery = ranksToFilter
        .map((rank) => `&ranking=${rank.value}`)
        .join("&");

      const tenureQuery = tenuresToFilter
        .map((tenure) => `&tenure=${encodeURIComponent(tenure.label)}`)
        .join("&");

      const terminationTagIdQuery = terminationTagIdsToFilter
        .map((reason) => `&terminationTagId=${reason.value}`)
        .join("&");

      const response = await api.get(
        `/${accountId}/metrics/profiles/turnover?startDate=${startDate}&endDate=${endDate}&${departmentQuery}${rankQuery}${tenureQuery}${terminationTagIdQuery}`
      );
      return response.data;
    },
  });

export const useFetchRetentionMetrics = (
  accountId: string,
  dateRange: string,
  tenuresToFilter: Array<{ label: string }>,
  departments: Array<{ label: string }>,
  ranksToFilter: Array<{ value: string }>
) =>
  useQuery({
    queryKey: [
      "fetchRetentionMetrics",
      accountId,
      dateRange,
      tenuresToFilter,
      departments,
      ranksToFilter,
    ],
    queryFn: async () => {
      const departmentQuery = departments
        .map((department) => `&department=${department.label}`)
        .join("&");

      const rankQuery = ranksToFilter
        .map((rank) => `&ranking=${rank.value}`)
        .join("&");

      const tenureQuery = tenuresToFilter
        .map((tenure) => `&tenure=${encodeURIComponent(tenure.label)}`)
        .join("&");

      const response = await api.get(
        `/${accountId}/metrics/profiles/retention?dateRange=${dateRange}&${departmentQuery}${rankQuery}${tenureQuery}`
      );
      return response.data;
    },
  });

export const useFetchActiveEmployeesOnDates = (
  accountId: string,
  dates: string[],
  departments: Array<{ label: string }>,
  ranksToFilter: Array<{ value: string }>,
  tenuresToFilter: Array<{ label: string }>
) =>
  useQuery({
    queryKey: [
      "fetchActiveEmployeesOnDates",
      accountId,
      dates,
      departments,
      ranksToFilter,
      tenuresToFilter,
    ],
    queryFn: async () => {
      const departmentQuery = departments
        .map((department) => `&department=${department.label}`)
        .join("&");

      const rankQuery = ranksToFilter
        .map((rank) => `&ranking=${rank.value}`)
        .join("&");

      const tenureQuery = tenuresToFilter
        .map((tenure) => `&tenure=${encodeURIComponent(tenure.label)}`)
        .join("&");

      const datesQuery = dates?.map((date) => `&date=${date}`).join("&");

      const response = await api.get(
        `/${accountId}/metrics/profiles/getActiveEmployeesOnDates?${datesQuery}${departmentQuery}${rankQuery}${tenureQuery}`
      );
      return response.data;
    },
  });
