import { Box, Spinner, Text } from "@chakra-ui/react";
import { useFetchLatestAbcRankingPeriodMetrics } from "../../../../api/abcRankings/abcRankingPeriod";
import DonutChart from "../../../../components/common/charts/DonutChart";
import { teamAlignment } from "../../../../constants";
import useAccountId from "../../../../hooks/customDomainHooks";
import SampleDataWidget from "./SampleDataWidget";

const LatestAbcRankingsWidget = () => {
  const { accountId } = useAccountId();
  const {
    data: abcRankingPeriodMetrics,
    isLoading: isLoadingAbcRankingPeriodMetrics,
    isError: isErrorAbcRankingPeriodMetrics,
    error: errorAbcRankingPeriodMetrics,
  } = useFetchLatestAbcRankingPeriodMetrics(accountId || "");

  if (isLoadingAbcRankingPeriodMetrics) return <Spinner />;
  if (isErrorAbcRankingPeriodMetrics) {
    console.error(errorAbcRankingPeriodMetrics);
    return <Text>Error loading Team Alignment Period metrics!</Text>;
  }
  if (!abcRankingPeriodMetrics) {
    return (
      <SampleDataWidget
        imageUrl={"team-alignment-widget.png"}
        text={
          "This chart will update with live data after you submit your first Team Alignment report."
        }
        width={346}
        height={280}
        accountId={accountId}
      />
    );
  }

  const data = {
    A: { count: abcRankingPeriodMetrics.aCount, color: teamAlignment.A.color },
    B: { count: abcRankingPeriodMetrics.bCount, color: teamAlignment.B.color },
    C: { count: abcRankingPeriodMetrics.cCount, color: teamAlignment.C.color },
    Unranked: {
      count: abcRankingPeriodMetrics.unrankedCount,
      color: teamAlignment.Unranked.color,
    },
  };
  return (
    <Box w={"100%"}>
      <DonutChart data={data} />
    </Box>
  );
};

export default LatestAbcRankingsWidget;
