import { Button, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { useCreateTeam } from "../../api/teams/team";
import { InputField } from "../../components/fields/InputField";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import { useToastMessage } from "../../hooks/useToastMessage";

const CreateTeam = () => {
  const { accountId } = useAccountId();
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const createTeamMutation = useCreateTeam();
  const navigate = useCustomDomainNavigate();
  const queryClient = useQueryClient();
  const form = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const createTeam = async (data) => {
    try {
      const respData = await createTeamMutation.mutateAsync({
        accountId: accountId || "",
        data,
      });
      console.log(respData);
      showSuccessToast("Team created");
      navigate(`/${accountId}/teams/${respData.id}`);
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating team." });
    }
  };

  const onSubmit = async (data) => {
    await createTeam(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          <InputField
            field={{
              id: "name",
              placeholder: "Team Name",
              label: "Team Name",
              validation: {
                required: { value: true, message: "Name is required" },
              },
            }}
          />
          <Button type="submit" isLoading={isSubmitting}>
            Create Team
          </Button>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default CreateTeam;
