import { Avatar, Box, HStack, Icon, Text } from "@chakra-ui/react";
import { BiCheckCircle } from "react-icons/bi";

export const AvatarWithName = ({
  profile,
  showCheck,
}: {
  profile: any;
  showCheck?: boolean;
}) => {
  return (
    <HStack position="relative">
      <Avatar
        w="38px"
        h="38px"
        objectFit="cover"
        src={profile?.user?.photoUrl}
        name={profile?.name}
      >
        {showCheck && (
          <Box position="absolute" right="-6px" bottom="-16px">
            <Icon
              as={BiCheckCircle}
              color="green.500"
              w={5}
              h={5}
              bg="green.100"
              borderRadius="full"
            />
          </Box>
        )}
      </Avatar>
      <Text color="textPrimary" fontWeight={400}>
        {profile?.name}
      </Text>
    </HStack>
  );
};
