import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import api from "../config";
import { CultureHubWidget } from "./types";

interface CreateCultureHubWidgetProps
  extends UseMutationOptions<
    CultureHubWidget,
    unknown,
    { accountId: string; data: any }
  > {}
interface DeleteCultureHubWidgetProps
  extends UseMutationOptions<
    CultureHubWidget,
    unknown,
    { accountId: string; cultureHubWidgetId: string }
  > {}
interface UpdateCultureHubWidgetProps
  extends UseMutationOptions<
    CultureHubWidget,
    unknown,
    {
      accountId: string;
      cultureHubWidgetId: string;
      data: any;
    }
  > {}

export const useCreateCultureHubWidget = (
  props?: CreateCultureHubWidgetProps
) =>
  useMutation<CultureHubWidget, unknown, { accountId: string; data: any }>({
    mutationKey: ["createCultureHubWidget"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/cultureHubWidget`, data);
      return response.data;
    },
    ...props,
  });

export const useDeleteCultureHubWidget = (
  props?: DeleteCultureHubWidgetProps
) =>
  useMutation<
    CultureHubWidget,
    unknown,
    { accountId: string; cultureHubWidgetId: string }
  >({
    mutationKey: ["deleteCultureHubWidget"],
    mutationFn: async ({ accountId, cultureHubWidgetId }) => {
      const response = await api.delete(
        `/${accountId}/cultureHubWidget/${cultureHubWidgetId}`
      );
      return response.data;
    },
    ...props,
  });

export const useUpdateCultureHubWidget = (
  props?: UpdateCultureHubWidgetProps
) =>
  useMutation<
    CultureHubWidget,
    unknown,
    {
      accountId: string;
      cultureHubWidgetId: string;
      data: any;
    }
  >({
    mutationKey: ["updateCultureHubWidget"],
    mutationFn: async ({ accountId, cultureHubWidgetId, data }) => {
      const response = await api.put(
        `/${accountId}/cultureHubWidget/${cultureHubWidgetId}`,
        data
      );
      return response.data;
    },
    ...props,
  });
