import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import api from "../config";
import { PageInfo } from "./types";

export const useCreatePageInfo = (
  props?: UseMutationOptions<PageInfo, Error, { accountId: string; data: any }>
) =>
  useMutation<PageInfo, Error, { accountId: string; data: any }>({
    mutationKey: ["createPageInfo"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/pageInfo`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdatePageInfo = (
  props?: UseMutationOptions<
    PageInfo,
    Error,
    { accountId: string; pageInfoId: string; data: any }
  >
) =>
  useMutation<
    PageInfo,
    Error,
    { accountId: string; pageInfoId: string; data: any }
  >({
    mutationKey: ["updatePageInfo"],
    mutationFn: async ({ accountId, pageInfoId, data }) => {
      const response = await api.put(
        `/${accountId}/pageInfo/${pageInfoId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeletePageInfo = (
  props?: UseMutationOptions<
    PageInfo,
    Error,
    { accountId: string; pageInfoId: string }
  >
) =>
  useMutation<PageInfo, Error, { accountId: string; pageInfoId: string }>({
    mutationKey: ["deletePageInfo"],
    mutationFn: async ({ accountId, pageInfoId }) => {
      const response = await api.delete(`/${accountId}/pageInfo/${pageInfoId}`);
      return response.data;
    },
    ...props,
  });
