import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Stack,
  Switch,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useContext, useMemo, useState } from "react";
import { useFetchOneOnOneMeetings } from "../../api/oneOnOne/oneOnOneMeeting";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId from "../../hooks/customDomainHooks";
import { NotesFilter } from "./Filters/MeetingNotesFilterSelect";
import { MeetingParticipantFilterSelect } from "./Filters/MeetingParticipantFilterSelect";
import { MeetingStatusFilterSelect } from "./Filters/MeetingStatusFilterSelect";
import { OneOnOnesTable } from "./OneOnOnesTable";

const AllMyOneOnOnes = () => {
  const { accountId } = useAccountId();
  const {
    data: oneOnOneMeetings,
    isLoading: isLoadingOneOnOneMeetings,
    isError: isErrorOneOnOneMeetings,
    error: errorOneOnOneMeetings,
  } = useFetchOneOnOneMeetings(accountId || "");
  const { currentProfile } = useContext(AccountContext);
  const [participantsToFilter, setParticipantsToFilter] = useState([]);
  const [statusesToFilter, setStatusesToFilter] = useState([]);
  const [noteOptionFilterValue, setNoteOptionFilterValue] = useState("all");
  const [showArchived, setShowArchived] = useState(false);

  const isManager = currentProfile?._count?.reports > 0;

  const participantFilterOptions = useMemo(() => {
    // Create a set of unique invitee IDs
    const participantIdSet = new Set();
    const uniqueParticipants = [];
    oneOnOneMeetings?.forEach((meeting) => {
      if (!participantIdSet.has(meeting.invitee.id)) {
        participantIdSet.add(meeting.invitee.id);
        uniqueParticipants.push(meeting.invitee);
      }
      if (!participantIdSet.has(meeting.creator.id)) {
        participantIdSet.add(meeting.creator.id);
        uniqueParticipants.push(meeting.creator);
      }
    });

    return uniqueParticipants
      ?.filter(
        (participant) =>
          !participantsToFilter.some(
            (inviteeSelected) => inviteeSelected.value === participant.id
          )
      )
      .map((participant) => ({
        label: participant.name,
        value: participant.id,
      }));
  }, [oneOnOneMeetings, participantsToFilter]);

  const statusFilterOptions = useMemo(() => {
    const statuses = [
      { label: "Completed", value: "Held" },
      { label: "Scheduled", value: "NoStatus" },
      { label: "Skipped", value: "Skipped" },
    ];

    return statuses.filter(
      (status) =>
        !statusesToFilter.some(
          (statusSelected) => statusSelected.value === status.value
        )
    );
  }, [statusesToFilter]);

  const meetingsToShow = useMemo(() => {
    // Filter on isArchived
    let finalMeetings = oneOnOneMeetings?.filter(
      (meeting) => meeting.isArchived === showArchived
    );

    // Filter on participants
    if (participantsToFilter?.length > 0) {
      finalMeetings = oneOnOneMeetings
        ?.filter((meeting) =>
          participantsToFilter.some(
            (participantSelected) =>
              participantSelected.value === meeting.invitee.id ||
              participantSelected.value === meeting.creator.id
          )
        )
        ?.filter((meeting) => meeting.isArchived === showArchived);
    }

    // Filter on statuses
    if (statusesToFilter?.length > 0) {
      finalMeetings = finalMeetings?.filter((meeting) =>
        statusesToFilter.some(
          (statusSelected) => statusSelected.value === meeting.status
        )
      );
    }

    // Filter on notes
    finalMeetings = finalMeetings?.filter((meeting) => {
      if (noteOptionFilterValue === "all") {
        return true;
      } else if (
        noteOptionFilterValue === "hasNotes" // private note OR isUpdatedByUser is true
      ) {
        const privateNote = meeting.creatorPrivateNote;
        if (privateNote && privateNote.content?.length > 0) {
          return true;
        }
        return meeting.sharedNote?.isUpdatedByUser;
      } else if (
        noteOptionFilterValue === "noNotes" // no private note AND isUpdatedByUser is false
      ) {
        const privateNote = meeting.creatorPrivateNote;
        if (privateNote?.content?.length > 0) {
          return false;
        }
        return !meeting.sharedNote.isUpdatedByUser;
      } else if (
        noteOptionFilterValue === "hasPrivateNote" // isUpdatedByUser is true
      ) {
        const privateNote = meeting.creatorPrivateNote;
        if (!privateNote || privateNote.content?.length === 0) {
          return false;
        }
        return privateNote && privateNote.content?.length > 0;
      }
    });

    return finalMeetings;
  }, [
    oneOnOneMeetings,
    participantsToFilter,
    showArchived,
    statusesToFilter,
    noteOptionFilterValue,
  ]);

  const showPrivateNoteOptionInFilter = useMemo(() => {
    return oneOnOneMeetings?.some(
      (meeting) => meeting.creatorId === currentProfile.id
    );
  }, [oneOnOneMeetings]);

  return (
    <Box>
      <VStack
        w={"100%"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        gap={0}
        maxW={1300}
      >
        <Text fontSize={"2xl"} fontWeight={"bold"}>
          1-on-1s
        </Text>
        <OneOnOnesTable
          oneOnOneMeetings={meetingsToShow}
          isLoadingOneOnOneMeetings={isLoadingOneOnOneMeetings}
          isErrorOneOnOneMeetings={isErrorOneOnOneMeetings}
          errorOneOnOneMeetings={errorOneOnOneMeetings}
          showCreateButton={isManager}
          tableInitialState={{
            pageSize: 10,
          }}
          filterElements={
            <Stack
              direction={{ base: "column", sm: "row" }}
              alignItems={"baseline"}
            >
              <Text fontWeight={700} whiteSpace={"nowrap"}>
                Filtered by:
              </Text>
              <Box display={"flex"} flex={1} justifyContent={"space-between"}>
                <Box>
                  <Wrap>
                    {participantsToFilter?.map((invitee) => (
                      <WrapItem key={invitee.value}>
                        <Tag
                          borderRadius="full"
                          variant="outline"
                          colorScheme="blue"
                          bg="#4285F414"
                        >
                          <TagLabel>{invitee.label}</TagLabel>
                          <TagCloseButton
                            onClick={() => {
                              setParticipantsToFilter(
                                participantsToFilter?.filter(
                                  (o) => o.value !== invitee.value
                                )
                              );
                            }}
                          />
                        </Tag>
                      </WrapItem>
                    ))}
                    {statusesToFilter?.map((status) => (
                      <WrapItem key={status.value}>
                        <Tag
                          borderRadius="full"
                          variant="outline"
                          colorScheme="blue"
                          bg="#4285F414"
                        >
                          <TagLabel>{status.label}</TagLabel>
                          <TagCloseButton
                            onClick={() => {
                              setStatusesToFilter(
                                statusesToFilter?.filter(
                                  (o) => o.value !== status.value
                                )
                              );
                            }}
                          />
                        </Tag>
                      </WrapItem>
                    ))}
                    <WrapItem>
                      <MeetingParticipantFilterSelect
                        participantFilterOptions={participantFilterOptions}
                        participantsSelected={participantsToFilter}
                        onSelection={(newParticipant) => {
                          setParticipantsToFilter(
                            participantsToFilter?.concat(newParticipant)
                          );
                        }}
                      />
                    </WrapItem>
                    <WrapItem>
                      <MeetingStatusFilterSelect
                        statusOptions={statusFilterOptions}
                        statusesSelected={statusesToFilter}
                        onSelection={(newStatus) => {
                          setStatusesToFilter(
                            statusesToFilter?.concat(newStatus)
                          );
                        }}
                      />
                      <Divider
                        orientation={"vertical"}
                        ml={2}
                        mr={2}
                        borderColor={"gray.500"}
                      />
                    </WrapItem>
                    <WrapItem alignItems={"center"}>
                      <NotesFilter
                        showPrivateNoteOptionInFilter={
                          showPrivateNoteOptionInFilter
                        }
                        noteOptionFilterValue={noteOptionFilterValue}
                        onSelection={(newNoteOption) => {
                          setNoteOptionFilterValue(newNoteOption);
                        }}
                      />
                    </WrapItem>
                  </Wrap>
                </Box>
                <Box>
                  <FormControl
                    display="flex"
                    alignItems="center"
                    gap={2}
                    justifyContent={"flex-end"}
                  >
                    <Switch
                      isChecked={showArchived}
                      onChange={(e) => {
                        setShowArchived(e.target.checked);
                      }}
                      id="showArchived"
                    ></Switch>
                    <FormLabel htmlFor="showArchived" mb={0} fontWeight={400}>
                      Show Deleted
                    </FormLabel>
                  </FormControl>
                </Box>
              </Box>
            </Stack>
          }
        />
      </VStack>
    </Box>
  );
};

export default AllMyOneOnOnes;
