import { useParams } from "react-router-dom";
import PageShell from "../../../layout/PageShell";
import { Text } from "@chakra-ui/react";
import { useContext } from "react";
import { AccountContext } from "../../../context/AccountContextComponent";
import LoadingBox from "../../../components/common/LoadingBox";
import { useFetchMySurveyRunResponse } from "../../../api/survey/surveyRunResponse";
import TakeSurvey from "./TakeSurvey";
import useAccountId, { useBreadcrumbsWithCustomDomain } from "../../../hooks/customDomainHooks";

const TakeSurveyPage = () => {
  const { surveyRunId } = useParams();
  const { accountId } = useAccountId();
  const { isLoading: isLoadingAccount, currentProfile } =
    useContext(AccountContext);

  const {
    data: surveyRunResponse,
    isLoading: isLoadingSurveyRunResponse,
    isError,
    error,
  } = useFetchMySurveyRunResponse(accountId, surveyRunId, currentProfile?.id);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Surveys", href: `/${accountId}/surveys` },
    { name: "Take Survey" },
  ]);

  if (isLoadingAccount || isLoadingSurveyRunResponse) {
    return <LoadingBox />;
  }

  return (
    <PageShell breadcrumbs={breadcrumbs}>
      {isError && <Text>{error.message}</Text>}
      {surveyRunResponse?.surveyRun && (
        <TakeSurvey surveyRunResponse={surveyRunResponse} />
      )}
    </PageShell>
  );
};

export default TakeSurveyPage;
