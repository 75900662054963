import { Box, Spinner, Text, VStack } from "@chakra-ui/react";
import { useFetchMyEvents } from "../../api/events/event";
import useAccountId from "../../hooks/customDomainHooks";
import EventCard from "./EventCard";

const MyEvents = () => {
  const { accountId } = useAccountId();
  const { data: myEvents, isLoading: isLoadingMyEvents } = useFetchMyEvents(
    accountId || ""
  );

  if (isLoadingMyEvents) {
    return <Spinner />;
  }

  return (
    <Box>
      <VStack alignItems={"flex-start"} spacing={4}>
        {myEvents?.length === 0 && (
          <Box>
            <Text>No events to show.</Text>
          </Box>
        )}
        {myEvents?.map((event) => (
          <EventCard
            key={event.id}
            event={event}
            eventMenuItems={["EDIT", "COPY", "PUBLISH", "REMOVE", "DELETE"]}
          />
        ))}
      </VStack>
    </Box>
  );
};

export default MyEvents;
