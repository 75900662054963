import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { AppUser } from "./types";

export const useCreateUser = (
  props?: UseMutationOptions<AppUser, Error, void>
) =>
  useMutation<AppUser, Error, void>({
    mutationKey: ["createUser"],
    mutationFn: async () => {
      const response = await api.post("/users");
      return response.data;
    },
    ...props,
  });

export const useGetMe = () => {
  return useQuery<AppUser, Error>({
    queryKey: ["getMe"],
    queryFn: async () => {
      const response = await api.get("/users/me");
      return response.data;
    },
  });
};

export const useUpdateUser = (
  props?: UseMutationOptions<AppUser, Error, any>
) => {
  return useMutation<AppUser, Error, any>({
    mutationKey: ["updateUser"],
    mutationFn: async (data: any) => {
      const response = await api.put("/users/me", data);
      return response.data;
    },
    ...props,
  });
};
