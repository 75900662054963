import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useUpdateEvent } from "../../../api/events/event";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import EditInvites from "../EditEvent/EditInvites";

export const EditEventInvitesModal = (props) => {
  const { isOpen, setIsOpen, currentInvited } = props;
  const { eventId } = useParams();
  const { accountId } = useAccountId();
  const updateEventMutation = useUpdateEvent();
  const queryClient = useQueryClient();
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const [rightRowsSelected, setRightRowsSelected] = useState([]);

  const inviteToEvent = async (data) => {
    try {
      const membersToInvite = data.map((row) => {
        return row.id;
      });
      await updateEventMutation.mutateAsync({
        accountId,
        eventId,
        data: { invitees: membersToInvite },
      });
      queryClient.invalidateQueries(["fetchEvent", eventId]);
      showSuccessToast(`Invitees updated`);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error adding people as invited" });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"5xl"}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite people</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EditInvites
            currentInvited={currentInvited}
            setRightRowsSelected={setRightRowsSelected}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            mr={4}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            onClick={() => inviteToEvent(rightRowsSelected)}
            isLoading={updateEventMutation.isLoading}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
