import { Button, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IoAddOutline } from "react-icons/io5";
import { useCreateAdminWidget } from "../../api/adminDashboard/adminWidget";
import { SelectField } from "../../components/fields/SelectField";
import useAccountId from "../../hooks/customDomainHooks";
import useToastMessage from "../../hooks/useToastMessage";
import OkrWidgetConfig from "./widgets/edit/OkrWidgetConfig";
import RetentionConfig from "./widgets/edit/RetentionConfig";
import SurveyQuestionWidgetConfig from "./widgets/edit/SurveyQuestionWidgetConfig";
import SurveyWidgetConfig from "./widgets/edit/SurveyWidgetConfig";
import TurnoverConfig from "./widgets/edit/TurnoverConfig";

const AddWidgetForm = ({ onSuccess, adminDashboardLayout }) => {
  const createAdminWidgetMutation = useCreateAdminWidget();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const { accountId } = useAccountId();
  const form = useForm({
    mode: "onChange",
    defaultValues: { type: "COMPANY_OKRS", data: {} },
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, isValid },
  } = form;

  const widgetTypes = [
    {
      label: "OKRS",
      options: [
        { label: "Company OKRs", value: "COMPANY_OKRS" },
        { label: "OKRs", value: "OKRS" },
      ],
    },
    {
      label: "Surveys",
      options: [
        { label: "Survey Question Latest", value: "SURVEY_QUESTION_LATEST" },
        { label: "Survey Question Series", value: "SURVEY_QUESTION_SERIES" },
        {
          label: "Survey Participation",
          value: "SURVEY_RUN_PARTICIPATION_LATEST",
        },
      ],
    },
    {
      label: "Team Alignment",
      options: [
        {
          label: "Latest Team Alignment Submissions",
          value: "ABC_RANKING_LATEST",
        },
        { label: "Team Alignment Over Time", value: "ABC_RANKING_TIME_SERIES" },
      ],
    },
    {
      label: "Culture",
      options: [
        { label: "Culture Success Score", value: "CULTURE_SCORE" },
        {
          label: "Culture Success Score Over Time",
          value: "CULTURE_SCORE_SERIES",
        },
      ],
    },
    {
      label: "Retention",
      options: [
        {
          label: "Recent Employee Turnover By Reason",
          value: "EMPLOYEE_TURNOVER_BY_REASON",
        },
        { label: "Employee Retention Over Time", value: "EMPLOYEE_RETENTION" },
      ],
    },
  ];

  const widgetType = watch("type");

  useEffect(() => {
    console.log("widgetType", widgetType);
    // If widgetType changes, reset the data field
    if (widgetType === "ABC_RANKING_TIME_SERIES") {
      setValue(
        "data",
        {
          displayPercentages: true,
        },
        { shouldValidate: true }
      );
    } else if (widgetType === "EMPLOYEE_TURNOVER_BY_REASON") {
      setValue(
        "data",
        {
          dayRange: 90,
        },
        { shouldValidate: true }
      );
    } else if (widgetType === "EMPLOYEE_RETENTION") {
      setValue(
        "data",
        {
          dateRange: "6_QUARTERS",
        },
        { shouldValidate: true }
      );
    } else {
      setValue("data", {}, { shouldValidate: true });
    }
  }, [widgetType, setValue]);

  const createAdminWidget = async (data) => {
    try {
      await createAdminWidgetMutation.mutateAsync({
        accountId,
        data: {
          ...data,
          adminDashboardLayoutId: adminDashboardLayout.id,
        },
      });
      queryClient.invalidateQueries([accountId, "fetchAdminDashboardLayout"]);
      onSuccess();
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating admin widget" });
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    createAdminWidget(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack alignItems={"flex-start"} spacing={4}>
          <SelectField
            field={{
              id: "type",
              label: "Widget Type",
              validation: { required: true },
            }}
            options={widgetTypes}
          />
          {widgetType === "OKRS" && <OkrWidgetConfig />}
          {widgetType === "SURVEY_QUESTION_LATEST" && (
            <SurveyQuestionWidgetConfig type={"SURVEY_QUESTION_LATEST"} />
          )}
          {widgetType === "SURVEY_QUESTION_SERIES" && (
            <SurveyQuestionWidgetConfig
              disallowedQuestionTypes={["TEXT"]}
              type={"SURVEY_QUESTION_SERIES"}
            />
          )}
          {widgetType === "SURVEY_RUN_PARTICIPATION_LATEST" && (
            <SurveyWidgetConfig />
          )}

          {widgetType === "EMPLOYEE_TURNOVER_BY_REASON" && <TurnoverConfig />}
          {widgetType === "EMPLOYEE_RETENTION" && <RetentionConfig />}

          <Button
            mt={4}
            isLoading={createAdminWidgetMutation.isLoading}
            type="submit"
            isDisabled={isSubmitting || !isValid}
            leftIcon={<IoAddOutline />}
          >
            Add Widget
          </Button>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default AddWidgetForm;
