import { useContext, useEffect, type ReactNode } from "react";
import { Box, Button, Divider, HStack, Icon } from "@chakra-ui/react";
import EditorBubble from "./EditorBubble";
import { Editor } from "@tiptap/react";
import Magic from "../ui/MagicIcon";
import { AskAiSelector } from "./AskAiSelector";
import { removeAIHighlight } from "../extensions/AiHighlight";
import { TipTapAiContext } from "../../../../context/TipTapAiContextComponent";

interface GenerativeMenuSwitchProps {
  editor: Editor;
  children: ReactNode;
}
const GenerativeMenuSwitch = ({
  children,
  editor,
}: GenerativeMenuSwitchProps) => {
  const { openAiMenu, setOpenAiMenu } = useContext(TipTapAiContext);

  useEffect(() => {
    if (!openAiMenu) {
      removeAIHighlight(editor);
    }
  }, [openAiMenu, editor]);

  return (
    <EditorBubble
      tippyOptions={{
        placement: openAiMenu ? "bottom-start" : "top",
        onHidden: () => {
          console.log("onHidden close menu");
          setOpenAiMenu(false);
          editor?.chain().unsetHighlight().run();
        },
        onClickOutside: () => {
          console.log("onClickOutside");
          setOpenAiMenu(false);
          // editor?.chain().unsetHighlight().run();
          // editor?.chain().setTextSelection({ from: 0, to: 0 }).run();
        },
        appendTo: () => {
          return document.getElementById("root") as HTMLElement;
        },
      }}
      editor={editor}
      openAiMenu={openAiMenu}
    >
      <Box display={"flex"} flexDirection={"column"}>
        {/* This is the menu for different AI actions ie Fix Grammer, Improve Writing */}
        {openAiMenu && <AskAiSelector editor={editor} isOpen={openAiMenu} />}
        {/* This is the "Ask AI" button + the base menu options to the right of the button*/}
        {!openAiMenu && (
          <HStack
            bg="white"
            rounded="md"
            border="1px solid"
            borderColor="borderPrimary"
            shadow="xl"
            h={"42px"}
            gap={0}
            overflow={"hidden"}
          >
            <Button
              variant="ghost"
              onClick={() => setOpenAiMenu(true)}
              size="sm"
              color={"primary"}
              h={"100%"}
              borderRadius={0}
            >
              <Icon as={Magic} />
              <Box w={"4px"} />
              Ask AI
            </Button>
            {children && (
              <Divider
                orientation="vertical"
                color={"textPrimary"}
                display={"flex"}
                h={"100%"}
                w={"1px"}
              />
            )}
            {children}
          </HStack>
        )}
      </Box>
    </EditorBubble>
  );
};

export default GenerativeMenuSwitch;
