import { Link } from "react-router-dom";
import { BiLinkExternal } from "react-icons/bi";
import {
  Box,
  Button,
  Container,
  Flex,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useAcceptTos } from "../../api/tosAcceptance/tosAcceptance";
import useAccountId from "../../hooks/customDomainHooks";
import { useQueryClient } from "@tanstack/react-query";
import useToastMessage from "../../hooks/useToastMessage";
import BackgroundImageGallery from "../../components/common/BackgroundImageGallery";
import TBALogo from "../../assets/tba-frame.png";

const AcceptTos = () => {
  const acceptTosMutation = useAcceptTos();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();

  const acceptTos = async () => {
    try {
      await acceptTosMutation.mutateAsync({
        accountId,
      });
      queryClient.invalidateQueries(["fetchAccount", accountId]);
      window.location.href = `/${accountId}/admin-dashboard`;
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error accepting ToS" });
    }
  };

  return (
    <Box h="100vh">
      <BackgroundImageGallery>
        <Container
          maxW="2xl"
          py={"8"}
          px={{ base: "4", sm: "10" }}
          bg={"white"}
          boxShadow={{ base: "none", sm: "xl" }}
          borderRadius={{ base: "none", sm: "5px" }}
          position="absolute"
        >
          <VStack justifyContent={"center"} w={"100%"} spacing={4}>
            <Image src={TBALogo} w={"328px"}></Image>
            <Text fontSize={"xl"} fontWeight={800}>
              Accept Terms of Service
            </Text>
            <Box
              border={"1px"}
              borderRadius={"md"}
              borderColor={"gray.200"}
              p={"2px"}
              w={"600px"}
              h={"400px"}
            >
              <TermsOfServiceIFrame />
            </Box>
            <Link
              w={"150px"}
              display={"inline"}
              onClick={() =>
                window.open("https://thebestanswer.com/terms-of-service/")
              }
            >
              <Flex align="center">
                <Text color={"primary"} pr={1}>
                  Open Terms of Service
                </Text>
                <Icon as={BiLinkExternal} boxSize="4" color={"primary"} />
              </Flex>
            </Link>
            <Button
              onClick={acceptTos}
              isLoading={acceptTosMutation.isLoading}
              w={"100%"}
            >
              Accept & Get Started
            </Button>
          </VStack>
        </Container>
      </BackgroundImageGallery>
    </Box>
  );
};

const TermsOfServiceIFrame = () => {
  return (
    <iframe
      id="tos-iframe"
      title="Terms of Service"
      width="100%"
      height="100%"
      src="https://storage.googleapis.com/the-best-answer-prod.appspot.com/docs/tba_terms_of_service.html"
    ></iframe>
  );
};

export default AcceptTos;
