import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { ChakraStylesConfig, GroupBase, Select } from "chakra-react-select";
import get from "lodash/get";
import { ReactNode, useRef } from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";

// To do search on nested options use labelToSearch
// options: [{
//   "label": "Searchable",
//   "value": "searchable",
//   "labelToSearch": "Searchable",
// }]
type Option = {
  label: string | ReactNode;
  labelToSearch?: string;
  value: string | number | boolean | null;
};
export const SelectField = (props: {
  field: {
    label?: string;
    placeholder?: string;
    id: string;
    isMulti?: boolean;
    validation?: any;
    isReadOnly?: boolean;
  };
  options: Option[] | { label: string; options: Option[] }[];
  isLoading?: boolean;
  onSelectionChange?: (val: any) => void;
  isClearable?: boolean;
  selectStyles?: any;
  selectProps?: any;
}) => {
  const {
    field,
    options,
    isLoading,
    onSelectionChange,
    isClearable,
    selectStyles,
    selectProps,
  } = props;
  const form = useFormContext();
  const {
    formState: { errors },
    // register,
    getValues,
    control,
  } = form;
  const formValuesRef = useRef<FieldValues>({});
  const selectRef = useRef<any | null>(null);

  const findOption = (currentValue: string) => {
    if (currentValue === null || !options) return undefined;
    for (const option of options) {
      if ("options" in option) {
        const foundOption = option.options.find(
          (o: Option) => o.value === currentValue
        );
        if (foundOption !== undefined && foundOption !== null) {
          return foundOption;
        }
      } else if (option.value == currentValue) {
        return option;
      }
    }
    return undefined;
  };

  // This is for the search functionality
  const filterOption = (option: any, inputValue: string) => {
    if (option.data?.labelToSearch) {
      return String(option.data?.labelToSearch)
        .toLowerCase()
        .includes(inputValue.toLowerCase());
    }
    return (
      option.label &&
      String(option.label).toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const defaultSelectStyles = {
    menu: (
      provided: ChakraStylesConfig<unknown, false, GroupBase<unknown>>
    ) => ({
      ...provided,
      zIndex: 9999,
      minW: "250px",
    }),
    option: (
      provided: ChakraStylesConfig<unknown, false, GroupBase<unknown>>
    ) => ({
      ...provided,
      pl: 7,
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      backgroundColor: "transparent",
    }),
    ...selectStyles,
  };

  return (
    <FormControl
      isInvalid={!!get(errors, field.id)}
      isRequired={field.validation?.required}
    >
      {field.label && <FormLabel>{field.label}</FormLabel>}
      <Controller
        control={control}
        name={field.id}
        // id={field.id}
        rules={field.validation}
        // {...register(field.id, {
        //   valueAsNumber: false,
        //   //validate: () => {},
        // })}
        // ref={null}
        render={(props) => {
          const formValues = getValues();
          const valueFromForm = get(formValues, field.id);
          const selectedOption = findOption(valueFromForm);
          formValuesRef.current = getValues();
          return (
            <Select
              isLoading={isLoading}
              key={formValuesRef.current[field.id]}
              inputRef={props.field.ref}
              options={options}
              value={selectedOption}
              ref={selectRef}
              defaultValue={() => {
                const formValues = getValues();
                const valueFromInitial = get(formValues, field.id);
                if (field.isMulti) {
                  const listOptions = [];
                  for (
                    let index = 0;
                    index < valueFromInitial?.length;
                    index++
                  ) {
                    const val = valueFromInitial[index];
                    const option = findOption(val);
                    listOptions.push(option);
                  }
                  if (listOptions) return listOptions;
                } else {
                  const option = findOption(valueFromInitial);
                  if (option) return option;
                }
              }}
              onChange={(val) => {
                if (Array.isArray(val)) {
                  const vals = val.map((v) => {
                    return v.value;
                  });
                  props.field.onChange(vals);
                } else {
                  let value = (val as Option)?.value;
                  if (!value && value !== false && value !== 0) {
                    value = null;
                  }
                  props.field.onChange(value);
                }
                if (onSelectionChange) {
                  onSelectionChange(val);
                }
                // Focus on the select after selecting an option
                setTimeout(() => {
                  selectRef?.current?.focus();
                }, 100);
              }}
              styles={{}}
              placeholder={field.placeholder}
              closeMenuOnSelect={true}
              openMenuOnClick={true}
              formatCreateLabel={(inputLabel: string) => {
                return `Use "${inputLabel}"`;
              }}
              //isValidNewOption={() => { return !customValueNotAllowed }}
              createOptionPosition={"last"}
              isMulti={field.isMulti}
              menuPosition="fixed"
              isReadOnly={field.isReadOnly}
              isDisabled={field.isReadOnly}
              // formatGroupLabel={(data) => (
              //   <Box>
              //     <strong>{data.label}</strong>
              //     <Box as="span" color="gray.500">
              //       {data.label}
              //     </Box>
              //   </Box>
              // )}
              filterOption={filterOption}
              isClearable={isClearable}
              chakraStyles={defaultSelectStyles}
              {...selectProps}
            />
          );
        }}
      />
      <FormErrorMessage>
        <>{get(errors, field.id) && get(errors, `${field.id}.message`)}</>
      </FormErrorMessage>
    </FormControl>
  );
};
