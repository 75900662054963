import { Box, Text } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  useCreateSurveyRun,
  useFetchSurveyRuns,
} from "../../../api/survey/surveyRun";
import LoadingBox from "../../../components/common/LoadingBox";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import EditSurveyRuns from "./EditSurveyRuns";

const SurveyRuns = ({ survey }) => {
  const { surveyId } = useParams();
  const { accountId } = useAccountId();
  const {
    data: surveyRuns,
    isLoading: isLoadingSurveyRuns,
    isError,
    error,
  } = useFetchSurveyRuns(accountId, surveyId);
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const createSurveyRunMutation = useCreateSurveyRun();
  const queryClient = useQueryClient();

  const createSurveyRun = async () => {
    try {
      const data = {
        surveyId,
        isActive: true,
        label: `Run ${surveyRuns.length + 1}`,
      };
      await createSurveyRunMutation.mutateAsync({
        accountId: accountId || "",
        data,
      });
      showSuccessToast("Survey Run created");
      queryClient.invalidateQueries(["fetchSurveyRuns", accountId, surveyId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating survey run" });
    }
  };

  return (
    <Box>
      {isLoadingSurveyRuns && <LoadingBox height={"200px"} />}
      {isError && <Text color={"red.500"}>Error: {error.message}</Text>}
      {surveyRuns && (
        <EditSurveyRuns
          survey={survey}
          surveyRuns={surveyRuns}
          createSurveyRun={createSurveyRun}
          isCreatingSurveyRun={createSurveyRunMutation.isLoading}
        />
      )}
    </Box>
  );
};

export default SurveyRuns;
