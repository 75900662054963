import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useUpdateKbPage } from "../../../api/knowledgeBase/kbPage";
import { AccountContext } from "../../../context/AccountContextComponent";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import EditKbPageEditors from "../EditKbPage/EditKbPageEditors";

export const EditKbPageEditorsModal = (props) => {
  const { isOpen, setIsOpen, pageData } = props;
  const navigate = useCustomDomainNavigate();
  const queryClient = useQueryClient();
  const { pageId } = useParams();
  const { accountId } = useAccountId();
  const updateKbPageMutation = useUpdateKbPage();
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const { currentProfile } = useContext(AccountContext);
  const [rightRowsSelected, setRightRowsSelected] = useState([]);

  const addToEditors = async (data) => {
    try {
      const editors = data.map((row) => {
        return row.id;
      });
      await updateKbPageMutation.mutateAsync({
        accountId,
        pageId,
        data: { editors },
      });
      if (
        !currentProfile.id === pageData.creator.id &&
        !editors.some((row) => row.id === currentProfile.id)
      ) {
        showSuccessToast(`You have been removed as an editor`);
        navigate(`/${accountId}/knowledge-base`);
      }
      queryClient.invalidateQueries(["fetchKbPage", pageId]);
      showSuccessToast(`Editors updated`);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error adding people as editors" });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"5xl"}
    >
      <ModalOverlay />
      <ModalContent mt={{ base: "10px", "2xl": "64px" }}>
        <ModalHeader>Update Page Editors</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EditKbPageEditors
            pageData={pageData}
            setRightRowsSelected={setRightRowsSelected}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            mr={4}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            onClick={() => addToEditors(rightRowsSelected)}
            isLoading={updateKbPageMutation.isLoading}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
