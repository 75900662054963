import { Accordion, VStack } from "@chakra-ui/react";
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useUpdateObjective } from "../../../api/okr/objective";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import { KeyResultAccordion } from "./KeyResultAccordion";

export const KeyResults = ({ objective, canEditObjective }) => {
  const sensors = useSensors(useSensor(PointerSensor));
  const [accordionIndex, setAccordionIndex] = useState([-1]);
  const [keyResults, setKeyResults] = useState(objective?.keyResults);
  const [activeId, setActiveId] = useState(null);
  const krIds = keyResults?.map((kr) => kr.id);
  const { accountId } = useAccountId();
  const updateObjectiveMutation = useUpdateObjective();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const [focusedKeyResultId, setFocusedKeyResultId] = useState(null);

  const updateObjective = async (data) => {
    try {
      await updateObjectiveMutation.mutateAsync({
        accountId,
        objectiveId: objective.id,
        data,
      });
      queryClient.invalidateQueries(["fetchObjectives", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating Key Results" });
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id && over?.id) {
      const activeIndex = krIds.indexOf(active.id);
      const overIndex = krIds.indexOf(over.id);

      // Remove the item from its current position
      const [movedItem] = _.pullAt(keyResults, activeIndex);

      // Insert the item at its new position
      const before = _.slice(keyResults, 0, overIndex);
      const after = _.slice(keyResults, overIndex);

      const newKeyResults = [...before, movedItem, ...after];

      // Update the state with the new order
      setKeyResults(newKeyResults);

      // Console log the updated keyResults
      console.log(newKeyResults.map((kr) => kr.id));
      updateObjective({ keyResultsOrder: newKeyResults.map((kr) => kr.id) });
    }

    setActiveId(null);
  };

  const DragPreview = ({ item }) => {
    return (
      <KeyResultAccordion
        keyResult={item}
        index={null}
        objective={objective}
        accordionIndex={[-1]}
        setFocusedKeyResultId={setFocusedKeyResultId}
        focusedKeyResultId={focusedKeyResultId}
      />
    );
  };

  useEffect(() => {
    setKeyResults(objective?.keyResults);
  }, [objective]);

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={(event) => setActiveId(event.active.id)}
      onDragCancel={() => setActiveId(null)}
      collisionDetection={closestCenter}
    >
      <SortableContext items={krIds} strategy={verticalListSortingStrategy}>
        <Accordion allowToggle w={"100%"} index={accordionIndex}>
          <VStack alignItems={"flex-start"} spacing={0} w={"100%"}>
            {keyResults?.map((keyResult, index) => (
              <div
                key={keyResult.id}
                style={{
                  visibility: activeId === keyResult.id ? "hidden" : "visible",
                  width: "100%",
                }}
              >
                <SortableKeyResultAccordion
                  key={keyResult.id}
                  keyResult={keyResult}
                  index={index}
                  objective={objective}
                  setAccordionIndex={setAccordionIndex}
                  accordionIndex={accordionIndex}
                  canEditObjective={canEditObjective}
                  setFocusedKeyResultId={setFocusedKeyResultId}
                  focusedKeyResultId={focusedKeyResultId}
                />
              </div>
            ))}
          </VStack>
        </Accordion>
      </SortableContext>
      <DragOverlay>
        {activeId ? (
          <DragPreview item={keyResults.find((kr) => kr.id === activeId)} />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export const SortableKeyResultAccordion = ({
  keyResult,
  index,
  objective,
  setAccordionIndex,
  accordionIndex,
  canEditObjective,
  setFocusedKeyResultId,
  focusedKeyResultId,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: keyResult.id });

  useEffect(() => {
    if (isDragging) {
      setAccordionIndex([-1]);
    }
  }, [isDragging]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <KeyResultAccordion
        keyResult={keyResult}
        index={index}
        objective={objective}
        dragAttributes={attributes}
        dragListeners={listeners}
        setAccordionIndex={setAccordionIndex}
        accordionIndex={accordionIndex}
        canEditObjective={canEditObjective}
        setFocusedKeyResultId={setFocusedKeyResultId}
        focusedKeyResultId={focusedKeyResultId}
      />
    </div>
  );
};
