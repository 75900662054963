import {
  Cell,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const defaultColors = ["#F56565", "#36A2EB", "#48BB78", "#FF9F40", "#FFCE56"];

const DonutChart = ({
  data,
  useDefaultColors,
}: {
  data: any;
  useDefaultColors?: boolean;
}) => {
  const colors = useDefaultColors
    ? defaultColors
    : Object.values(data).map((d: any) => d.color);
  const chartData = Object.keys(data).map((key, index) => {
    return {
      name: key,
      value: data[key].count,
      color: colors[index % colors.length],
    };
  });

  // Transform the percentage data into the format expected by the LabelList
  const total = chartData.reduce((sum, entry) => sum + entry.value, 0);
  const dataWithPercentages = chartData.map((entry) => ({
    ...entry,
    percentage: ((entry.value / total) * 100).toFixed(0) + "%",
  }));

  return (
    <ResponsiveContainer width="100%" height={280}>
      <PieChart>
        <Pie
          dataKey="value"
          innerRadius={80}
          outerRadius={100}
          paddingAngle={2}
          data={dataWithPercentages}
        >
          {dataWithPercentages.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
          <LabelList
            dataKey="percentage"
            position="outside"
            stroke="#718096"
            fill="#718096"
            fontWeight={100}
            fontSize={12}
          />
        </Pie>
        <Tooltip isAnimationActive={false} />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
