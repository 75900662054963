import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedDate } from "react-intl";
import { useParams } from "react-router-dom";
import {
  useSubmitEmployeeRanking,
  useUpdateEmployeeAbcRanking,
} from "../../api/abcRankings/employeeAbcRanking";
import { AvatarWithName } from "../../components/common/AvatarWithName";
import LoadingBox from "../../components/common/LoadingBox";
import { RadioGroupField } from "../../components/fields/RadioGroupField";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId from "../../hooks/customDomainHooks";
import useToastMessage from "../../hooks/useToastMessage";
import ManageReportMenu from "./ManageReportMenu";

const EditAbcRankingReport = (props) => {
  const {
    rankingReport,
    isLoadingAbcRankingReport,
    canManageViewers,
    canManageReviewers,
    setViewMode,
    viewMode,
    setIsSavingReport,
  } = props;
  const { reportId } = useParams();
  const { accountId } = useAccountId();
  const { showErrorToast, showSuccessToast } = useToastMessage();
  const queryClient = useQueryClient();
  const submitEmployeeRankingMutation = useSubmitEmployeeRanking();

  if (isLoadingAbcRankingReport) {
    return <LoadingBox />;
  }

  const submitAbcRanking = async () => {
    try {
      await submitEmployeeRankingMutation.mutateAsync({
        accountId,
        reportId,
      });
      showSuccessToast("Team Alignment Report Submitted");
      queryClient.invalidateQueries(["fetchAbcRankingReport", reportId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error submitting Team Alignment Report" });
    }
  };

  return (
    <>
      <Box
        bg={"white"}
        borderRadius={"md"}
        border={"1px"}
        borderColor={"gray.200"}
        p={4}
        overflow={"auto"}
      >
        <HStack justifyContent={"space-between"} mb={4}>
          <Box>
            <Text fontWeight={"bold"}>
              Manager: {rankingReport.manager.name}
            </Text>
            <Text>Department: {rankingReport.department?.name}</Text>
          </Box>
          <HStack>
            {rankingReport.submittedOn !== null && (
              <Text>
                Submitted on: {` `}
                <FormattedDate
                  value={rankingReport.submittedOn}
                  year="numeric"
                  month="short"
                  day="2-digit"
                />
              </Text>
            )}
            {rankingReport.submittedOn === null && (
              <Text color={"red.600"} fontWeight={"bold"}>
                Rankings due: {` `}
                <FormattedDate
                  value={rankingReport.rankingPeriod.dueDate}
                  year="numeric"
                  month="short"
                  day="2-digit"
                />
              </Text>
            )}
            <Button
              onClick={submitAbcRanking}
              isDisabled={
                rankingReport.submittedOn !== null ||
                rankingReport.employeeRankings.some(
                  (employeeRanking) => employeeRanking.ranking === null
                )
              }
              isLoading={submitEmployeeRankingMutation.isLoading}
            >
              Submit Report
            </Button>
            <ManageReportMenu
              canEdit={true}
              setViewMode={setViewMode}
              viewMode={viewMode}
              canManageReviewers={canManageReviewers}
              canManageViewers={canManageViewers}
              rankingReport={rankingReport}
            />
          </HStack>
        </HStack>
        <Box
          borderRadius={"md"}
          border={"1px"}
          borderColor={"gray.200"}
          mb={4}
          overflow={"auto"}
        >
          <Grid
            templateColumns="repeat(12, 1fr)"
            minWidth={"600px"}
            bg={"gray.100"}
          >
            <GridItem p={4} colSpan={3}>
              Name
            </GridItem>
            <GridItem p={4} colSpan={2}>
              Title
            </GridItem>
            <GridItem p={4} colSpan={2}>
              Department
            </GridItem>
            <GridItem p={4} colSpan={5}>
              Ranking
            </GridItem>

            {rankingReport.employeeRankings?.map((employeeRanking, index) => (
              <GridItem colSpan={12} key={employeeRanking.id} bg={"white"}>
                <EditRankingReportRow
                  reportId={reportId}
                  employeeRanking={employeeRanking}
                  index={index}
                  key={employeeRanking.id}
                  setIsSavingReport={setIsSavingReport}
                />
              </GridItem>
            ))}

            {rankingReport.employeeRankings?.length === 0 && (
              <GridItem colSpan={12} bg={"white"} p={4}>
                <Center>
                  <Text>No Employees to rank</Text>
                </Center>
              </GridItem>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

const EditRankingReportRow = ({
  reportId,
  employeeRanking,
  index,
  setIsSavingReport,
}) => {
  const updateEmployeeAbcRankingMutation = useUpdateEmployeeAbcRanking();
  const { showErrorToast } = useToastMessage();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const defaultValues = {
    ranking: employeeRanking.ranking,
    note: employeeRanking.note !== null ? employeeRanking.note : "",
  };
  const { aRankLabel, bRankLabel, cRankLabel } = useContext(AccountContext);

  const form = useForm({
    mode: "onChange",
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = form;

  useEffect(() => {
    if (employeeRanking && !isDirty) {
      reset(defaultValues);
    }
  }, [employeeRanking, reset]);

  const updateEmployeeRanking = async (data) => {
    try {
      setIsSavingReport(true);
      await updateEmployeeAbcRankingMutation.mutateAsync({
        accountId,
        reportId,
        employeeRankingId: employeeRanking.id,
        data,
      });
      setIsSavingReport(false);
      queryClient.invalidateQueries(["fetchAbcRankingReport", reportId]);
    } catch (error) {
      setIsSavingReport(false);
      console.log(error);
      reset(employeeRanking);
      showErrorToast({ message: "Error updating Team Alignment" });
    }
  };

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  // Only submit if there are no errors
  const submitForm = (event) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateEmployeeRanking)(event);
    } else {
      console.log("errors", errors);
    }
  };

  return (
    <>
      <FormProvider {...form} key={index}>
        <form onChange={debouncedSubmit}>
          <Box py={2}>
            <Grid
              templateColumns="repeat(12, 1fr)"
              gap={4}
              alignItems="center"
              borderTop={index !== 0 && "1px"}
              borderColor="gray.200"
              p={4}
            >
              <GridItem colSpan={3}>
                <AvatarWithName profile={employeeRanking.employee} />
              </GridItem>

              <GridItem colSpan={2}>
                <Text>{employeeRanking.employee?.title}</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <Text>{employeeRanking.employee?.department?.name}</Text>
              </GridItem>
              <GridItem colSpan={5}>
                <RadioGroupField
                  styleType="AbcCards"
                  field={{
                    id: `ranking`,
                    options: [
                      {
                        value: "A",
                        label: aRankLabel,
                        styles: {
                          borderColor: "green.200",
                          _checked: {
                            bg: "green.400",
                            color: "white",
                            borderColor: "green.400",
                          },
                        },
                      },
                      {
                        value: "B",
                        label: bRankLabel,
                        styles: {
                          borderColor: "#FFD600",
                          _checked: {
                            bg: "#FFD600",
                          },
                        },
                      },
                      {
                        value: "C",
                        label: cRankLabel,
                        styles: {
                          borderColor: "red.200",
                          _checked: {
                            bg: "red.400",
                            color: "white",
                            borderColor: "red.400",
                          },
                        },
                      },
                    ],
                  }}
                />
              </GridItem>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default EditAbcRankingReport;
