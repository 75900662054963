import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { Account } from "./types";

export const useCreateAccount = (
  props?: UseMutationOptions<any, unknown, any, unknown>
) =>
  useMutation({
    mutationKey: ["createAccount"],
    mutationFn: async (newAccount: any) => {
      const response = await api.post("/accounts", newAccount);
      return response.data;
    },
    ...props,
  });

export const useFetchAccount = (accountId: string) => {
  return useQuery<Account>({
    queryKey: ["fetchAccount", accountId],
    queryFn: async () => {
      const response = await api.get(`/accounts/${accountId}`);
      return response.data;
    },
    enabled: !!accountId,
    refetchInterval: 300000, // 5 minutes
  });
};

export const useUpdateAccount = (
  props?: UseMutationOptions<
    any,
    unknown,
    { accountData: any; accountId: string },
    unknown
  >
) =>
  useMutation({
    mutationKey: ["updateAccount"],
    mutationFn: async ({
      accountData,
      accountId,
    }: {
      accountData: any;
      accountId: string;
    }) => {
      const response = await api.put(`/accounts/${accountId}`, accountData);
      return response.data;
    },
    ...props,
  });

export const useCheckAccountIdExists = (accountId: string) => {
  return useQuery<{
    exists: boolean;
  }>({
    queryKey: ["checkAccountId"],
    queryFn: async () => {
      const response = await api.get(
        `/accounts/checkIfAccountExists/${accountId}`
      );
      return response.data;
    },
    enabled: !!accountId,
  });
};
