import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import useToastMessage from "../../../hooks/useToastMessage";

export const LinkEmailPasswordModal = (props) => {
  const { isOpen, setIsOpen, linkAuth, isLinking } = props;
  const { showErrorToast } = useToastMessage();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.newEmail.value;
    const password = e.target.password.value;
    const confirmPassword = e.target.confirmPassword.value;

    if (password !== confirmPassword) {
      showErrorToast({
        message: "Passwords do not match",
      });
      return;
    }

    await linkAuth(email, password);
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>{"Add Email/Password"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={{ base: 2, md: 5 }}>
              <Text>
                Provide your email and password to add the option of signing in
                with email/password.
              </Text>
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input id="newEmail" type="email" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input id="password" type="password" />
                <FormHelperText color="muted">
                  At least 8 characters long
                </FormHelperText>
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="confirmPassword">
                  Confirm Password
                </FormLabel>
                <Input id="confirmPassword" type="password" />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <VStack>
              <Box>
                <Button
                  mr={3}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  variant="outline"
                >
                  Close
                </Button>
                <Button variant="solid" isLoading={isLinking} type="submit">
                  Add Email/Password
                </Button>
              </Box>
              <div
                id="recaptcha-container-id"
                style={{ display: "flex", justifyContent: "center" }}
              ></div>
            </VStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
