import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import { ObjectiveLabel } from "./ObjectiveLabel";
import { ObjectiveHeader } from "./ObjectiveHeader";
import { KeyResults } from "../KeyResults/KeyResults";
import { useContext, useEffect, useMemo, useState } from "react";
import SquareMoreIcon from "../../../assets/icons/square_more.svg?react";
import AddIcon from "../../../assets/icons/add.svg?react";
import {
  useCreateObjective,
  useDeleteObjective,
} from "../../../api/okr/objective";
import useAccountId from "../../../hooks/customDomainHooks";
import { useQueryClient } from "@tanstack/react-query";
import useToastMessage from "../../../hooks/useToastMessage";
import { AccountContext } from "../../../context/AccountContextComponent";
import { ViewOkrTreeButton } from "./ViewOkrTreeButton";
import { DeleteSomethingModal } from "../../../components/modals/DeleteSomethingModal";
import { useLocation } from "react-router-dom";

export const ObjectiveAccordion = ({
  objective,
  index,
  dragAttributes,
  dragListeners,
  handleAccordionChange,
  createObjective,
  setNewlyCreatedObjectiveId,
}) => {
  const searchParams = new URLSearchParams(useLocation().search);
  const objectiveId = searchParams.get("objectiveId");
  const { currentProfile } = useContext(AccountContext);
  const [showEditIcons, setShowEditIcons] = useState(false);
  const [highlight, setHighlight] = useState(false);

  const canEditObjective = useMemo(() => {
    if (!currentProfile || !objective) return false;

    // Admins and owners can always edit objectives
    if (["OWNER", "ADMIN"].includes(currentProfile.role)) {
      return true;
    }

    // Check if the current user is the owner of the ObjectiveSet
    if (objective.objectiveSet?.ownerType === "PROFILE") {
      return currentProfile.id === objective.objectiveSet?.ownerId;
    }

    // Check if the current user is on the team of the ObjectiveSet
    if (objective.objectiveSet?.ownerType === "TEAM") {
      const isOnTeam = currentProfile.teams.some((team) => {
        return objective.objectiveSet?.teamId === team.teamId;
      });
      return isOnTeam;
    }

    // Check if the current user is on the department of the ObjectiveSet
    if (objective.objectiveSet?.ownerType === "DEPARTMENT") {
      const isInDepertment =
        currentProfile.departmentId === objective.objectiveSet?.departmentId;
      return isInDepertment;
    }

    return false;
  }, [currentProfile, objective]);

  useEffect(() => {
    if (objectiveId === objective.id) {
      setHighlight(true);
    } else if (highlight) {
      // Use a timeout to allow CSS transition to occur before removing the style
      const timer = setTimeout(() => setHighlight(false), 300); // Adjust time as needed
      return () => clearTimeout(timer);
    }
  }, [objectiveId, objective.id, highlight]);

  const glowStyle = useMemo(
    () => ({
      border: highlight ? "2px solid #38A169" : "0px solid transparent", // Ensures transition is smooth
      transition: "border 300ms ease-out", // Consistent transition
    }),
    [highlight]
  );

  return (
    <AccordionItem border={0} style={glowStyle}>
      {({ isExpanded }) => (
        <Box
          bg={"white"}
          px={0}
          py={4}
          cursor={"pointer"}
          onClick={() => {
            handleAccordionChange(objective.id);
          }}
          onMouseEnter={() => setShowEditIcons(true)}
          onMouseLeave={() => {
            setShowEditIcons(false);
          }}
        >
          <HStack alignItems={"flex-start"} px={4} spacing={0} w={"100%"}>
            <ObjectiveIcons
              objective={objective}
              canEditObjective={canEditObjective}
              createObjective={createObjective}
              index={index}
              showEditIcons={showEditIcons}
              setShowEditIcons={setShowEditIcons}
            />
            <Box py={1} w={"100px"}>
              <AccordionButton w={0} h={0} p={0} m={0} />
              <ObjectiveLabel
                objective={objective}
                canExpand={true}
                isExpanded={isExpanded}
                dragAttributes={dragAttributes}
                dragListeners={dragListeners}
                canEditObjective={canEditObjective}
              />
            </Box>
            <Box
              onClick={(e) => {
                // Don't toggle accordion when clicking on objective title
                e.stopPropagation();
              }}
              flex={1}
              pl={2}
              // overflowX={"hidden"}
            >
              <ObjectiveHeader
                objective={objective}
                isObjectiveExpanded={isExpanded}
                canEditObjective={canEditObjective}
                setNewlyCreatedObjectiveId={setNewlyCreatedObjectiveId}
              />
            </Box>
          </HStack>
          <AccordionPanel
            onClick={(e) => {
              // Don't toggle accordion when clicking in panel
              e.stopPropagation();
            }}
            cursor={"default"}
            px={0}
            py={4}
          >
            {isExpanded && (
              <Stack w={"100%"} direction={{ base: "column", md: "row" }}>
                <KeyResults
                  objective={objective}
                  canEditObjective={canEditObjective}
                />
                <Box w={"75px"} pr={"16px"} alignSelf={"center"}>
                  <ViewOkrTreeButton objective={objective} showText={true} />
                </Box>
              </Stack>
            )}
          </AccordionPanel>
        </Box>
      )}
    </AccordionItem>
  );
};

const ObjectiveIcons = ({
  objective,
  canEditObjective,
  createObjective,
  index,
  showEditIcons,
  setShowEditIcons,
}) => {
  const createObjectiveMutation = useCreateObjective();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const deleteObjectiveMutation = useDeleteObjective();
  const [isDeleteObjectiveOpen, setIsDeleteObjectiveModallOpen] =
    useState(false);

  const deleteObjective = async () => {
    try {
      await deleteObjectiveMutation.mutateAsync({
        accountId,
        objectiveId: objective.id,
      });
      queryClient.invalidateQueries(["fetchObjectives", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error deleting Objective" });
    }
  };

  return (
    <>
      <DeleteSomethingModal
        isOpen={isDeleteObjectiveOpen}
        isDeleting={deleteObjectiveMutation.isLoading}
        setIsOpen={setIsDeleteObjectiveModallOpen}
        headerText={"Delete Objective"}
        bodyText={`Are you sure you want to delete this objective?`}
        deleteFunction={async () => {
          await deleteObjective();
        }}
        buttonText={"Delete Objective"}
      />
      <Box
        cursor={"default"}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onMouseEnter={() => setShowEditIcons(true)}
        onMouseLeave={() => {
          if (!isMenuOpen) {
            setShowEditIcons(false);
          }
        }}
        h={"30px"}
        w={"60px"}
        minW={"60px"}
        pr={4}
        py={0}
        m={0}
      >
        {showEditIcons && (
          <Center h={"100%"} p={0} m={0}>
            <HStack>
              {canEditObjective ? (
                <IconButton
                  icon={<AddIcon stroke={"gray"} />}
                  variant={"ghost"}
                  p={0}
                  m={0}
                  size={"xs"}
                  _hover={{ bg: "none" }}
                  isLoading={createObjectiveMutation.isLoading}
                  onClick={() => {
                    createObjective(
                      objective.objectiveSet.timePeriod,
                      objective.objectiveSet.ownerType,
                      index + 1
                    );
                  }}
                />
              ) : (
                <Box w={"20px"} />
              )}
              <ObjectiveMenuButton
                setMenuOpen={setMenuOpen}
                setIsDeleteObjectiveModallOpen={setIsDeleteObjectiveModallOpen}
                isDeleting={deleteObjectiveMutation.isLoading}
                canDelete={canEditObjective}
              />
            </HStack>
          </Center>
        )}
      </Box>
    </>
  );
};

const ObjectiveMenuButton = ({
  setMenuOpen,
  setIsDeleteObjectiveModallOpen,
  isDeleting,
  canDelete,
}) => {
  return (
    <Menu onOpen={() => setMenuOpen(true)} onClose={() => setMenuOpen(false)}>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<SquareMoreIcon stroke={"gray"} />}
        variant={"ghost"}
        p={0}
        m={0}
        size={"xs"}
        _hover={{ bg: "none" }}
        isLoading={isDeleting}
      />
      <MenuList>
        {/* <MenuItem onClick={() => {}}>Duplicate Objective</MenuItem> */}
        {canDelete && (
          <MenuItem
            onClick={() => {
              setIsDeleteObjectiveModallOpen(true);
            }}
            _hover={{ bg: "red.100" }}
          >
            Delete Objective
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};
