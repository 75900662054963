/* eslint-disable no-unexpected-multiline */
import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { Editor } from "@tiptap/react";
import { useEffect, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { FaHighlighter } from "react-icons/fa";
import {
  MdFormatAlignCenter as AlignCenterIcon,
  MdFormatAlignJustify as AlignJustifyIcon,
  MdFormatAlignLeft as AlignLeftIcon,
  MdFormatTextdirectionLToR as AlignLeftToRightIcon,
  MdFormatAlignRight as AlignRightIcon,
  MdFormatTextdirectionRToL as AlignRightToLeftIcon,
  MdFormatQuote as BlockQuoteIcon,
  MdFormatBold as BoldIcon,
  MdFormatSize as HeadingIcon,
  MdFormatItalic as ItalicIcon,
  MdLink as LinkIcon,
  MdFormatListBulleted as ListIcon,
  MdOutlineFormatColorReset,
  MdOutlineImage,
  MdFormatListNumbered as NumberedListIcon,
  MdRedo as RedoIcon,
  MdFormatStrikethrough as StrikethroughIcon,
  MdFormatUnderlined as UnderlineIcon,
  MdUndo as UndoIcon,
} from "react-icons/md";
import api from "../../../../api/config";
import useAccountId from "../../../../hooks/customDomainHooks";
import EmojiPicker from "../../EmojiPicker/EmojiPicker";
import useUploadFile from "../../UploadFile/useUploadFile";

export const TopMenuBar = ({
  editor,
  makeImagesPublic,
}: {
  editor: Editor;
  makeImagesPublic?: boolean;
}) => {
  // Link Popover
  const {
    isOpen: isLinkPopoverOpen,
    onToggle: onLinkPopoverToggle,
    onClose: onLinkPopoverClose,
  } = useDisclosure();
  const [linkUrl, setLinkUrl] = useState("");
  const linkPickerRef = useRef<HTMLInputElement | null>(null);
  const { uploadImage } = useUploadFile();
  const { accountId } = useAccountId();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // TODO this isn't working
  // useEffect(() => {
  //   if (isLinkPopoverOpen) {
  //     linkPickerRef.current.focus();
  //   }
  // }, [isLinkPopoverOpen, linkPickerRef]);
  // This code will update the Text Align Icon
  // in the toolbar based on the current alignment of the selected text.
  const [currentAlignment, setCurrentAlignment] = useState("left");

  const getCurrentAlignmentIcon = () => {
    switch (currentAlignment) {
      case "left":
        return <AlignLeftIcon fontSize={"24px"} />;
      case "right":
        return <AlignRightIcon fontSize={"24px"} />;
      case "center":
        return <AlignCenterIcon fontSize={"24px"} />;
      case "justify":
        return <AlignJustifyIcon fontSize={"24px"} />;
      default:
        return <AlignLeftIcon fontSize={"24px"} />;
    }
  };

  useEffect(() => {
    if (!editor) return;

    const convertRgbColorsToHex = (string: string) => {
      if (!string) return "#000000";
      return string.replace(/rgb\(\d+,\s*\d+,\s*\d+\)/g, (rgbString) => {
        const match = rgbString.match(/\b(\d+)\b/g);
        if (!match) return "#000000";
        return (
          "#" +
          match
            .map((digit) =>
              parseInt(digit).toString(16).padStart(2, "0").toUpperCase()
            )
            .join("")
        );
      });
    };

    const updateToolbar = () => {
      const { selection } = editor.view.state;
      if (selection.empty) {
        // Update alignment icon
        const parent = editor.view.state.doc.resolve(selection.from).parent;
        const alignment = parent.attrs.textAlign;
        setCurrentAlignment(alignment || "left");

        // Update color picker icon
        const { $from } = selection;
        const marks = $from.marks();
        const colorMark = marks.find((mark) => mark.type.name === "textStyle");
        const hexColor = convertRgbColorsToHex(colorMark?.attrs?.color);
        setColor(hexColor);

        // Update font
        const fontMark = marks.find((mark) => mark.type.name === "textStyle");
        const font = fontMark?.attrs?.fontFamily;
        setFont(font);
      }
    };

    editor.on("transaction", updateToolbar);
    return () => {
      editor.off("transaction", updateToolbar);
    };
  }, [editor]);

  // Highlight Color
  const [highlightColor, setHighlightColor] = useState("#FFFF00");
  const [isHighlightModalOpen, setIsHighlightModalOpen] = useState(false);
  const highlightPickerRef = useRef<HTMLElement>(null);
  useOutsideClick({
    ref: highlightPickerRef,
    handler: () => setIsHighlightModalOpen(false),
  });

  // Text Color
  const [color, setColor] = useState("#000000");
  const colorPickerRef = useRef<HTMLElement>(null);
  const [isColorModalOpen, setIsColorModalOpen] = useState(false);
  useOutsideClick({
    ref: colorPickerRef,
    handler: () => setIsColorModalOpen(false),
  });

  // Font Family
  const [font, setFont] = useState("Arial");
  const setFontFamily = (fontFamily: string) => {
    if (!editor) return;
    editor.commands.setFontFamily(fontFamily);
  };

  const toggleMark = (mark: string) => {
    if (!editor) return;
    editor.chain().focus().toggleMark(mark).run();
  };

  const toggleBlock = () => {
    if (!editor) return;
    if (editor.can().setBlockquote()) {
      editor.chain().focus().setBlockquote().run();
    } else {
      editor.chain().focus().unsetBlockquote().run();
    }
  };

  const setHeading = (level: 1 | 2 | 3 | 4 | 5 | 6) => {
    if (!editor) return;
    editor.chain().focus().toggleHeading({ level }).run();
  };

  const setTextAlign = (alignment: string) => {
    if (!editor) return;
    editor.chain().focus().setTextAlign(alignment).run();
  };

  const setLink = () => {
    if (!editor) return;
    let url = linkUrl;

    // Cancelled or empty
    if (!url) {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      return;
    }

    // if http or https is not provided, add https
    if (!url.match(/^(?:https?:\/\/)/)) {
      url = "https://" + url;
    }

    // Get current domain
    const currentDomain = window.location.hostname;

    // Determine if the url is external
    const link = new URL(url);
    const isExternal = link.hostname !== currentDomain;

    // Update link. External links open in a new tab.
    editor
      .chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: url, target: isExternal ? "_blank" : "_self" })
      .run();
  };

  const toggleBulletList = () => {
    if (!editor) return;
    editor.chain().focus().toggleBulletList().run();
  };

  const toggleOrderedList = () => {
    if (!editor) return;
    editor.chain().focus().toggleOrderedList().run();
  };

  const increaseIndent = () => {
    if (!editor) return;
    (editor.chain().focus() as any).indent().run();
  };

  const decreaseIndent = () => {
    if (!editor) return;
    (editor.chain().focus() as any).outdent().run();
  };

  const toggleType = (type: string) => {
    if (!editor) return;
    if (!editor.isActive(type)) {
      (editor as any)
        .chain()
        .focus()
        [`set${type.charAt(0).toUpperCase() + type.slice(1)}`]()
        .run();
    }
  };

  const handleColorChange = (color: string) => {
    setColor(color);
    (editor as any).chain().focus().setColor(color).run();
  };

  const handleHighlightColorChange = (color: string) => {
    setHighlightColor(color);
    (editor as any).chain().focus().toggleHighlight({ color }).run();
  };

  const removeHighlightColor = () => {
    (editor as any).chain().focus().unsetHighlight().run();
  };

  // Image Upload
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const handleImageUploadClick = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  const handleUploadImage = async (file: File) => {
    const resp = await uploadImage({
      file,
      accountId: accountId || "",
      allowCrop: false,
      isPublic: makeImagesPublic,
    });
    if (makeImagesPublic) return resp.downloadUrl;
    else {
      const downloadUrlResp = await api.get(
        `${accountId}/storage/getAccountDownloadUrl/${resp.fileName}`
      );
      return downloadUrlResp.data.downloadUrl;
    }
  };

  const insertImage = (url: string) => {
    if (url && editor) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  };

  return (
    <Box borderBottom={"1px"} borderColor={"gray.200"} p={1}>
      <Menu>
        <MenuButton as={Button} size={"sm"} variant="ghost">
          {font || "Font"}
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => setFontFamily("Arial")} fontFamily={"Arial"}>
            Arial
          </MenuItem>
          <MenuItem
            onClick={() => setFontFamily("Courier New")}
            fontFamily={"Courier New"}
          >
            Courier New
          </MenuItem>
          <MenuItem
            onClick={() => setFontFamily("Times New Roman")}
            fontFamily={"Times New Roman"}
          >
            Times New Roman
          </MenuItem>
          <MenuItem
            onClick={() => setFontFamily("Verdana")}
            fontFamily={"Verdana"}
          >
            Verdana
          </MenuItem>
        </MenuList>
      </Menu>
      <Divider
        orientation="vertical"
        m={1}
        display={"inline"}
        borderColor={"gray.400"}
      />
      <Menu>
        <MenuButton
          as={IconButton}
          size={"sm"}
          icon={<HeadingIcon fontSize={"24px"} />}
          variant="ghost"
        />
        <MenuList>
          <MenuItem onClick={() => setHeading(1)}>Heading 1</MenuItem>
          <MenuItem onClick={() => setHeading(2)}>Heading 2</MenuItem>
          <MenuItem onClick={() => setHeading(3)}>Heading 3</MenuItem>
          <MenuItem onClick={() => toggleType("paragraph")}>Paragraph</MenuItem>
        </MenuList>
      </Menu>
      <Divider
        orientation="vertical"
        m={1}
        display={"inline"}
        borderColor={"gray.400"}
      />
      <IconButton
        size={"sm"}
        icon={<BoldIcon fontSize={"24px"} />}
        variant={"ghost"}
        aria-label="Toggle bold"
        onClick={() => toggleMark("bold")}
        bg={editor.isActive("bold") ? "gray.100" : "white"}
      />
      <IconButton
        size={"sm"}
        icon={<ItalicIcon fontSize={"24px"} />}
        variant={"ghost"}
        aria-label="Toggle italic"
        onClick={() => toggleMark("italic")}
        bg={editor.isActive("italic") ? "gray.100" : "white"}
      />
      <IconButton
        size={"sm"}
        icon={<UnderlineIcon fontSize={"24px"} />}
        variant={"ghost"}
        aria-label="Toggle underline"
        onClick={() => toggleMark("underline")}
        bg={editor.isActive("underline") ? "gray.100" : "white"}
      />
      <Popover closeOnBlur={false} isOpen={isColorModalOpen}>
        <PopoverTrigger>
          <Button
            size={"xs"}
            aria-label="Change text color"
            onClick={() => setIsColorModalOpen(true)}
            variant={"ghost"}
            py={4}
          >
            <VStack spacing={0} w={"100%"}>
              <Text fontSize={14}>A</Text>
              <Box bg={color} h={"4px"} w={"20px"}></Box>
            </VStack>
          </Button>
        </PopoverTrigger>
        <PopoverContent ref={colorPickerRef} w={"250px"}>
          <PopoverArrow />
          <PopoverBody>
            <HexColorPicker
              color={color}
              onChange={handleColorChange}
              style={{ width: "100%" }}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Popover closeOnBlur={false} isOpen={isHighlightModalOpen}>
        <PopoverTrigger>
          <Button
            size={"xs"}
            aria-label="Change highlight color"
            onClick={() => setIsHighlightModalOpen(true)}
            variant={"ghost"}
            py={4}
          >
            <VStack spacing={1} w={"100%"}>
              <FaHighlighter fontSize={"14px"} />
              <Box bg={highlightColor} h={"4px"} w={"20px"}></Box>
            </VStack>
          </Button>
        </PopoverTrigger>
        <PopoverContent ref={highlightPickerRef} w={"250px"}>
          <PopoverArrow />
          <PopoverBody>
            <Button
              leftIcon={<MdOutlineFormatColorReset />}
              onClick={removeHighlightColor}
              w={"100%"}
              variant={"ghost"}
              justifyContent={"flex-start"}
            >
              None
            </Button>
            <HexColorPicker
              color={highlightColor}
              onChange={handleHighlightColorChange}
              style={{ width: "100%" }}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Divider
        orientation="vertical"
        m={1}
        display={"inline"}
        borderColor={"gray.400"}
      />
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={async () => {
          if (!fileInputRef.current?.files) return;
          setIsUploadingImage(true);
          const file = fileInputRef.current.files[0];
          const imageUrl = await handleUploadImage(file);
          insertImage(imageUrl);
          setIsUploadingImage(false);
        }}
      />
      <IconButton
        size={"sm"}
        icon={<MdOutlineImage fontSize={"24px"} />}
        variant={"ghost"}
        aria-label="Upload image"
        onClick={handleImageUploadClick}
        isLoading={isUploadingImage}
      />
      <EmojiPicker
        onEmojiSelect={(emoji: any) => {
          editor.chain().insertContent(emoji.native).run();
        }}
      />
      <IconButton
        size={"sm"}
        icon={<StrikethroughIcon fontSize={"24px"} />}
        variant={"ghost"}
        aria-label="Toggle strikethrough"
        onClick={() => toggleMark("strike")}
        bg={editor.isActive("strike") ? "gray.100" : "white"}
      />
      <IconButton
        size={"sm"}
        icon={<BlockQuoteIcon fontSize={"24px"} />}
        variant={"ghost"}
        aria-label="Toggle blockquote"
        onClick={() => toggleBlock()}
        bg={editor.isActive("blockquote") ? "gray.100" : "white"}
      />
      <IconButton
        size={"sm"}
        icon={<ListIcon fontSize={"24px"} />}
        variant={"ghost"}
        aria-label="Toggle bullet list"
        onClick={() => toggleBulletList()}
        bg={editor.isActive("bulletList") ? "gray.100" : "white"}
      />
      <IconButton
        size={"sm"}
        icon={<NumberedListIcon fontSize={"24px"} />}
        variant={"ghost"}
        aria-label="Toggle numbered list"
        onClick={() => toggleOrderedList()}
        bg={editor.isActive("orderedList") ? "gray.100" : "white"}
      />
      <>
        <Popover
          isOpen={isLinkPopoverOpen}
          onClose={onLinkPopoverClose}
          onOpen={() => {
            const previousUrl = editor.getAttributes("link").href || "";
            setLinkUrl(previousUrl);
          }}
        >
          <PopoverTrigger>
            <IconButton
              size={"sm"}
              icon={<LinkIcon fontSize={"24px"} />}
              variant={"ghost"}
              aria-label="Toggle link"
              onClick={onLinkPopoverToggle}
              bg={editor.isActive("link") ? "gray.100" : "white"}
            />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Enter a link</PopoverHeader>
            <PopoverBody
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
            >
              <Input
                value={linkUrl}
                onChange={(e) => setLinkUrl(e.target.value)}
                placeholder="https://example.com"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents the default action of creating a new line
                    setLink();
                  }
                }}
                ref={linkPickerRef}
              />
              <Button mt={2} onClick={setLink}>
                Apply
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </>
      <IconButton
        size={"sm"}
        icon={<UndoIcon fontSize={"24px"} />}
        variant={"ghost"}
        aria-label="Undo"
        onClick={() => editor.chain().focus().undo().run()}
        colorScheme="gray"
      />
      <IconButton
        size={"sm"}
        icon={<RedoIcon fontSize={"24px"} />}
        variant={"ghost"}
        aria-label="Redo"
        onClick={() => editor.chain().focus().redo().run()}
        colorScheme="gray"
      />
      <Menu>
        <MenuButton
          as={IconButton}
          size={"sm"}
          icon={getCurrentAlignmentIcon()}
          variant="ghost"
        />
        <MenuList>
          <MenuItem onClick={() => setTextAlign("left")}>Align Left</MenuItem>
          <MenuItem onClick={() => setTextAlign("right")}>Align Right</MenuItem>
          <MenuItem onClick={() => setTextAlign("center")}>
            Align Center
          </MenuItem>
          <MenuItem onClick={() => setTextAlign("justify")}>Justify</MenuItem>
        </MenuList>
      </Menu>
      <IconButton
        size={"sm"}
        icon={<AlignLeftToRightIcon fontSize={"24px"} />}
        variant={"ghost"}
        aria-label="Increase indentation"
        onClick={increaseIndent}
        colorScheme="gray"
      />
      <IconButton
        size={"sm"}
        icon={<AlignRightToLeftIcon fontSize={"24px"} />}
        variant={"ghost"}
        aria-label="Decrease indentation"
        onClick={decreaseIndent}
        colorScheme="gray"
      />
    </Box>
  );
};
