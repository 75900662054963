import { useContext, useEffect, useState } from "react";
import { Box, Button, HStack, Icon } from "@chakra-ui/react";
import EditorBubble from "./EditorBubble";
import { Editor, isNodeSelection } from "@tiptap/react";
import { AskAiSelector } from "./AskAiSelector";
import { TipTapAiContext } from "../../../../context/TipTapAiContextComponent";
import { removeAIHighlight } from "../extensions/AiHighlight";
import Magic from "../ui/MagicIcon";

interface GenerativeMenuSwitchProps {
  editor: Editor;
}
const OkrAiMenu = ({ editor }: GenerativeMenuSwitchProps) => {
  const [numCharsTyped, setNumCharsTyped] = useState(0);
  const { openOKRAiMenu, setOpenOKRAiMenu, setKey } =
    useContext(TipTapAiContext);
  const [stayClosed, setStayClosed] = useState(false);

  // Keep track of number of characters typed
  useEffect(() => {
    const onUpdate = () => {
      setNumCharsTyped((prev) => prev + 1);
    };

    // Attach the event listener
    editor.on("update", onUpdate);

    // Return a cleanup function that will be called on component unmount
    return () => {
      editor.off("update", onUpdate);
    };
  }, [editor]);

  const forceUpdateOkrAiMenu = (val: boolean) => {
    setOpenOKRAiMenu((prev) => {
      // force update the menu using a key
      setKey((prev: number) => prev + 1);
      return { isOpen: val, autofocus: prev.autofocus };
    });
  };

  useEffect(() => {
    if (numCharsTyped > 3 && !stayClosed) {
      setOpenOKRAiMenu((prev) => {
        if (prev.isOpen) {
          return { isOpen: true, autofocus: false };
        }
        setKey((prev: number) => prev + 1);
        return { isOpen: true, autofocus: false };
      });
    }
  }, [numCharsTyped, setOpenOKRAiMenu, setKey, stayClosed]);

  return (
    <EditorBubble
      shouldShow={({ editor, state }) => {
        if (openOKRAiMenu.isOpen) {
          return true;
        }
        const { selection } = state;
        const { empty } = selection;
        if (
          !editor.isEditable ||
          editor.isActive("image") ||
          (empty && !openOKRAiMenu.isOpen) ||
          isNodeSelection(selection)
        ) {
          return false;
        }
        return true;
      }}
      tippyOptions={{
        placement: openOKRAiMenu.isOpen ? "bottom-start" : "top",
        onHidden: () => {
          console.log("onHidden OKR AI Menu");
          forceUpdateOkrAiMenu(false);
          editor?.chain().unsetHighlight().run();
          removeAIHighlight(editor);
        },
        onClickOutside: (_, event) => {
          console.log("onClickOutside OKR AI Menu");
          const eventTarget = event?.target as HTMLElement;
          if (eventTarget?.id === "ask-ai-button") {
            // Click is inside the tippy content, do not hide the instance
            console.log("click is inside");
            return;
          }
          console.log("click is outside");
          forceUpdateOkrAiMenu(false);
          editor?.chain().setTextSelection({ from: 0, to: 0 }).run();
          removeAIHighlight(editor);
        },
        hideOnClick: false,

        appendTo: () => {
          return document.getElementById("root") as HTMLElement;
        },
      }}
      editor={editor}
      openAiMenu={openOKRAiMenu.isOpen}
    >
      <Box display={"flex"} flexDirection={"column"}>
        {/* This is the menu for different AI actions ie Fix Grammer, Improve Writing */}
        {openOKRAiMenu.isOpen && (
          <AskAiSelector
            editor={editor}
            isOpen={openOKRAiMenu.isOpen}
            autofocus={openOKRAiMenu.autofocus}
            placeholder="I can help you complete your OKR. Just tell me what you're thinking..."
            setStayClosed={setStayClosed}
          />
        )}

        {/* This is the "Ask AI" button + the base menu options to the right of the button*/}
        {!openOKRAiMenu.isOpen && !document.querySelector("#slash-command") && (
          <HStack
            bg="white"
            rounded="md"
            border="1px solid"
            borderColor="borderPrimary"
            shadow="xl"
            h={"42px"}
            gap={0}
            overflow={"hidden"}
          >
            <Button
              variant="ghost"
              onClick={() =>
                setOpenOKRAiMenu({ isOpen: true, autofocus: true })
              }
              size="sm"
              color={"primary"}
              h={"100%"}
              borderRadius={0}
              id="ask-ai-button"
            >
              <Icon as={Magic} />
              <Box w={"4px"} />
              Ask AI
            </Button>
          </HStack>
        )}
      </Box>
    </EditorBubble>
  );
};

export default OkrAiMenu;
