import { Box } from "@chakra-ui/react";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { SelectField } from "../../../components/fields/SelectField";
import { AccountContext } from "../../../context/AccountContextComponent";

const getDefaultQuestionData = (type, companyName) => {
  const questionData = {
    TEXT: {
      element: {
        type: "TEXT",
      },
    },
    NUMBER: {
      element: {
        type: "NUMBER",
        min: 0,
        max: 10,
      },
    },
    RADIO: {
      element: {
        type: "RADIO",
        choices: [{ label: "Option 1" }],
      },
    },
    DROPDOWN: {
      element: {
        type: "DROPDOWN",
        choices: [{ label: "Option 1" }],
      },
    },
    CHECKBOX: {
      element: {
        type: "CHECKBOX",
        choices: [{ label: "Option 1" }],
      },
    },
    LINEAR_SCALE: {
      element: {
        type: "LINEAR_SCALE",
        choices: [
          { label: "Strongly Disagree", value: 1 },
          { label: "", value: 2 },
          { label: "Neutral", value: 3 },
          { label: "", value: 4 },
          { label: "Strongly Agree", value: 5 },
        ],
      },
    },
    NPS: {
      prompt: `On a scale of 0 - 10, how likely are you to recommend ${companyName} as a good place to work?`,
      element: {
        type: "NPS",
        choices: [
          { label: "Not Likely", value: 0 },
          { label: "", value: 1 },
          { label: "", value: 2 },
          { label: "", value: 3 },
          { label: "", value: 4 },
          { label: "", value: 5 },
          { label: "", value: 6 },
          { label: "", value: 7 },
          { label: "", value: 8 },
          { label: "", value: 9 },
          { label: "Extremely Likely", value: 10 },
        ],
      },
    },
  };
  return questionData[type];
};

const QuestionTypeSelector = ({ onChange }) => {
  const form = useFormContext();
  const { accountData } = useContext(AccountContext);

  return (
    <Box w={"270px"}>
      <SelectField
        field={{
          id: `element.type`,
        }}
        options={[
          { label: "Text", value: "TEXT" },
          { label: "Number", value: "NUMBER" },
          { label: "Radio", value: "RADIO" },
          { label: "Dropdown", value: "DROPDOWN" },
          { label: "Checkbox", value: "CHECKBOX" },
          { label: "Linear Scale", value: "LINEAR_SCALE" },
          { label: "eNPS", value: "NPS" },
        ]}
        onSelectionChange={(value) => {
          // Clear out the old question element
          const questionType = value.value;
          const newQuestionData = getDefaultQuestionData(
            questionType,
            accountData.companyName || accountData.name
          );

          const currentPrompt = form.getValues(`prompt`);
          form.setValue(`element`, { ...newQuestionData.element });
          form.setValue(`prompt`, newQuestionData.prompt || currentPrompt);

          form.clearErrors(`element`);
          onChange();
        }}
      />
    </Box>
  );
};

export default QuestionTypeSelector;
