import { Box, Checkbox, FormControl, FormErrorMessage } from "@chakra-ui/react";
import get from "lodash/get";
import { Controller, useFormContext } from "react-hook-form";

export const CheckboxField = ({ field }: { field: any }) => {
  const form = useFormContext();
  const {
    formState: { errors },
    control,
  } = form;

  return (
    <Controller
      name={field.id}
      control={control}
      rules={field.validation}
      render={({ field: { value, onChange } }) => (
        <FormControl
          isInvalid={!!get(errors, field.id)}
          isRequired={field.validation?.required}
        >
          <Box key={field.value}>
            <Checkbox
              isDisabled={field.disabled}
              key={field.value}
              isChecked={value}
              colorScheme="blue"
              onChange={(val) => {
                onChange(val);
              }}
            >
              {field.label}
            </Checkbox>
          </Box>
          <FormErrorMessage>
            <>
              {" "}
              {!!get(errors, field.id) && get(errors, `${field.id}.message`)}
            </>
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};
