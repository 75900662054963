import { Box, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useFetchShoutouts } from "../../api/shoutouts/shoutout";
import LoadingBox from "../../components/common/LoadingBox";
import InfoBox from "../../components/common/PageInfo/InfoBox";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../hooks/customDomainHooks";
import PageShell from "../../layout/PageShell";
import CreateShoutout from "./CreateShoutout";
import { ShoutoutCard } from "./ShoutoutCard";

const ShoutoutsPage = () => {
  const { accountId } = useAccountId();
  const [pageInfoEditMode, setPageInfoEditMode] = useState(false);

  const {
    data: shoutoutData,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingShoutouts,
    isError: isErrorShoutouts,
    error: errorShoutouts,
  } = useFetchShoutouts(accountId || "");

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Shoutouts" },
  ]);

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      menuItems={[
        {
          type: "CREATE_PAGE_INFO",
          pageType: "SHOUTOUTS",
        },
      ]}
      setPageInfoEditMode={setPageInfoEditMode}
    >
      <InfoBox
        pageInfoType={"SHOUTOUTS"}
        setPageInfoEditMode={setPageInfoEditMode}
        pageInfoEditMode={pageInfoEditMode}
      />
      {isLoadingShoutouts && <LoadingBox />}
      {isErrorShoutouts && <div>{errorShoutouts.message}</div>}
      {shoutoutData && (
        <Box
          w={"100%"}
          py={2}
          style={{ overflow: "hidden" }}
          pb={400} // this is to give space for emoji picker to not be hidden
        >
          <Box mb={4} w={"2xl"} py={2}>
            <CreateShoutout onSuccess={() => {}} />
          </Box>
          <InfiniteScroll
            dataLength={shoutoutData.pages.length}
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={<LoadingBox />}
            endMessage={
              shoutoutData.pages.length > 1 && (
                <Text align={"center"} fontWeight={"bold"}>
                  You have seen all the shoutouts!
                </Text>
              )
            }
            style={{ overflow: "visible" }}
          >
            {shoutoutData.pages.map((page, index) => (
              <React.Fragment key={index}>
                {page.shoutouts.map((shoutout) => (
                  <ShoutoutCard
                    shoutout={shoutout}
                    key={shoutout.id}
                    size={"2xl"}
                  />
                ))}
              </React.Fragment>
            ))}
          </InfiniteScroll>
        </Box>
      )}
    </PageShell>
  );
};

export default ShoutoutsPage;
