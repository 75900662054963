import { Navigate, Outlet } from "react-router-dom";
import useAuthState from "../../components/auth/useAuthState";
import LoadingBox from "../../components/common/LoadingBox";

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { user, loading } = useAuthState();

  if (loading) {
    return <LoadingBox />;
  }

  if (!user) {
    const currentPathWithQuery =
      window.location.pathname + window.location.search;
    return (
      <Navigate
        to={`/_auth/signin?redirectPath=${currentPathWithQuery}`}
        replace
      />
    );
  }

  return <>{children || <Outlet />}</>;
};

export default ProtectedRoute;
