import { Box, Center, Spinner } from "@chakra-ui/react";

const LoadingBox = ({ height }: { height?: string }) => {
  return (
    <Box height={height ? height : "100vh"}>
      <Center height={height ? height : "100vh"}>
        <Spinner thickness="7px" speed="1s" color="green.600" size="xl" />
      </Center>
    </Box>
  );
};
export default LoadingBox;
