import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  Select,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import Papa from "papaparse";
import { useContext, useEffect, useRef, useState } from "react";
import { BiRightArrowAlt } from "react-icons/bi";
import { BsFiletypeCsv } from "react-icons/bs";
import UsageChangeNotification from "../../components/common/UsageChangeNotification";
import { AccountContext } from "../../context/AccountContextComponent";
import { downloadCSV } from "../../utils/downloadAsCsv";

const CSVMapping = ({
  templateHeaders,
  handleSubmit,
  isSubmitting,
  onCancelClick,
}) => {
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [mappings, setMappings] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [doSendInviteEmail, setDoSendInviteEmail] = useState(false);
  const [isParsingComplete, setIsParsingComplete] = useState(false);
  const [allRequiredHeadersMapped, setAllRequiredHeadersMapped] =
    useState(false);
  const { accountData } = useContext(AccountContext);

  const toast = useToast();
  const fileInputRef = useRef();

  const parseCSV = (file) => {
    Papa.parse(file, {
      header: true,
      complete: function (results) {
        setCsvHeaders(results.meta.fields);
        setCsvData(results.data);
        setIsParsingComplete(true);
        const newMappings = smartMapping(results.meta.fields);
        console.log(newMappings);
        setMappings(newMappings);
      },
    });
  };

  const checkAllRequiredHeadersMapped = () => {
    return templateHeaders.every(
      // eslint-disable-next-line no-prototype-builtins
      (header) => !header.required || mappings.hasOwnProperty(header.id)
    );
  };

  useEffect(() => {
    console.log(mappings);
    setAllRequiredHeadersMapped(checkAllRequiredHeadersMapped());
  }, [mappings]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    event.target.value = "";
    parseCSV(file);
  };

  const handleMappingChange = (e, templateHeader) => {
    setMappings({ ...mappings, [templateHeader]: e.target.value });
  };

  const smartMapping = (csvHeaders) => {
    const newMappings = {};
    for (const csvHeader of csvHeaders) {
      for (const templateHeader of templateHeaders) {
        if (
          templateHeader.smart_mappings.includes(csvHeader.toLowerCase().trim())
        ) {
          newMappings[templateHeader.id] = csvHeader;
        }
      }
    }
    return newMappings;
  };

  const mapData = () => {
    const mappedData = csvData
      .map((row) => {
        return Object.entries(mappings).reduce(
          (acc, [templateHeader, csvHeader]) => {
            acc[templateHeader] = row[csvHeader];
            return acc;
          },
          {}
        );
      })
      // remove rows that don't have name and email
      .filter((row) => row.name && row.email);
    return mappedData;
  };

  const onSubmit = () => {
    const mappedData = mapData();

    // convert all startDate fields to Date objects
    mappedData.forEach((row) => {
      if (row.startDate) {
        const localDate = new Date(row.startDate);
        // Set localDate time to 8am
        localDate.setHours(8);
        // Convert localDate to GMT
        const utcDate = new Date(
          Date.UTC(
            localDate.getUTCFullYear(),
            localDate.getUTCMonth(),
            localDate.getUTCDate(),
            localDate.getUTCHours()
          )
        );
        row.startDate = utcDate.toISOString();
      }
    });
    handleSubmit(mappedData, doSendInviteEmail);
  };

  const cleanProfilesForDownload = (profiles) => {
    // remove all tba-specific user data
    profiles.forEach((profile) => {
      profile.reportsToEmail = profile?.reportsTo?.email;
      profile.department = profile?.department?.name;
      profile.level = profile?.level?.name;
      profile.phoneNumbers = profile?.phoneNumbers
        .map((phoneNumber) => {
          return phoneNumber.number;
        })
        .join(", ");
      delete profile.id;
      delete profile.createdAt;
      delete profile.invitedAt;
      delete profile.user;
      delete profile.userId;
      delete profile._count;
      delete profile.test;
      delete profile.reportsTo;
      delete profile.reportsToId;
    });
    return profiles.filter((profile) => profile.isActive === true);
  };

  const determineNumberOfEmployees = (mappedData) => {
    const profiles = accountData.profiles;
    // Count the number of profiles in csvData where email or employeeId not in account profiles
    // TODO also consider isActive?
    // Right now, all new uploaded profiles will have employee role.
    const newEmployees = mappedData.filter(
      (row) =>
        !profiles.some(
          (profile) =>
            profile.email === row["email"] ||
            profile.employeeId === row["employeeId"]
        )
    ).length;

    const updatedEmployees = mappedData.filter((row) =>
      profiles.some(
        (profile) =>
          profile.email === row["email"] ||
          profile.employeeId === row["employeeId"]
      )
    ).length;

    const profileCounts = {
      currentEmployees: profiles.filter(
        (profile) => profile.isActive && profile.role === "EMPLOYEE"
      ).length,
      currentAdmins: profiles.filter(
        (profile) =>
          profile.isActive &&
          (profile.role === "ADMIN" || profile.role === "OWNER")
      ).length,
      newEmployees: newEmployees,
      updatedEmployees: updatedEmployees,
    };
    return profileCounts;
  };

  const mappedData = mapData();

  const profileCounts = determineNumberOfEmployees(mappedData);

  const hasAtleastOneEmployeeReadyForImport =
    isParsingComplete && mappedData.length > 0 && allRequiredHeadersMapped;

  return (
    <VStack spacing={6}>
      <FormControl>
        <Box
          border="1px"
          borderColor="gray.200"
          borderRadius="md"
          p={4}
          textAlign="center"
          cursor="pointer"
          _hover={{ bg: "gray.50" }}
          onClick={() => fileInputRef.current.click()}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => {
            e.preventDefault();
            if (e.dataTransfer.files.length > 0) {
              const file = e.dataTransfer.files[0];
              if (file.type === "text/csv" || file.name.endsWith(".csv")) {
                parseCSV(file);
              } else {
                toast({
                  title: "Invalid file type",
                  description: "Please upload a CSV file.",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              }
            }
          }}
        >
          <VStack>
            <Text>Drop a CSV file here or click to select</Text>
            <Input
              ref={fileInputRef}
              type="file"
              accept=".csv"
              display="none"
              onChange={handleFileChange}
            />
            <BsFiletypeCsv size={62} />
          </VStack>
        </Box>
      </FormControl>

      {csvHeaders.length > 0 && (
        <Box>
          <FormLabel>Choose mappings</FormLabel>
          <VStack>
            {templateHeaders.map((headerObj) => (
              <HStack key={headerObj.id} spacing={4} w={"100%"}>
                <Select
                  value={mappings[headerObj.id] || ""}
                  onChange={(e) => handleMappingChange(e, headerObj.id)}
                  w={"220px"}
                >
                  <option value="" disabled>
                    Select a column
                  </option>
                  {csvHeaders.map((csvHeader) => (
                    <option key={csvHeader} value={csvHeader}>
                      {csvHeader}
                    </option>
                  ))}
                </Select>
                <BiRightArrowAlt size={24} />
                <Text fontWeight={"bold"}>
                  {headerObj.label}{" "}
                  {headerObj.required && (
                    <Box as="span" color="red.500">
                      *
                    </Box>
                  )}
                </Text>
              </HStack>
            ))}
          </VStack>
        </Box>
      )}

      <Text>
        Required fields:{" "}
        <strong>
          {templateHeaders
            .filter((header) => header.required)
            .map((header) => {
              return header.label;
            })
            .join(", ")}
        </strong>
      </Text>
      <Text textAlign={"center"}>
        This tool does smart mapping.
        <br />
        <Link
          onClick={() => {
            const cleanProfiles = cleanProfilesForDownload(
              accountData.profiles
            );
            console.log("cleanProfiles", cleanProfiles);
            downloadCSV(cleanProfiles, "profilesExample", {
              Active: "isActive",
              "Start Date": "startDate",
              Name: "name",
              Email: "email",
              Title: "title",
              Level: "level",
              Department: "department",
              "Phone Number Extension": "phoneNumberExtension",
              "Phone Numbers": "phoneNumbers",
              "Employee ID": "employeeId",
              "Office Location": "officeLocation",
              Role: "role",
              "Manager Email": "reportsToEmail",
            });
          }}
        >
          Download existing employees in a csv file.
        </Link>
      </Text>
      {hasAtleastOneEmployeeReadyForImport ? (
        <UsageChangeNotification
          subscriptionPlan={accountData.accountSubscription?.subscriptionPlan}
          currentNumEmployees={profileCounts.currentEmployees}
          numEmployeesToAdd={profileCounts.newEmployees}
          newUsageType={csvData?.length ? "CSV_UPLOAD" : ""}
        />
      ) : (
        <Box h={"64px"} />
      )}

      <HStack w={"100%"} justifyContent={"space-between"}>
        <Box />
        <VStack alignItems={"flex-end"}>
          <Checkbox
            isChecked={doSendInviteEmail}
            onChange={(e) => {
              setDoSendInviteEmail(e.target.checked);
            }}
            isDisabled={
              !hasAtleastOneEmployeeReadyForImport ||
              !profileCounts.newEmployees > 0
            }
          >
            Send invite email
            {profileCounts.newEmployees > 0
              ? ` to ${profileCounts.newEmployees} member${
                  profileCounts.newEmployees === 1 ? "" : "s"
                }`
              : ""}
          </Checkbox>
          <HStack>
            <Button
              onClick={onCancelClick}
              isLoading={isSubmitting}
              variant={"ghost"}
            >
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              isDisabled={!hasAtleastOneEmployeeReadyForImport}
              isLoading={isSubmitting}
            >
              Import all
            </Button>
          </HStack>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default CSVMapping;
