import { HStack, Icon, Link, Text } from "@chakra-ui/react";
import { FiInfo } from "react-icons/fi";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import {
  getNewUsageMessageForAddAdmin,
  getNewUsageMessageForAddEmployee,
  getNewUsageMessageForCsvUpload,
} from "../../pages/Billing/common";

const UsageChangeNotification = ({
  subscriptionPlan,
  currentNumEmployees,
  numEmployeesToAdd,
  currentNumAdmins,
  newUsageType, // CSV_UPLOAD, ADD_EMPLOYEE, ADD_ADMIN
}: {
  subscriptionPlan: any;
  currentNumEmployees: number;
  numEmployeesToAdd: number;
  currentNumAdmins: number;
  newUsageType: string;
}) => {
  const navigate = useCustomDomainNavigate();
  const { accountId } = useAccountId();
  const { includedAdmins, includedEmployees, maxEmployees, maxAdmins } =
    subscriptionPlan || {};

  const SubscriptionTabLink = () => {
    return (
      <Link
        onClick={() => {
          navigate(`/${accountId}/company-information?tab=Subscription`);
        }}
      >
        See subscription page for details.
      </Link>
    );
  };

  const renderMessage = () => {
    const includeMessageParts = [];
    const maxMessageParts = [];

    if (includedAdmins) {
      includeMessageParts.push(`${includedAdmins} admins`);
    }
    if (includedEmployees) {
      includeMessageParts.push(`${includedEmployees} employees`);
    }

    if (maxAdmins) {
      maxMessageParts.push(`up to ${maxAdmins} admins`);
    }
    if (maxEmployees) {
      maxMessageParts.push(`up to ${maxEmployees} employees`);
    }

    const includeMessage =
      includeMessageParts.length > 0
        ? `Your plan includes ${includeMessageParts.join(" and ")}.`
        : "";

    const maxMessage =
      maxMessageParts.length > 0
        ? `Your plan allows ${maxMessageParts.join(" or ")}.`
        : "";

    return (
      <>
        {includeMessage} {` `}
        {maxMessage} {` `}
        {
          {
            CSV_UPLOAD: (
              <>
                {getNewUsageMessageForCsvUpload(
                  subscriptionPlan,
                  currentNumEmployees,
                  numEmployeesToAdd
                )}{" "}
                {` `}
              </>
            ),
            ADD_EMPLOYEE: (
              <>
                {getNewUsageMessageForAddEmployee(
                  subscriptionPlan,
                  currentNumEmployees
                )}{" "}
                {` `}
              </>
            ),
            ADD_ADMIN: (
              <>
                {getNewUsageMessageForAddAdmin(
                  subscriptionPlan,
                  currentNumAdmins
                )}{" "}
                {` `}
              </>
            ),
          }[newUsageType]
        }
        <SubscriptionTabLink />
      </>
    );
  };

  const message = renderMessage();
  if (!message) {
    return null;
  }

  return (
    <HStack w={"100%"}>
      <Icon as={FiInfo} boxSize="7" color={"primary"} />
      <Text fontSize={"sm"}>{message}</Text>
    </HStack>
  );
};

export default UsageChangeNotification;
