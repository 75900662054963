import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import api from "../config";
import { EventSignUp } from "./types";

interface CreateEventSignupVariables {
  accountId: string;
  data: any;
}

interface UpdateEventSignupVariables {
  accountId: string;
  eventSignupId: string;
  data: any;
}

interface DeleteEventSignupVariables {
  accountId: string;
  eventSignupId: string;
}

export const useCreateEventSignup = (
  props?: UseMutationOptions<EventSignUp, unknown, CreateEventSignupVariables>
) =>
  useMutation({
    mutationKey: ["createEventSignup"],
    mutationFn: async ({ accountId, data }): Promise<EventSignUp> => {
      const response = await api.post(`/${accountId}/eventSignup`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateEventSignup = (
  props?: UseMutationOptions<EventSignUp, unknown, UpdateEventSignupVariables>
) =>
  useMutation({
    mutationKey: ["updateEventSignup"],
    mutationFn: async ({
      accountId,
      eventSignupId,
      data,
    }): Promise<EventSignUp> => {
      const response = await api.put(
        `/${accountId}/eventSignup/${eventSignupId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeleteEventSignup = (
  props?: UseMutationOptions<void, unknown, DeleteEventSignupVariables>
) =>
  useMutation({
    mutationKey: ["deleteEventSignup"],
    mutationFn: async ({ accountId, eventSignupId }): Promise<void> => {
      const response = await api.delete(
        `/${accountId}/eventSignup/${eventSignupId}`
      );
      return response.data;
    },
    ...props,
  });
