import {
  Box,
  Heading,
  Text,
  VStack,
  ListItem,
  ListIcon,
  useRadio,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { AccountContext } from "../../context/AccountContextComponent";
import { Subscriptions } from "./Subscriptions";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import pricingBg from "../../assets/backgrounds/pricing_bg.png";
import TagManager from "react-gtm-module/dist/TagManager.js";

export const plans = {
  starter: {
    features: [
      "Admin Dashboard",
      "Employee Culture Hub",
      "Objectives and Key Results",
      "Event Registration",
      "eNPS and Surveys",
      "Team Alignment",
      "Reports",
    ],
  },
  professional: {
    features: [
      `All the features in "Starter" PLUS Integrations`,
      // ">10 Users",
    ],
  },
  enterprise: {
    features: [
      "Everything in Professional",
      // "Unlimited Users",
      "Additional customization",
      "Enterprise support",
    ],
  },
};

export function PriceWrapper(props) {
  const { children } = props;

  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      borderColor={"gray.500"}
      borderRadius={"xl"}
      alignSelf={{ base: "center", lg: "flex-start" }}
      bg={"white"}
      w={"270px"}
      py={4}
      px={5}
      h={"614px"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      {children}
    </Box>
  );
}

export function RadioCard(props) {
  const { styleProps } = props;
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        boxShadow="md"
        bg={"white"}
        _checked={{
          bg: "primary",
          color: "white",
          borderColor: "primary",
        }}
        px={5}
        py={3}
        {...styleProps}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export const PlanFeature = ({ label }) => {
  return (
    <ListItem>
      <ListIcon as={FaCheckCircle} color="green.500" />
      {label}
    </ListItem>
  );
};

export default function SubscriptionsPage({ handlePlanSelect }) {
  const { accountData } = useContext(AccountContext);
  const hasAccountSubscription = accountData?.accountSubscription !== null;
  const navigate = useCustomDomainNavigate();
  const { accountId } = useAccountId();

  TagManager.dataLayer({
    dataLayer: {
      accountId: accountId,
      page: 'subscriptionSetup'
    }
  });

  // If the account has a subscription, redirect to the admin dashboard
  if (accountData?.accountSubscription && accountData.accountSubscription) {
    console.log(
      "Redirecting to admin dashboard because an account sub is already set."
    );
    navigate(`/${accountId}/admin-dashboard`);
  }

  useEffect(() => {
    if (accountData?.accountSubscription && accountData.accountSubscription) {
      console.log(
        "Redirecting to admin dashboard because an account sub is already set."
      );
      navigate(`/${accountId}/admin-dashboard`);
    }
  }, [accountData]);

  return (
    <Box
      py={12}
      align={"center"}
      style={{
        position: "relative", // Changed from absolute to relative
        minHeight: "100vh", // Ensures it takes at least the full height of the viewport
        width: "100%",
        backgroundImage: `url(${pricingBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="5xl" color={"white"} fontWeight={800}>
          Plans that fit your need
        </Heading>
        <Text fontSize="2xl" color={"white"} fontWeight={400}>
          {!hasAccountSubscription && "Signup for a FREE 30-day trial."}
        </Text>
      </VStack>
      <Box maxW={"1200px"}>
        <Subscriptions handlePlanSelect={handlePlanSelect} />
      </Box>
    </Box>
  );
}
