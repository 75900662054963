import {
  Box,
  Button,
  HStack,
  Link,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { AccountContext } from "../../../context/AccountContextComponent";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../../hooks/customDomainHooks";
import { ObjectiveSetTitle } from "../../OKRV2/ObjectiveSetTitle";
import { ObjectiveLabel } from "../../OKRV2/Objectives/ObjectiveLabel";
import useOKRs from "../../OKRV2/hooks/useOkrs";

const MyOKRsWidget = () => {
  const navigate = useCustomDomainNavigate();
  const { accountId } = useAccountId();
  const { currentTimePeriod } = useContext(AccountContext);

  const {
    objectiveSets,
    isLoadingObjectives,
    isErrorObjectives,
    setTimePeriodsToFilter,
  } = useOKRs("my", "PROFILE", "");

  // Only show the current time period for widgets
  useEffect(() => {
    if (currentTimePeriod) {
      setTimePeriodsToFilter([
        { value: currentTimePeriod.id, label: currentTimePeriod.label },
      ]);
    }
  }, [currentTimePeriod]);

  if (!isLoadingObjectives && !objectiveSets)
    return <Text>You have no OKRs.</Text>;
  if (isLoadingObjectives) return <Spinner />;
  if (isErrorObjectives) {
    return <Text>Error loading OKRs!</Text>;
  }

  const handleViewAllClick = () => {
    navigate(`/${accountId}/okrs?tab=My%20OKRs`);
  };

  return (
    <Box w={"100%"}>
      {(objectiveSets?.length > 5
        ? objectiveSets.slice(0, 5)
        : objectiveSets
      ).map((objectiveSet, index) => (
        <ObjectiveSet objectiveSet={objectiveSet} key={index} />
      ))}
      {objectiveSets?.length > 3 && (
        <Button mt={4} w={"100%"} size={"sm"} onClick={handleViewAllClick}>
          View All OKRs
        </Button>
      )}
      {objectiveSets?.length === 0 && (
        <VStack>
          <Text color={"gray.500"}>
            No OKRs in {currentTimePeriod?.label} to show.
          </Text>
          <Button mt={4} w={"100%"} size={"sm"} onClick={handleViewAllClick}>
            Create an OKR
          </Button>
        </VStack>
      )}
    </Box>
  );
};

const ObjectiveSet = ({ objectiveSet }) => {
  const { accountId } = useAccountId();
  const navigate = useCustomDomainNavigate();

  const handleViewAllObjectiveClick = (objectiveId) => {
    navigate(`/${accountId}/okrs/?tab=My%20OKRs&objectiveId=${objectiveId}`);
  };

  const handleViewAllClick = () => {
    navigate(`/${accountId}/okrs?tab=My%20OKRs`);
  };

  return (
    <Box w={"100%"} py={0} px={0} key={objectiveSet.id}>
      <Box bg={"#4285F41F"} my={2}>
        <ObjectiveSetTitle objectiveSet={objectiveSet} />
      </Box>
      <VStack spacing={3}>
        {(objectiveSet.objectives?.length > 5
          ? objectiveSet.objectives.slice(0, 5)
          : objectiveSet.objectives
        ).map((objective) => (
          <Box
            key={objective.id}
            w={"100%"}
            py={0}
            px={0}
            style={{
              fontWeight: 800,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <HStack>
              <ObjectiveLabel
                canExpand={false}
                objective={objective}
                onClick={() => {
                  handleViewAllObjectiveClick(objective.id);
                }}
              />
              <HStack justifyContent={"space-between"} w={"100%"} spacing={0}>
                <Box
                  w={"210px"}
                  color={"blue.500"}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Link
                    color={"blue.500"}
                    onClick={() => {
                      handleViewAllObjectiveClick(objective.id);
                    }}
                    style={{
                      fontWeight: 800,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    textDecoration="none"
                  >
                    {objective.title || "no title"}
                  </Link>
                </Box>
                <Box>
                  <Link
                    color={"gray.500"}
                    fontSize={"sm"}
                    textDecoration="none"
                    onClick={() => {
                      handleViewAllObjectiveClick(objective.id);
                    }}
                  >
                    {objective.keyResults.length} KR
                    {objective.keyResults.length === 1 ? "" : "s"}
                  </Link>
                </Box>
              </HStack>
            </HStack>
          </Box>
        ))}
        {objectiveSet.objectives?.length > 3 && (
          <Button
            onClick={handleViewAllClick}
            size={"xs"}
            variant={"ghost"}
            alignSelf={"flex-end"}
          >
            View all
          </Button>
        )}
      </VStack>
    </Box>
  );
};
export default MyOKRsWidget;
