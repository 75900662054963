import { RRule } from "rrule";
import {
  AddToCalendarButton,
  createGoogleCalendarLink,
  createICalData,
} from "../../components/common/Buttons/AddToCalendarButton";

export const OneOnOneCalendarLinks = ({ oneOnOneSchedule, startDate }) => {
  const durationMinutes = oneOnOneSchedule.durationMinutes;
  const rRuleString = oneOnOneSchedule?.recurrence?.rrule;

  const rrule = rRuleString ? RRule.fromString(rRuleString) : null;
  // endDate is the start date + durationMinutes
  const endDate = new Date(startDate);
  endDate.setMinutes(endDate.getMinutes() + durationMinutes);

  const googleLink = createGoogleCalendarLink(
    {
      title: "1-on-1 with " + oneOnOneSchedule.invitee.name,
      startTime: startDate,
      endTime: endDate.toISOString(),
      location: "",
      recurrence: rrule?.toString(),
    },
    [oneOnOneSchedule.invitee.email]
  );

  const iCalLink = `data:text/calendar;charset=utf8,${createICalData(
    {
      title: "1-on-1 with " + oneOnOneSchedule.invitee.name,
      startTime: startDate,
      endTime: endDate.toISOString(),
      location: "",
      recurrence: rrule?.toString(),
    },
    [oneOnOneSchedule.invitee.email]
  )}`;

  return <AddToCalendarButton googleLink={googleLink} iCalLink={iCalLink} />;
};
