import {
  Box,
  Center,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import LoadingBox from "../../components/common/LoadingBox";
import ManageReportMenu from "./ManageReportMenu";
import { aRankStyle, bRankStyle, cRankStyle } from "./style";
import { AvatarWithName } from "../../components/common/AvatarWithName";
import { useContext } from "react";
import { AccountContext } from "../../context/AccountContextComponent";

const ViewAbcRankingReport = (props) => {
  const {
    rankingReport,
    isLoadingAbcRankingReport,
    canEdit,
    setViewMode,
    viewMode,
    canManageReviewers,
    canManageViewers,
  } = props;
  const { reportId } = useParams();

  if (isLoadingAbcRankingReport) {
    return <LoadingBox />;
  }

  return (
    <>
      <Box
        bg={"white"}
        borderRadius={"md"}
        border={"1px"}
        borderColor={"gray.200"}
        p={4}
        overflow={"auto"}
      >
        <HStack justifyContent={"space-between"} mb={4}>
          <Box>
            <Text fontWeight={"bold"}>
              Manager: {rankingReport.manager.name}
            </Text>
            <Text>Department: {rankingReport.department?.name}</Text>
            {!rankingReport.rankingPeriod.isOpen && (
              <Text fontSize={"sm"} color={"red.600"}>
                This ranking report for{" "}
                <strong>{rankingReport.rankingPeriod?.period?.label}</strong> is
                currently closed. Changes cannot be made.
              </Text>
            )}
            {rankingReport.submittedBy && (
              <Text color={"red.600"}>
                Report submitted by: {rankingReport.submittedBy.name}
              </Text>
            )}
          </Box>
          {(canEdit || canManageViewers) && (
            <ManageReportMenu
              canEdit={canEdit}
              setViewMode={setViewMode}
              viewMode={viewMode}
              canManageReviewers={canManageReviewers}
              canManageViewers={canManageViewers}
              rankingReport={rankingReport}
            />
          )}
        </HStack>
        <Box
          borderRadius={"md"}
          border={"1px"}
          borderColor={"gray.200"}
          mb={4}
          overflow={"auto"}
        >
          <Grid
            templateColumns="repeat(12, 1fr)"
            minWidth={"600px"}
            bg={"gray.100"}
          >
            <GridItem p={4} colSpan={3}>
              Name
            </GridItem>
            <GridItem p={4} colSpan={2}>
              Title
            </GridItem>
            <GridItem p={4} colSpan={2}>
              Department
            </GridItem>
            <GridItem p={4} colSpan={5}>
              Ranking
            </GridItem>

            {rankingReport.employeeRankings?.map((employeeRanking, index) => (
              <GridItem colSpan={12} key={employeeRanking.id} bg={"white"}>
                <ViewRankingReportRow
                  reportId={reportId}
                  employeeRanking={employeeRanking}
                  index={index}
                  key={employeeRanking.id}
                />
              </GridItem>
            ))}

            {rankingReport.employeeRankings?.length === 0 && (
              <GridItem colSpan={12} bg={"white"} p={4}>
                <Center>
                  <Text>No Employees to rank</Text>
                </Center>
              </GridItem>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

const ViewRankingReportRow = ({ employeeRanking, index }) => {
  const { getRankLabel } = useContext(AccountContext);

  return (
    <Box py={2}>
      <Grid
        templateColumns="repeat(12, 1fr)"
        gap={4}
        alignItems="center"
        borderTop={index !== 0 && "1px"}
        borderColor="gray.200"
        p={4}
      >
        <GridItem colSpan={3}>
          <AvatarWithName profile={employeeRanking.employee} />
        </GridItem>

        <GridItem colSpan={2}>
          <Text>{employeeRanking.employee?.title}</Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Text>{employeeRanking.employee?.department?.name}</Text>
        </GridItem>
        <GridItem colSpan={5}>
          <VStack alignItems={"flex-start"}>
            <Box
              // w={"20px"}
              borderWidth="2px"
              borderRadius="md"
              px={8}
              py={1}
              justifyContent={"center"}
              display={"flex"}
              {...(employeeRanking.ranking === "A" && aRankStyle)}
              {...(employeeRanking.ranking === "B" && bRankStyle)}
              {...(employeeRanking.ranking === "C" && cRankStyle)}
            >
              {getRankLabel(employeeRanking.ranking)}
            </Box>
            {employeeRanking.editor && (
              <Text alignSelf={"flex-start"} fontSize={"xs"} color={"red.600"}>
                Last updated by: {employeeRanking.editor?.name}
              </Text>
            )}
          </VStack>
        </GridItem>

        {/* <GridItem colSpan={6} justifyContent="center" display="flex">
          <VStack>
            <Textarea value={employeeRanking.note || ""} isReadOnly={true} />
            {employeeRanking.editor && (
              <Text alignSelf={"flex-start"} fontSize={"xs"} color={"red.600"}>
                Last updated by: {employeeRanking.editor?.name}
              </Text>
            )}
          </VStack>
        </GridItem> */}
      </Grid>
    </Box>
  );
};

export default ViewAbcRankingReport;
