import { useRadio, useRadioGroup } from "@chakra-ui/radio";
import {
  Box,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import get from "lodash/get";
import { Controller, useFormContext } from "react-hook-form";

export function RadioCard(props: any) {
  const { styleProps, label } = props;
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const radio = getRadioProps();

  return (
    <Box as="label">
      <Center>
        <input {...input} />
        <Box
          {...radio}
          cursor="pointer"
          borderWidth="2px"
          borderRadius="md"
          px={8}
          py={1}
          align={"center"}
          {...styleProps}
        >
          {props.children}
        </Box>
      </Center>
      {label}
    </Box>
  );
}

export const RadioGroupField = ({
  field,
  styleType,
}: {
  field: any;
  styleType?: "NPS" | "LinearScale" | "AbcCards";
}) => {
  const form = useFormContext();
  const {
    formState: { errors },
    control,
    getValues,
  } = form;

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: field.id,
    // It makes it easier if all values are strings
    defaultValue: getValues(field.id)?.toString(),
    onChange: (value) => {
      // Convert the value to number if NPS.
      // In the future, we can set response type
      // on the question and use that to determine how to handle the value.
      if (styleType === "NPS" || styleType === "LinearScale") {
        form.setValue(field.id, parseInt(value, 10));
      } else {
        form.setValue(field.id, value);
      }
    },
  });

  const radioGroupProps = getRootProps() as any;

  return (
    <Controller
      name={field.id}
      control={control}
      rules={field.validation}
      render={({ field: { value } }) => (
        <FormControl
          isInvalid={!!get(errors, field.id)}
          isRequired={field.validation?.required}
        >
          <FormLabel htmlFor={field.id}>{field.label}</FormLabel>
          <RadioGroup {...radioGroupProps} value={value}>
            <Wrap
              spacing={4}
              direction={!styleType ? "column" : "row"}
              justify={styleType == "AbcCards" ? "flex-start" : "center"}
            >
              {field.options.map((option: any) => (
                <WrapItem key={option.value}>
                  {styleType === "AbcCards" && (
                    <RadioCard
                      key={option.value}
                      {...getRadioProps({
                        value: option.value.toString(),
                      })}
                      styleProps={option.styles}
                    >
                      {option.label}
                    </RadioCard>
                  )}
                  {styleType === "NPS" && (
                    <RadioCard
                      key={option.value}
                      styleProps={{
                        borderColor: get(errors, field.id)
                          ? "red.500"
                          : "gray.200",
                        w: "60px",
                        px: 2,
                        py: 1,
                        _checked: {
                          bg: "blue.400",
                          color: "white",
                          borderColor: "blue.400",
                        },
                      }}
                      {...getRadioProps({ value: option.value.toString() })}
                    >
                      {option.value}
                    </RadioCard>
                  )}
                  {styleType === "LinearScale" && (
                    <Box w={"70px"}>
                      <RadioCard
                        key={option.value}
                        label={
                          option.label && (
                            <Box
                              pt={2}
                              alignItems={"center"}
                              alignContent={"center"}
                            >
                              <Text fontSize={"xs"}>{option.label}</Text>
                            </Box>
                          )
                        }
                        styleProps={{
                          borderColor: get(errors, field.id)
                            ? "red.500"
                            : "gray.200",
                          w: "60px",
                          px: 2,
                          py: 1,
                          _checked: {
                            bg: "blue.400",
                            color: "white",
                            borderColor: "blue.400",
                          },
                        }}
                        {...getRadioProps({ value: option.value.toString() })}
                      >
                        {option.value}
                      </RadioCard>
                    </Box>
                  )}
                  {!styleType && (
                    <Radio
                      key={option.value}
                      {...getRadioProps({ value: option.value.toString() })}
                      px={1}
                    >
                      {option.label}
                    </Radio>
                  )}
                </WrapItem>
              ))}
            </Wrap>
          </RadioGroup>
          {styleType === "NPS" && (
            <Center w={"100%"}>
              <Box w={"100%"}>
                <HStack w={"100%"} pt={2} justifyContent={"space-between"}>
                  <Text fontSize={"xs"}>0 - {field.options[0]?.label}</Text>
                  <Text fontSize={"xs"}>
                    10 - {field.options[field.options.length - 1]?.label}
                  </Text>
                </HStack>
              </Box>
            </Center>
          )}
          <FormErrorMessage>
            <>{!!get(errors, field.id) && get(errors, `${field.id}.message`)}</>
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};
