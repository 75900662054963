import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../config";
import { AdminDashboardLayout } from "./types";

export const useFetchAdminDashboardLayout = (accountId: string) => {
  return useQuery<AdminDashboardLayout, Error>({
    queryKey: [accountId, "fetchAdminDashboardLayout"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/adminDashboardLayout`);
      return response.data;
    },
  });
};

export const useUpdateAdminDashboardLayout = (props?: any) =>
  useMutation<
    AdminDashboardLayout,
    Error,
    { accountId: string; adminDashboardLayoutId: string; data: any }
  >({
    mutationKey: ["updateAdminDashboardLayout"],
    mutationFn: async ({ accountId, adminDashboardLayoutId, data }) => {
      const response = await api.put(
        `/${accountId}/adminDashboardLayout/${adminDashboardLayoutId}`,
        data
      );
      return response.data;
    },
    ...props,
  });
