import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import { AccountContextComponent } from "../context/AccountContextComponent";
import { BindSlackContextComponent } from "../context/BindSlackContextComponent";
import { UserContextComponent } from "../context/UserContextComponent";
import { Navbar } from "./Navbar";
import DashboardSidebar from "./sidebar/DashboardSidebar";

const DynamicHelmet = () => {
  return (
    <Helmet>
      <title> The Best Answer | Dashboard</title>
    </Helmet>
  );
};

export const DashboardLayout = ({
  children,
  hideSidebar,
  hideCompanySettings,
}: {
  children: React.ReactNode;
  hideSidebar?: boolean;
  hideCompanySettings?: boolean;
}) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <UserContextComponent>
      <AccountContextComponent>
        <BindSlackContextComponent>
          <DynamicHelmet />
          <Flex
            direction="column"
            h="full"
            id="app-container"
            minHeight="100vh"
            position="relative"
            overflow="hidden"
          >
            <Navbar
              hideSidebar={hideSidebar}
              hideCompanySettings={hideCompanySettings}
            />
            {isDesktop && !hideSidebar && <DashboardSidebar />}
            <Box
              bg={"offWhite"}
              flex="1"
              overflowY={"auto"}
              ml={isDesktop && !hideSidebar ? "64" : "0"}
              //pl={isDesktop && !hideSidebar ? "4" : "0"}
              pt="64px"
            >
              {children || <Outlet />}
            </Box>
          </Flex>
        </BindSlackContextComponent>
      </AccountContextComponent>
    </UserContextComponent>
  );
};
