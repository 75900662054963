import { Box, Link, Spinner, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useFetchLatestSurveyRunMetrics } from "../../../../api/survey/survey";
import PieChartComponent from "../../../../components/common/charts/PieChart";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../../../hooks/customDomainHooks";
import SampleDataWidget from "./SampleDataWidget";

const SurveyParticipationWidget = ({ widgetData, setWidgetTitle }) => {
  const { accountId } = useAccountId();
  const {
    data: surveyParticipationData,
    isLoading: isLoadingSurveyParticipationMetrics,
    isError: isErrorSurveyParticipationMetrics,
    error: errorSurveyParticipationMetrics,
  } = useFetchLatestSurveyRunMetrics(accountId, widgetData.data?.surveyId);
  const navigate = useCustomDomainNavigate();

  useEffect(() => {
    if (surveyParticipationData?.surveyRun?.label) {
      setWidgetTitle(
        <Link
          color={"textPrimary"}
          onClick={() => {
            navigate(
              `/${accountId}/surveys/${surveyParticipationData.surveyRun?.surveyId}/update`
            );
          }}
        >
          <Text fontWeight={"bold"} noOfLines={1} align={"center"}>
            {surveyParticipationData.surveyRun?.survey?.title}
          </Text>
          <Text fontWeight={"normal"} noOfLines={1} align={"center"}>
            {surveyParticipationData.surveyRun?.label}
          </Text>
        </Link>
      );
    } else {
      setWidgetTitle(
        <Link
          color={"textPrimary"}
          onClick={() => {
            navigate(
              `/${accountId}/surveys/${widgetData?.data?.surveyId}/update?tab=Run`
            );
          }}
          fontWeight={"bold"}
        >
          Survey Participation
        </Link>
      );
    }
  }, [surveyParticipationData, setWidgetTitle]);

  if (isLoadingSurveyParticipationMetrics) return <Spinner />;
  if (isErrorSurveyParticipationMetrics) {
    console.error(errorSurveyParticipationMetrics);
    return <Text>Error loading survey participation metrics!</Text>;
  }

  if (!surveyParticipationData || surveyParticipationData.message) {
    return (
      <SampleDataWidget
        imageUrl={"survey-participation-widget.png"}
        text={
          "Survey participation results will display after creating your first survey run."
        }
        // width={310}
        height={250}
        accountId={accountId}
      />
    );
    // return (
    //   <Center h={"200px"}>
    //     <Text align={"center"} color={"gray.500"}>
    //       No survey results to show yet or the survey run is still accepting
    //       responses.
    //     </Text>
    //   </Center>
    // );
  }
  const colors = {
    Submitted: "#48BB78",
    Started: "#d1af00",
    "Not Started": "#afb9c4",
  };

  const data = [
    { label: "Submitted", value: surveyParticipationData.numSubmitted },
    { label: "Started", value: surveyParticipationData.numStartedNotSubmitted },
    { label: "Not Started", value: surveyParticipationData.numNotStarted },
  ];

  return (
    <Box pt={4}>
      <PieChartComponent
        data={data}
        colors={colors}
        displayPercentages={true}
        height={280}
      />
    </Box>
  );
};

export default SurveyParticipationWidget;
