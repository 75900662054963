import { Box, Center, HStack, Link, Spinner, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useFetchTimeSeriesQuestionFamilyResponseMetrics } from "../../../../api/survey/questionFamily";
import BarGraph from "../../../../components/common/charts/BarGraph";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../../../hooks/customDomainHooks";
import PercentageSwitch from "./PercentageSwitch";

const TimeSeriesSurveyQuestionResponsesWidget = ({
  widgetData,
  setWidgetTitle,
  updateAdminWidget,
}) => {
  const { accountId } = useAccountId();
  const {
    data: questionMetrics,
    isLoading: isLoadingQuestionMetrics,
    isError: isErrorQuestionMetrics,
    error: errorQuestionMetrics,
  } = useFetchTimeSeriesQuestionFamilyResponseMetrics(
    accountId,
    widgetData.data?.familyId
  );
  const navigate = useCustomDomainNavigate();

  useEffect(() => {
    if (questionMetrics?.surveyRuns?.length) {
      setWidgetTitle(
        <Link
          color={"textPrimary"}
          onClick={() => {
            navigate(
              `/${accountId}/surveys/${questionMetrics.surveyRuns[0]?.survey?.id}/update`
            );
          }}
        >
          <Text fontWeight={"bold"} noOfLines={1} align={"center"}>
            {questionMetrics.surveyRuns[0]?.survey?.title}
          </Text>
        </Link>
      );
    } else {
      setWidgetTitle(
        <Link
          color={"textPrimary"}
          onClick={() => {
            navigate(
              `/${accountId}/surveys/${widgetData?.data?.surveyId}/update?tab=Run`
            );
          }}
          fontWeight={"bold"}
        >
          Survey Results
        </Link>
      );
    }
  }, [questionMetrics, setWidgetTitle]);

  if (isLoadingQuestionMetrics) return <Spinner />;
  if (isErrorQuestionMetrics) {
    console.error(errorQuestionMetrics);
    return <Text>Error loading survey response metrics!</Text>;
  }
  if (!questionMetrics || questionMetrics.message) {
    return (
      <Center h={"200px"}>
        <Text align={"center"} color={"gray.500"}>
          No survey results to show yet or the survey run is still accepting
          responses.
        </Text>
      </Center>
    );
  }

  return (
    <Box w={"100%"} h={"200px"}>
      <HStack w={"100%"} justifyContent={"space-between"}>
        <Text m={1} noOfLines={2}>
          {questionMetrics.question?.prompt}
        </Text>
        {["CHECKBOX", "RADIO", "DROPDOWN"].includes(
          questionMetrics.question?.element?.type
        ) && (
          <PercentageSwitch
            widgetData={widgetData}
            updateAdminWidget={updateAdminWidget}
          />
        )}
      </HStack>
      {questionMetrics.surveyRuns?.length &&
        ["CHECKBOX", "RADIO", "DROPDOWN"].includes(
          questionMetrics.question?.element?.type
        ) && (
          <StaticValuesSeriesChart
            questionMetrics={questionMetrics}
            widgetData={widgetData}
          />
        )}
      {questionMetrics.surveyRuns?.length &&
        ["LINEAR_SCALE"].includes(questionMetrics.question?.element?.type) && (
          <LinearScaleChart questionMetrics={questionMetrics} />
        )}
      {questionMetrics.surveyRuns?.length &&
        ["NUMBER"].includes(questionMetrics.question?.element?.type) && (
          <NumericChart questionMetrics={questionMetrics} />
        )}
      {questionMetrics.surveyRuns?.length &&
        ["NPS"].includes(questionMetrics.question?.element?.type) && (
          <NPSChart questionMetrics={questionMetrics} />
        )}
      {!questionMetrics.surveyRuns?.length && (
        <Center h={"100%"}>
          <Text align={"center"}>No responses yet.</Text>
        </Center>
      )}
    </Box>
  );
};

const NPSChart = ({ questionMetrics }) => {
  function transformResponses(surveyData) {
    let data = surveyData.surveyRuns.map((surveyRun) => {
      let surveyRunData = { label: surveyRun.label };
      let points = 0;
      surveyRun.questionResponses.forEach((response) => {
        if (response.response.value >= 9) {
          points += 100;
        } else if (response.response.value <= 6) {
          points -= 100;
        }
      });
      const responsesLength = surveyRun.questionResponses.filter(
        (response) => response.response.value !== undefined
      ).length;
      if (responsesLength === 0) {
        surveyRunData["NPS"] = 0;
        return surveyRunData;
      }
      const nps = points / responsesLength;
      surveyRunData["NPS"] = nps.toFixed(0);

      return surveyRunData;
    });

    return data;
  }

  let transformedResponses = transformResponses(questionMetrics);

  const colors = {
    NPS: "#48BB78",
  };

  return <BarGraph data={transformedResponses} height={260} colors={colors} />;
};

const NumericChart = ({ questionMetrics }) => {
  const minAllowed = questionMetrics.question?.element?.min || undefined;
  const maxAllowed = questionMetrics.question?.element?.max || undefined;

  function transformResponses(surveyData) {
    let data = surveyData.surveyRuns.map((surveyRun) => {
      let surveyRunData = { label: surveyRun.label };
      const total = surveyRun.questionResponses.reduce((total, response) => {
        if (
          (minAllowed !== undefined && response.response.value < minAllowed) ||
          (maxAllowed !== undefined && response.response.value > maxAllowed) ||
          response.response.value === undefined
        ) {
          return total;
        }
        return total + Number(response.response.value);
      }, 0);
      const responsesLength = surveyRun.questionResponses.filter(
        (response) => response.response.value !== undefined
      ).length;
      if (responsesLength === 0) {
        surveyRunData["Average"] = 0;
        return surveyRunData;
      }
      const average = total / responsesLength;
      surveyRunData["Average"] = average.toFixed(2);

      return surveyRunData;
    });

    return data;
  }

  let transformedResponses = transformResponses(questionMetrics);

  const colors = {
    Average: "#23395d",
  };

  return <BarGraph data={transformedResponses} height={260} colors={colors} />;
};

const LinearScaleChart = ({ questionMetrics }) => {
  function transformResponses(surveyData) {
    let data = surveyData.surveyRuns.map((surveyRun) => {
      let surveyRunData = { label: surveyRun.label };
      const total = surveyRun.questionResponses.reduce((total, response) => {
        if (response.response.value === undefined) {
          return total;
        }
        return total + Number(response.response.value);
      }, 0);
      const responsesLength = surveyRun.questionResponses.filter(
        (response) => response.response.value !== undefined
      ).length;
      if (responsesLength === 0) {
        surveyRunData["Average"] = 0;
        return surveyRunData;
      }
      const average = total / responsesLength;
      surveyRunData["Average"] = average.toFixed(2);

      return surveyRunData;
    });

    return data;
  }

  let transformedResponses = transformResponses(questionMetrics);

  const colors = {
    Average: "#48BB78",
  };

  return <BarGraph data={transformedResponses} height={260} colors={colors} />;
};

const StaticValuesSeriesChart = ({ questionMetrics, widgetData }) => {
  function transformResponses(surveyData) {
    let data = surveyData.surveyRuns.map((surveyRun) => {
      let surveyRunData = { label: surveyRun.label };
      // Initialize all possible options in the surveyRunData
      surveyRunData = surveyData.question?.element?.choices.reduce(
        (acc, choice) => {
          acc[choice.label] = 0;
          return acc;
        },
        surveyRunData
      );

      surveyRun.questionResponses.forEach((response) => {
        // RADIO or DROPDOWN Question
        if (
          questionMetrics.question?.element?.type === "RADIO" ||
          questionMetrics.question?.element?.type === "DROPDOWN"
        ) {
          const val = response.response?.value;
          if (!surveyRunData[val]) {
            surveyRunData[val] = 1;
          } else {
            surveyRunData[val] += 1;
          }
        } else {
          // CHECKBOX Question
          response.response.value.forEach((val) => {
            if (!surveyRunData[val]) {
              surveyRunData[val] = 1;
            } else {
              surveyRunData[val] += 1;
            }
          });
        }
      });
      return surveyRunData;
    });

    return data;
  }

  let transformedResponses = transformResponses(questionMetrics);

  return (
    <BarGraph
      data={transformedResponses}
      height={260}
      displayPercentages={widgetData.data?.displayPercentages}
    />
  );
};

export default TimeSeriesSurveyQuestionResponsesWidget;
