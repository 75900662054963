import { Button, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { useCreateDepartment } from "../../../api/departments/department";
import { InputField } from "../../../components/fields/InputField";
import useAccountId from "../../../hooks/customDomainHooks";
import { useToastMessage } from "../../../hooks/useToastMessage";

const CreateDepartment = () => {
  const { accountId } = useAccountId();
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const createDepartmentMutation = useCreateDepartment();
  const queryClient = useQueryClient();
  const form = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const createDepartment = async (data) => {
    try {
      const respData = await createDepartmentMutation.mutateAsync({
        accountId: accountId || "",
        data,
      });
      console.log(respData);
      showSuccessToast("Department created");
      queryClient.invalidateQueries(["fetchDepartments", accountId]);
      form.reset();
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating department." });
    }
  };

  const onSubmit = async (data) => {
    await createDepartment(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          <InputField
            field={{
              id: "name",
              placeholder: "Name",
              label: "Name",
              validation: {
                required: { value: true, message: "Name is required" },
              },
            }}
          />
          <InputField
            field={{
              id: "description",
              placeholder: "Description",
              label: "Description",
            }}
          />
          <InputField
            field={{
              id: "code",
              placeholder: "Code",
              label: "Code",
            }}
          />
          <Button type="submit" isLoading={isSubmitting}>
            Create Department
          </Button>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default CreateDepartment;
