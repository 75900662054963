import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import UpdateOneOnOneSchedule from "../Forms/UpdateOneOnOneSchedule";

export const UpdateOneOnOneScheduleModal = (props) => {
  const { isOpen, setIsOpen, meetingData } = props;

  if (!meetingData) return null;
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"2xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Update Scheduled 1-1 with {meetingData?.invitee?.name}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <UpdateOneOnOneSchedule
            onSuccess={() => {
              setIsOpen(false);
            }}
            meetingData={meetingData}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
