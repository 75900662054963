import { Stack, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { DepartmentSelect } from "../common/DepartmentSelect";
import { TenureSelect } from "../common/TenureSelect";

export const TeamAlignmentReportFilters = ({
  tenuresToFilter,
  setTenuresToFilter,
  departmentsToFilter,
  setDepartmentsToFilter,
  watchGraphType,
}) => {
  return (
    <Stack direction={{ base: "column", sm: "row" }} alignItems={"center"}>
      <Text fontWeight={700} whiteSpace={"nowrap"}>
        Filtered by:
      </Text>
      <Wrap>
        {watchGraphType !== "byTenure" && (
          <WrapItem>
            <TenureSelect
              selectedTenures={tenuresToFilter}
              setSelectedTenures={setTenuresToFilter}
            />
          </WrapItem>
        )}
        {watchGraphType !== "byDepartment" && (
          <WrapItem>
            <DepartmentSelect
              selectedDepartments={departmentsToFilter}
              setSelectedDepartments={setDepartmentsToFilter}
            />
          </WrapItem>
        )}
      </Wrap>
    </Stack>
  );
};
