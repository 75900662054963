import { useParams } from "react-router-dom";
import PageShell from "../../../layout/PageShell";
import { useState } from "react";
import LoadingBox from "../../../components/common/LoadingBox";
import EditKbPageForm from "./EditKbPageForm";
import { EditKbPageViewersModal } from "../modals/EditKbPageViewersModal";
import { EditKbPageEditorsModal } from "../modals/EditKbPageEditorsModal";
import { HStack } from "@chakra-ui/react";
import KbPageMenu from "../KbPageMenu";
import { useFetchKbPage } from "../../../api/knowledgeBase/kbPage";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../../hooks/customDomainHooks";

const EditKbPage = () => {
  const { pageId } = useParams();
  const { accountId } = useAccountId();
  const {
    data: pageData,
    isLoading: isLoadingKbPage,
    isError: isErrorFetchingKbPage,
    error: errorFetchingKbPage,
  } = useFetchKbPage(accountId, pageId);

  const [showViewersModal, setShowViewersModal] = useState(false);
  const [showEditorsModal, setShowEditorsModal] = useState(false);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Knowledge Base", href: `/${accountId}/knowledge-base` },
    ...(pageData?.parent?.parent
      ? [
          {
            name: "...",
            href: `/${accountId}/knowledge-base/${pageData.parent.parent.id}/view`,
          },
        ]
      : []),
    ...(pageData?.parent
      ? [
          {
            name: pageData.parent.title,
            href: `/${accountId}/knowledge-base/${pageData.parent.id}/view`,
          },
        ]
      : []),
    { name: pageData?.title },
  ]);

  if (isLoadingKbPage) {
    return <LoadingBox />;
  }
  if (isErrorFetchingKbPage) {
    return <p>{errorFetchingKbPage.message}</p>;
  }
  if (!pageData) {
    return <p>Page not found</p>;
  }

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      headerComponentRight={
        <HStack>
          <KbPageMenu
            pageData={pageData}
            menuItems={["VIEW", "COPY", "DELETE"]}
          />
        </HStack>
      }
    >
      <EditKbPageForm
        pageData={pageData}
        setShowViewersModal={setShowViewersModal}
        setShowEditorsModal={setShowEditorsModal}
      />
      <EditKbPageViewersModal
        isOpen={showViewersModal}
        setIsOpen={setShowViewersModal}
        currentViewers={pageData.viewers}
      />
      <EditKbPageEditorsModal
        isOpen={showEditorsModal}
        setIsOpen={setShowEditorsModal}
        pageData={pageData}
      />
    </PageShell>
  );
};

export default EditKbPage;
