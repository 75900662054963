export const formatTimeperiodOptions = (timePeriods, hideTimePeriods) => {
  if (!timePeriods) return [];
  const pastTimePeriods = timePeriods
    .filter((timePeriod) => new Date(timePeriod.endDate) < new Date())
    // filter out hideTimePeriods
    .filter(
      (timePeriod) =>
        !hideTimePeriods.some(
          (timePeriodToHide) => timePeriodToHide.value === timePeriod.id
        )
    )
    .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
    .map((timePeriod) => ({
      value: timePeriod.id,
      label: timePeriod.label,
    }));

  const upcomingTimePeriods = timePeriods
    .filter(
      (timePeriod) =>
        new Date(timePeriod.endDate) > new Date() &&
        new Date(timePeriod.startDate) > new Date()
    )
    // filter out hideTimePeriods
    .filter(
      (timePeriod) =>
        !hideTimePeriods.some(
          (timePeriodToHide) => timePeriodToHide.value === timePeriod.id
        )
    )
    .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    .map((timePeriod) => ({
      value: timePeriod.id,
      label: timePeriod.label,
    }));

  const currentTimePeriods = timePeriods
    .filter(
      (timePeriod) =>
        new Date(timePeriod.startDate) < new Date() &&
        new Date(timePeriod.endDate) > new Date()
    )
    // filter out hideTimePeriods
    .filter(
      (timePeriod) =>
        !hideTimePeriods.some(
          (timePeriodToHide) => timePeriodToHide.value === timePeriod.id
        )
    )
    .map((timePeriod) => ({
      value: timePeriod.id,
      label: timePeriod.label,
    }));

  return [
    {
      label: "Current",
      options: currentTimePeriods,
    },
    {
      label: "Upcoming",
      options: upcomingTimePeriods,
    },
    {
      label: "Past",
      options: pastTimePeriods,
    },
  ];
};

export const formatOwnerOptions = (
  accountData,
  hideOwners = [],
  includeAll = true,
  hideCompany = false
) => {
  if (!accountData) return [];
  const profiles = accountData.profiles;
  const departments = accountData.departments;
  const teams = accountData.teams;
  let options = [
    {
      label: "People",
      options: profiles
        ?.filter(
          (owner) =>
            !hideOwners.some((ownerToHide) => ownerToHide.value === owner.id)
        )
        .map((profile) => ({
          value: profile.id,
          label: profile.name,
          type: "profile",
        })),
    },
  ];
  if (includeAll) {
    if (!hideCompany) {
      options.push({
        label: "Company",
        options: [
          {
            value: "COMPANY",
            label: "Company",
            type: "company",
          },
        ],
      });
    }
    options.push({
      label: "Departments",
      options: departments
        ?.filter(
          (owner) =>
            !hideOwners.some((ownerToHide) => ownerToHide.value === owner.id)
        )
        .map((department) => ({
          value: department.id,
          label: department.name,
          type: "department",
        })),
    });
    options.push({
      label: "Teams",
      options: teams
        ?.filter(
          (owner) =>
            !hideOwners.some((ownerToHide) => ownerToHide.value === owner.id)
        )
        .map((team) => ({
          value: team.id,
          label: team.name,
          type: "team",
        })),
    });
  }
  return options;
};
