import { Box, Center, Text } from "@chakra-ui/react";
import HistogramChart from "../../../../components/common/charts/HistogramChart";
import { getCultureScoreColor } from "../../../CultureScore/CultureScoreIndicator";
import { useCultureScoreData } from "../../../CultureScore/useCultureScoreData";
import SampleDataWidget from "./SampleDataWidget";

const CultureScoreSeriesWidget = () => {
  const { monthlyCultureScoreAverages: dataFromHook, cultureScoreFactors } =
    useCultureScoreData();

  // Create an array of months starting from the current month and going back 12 months
  const allMonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const last12MonthYears = getLastTwelveMonthYears(allMonthNames);

  // Construct a full year of data with 0 values if a month is missing from dataFromHook
  const data = last12MonthYears.map((monthYear) => {
    const monthData = dataFromHook?.find((d) => d.name === monthYear);
    if (monthData) {
      // strip the year for display
      const monthName = monthYear.split("-")[0];
      return { name: monthName, value: monthData.value };
    } else {
      return { name: monthYear, value: 0 };
    }
  });

  // olorsBasedOnNames is a map of month names to colors
  const colorsBasedOnNames = data.reduce((acc, month) => {
    acc[month.name] = getCultureScoreColor(month.value);
    return acc;
  }, {});

  return (
    <Box h={"80%"} maxH={"300px"}>
      <Center>
        <Text fontWeight={"bold"} pb={2}>
          Average Monthly Culture Success Score
        </Text>
      </Center>
      {!dataFromHook ||
      dataFromHook.length === 0 ||
      cultureScoreFactors.length < 7 ? (
        <SampleDataWidget
          imageUrl={"culture-score-time-widget.png"}
          text={
            "Your Culture Success Score time series will start reporting after a week of utilizing the software."
          }
          width={730}
          height={280}
        />
      ) : (
        <HistogramChart
          data={data}
          colorsBasedOnNames={colorsBasedOnNames}
          minValueForColor={0}
          maxValueForColor={100}
          height={"100%"}
          domain={[0, 100]}
        />
      )}
    </Box>
  );
};

const getLastTwelveMonthYears = (monthNames) => {
  const today = new Date();
  let month = today.getMonth(); // 0-indexed, so 0 is January and 11 is December
  let year = today.getFullYear();

  const result = [];

  for (let i = 0; i < 12; i++) {
    result.unshift(`${monthNames[month]}-${year}`); // Adds month-year at the beginning of the result array
    if (month === 0) {
      // if current month is January
      month = 11; // set month to December
      year--; // decrement the year
    } else {
      month--; // otherwise, just decrement the month
    }
  }

  return result;
};

export default CultureScoreSeriesWidget;
