export const allTenures = [
  {
    label: "0 - 1 year",
    value: {
      minDays: 0,
      maxDays: 365,
    },
    color: "#48BB78",
  },
  {
    label: "1 - 4 years",
    value: {
      minDays: 365,
      maxDays: 365 * 4 - 1,
    },
    color: "#36A2EB",
  },
  {
    label: "4 - 7 years",
    value: {
      minDays: 365 * 4,
      maxDays: 365 * 7 - 1,
    },
    color: "#F56565",
  },
  {
    label: "7 - 10 years",
    value: {
      minDays: 365 * 7,
      maxDays: 365 * 10 - 1,
    },
    color: "#FF9F40",
  },
  {
    label: "10+ years",
    value: {
      minDays: 365 * 10,
      maxDays: 365 * 100,
    },
    color: "#FFCE56",
  },
];

export const teamAlignment = {
  A: { color: "#48BB78" },
  B: { color: "#FFD600" },
  C: { color: "#F56565" },
  Unranked: {
    color: "#afb9c4",
  },
};


export const timeRangeOptions = [
  {
    label: "2 Quarters",
    value: "2_QUARTERS",
  },
  {
    label: "3 Quarters",
    value: "3_QUARTERS",
  },
  {
    label: "4 Quarters",
    value: "4_QUARTERS",
  },
  {
    label: "5 Quarters",
    value: "5_QUARTERS",
  },
  {
    label: "6 Quarters",
    value: "6_QUARTERS",
  },
  {
    label: "7 Quarters",
    value: "7_QUARTERS",
  },
  {
    label: "8 Quarters",
    value: "8_QUARTERS",
  },
];
