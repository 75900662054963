import {
  VStack,
  Button,
  HStack,
  Link,
  Text,
  Stack,
  StackDivider,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Center,
  Tag,
  Tooltip,
} from "@chakra-ui/react";
import AddIcon from "../../assets/icons/add.svg?react";
import MinusIcon from "../../assets/icons/minus.svg?react";
import HtmlViewer from "../../components/common/Editor/HtmlViewer";
import { useEffect, useRef, useState } from "react";
import ReceiptIcon from "../../assets/icons/receipt.svg?react";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";

const Page = ({ page }) => {
  const { accountId } = useAccountId();
  const navigate = useCustomDomainNavigate();

  return (
    <Accordion allowToggle w={"100%"}>
      <AccordionItem border={0}>
        {({ isExpanded }) => (
          <Box
            bg={"white"}
            px={6}
            py={4}
            borderRadius={"10px"}
            border={"1px"}
            borderColor={isExpanded ? "primary" : "gray.200"}
            style={{
              boxShadow: isExpanded && "0px 4px 9px 2px #1A202C14",
              // transform: "rotate(0.0001deg)", // Fixes Safari border radius bug
            }}
          >
            <HStack justifyContent={"space-between"}>
              <HStack>
                {(page.content || page.children?.length > 0) && (
                  <AccordionButton
                    w="22px"
                    h="22px"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={"100%"}
                    border={"1px"}
                    borderColor={"primary"}
                    p={1}
                  >
                    {isExpanded ? (
                      <MinusIcon stroke={"#2F855A"} />
                    ) : (
                      <AddIcon stroke={"#2F855A"} />
                    )}
                  </AccordionButton>
                )}
                <Link
                  fontSize={18}
                  fontWeight={"bold"}
                  onClick={() => {
                    navigate(`/${accountId}/knowledge-base/${page.id}/view`);
                  }}
                  textDecoration={"none"}
                >
                  <Text color={"primary"}>{page.title}</Text>
                </Link>
              </HStack>
              <HStack>
                {!page.isPublished && (
                  <Tooltip
                    label="Draft items are only visible to Admins"
                    bg="white"
                    color="gray.500"
                  >
                    <Tag colorScheme="gray" variant={"outline"}>
                      DRAFT
                    </Tag>
                  </Tooltip>
                )}
                <Box
                  w={"80px"}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  {new Date() - new Date(page.createdAt) < 86400000 &&
                  page.isPublished ? (
                    <NewTag />
                  ) : (
                    <PageTag />
                  )}
                </Box>
              </HStack>
            </HStack>
            <AccordionPanel>
              <Stack
                spacing={4}
                divider={<StackDivider />}
                direction={{ base: "column", md: "row" }}
              >
                <ContentView page={page} isExpanded={isExpanded} />
                {page.children?.length > 0 && (
                  <VStack alignItems={"flex-start"} w={"30%"}>
                    <Text ml={4}>Child Pages</Text>
                    {page.children.map((childPage, index) => (
                      <HStack fontSize={"sm"} key={index}>
                        <Text color={"primary"}>•</Text>
                        <Link
                          onClick={() => {
                            navigate(
                              `/${accountId}/knowledge-base/${childPage.id}/view`
                            );
                          }}
                        >
                          {childPage.title}
                        </Link>
                      </HStack>
                    ))}
                  </VStack>
                )}
              </Stack>
            </AccordionPanel>
          </Box>
        )}
      </AccordionItem>
    </Accordion>
  );
};

function ContentView({ page, isExpanded }) {
  const [showSeeMore, setShowSeeMore] = useState(false);
  const { accountId } = useAccountId();
  const contentRef = useRef(null);
  const navigate = useCustomDomainNavigate();
  const maxHeight = 200;

  useEffect(() => {
    // give a slight delay for the content to render fully
    setTimeout(() => {
      if (contentRef.current.scrollHeight > maxHeight) {
        setShowSeeMore(true);
      } else {
        setShowSeeMore(false);
      }
    }, 50);
  }, [isExpanded]);

  return (
    <Box w="70%" maxH={maxHeight} position="relative" overflow="hidden">
      {/* Gradient Overlay */}
      {showSeeMore && (
        <Box
          position="absolute"
          bottom="0"
          left="0"
          right="0"
          height="150px" // height of the fade effect
          background="linear-gradient(0deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%)"
          pointerEvents="none" // Ensure it doesn't block content interactions
          zIndex="1"
        />
      )}

      {/* Content */}
      <Box position="relative" zIndex="0" ref={contentRef} overflow="hidden">
        {page.content ? (
          <HtmlViewer html={page.content} minHeight={"10px"} key={page.id} />
        ) : (
          <Box p={4}>
            <Text>No content</Text>
          </Box>
        )}
      </Box>

      {/* See More Button */}
      {showSeeMore && (
        <Button
          position="absolute"
          bottom="0px"
          left="50%"
          transform="translateX(-50%)"
          zIndex="2"
          onClick={() => {
            navigate(`/${accountId}/knowledge-base/${page.id}/view`);
          }}
        >
          See More
        </Button>
      )}
    </Box>
  );
}

const NewTag = () => {
  return (
    <Box borderRadius={"6px"} height={"31px"} w={"80px"} bg={"#3181CE2E"}>
      <Center h={"100%"}>
        <HStack spacing={2} alignItems={"center"}>
          <Text fontSize={"sm"} color={"#4285F4"} fontWeight={"700"}>
            New
          </Text>
          <ReceiptIcon stroke={"#4285F4"} />
        </HStack>
      </Center>
    </Box>
  );
};

const PageTag = () => {
  return (
    <Box borderRadius={"6px"} height={"31px"} w={"40px"} bg={"#3181CE2E"}>
      <Center h={"100%"}>
        <HStack spacing={2} alignItems={"center"}>
          <ReceiptIcon stroke={"#4285F4"} />
        </HStack>
      </Center>
    </Box>
  );
};

const KbPages = ({ pages }) => {
  return (
    <VStack spacing={4} alignItems={"flex-start"}>
      {pages.map((page, index) => (
        <Page key={index} page={page} />
      ))}
    </VStack>
  );
};

export default KbPages;
