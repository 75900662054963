import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import api from "../config";

interface CreateReportViewableByVariables {
  accountId: string;
  data: any;
}

interface UpdateReportViewableByVariables {
  accountId: string;
  viewableById: string;
  data: any;
}

interface DeleteReportViewableByVariables {
  accountId: string;
  viewableById: string;
}

export const useCreateReportViewableBy = (
  props?: UseMutationOptions<any, Error, CreateReportViewableByVariables>
): UseMutationResult<any, Error, CreateReportViewableByVariables> =>
  useMutation({
    mutationKey: ["createReportViewableBy"],
    mutationFn: async ({
      accountId,
      data,
    }: CreateReportViewableByVariables) => {
      const response = await api.post(
        `/${accountId}/abcRankingReportsViewableBy`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useUpdateReportViewableBy = (
  props?: UseMutationOptions<any, Error, UpdateReportViewableByVariables>
): UseMutationResult<any, Error, UpdateReportViewableByVariables> =>
  useMutation({
    mutationKey: ["updateReportViewableBy"],
    mutationFn: async ({
      accountId,
      viewableById,
      data,
    }: UpdateReportViewableByVariables) => {
      const response = await api.put(
        `/${accountId}/abcRankingReportsViewableBy/${viewableById}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeleteReportViewableBy = (
  props?: UseMutationOptions<any, Error, DeleteReportViewableByVariables>
): UseMutationResult<any, Error, DeleteReportViewableByVariables> =>
  useMutation({
    mutationKey: ["deleteReportViewableBy"],
    mutationFn: async ({
      accountId,
      viewableById,
    }: DeleteReportViewableByVariables) => {
      const response = await api.delete(
        `/${accountId}/abcRankingReportsViewableBy/${viewableById}`
      );
      return response.data;
    },
    ...props,
  });
