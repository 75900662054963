import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Link from "@tiptap/extension-link";
import FontFamily from "@tiptap/extension-font-family";
import Highlight from "@tiptap/extension-highlight";
import Placeholder from "@tiptap/extension-placeholder";
import { Node } from "@tiptap/core";

import Indent from "./indent";
import ResizableImageExtension from "./ResizableImageExtension";
import SlashCommand from "./SlashCommandsExtension";
import { AIHighlight } from "./AiHighlight";

// TODO this is for checkboxes. Will they work in google docs?
// import TaskItem from "@tiptap/extension-task-item";
// import TaskList from "@tiptap/extension-task-list";

const OneLiner = Node.create({
  name: "oneLiner",
  topNode: true,
  content: "block",
});

export const getExtensions = ({
  placeholder,
  isOneLine,
}: {
  placeholder?: string;
  isOneLine?: boolean;
  useAi?: boolean;
}) => {
  return [
    ...(isOneLine ? [OneLiner] : []),
    StarterKit,
    Underline,
    TextAlign.configure({
      types: ["heading", "paragraph"],
    }),
    Link,
    Indent.configure({
      types: ["listItem", "paragraph"],
      minLevel: 0,
      maxLevel: 8,
    }),
    Color,
    TextStyle,
    Highlight.configure({ multicolor: true }),
    FontFamily.configure({
      types: ["textStyle"],
    }),
    Placeholder.configure({
      placeholder: ({ node }) => {
        if (node.type.name === "heading") {
          return `Heading ${node.attrs.level}`;
        }
        return placeholder || "Write something or type '/' for commands";
      },
      includeChildren: true,
    }),
    ResizableImageExtension,
    SlashCommand,
    AIHighlight,
    //   TaskList,
    //   TaskItem
  ];
};
