import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  useCheckAccountIdExists,
  useCreateAccount,
} from "../../api/accounts/accounts";
import GreenCheckWaveyIcon from "../../assets/icons/green_check_wavey_outline.svg?react";
import TBALogo from "../../assets/tba-frame.png";
import BackgroundImageGallery from "../../components/common/BackgroundImageGallery";

const CreateAccount = () => {
  const form = useForm({ mode: "onChange" });
  const [accountIdExists, setAccountIdExists] = useState(false);

  const createAccountMutation = useCreateAccount({
    onSuccess: (data) => {
      window.location.href = `/${data.id}/setup/subscriptions`;
    },
  });

  const {
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    register,
    watch,
    setValue,
  } = form;

  const companyName = watch("name");
  const companyId = watch("companyId");
  const checkAccountExists = useCheckAccountIdExists(companyId);

  // TODO this doesn't appear to be updating when the query is refetched
  const { isLoading: isLoadingCheckAccountExists, fetchStatus } =
    checkAccountExists;

  // https://github.com/TanStack/query/issues/3975#issuecomment-1244075132
  const isVerifyingAccountId =
    isLoadingCheckAccountExists && fetchStatus !== "idle";

  useEffect(() => {
    if (companyName) {
      const generatedId = companyName
        .trim()
        .replace(/\s+/g, "-") // replace spaces with dashes
        .replace(/[^a-z0-9-]+/gi, "") // remove all non-alphanumeric characters
        .replace(/-+$/, "") // remove trailing dashes
        .replace(/^-+/, "") // remove leading dashes
        .replace(/--+/g, "-") // replace multiple dashes with single dash
        .toLowerCase()
        .substring(0, 32);
      setValue("companyId", generatedId);
      form.trigger("companyId");
    }
  }, [companyName]);

  async function createAccount() {
    try {
      const createAccountResp = await createAccountMutation.mutateAsync(
        form.getValues()
      );
      console.log("createAccountResp", createAccountResp);
    } catch (error) {
      console.error("Error creating account:", error);
    }
  }

  useEffect(() => {
    if (companyId) {
      debouncedCheckAccountExists();
    }
  }, [companyId]);

  const debouncedCheckAccountExists = useCallback(
    debounce(() => {
      checkAccountExists.refetch();
    }, 1000),
    []
  );

  useEffect(() => {
    if (checkAccountExists.data !== undefined) {
      setAccountIdExists(checkAccountExists.data.exists);
    }
  }, [checkAccountExists.data]);

  return (
    <Box bg={"offWhite"} h="100vh">
      <BackgroundImageGallery>
        <Container
          maxW="2xl"
          py={"8"}
          px={{ base: "4", sm: "10" }}
          bg={"white"}
          boxShadow={{ base: "none", sm: "xl" }}
          borderRadius={{ base: "none", sm: "xl" }}
          position="absolute"
        >
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(createAccount)}>
              <Stack spacing="6">
                <Stack spacing="6" align="center">
                  <Image src={TBALogo} w={"328px"}></Image>
                  <Text fontSize="xl" fontWeight={800}>
                    Create Company Account
                  </Text>
                </Stack>
                <Divider />

                <FormControl isInvalid={!!errors.name} isRequired>
                  <FormLabel>Company Name</FormLabel>
                  <InputGroup>
                    <Input
                      placeholder="My Company"
                      {...register(`name`, {
                        required: "Company Name is required",
                        maxLength: { value: 50, message: "Max length 50" },
                      })}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    <>{errors.name && errors.name.message}</>
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.companyId} isRequired>
                  <FormLabel>Company URL</FormLabel>
                  <HStack>
                    <Text mt={2} fontSize="lg">
                      {`${window.location.origin}/`}
                    </Text>
                    <InputGroup>
                      <Input
                        placeholder=""
                        {...register(`companyId`, {
                          required: "Company ID is required",
                          minLength: { value: 4, message: "Minimum length 4" },
                          maxLength: {
                            value: 32,
                            message: "Maximum length 32",
                          },
                          validate: (value) =>
                            value.match(/^[a-z0-9]+(?:-[a-z0-9]+)*$/)
                              ? true
                              : "Must be lowercase letters, numbers, and dashes",
                        })}
                        h={"26px"}
                        mt={"8px"}
                        ml={"-6px"}
                        variant={"flushed"}
                      />
                      <InputRightElement>
                        {isValid &&
                          !accountIdExists &&
                          !isVerifyingAccountId && (
                            <Icon
                              as={GreenCheckWaveyIcon}
                              boxSize={"26px"}
                              mb={5}
                              // bg={"yellow"}
                            />
                          )}
                      </InputRightElement>
                    </InputGroup>
                  </HStack>
                  {errors.companyId ? (
                    <FormErrorMessage>
                      <>{errors.companyId && errors.companyId.message}</>
                    </FormErrorMessage>
                  ) : (
                    <Box h={"24px"} />
                  )}
                </FormControl>
                <Stack spacing="6">
                  <Stack spacing="4">
                    <Button
                      type="submit"
                      isDisabled={
                        !isValid || accountIdExists || isVerifyingAccountId
                      }
                      isLoading={isSubmitting || isVerifyingAccountId}
                      height={"50px"}
                    >
                      Create Company Account
                    </Button>
                    <Box h={"20px"}>
                      {accountIdExists && (
                        <Text color="red.500" fontSize={"sm"}>
                          Company URL already taken.
                        </Text>
                      )}
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          </FormProvider>
        </Container>
      </BackgroundImageGallery>
    </Box>
  );
};

export default CreateAccount;
