import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { ApiKey } from "./types";

interface UseCreateApiKeyProps
  extends UseMutationOptions<
    ApiKey,
    unknown,
    { accountId: string; data: any }
  > {}

export const useCreateApiKey = (props?: UseCreateApiKeyProps) =>
  useMutation<ApiKey, unknown, { accountId: string; data: any }>({
    mutationKey: ["createApiKey"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/integrations/apiKeys`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useGetApiKeys = (accountId: string) => {
  return useQuery<ApiKey[], unknown>({
    queryKey: ["getApiKeys", accountId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/integrations/apiKeys`);
      return response.data;
    },
  });
};

export const useDeleteApiKey = () =>
  useMutation<ApiKey, unknown, { accountId: string; apiKeyId: string }>({
    mutationKey: ["deleteApiKey"],
    mutationFn: async ({ accountId, apiKeyId }) => {
      const response = await api.delete(
        `/${accountId}/integrations/apiKeys/${apiKeyId}`
      );
      return response.data;
    },
  });

interface UseUpdateApiKeyProps
  extends UseMutationOptions<
    ApiKey,
    unknown,
    { accountId: string; apiKeyId: string; data: any }
  > {}

export const useUpdateApiKey = (props?: UseUpdateApiKeyProps) =>
  useMutation<
    ApiKey,
    unknown,
    { accountId: string; apiKeyId: string; data: any }
  >({
    mutationKey: ["updateApiKey"],
    mutationFn: async ({ accountId, apiKeyId, data }) => {
      const response = await api.put(
        `/${accountId}/integrations/apiKeys/${apiKeyId}`,
        data
      );
      return response.data;
    },
    ...props,
  });
