import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useContext, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useUpdateObjective } from "../../../api/okr/objective";
import { SelectField } from "../../../components/fields/SelectField";
import { AccountContext } from "../../../context/AccountContextComponent";
import useAccountId from "../../../hooks/customDomainHooks";
import { useToastMessage } from "../../../hooks/useToastMessage";
import { formatTimeperiodOptions } from "../formatters";

export const ObjectiveTimeperiod = ({ objective, canEditObjective }) => {
  const { accountId } = useAccountId();
  const { accountData } = useContext(AccountContext);
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const updateObjectiveMutation = useUpdateObjective();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      timePeriodId: objective?.objectiveSet?.timePeriodId,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  const submitForm = (data) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateObjective)(data);
    }
  };

  const updateObjective = async (data) => {
    try {
      await updateObjectiveMutation.mutateAsync({
        accountId,
        objectiveId: objective.id,
        data: {
          timePeriodId: data.timePeriodId,
        },
      });
      queryClient.invalidateQueries(["fetchObjectives", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating objective" });
    }
  };

  const timePeriodOptions = useMemo(
    () => formatTimeperiodOptions(accountData?.timePeriods, []),
    [accountData]
  );

  return (
    <FormProvider {...form}>
      <form
        onChange={debouncedSubmit}
        // Prevent form submission on enter key
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        <SelectField
          field={{
            id: "timePeriodId",
            isReadOnly: !canEditObjective,
          }}
          options={timePeriodOptions}
          onSelectionChange={submitForm}
          selectStyles={{
            input: (provided) => ({
              ...provided,
              color: "blue.500",
              fontWeight: 800,
            }),
            control: (provided) => ({
              ...provided,
              color: "blue.500",
              fontWeight: 800,
              textDecoration: canEditObjective ? "underline" : "none",
              cursor: canEditObjective ? "pointer" : "default",
            }),
          }}
          selectProps={{
            variant: "unstyled",
            components: !canEditObjective
              ? {
                  DropdownIndicator: () => null,
                }
              : {},
          }}
        />
      </form>
    </FormProvider>
  );
};
