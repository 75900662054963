import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  linkWithPopup,
  updatePassword,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PasswordRequirements from "../../../components/common/PasswordRequirements";
import useToastMessage from "../../../hooks/useToastMessage";
import AuthLayout from "../../components/AuthLayout";
import { OAuthButtonGroup } from "../../components/OAuthButtonGroup";

const CreatePassword = () => {
  const { showErrorToast, showSuccessToast } = useToastMessage();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const searchParams = new URLSearchParams(useLocation().search);
  const email = searchParams.get("email")?.replace(" ", "+");
  const navigate = useNavigate();
  const googleProvider = new GoogleAuthProvider();
  const microsoftProvider = new OAuthProvider("microsoft.com");

  const auth = getAuth();

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const passwordsMatch = password === confirmPassword;
  const passwordCriteria = {
    minLength: password.length >= 8,
    hasNumber: /\d/.test(password),
    hasUppercase: /[A-Z]/.test(password),
    hasLowercase: /[a-z]/.test(password),
    // eslint-disable-next-line no-useless-escape
    hasSpecialCharacter: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
      password
    ),
  };

  useEffect(() => {
    setIsPasswordValid(
      passwordCriteria.minLength &&
        passwordCriteria.hasNumber &&
        passwordCriteria.hasUppercase &&
        passwordCriteria.hasLowercase &&
        passwordCriteria.hasSpecialCharacter &&
        passwordsMatch
    );
  }, [password, confirmPassword]);

  const linkWithPassword = async (event: any) => {
    event.preventDefault();

    if (!passwordsMatch || !isPasswordValid) {
      showErrorToast({
        message: !passwordsMatch
          ? "The passwords do not match. Please try again."
          : "Password doesn't meet the requirements. Please try again.",
      });
      return;
    }

    try {
      if (!auth.currentUser) {
        showErrorToast({ message: "User not found" });
        return;
      }
      await updatePassword(auth.currentUser, password);
      showSuccessToast("Account created successfully");
      navigate("/");
    } catch (e) {
      console.error(e);
      showErrorToast({ message: "Failed to set password" });
    }
  };

  const linkWithGoogle = async () => {
    try {
      if (!auth.currentUser) {
        showErrorToast({ message: "User not found" });
        return;
      }
      await linkWithPopup(auth.currentUser, googleProvider);
      navigate("/");
    } catch (e: any) {
      if (e.code === "auth/credential-already-in-use") {
        showErrorToast({
          message: "This Google account is already linked with another account",
        });
      } else {
        showErrorToast({ message: e.message });
      }
    }
  };

  const linkWithMicrosoft = async () => {
    try {
      if (!auth.currentUser) {
        showErrorToast({ message: "User not found" });
        return;
      }
      await linkWithPopup(auth.currentUser, microsoftProvider);
      navigate("/");
    } catch (e: any) {
      if (e.code === "auth/credential-already-in-use") {
        showErrorToast({
          message:
            "This Microsoft account is already linked with another account",
        });
      } else {
        showErrorToast({ message: e.message });
      }
    }
  };

  const handleSocialLogin = async (authType: string) => {
    if (authType === "google") {
      await linkWithGoogle();
    } else if (authType === "microsoft") {
      await linkWithMicrosoft();
    }
  };

  return (
    <AuthLayout>
      <Text fontSize={"2xl"} fontWeight={600} pb={4}>
        Create Account
      </Text>
      <Stack spacing="6">
        <OAuthButtonGroup login={handleSocialLogin} />
        <HStack>
          <Divider />
          <Text fontSize="sm" whiteSpace="nowrap" color="muted">
            or
          </Text>
          <Divider />
        </HStack>
        <form onSubmit={linkWithPassword}>
          <Stack spacing={{ base: 4 }}>
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Text>{email}</Text>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                minLength={8}
              />
              <PasswordRequirements
                password={password}
                passwordCriteria={passwordCriteria}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Retype Password</FormLabel>
              <Input
                type="password"
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                mb={3}
              />
            </FormControl>
            <Button type="submit" isDisabled={!isPasswordValid}>
              Create Account
            </Button>
          </Stack>
        </form>
      </Stack>
    </AuthLayout>
  );
};

export default CreatePassword;
