import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import AddDepartmentMembers from "../AddDepartmentMembers";

export const AddDepartmentMemberModal = (props) => {
  const { isOpen, setIsOpen, currentMembers, departmentId } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"4xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Department Members</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowX={"auto"}>
          <AddDepartmentMembers
            onDone={() => setIsOpen(false)}
            currentMembers={currentMembers}
            departmentId={departmentId}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
