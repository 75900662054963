import { Stack, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { TenureSelect } from "../common/TenureSelect";
import { DepartmentSelect } from "../common/DepartmentSelect";
import { RankingSelect } from "../common/RankingSelect";

export const SurveyReportFilters = ({
  tenuresToFilter,
  setTenuresToFilter,
  departmentsToFilter,
  setDepartmentsToFilter,
  ranksToFilter,
  setRanksToFilter,
}) => {
  return (
    <Stack direction={{ base: "column", sm: "row" }} alignItems={"center"}>
      <Text fontWeight={700} whiteSpace={"nowrap"}>
        Filtered by:
      </Text>
      <Wrap>
        <WrapItem>
          <TenureSelect
            selectedTenures={tenuresToFilter}
            setSelectedTenures={setTenuresToFilter}
          />
        </WrapItem>
        <WrapItem>
          <DepartmentSelect
            selectedDepartments={departmentsToFilter}
            setSelectedDepartments={setDepartmentsToFilter}
          />
        </WrapItem>
        <WrapItem>
          <RankingSelect
            selectedRanks={ranksToFilter}
            setSelectedRanks={setRanksToFilter}
          />
        </WrapItem>
      </Wrap>
    </Stack>
  );
};
