import PageShell from "../../layout/PageShell";
import { useContext, useState } from "react";
import InfoBox from "../../components/common/PageInfo/InfoBox";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../hooks/customDomainHooks";
import AllMyOneOnOnes from "./AllMyOneOnOnes";
import ViewMyTeam from "./ViewMyTeam";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import useTabNavigation from "../../hooks/useTabNavigation";
import { AccountContext } from "../../context/AccountContextComponent";
import OneOnOneTemplates from "./Templates/OneOnOneTemplates";

const OneOnOnePage = () => {
  const { accountId } = useAccountId();
  const { currentProfile } = useContext(AccountContext);
  const [pageInfoEditMode, setPageInfoEditMode] = useState(false);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "1-on-1s" },
  ]);

  const allTabs = [
    {
      name: "1-on-1s",
      content: AllMyOneOnOnes,
    },
  ];

  // Only Managers can view the "My Team" tab
  if (currentProfile?._count?.reports > 0) {
    allTabs.unshift({
      name: "My Team",
      content: ViewMyTeam,
    });
    allTabs.push({
      name: "Templates",
      content: OneOnOneTemplates,
    });
  }

  const { tabIndex, handleTabsChange } = useTabNavigation(allTabs, "OneOnOne");

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      menuItems={[
        {
          type: "CREATE_PAGE_INFO",
          pageType: "ONE_ON_ONE",
        },
      ]}
      setPageInfoEditMode={setPageInfoEditMode}
    >
      <InfoBox
        pageInfoType={"ONE_ON_ONE"}
        setPageInfoEditMode={setPageInfoEditMode}
        pageInfoEditMode={pageInfoEditMode}
      />
      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          {allTabs.map((tab) => (
            <Tab key={tab.name}>{tab.name}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {allTabs.map((tab, idx) => (
            <TabPanel key={tab.name} pl={0}>
              {tabIndex === idx && <tab.content />}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </PageShell>
  );
};

export default OneOnOnePage;
