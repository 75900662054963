import {
  Box,
  Center,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiArchive, BiDotsVerticalRounded } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { useUpdateSurvey } from "../../../api/survey/survey";
import { InputField } from "../../../components/fields/InputField";
import { SwitchField } from "../../../components/fields/SwitchField";
import { DeleteSomethingModal } from "../../../components/modals/DeleteSomethingModal";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import { EditSurveyInvitesModal } from "./EditInvitees/EditSurveyInvitesModal";
import SurveyQuestions from "./SurveyQuestions";

const SurveyBuilder = ({ survey, setIsLoadingForm }) => {
  const navigate = useCustomDomainNavigate();
  const { surveyId } = useParams();
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const updateSurveyMutation = useUpdateSurvey();
  const [isDeleteSurveyModalOpen, setIsDeleteSurveyModalOpen] = useState(false);
  const [showInvitesModal, setShowInvitesModal] = useState(false);
  const form = useForm({
    mode: "onChange",
    defaultValues: survey,
  });
  const {
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
  } = form;

  const updateSurvey = async (data) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { invitees, ...rest } = data;
      await updateSurveyMutation.mutateAsync({
        accountId,
        surveyId,
        data: rest,
      });
      queryClient.invalidateQueries(["fetchSurvey", surveyId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating survey" });
    }
  };

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  const submitForm = (event) => {
    if (Object.keys(errors).length === 0) {
      console.log(event);
      handleSubmit(updateSurvey)(event);
    } else {
      // TODO show toast message if errors when trying to submit
      console.log("errors", errors);
    }
  };

  useEffect(() => {
    setIsLoadingForm(updateSurveyMutation.isLoading);
  }, [updateSurveyMutation.isLoading]);

  // When the questionsOrder changes from the fetched survey, update the form
  useEffect(() => {
    reset(survey);
  }, [survey.questionsOrder]);

  const archiveSurvey = async () => {
    await updateSurvey({ isArchived: true });
    navigate(`/${accountId}/surveys`);
  };

  const unarchiveSurvey = async () => {
    await updateSurvey({ isArchived: false });
    queryClient.invalidateQueries(["fetchSurvey", surveyId]);
  };

  return (
    <>
      <DeleteSomethingModal
        isOpen={isDeleteSurveyModalOpen}
        setIsOpen={setIsDeleteSurveyModalOpen}
        headerText={"Archive Survey"}
        bodyText={`Are you sure you want to archive this survey?`}
        deleteFunction={archiveSurvey}
        isDeleting={updateSurveyMutation.isLoading}
      />
      <EditSurveyInvitesModal
        isOpen={showInvitesModal}
        setIsOpen={setShowInvitesModal}
        currentInvited={survey.invitees}
      />
      <VStack spacing={4} w={"100%"}>
        <Box
          bg={"white"}
          borderRadius={"md"}
          border={"1px"}
          borderColor={"gray.200"}
          p={4}
          mb={4}
          w={"100%"}
        >
          <HStack w={"100%"} justifyContent={"space-between"}>
            <Box w={"100%"}>
              <FormProvider {...form}>
                <form
                  onChange={() => {
                    const formValues = form.getValues();
                    debouncedSubmit(formValues);
                  }}
                  // Prevent form submission on enter key
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                >
                  <VStack spacing={4} w={"100%"}>
                    <InputField
                      field={{
                        id: "title",
                        placeholder: "Title",
                        label: "Title",
                        validation: {
                          required: {
                            value: true,
                            message: "Title is required",
                          },
                        },
                      }}
                      inputStyle={{ size: "lg" }}
                    />
                    <Stack
                      spacing={8}
                      direction={{
                        base: "column",
                        md: "row",
                      }}
                      w={"100%"}
                    >
                      <VStack alignItems={"flex-start"} w={350}>
                        <FormLabel>Participants</FormLabel>
                        <VStack alignItems={"flex-start"}>
                          <HStack>
                            <Switch
                              defaultChecked={survey.isEveryoneInvited}
                              onChange={(e) => {
                                setValue("isEveryoneInvited", e.target.checked);
                              }}
                            />
                            <Text
                              color={!survey.isEveryoneInvited && "gray.500"}
                              fontSize={"sm"}
                            >
                              Everyone
                            </Text>
                          </HStack>
                          {!survey.isEveryoneInvited && (
                            <HStack>
                              <Link
                                fontSize={"sm"}
                                onClick={() => setShowInvitesModal(true)}
                                fontWeight={"semibold"}
                              >
                                Edit
                              </Link>
                              <Text fontSize={"sm"}>
                                {survey._count.invitees === 0 &&
                                  "0 participants"}
                                {survey._count.invitees === 1 &&
                                  "1 participant"}
                                {survey._count.invitees > 1 &&
                                  `${survey._count.invitees} participants`}
                              </Text>
                            </HStack>
                          )}
                        </VStack>
                      </VStack>
                      <SwitchField
                        field={{
                          id: "isAnonymous",
                          label: "Survey will be anonymous",
                        }}
                      />
                    </Stack>
                    <InputField
                      field={{
                        id: "overview",
                        placeholder:
                          "Provide a description of the purpose of the survey",
                        label: "Overview",
                      }}
                      multiLine={true}
                    />
                  </VStack>
                </form>
              </FormProvider>
            </Box>
            <Box alignSelf={"flex-start"}>
              <ThreeDotsMenu
                isDeleteModalOpen={isDeleteSurveyModalOpen}
                setIsDeleteModalOpen={setIsDeleteSurveyModalOpen}
                isArchived={survey.isArchived}
                unarchiveSurvey={unarchiveSurvey}
              />
            </Box>
          </HStack>
        </Box>

        <Text alignSelf={"flex-start"} fontSize={"lg"} fontWeight={"bold"}>
          Questions
        </Text>
        <SurveyQuestions
          updateSurvey={updateSurvey}
          questions={survey.questions}
          questionTags={survey.account.questionTags}
        />
      </VStack>
    </>
  );
};

const ThreeDotsMenu = ({
  setIsDeleteModalOpen,
  isArchived,
  unarchiveSurvey,
}) => {
  return (
    <Box>
      <Menu>
        <MenuButton
          as={IconButton}
          variant={"outline"}
          icon={<BiDotsVerticalRounded size={22} />}
        ></MenuButton>
        <MenuList>
          {!isArchived && (
            <MenuItem
              _hover={{ bg: "red.100" }}
              color={"red.500"}
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
              icon={
                <Center>
                  <Icon as={BiArchive} fontSize={"xl"} />
                </Center>
              }
            >
              Archive Survey
            </MenuItem>
          )}
          {isArchived && (
            <MenuItem
              _hover={{ bg: "red.100" }}
              onClick={unarchiveSurvey}
              icon={
                <Center>
                  <Icon as={BiArchive} fontSize={"xl"} />
                </Center>
              }
              color={"red.500"}
            >
              Unarchive Survey
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default SurveyBuilder;
