import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { SurveyRun } from "./types";

export const useFetchSurveyRuns = (accountId: string, surveyId: string) => {
  return useQuery<SurveyRun[]>({
    queryKey: ["fetchSurveyRuns", accountId, surveyId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/surveyRun?surveyId=${surveyId}`
      );
      return response.data;
    },
  });
};

export const useFetchAllSurveyRuns = (accountId: string) => {
  return useQuery<SurveyRun[]>({
    queryKey: ["fetchAllSurveyRuns", accountId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/surveyRun/all`);
      return response.data;
    },
  });
};

export const useFetchMySurveyRuns = (accountId: string) => {
  return useQuery<SurveyRun[]>({
    queryKey: ["fetchMySurveyRuns", accountId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/surveyRun/my-surveys`);
      return response.data;
    },
  });
};

export const useCreateSurveyRun = (
  props?: UseMutationOptions<
    any,
    unknown,
    { accountId: string; data: any },
    unknown
  >
) =>
  useMutation<any, unknown, { accountId: string; data: any }>({
    mutationKey: ["createSurveyRun"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/surveyRun`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateSurveyRun = (
  props?: UseMutationOptions<
    any,
    unknown,
    { accountId: string; surveyRunId: string; data: any },
    unknown
  >
) =>
  useMutation({
    mutationKey: ["updateSurveyRun"],
    mutationFn: async ({ accountId, surveyRunId, data }) => {
      const response = await api.put(
        `/${accountId}/surveyRun/${surveyRunId}`,
        data
      );
      return response.data;
    },
    ...props,
  });
