import { FormHelperText } from "@chakra-ui/react";

const PasswordRequirements = ({
  password,
  passwordCriteria,
}: {
  password: string;
  passwordCriteria: {
    minLength: boolean;
    hasNumber: boolean;
    hasUppercase: boolean;
    hasLowercase: boolean;
    hasSpecialCharacter: boolean;
  };
}) => {
  const PasswordHint = ({
    isValid,
    children,
  }: {
    isValid: boolean;
    children: any;
  }) => {
    let textColor = isValid ? "#33D182" : "#f24b35";
    textColor = password.length === 0 ? "textPrimary" : textColor;
    return (
      <span style={{ color: textColor, fontWeight: 600 }}>{children}</span>
    );
  };

  return (
    <FormHelperText textAlign={"center"}>
      Password must be {` `}
      <PasswordHint isValid={passwordCriteria.minLength}>
        at least 8 characters long
      </PasswordHint>
      ,
      <PasswordHint isValid={passwordCriteria.hasNumber}>
        {` `}with at least one number
      </PasswordHint>
      ,
      <PasswordHint isValid={passwordCriteria.hasUppercase}>
        {` `}one uppercase letter
      </PasswordHint>
      ,
      <PasswordHint isValid={passwordCriteria.hasLowercase}>
        {` `}one lowercase letter
      </PasswordHint>
      , and
      <PasswordHint isValid={passwordCriteria.hasSpecialCharacter}>
        {` `}one special character
      </PasswordHint>
      .
    </FormHelperText>
  );
};

export default PasswordRequirements;
