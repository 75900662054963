import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import ArchiveEmployee from "../ArchiveEmployee";

export const ArchiveEmployeeModal = (props) => {
  const { isOpen, setIsOpen, member } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"2xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Archive {member?.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ArchiveEmployee
            onSuccess={() => {
              setIsOpen(false);
            }}
            member={member}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
