import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import api from "../config";
import { AdminWidget } from "./types";

interface CreateAdminWidgetVariables {
  accountId: string;
  data: any;
}

interface DeleteAdminWidgetVariables {
  accountId: string;
  adminWidgetId: string;
}

interface UpdateAdminWidgetVariables {
  accountId: string;
  adminWidgetId: string;
  data: any;
}

interface CreateAdminWidgetResponse extends AdminWidget {
  createdAt: string;
  updatedAt: string;
}

interface UpdateAdminWidgetResponse extends AdminWidget {
  createdAt: string;
  updatedAt: string;
}

interface DeleteAdminWidgetResponse {
  success: boolean;
}

export const useCreateAdminWidget = (
  props: UseMutationOptions<
    CreateAdminWidgetResponse,
    unknown,
    CreateAdminWidgetVariables
  >
) =>
  useMutation<CreateAdminWidgetResponse, unknown, CreateAdminWidgetVariables>({
    mutationKey: ["createAdminWidget"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/adminWidget`, data);
      return response.data;
    },
    ...props,
  });

export const useDeleteAdminWidget = (
  props: UseMutationOptions<
    DeleteAdminWidgetResponse,
    unknown,
    DeleteAdminWidgetVariables
  >
) =>
  useMutation<DeleteAdminWidgetResponse, unknown, DeleteAdminWidgetVariables>({
    mutationKey: ["deleteAdminWidget"],
    mutationFn: async ({ accountId, adminWidgetId }) => {
      const response = await api.delete(
        `/${accountId}/adminWidget/${adminWidgetId}`
      );
      return response.data;
    },
    ...props,
  });

export const useUpdateAdminWidget = (
  props: UseMutationOptions<
    UpdateAdminWidgetResponse,
    unknown,
    UpdateAdminWidgetVariables
  >
) =>
  useMutation<UpdateAdminWidgetResponse, unknown, UpdateAdminWidgetVariables>({
    mutationKey: ["updateAdminWidget"],
    mutationFn: async ({ accountId, adminWidgetId, data }) => {
      const response = await api.put(
        `/${accountId}/adminWidget/${adminWidgetId}`,
        data
      );
      return response.data;
    },
    ...props,
  });
