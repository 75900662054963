import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { PhoneAuthProvider, PhoneMultiFactorGenerator } from "firebase/auth";
import { useState } from "react";
import useToastMessage from "../../hooks/useToastMessage";

export const VerifySMSCodeModal = ({
  isOpen,
  setIsOpen,
  verificationId,
  resolver,
  onSuccess,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  verificationId: string;
  resolver: any;
  onSuccess: () => void;
}) => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const { showErrorToast } = useToastMessage();

  const submitVerificationCode = async (e: any) => {
    e.preventDefault();
    setIsAuthenticating(true);
    const verificationCode = e.target.verificationCode.value;

    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    resolver
      .resolveSignIn(multiFactorAssertion)
      .then(function () {
        // User successfully signed in with the second factor phone number.
        setIsOpen(false);
        setIsAuthenticating(false);
        onSuccess();
      })
      .catch(function (error: any) {
        setIsAuthenticating(false);
        showErrorToast({
          message: "Wrong code. Please try again.",
        });
        console.log("error", error);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{"Check your phone"}</ModalHeader>
        <ModalBody>
          <Stack spacing={{ base: 2, md: 5 }}>
            <form onSubmit={submitVerificationCode}>
              <VStack>
                <FormControl isRequired>
                  <FormLabel htmlFor="verificationCode">
                    Verification Code
                  </FormLabel>
                  <Input id="verificationCode" type="text" />
                </FormControl>
                <Button
                  variant="solid"
                  isLoading={isAuthenticating}
                  type="submit"
                >
                  Verify Code
                </Button>
              </VStack>
            </form>
            <div id="recaptcha-container"></div>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
