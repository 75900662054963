import { Avatar, HStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useContext, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useUpdateObjective } from "../../../api/okr/objective";
import { SelectField } from "../../../components/fields/SelectField";
import { AccountContext } from "../../../context/AccountContextComponent";
import useAccountId from "../../../hooks/customDomainHooks";
import { useToastMessage } from "../../../hooks/useToastMessage";
import { formatOwnerOptions } from "../formatters";

export const ObjectiveOwner = ({ objective, canEditObjective }) => {
  const { accountId } = useAccountId();
  const { accountData, currentProfile } = useContext(AccountContext);
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const updateObjectiveMutation = useUpdateObjective();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      dynamicOwnerId:
        objective?.objectiveSet?.ownerId ||
        objective?.objectiveSet?.departmentId ||
        objective?.objectiveSet?.teamId ||
        "COMPANY",
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  const submitForm = (data) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateObjective)(data);
    }
  };

  // dynamicOwnerId could be a profileId, departmentId, teamId, or undefined
  // depending on the type, we need to set the ownerType
  const getOwnerType = (dynamicOwnerId) => {
    if (
      accountData?.profiles?.find((profile) => profile.id === dynamicOwnerId)
    ) {
      return "PROFILE";
    } else if (
      accountData?.departments?.find(
        (department) => department.id === dynamicOwnerId
      )
    ) {
      return "DEPARTMENT";
    } else if (accountData?.teams?.find((team) => team.id === dynamicOwnerId)) {
      return "TEAM";
    } else if (dynamicOwnerId === "COMPANY") {
      return "COMPANY";
    } else {
      throw new Error("Owner not found");
    }
  };

  const updateObjective = async (data) => {
    try {
      const ownerType = getOwnerType(data.dynamicOwnerId);

      await updateObjectiveMutation.mutateAsync({
        accountId,
        objectiveId: objective.id,
        data: {
          title: data.title,
          ownerType: ownerType,
          ownerId: ownerType === "PROFILE" ? data.dynamicOwnerId : undefined,
          teamId: ownerType === "TEAM" ? data.dynamicOwnerId : undefined,
          departmentId:
            ownerType === "DEPARTMENT" ? data.dynamicOwnerId : undefined,
        },
      });
      queryClient.invalidateQueries(["fetchObjectives", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating objective" });
    }
  };

  const hideCompanyOption = useMemo(() => {
    // hide company option if the user is not an admin or owner or partner
    // and they can edit the objective.
    return (
      !["ADMIN", "OWNER", "PARTNER"].includes(currentProfile?.role || "") &&
      canEditObjective
    );
  }, [currentProfile]);

  const ownerOptions = useMemo(
    () => formatOwnerOptions(accountData, [], true, hideCompanyOption),
    [accountData]
  );

  return (
    <HStack w={"100%"}>
      {objective?.objectiveSet?.ownerType === "PROFILE" && (
        <Avatar
          key={objective?.objectiveSet?.owner?.user?.photoUrl}
          size="sm"
          src={objective?.objectiveSet?.owner?.user?.photoUrl}
          name={objective?.objectiveSet?.owner?.user?.name}
        />
      )}
      <FormProvider {...form}>
        <form
          onChange={debouncedSubmit}
          // Prevent form submission on enter key
          onKeyDown={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
        >
          <SelectField
            field={{
              id: "dynamicOwnerId",
              isReadOnly: !canEditObjective,
            }}
            options={ownerOptions}
            onSelectionChange={submitForm}
            selectStyles={{
              input: (provided) => ({
                ...provided,
                color: "blue.500",
                fontWeight: 800,
              }),
              control: (provided) => ({
                ...provided,
                color: "blue.500",
                fontWeight: 800,
                textDecoration: canEditObjective ? "underline" : "none",
                cursor: canEditObjective ? "pointer" : "default",
              }),
            }}
            selectProps={{
              variant: "unstyled",
              components: !canEditObjective
                ? {
                    DropdownIndicator: () => null,
                  }
                : {},
            }}
          />
        </form>
      </FormProvider>
    </HStack>
  );
};
