import { Box, Center, FormControl, Input, VStack } from "@chakra-ui/react";
import { useRef } from "react";
import useToastMessage from "../../../hooks/useToastMessage";

const UploadArea = ({
  children,
  allowedFileTypes,
  onFileSelect,
  style,
}: {
  children: React.ReactNode;
  allowedFileTypes: string[];
  onFileSelect: (file: File) => void;
  style?: any;
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { showErrorToast } = useToastMessage();

  const handleFileChange = (file: File) => {
    console.log(file);
    if (allowedFileTypes.includes(file.type)) {
      onFileSelect(file);
    } else {
      showErrorToast({
        message: "Invalid file type",
      });
    }
  };

  return (
    <FormControl>
      <Box
        border="1px"
        borderStyle={"dashed"}
        borderColor="gray.500"
        borderRadius="md"
        p={4}
        textAlign="center"
        cursor="pointer"
        _hover={{ bg: "gray.50" }}
        onClick={() => fileInputRef.current?.click()}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault();
          if (e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0];
            handleFileChange(file);
          }
        }}
        {...style}
      >
        <Center h={"100%"}>
          <Input
            ref={fileInputRef}
            type="file"
            accept={allowedFileTypes.map((type) => `.${type}`).join(",")}
            display="none"
            onChange={(event) => {
              event.stopPropagation();
              const file = event?.target?.files
                ? event?.target?.files[0]
                : null;
              if (!file) return;
              handleFileChange(file);
              event.target.value = "";
            }}
          />
          <VStack>{children}</VStack>
        </Center>
      </Box>
    </FormControl>
  );
};

export default UploadArea;
