import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import CreateOneOnOneSchedule from "../Forms/CreateOneOnOneSchedule";

export const CreateOneOnOneScheduleModal = (props) => {
  const { isOpen, setIsOpen, profileId } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"2xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Schedule 1-on-1</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CreateOneOnOneSchedule
            onSuccess={() => {
              setIsOpen(false);
            }}
            profileId={profileId}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
