import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContext, useMemo, useState } from "react";
import { FormattedDate } from "react-intl";
import { useFetchAbcRankingReports } from "../../api/abcRankings/abcRankingReport";
import { AvatarWithName } from "../../components/common/AvatarWithName";
import LoadingBox from "../../components/common/LoadingBox";
import CustomTable from "../../components/table/CustomTableV2";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import SampleDataWidget from "../CultureDashboard/widgets/view/SampleDataWidget";
import { aRankStyle, bRankStyle, cRankStyle } from "./style";

const AbcRankingReports = () => {
  const { accountId } = useAccountId();
  const navigate = useCustomDomainNavigate();
  const {
    data: rankingReports,
    isLoading: isLoadingAbcRankingReports,
    isError,
    error,
  } = useFetchAbcRankingReports(accountId || "");
  const { getModuleConfig, currentProfile } = useContext(AccountContext);

  const abcRankingModuleConfig = getModuleConfig("ABC_RANKING");

  const rankingReportsColumns = useMemo(
    () => [
      {
        Header: "Manager",
        accessor: "manager.name",
        Cell: ({ row }) => {
          return <AvatarWithName profile={row.original.manager} />;
        },
      },
      {
        Header: "Department",
        accessor: "department.name",
      },
      {
        Header: "Due",
        accessor: "rankingPeriod.dueDate",
        id: "rankingPeriod.dueDate",
        // Use our custom `sortTypes` method for this column
        // sortType: "custom",
        Cell: ({ row }) => {
          const date = row.original.rankingPeriod.dueDate;
          return (
            <FormattedDate
              value={date}
              year="numeric"
              month="short"
              day="2-digit"
            />
          );
        },
      },
      {
        Header: "Period",
        accessor: "rankingPeriod.period.label",
        id: "timePeriod",
        Cell: ({ row }) => {
          return <Text>{row.original.rankingPeriod?.period?.label}</Text>;
        },
      },
      {
        Header: "Submitted",
        accessor: "submittedOn",
        Cell: ({ row }) => {
          const date = row.original.submittedOn;
          if (!date) return <Text>--</Text>;
          return (
            <FormattedDate
              value={date}
              year="numeric"
              month="short"
              day="2-digit"
            />
          );
        },
      },
      {
        Header: abcRankingModuleConfig.firstRank || "A",
        headerStyle: { justifyContent: "center" },
        accessor: (row) => {
          const aRankings = row.employeeRankings.filter(
            (er) => er.ranking === "A"
          );
          return aRankings.length;
        },
        Cell: ({ row }) => {
          const aRankings = row.original.employeeRankings.filter(
            (er) => er.ranking === "A"
          );
          return (
            <Tag w={"60px"} justifyContent={"center"} {...aRankStyle}>
              {aRankings.length}
            </Tag>
          );
        },
      },
      {
        Header: abcRankingModuleConfig.secondRank || "B",
        headerStyle: { justifyContent: "center" },
        accessor: (row) => {
          const bRankings = row.employeeRankings.filter(
            (er) => er.ranking === "B"
          );
          return bRankings.length;
        },
        Cell: ({ row }) => {
          const bRankings = row.original.employeeRankings.filter(
            (er) => er.ranking === "B"
          );
          return (
            <Tag w={"60px"} justifyContent={"center"} {...bRankStyle}>
              {bRankings.length}
            </Tag>
          );
        },
      },
      {
        Header: abcRankingModuleConfig.thirdRank || "C",
        headerStyle: { justifyContent: "center" },
        accessor: (row) => {
          const cRankings = row.employeeRankings.filter(
            (er) => er.ranking === "C"
          );
          return cRankings.length;
        },
        Cell: ({ row }) => {
          const cRankings = row.original.employeeRankings.filter(
            (er) => er.ranking === "C"
          );
          return (
            <Tag w={"60px"} justifyContent={"center"} {...cRankStyle}>
              {cRankings.length}
            </Tag>
          );
        },
      },
    ],
    [rankingReports]
  );

  const onRowClick = (row) => {
    navigate(`/${accountId}/ta/${row.original.id}`);
  };

  if (!rankingReports || rankingReports.length === 0) {
    return (
      <Box
        bg={"white"}
        borderRadius={"md"}
        border={"1px"}
        borderColor={"gray.200"}
        p={0}
        overflow={"auto"}
        maxW={"1200px"}
      >
        <SampleDataWidget
          imageUrl={"team-alignment-list.png"}
          width={"100%"}
          height={1009}
          text={
            "Your Team Alignment data will show here once an initial report is submitted."
          }
          textPositionHeight="20%"
        />
      </Box>
    );
  }

  return (
    <>
      <Box
        bg={"white"}
        borderRadius={"md"}
        border={"1px"}
        borderColor={"gray.200"}
        p={4}
        overflow={"auto"}
        maxW={"1200px"}
      >
        {/* <Text>Team Alignment Submissions</Text> */}
        {isLoadingAbcRankingReports && <LoadingBox height={"200px"} />}
        {isError && <Text color={"red.500"}>Error: {error.message}</Text>}
        {rankingReports && (
          <VStack
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            spacing={0}
            w={"1150px"}
          >
            <MyReports
              rankingReportsColumns={rankingReportsColumns}
              rankingReports={rankingReports}
              onRowClick={onRowClick}
            />
            <Box pt={4} />
            <Text fontSize={"lg"} fontWeight={700}>
              All Reports
            </Text>
            <CustomTable
              columns={rankingReportsColumns}
              data={rankingReports.filter(
                (col) => col.managerId !== currentProfile.id
              )}
              doUsePagination={true}
              onRowClick={onRowClick}
              initialState={{
                sortBy: [
                  {
                    id: "rankingPeriod.dueDate",
                    desc: true,
                  },
                ],
              }}
              boxStyle={{
                w: "1150px",
              }}
              tableId={"allReportsTable"}
            />
          </VStack>
        )}
      </Box>
    </>
  );
};

const MyReports = ({ rankingReportsColumns, rankingReports, onRowClick }) => {
  const { currentProfile } = useContext(AccountContext);
  const [accordionIndex, setAccordionIndex] = useState([-1]);

  const myRankingReports = rankingReports.filter(
    (col) => col.managerId === currentProfile.id
  );

  let myRankingReportsToShowInCollapsedView = myRankingReports;

  const myOpenRankingReports = myRankingReports
    .filter((col) => col.rankingPeriod.isOpen === true)
    .filter((col) => col.submittedOn === null);

  if (myOpenRankingReports.length > 0) {
    myRankingReportsToShowInCollapsedView = myOpenRankingReports;
  }
  return (
    <>
      <Text fontSize={"lg"} fontWeight={700} pb={4}>
        My Reports
      </Text>
      <Accordion
        allowToggle
        index={accordionIndex}
        key={accordionIndex}
        p={0}
        w={"1150px"}
      >
        <AccordionItem border={"0px"}>
          {({ isExpanded }) => {
            return (
              <>
                <AccordionButton h={0} p={0} />
                <>
                  {!isExpanded && (
                    <Box>
                      <CustomTable
                        columns={rankingReportsColumns}
                        data={myRankingReportsToShowInCollapsedView
                          .sort((a, b) => {
                            return (
                              new Date(b.rankingPeriod.dueDate) -
                              new Date(a.rankingPeriod.dueDate)
                            );
                          })
                          .slice(0, 2)}
                        showSearchBar={false}
                        doUsePagination={false}
                        onRowClick={onRowClick}
                        initialState={{
                          sortBy: [
                            {
                              id: "rankingPeriod.dueDate",
                              desc: true,
                            },
                          ],
                        }}
                      />
                    </Box>
                  )}
                  <AccordionPanel>
                    <CustomTable
                      columns={rankingReportsColumns}
                      data={myRankingReports}
                      showSearchBar={false}
                      onRowClick={onRowClick}
                      doUsePagination={true}
                      initialState={{
                        sortBy: [
                          {
                            id: "rankingPeriod.dueDate",
                            desc: true,
                          },
                        ],
                      }}
                    />
                  </AccordionPanel>
                  {myRankingReports && myRankingReports.length > 1 ? (
                    <Box
                      flex="1"
                      textAlign="center"
                      _hover={{ bg: "gray.100" }}
                      p={2}
                      cursor={"pointer"}
                      onClick={() => {
                        setAccordionIndex(isExpanded ? [-1] : [0]);
                      }}
                    >
                      <HStack
                        w={"100%"}
                        justifyContent={"center"}
                        cursor={"pointer"}
                      >
                        <Text>{isExpanded ? "Hide" : "View more"}</Text>
                        <AccordionIcon />
                      </HStack>
                    </Box>
                  ) : (
                    <Box p={4} />
                  )}
                </>
              </>
            );
          }}
        </AccordionItem>
      </Accordion>
    </>
  );
};
export default AbcRankingReports;
