/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  useState,
  useEffect,
  useCallback,
  ReactNode,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import { Editor, Range, Extension } from "@tiptap/core";
import Suggestion from "@tiptap/suggestion";
import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";
import {
  Heading1,
  Heading2,
  Heading3,
  List,
  ListOrdered,
  TextQuote,
  Code,
} from "lucide-react";
import { getAfterText, getPrevText } from "../lib/editor";
import { Box, Button, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import Magic from "../ui/MagicIcon";
import { BiAlignLeft } from "react-icons/bi";
import {
  AiContextTask,
  TipTapAiContext,
} from "../../../../context/TipTapAiContextComponent";
import { AiAnimation } from "../ui/AiAnimation";
import useAICompletion from "../useAICompletion";
import { AiTask } from "../../../../api/ai/ai";

interface CommandItemProps {
  title: string;
  description: string;
  icon: ReactNode;
}

interface CommandProps {
  editor: Editor;
  range: Range;
}

const Command = Extension.create({
  name: "slash-command",
  addOptions() {
    return {
      useAi: true,
      suggestion: {
        char: "/",
        command: ({
          editor,
          range,
          props,
        }: {
          editor: Editor;
          range: Range;
          props: any;
        }) => {
          props.command({ editor, range });
        },
      },
    };
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

const getSuggestionItems = ({
  query,
  useAi,
}: {
  query: string;
  useAi: boolean;
}) => {
  return [
    ...(useAi
      ? [
          {
            title: "Write it for me",
            description: "Get started with AI",
            searchTerms: ["gpt", "ai"],
            icon: <Icon as={Magic} fontSize={"26px"} color={"primary"} />,
          },
          {
            title: "Ask AI",
            description: "Ask AI to edit or generate.",
            searchTerms: ["gpt", "ai", "ask"],
            icon: <Icon as={Magic} fontSize={"26px"} color={"primary"} />,
          },
        ]
      : []),
    {
      title: "Text",
      description: "Just start typing with plain text.",
      searchTerms: ["p", "paragraph"],
      icon: <BiAlignLeft size={18} />,
      command: ({ editor, range }: CommandProps) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .toggleNode("paragraph", "paragraph")
          .run();
      },
    },
    // {
    //   title: "To-do List",
    //   description: "Track tasks with a to-do list.",
    //   searchTerms: ["todo", "task", "list", "check", "checkbox"],
    //   icon: <CheckSquare size={18} />,
    //   command: ({ editor, range }: CommandProps) => {
    //     editor.chain().focus().deleteRange(range).toggleTaskList().run();
    //   },
    // },
    {
      title: "Heading 1",
      description: "Big section heading.",
      searchTerms: ["title", "big", "large"],
      icon: <Heading1 size={18} />,
      command: ({ editor, range }: CommandProps) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode("heading", { level: 1 })
          .run();
      },
    },
    {
      title: "Heading 2",
      description: "Medium section heading.",
      searchTerms: ["subtitle", "medium"],
      icon: <Heading2 size={18} />,
      command: ({ editor, range }: CommandProps) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode("heading", { level: 2 })
          .run();
      },
    },
    {
      title: "Heading 3",
      description: "Small section heading.",
      searchTerms: ["subtitle", "small"],
      icon: <Heading3 size={18} />,
      command: ({ editor, range }: CommandProps) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode("heading", { level: 3 })
          .run();
      },
    },
    {
      title: "Bullet List",
      description: "Create a simple bullet list.",
      searchTerms: ["unordered", "point"],
      icon: <List size={18} />,
      command: ({ editor, range }: CommandProps) => {
        editor.chain().focus().deleteRange(range).toggleBulletList().run();
      },
    },
    {
      title: "Numbered List",
      description: "Create a list with numbering.",
      searchTerms: ["ordered"],
      icon: <ListOrdered size={18} />,
      command: ({ editor, range }: CommandProps) => {
        editor.chain().focus().deleteRange(range).toggleOrderedList().run();
      },
    },
    {
      title: "Quote",
      description: "Capture a quote.",
      searchTerms: ["blockquote"],
      icon: <TextQuote size={18} />,
      command: ({ editor, range }: CommandProps) =>
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .toggleNode("paragraph", "paragraph")
          .toggleBlockquote()
          .run(),
    },
    {
      title: "Code",
      description: "Capture a code snippet.",
      searchTerms: ["codeblock"],
      icon: <Code size={18} />,
      command: ({ editor, range }: CommandProps) =>
        editor.chain().focus().deleteRange(range).toggleCodeBlock().run(),
    },
    // {
    //   title: "Image",
    //   description: "Upload an image from your computer.",
    //   searchTerms: ["photo", "picture", "media"],
    //   icon: <ImageIcon size={18} />,
    //   command: ({ editor, range }: CommandProps) => {
    //     editor.chain().focus().deleteRange(range).run();
    //     // upload image
    //     const input = document.createElement("input");
    //     input.type = "file";
    //     input.accept = "image/*";
    //     input.onchange = async () => {
    //       if (input.files?.length) {
    //         const file = input.files[0];
    //         const pos = editor.view.state.selection.from;
    //         startImageUpload(file, editor.view, pos);
    //       }
    //     };
    //     input.click();
    //   },
    // },
  ].filter((item) => {
    if (typeof query === "string" && query.length > 0) {
      const search = query.toLowerCase();
      return (
        item.title.toLowerCase().includes(search) ||
        item.description.toLowerCase().includes(search) ||
        (item.searchTerms &&
          item.searchTerms.some((term: string) => term.includes(search)))
      );
    }
    return true;
  });
};

const updateScrollView = (container: HTMLElement, item: HTMLElement) => {
  const containerHeight = container.offsetHeight;
  const itemHeight = item ? item.offsetHeight : 0;

  const top = item.offsetTop;
  const bottom = top + itemHeight;

  if (top < container.scrollTop) {
    container.scrollTop -= container.scrollTop - top + 5;
  } else if (bottom > containerHeight + container.scrollTop) {
    container.scrollTop += bottom - containerHeight - container.scrollTop + 5;
  }
};

const CommandList = ({
  items,
  command,
  editor,
  range,
}: {
  items: CommandItemProps[];
  command: any;
  editor: Editor;
  range: Range;
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { context, setOpenAiMenu, setOpenOKRAiMenu, isOneLine, contextTask } =
    useContext(TipTapAiContext);
  // const aiPreviewAreaRef = useRef<HTMLParagraphElement>(null);
  const [itemsToDisplay, setItemsToDisplay] = useState(items);

  useEffect(() => {
    setSelectedIndex(0);
    if (contextTask === AiContextTask.OKR_OBJECTIVE_TITLE) {
      setItemsToDisplay(items.filter((item) => item.title === "Ask AI"));
    } else if (isOneLine) {
      setItemsToDisplay(
        items.filter(
          (item) => item.title === "Write it for me" || item.title === "Ask AI"
        )
      );
    } else {
      setItemsToDisplay(items);
    }
  }, [items, isOneLine, contextTask]);

  const { isLoading, complete } = useAICompletion({
    context: context.context,
    editor,
    // aiPreviewAreaRef,
    insertDirectlyIntoEditor: true,
  });

  const selectItem = useCallback(
    (index: number) => {
      const item = itemsToDisplay[index];
      if (item) {
        if (item.title === "Write it for me") {
          if (isLoading) return;
          complete({
            task: AiTask.WRITE_FOR_ME,
            rangeToDelete: range,
            contextBefore: getPrevText(editor, {
              chars: 5000,
              offset: 1,
            }),
            contextAfter: getAfterText(editor, {
              chars: 5000,
              offset: 1,
            }),
          });
        } else if (item.title === "Ask AI") {
          editor
            ?.chain()
            .focus()
            .deleteRange(range)
            .insertContent(" ")
            .setTextSelection({
              from: range.from,
              to: range.from + 1,
            })
            .run();
          setOpenAiMenu(true);
          setOpenOKRAiMenu({
            isOpen: true,
            autofocus: true,
          });
        } else {
          command(item);
        }
      }
    },
    [
      complete,
      isLoading,
      command,
      editor,
      itemsToDisplay,
      range,
      setOpenAiMenu,
      setOpenOKRAiMenu,
    ]
  );

  useEffect(() => {
    const navigationKeys = ["ArrowUp", "ArrowDown", "Enter"];
    const onKeyDown = (e: KeyboardEvent) => {
      if (navigationKeys.includes(e.key)) {
        e.preventDefault();
        if (e.key === "ArrowUp") {
          setSelectedIndex(
            (selectedIndex + itemsToDisplay.length - 1) % itemsToDisplay.length
          );
          return true;
        }
        if (e.key === "ArrowDown") {
          setSelectedIndex((selectedIndex + 1) % itemsToDisplay.length);
          return true;
        }
        if (e.key === "Enter") {
          selectItem(selectedIndex);
          return true;
        }
        return false;
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [itemsToDisplay, selectedIndex, setSelectedIndex, selectItem]);

  const commandListContainer = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const container = commandListContainer?.current;

    const item = container?.children[selectedIndex] as HTMLElement;

    if (item && container) updateScrollView(container, item);
  }, [selectedIndex]);

  return itemsToDisplay.length > 0 ? (
    <Box
      id="slash-command"
      ref={commandListContainer}
      zIndex={50}
      h="auto"
      maxH="330px"
      w="305px"
      overflowY="auto"
      rounded="md"
      border="1px"
      borderColor="borderPrimary"
      bg="white"
      p={1}
      shadow="md"
      transition="all"
    >
      {itemsToDisplay.map((item: CommandItemProps, index: number) => (
        <Button
          key={index}
          variant="ghost"
          justifyContent="start"
          textAlign="left"
          isActive={index === selectedIndex}
          onClick={() => selectItem(index)}
          h={"50px"}
          w={"100%"}
        >
          <HStack spacing={2}>
            <Box
              h={10}
              w={10}
              display="flex"
              alignItems="center"
              justifyContent="center"
              rounded="md"
              border="1px"
              borderColor="borderPrimary"
              bg="white"
            >
              {item.title === "Write it for me" && isLoading ? (
                <AiAnimation />
              ) : (
                item.icon
              )}
            </Box>
            <VStack alignItems="start" gap={1}>
              <Text fontSize={"sm"}>{item.title}</Text>
              <Text fontSize="xs" color="stone.500">
                {item.description}
              </Text>
            </VStack>
          </HStack>
        </Button>
      ))}
    </Box>
  ) : null;
};

const renderItems = () => {
  let component: ReactRenderer | null = null;
  let popup: any | null = null;

  return {
    onStart: (props: { editor: Editor; clientRect: DOMRect }) => {
      component = new ReactRenderer(CommandList, {
        props,
        editor: props.editor,
      });
      const tipTapAiContext = props.editor.options.editorProps.attributes as any
      if (tipTapAiContext) {
        if (tipTapAiContext?.openOKRAiMenu?.isOpen) {
          console.log("close OKRAiMenu");
          tipTapAiContext.setOpenOKRAiMenu({
            isOpen: false,
            autofocus: false,
          });
          tipTapAiContext.setOpenAiMenu(false);
        }
      }
      // @ts-ignore
      popup = tippy("body", {
        getReferenceClientRect: props.clientRect,
        appendTo: () => document.body,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: "manual",
        placement: "bottom-start",
      });
    },
    onUpdate: (props: { editor: Editor; clientRect: DOMRect }) => {
      component?.updateProps(props);

      popup &&
        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
    },
    onKeyDown: (props: { event: KeyboardEvent }) => {
      if (props.event.key === "Escape") {
        popup?.[0].hide();

        return true;
      }
      // @ts-ignore
      return component?.ref?.onKeyDown(props);
    },
    onExit: () => {
      popup?.[0].destroy();
      setTimeout(() => {
        component?.destroy();
      }, 0);
    },
  };
};

const SlashCommand = Command.configure({
  suggestion: {
    items: (props: any) =>
      getSuggestionItems({
        ...props,
        useAi: props.editor.options.editorProps?.attributes?.useAi,
      }),
    render: renderItems,
  },
});

export default SlashCommand;
