import {
  Box,
  Button,
  HStack,
  IconButton,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MdClose } from "react-icons/md";
import { FormattedDate, FormattedTime } from "react-intl";
import {
  useDeletePageInfo,
  useUpdatePageInfo,
} from "../../../api/pageInfo/pageInfo";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import { EditUsefulLinks } from "../../../pages/CultureHub/widgets/UsefulLinksWidget";
import { InputField } from "../../fields/InputField";
import { DeleteSomethingModal } from "../../modals/DeleteSomethingModal";
import TipTapEditor from "../Editor/TipTapEditor";

export const EditPageInfo = ({
  pageInfo,
  pageInfoRef,
  setPageInfoEditMode,
}: {
  pageInfo: any;
  pageInfoRef: any;
  setPageInfoEditMode: any;
}) => {
  const { accountId } = useAccountId();
  const updatePageInfoMutation = useUpdatePageInfo();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      content: pageInfo?.content,
      usefulLinks: pageInfo?.usefulLinks,
      title: pageInfo?.title,
    },
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isDirty },
    watch,
  } = form;

  const currentTitle = watch("title");

  const debouncedSubmit = useCallback(
    debounce((event?: any) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  // Only submit if there are no errors
  const submitForm = (data: any) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updatePageInfo)(data);
    }
  };

  const updatePageInfo = async (data: any) => {
    try {
      await updatePageInfoMutation.mutateAsync({
        accountId: accountId || "",
        pageInfoId: pageInfo.id,
        data: {
          content: data.content,
          usefulLinks: data.usefulLinks,
          title: data.title,
        },
      });
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating page header" });
    }
  };

  useEffect(() => {
    if (pageInfo && !isDirty) {
      reset(pageInfo);
    }
  }, [pageInfo]);

  return (
    <>
      {/* Overlay */}
      <Box
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bg="rgba(0,0,0,0.3)" // semi-transparent black
        zIndex="10" // ensures it's above other content
        onClick={() => setPageInfoEditMode(false)}
        sx={{
          backdropFilter: "blur(0px)", // this creates the blurring effect
          WebkitBackdropFilter: "blur(0px)", // for Safari
        }}
      />
      <Box
        position="relative"
        zIndex="20" // ensures it's above the overlay
        borderRadius={"md"}
        border={"1px"}
        borderColor={"gray.200"}
        p={4}
        bg={"white"}
        ref={pageInfoRef}
      >
        <HStack justifyContent={"space-between"} w={"100%"}>
          <Stack
            flexDirection={{ base: "column", "2xl": "row" }}
            gap={8}
            w={"100%"}
          >
            <Box w={"100%"}>
              <FormProvider {...form}>
                <form
                  onSubmit={handleSubmit(updatePageInfo)}
                  onChange={debouncedSubmit}
                >
                  <VStack>
                    <InputField
                      field={{
                        id: "title",
                        placeholder: "Title",
                        label: "Title",
                      }}
                    />
                    <Text
                      fontSize={"sm"}
                      fontWeight={600}
                      color={"textSecondary"}
                      alignSelf={"flex-start"}
                    >
                      Last Updated:{" "}
                      <FormattedDate
                        value={pageInfo.updatedAt}
                        month="short"
                        day="2-digit"
                        year="numeric"
                      />
                      {` `}
                      <FormattedTime
                        value={pageInfo.updatedAt}
                        timeZoneName="short"
                      />
                    </Text>
                    <Box w={"100%"}>
                      <TipTapEditor
                        defaultValue={pageInfo.content}
                        onChange={(htmlContent) => {
                          setValue("content", htmlContent);
                          debouncedSubmit();
                        }}
                        minHeight={200}
                        aiContext={{
                          context: `We are writing a description for a page titled '${currentTitle}'.`,
                        }}
                      />
                    </Box>
                  </VStack>
                </form>
              </FormProvider>
            </Box>

            <VStack
              justifyContent={"space-between"}
              w={"100%"}
              alignItems={"flex-start"}
            >
              <VStack spacing={2} alignItems={"flex-start"} w={"100%"}>
                <Text fontWeight={"bold"}>Useful links</Text>
                <Box w={"100%"}>
                  <EditUsefulLinks
                    data={{ links: pageInfo.usefulLinks }}
                    submitLinks={async (data: any) => {
                      updatePageInfo({
                        content: getValues().content,
                        usefulLinks: data.data.links,
                      });
                    }}
                    showViewButton={false}
                    setEditMode={() => {}}
                  />
                </Box>
              </VStack>
              <HStack w={"100%"} justifyContent={"space-between"}>
                <Box />
                <DeletePageInfoButton pageInfo={pageInfo} />
              </HStack>
            </VStack>
          </Stack>
          <Box alignSelf={"flex-start"}>
            <IconButton
              aria-label="Close"
              icon={<MdClose size={"24px"} />}
              variant="ghost"
              onClick={() => {
                setPageInfoEditMode(false);
              }}
              isLoading={updatePageInfoMutation.isLoading}
            />
          </Box>
        </HStack>
      </Box>
    </>
  );
};

const DeletePageInfoButton = ({ pageInfo }: { pageInfo: any }) => {
  const { showErrorToast } = useToastMessage();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const deletePageInfoMutation = useDeletePageInfo();
  const [isDeletePageInfoModalOpen, setIsDeletePageInfoModalOpen] =
    useState(false);

  const deletePageInfo = async (pageInfoId: string) => {
    try {
      await deletePageInfoMutation.mutateAsync({
        accountId: accountId || "",
        pageInfoId,
      });
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error deleting page header" });
    }
  };

  return (
    <Box>
      <DeleteSomethingModal
        isOpen={isDeletePageInfoModalOpen}
        isDeleting={deletePageInfoMutation.isLoading}
        setIsOpen={setIsDeletePageInfoModalOpen}
        headerText={"Delete Page Header"}
        bodyText={`Are you sure you want to delete this page header?`}
        deleteFunction={async () => {
          await deletePageInfo(pageInfo.id);
        }}
        buttonText={"Delete Page Header"}
      />
      <Button
        _hover={{ bg: "red.100" }}
        onClick={() => {
          setIsDeletePageInfoModalOpen(true);
        }}
        variant={"outline"}
      >
        Delete Page Header
      </Button>
    </Box>
  );
};
