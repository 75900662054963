import PageShell from "../../../layout/PageShell";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../../hooks/customDomainHooks";
import SurveysReport from "./SurveysReport";

const SurveysReportPage = () => {
  const { accountId } = useAccountId();
  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Reports", href: `/${accountId}/reports` },
    { name: "Surveys" },
  ]);

  return (
    <PageShell breadcrumbs={breadcrumbs}>
      <SurveysReport />
    </PageShell>
  );
};

export default SurveysReportPage;
