import bg1 from "../../assets/backgrounds/bg1.png";
import bg2 from "../../assets/backgrounds/bg2.png";
import bg3 from "../../assets/backgrounds/bg3.png";
import bg4 from "../../assets/backgrounds/bg4.png";

function hashUUID(uuid) {
  let hash = 0;
  for (let i = 0; i < uuid.length; i++) {
    hash += uuid.charCodeAt(i);
  }
  return hash;
}

export const getEventImage = (eventId) => {
  const defaultImages = [bg1, bg2, bg3, bg4];
  return defaultImages[hashUUID(eventId) % 4];
};
