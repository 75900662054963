import { Stack, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { DepartmentSelect } from "../common/DepartmentSelect";
import { RankingSelect } from "../common/RankingSelect";
import { TenureSelect } from "../common/TenureSelect";
import { TerminationTagIdSelect } from "../common/TerminationReasonSelect";

export const EmployeeTurnoverReportFilters = ({
  departmentsToFilter,
  setDepartmentsToFilter,
  ranksToFilter,
  setRanksToFilter,
  tenuresToFilter,
  setTenuresToFilter,
  terminationTagIdsToFilter,
  setTerminationTagIdsToFilter,
  watchGraphType,
}) => {
  return (
    <Stack direction={{ base: "column", sm: "row" }} alignItems={"center"}>
      <Text fontWeight={700} whiteSpace={"nowrap"}>
        Filtered by:
      </Text>
      <Wrap>
        {watchGraphType !== "byDepartment" && (
          <WrapItem>
            <DepartmentSelect
              selectedDepartments={departmentsToFilter}
              setSelectedDepartments={setDepartmentsToFilter}
            />
          </WrapItem>
        )}
        {watchGraphType !== "byRank" && (
          <WrapItem>
            <RankingSelect
              selectedRanks={ranksToFilter}
              setSelectedRanks={setRanksToFilter}
            />
          </WrapItem>
        )}
        <WrapItem>
          <TenureSelect
            selectedTenures={tenuresToFilter}
            setSelectedTenures={setTenuresToFilter}
          />
        </WrapItem>
        <WrapItem>
          <TerminationTagIdSelect
            selectedTerminationTagIds={terminationTagIdsToFilter}
            setSelectedTerminationTagIds={setTerminationTagIdsToFilter}
          />
        </WrapItem>
      </Wrap>
    </Stack>
  );
};
