import {
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  VStack,
  Link,
  usePopoverContext,
} from "@chakra-ui/react";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import { useQueryClient } from "@tanstack/react-query";
import { PROGRESS_OPTIONS } from "../common";
import { useState } from "react";
import { useUpdateKeyResult } from "../../../api/okr/keyResult";

const KeyResultProgress = ({ objective, keyResult, canEditKeyResult }) => {
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const updateKeyResultMutation = useUpdateKeyResult();
  const [isSaving, setIsSaving] = useState(false);

  const updateKeyResult = async (newProgress) => {
    try {
      setIsSaving(true);
      await updateKeyResultMutation.mutateAsync({
        accountId,
        objectiveId: objective.id,
        keyResultId: keyResult.id,
        data: {
          progress: newProgress,
        },
      });
      await queryClient.invalidateQueries(["fetchObjectives", accountId]);
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      console.log(error);
      showErrorToast({ message: "Error updating Key Result" });
    }
  };

  const ProgressLinks = () => {
    const { onClose } = usePopoverContext();

    const handleLinkClick = async (visibility) => {
      onClose();
      await updateKeyResult(visibility);
    };

    return (
      <VStack alignItems={"center"} textAlign={"center"} fontWeight={700}>
        {keyResult.progress !== "DONE" && (
          <Link
            onClick={() => handleLinkClick("DONE")}
            color={PROGRESS_OPTIONS["DONE"].color}
          >
            {PROGRESS_OPTIONS["DONE"].label}
          </Link>
        )}
        {keyResult.progress !== "ON_TARGET" && (
          <Link
            onClick={() => handleLinkClick("ON_TARGET")}
            color={PROGRESS_OPTIONS["ON_TARGET"].color}
          >
            {PROGRESS_OPTIONS["ON_TARGET"].label}
          </Link>
        )}
        {keyResult.progress !== "AT_RISK" && (
          <Link
            onClick={() => handleLinkClick("AT_RISK")}
            color={PROGRESS_OPTIONS["AT_RISK"].color}
          >
            {PROGRESS_OPTIONS["AT_RISK"].label}
          </Link>
        )}
        {keyResult.progress !== "OFF_TRACK" && (
          <Link
            onClick={() => handleLinkClick("OFF_TRACK")}
            color={PROGRESS_OPTIONS["OFF_TRACK"].color}
          >
            {PROGRESS_OPTIONS["OFF_TRACK"].label}
          </Link>
        )}
      </VStack>
    );
  };

  return (
    <Box>
      <Popover isOpen={canEditKeyResult ? undefined : false}>
        <PopoverTrigger>
          <Button
            isLoading={isSaving}
            variant="ghost"
            _hover={{ textDecoration: canEditKeyResult ? "underline" : "none" }}
            cursor={canEditKeyResult ? "pointer" : "default"}
            _active={{ bg: "none" }}
            p={0}
            m={0}
            color={PROGRESS_OPTIONS[keyResult.progress].color}
          >
            {PROGRESS_OPTIONS[keyResult.progress].label}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
          borderRadius={"10px"}
          w={"130px"}
          p={3}
        >
          <PopoverArrow />
          <PopoverBody>
            <ProgressLinks />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default KeyResultProgress;
