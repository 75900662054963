import {
  Box,
  Center,
  Container,
  Heading,
  HStack,
  Image,
  Link,
  SlideFade,
  Stack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import tbaFrame from "../../assets/tba-frame.png";
import BackgroundImageGallery from "../../components/common/BackgroundImageGallery";

const AuthLayout = ({
  children,
  isSignup,
}: {
  children: React.ReactNode;
  isSignup?: boolean;
}) => {
  const navigate = useNavigate();

  const footer = () => {
    return (
      <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
        <Heading size={{ base: "xs", md: "lg" }}></Heading>
        <HStack spacing="1" justify="center">
          {isSignup && (
            <>
              {" "}
              Already have an account?
              <Link
                fontSize={"md"}
                fontWeight={600}
                onClick={() => {
                  navigate("/_auth/login");
                }}
                color="primary"
              >
                <strong>Login</strong>{" "}
              </Link>
            </>
          )}
        </HStack>
      </Stack>
    );
  };
  return (
    <Box h="100vh">
      <BackgroundImageGallery>
        <SlideFade
          in
          offsetY={-30}
          style={{ display: "flex", flex: 1, flexDirection: "column" }}
        >
          <Container
            maxW="xl"
            py={{ base: "2", md: "4" }}
            px={{ base: "0", sm: "8" }}
          >
            <Stack spacing="8">
              <Box
                py={{ base: "3", md: "6" }}
                px={{ base: "4", sm: "10" }}
                bg={"white"}
                borderRadius={{ base: "none", sm: "5px" }}
                border={"1px"}
                borderColor={"gray.200"}
              >
                <Center pb={4}>
                  <Image src={tbaFrame} alt="The Best Answer" w={"328px"} />
                </Center>
                {children}
                {footer()}
              </Box>
            </Stack>
          </Container>
        </SlideFade>
      </BackgroundImageGallery>
    </Box>
  );
};

export default AuthLayout;
