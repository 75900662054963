import { useQuery } from "@tanstack/react-query";
import api from "../config";
import {  AbcRankingReport } from "./types";

export const useFetchAbcRankingReports = (accountId: string) => {
  return useQuery<AbcRankingReport[]>({
    queryKey: ["fetchAbcRankingReports"],
    queryFn: async () => {
      const response = await api.get<AbcRankingReport[]>(`/${accountId}/abcRankingReports`);
      return response.data;
    },
  });
};

export const useFetchAbcRankingReport = (accountId: string, reportId: string) => {
  return useQuery<AbcRankingReport>({
    queryKey: ["fetchAbcRankingReport", reportId],
    queryFn: async () => {
      const response = await api.get<AbcRankingReport>(`/${accountId}/abcRankingReports/${reportId}`);
      return response.data;
    },
  });
};

export const useFetchAllAbcRankingReportMetrics = (
  accountId: string,
  departments: { label: string }[],
  tenures: { label: string }[],
  timePeriod: string
) => {
  return useQuery<any>({
    queryKey: [
      "fetchAllAbcRankingReportMetrics",
      departments,
      tenures,
      timePeriod,
    ],
    queryFn: async () => {
      const departmentQuery = departments
        ?.map((department) => `&department=${department.label}`)
        .join("&");

      const tenureQuery = tenures
        ?.map((tenure) => `&tenure=${encodeURIComponent(tenure.label)}`)
        .join("&");

      const timePeriodQuery =
        timePeriod && timePeriod !== "LAST_6_REPORTS"
          ? `&timePeriod=${timePeriod}`
          : "";

      const response = await api.get<any>(
        `/${accountId}/metrics/employeeAbcRankings/all?${departmentQuery}${tenureQuery}${timePeriodQuery}`
      );
      return response.data;
    },
  });
};