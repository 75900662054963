import { avatarAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

// See here https://chakra-ui.com/docs/components/avatar/theming#customizing-the-default-theme
const baseStyle = definePartsStyle({
  excessLabel: {
    fontSize: "sm",
  },
});

export const avatarTheme = defineMultiStyleConfig({ baseStyle });
