import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { BiArchive, BiDotsVerticalRounded } from "react-icons/bi";
import { IoPersonAdd } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useRemoveTeamMembers, useUpdateTeam } from "../../api/teams/team";
import { AvatarWithName } from "../../components/common/AvatarWithName";
import { DeleteSomethingModal } from "../../components/modals/DeleteSomethingModal";
import CustomTable from "../../components/table/CustomTableV2";
import useAccountId from "../../hooks/customDomainHooks";
import useToastMessage from "../../hooks/useToastMessage";
import { AddTeamMemberModal } from "./modals/AddTeamMemberModal";

const TeamMembers = ({ members, allowEdit, isTeamArchived }) => {
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [isArchiveTeamModalOpen, setIsArchiveTeamModalOpen] = useState(false);
  const removeTeamMembersMutation = useRemoveTeamMembers();
  const { showErrorToast, showSuccessToast } = useToastMessage();
  const { teamId } = useParams();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const updateTeamMutation = useUpdateTeam();

  const removeTeamMembers = async (profileIds) => {
    try {
      const membersToRemove = profileIds.map((profileId) => {
        return {
          id: profileId,
        };
      });
      await removeTeamMembersMutation.mutateAsync({
        accountId,
        teamId,
        data: { members: membersToRemove },
      });
      queryClient.invalidateQueries(["fetchTeam", teamId]);
      queryClient.invalidateQueries(["getMe"]);
      showSuccessToast(`Member removed from team`);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error removing member from team" });
    }
  };

  const updateTeam = async (data) => {
    try {
      await updateTeamMutation.mutateAsync({
        accountId,
        teamId,
        data,
      });
      queryClient.invalidateQueries(["fetchTeam", teamId]);
      showSuccessToast("Team updated");
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating team." });
    }
  };

  const editAndViewColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "profile.name",
        Cell: ({ cell }) => {
          const member = cell.row.original;
          return <AvatarWithName profile={member.profile} />;
        },
      },
      {
        Header: "Email",
        accessor: "profile.email",
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ cell }) => (
          <Box textAlign={"right"}>
            <Menu>
              <MenuButton
                isLoading={removeTeamMembersMutation.isLoading}
                as={IconButton}
                variant="outline"
                icon={<BiDotsVerticalRounded size={22} />}
              ></MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    removeTeamMembers([cell.row.original.profile.id]);
                  }}
                  icon={
                    <Center>
                      <Icon as={BiArchive} fontSize={"xl"} />
                    </Center>
                  }
                  color={"red.500"}
                >
                  Remove
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        ),
      },
    ],
    []
  );

  const viewOnlyColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "profile.name",
        Cell: ({ cell }) => {
          const member = cell.row.original;
          return <AvatarWithName profile={member.profile} />;
        },
      },
      {
        Header: "Email",
        accessor: "profile.email",
      },
    ],
    []
  );

  const inviteButton = (
    <HStack alignItems={"flex-end"} spacing={4}>
      <Button
        w={"180px"}
        leftIcon={<IoPersonAdd />}
        onClick={() => setIsAddMemberModalOpen(true)}
      >
        Add Members
      </Button>
      <ThreeDotsMenu
        setIsDeleteModalOpen={setIsArchiveTeamModalOpen}
        isArchived={isTeamArchived}
        unarchiveTeam={async () => {
          await updateTeam({ isArchived: false });
        }}
      />
    </HStack>
  );

  return (
    <Box>
      <AddTeamMemberModal
        isOpen={isAddMemberModalOpen}
        setIsOpen={setIsAddMemberModalOpen}
        currentMembers={members}
      />
      <DeleteSomethingModal
        isOpen={isArchiveTeamModalOpen}
        setIsOpen={setIsArchiveTeamModalOpen}
        headerText={"Archive Team"}
        bodyText={`Are you sure you want to archive this team?`}
        deleteFunction={async () => {
          await updateTeam({ isArchived: true });
        }}
        buttonText={"Archive Team"}
      />
      <Box>
        <CustomTable
          columns={allowEdit ? editAndViewColumns : viewOnlyColumns}
          data={members}
          rightElement={allowEdit && inviteButton}
          doUsePagination={true}
          selectionType={"none"}
          boxStyle={{
            bg: "white",
            borderRadius: "10px",
            border: "1px",
            borderColor: "gray.200",
            p: 4,
            overflow: "auto",
          }}
          tableStyle={{
            size: "md",
          }}
          tableId={"viewTeamMembersTable"}
        />
      </Box>
    </Box>
  );
};

export default TeamMembers;

const ThreeDotsMenu = ({ setIsDeleteModalOpen, isArchived, unarchiveTeam }) => {
  return (
    <Box>
      <Menu>
        <MenuButton
          as={IconButton}
          variant={"outline"}
          icon={<BiDotsVerticalRounded size={22} />}
        ></MenuButton>
        <MenuList>
          {!isArchived && (
            <MenuItem
              _hover={{ bg: "red.100" }}
              color={"red.500"}
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
              icon={
                <Center>
                  <Icon as={BiArchive} fontSize={"xl"} />
                </Center>
              }
            >
              Archive Team
            </MenuItem>
          )}
          {isArchived && (
            <MenuItem
              _hover={{ bg: "red.100" }}
              onClick={unarchiveTeam}
              icon={
                <Center>
                  <Icon as={BiArchive} fontSize={"xl"} />
                </Center>
              }
              color={"red.500"}
            >
              Unarchive Team
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};
