import { Text, VStack } from "@chakra-ui/react";
import { OneOnOnesTable } from "./OneOnOnesTable";
import { useFetchOneOnOneMeetings } from "../../api/oneOnOne/oneOnOneMeeting";
import useAccountId from "../../hooks/customDomainHooks";
import CreateOneOnOneButton from "./Buttons/CreateOneOnOneButton";
import { useMemo } from "react";

const InviteeOneOnOnes = ({ inviteeId, inviteeName }) => {
  const { accountId } = useAccountId();
  const {
    data: oneOnOneMeetings,
    isLoading: isLoadingOneOnOneMeetings,
    isError: isErrorOneOnOneMeetings,
    error: errorOneOnOneMeetings,
  } = useFetchOneOnOneMeetings(accountId, inviteeId);

  const meetingsToShow = useMemo(() => {
    return oneOnOneMeetings?.filter((meeting) => {
      return !meeting.isArchived;
    });
  }, [oneOnOneMeetings]);

  if (!isLoadingOneOnOneMeetings && meetingsToShow.length === 0) {
    return (
      <VStack
        w={"100%"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        gap={4}
      >
        <Text>No 1-on-1 meetings scheduled with {inviteeName}.</Text>
        <CreateOneOnOneButton profileId={inviteeId} />
      </VStack>
    );
  }

  return (
    <VStack
      w={"100%"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      gap={0}
    >
      <OneOnOnesTable
        oneOnOneMeetings={meetingsToShow}
        isLoadingOneOnOneMeetings={isLoadingOneOnOneMeetings}
        isErrorOneOnOneMeetings={isErrorOneOnOneMeetings}
        errorOneOnOneMeetings={errorOneOnOneMeetings}
        showSearch={false}
        showCreateButton={false}
        showNameColumn={false}
      />
    </VStack>
  );
};

export default InviteeOneOnOnes;
