import { Box, Button, Divider, HStack, Stack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { auth, useLogout } from "../firebase";
import useToastMessage from "../../hooks/useToastMessage";
import { useEffect, useState } from "react";
import api from "../../api/config";
import { onAuthStateChanged } from "firebase/auth";
import AuthLayout from "../components/AuthLayout";

const ProfileNotVerified = () => {
  const navigate = useNavigate();
  const { showErrorToast } = useToastMessage();
  const [isSendingVerificationEmail, setIsSendingVerificationEmail] =
    useState(false);
  const [hasSentVerificationEmail, setHasSentVerificationEmail] =
    useState(false);
  const logout = useLogout();

  const checkIfVerified = async (showError = false) => {
    // Need to refresh the token so that the emailVerified property is updated
    try {
      await auth.currentUser?.reload();
      await auth.currentUser?.getIdToken(true);
      if (auth.currentUser?.emailVerified) {
        navigate("/");
        return true;
      } else {
        if (showError) {
          showErrorToast({
            message:
              "Your email is not verified yet. Be sure to check your spam folder if you don't see an email in your inbox.",
          });
        }
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in
        console.log("updated", auth.currentUser);
        const isVerified = await checkIfVerified();
        if (isVerified) {
          return;
        }
      } else {
        // User is signed out
        console.log("User is signed out");
      }
    });

    // Cleanup the subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  return (
    <Box bg={"offWhite"} h="100vh">
      <HStack justifyContent={"space-between"}>
        <Box />
        <Button m={4} onClick={() => logout()} variant={"outline"}>
          Logout
        </Button>
      </HStack>
      <AuthLayout>
        <Stack spacing="8">
          <Stack spacing="6" align="center">
            <Text size="sm">
              Please verify your email address before logging in.
            </Text>
          </Stack>
          <Stack spacing="6">
            <Divider />
            <Stack spacing="4">
              <Button onClick={() => checkIfVerified(true)}>
                I have verified my email
              </Button>
              {hasSentVerificationEmail && (
                <Text>
                  Email sent. Be sure to check your spam folder if you don't see
                  an email in your inbox.
                </Text>
              )}
              {!hasSentVerificationEmail && (
                <Button
                  variant={"outline"}
                  isLoading={isSendingVerificationEmail}
                  onClick={async () => {
                    try {
                      setIsSendingVerificationEmail(true);
                      // await sendEmailVerification(auth.currentUser);
                      await api.post("/users/sendVerificationEmail");
                      setHasSentVerificationEmail(true);
                      setIsSendingVerificationEmail(false);
                    } catch (error) {
                      console.log(error);
                      setIsSendingVerificationEmail(false);
                      showErrorToast({
                        message:
                          "Error sending verification email. Please try again later.",
                      });
                    }
                  }}
                >
                  Resend Verification Email
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      </AuthLayout>
    </Box>
  );
};
export default ProfileNotVerified;
