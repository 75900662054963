import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import { useCreateSurvey } from "../../api/survey/survey";
import LoadingBox from "../../components/common/LoadingBox";
import InfoBox from "../../components/common/PageInfo/InfoBox";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import useTabNavigation from "../../hooks/useTabNavigation";
import useToastMessage from "../../hooks/useToastMessage";
import PageShell from "../../layout/PageShell";
import ManageSurveys from "./ManageSurveys";
import MySurveys from "./MySurveys";

const SurveysPage = () => {
  const { accountId } = useAccountId();
  const { currentProfile, isLoading: isLoadingAccount } =
    useContext(AccountContext);
  const [pageInfoEditMode, setPageInfoEditMode] = useState(false);
  const { showErrorToast, showSuccessToast } = useToastMessage();
  const createSurveyMutation = useCreateSurvey(accountId || "");
  const queryClient = useQueryClient();
  const navigate = useCustomDomainNavigate();

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Surveys" },
  ]);

  const profileRole = currentProfile?.role;

  const allTabs = [
    {
      name: "My Open Surveys",
      rolesAllowed: ["ADMIN", "OWNER", "PARTNER", "EMPLOYEE"],
      content: <MySurveys />,
    },
    {
      name: "Manage Surveys",
      rolesAllowed: ["ADMIN", "OWNER", "PARTNER"],
      content: <ManageSurveys />,
    },
  ];

  const { tabIndex, handleTabsChange } = useTabNavigation(allTabs, "Surveys");

  const allowedTabs = allTabs.filter((tab) => {
    return tab.rolesAllowed.includes(profileRole);
  });

  const createSurvey = async () => {
    try {
      const data = {
        title: "New Survey",
      };
      const respData = await createSurveyMutation.mutateAsync({
        accountId: accountId || "",
        data,
      });
      showSuccessToast("Survey created");
      queryClient.invalidateQueries(["fetchSurveys", accountId]);
      navigate(`/${accountId}/surveys/${respData.id}/update`);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating survey" });
    }
  };

  if (isLoadingAccount) {
    return <LoadingBox />;
  }
  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      menuItems={[
        {
          type: "CREATE_PAGE_INFO",
          pageType: "SURVEYS",
        },
      ]}
      setPageInfoEditMode={setPageInfoEditMode}
      headerComponentRight={
        <Button
          leftIcon={<IoAddOutline />}
          onClick={() => createSurvey()}
          isLoading={createSurveyMutation.isLoading}
        >
          Create Survey
        </Button>
      }
    >
      <InfoBox
        pageInfoType={"SURVEYS"}
        setPageInfoEditMode={setPageInfoEditMode}
        pageInfoEditMode={pageInfoEditMode}
      />

      <Tabs
        index={tabIndex}
        onChange={(index) => {
          handleTabsChange(index);
        }}
      >
        <TabList>
          {allowedTabs.map((tab) => (
            <Tab key={tab.name}>{tab.name}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {allowedTabs.map((tab) => (
            <TabPanel key={tab.name} pl={0}>
              {tab.content}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </PageShell>
  );
};

export default SurveysPage;
