import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Grid,
  GridItem,
  HStack,
  InputRightAddon,
  Text,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { NumberField } from "../../components/fields/NumberField";
import TrendingIcon from "./TrendingIcon";
import Factors from "./Factors";
import { useCultureScoreData } from "./useCultureScoreData";

export const CultureScoreMetricRow = ({ cultureScoreSetting, index }) => {
  const form = useFormContext();
  const { getValues } = form;
  const {
    cultureScoreFactors,
    currentMonthModuleWeightedScoreAverages,
    lastMonthModuleWeightedScoreAverages,
  } = useCultureScoreData();

  const currentMonthModuleWeightedScoreAverage =
    currentMonthModuleWeightedScoreAverages &&
    currentMonthModuleWeightedScoreAverages[cultureScoreSetting.module] * 100;
  const lastMonthModuleWeightedScoreAverage =
    lastMonthModuleWeightedScoreAverages &&
    lastMonthModuleWeightedScoreAverages[cultureScoreSetting.module] * 100;

  const verifyWeights = () => {
    const values = getValues();

    const totalWeight = Object.keys(values).reduce((acc, key) => {
      return acc + values[key].weight;
    }, 0);

    if (totalWeight !== 100) {
      return false; //"Weights for factors must add up to 100%";
    } else {
      return true;
    }
  };

  const currentModuleScore =
    (cultureScoreFactors &&
      cultureScoreFactors[0]?.modules?.find((module) => {
        return module.id === cultureScoreSetting.module;
      })?.score) * 100 || 0;

  return (
    <Accordion
      allowToggle
      borderTop={index !== 0 && "1px"}
      borderColor="gray.200"
    >
      <AccordionItem border={0}>
        <h2>
          <AccordionButton w="100%">
            <Box flex="1" align="left">
              <Grid
                templateColumns="repeat(12, 1fr)"
                gap={0}
                alignItems="center"
                p={2}
              >
                <GridItem colSpan={6}>
                  <HStack>
                    <Box
                      bg={"gray.100"}
                      borderRadius={"md"}
                      w={"50px"}
                      h={"50px"}
                    >
                      <Center h={"100%"}>{cultureScoreSetting.icon}</Center>
                    </Box>
                    <Text fontWeight={"bold"}>{cultureScoreSetting.label}</Text>
                  </HStack>
                </GridItem>

                <GridItem colSpan={2}>
                  <Text>{currentModuleScore.toFixed(0)}</Text>
                </GridItem>

                <GridItem colSpan={2}>
                  <NumberField
                    field={{
                      id: `${cultureScoreSetting.module}.weight`,
                      max: 100,
                      min: 0,
                      validation: {
                        required: {
                          value: true,
                          message: "Weight is required",
                        },
                        validate: {
                          verifyWeights: verifyWeights,
                        },
                      },
                    }}
                    styleProps={{ w: "50px", bg: "white" }}
                    inputProps={{
                      onClick: (e) => {
                        e.stopPropagation();
                      },
                    }}
                    rightAddon={
                      <InputRightAddon children="%" px={2} bg={"gray.50"} />
                    }
                  />
                </GridItem>

                <GridItem colSpan={1} align={"center"}>
                  <TrendingIcon
                    current={currentMonthModuleWeightedScoreAverage}
                    previous={lastMonthModuleWeightedScoreAverage}
                  />
                </GridItem>

                <GridItem colSpan={1} align={"right"}>
                  <AccordionIcon />
                </GridItem>
              </Grid>
            </Box>
          </AccordionButton>
        </h2>

        <AccordionPanel pb={4}>
          <Factors cultureScoreSetting={cultureScoreSetting} />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
