import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import api from "../config";
import { ShoutoutReaction } from "./types";

export const useCreateShoutoutReaction = (
  props?: UseMutationOptions<
    ShoutoutReaction,
    Error,
    { accountId: string; data: any }
  >
) =>
  useMutation<ShoutoutReaction, Error, { accountId: string; data: any }>({
    mutationKey: ["createShoutoutReaction"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/shoutoutReaction`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateShoutoutReaction = (
  props?: UseMutationOptions<
    ShoutoutReaction,
    Error,
    { accountId: string; shoutoutReactionId: string; data: any }
  >
) =>
  useMutation<
    ShoutoutReaction,
    Error,
    { accountId: string; shoutoutReactionId: string; data: any }
  >({
    mutationKey: ["updateShoutoutReaction"],
    mutationFn: async ({ accountId, shoutoutReactionId, data }) => {
      const response = await api.put(
        `/${accountId}/shoutoutReaction/${shoutoutReactionId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeleteShoutoutReaction = (
  props?: UseMutationOptions<
    ShoutoutReaction,
    Error,
    { accountId: string; shoutoutReactionId: string }
  >
) =>
  useMutation<
    ShoutoutReaction,
    Error,
    { accountId: string; shoutoutReactionId: string }
  >({
    mutationKey: ["deleteShoutoutReaction"],
    mutationFn: async ({ accountId, shoutoutReactionId }) => {
      const response = await api.delete(
        `/${accountId}/shoutoutReaction/${shoutoutReactionId}`
      );
      return response.data;
    },
    ...props,
  });
