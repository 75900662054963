import { Box, HStack, Tag, Text, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedDate, FormattedTime } from "react-intl";
import { useParams } from "react-router-dom";
import { useUpdatePrivateNote } from "../../../api/oneOnOne/oneOnOneMeeting";
import TipTapEditor from "../../../components/common/Editor/TipTapEditor";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";

export const PrivateNote = ({ privateNote }) => {
  const { meetingId } = useParams();
  const { accountId } = useAccountId();
  const updatePrivateNoteMutation = useUpdatePrivateNote();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();

  const defaultValues = {
    content: privateNote.content,
  };

  const form = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = form;

  const submitForm = (event) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updatePrivateNote)(event);
    }
  };

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  const updatePrivateNote = async (data) => {
    try {
      await updatePrivateNoteMutation.mutateAsync({
        accountId,
        data,
        meetingId,
      });
      queryClient.invalidateQueries(["fetchOneOnOneMeeting", meetingId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating private notes" });
    }
  };

  return (
    <VStack
      gap={2}
      alignItems={"flex-start"}
      w={"100%"}
      // w={{ base: "100%", md: "70%" }}
    >
      <HStack alignItems={"center"}>
        <Text fontSize={"xl"} fontWeight={"bold"}>
          Private Notes
        </Text>
        <Tag size={"md"} colorScheme={"yellow"}>
          Visible only to you
        </Tag>
      </HStack>
      <Text fontSize={"sm"} fontWeight={600} color={"textSecondary"}>
        Last saved {` `}
        <FormattedDate
          value={privateNote.updatedAt}
          month="short"
          day="2-digit"
        />
        {` at `}
        <FormattedTime value={privateNote.updatedAt} />{" "}
      </Text>
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(updatePrivateNote)}
          onChange={debouncedSubmit}
          style={{
            width: "100%",
          }}
        >
          {/* Editor */}
          <Box w={"100%"}>
            <TipTapEditor
              defaultValue={privateNote.content}
              onChange={(htmlContent) => {
                setValue("content", htmlContent);
                debouncedSubmit();
              }}
              minHeight={400}
              aiContext={{
                context: "We are writing private notes for a 1-on-1 meeting.",
              }}
            />
          </Box>
        </form>
      </FormProvider>
    </VStack>
  );
};
