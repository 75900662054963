import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useUpdateKbPage } from "../../../api/knowledgeBase/kbPage";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import EditKbPageViewers from "../EditKbPage/EditKbPageViewers";

export const EditKbPageViewersModal = (props) => {
  const { isOpen, setIsOpen, currentViewers } = props;
  const { pageId } = useParams();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const updateKbPageMutation = useUpdateKbPage();
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const [rightRowsSelected, setRightRowsSelected] = useState([]);

  const addAsViewers = async (data) => {
    try {
      const viewersToAdd = data.map((row) => {
        return row.id;
      });
      await updateKbPageMutation.mutateAsync({
        accountId,
        pageId,
        data: { viewers: viewersToAdd },
      });
      queryClient.invalidateQueries(["fetchKbPage", pageId]);
      showSuccessToast(`Viewers updated`);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error adding viewers" });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"5xl"}
    >
      <ModalOverlay />
      <ModalContent mt={{ base: "10px", "2xl": "64px" }}>
        <ModalHeader>Add Page Viewers</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EditKbPageViewers
            setRightRowsSelected={setRightRowsSelected}
            currentViewers={currentViewers}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            mr={4}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            onClick={() => addAsViewers(rightRowsSelected)}
            isLoading={updateKbPageMutation.isLoading}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
