import { useContext, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { EditorContent, useEditor } from "@tiptap/react";
import "./TipTapStyle.css";
import { TopMenuBar } from "./TopMenuBar/TopMenuBar";
import { getExtensions } from "./extensions/extensions";
import { EditorProps } from "@tiptap/pm/view";
import { BubbleMenuBaseOptions } from "./BubbleMenu/BubbleMenuBaseOptions";
import GenerativeMenuSwitch from "./BubbleMenu/GenerativeMenu";
import {
  AiContextTask,
  TipTapAiContextComponent,
} from "../../../context/TipTapAiContextComponent";
import OkrAiMenu from "./BubbleMenu/OkrAiMenu";
import { AccountContext } from "../../../context/AccountContextComponent";

export type AiContext = {
  context: string;
  metaData?: any;
};

const defaultEditorProps: EditorProps = {
  handleDOMEvents: {
    keydown: (_view, event) => {
      // prevent default event listeners from firing when slash command is active
      if (["ArrowUp", "ArrowDown", "Enter"].includes(event.key)) {
        const slashCommand = document.querySelector("#slash-command");
        if (slashCommand) {
          return true;
        }
      }
    },
  },
};

const TipTapEditor = ({
  onChange,
  defaultValue,
  minHeight,
  makeImagesPublic = false,
  setEditor,
  aiContext,
  aiContextTask,
  isOneLine = false,
  showTopBar = true,
  variant = "default",
  editable = true,
  placeholder,
  aiPlaceholder,
  useAi = true,
}: {
  onChange: (htmlContent: string) => void;
  defaultValue: string;
  minHeight?: number;
  makeImagesPublic?: boolean;
  setEditor?: any;
  aiContext?: AiContext;
  aiContextTask?: AiContextTask;
  isOneLine?: boolean;
  showTopBar?: boolean;
  variant?: "default" | "unstyled" | "oneLine";
  editable?: boolean;
  placeholder?: string;
  aiPlaceholder?: string;
  useAi?: boolean;
}) => {
  const { hasModuleTurnedOn } = useContext(AccountContext);

  // TODO if the account has AI turned off, set useAi to false here
  if (!hasModuleTurnedOn("AI")) {
    useAi = false;
  }
  // placeholder = undefined;
  const extensions = getExtensions({
    placeholder: useAi ? aiPlaceholder : placeholder,
    isOneLine,
  });
  const editor = useEditor({
    extensions: extensions,
    editorProps: defaultEditorProps,
    content: defaultValue,
    onUpdate: ({ editor }) => {
      isOneLine ? onChange(editor.getText()) : onChange(editor.getHTML());
    },
    editable: editable,
  });

  useEffect(() => {
    if (editor && setEditor) {
      setEditor(editor);
    }
  }, [editor, setEditor]);

  return (
    <TipTapAiContextComponent
      aiContext={aiContext}
      isOneLine={isOneLine}
      contextTask={aiContextTask}
      editor={editor}
      useAi={useAi}
    >
      <Box
        borderWidth={variant === "default" ? 1 : ""}
        borderRadius={variant === "default" ? "md" : ""}
        borderColor={"gray.200"}
        bg={variant === "default" ? "white" : "transparent"}
      >
        {editor && showTopBar && (
          <TopMenuBar editor={editor} makeImagesPublic={makeImagesPublic} />
        )}
        <Box
          style={
            {
              "--editor-min-height": minHeight ? `${minHeight}px` : null,
            } as React.CSSProperties
          }
        >
          {/* This is the generic AI assistant */}
          {editor &&
            useAi &&
            aiContextTask !== AiContextTask.OKR_OBJECTIVE_TITLE && (
              <GenerativeMenuSwitch editor={editor}>
                {!isOneLine && <BubbleMenuBaseOptions editor={editor} />}
              </GenerativeMenuSwitch>
            )}

          {/* This is the AI assistant that pops up when the user starts typing in an OKR  */}
          {editor &&
            useAi &&
            aiContextTask === AiContextTask.OKR_OBJECTIVE_TITLE && (
              <OkrAiMenu editor={editor}></OkrAiMenu>
            )}

          <EditorContent
            editor={editor}
            className={`editor editMode ${
              variant === "oneLine" ? "oneLineMode" : ""
            } ${variant === "unstyled" ? "noPadding" : ""}`}
          />
        </Box>
      </Box>
    </TipTapAiContextComponent>
  );
};

export default TipTapEditor;
