import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { ValueTag } from "./types";

export const useCreateValueTag = (
  props?: UseMutationOptions<any, unknown, { accountId: string; data: any }>
) =>
  useMutation<any, unknown, { accountId: string; data: any }>({
    mutationKey: ["createValueTag"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/valueTag`, data);
      return response.data;
    },
    ...props,
  });

export const useDeleteValueTag = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId: string; valueTagId: string }
  >
) =>
  useMutation<any, Error, { accountId: string; valueTagId: string }>({
    mutationKey: ["deleteValueTag"],
    mutationFn: async ({ accountId, valueTagId }) => {
      const response = await api.delete(`/${accountId}/valueTag/${valueTagId}`);
      return response.data;
    },
    ...props,
  });

export const useFetchValueTags = (accountId: string) => {
  return useQuery<ValueTag[]>({
    queryKey: ["fetchValueTags"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/valueTag`);
      return response.data;
    },
  });
};
