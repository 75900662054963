import { Box } from "@chakra-ui/react";

const WhiteBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      borderRadius={"md"}
      border={"1px"}
      borderColor={"gray.200"}
      bg={"white"}
      p={4}
      overflow={"auto"}
      w={"100%"}
    >
      {children}
    </Box>
  );
};

export default WhiteBox;
