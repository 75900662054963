import {
  Avatar,
  Box,
  Button,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { FormattedDate } from "react-intl";
import { useParams } from "react-router-dom";
import { RRule } from "rrule";
import { useUpdateOneOnOneMeeting } from "../../../api/oneOnOne/oneOnOneMeeting";
import HeartIcon from "../../../assets/icons/heart.svg?react";
import { AccountContext } from "../../../context/AccountContextComponent";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import { OneOnOneCalendarLinks } from "../OneOnOneCalendarLinks";
import { UpdateOneOnOneScheduleModal } from "../modals/UpdateOneOnOneScheduleModal";
import { MEETING_STATUSES, MeetingStatusField } from "./MeetingStatusField";
import { PrivateNote } from "./PrivateNote";
import { SharedNote } from "./SharedNote";

const ViewMeeting = ({ meetingData }) => {
  const { currentProfile } = useContext(AccountContext);
  const navigate = useCustomDomainNavigate();
  const { accountId } = useAccountId();

  let profileToShoutout = meetingData.creator;
  if (currentProfile.id === meetingData.creatorId) {
    profileToShoutout = meetingData.invitee;
  }

  return (
    <VStack gap={10} alignItems={"flex-start"} maxW={1000}>
      <Stack
        direction={{ base: "column", md: "row" }}
        justifyContent={"space-between"}
        w={"100%"}
      >
        <MeetingHeader meetingData={meetingData} />
        <NextMeeting meetingData={meetingData} />
      </Stack>
      <SharedNote meetingData={meetingData} />
      <Button
        onClick={() => {
          const encodedRecipient = encodeURIComponent(profileToShoutout.id);
          navigate(`/${accountId}/shoutouts?recipient=${encodedRecipient}`);
        }}
        leftIcon={<HeartIcon stroke={"#292D32"} />}
        variant={"outline"}
        alignSelf={"flex-end"}
      >
        Give a Shoutout to {profileToShoutout?.name?.split(" ")[0]}
      </Button>
      {currentProfile.id === meetingData.creatorId && (
        <PrivateNote privateNote={meetingData.creatorPrivateNote} />
      )}
    </VStack>
  );
};

const MeetingHeader = ({ meetingData }) => {
  const { meetingId } = useParams();
  const { accountId } = useAccountId();
  const updateMeetingMutation = useUpdateOneOnOneMeeting();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const { currentProfile } = useContext(AccountContext);

  const isCreator = meetingData.creatorId === currentProfile.id;

  const defaultValues = {
    status: meetingData.status,
  };

  const form = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const updateSharedNote = async (data) => {
    try {
      console.log(data);
      await updateMeetingMutation.mutateAsync({
        accountId,
        data: {
          ...data,
          status: data.status,
        },
        meetingId,
      });
      queryClient.invalidateQueries(["fetchOneOnOneMeeting", meetingId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating meeting" });
    }
  };

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  // Only submit if there are no errors
  const submitForm = (event) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateSharedNote)(event);
    }
  };

  // If meetingData is updated, update the form with the new status
  useEffect(() => {
    form.setValue("status", meetingData.status);
  }, [meetingData]);

  let profileToShow = meetingData.invitee;
  if (!isCreator) {
    profileToShow = meetingData.creator;
  }

  return (
    <Box
      bg={"white"}
      borderRadius={"md"}
      boxShadow={"sm"}
      border={"1px"}
      borderColor={"gray.200"}
      p={4}
      display={"flex"}
    >
      <VStack alignItems={"flex-start"}>
        <HStack spacing={2} alignItems={"center"}>
          <Text fontSize={"xl"} fontWeight={"bold"}>
            1-on-1 with
          </Text>
          <HStack>
            <Avatar
              size="md"
              src={profileToShow?.photoUrl}
              name={profileToShow?.name}
            />
            <VStack alignItems={"flex-start"} gap={0}>
              <Text fontSize={"lg"} fontWeight={"bold"}>
                {profileToShow?.name}
              </Text>
              <Text fontSize={"sm"} color={"textSecondary"}>
                {profileToShow?.title}
              </Text>
            </VStack>
          </HStack>
        </HStack>
        <Text fontSize={"md"} fontWeight={600} color={"textSecondary"}>
          <FormattedDate
            value={meetingData.startDate}
            month="short"
            day="2-digit"
            year="numeric"
            hour="numeric"
            minute="numeric"
          />
          {` (`}
          {meetingData.durationMinutes} minutes)
        </Text>
        <Box flex={1} />
        <Box>
          {isCreator ? (
            <FormProvider {...form}>
              <form
                onSubmit={handleSubmit(updateSharedNote)}
                onChange={debouncedSubmit}
              >
                <MeetingStatusField submit={debouncedSubmit} />
              </form>
            </FormProvider>
          ) : (
            <Text fontSize={"md"} fontWeight={600} color={"textSecondary"}>
              {MEETING_STATUSES[meetingData.status].label}
            </Text>
          )}
        </Box>
      </VStack>
    </Box>
  );
};

const NextMeeting = ({ meetingData }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const { currentProfile } = useContext(AccountContext);

  const isCreator = meetingData.creatorId === currentProfile.id;

  const getRecurrenceText = () => {
    if (
      !meetingData.oneOnOneSchedule.recurrence ||
      !meetingData.oneOnOneSchedule.recurrence.rrule
    ) {
      return "Does not repeat";
    }
    const rule = RRule.fromString(
      meetingData.oneOnOneSchedule.recurrence.rrule
    );
    return rule.toText();
  };

  const renderNextMeeting = () => {
    const nextMeeting = meetingData.nextMeeting;

    if (!nextMeeting) {
      return (
        <Text fontSize={"md"} fontWeight={600} color={"textSecondary"}>
          No next meeting scheduled
        </Text>
      );
    }

    return (
      <VStack alignItems={"flex-start"}>
        <Text fontSize={"xl"} fontWeight={"bold"}>
          Next 1-on-1 scheduled for
        </Text>
        <Text fontSize={"md"} fontWeight={600} color={"textSecondary"}>
          <FormattedDate
            value={nextMeeting.startDate}
            month="short"
            day="2-digit"
            year="numeric"
            hour="numeric"
            minute="numeric"
          />
        </Text>
        <Text fontSize={"md"} fontWeight={600} color={"textSecondary"}>
          ({getRecurrenceText()})
        </Text>
      </VStack>
    );
  };

  return (
    <Box
      bg={"white"}
      borderRadius={"md"}
      boxShadow={"sm"}
      border={"1px"}
      borderColor={"gray.200"}
      p={4}
    >
      <UpdateOneOnOneScheduleModal
        isOpen={showEditModal}
        setIsOpen={setShowEditModal}
        meetingData={meetingData}
      />
      {renderNextMeeting()}
      <Box pt={4} />
      <HStack spacing={4} alignItems={"center"}>
        <OneOnOneCalendarLinks
          oneOnOneSchedule={meetingData.oneOnOneSchedule}
          startDate={meetingData.startDate}
        />
        {isCreator && (
          <Button
            variant={"outline"}
            size={"sm"}
            onClick={() => setShowEditModal(true)}
            leftIcon={<BiPencil size={18} />}
          >
            Update Schedule
          </Button>
        )}
      </HStack>
    </Box>
  );
};

export default ViewMeeting;
