import { useMediaQuery } from "@chakra-ui/react";
import { useState } from "react";
import Joyride, { EVENTS, Placement, STATUS } from "react-joyride";
import WizardStepBottomLeft from "../Steps/WizardStepBottomLeft";
import WizardStepBottomRight from "../Steps/WizardStepBottomRight";
import WizardStepLeftStart from "../Steps/WizardStepLeftStart";
import WizardStepRightEnd from "../Steps/WizardStepRightEnd";
import WelcomeWizardScreen from "./WelcomeWizardScreen";

const defaultStepProps = {
  disableBeacon: true,
  disableOverlayClose: true,
  hideCloseButton: true,
  hideFooter: true,
};

const createWizardSteps = (
  incrementWizardStep: () => void,
  wizardName: string,
  isLargerThan1800: boolean
) => [
  {
    ...defaultStepProps,
    target: "#admin-dashboard",
    content: (
      <WizardStepRightEnd
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={
          "You're here—the Admin Dashboard is where it all comes together."
        }
        text={
          "As an Admin, this is your home base. Customize your Dashboard to give you a broad overview of your company's culture, then dive in for more detail."
        }
      />
    ),
    spotlightPadding: 6,
    floaterProps: {
      disableAnimation: true,
      placement: "right-end" as Placement,
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "650px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#CULTURE_SCORE",
    content: (
      <WizardStepBottomRight
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Keep your finger on the pulse"}
        text={
          "Your TBA Score updates daily, giving you a quick and up-to-date reading on company culture."
        }
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "bottom-start" as Placement,
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "700px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#CULTURE_SCORE_SERIES",
    content: (
      <WizardStepBottomRight
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Watch for trends"}
        text={"Watch for long term trends and focus on continuous improvement."}
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "bottom-start" as Placement,
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "700px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#COMPANY_OKRS",
    content: isLargerThan1800 ? (
      <WizardStepLeftStart
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Set a clear direction"}
        text={
          "Objectives and Key Results keep everyone aligned on what’s important."
        }
        boxStyles={{ width: "450px" }}
      />
    ) : (
      <WizardStepRightEnd
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Set a clear direction"}
        text={
          "Objectives and Key Results keep everyone aligned on what’s important."
        }
        boxStyles={{ width: "480px" }}
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: isLargerThan1800
        ? ("left-start" as Placement)
        : ("right-start" as Placement),
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "500px",
      },
    },
  },
  {
    ...defaultStepProps,
    // TODO this is assuming the first survey question latest widget is the eNPS one
    target: "#SURVEY_QUESTION_LATEST",
    content: (
      <WizardStepBottomRight
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Be aware of sentiment"}
        text={
          "Understand up-to-date employee sentiment with a quick Employee Net Promoter Score survey."
        }
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "bottom-start" as Placement,
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "700px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#ABC_RANKING_TIME_SERIES",
    content: (
      <WizardStepBottomRight
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Optimize your team"}
        text={
          "Are your employees aligned with your business needs? Empower your managers to monitor and report on trends in their team alignment over time."
        }
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "bottom-start" as Placement,
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "700px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#create-new-widget",
    content: (
      <WizardStepBottomLeft
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={". . . And much more!"}
        text={
          "Create additional widgets and drag-and-drop to customize your dashboard."
        }
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "bottom-end" as Placement,
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "700px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#profile-button",
    content: (
      <WizardStepBottomLeft
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Fine tune"}
        text={"User and Company Settings are up here."}
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "bottom-end" as Placement,
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "700px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#company-section",
    content: (
      <WizardStepRightEnd
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Next Steps"}
        text={
          "Employee facing tools are up here. Configure OKRs, schedule an event, create a survey, add Knowledge Base articles, or give a Shoutout up here."
        }
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "right-end" as Placement,
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "650px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#people-section",
    content: (
      <WizardStepRightEnd
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Next Steps"}
        text={"Import your roster, configure teams, view your org chart here."}
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "right-end" as Placement,
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "600px",
      },
    },
  },
  {
    ...defaultStepProps,
    target: "#culture-management-section",
    content: (
      <WizardStepRightEnd
        incrementWizardStep={incrementWizardStep}
        wizardName={wizardName}
        title={"Next Steps"}
        text={
          "Dig deeper into management tools, like Team Alignment, Culture Score, and Reports down here."
        }
        isLastStep={true}
      />
    ),
    floaterProps: {
      disableAnimation: true,
      placement: "right-end" as Placement,
      styles: {
        arrow: {
          length: 0,
        },
      },
    },
    styles: {
      options: {
        backgroundColor: "rgba(26, 32, 44, 0.94)",
        borderRadius: "10px",
        width: "600px",
      },
    },
  },
];

export const AdminDashboardWizard = ({
  wizardName,
  completeWizard,
}: {
  wizardName: string;
  completeWizard: ({ wizardName }: { wizardName: string }) => Promise<void>;
}) => {
  const [runWizard, setRunWizard] = useState(false);
  const [wizardStep, setWizardStep] = useState(0);
  const [isLargerThan1800] = useMediaQuery("(min-width: 1886px)");

  const incrementWizardStep = () => {
    setWizardStep(wizardStep + 1);
  };

  const startWizard = (event: any) => {
    event.preventDefault();
    setRunWizard(true);
  };

  const handleJoyrideCallback = async (data: any) => {
    const { status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      await completeWizard({ wizardName });
      setRunWizard(false);
    }
    // if the target is not found, increment the step
    else if (type === EVENTS.TARGET_NOT_FOUND) {
      incrementWizardStep();
    }
  };

  const wizardSteps = createWizardSteps(
    incrementWizardStep,
    wizardName,
    isLargerThan1800
  );

  return (
    <>
      {!runWizard && (
        <WelcomeWizardScreen
          wizardName={wizardName}
          startWizard={startWizard}
        />
      )}
      <Joyride
        steps={wizardSteps}
        callback={handleJoyrideCallback}
        run={runWizard}
        stepIndex={wizardStep}
      />
    </>
  );
};
