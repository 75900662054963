import { Button } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { useUpdateMyProfile } from "../../../api/profiles/profiles";
import { AccountContext } from "../../../context/AccountContextComponent";
import useAccountId from "../../../hooks/customDomainHooks";

export const SkipButton = ({ wizardName }: { wizardName: string }) => {
  const updateMyProfileMutation = useUpdateMyProfile();
  const { accountId } = useAccountId();
  const { currentProfile } = useContext(AccountContext);
  const queryClient = useQueryClient();

  const completeWizard = async () => {
    try {
      await updateMyProfileMutation.mutateAsync({
        accountId: accountId || "",
        data: {
          wizardsCompleted: [
            ...(currentProfile?.wizardsCompleted || []),
            wizardName,
          ],
        },
      });
      queryClient.invalidateQueries(["getMe"]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button
      variant={"outline"}
      borderColor={"white"}
      color={"white"}
      _hover={{ bg: "white", color: "black" }}
      onClick={completeWizard}
      isLoading={updateMyProfileMutation.isLoading}
    >
      Skip
    </Button>
  );
};
