import { Box, Spinner, Text } from "@chakra-ui/react";
import DonutChart from "../../../../components/common/charts/DonutChart";
import useAccountId from "../../../../hooks/customDomainHooks";
import SampleDataWidget from "./SampleDataWidget";
import useEmployeeTurnover from "../../../Reports/EmployeeTurnover/useEmployeeTurnover";
import { useContext, useMemo } from "react";
import { AccountContext } from "../../../../context/AccountContextComponent";

const EmployeeTurnoverByReasonWidget = ({ widgetData }) => {
  const { accountId } = useAccountId();
  const { accountData } = useContext(AccountContext);
  const dayRange = widgetData.data?.dayRange;

  const terminationTagOptions = useMemo(() => {
    return accountData?.terminationTags?.map((reason) => ({
      label: reason.label,
      value: reason.id,
    }));
  }, [accountData]);

  const startDate = useMemo(() => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - dayRange);
    return startDate.toISOString();
  }, [dayRange]);

  const {
    turnoverMetrics,
    isLoadingTurnoverMetrics,
    isErrorTurnoverMetrics,
    errorTurnoverMetrics,
    fetchStatus,
  } = useEmployeeTurnover(startDate);

  const turnoverByReasonForDonutChat = () => {
    const data = {};

    // Initialize data with termination reasons
    terminationTagOptions.forEach((terminationTag) => {
      data[terminationTag.label] = { count: 0 };
    });

    // Update counts based on turnover metrics
    turnoverMetrics.forEach((metric) => {
      if (data[metric.terminationTag?.label]) {
        data[metric.terminationTag?.label].count += 1;
      }
    });

    // Filter out tags with a count of 0 and replace tag keys with their labels
    const result = {};

    for (const key in data) {
      if (data[key].count > 0) {
        const label = terminationTagOptions.find(
          (terminationTag) => terminationTag.label === key
        )?.label;
        result[label] = data[key];
      }
    }

    return result;
  };

  const getDayRangeLabel = () => {
    if (dayRange < 180) {
      return `Past ${dayRange} days`;
    } else if (dayRange === 180) {
      return `Past 6 months`;
    } else if (dayRange === 365) {
      return `Past year`;
    } else {
      return `Past ${dayRange} days`;
    }
  };
  if (isLoadingTurnoverMetrics && fetchStatus !== "idle") return <Spinner />;
  if (isErrorTurnoverMetrics) {
    console.error(errorTurnoverMetrics);
    return <Text>Error loading Turnover metrics!</Text>;
  }
  if (!turnoverMetrics || turnoverMetrics.length === 0) {
    return (
      <SampleDataWidget
        imageUrl={"employee-turnover-widget.png"}
        text={"This chart will update at the start of next quarter."}
        width={346}
        height={310}
        accountId={accountId}
      />
    );
  }

  return (
    <Box w={"100%"}>
      <Text fontSize={"sm"} textAlign={"center"}>
        {getDayRangeLabel()}
      </Text>
      <DonutChart
        data={turnoverByReasonForDonutChat()}
        useDefaultColors={true}
      />
    </Box>
  );
};

export default EmployeeTurnoverByReasonWidget;
