import { Box, Center, Text, VStack } from "@chakra-ui/react";

const SampleDataWidget = ({
  imageUrl: imageName,
  text = "This is sample data and will be replaced when actual data is available.",
  width = "100%",
  height,
  isClickable = false,
  accountId,
  navigate,
  borderRadius = "none",
  boxShadow = undefined,
  textPositionHeight = "100%",
  imagePosition = "top left"
}) => {
  // Using a base URL from an environment variable and appending the imageUrl
  const bgImageUrl = `${
    import.meta.env.VITE_BASE_SAMPLE_DATA_URL
  }/${imageName}`;

  return (
    <Box
      bg={`url('${bgImageUrl}') no-repeat center/cover`}
      bgPosition={imagePosition}
      p={10}
      w={width}
      h={height}
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      cursor={isClickable ? "pointer" : "default"}
      onClick={() => {
        isClickable && navigate && navigate(`/${accountId}/culture-score`);
      }}
    >
      <Center h={textPositionHeight}>
        <VStack spacing={4}>
          <Box
            backgroundColor="rgba(255,255,255,0.8)"
            border={"1px"}
            borderColor={"gray.400"}
            p={4}
            borderRadius={"md"}
            w={"80%"}
          >
            <Text color={"black"}>{text}</Text>
          </Box>
        </VStack>
      </Center>
    </Box>
  );
};

export default SampleDataWidget;
