import { Box, Center, Link, Spinner, Text } from "@chakra-ui/react";
import {
  useFetchLatestQuestionFamilyResponseMetrics,
  useFetchLatestSingleQuestionSurveyResponseMetrics,
} from "../../../../api/survey/questionFamily";
import { useEffect } from "react";
import {
  LinearScaleChart,
  NPSChart,
  NumericChart,
  StaticValuesChart,
  TextValues,
} from "./WidgetCharts";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../../../hooks/customDomainHooks";
import SampleDataWidget from "./SampleDataWidget";

const QuestionResponsesChart = ({
  questionMetrics,
  widgetData,
  updateAdminWidget,
}) => {
  return (
    <Box w={"100%"} h={"200px"}>
      <Text m={2} noOfLines={2}>
        {questionMetrics.question?.prompt}
      </Text>
      {questionMetrics.responses?.length > 0 &&
        ["CHECKBOX", "RADIO", "DROPDOWN"].includes(
          questionMetrics.question?.element?.type
        ) && <StaticValuesChart responses={questionMetrics.responses} />}
      {questionMetrics.responses?.length > 0 &&
        ["TEXT"].includes(questionMetrics.question?.element?.type) && (
          <TextValues responses={questionMetrics.responses} />
        )}
      {questionMetrics.responses?.length > 0 &&
        ["LINEAR_SCALE"].includes(questionMetrics.question?.element?.type) && (
          <LinearScaleChart
            responses={questionMetrics.responses}
            question={questionMetrics.question}
          />
        )}
      {questionMetrics.responses?.length > 0 &&
        ["NUMBER"].includes(questionMetrics.question?.element?.type) && (
          <NumericChart
            responses={questionMetrics.responses}
            question={questionMetrics.question}
          />
        )}
      {questionMetrics.responses?.length > 0 &&
        ["NPS"].includes(questionMetrics.question?.element?.type) && (
          <NPSChart
            responses={questionMetrics.responses}
            question={questionMetrics.question}
            widgetData={widgetData}
            updateAdminWidget={updateAdminWidget}
          />
        )}
      {!questionMetrics.responses?.length && (
        <Center h={"100%"}>
          <Text align={"center"} color={"gray.500"}>
            No responses yet for Survey Run: {questionMetrics.surveyRun.label}
          </Text>
        </Center>
      )}
    </Box>
  );
};

export const LatestSurveyQuestionResponsesWidget = ({
  widgetData,
  setWidgetTitle,
  updateAdminWidget,
}) => {
  const { accountId } = useAccountId();
  const {
    data: questionMetrics,
    isLoading: isLoadingQuestionMetrics,
    isError: isErrorQuestionMetrics,
    error: errorQuestionMetrics,
  } = useFetchLatestQuestionFamilyResponseMetrics(
    accountId,
    widgetData.data?.familyId
  );
  const navigate = useCustomDomainNavigate();

  const isEnpsQuestion = questionMetrics?.question?.element?.type === "NPS";

  useEffect(() => {
    if (questionMetrics?.surveyRun?.label) {
      setWidgetTitle(
        <Link
          color={"textPrimary"}
          onClick={() => {
            navigate(
              `/${accountId}/surveys/${questionMetrics.surveyRun?.surveyId}/update`
            );
          }}
        >
          <Text fontWeight={"bold"} noOfLines={1} align={"center"}>
            {questionMetrics.surveyRun?.survey?.title}
          </Text>
          <Text fontWeight={"normal"} noOfLines={1} align={"center"}>
            {questionMetrics.surveyRun?.label}
          </Text>
        </Link>
      );
    } else {
      setWidgetTitle(
        <Link
          color={"textPrimary"}
          onClick={() => {
            navigate(
              `/${accountId}/surveys/${widgetData?.data?.surveyId}/update?tab=Run`
            );
          }}
          fontWeight={"bold"}
        >
          {isEnpsQuestion ? "eNPS Survey Results" : "Survey Results"}
        </Link>
      );
    }
  }, [questionMetrics, setWidgetTitle]);

  if (isLoadingQuestionMetrics) return <Spinner />;
  if (isErrorQuestionMetrics) {
    console.error(errorQuestionMetrics);
    return <Text>Error loading survey response metrics!</Text>;
  }
  if (!questionMetrics || questionMetrics.message) {
    if (widgetData.data.showDetails && widgetData.data.widthSize === "MEDIUM") {
      return (
        <SampleDataWidget
          imageUrl={"enps-widget.png"}
          text={"eNPS Data will display after your first survey run."}
          width={720}
          height={280}
          accountId={accountId}
        />
      );
    } else {
      return (
        <Center h={"200px"}>
          <Text align={"center"} color={"gray.500"}>
            No survey results to show yet or the survey run is still accepting
            responses.
          </Text>
        </Center>
      );
    }
  }
  return (
    <QuestionResponsesChart
      questionMetrics={questionMetrics}
      widgetData={widgetData}
      updateAdminWidget={updateAdminWidget}
    />
  );
};

export const LatestSingleQuestionSurveyResponsesWidget = ({
  widgetData,
  setWidgetTitle,
  updateAdminWidget,
}) => {
  const { accountId } = useAccountId();
  const {
    data: questionMetrics,
    isLoading: isLoadingQuestionMetrics,
    isError: isErrorQuestionMetrics,
    error: errorQuestionMetrics,
  } = useFetchLatestSingleQuestionSurveyResponseMetrics(
    accountId,
    widgetData.data?.surveyId
  );
  const navigate = useCustomDomainNavigate();

  const isEnpsQuestion = questionMetrics?.question?.element?.type === "NPS";

  useEffect(() => {
    if (questionMetrics?.surveyRun?.label) {
      setWidgetTitle(
        <Link
          color={"textPrimary"}
          onClick={() => {
            navigate(
              `/${accountId}/surveys/${questionMetrics.surveyRun?.surveyId}/update`
            );
          }}
        >
          <Text fontWeight={"bold"} noOfLines={1} align={"center"}>
            {questionMetrics.surveyRun?.survey?.title}
          </Text>
          <Text fontWeight={"normal"} noOfLines={1} align={"center"}>
            {questionMetrics.surveyRun?.label}
          </Text>
        </Link>
      );
    } else {
      setWidgetTitle(
        <Link
          color={"textPrimary"}
          onClick={() => {
            navigate(
              `/${accountId}/surveys/${widgetData?.data?.surveyId}/update?tab=Run`
            );
          }}
          fontWeight={"bold"}
        >
          {isEnpsQuestion ? "eNPS Survey Results" : "Survey Results"}
        </Link>
      );
    }
  }, [questionMetrics, setWidgetTitle]);

  if (isLoadingQuestionMetrics) return <Spinner />;
  if (isErrorQuestionMetrics) {
    console.error(errorQuestionMetrics);
    return <Text>Error loading survey response metrics!</Text>;
  }
  if (!questionMetrics || questionMetrics.message) {
    return (
      <SampleDataWidget
        imageUrl={"single-question-survey-latest-widget.png"}
        text={"Survey result will display after your first survey run."}
        width={330}
        height={310}
        accountId={accountId}
      />
    );
    // return (
    //   <Center h={"200px"}>
    //     <Text align={"center"} color={"gray.500"}>
    //       No survey results to show yet or the survey run is still accepting
    //       responses.
    //     </Text>
    //   </Center>
    // );
  }
  return (
    <QuestionResponsesChart
      questionMetrics={questionMetrics}
      widgetData={widgetData}
      updateAdminWidget={updateAdminWidget}
    />
  );
};
