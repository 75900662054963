import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiCaretDown, BiCheck } from "react-icons/bi";
import {
  useCreateReportViewableBy,
  useDeleteReportViewableBy,
  useUpdateReportViewableBy,
} from "../../api/abcRankings/reportViewableBy";
import { useFetchAccount } from "../../api/accounts/accounts";
import { AvatarWithName } from "../../components/common/AvatarWithName";
import { SelectField } from "../../components/fields/SelectField";
import useAccountId from "../../hooks/customDomainHooks";
import { useToastMessage } from "../../hooks/useToastMessage";

const EditReportReviewers = (props) => {
  const { rankingReport, canManageReviewers, canManageViewers } = props;
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const createReportViewableByMutation = useCreateReportViewableBy();
  const queryClient = useQueryClient();
  const { data: accountData } = useFetchAccount(accountId || "");

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      profileId: "",
    },
  });

  const { handleSubmit, setValue } = form;

  const createViewableBy = async (profileId) => {
    try {
      console.log("Creating viewable by:");
      await createReportViewableByMutation.mutateAsync({
        accountId,
        data: {
          profileId: profileId,
          canReview: false,
          abcRankingReportId: rankingReport.id,
        },
      });
      queryClient.invalidateQueries([
        "fetchAbcRankingReport",
        rankingReport.id,
      ]);
    } catch (error) {
      console.log(error);
      showErrorToast({
        message: "Error adding viewer to Team Alignment report",
      });
    }
  };

  const onSubmit = async (data) => {
    await createViewableBy(data);
  };

  const profileOptionsToShow = useMemo(() => {
    if (!accountData) return [];
    const currentViewableProfileIds = rankingReport.viewableBy.map(
      (viewableBy) => viewableBy.profile.id
    );
    const profilesToShow = accountData.profiles.filter((profile) => {
      return (
        profile.isActive &&
        !currentViewableProfileIds.includes(profile.id) &&
        profile.id !== rankingReport.managerId
      );
    });
    return profilesToShow.map((profile) => ({
      value: profile.id,
      label: profile.name,
    }));
  }, [rankingReport.viewableBy, accountData.profiles]);

  return (
    <>
      {/* Create Viewer Form  */}
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <SelectField
              field={{
                placeholder: "Add people",
                id: "profileId",
              }}
              options={profileOptionsToShow}
              onSelectionChange={async (value) => {
                console.log("Selected value:", value);
                if (!value) return;
                await createViewableBy(value?.value);
                setValue("profileId", "", { shouldValidate: true });
              }}
              isLoading={createReportViewableByMutation.isLoading}
              isClearable={true}
            />
          </VStack>
        </form>
      </FormProvider>
      {/* Edit Viewers */}
      {rankingReport && (
        <VStack spacing={4} pt={4}>
          <Text alignSelf={"flex-start"} fontWeight={"bold"}>
            People that can view this report
          </Text>
          <VStack spacing={0} w={"100%"}>
            <HStack
              justifyContent={"space-between"}
              w={"100%"}
              _hover={{ bg: "gray.100" }}
              p={4}
            >
              <AvatarWithName profile={rankingReport.manager} />
              <Text px={"16px"}>Manager</Text>
            </HStack>
            {rankingReport.viewableBy.map((viewer) => (
              <HStack
                key={viewer.profile.id}
                justifyContent={"space-between"}
                w={"100%"}
                _hover={{ bg: "gray.100" }}
                p={4}
              >
                <Text>{viewer.profile.name}</Text>
                <ViewablyByMenu
                  viewableBy={viewer}
                  rankingReport={rankingReport}
                  canManageViewers={canManageViewers}
                  canManageReviewers={canManageReviewers}
                />
              </HStack>
            ))}
          </VStack>
        </VStack>
      )}
    </>
  );
};

const ViewablyByMenu = ({
  viewableBy,
  rankingReport,
  canManageReviewers,
  canManageViewers,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    viewableBy.canReview ? "Reviewer" : "Viewer"
  );
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const deleteReportViewableByMutation = useDeleteReportViewableBy();
  const updateReportViewableByMutation = useUpdateReportViewableBy();
  const queryClient = useQueryClient();

  const updateViewableBy = async (data) => {
    try {
      await updateReportViewableByMutation.mutateAsync({
        accountId,
        viewableById: viewableBy.id,
        data,
      });
      queryClient.invalidateQueries([
        "fetchAbcRankingReport",
        rankingReport.id,
      ]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating viewer" });
    }
  };

  const deleteViewableBy = async () => {
    try {
      await deleteReportViewableByMutation.mutateAsync({
        accountId,
        viewableById: viewableBy.id,
      });
      queryClient.invalidateQueries([
        "fetchAbcRankingReport",
        rankingReport.id,
      ]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error removing viewer" });
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    if (option === "Reviewer") {
      updateViewableBy({ canReview: true });
    }
    if (option === "Viewer") {
      updateViewableBy({ canReview: false });
    }
  };

  return (
    <Box>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<BiCaretDown />}
          variant={"ghost"}
          isLoading={
            updateReportViewableByMutation.isLoading ||
            deleteReportViewableByMutation.isLoading
          }
          size={"sm"}
        >
          {selectedOption}
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => handleOptionSelect("Viewer")}
            icon={
              selectedOption === "Viewer" ? (
                <Box w={"18px"}>
                  <BiCheck fontSize={"18px"} />
                </Box>
              ) : (
                <Box w={"18px"} />
              )
            }
            isDisabled={!canManageReviewers || !canManageViewers}
          >
            Viewer
          </MenuItem>
          <MenuItem
            onClick={() => handleOptionSelect("Reviewer")}
            icon={
              selectedOption === "Reviewer" ? (
                <Box w={"18px"}>
                  <BiCheck fontSize={"18px"} />
                </Box>
              ) : (
                <Box w={"18px"} />
              )
            }
            isDisabled={!canManageReviewers}
          >
            Reviewer
          </MenuItem>
          <MenuDivider />
          <MenuItem
            onClick={deleteViewableBy}
            isDisabled={
              (viewableBy.canReview && !canManageReviewers) || !canManageViewers
            }
          >
            Remove Access
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default EditReportReviewers;
