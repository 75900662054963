import { Editor } from "@tiptap/core";

export const getPrevText = (
  editor: Editor,
  { chars, offset = 0 }: { chars: number; offset?: number }
) => {
  return editor.state.doc.textBetween(
    Math.max(0, editor.state.selection.from - chars),
    editor.state.selection.from - offset,
    "\n"
  );
};

export const getAfterText = (
  editor: Editor,
  { chars, offset = 0 }: { chars: number; offset?: number }
) => {
  console.log("Selection", editor.state.selection);
  console.log("From", editor.state.selection.from);
  console.log("To", editor.state.selection.to);
  return editor.state.doc.textBetween(
    editor.state.selection.to + offset,
    Math.min(editor.state.selection.to + chars, editor.state.doc.nodeSize - 2),
    "\n"
  );
};
