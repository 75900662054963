import { Box, Text } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import CustomTable from "../../../components/table/CustomTableV2";
import { AccountContext } from "../../../context/AccountContextComponent";
import { allTenures } from "../../../constants";

export const EnpsFullDataTable = ({ enpsMetrics, graphType }) => {
  const { accountData, aRankLabel, bRankLabel, cRankLabel } =
    useContext(AccountContext);
  const { departments } = accountData;

  console.log("enpsMetrics", enpsMetrics);

  const createColumn = (headerText, accessorString, textAlign = "end") => ({
    Header: () => (
      <Box w="100%">
        <Text textAlign={textAlign}>{headerText}</Text>
      </Box>
    ),
    accessor: accessorString,
    Cell: ({ value }) => <Text textAlign={textAlign}>{value}</Text>,
  });

  const baseColumns = [
    createColumn("Survey Run Date", "label", "left"),
    createColumn("Survey", "survey.title", "left"),
    createColumn("Survey Run", "surveyRun.label", "left"),
    createColumn(graphType !== "all" ? "All" : "Score", "All", "center"),
  ];

  const addColumnsByType = (type, items, scoreKey) => {
    items.forEach((item) => {
      const header = type === "byTenure" ? item.label : item.name;
      const accessor = `${scoreKey}.${header}`;
      baseColumns.push(createColumn(header, accessor));
    });
  };

  if (graphType === "byRank") {
    baseColumns.push(createColumn(aRankLabel, `rankingScores.A`));
    baseColumns.push(createColumn(bRankLabel, `rankingScores.B`));
    baseColumns.push(createColumn(cRankLabel, `rankingScores.C`));
  } else if (graphType === "byTenure") {
    addColumnsByType("byTenure", allTenures, "tenureScores");
  } else if (graphType === "byDepartment") {
    addColumnsByType("byDepartment", departments, "departmentScores");
  }

  const dataColumns = useMemo(
    () => baseColumns,
    [enpsMetrics, graphType, departments]
  );

  const getCsvMapping = () => {
    const csvMapping = {
      "Survey Run Date": "label",
      All: "All",
      Survey: "survey.title",
      "Survey Run": "surveyRun.label",
    };

    const addCsvMapping = (items, scoreKey) => {
      items.forEach((item) => {
        const key = item.label || item.name;
        csvMapping[key] = `${scoreKey}.${key}`;
      });
    };

    if (graphType === "byRank") {
      csvMapping[aRankLabel] = `rankingScores.A`;
      csvMapping[bRankLabel] = `rankingScores.B`;
      csvMapping[cRankLabel] = `rankingScores.C`;
    } else if (graphType === "byTenure") {
      addCsvMapping(allTenures, "tenureScores");
    } else if (graphType === "byDepartment") {
      addCsvMapping(departments, "departmentScores");
    }

    return csvMapping;
  };

  return (
    <Box w="100%">
      <CustomTable
        columns={dataColumns}
        data={enpsMetrics || []}
        initialState={{
          sortBy: [{ id: "name", desc: false }],
        }}
        showSearchBar={true}
        showDownloadLink={true}
        csvFileName="enps.csv" // Simplified file naming. Adjust as needed.
        csvMapping={getCsvMapping()}
        doUsePagination={true}
        selectionType={"none"}
        boxStyle={{
          bg: "white",
          borderRadius: "10px",
          border: "1px",
          borderColor: "gray.200",
          p: 4,
          overflow: "auto",
        }}
        tableStyle={{
          size: "md",
        }}
        tableId={"enpsFullDataTable"}
      />
    </Box>
  );
};
