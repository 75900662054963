import { useParams } from "react-router-dom";
import PageShell from "../../../layout/PageShell";
import LoadingBox from "../../../components/common/LoadingBox";
import ViewMeeting from "./ViewMeeting";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../../hooks/customDomainHooks";
import { useFetchOneOnOneMeeting } from "../../../api/oneOnOne/oneOnOneMeeting";
import { AccountContext } from "../../../context/AccountContextComponent";
import { useContext } from "react";
import { MeetingMenu } from "./MeetingMenu";
import { Box, Tag } from "@chakra-ui/react";

const ViewMeetingPage = () => {
  const { meetingId } = useParams();
  const { accountId } = useAccountId();
  const { currentProfile } = useContext(AccountContext);

  const {
    data: meetingData,
    isLoading: isLoadingMeeting,
    isError: isErrorFetchingMeeting,
    error: errorFetchingMeeting,
  } = useFetchOneOnOneMeeting(accountId, meetingId);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "1-on-1s", href: `/${accountId}/one-on-ones` },
    { name: "Notes" },
  ]);

  if (isLoadingMeeting) {
    return <LoadingBox />;
  }
  if (isErrorFetchingMeeting) {
    return <p>{errorFetchingMeeting.message}</p>;
  }
  if (!meetingData) {
    return <p>Meeting not found</p>;
  }

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      headerComponentRight={
        currentProfile.id === meetingData?.creatorId && (
          <MeetingMenu
            meetingId={meetingId}
            canDelete={true}
            isArchived={meetingData.isArchived}
          />
        )
      }
      headerComponent={
        meetingData?.isArchived && (
          <Box alignSelf={"flex-end"} pl={4}>
            <Tag ml={4} colorScheme="red">
              Deleted
            </Tag>
          </Box>
        )
      }
      maxWidth={1000}
    >
      <ViewMeeting meetingData={meetingData} />
    </PageShell>
  );
};

export default ViewMeetingPage;
