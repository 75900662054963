import { NavigateOptions, useNavigate, useParams } from "react-router-dom";

/**
 * Determine if we're using a custom domain and extract the account ID
 * This Hook uses the useParams() hook from react-router-dom and can
 * only be used after routes are setup
 *
 * @returns {object} { customDomain: bool, accountId: string }
 */
export default function useAccountId() {
  const { accountId } = useParams();
  const { customDomain, domainAccountId } = useCustomDomainAccountId();
  const calculatedAccountId = customDomain ? domainAccountId : accountId;
  return {
    customDomain,
    accountId: calculatedAccountId,
  };
}

/**
 * Determine if we're using a custom domain and extract the account ID
 * This Hook is safe for use before routes are setup
 *
 * @returns {object} { customDomain: bool, domainAccountId: string }
 */
export function useCustomDomainAccountId() {
  // Check hostname first then fall back to params
  const base_domain = `${import.meta.env.VITE_CUSTOM_BASE_DOMAIN}`;
  const hostname = window.location.hostname;
  const hostnameParts = hostname.split(".");
  const baseParts = base_domain.split(".");
  if (
    hostname.endsWith(base_domain) &&
    hostnameParts.length === baseParts.length + 1 &&
    hostnameParts[0] !== "app"
  ) {
    return {
      customDomain: true,
      domainAccountId: hostnameParts[0],
    };
  } else {
    return {
      customDomain: false,
      domainAccountId: null,
    };
  }
}

interface Breadcrumb {
  type?: string;
  href?: string;
  name?: string;
}

/**
 * Generates breadcrumbs by optionally stripping the account ID from the `href` attributes, based on the use of a custom domain.
 * Utilizes the `useCustomDomainAccountId` hook to determine if a custom domain is being used, and if so, removes the account ID from the `href` attributes.
 *
 * @param {Array} breadcrumbs - An array of breadcrumb objects. Each object may contain a `type` and `href`, among other properties.
 * @returns {Array} An array of breadcrumb objects, with the `href` attributes modified as needed based on the use of a custom domain.
 *
 * @example
 * const originalBreadcrumbs = [
 *   { type: "HOME", href: `/${accountId}/home` },
 *   { name: "People" },
 * ];
 * const modifiedBreadcrumbs = useBreadcrumbsWithCustomDomain(originalBreadcrumbs);
 */
export function useBreadcrumbsWithCustomDomain(breadcrumbs: Breadcrumb[]) {
  const { customDomain, domainAccountId } = useCustomDomainAccountId();

  return breadcrumbs.map((breadcrumb) => {
    if (customDomain && breadcrumb.href) {
      // Replace the accountId in the href with an empty string
      const modifiedHref = breadcrumb.href.replace(`/${domainAccountId}`, "");
      return { ...breadcrumb, href: modifiedHref };
    }
    return breadcrumb;
  });
}

/**
 * Custom hook that wraps React Router's `useNavigate`, providing navigation functionality
 * that optionally strips the account ID from the path based on the use of a custom domain.
 * Utilizes the `useCustomDomainAccountId` hook to determine if a custom domain is being
 * used. If a custom domain is detected, the account ID is removed from the path before navigation.
 *
 * @returns {function} A navigation function that takes the same parameters as React Router's
 * `navigate` function, with the path modified as needed based on the use of a custom domain.
 *
 * @example
 * const navigate = useCustomDomainNavigate();
 * navigate(`/${accountId}/blah/${blah.id}/view`);
 */
export function useCustomDomainNavigate() {
  const navigate = useNavigate();
  const { customDomain, domainAccountId } = useCustomDomainAccountId();

  // Return a function that takes a path and optionally strips the accountId
  return (path: string, ...rest: NavigateOptions[]) => {
    let modifiedPath = path;
    if (customDomain && domainAccountId) {
      modifiedPath = path.replace(`/${domainAccountId}`, "");
    }
    navigate(modifiedPath, ...rest);
  };
}
