import PageShell from "../../../layout/PageShell";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../../hooks/customDomainHooks";
import EmployeeTenureReport from "./EmployeeTenureReport";

const EmployeeTenureReportPage = () => {
  const { accountId } = useAccountId();
  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Reports", href: `/${accountId}/reports` },
    { name: "Employee Tenure" },
  ]);

  return (
    <PageShell breadcrumbs={breadcrumbs}>
      <EmployeeTenureReport />
    </PageShell>
  );
};

export default EmployeeTenureReportPage;
