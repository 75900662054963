import {
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  VStack,
  Link,
  usePopoverContext,
} from "@chakra-ui/react";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import { useQueryClient } from "@tanstack/react-query";
import { useUpdateObjective } from "../../../api/okr/objective";
import { VISIBILITIES } from "../common";
import { useState } from "react";

const ObjectiveVisibility = ({ objective, canEditObjective }) => {
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const updateObjectiveMutation = useUpdateObjective();
  const [isSaving, setIsSaving] = useState(false);

  const updateObjective = async (newVisibility) => {
    try {
      setIsSaving(true);
      await updateObjectiveMutation.mutateAsync({
        accountId,
        objectiveId: objective.id,
        data: {
          visibility: newVisibility,
        },
      });
      await queryClient.invalidateQueries(["fetchObjectives", accountId]);
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      console.log(error);
      showErrorToast({ message: "Error updating objective" });
    }
  };

  const VisibilityLinks = () => {
    const { onClose } = usePopoverContext();

    const handleLinkClick = async (visibility) => {
      onClose();
      await updateObjective(visibility);
    };

    return (
      <VStack alignItems={"center"} textAlign={"center"} fontWeight={700}>
        {objective.visibility !== "DRAFT" && (
          <Link
            onClick={() => handleLinkClick("DRAFT")}
            color={VISIBILITIES["DRAFT"].color}
          >
            Draft
          </Link>
        )}
        {objective.visibility !== "PUBLIC" && (
          <Link
            onClick={() => handleLinkClick("PUBLIC")}
            color={VISIBILITIES["PUBLIC"].color}
          >
            Public
          </Link>
        )}
        {objective.visibility !== "PRIVATE" && (
          <Link
            onClick={() => handleLinkClick("PRIVATE")}
            color={VISIBILITIES["PRIVATE"].color}
          >
            Private
          </Link>
        )}
      </VStack>
    );
  };

  return (
    <Box>
      <Popover isOpen={canEditObjective ? undefined : false}>
        <PopoverTrigger>
          <Button
            isLoading={isSaving}
            variant="ghost"
            _hover={{ textDecoration: canEditObjective ? "underline" : "none" }}
            cursor={canEditObjective ? "pointer" : "default"}
            _active={{ bg: "none" }}
            p={0}
            m={0}
            color={VISIBILITIES[objective.visibility].color}
          >
            ({VISIBILITIES[objective.visibility].label})
          </Button>
        </PopoverTrigger>
        <PopoverContent
          style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
          borderRadius={"10px"}
          w={"110px"}
          p={3}
        >
          <PopoverArrow />
          <PopoverBody>
            <VisibilityLinks />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default ObjectiveVisibility;
