import { useQuery, useMutation } from "@tanstack/react-query";
import {  UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";
import api from "../config";
import {  Event } from "./types";

type MutationProps = Omit<UseMutationOptions<any, unknown, any, unknown>, 'mutationFn' | 'mutationKey'>;

type CreateEventVariables = { accountId: string; data: any };
type CloneEventVariables = { accountId: string; eventId: string };
type UpdateEventVariables = { accountId: string; eventId: string; data: any };
type DeleteEventVariables = { accountId: string; eventId: string };

export const useCreateEvent = (props?: MutationProps) =>
  useMutation<Event, unknown, CreateEventVariables>({
    mutationKey: ["createEvent"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/event`, data);
      return response.data;
    },
    ...props,
  });

export const useCloneEvent = (props?: MutationProps) =>
  useMutation<Event, unknown, CloneEventVariables>({
    mutationKey: ["cloneEvent"],
    mutationFn: async ({ accountId, eventId }) => {
      const response = await api.post(`/${accountId}/event/${eventId}/clone`);
      return response.data;
    },
    ...props,
  });

export const useUpdateEvent = (props?: MutationProps) =>
  useMutation<Event, unknown, UpdateEventVariables>({
    mutationKey: ["updateEvent"],
    mutationFn: async ({ accountId, eventId, data }) => {
      const response = await api.put(`/${accountId}/event/${eventId}`, data);
      return response.data;
    },
    ...props,
  });

export const useDeleteEvent = (props?: MutationProps) =>
  useMutation<void, unknown, DeleteEventVariables>({
    mutationKey: ["deleteEvent"],
    mutationFn: async ({ accountId, eventId }) => {
      const response = await api.delete(`/${accountId}/event/${eventId}`);
      return response.data;
    },
    ...props,
  });

export const useFetchUpcomingEvents = (accountId: string, options?: UseQueryOptions<Event[]>) => {
  return useQuery<Event[]>({
    queryKey: ["fetchUpcomingEvents"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/event/upcoming`);
      return response.data;
    },
    ...options,
  });
};

export const useFetchPastEvents = (accountId: string, options?: UseQueryOptions<Event[]>) => {
  return useQuery<Event[]>({
    queryKey: ["fetchPastEvents"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/event/past`);
      return response.data;
    },
    ...options,
  });
};

export const useFetchMyEvents = (accountId: string, options?: UseQueryOptions<Event[]>) => {
  return useQuery<Event[]>({
    queryKey: ["fetchMyEvents"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/event/signedup`);
      return response.data;
    },
    ...options,
  });
};

export const useFetchEvent = (accountId: string, eventId: string, options?: UseQueryOptions<Event>) => {
  return useQuery<Event>({
    queryKey: ["fetchEvent", eventId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/event/${eventId}`);
      return response.data;
    },
    ...options,
  });
};