import {
  useCreateObjective,
  useFetchObjectives,
} from "../../../api/okr/objective";
import useAccountId from "../../../hooks/customDomainHooks";
import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../../../context/AccountContextComponent";
import useToastMessage from "../../../hooks/useToastMessage";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../utils/localStorageHelper";

const useOKRs = (filterOwnerType, createOwnerType, defaultOrderBy) => {
  const { accountId } = useAccountId();
  const createObjectiveMutation = useCreateObjective();
  const { currentTimePeriod, nextTimePeriod, currentProfile } =
    useContext(AccountContext);
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  // Default time periods to filter are the current time period and the next time period
  // the next time period may be the same as the current time period, in which case
  // we don't want to show it twice
  const getDefaultTimePeriods = () => {
    if (currentTimePeriod && nextTimePeriod) {
      const res = [
        { value: currentTimePeriod.id, label: currentTimePeriod.label },
      ];
      if (nextTimePeriod.id !== currentTimePeriod.id) {
        res.push({ value: nextTimePeriod.id, label: nextTimePeriod.label });
      }
      return res;
    }
    return [];
  };

  const getInitialTimePeriods = () => {
    // Check local storage for time periods to filter for this tab
    // If null for this tab, use the default time periods
    const localStorageTimePeriodsToFilter = getLocalStorageItem(
      `Tab.${filterOwnerType}.TimePeriodsToFilter`
    );
    if (localStorageTimePeriodsToFilter) {
      return localStorageTimePeriodsToFilter;
    }
    return getDefaultTimePeriods();
  };

  const getInitialOwners = () => {
    // Check local storage for owners to filter for this tab
    // If null for this tab, use an empty array
    const localStorageOwnersToFilter = getLocalStorageItem(
      `Tab.${filterOwnerType}.OwnersToFilter`
    );
    if (localStorageOwnersToFilter) {
      return localStorageOwnersToFilter;
    }
    return [];
  };

  const [timePeriodsToFilter, setTimePeriodsToFilter] = useState(
    getInitialTimePeriods
  );
  const [ownersToFilter, setOwnersToFilter] = useState(getInitialOwners);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [onlyShowMyKrs, setOnlyShowMyKrs] = useState(false);
  const [isCreatingObjective, setIsCreatingObjective] = useState(false);
  const [newlyCreatedObjectiveId, setNewlyCreatedObjectiveId] = useState(null);

  const {
    data: objectiveSets,
    isLoading: isLoadingObjectives,
    isError: isErrorObjectives,
    error: errorObjectives,
  } = useFetchObjectives(
    accountId,
    filterOwnerType,
    orderBy,
    timePeriodsToFilter.map((tp) => tp.value),
    ownersToFilter.filter((o) => o.type === "profile").map((o) => o.value),
    ownersToFilter.filter((o) => o.type === "team").map((o) => o.value),
    ownersToFilter.filter((o) => o.type === "department").map((o) => o.value)
  );

  const [filteredObjectiveSets, setFilteredObjectiveSets] =
    useState(objectiveSets);

  // when timePeriodsToFilter changes, update local storage
  useEffect(() => {
    setLocalStorageItem(
      `Tab.${filterOwnerType}.TimePeriodsToFilter`,
      timePeriodsToFilter
    );
  }, [timePeriodsToFilter]);

  // When ownersToFilter changes, update local storage
  useEffect(() => {
    setLocalStorageItem(
      `Tab.${filterOwnerType}.OwnersToFilter`,
      ownersToFilter
    );
  }, [ownersToFilter]);

  useEffect(() => {
    if (objectiveSets) {
      let updatedObjectiveSets = [...objectiveSets];

      // Filter objectives and their KRs based on the onlyShowMyKrs value
      if (onlyShowMyKrs) {
        updatedObjectiveSets = filterMyKrs(updatedObjectiveSets);
      }

      setFilteredObjectiveSets(updatedObjectiveSets);
    }
  }, [objectiveSets, onlyShowMyKrs]);

  const filterMyKrs = (objectiveSets) => {
    return objectiveSets
      .map((obj) => {
        let newObjectives = obj.objectives
          .filter((o) =>
            o.keyResults.some((kr) => kr.ownerId === currentProfile.id)
          )
          .map((o) => ({
            ...o,
            keyResults: o.keyResults.filter(
              (kr) => kr.ownerId === currentProfile.id
            ),
          }));

        return { ...obj, objectives: newObjectives };
      })
      .filter((obj) => obj.objectives.length > 0);
  };

  const searchObjectives = (term) => {
    if (!objectiveSets) return;

    let results = [...objectiveSets];

    if (term) {
      const lowerCaseTerm = term.toLowerCase();
      results = results
        .map((objSet) => {
          // Filter the objectives based on the search term
          const matchedObjectives = objSet.objectives.filter(
            (obj) =>
              obj.title?.toLowerCase().includes(lowerCaseTerm) ||
              obj.keyResults?.some(
                (kr) =>
                  kr.title?.toLowerCase().includes(lowerCaseTerm) ||
                  kr.owner?.name?.toLowerCase().includes(lowerCaseTerm)
              )
          );

          // If the owner name matches or there are any matched objectives, include this objSet
          if (
            objSet.owner?.name?.toLowerCase().includes(lowerCaseTerm) ||
            matchedObjectives.length > 0
          ) {
            return {
              ...objSet,
              objectives: matchedObjectives,
            };
          }

          return null; // this objSet doesn't match the search criteria
        })
        .filter(Boolean); // Filter out null values (non-matching objective sets)
    }

    if (onlyShowMyKrs) {
      results = filterMyKrs(results);
    }

    setFilteredObjectiveSets(results);
  };

  const setQueryParam = (key, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);
    navigate(
      {
        ...location,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  };

  const createObjective = async (
    timePeriod = nextTimePeriod,
    ownerType = createOwnerType,
    index = undefined
  ) => {
    try {
      // Add next time period to the time periods to filter if it's not already there
      // this way the created objective will show up in the list
      if (!timePeriodsToFilter.some((tp) => tp.value === timePeriod.id)) {
        setTimePeriodsToFilter([
          ...timePeriodsToFilter,
          { value: timePeriod.id, label: timePeriod.label },
        ]);
      }
      setIsCreatingObjective(true);
      const resp = await createObjectiveMutation.mutateAsync({
        accountId,
        data: {
          index: index,
          title: "",
          ownerType: ownerType,
          timePeriodId: timePeriod.id,
        },
      });
      await queryClient.invalidateQueries(["fetchObjectives", accountId]);
      setIsCreatingObjective(false);
      // This will open the accordion of the newly created objective
      setNewlyCreatedObjectiveId(resp.id);
      // This will highlight the newly created objective and update it in the URL
      setQueryParam("objectiveId", resp.id);
    } catch (error) {
      setIsCreatingObjective(false);
      console.log(error);
      showErrorToast({ message: "Error creating Objective" });
    }
  };

  return {
    objectiveSets: filteredObjectiveSets,
    isLoadingObjectives,
    isErrorObjectives,
    errorObjectives,
    timePeriodsToFilter,
    setTimePeriodsToFilter,
    ownersToFilter,
    setOwnersToFilter,
    createObjective,
    searchObjectives,
    onlyShowMyKrs,
    setOnlyShowMyKrs,
    isCreatingObjective,
    newlyCreatedObjectiveId,
    setNewlyCreatedObjectiveId,
    setOrderBy,
    orderBy,
  };
};

export default useOKRs;
