import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

export const AreYouSureModal = ({
  isOpen,
  setIsOpen,
  actionFunction,
  isRunning,
  headerText,
  bodyText,
  buttonText,
}:{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  actionFunction: () => Promise<void>;
  isRunning: boolean;
  headerText?: string;
  bodyText: string;
  buttonText?: string;
}) => {


  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{headerText || "Are you sure?"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{bodyText}</ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            onClick={() => {
              setIsOpen(false);
            }}
            variant="outline"
          >
            Close
          </Button>
          <Button
            color={"red.600"}
            variant="outline"
            isLoading={isRunning}
            onClick={async () => {
              await actionFunction();
              setIsOpen(false);
            }}
          >
            {buttonText || "Yes"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
