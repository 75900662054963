import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { SurveyRunResponse } from "./types";

export const useFetchMySurveyRunResponse = (
  accountId: string,
  surveyRunId: string,
  profileId: string,
  options?: UseQueryOptions<SurveyRunResponse, Error>
) => {
  return useQuery<SurveyRunResponse, Error>({
    queryKey: ["fetchMySurveyRunResponse", accountId, surveyRunId, profileId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/surveyRun/${surveyRunId}/response`
      );
      return response.data;
    },
    ...options,
  });
};

export const useUpdateSurveyRunResponse = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId: string; surveyRunResponseId: string; data: any }
  >
) =>
  useMutation({
    mutationKey: ["updateSurveyRunResponse"],
    mutationFn: async ({ accountId, surveyRunResponseId, data }) => {
      const response = await api.put(
        `/${accountId}/surveyRunResponse/${surveyRunResponseId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useGetAllSurveyRunResponsesAsCsv = (
  props?: UseMutationOptions<
    string,
    Error,
    { contentType?: string; accountId: string; surveyRunId: string }
  >
) =>
  useMutation({
    mutationKey: ["fetchAllSurveyRunResponsesAsCsv"],
    mutationFn: async ({ contentType, accountId, surveyRunId }) => {
      const response = await api.get(
        `/${accountId}/surveyRun/${surveyRunId}/all-responses`,
        {
          headers: {
            Accept: contentType || "text/csv",
          },
        }
      );
      return response.data;
    },
    ...props,
  });

export const useGetAllSurveyRunResponses = (
  contentType: string,
  accountId: string,
  surveyRunId: string,
  departments: any[],
  tenuresToFilter: any[],
  ranksToFilter: any[],
  options?: UseQueryOptions<SurveyRunResponse[], Error>
) =>
  useQuery<SurveyRunResponse[], Error>({
    queryKey: [
      "fetchAllSurveyRunResponses",
      contentType,
      accountId,
      surveyRunId,
      departments,
      tenuresToFilter,
      ranksToFilter,
    ],
    enabled: !!surveyRunId,
    queryFn: async () => {
      const departmentQuery = departments
        ?.map((department) => `&department=${department.label}`)
        .join("&");

      const tenureQuery = tenuresToFilter
        ?.map((tenure) => `&tenure=${encodeURIComponent(tenure.label)}`)
        .join("&");

      const rankQuery = ranksToFilter
        ?.map((rank) => `&ranking=${rank.value}`)
        .join("&");

      const response = await api.get(
        `/${accountId}/surveyRun/${surveyRunId}/all-responses?${departmentQuery}${tenureQuery}${rankQuery}`,
        {
          headers: {
            Accept: contentType || "text/csv",
          },
        }
      );
      return response.data;
    },
    ...options,
  });

export const useGetEnpsResponses = (
  accountId: string,
  departments: any[],
  tenuresToFilter: any[],
  ranksToFilter: any[],
  options?: UseQueryOptions<SurveyRunResponse[], Error>
) =>
  useQuery<SurveyRunResponse[], Error>({
    queryKey: [
      "fetchEnpsResponses",
      accountId,
      departments,
      tenuresToFilter,
      ranksToFilter,
    ],
    queryFn: async () => {
      const departmentQuery = departments
        ?.map((department) => `&department=${department.label}`)
        .join("&");

      const tenureQuery = tenuresToFilter
        ?.map((tenure) => `&tenure=${encodeURIComponent(tenure.label)}`)
        .join("&");

      const rankQuery = ranksToFilter
        ?.map((rank) => `&ranking=${rank.value}`)
        .join("&");

      const response = await api.get(
        `/${accountId}/metrics/enps?${departmentQuery}${tenureQuery}${rankQuery}`
      );
      return response.data;
    },
    ...options,
  });
