import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import api from "../config";
import { QuestionResponse } from "./types";

export const useUpdateQuestionResponse = (
  props?: UseMutationOptions<
    QuestionResponse,
    unknown,
    {
      accountId: string;
      surveyRunResponseId: string;
      questionId: string;
      data: any;
    }
  >
) =>
  useMutation<
    QuestionResponse,
    unknown,
    {
      accountId: string;
      surveyRunResponseId: string;
      questionId: string;
      data: any;
    }
  >({
    mutationKey: ["updateQuestionResponse"],
    mutationFn: async ({
      accountId,
      surveyRunResponseId,
      questionId,
      data,
    }) => {
      const response = await api.put(
        `/${accountId}/surveyRunResponse/${surveyRunResponseId}/question/${questionId}`,
        data
      );
      return response.data;
    },
    ...props,
  });
