import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { OneOnOneMeeting } from "./types";

export const useFetchOneOnOneMeetings = (
  accountId: string,
  inviteeId?: string
) => {
  return useQuery<OneOnOneMeeting[]>({
    queryKey: ["fetchOneOnOneMeetings", inviteeId],
    queryFn: async () => {
      const inviteeQuery = inviteeId ? `inviteeId=${inviteeId}` : "";
      const response = await api.get(
        `/${accountId}/oneOnOneMeeting?${inviteeQuery}`
      );
      return response.data;
    },
  });
};

export const useFetchOneOnOneMeeting = (
  accountId: string,
  meetingId: string
) => {
  return useQuery<OneOnOneMeeting>({
    queryKey: ["fetchOneOnOneMeeting", meetingId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/oneOnOneMeeting/${meetingId}`
      );
      return response.data;
    },
  });
};

export const useUpdateOneOnOneMeeting = (
  props?: UseMutationOptions<
    any,
    unknown,
    { accountId: string; data: any; meetingId: string },
    unknown
  >
) =>
  useMutation({
    mutationKey: ["updateOneOnOneMeeting"],
    mutationFn: async ({ accountId, data, meetingId }) => {
      const response = await api.put(
        `/${accountId}/oneOnOneMeeting/${meetingId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useUpdateSharedNote = (
  props?: UseMutationOptions<
    any,
    unknown,
    { accountId: string; data: any; meetingId: string },
    unknown
  >
) =>
  useMutation({
    mutationKey: ["updateSharedNote"],
    mutationFn: async ({ accountId, data, meetingId }) => {
      const response = await api.put(
        `/${accountId}/oneOnOneMeeting/${meetingId}/sharedNote`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useUpdatePrivateNote = (
  props?: UseMutationOptions<
    any,
    unknown,
    { accountId: string; data: any; meetingId: string },
    unknown
  >
) =>
  useMutation({
    mutationKey: ["updatePrivateNote"],
    mutationFn: async ({ accountId, data, meetingId }) => {
      const response = await api.put(
        `/${accountId}/oneOnOneMeeting/${meetingId}/privateNote`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useDeleteOneOnOneMeeting = (
  props?: UseMutationOptions<
    any,
    Error,
    {
      accountId: string;
      meetingId: string;
      allMeetings?: boolean;
      andFollowingMeetings?: boolean;
    },
    unknown
  >
) =>
  useMutation({
    mutationKey: ["deleteOneOnOneMeeting"],
    mutationFn: async ({
      accountId,
      meetingId,
      allMeetings = false,
      andFollowingMeetings = false,
    }) => {
      const response = await api.delete(
        `/${accountId}/oneOnOneMeeting/${meetingId}?allMeetings=${allMeetings}&andFollowingMeetings=${andFollowingMeetings}`
      );
      return response.data;
    },
    ...props,
  });

export const useUnarchiveOneOnOneMeeting = (
  props?: UseMutationOptions<
    any,
    unknown,
    { accountId: string; meetingId: string },
    unknown
  >
) =>
  useMutation({
    mutationKey: ["unarchiveOneOnOneMeeting"],
    mutationFn: async ({ accountId, meetingId }) => {
      const response = await api.put(
        `/${accountId}/oneOnOneMeeting/${meetingId}/unarchive`
      );
      return response.data;
    },
    ...props,
  });
