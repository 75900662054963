import { Box } from "@chakra-ui/react";

export const WizardOverlay = ({
  children,
  bg,
}: {
  children: React.ReactNode;
  bg: string;
}) => {
  // This side effect sets overflow to hidden on the body element when the component mounts
  // and then removes the overflow style (or sets it back to the original style) when the component unmounts.
  // useEffect(() => {
  //   // Store the original overflow value to restore it later
  //   const originalStyle = window.getComputedStyle(document.body).overflow;
  //   // Prevent scrolling on mount
  //   document.body.style.overflow = "hidden";
  //   // Re-enable scrolling when component unmounts
  //   return () => (document.body.style.overflow = originalStyle);
  // }, []); // Empty dependency array ensures this runs once when component mounts and unmounts
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      backgroundColor={bg}
      zIndex={2}
      h={"100%"}
      minH={"1200px"}
    >
      {children}
    </Box>
  );
};
