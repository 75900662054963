import { Box, Button, Stack, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useCreateRankingPeriod } from "../../api/abcRankings/abcRankingPeriod";
import { DateField } from "../../components/fields/DateField";
import { SelectField } from "../../components/fields/SelectField";
import { SwitchField } from "../../components/fields/SwitchField";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId from "../../hooks/customDomainHooks";
import { useToastMessage } from "../../hooks/useToastMessage";
import { formatTimeperiodOptions } from "../OKRV2/formatters";

const CreateRankingPeriod = ({ onSuccess, usedTimePeriods }) => {
  const { accountId } = useAccountId();
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const createRankingPeriodMutation = useCreateRankingPeriod();
  const queryClient = useQueryClient();
  const { accountData } = useContext(AccountContext);

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      timePeriodId: "",
      dueDate: "",
      isOpen: true,
    },
  });

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = form;

  const createRankingPeriod = async (data) => {
    try {
      const respData = await createRankingPeriodMutation.mutateAsync({
        accountId: accountId || "",
        data,
      });
      console.log(respData);
      showSuccessToast(
        "Team Alignment Period Created",
        "The Team Alignment Period has been successfully created."
      );
      onSuccess();
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating Team Alignment Period." });
    }
  };

  const onSubmit = async (data) => {
    await createRankingPeriod(data);
    queryClient.invalidateQueries(["fetchAbcRankingPeriods"]);
    queryClient.invalidateQueries(["fetchAbcRankingReports"]);
  };

  const timePeriodOptions = useMemo(
    () =>
      formatTimeperiodOptions(
        accountData?.timePeriods.filter(
          (timePeriod) => !usedTimePeriods.includes(timePeriod.id)
        ),
        usedTimePeriods
      ),
    [accountData, usedTimePeriods]
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          <Stack
            w={"100%"}
            direction={{
              base: "column",
              sm: "row",
            }}
            gap={{
              base: 2,
              sm: 8,
            }}
          >
            <SelectField
              field={{
                label: "Period",
                id: "timePeriodId",
                validation: {
                  required: { value: true },
                  message: "Period is required",
                },
              }}
              options={timePeriodOptions}
              // accountData?.timePeriods
              // .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
              // .filter(
              //   (timePeriod) => !usedTimePeriods.includes(timePeriod.id)
              // )
              // .map((timePeriod) => ({
              //   value: timePeriod.id,
              //   label: timePeriod.label,
              // }))}
            />
            <SwitchField
              field={{
                label: "Open",
                id: `isOpen`,
                tooltipId: "teamAlignmentOpen",
              }}
            />
          </Stack>
          <Box maxW={"200px"} alignSelf={"flex-start"}>
            <DateField
              field={{
                id: `dueDate`,
                label: "Due Date",
                tooltipId: "teamAlignmentDue",
                validation: { required: true },
              }}
            />
          </Box>
          <Button
            type="submit"
            isLoading={isSubmitting}
            isDisabled={!isValid || isSubmitting}
          >
            Create Reporting Period
          </Button>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default CreateRankingPeriod;
