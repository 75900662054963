import { BubbleMenu, isNodeSelection } from "@tiptap/react";
import { useMemo, useRef, useEffect, forwardRef, useContext } from "react";
import type { BubbleMenuProps, Editor } from "@tiptap/react";
import type { ReactNode } from "react";
import type { Instance, Props } from "tippy.js";
import { TipTapAiContext } from "../../../../context/TipTapAiContextComponent";

export interface EditorBubbleProps extends Omit<BubbleMenuProps, "editor"> {
  readonly children: ReactNode;
  editor: Editor;
  openAiMenu?: boolean;
}

export const EditorBubble = forwardRef<HTMLDivElement, EditorBubbleProps>(
  ({ children, editor, openAiMenu, tippyOptions, ...rest }, ref) => {
    const instanceRef = useRef<Instance<Props> | null>(null);
    const { key } = useContext(TipTapAiContext); // "key" is a hack to force the menu to re-render

    useEffect(() => {
      if (!instanceRef.current || !tippyOptions?.placement) return;
      instanceRef.current.setProps({ placement: tippyOptions.placement });
      instanceRef.current.popperInstance?.update();
    }, [tippyOptions?.placement]);

    const bubbleMenuProps: Omit<BubbleMenuProps, "children"> = useMemo(
      () => ({
        shouldShow: ({ editor, state }) => {
          if (openAiMenu) {
            return true;
          }
          const { selection } = state;
          const { empty } = selection;
          if (
            !editor.isEditable ||
            editor.isActive("image") ||
            empty ||
            isNodeSelection(selection)
          ) {
            console.log(
              "shouldShow false",
              editor.isActive("image"),
              empty,
              isNodeSelection(selection)
            );
            return false;
          }
          return true;
        },
        tippyOptions: {
          onCreate: (val) => {
            instanceRef.current = val;
          },
          moveTransition: "transform 0.15s ease-out",
          ...tippyOptions,
        },
        ...rest,
      }),
      [openAiMenu, rest, tippyOptions]
    );

    if (!editor) return null;

    return (
      <div ref={ref} key={key}>
        <BubbleMenu editor={editor} {...bubbleMenuProps}>
          {children}
        </BubbleMenu>
      </div>
    );
  }
);

EditorBubble.displayName = "EditorBubble";
export default EditorBubble;
