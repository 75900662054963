import api from "../../../api/config";
import {
  useCreateAccountUploadUrl,
  useCreatePublicUploadUrl,
} from "../../../api/storage/storage";
import useToastMessage from "../../../hooks/useToastMessage";
import getCroppedImg from "../../../utils/cropImage";

function useUploadFile() {
  const createAccountUploadUrlMutation = useCreateAccountUploadUrl();
  const createPublicUploadUrlMutation = useCreatePublicUploadUrl();
  const { showErrorToast } = useToastMessage();

  const getPublicDownloadUrl = async (filename: string) => {
    const response = await api.get(`/storage/getPublicDownloadUrl/${filename}`);
    return response.data.downloadUrl;
  };

  const uploadImage = async ({
    file,
    accountId,
    croppedAreaPixels,
    allowCrop = false,
    isPublic = false,
  }: {
    file: File;
    accountId?: string;
    croppedAreaPixels?: any;
    allowCrop?: boolean;
    isPublic?: boolean;
  }) => {
    try {
      let preparedImage: BlobPart | Blob;
      if (allowCrop) {
        const image = URL.createObjectURL(file);
        preparedImage = await getCroppedImg(image, croppedAreaPixels);
      } else {
        preparedImage = file;
      }

      const fileType = file.type;
      const fileData = new File([preparedImage], "fileName", {
        type: fileType,
      });

      const uploadMutation = isPublic
        ? createPublicUploadUrlMutation
        : createAccountUploadUrlMutation;

      const uploadParams = isPublic
        ? { data: { fileType } }
        : { accountId: accountId || "", data: { fileType } };
      const resp = await uploadMutation.mutateAsync(uploadParams);

      await fetch(resp.uploadUrl, {
        method: "PUT",
        body: fileData,
      });

      if (isPublic) {
        const downloadUrl = await getPublicDownloadUrl(resp.fileName);
        return { fileName: resp.fileName, downloadUrl };
      }

      return resp;
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error uploading image" });
    }
  };

  return {
    uploadImage,
  };
}

export default useUploadFile;
