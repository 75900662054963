import { Stack } from "@chakra-ui/react";
import { SidebarItem, SidebarItemType } from "./SidebarItem";

export type SidebarSection = {
  id: string;
  items: SidebarItemType[];
};

export const SidebarSection = ({
  sidebarSection,
}: {
  sidebarSection: SidebarSection;
}) => {
  return (
    <Stack pb={2} spacing={0} id={sidebarSection.id}>
      {sidebarSection.items.map((sidebarItem, itemIndex) => (
        <SidebarItem sidebarItem={sidebarItem} key={itemIndex} />
      ))}
    </Stack>
  );
};
