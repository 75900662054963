import { Tag, Text } from "@chakra-ui/react";

const KbTag = ({ pageData }) => {
  return (
    <>
      {!pageData.isPublished ? (
        <Tag bg="gray.200" py={"6px"} px={"12px"}>
          <Text fontWeight={700}>Draft</Text>
        </Tag>
      ) : (
        <Tag bg="green.200" py={"6px"} px={"12px"}>
          <Text fontWeight={700} color={"primary"}>
            Published
          </Text>
        </Tag>
      )}
    </>
  );
};

export default KbTag;
