import { Box, Stack, StackDivider, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useUpdateUser } from "../../api/user/user";
import ImageUpload from "../../components/common/UploadFile/ImageUpload";
import { InputField } from "../../components/fields/InputField";
import { SelectField } from "../../components/fields/SelectField";
import { AccountContext } from "../../context/AccountContextComponent";
import { LocaleContext } from "../../context/LocaleContextComponent";
import { UserContext } from "../../context/UserContextComponent";
import { useToastMessage } from "../../hooks/useToastMessage";
import { EditUserAuth } from "./Auth/EditUserAuth";
import { UserAuthContextComponent } from "./Auth/UserAuthContextComponent";
import { NotificationSettings } from "./NotificationSettings";
import { ViewProfileInfo } from "./ViewProfileInfo";

const EditUser = () => {
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const updateUserMutation = useUpdateUser();
  const queryClient = useQueryClient();
  const { accountData } = useContext(AccountContext);
  const { userData } = useContext(UserContext);
  const { locales } = useContext(LocaleContext);

  const defaultValues = {
    name: userData?.name,
    locale: userData?.locale,
    timezone: userData?.timezone,
  };

  const form = useForm({
    mode: "onChange",
    defaultValues,
  });

  const timezones = Intl.supportedValuesOf("timeZone").map((timezone) => ({
    value: timezone,
    label: timezone.replace("_", " "),
  }));
  // const defaultTimezone = timezones.find(
  //   (option) => option.value === userData?.timezone
  // );

  // const defaultLocale = locales.find(
  //   (option) => option.value === userData?.locale
  // );

  // const {
  //   formState: { errors, isSubmitting },
  // } = form;

  const updateUser = async (data) => {
    try {
      await updateUserMutation.mutateAsync(data);
      queryClient.invalidateQueries(["getMe"]);
      showSuccessToast("Profile updated");
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating member" });
    }
  };

  const submitForm = async (data) => {
    await updateUser(data);
  };

  const debouncedSubmit = debounce(submitForm, 1000);

  const handleFormChange = useCallback(() => {
    debouncedSubmit(form.getValues());
  }, [debouncedSubmit, form]);

  return (
    <FormProvider {...form}>
      <form
        onChange={(e) => {
          handleFormChange(e);
        }}
      >
        <Stack
          spacing={6}
          direction={{ base: "column", md: "row" }}
          divider={<StackDivider />}
          borderTop={"1px solid"}
          borderColor={"gray.300"}
          alignItems={"center"}
        >
          <Box pt={6}>
            <ImageUpload
              isPublic={true}
              imageUrl={userData.photoUrl}
              removeImage={async () => {
                await updateUser({ photoUrl: null });
              }}
              addImage={async (resp) => {
                await updateUser({ photoUrl: resp.downloadUrl });
              }}
              aspect={1 / 1}
              imageStyle={{ borderRadius: "100%", h: "150px", w: "150px" }}
            />
          </Box>
          <VStack spacing={4} align="start" py={4}>
            <InputField
              field={{
                id: `name`,
                placeholder: "Your name",
                validation: {
                  required: { value: true, message: "Name is required" },
                },
                label: "Name",
              }}
            />
            <Box w={"100%"}>
              <SelectField
                field={{
                  id: "locale",
                  label: "Date Format",
                }}
                options={locales}
                onSelectionChange={async (value) => {
                  await updateUser({ locale: value.value });
                }}
              />
            </Box>
            <Box w={"100%"}>
              <SelectField
                field={{
                  label: "Timezone",
                  id: "timezone",
                }}
                options={timezones}
                onSelectionChange={async (value) => {
                  await updateUser({ timezone: value.value });
                }}
              />
            </Box>
          </VStack>
        </Stack>
      </form>
      <UserAuthContextComponent>
        <EditUserAuth />
      </UserAuthContextComponent>
      <NotificationSettings />
      {accountData && <ViewProfileInfo />}
    </FormProvider>
  );
};

export default EditUser;
