import { Box, Center, HStack, Icon, Text } from "@chakra-ui/react";
import ArrowsOutIcon from "../../../assets/icons/arrows_out.svg?react";
import ArrowsInIcon from "../../../assets/icons/arrows_in.svg?react";
import { PROGRESS_OPTIONS } from "../common";
import { MdDragIndicator } from "react-icons/md";

export const KeyResultLabel = ({
  index,
  progress,
  isExpanded,
  dragAttributes,
  dragListeners,
  canEditObjective,
  keyResultsOrder,
  keyResult,
}) => {
  const getKeyResultIndex = () => {
    if (index === null) return "";
    const foundIndex = keyResultsOrder.indexOf(keyResult.id);
    return foundIndex !== -1 ? String(foundIndex + 1) : "";
  };

  return (
    <Box
      bg={isExpanded ? "#3181CE" : "#3181CE2E"}
      _hover={!isExpanded ? { bg: "#3181CE4E" } : { bg: "#3181CEAE" }}
      borderRadius={"md"}
      h={"30px"}
      w={"68px"}
      display="flex"
      alignItems="center"
    >
      <Box position={"relative"}>
        <Box
          position="absolute"
          top={"-24px"}
          left={"-10px"}
          w="19px"
          h="19px"
          borderRadius="50%"
          bg={PROGRESS_OPTIONS[progress].color}
          borderWidth="2px"
          borderColor="white"
        >
          <Center h={"100%"} p={"3px"}>
            {PROGRESS_OPTIONS[progress].icon}
          </Center>
        </Box>
      </Box>

      <Box
        flex="1" // Take up remaining space
      >
        <Center h={"100%"}>
          <HStack spacing={"6px"}>
            {isExpanded ? (
              <ArrowsInIcon fill={"white"} />
            ) : (
              <ArrowsOutIcon fill={"black"} />
            )}
            <Text
              fontSize={"sm"}
              fontWeight={700}
              color={isExpanded ? "white" : "blue.500"}
            >
              KR{getKeyResultIndex()}
            </Text>
          </HStack>
        </Center>
      </Box>

      {canEditObjective && (
        <Box
          {...dragAttributes}
          {...dragListeners}
          style={{ cursor: "grab" }}
          bg={"gray.400"}
          w={"14px"}
          h={"100%"}
          borderRightRadius={"md"}
        >
          <Center h={"100%"}>
            <Icon as={MdDragIndicator} fontSize={"sm"} />
          </Center>
        </Box>
      )}
    </Box>
  );
};
