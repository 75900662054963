import { Box, Text, VStack } from "@chakra-ui/react";
import DonutChart from "../../../components/common/charts/DonutChart";

export const AllEmployeesChart = ({ allEmployeeRankingData, timePeriod }) => {
  return (
    <VStack>
      <Box
        overflow={"auto"}
        bg={"white"}
        w={"100%"}
        p={4}
        borderRadius={"md"}
        border={"1px "}
        borderColor={"gray.200"}
        maxW={"900px"}
        minW={"900px"}
      >
        <Box justifyContent={"center"}>
          <Text textAlign={"center"}>
            Team Alignment for {timePeriod.label}{" "}
          </Text>
        </Box>
        <DonutChart data={allEmployeeRankingData} />
      </Box>
    </VStack>
  );
};
