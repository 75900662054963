import { Stack, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { DepartmentSelect } from "../common/DepartmentSelect";
import { RankingSelect } from "../common/RankingSelect";

export const EmployeeTenureReportFilters = ({
  departmentsToFilter,
  setDepartmentsToFilter,
  ranksToFilter,
  setRanksToFilter,
  watchGraphType,
}) => {
  return (
    <Stack direction={{ base: "column", sm: "row" }} alignItems={"center"}>
      <Text fontWeight={700} whiteSpace={"nowrap"}>
        Filtered by:
      </Text>
      <Wrap>
        {watchGraphType !== "byDepartment" && (
          <WrapItem>
            <DepartmentSelect
              selectedDepartments={departmentsToFilter}
              setSelectedDepartments={setDepartmentsToFilter}
            />
          </WrapItem>
        )}
        {watchGraphType !== "byRank" && (
          <WrapItem>
            <RankingSelect
              selectedRanks={ranksToFilter}
              setSelectedRanks={setRanksToFilter}
            />
          </WrapItem>
        )}
      </Wrap>
    </Stack>
  );
};
