import { useNavigate, useLocation } from "react-router-dom";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  updateProfile,
  getAdditionalUserInfo,
} from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import api from "../../../api/config";

const auth = getAuth();

const EmailActionHandler = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(useLocation().search);
  const mode = searchParams.get("mode");
  const actionCode = searchParams.get("oobCode");
  const isRegisteringAsPartner =
    searchParams.get("isRegisteringAsPartner") === "true";
  const partnerRef = searchParams.get("partnerRef");
  const [email, setEmail] = useState<undefined | string>(undefined);
  const [name, setName] = useState<undefined | string>(undefined);
  const [accountIdFromUrl, setAccountIdFromUrl] = useState<undefined | string>(
    undefined
  );
  const [partnerIdFromUrl, setPartnerIdFromUrl] = useState<undefined | string>(
    undefined
  );

  function extractEmailFromContinueUrl() {
    try {
      // Parse the main URL
      const mainUrl = new URL(window.location.href);

      // Extract the continueUrl parameter
      const continueUrlParam = mainUrl.searchParams.get("continueUrl");
      if (!continueUrlParam) {
        setEmail(searchParams.get("email")?.replace(" ", "+"));
        setName(searchParams.get("name") || undefined);
        setAccountIdFromUrl(searchParams.get("accountId") || undefined);
        setPartnerIdFromUrl(searchParams.get("partnerId") || undefined);
        return null;
      }

      // Decode the continueUrl parameter (since it's URL encoded)
      const decodedContinueUrl = decodeURIComponent(continueUrlParam);

      // Parse the continueUrl
      const continueUrl = new URL(decodedContinueUrl);

      // Extract the email parameter from the continueUrl
      setEmail(continueUrl.searchParams.get("email")?.replace(" ", "+"));
      setName(continueUrl.searchParams.get("name") || undefined);
      setAccountIdFromUrl(
        continueUrl.searchParams.get("accountId") || undefined
      );
      setPartnerIdFromUrl(
        continueUrl.searchParams.get("partnerId") || undefined
      );
    } catch (e) {
      console.error("Error while extracting email:", e);
      return null;
    }
  }

  useEffect(() => {
    extractEmailFromContinueUrl();
  }, []);

  const acceptInvite = useCallback(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      if (!email || !name) {
        return;
      }
      // Partner User Invite
      if (partnerIdFromUrl) {
        signInWithEmailLink(auth, email, window.location.href)
          .then(async (result) => {
            if (!auth.currentUser) {
              return;
            }
            // //1 update the firebase user's name if it's not already set
            // await updateProfile(auth.currentUser, {
            //   displayName: result.user.displayName || name,
            // });
            //2 create the user in the db
            await api.post("/users", {
              partnerId: partnerIdFromUrl,
            });
            const isNewUser = getAdditionalUserInfo(result)?.isNewUser;

            // If the user is new, then redirect to create password page
            if (isNewUser) {
              navigate(
                "/_auth/create-password?email=" + email + "&name=" + name
              );
            } else {
              navigate("/partner");
            }
          })
          .catch((error) => {
            console.log("error", error);
            navigate(
              "/_auth/invite-expired?email=" +
                email +
                "&name=" +
                name +
                "&partnerId=" +
                partnerIdFromUrl
            );
          });
        return;
      }

      // Handle Account user invite
      signInWithEmailLink(auth, email, window.location.href)
        .then(async (result) => {
          if (!auth.currentUser) {
            return;
          }
          //1 update the firebase user's name if it's not already set
          await updateProfile(auth.currentUser, {
            displayName: result.user.displayName || name,
          });
          //2 create the user in the db
          await api.post("/users");
          const isNewUser = getAdditionalUserInfo(result)?.isNewUser;

          // If the user is new, then redirect to create password page
          if (isNewUser) {
            navigate("/_auth/create-password?email=" + email + "&name=" + name);
          } else {
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("error", error);
          navigate(
            "/_auth/invite-expired?email=" +
              email +
              "&name=" +
              name +
              "&accountId=" +
              accountIdFromUrl
          );
        });
    }
  }, [email, name, accountIdFromUrl, navigate, partnerIdFromUrl]);

  useEffect(() => {
    if (!mode || !actionCode) {
      console.log(
        "Invalid URL, missing mode or oobCode",
        isRegisteringAsPartner
      );
      if (isRegisteringAsPartner) {
        navigate(`/partner/new?isRegisteringAsPartner=true`);
        return;
      }
      if (partnerRef) {
        navigate("/?partnerRef=" + partnerRef);
      } else {
        navigate("/");
      }
      return;
    }
    switch (mode.trim()) {
      case "resetPassword":
        navigate("/_auth/reset-password-handler?oobCode=" + actionCode);
        break;
      case "verifyEmail":
        navigate("/_auth/verify-email-handler?oobCode=" + actionCode);
        break;
      case "recoverEmail":
        break;
      case "signIn":
        acceptInvite();
        break;
      case "revertSecondFactorAddition":
        navigate("/_auth/revert-second-factor-addition?oobCode=" + actionCode);
        break;
      default:
        console.log("invalid mode:", mode);
      // Error: invalid mode.
    }
  }, [
    mode,
    navigate,
    email,
    name,
    actionCode,
    acceptInvite,
    isRegisteringAsPartner,
    partnerRef,
  ]);

  return null;
};

export default EmailActionHandler;
