import PageShell from "../../layout/PageShell";
import { useState } from "react";
import InfoBox from "../../components/common/PageInfo/InfoBox";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../hooks/customDomainHooks";
import Teams from "./Teams";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import useTabNavigation from "../../hooks/useTabNavigation";
import Departments from "../../pages/Account/departments/Departments";

const TeamsPage = () => {
  const { accountId } = useAccountId();
  const [pageInfoEditMode, setPageInfoEditMode] = useState(false);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Teams" },
  ]);

  const allTabs = [
    {
      name: "Teams",
      content: Teams,
    },
    {
      name: "Departments",
      content: Departments,
    },
  ];

  const { tabIndex, handleTabsChange } = useTabNavigation(allTabs, "Teams");

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      menuItems={[
        {
          type: "CREATE_PAGE_INFO",
          pageType: "TEAMS",
        },
      ]}
      setPageInfoEditMode={setPageInfoEditMode}
    >
      <InfoBox
        pageInfoType={"TEAMS"}
        setPageInfoEditMode={setPageInfoEditMode}
        pageInfoEditMode={pageInfoEditMode}
      />
      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          {allTabs.map((tab) => (
            <Tab key={tab.name}>{tab.name}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {allTabs.map((tab, idx) => (
            <TabPanel key={tab.name} pl={0}>
              {tabIndex === idx && <tab.content />}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </PageShell>
  );
};

export default TeamsPage;
