import React, { useState } from "react";
import { Box, Button, Spinner, StackDivider, VStack } from "@chakra-ui/react";
import { PaymentElement, AddressElement } from "@stripe/react-stripe-js";

export const BillingForm = ({ handleSubmit, isLoading, buttonLabel }) => {
  const [payElementIsLoading, setPayElementIsLoading] = useState(true);
  const [addressIsLoading, setAddressIsLoading] = useState(true);

  return (
    <form onSubmit={handleSubmit}>
      {(payElementIsLoading || addressIsLoading) && (
        <Box w={"100%"} textAlign={"center"}>
          <Spinner />
        </Box>
      )}
      <VStack
        spacing={4}
        divider={
          !payElementIsLoading &&
          !addressIsLoading && <StackDivider h={"2px"} />
        }
        w={"100%"}
        borderColor={"gray.200"}
      >
        <Box w={"100%"}>
          <AddressElement
            options={{ mode: "billing" }}
            onReady={() => {
              setAddressIsLoading(false);
            }}
          />
        </Box>
        <Box w={"100%"}>
          <PaymentElement
            onReady={() => {
              setPayElementIsLoading(false);
            }}
            options={{
              paymentMethodOrder: ["card", "us_bank_account"],
            }}
          />
        </Box>
        <Button type="submit" w={"100%"} isLoading={isLoading}>
          {buttonLabel}
        </Button>
      </VStack>
    </form>
  );
};
