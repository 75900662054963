import { useQuery, useMutation, UseMutationOptions, UseQueryResult } from "@tanstack/react-query";
import api from "../config";
import { CultureHubLayout } from "./types.ts";

export const useFetchCultureHubLayout = (accountId: string): UseQueryResult<CultureHubLayout> => {
  return useQuery<CultureHubLayout>({
    queryKey: ["fetchCultureHubLayout"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/cultureHubLayout`);
      return response.data;
    },
  });
};

interface UpdateCultureHubLayoutVariables {
  accountId: string;
  cultureHubLayoutId: string;
  data: CultureHubLayout;
}

export const useUpdateCultureHubLayout = (props?: UseMutationOptions<CultureHubLayout, unknown, UpdateCultureHubLayoutVariables>) =>
  useMutation<CultureHubLayout, unknown, UpdateCultureHubLayoutVariables>({
    mutationKey: ["updateCultureHubLayout"],
    mutationFn: async ({ accountId, cultureHubLayoutId, data }) => {
      const response = await api.put(
        `/${accountId}/cultureHubLayout/${cultureHubLayoutId}`,
        data
      );
      return response.data;
    },
    ...props,
  });
