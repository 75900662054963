import { Box, Button, Center } from "@chakra-ui/react";
import { useFetchShoutouts } from "../../../api/shoutouts/shoutout";
import LoadingBox from "../../../components/common/LoadingBox";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../../hooks/customDomainHooks";
import { ShoutoutCard } from "../../Shoutouts/ShoutoutCard";

const ShoutoutsWidget = () => {
  const navigate = useCustomDomainNavigate();
  const { accountId } = useAccountId();
  const {
    data: shoutoutData,
    isLoading: isLoadingShoutouts,
    error: errorShoutouts,
    isError: isErrorShoutouts,
  } = useFetchShoutouts(accountId || "");
  return (
    <Box w={"100%"} p={2}>
      {isLoadingShoutouts && <LoadingBox />}
      {isErrorShoutouts && <div>{errorShoutouts.message}</div>}
      {shoutoutData &&
        shoutoutData.pages[0]?.shoutouts?.slice(0, 1).map((shoutout) => {
          return <ShoutoutCard shoutout={shoutout} key={shoutout.id} />;
        })}
      <Center>
        <Button
          mt={4}
          w={"100%"}
          size={"sm"}
          onClick={() => {
            navigate(`/${accountId}/shoutouts`);
          }}
        >
          View all Shoutouts
        </Button>
      </Center>
    </Box>
  );
};

export default ShoutoutsWidget;
