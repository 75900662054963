import { SelectField } from "../../../../components/fields/SelectField";

const TurnoverConfig = () => {
  const timeRangeOptions = [
    {
      label: "30 Days",
      value: 30,
    },
    {
      label: "60 Days",
      value: 60,
    },
    {
      label: "90 Days",
      value: 90,
    },
    {
      label: "120 Days",
      value: 120,
    },
    {
      label: "6 Months",
      value: 180,
    },
    {
      label: "1 Year",
      value: 365,
    },
  ];

  return (
    <>
      <SelectField
        field={{
          id: "data.dayRange",
          label: "Time Range",
          validation: { required: true },
        }}
        options={timeRangeOptions}
      />
    </>
  );
};

export default TurnoverConfig;
