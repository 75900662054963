import {
  Avatar,
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { FormattedRelativeTime } from "react-intl";
import { AccountContext } from "../../context/AccountContextComponent";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useDeleteShoutoutComment } from "../../api/shoutouts/shoutoutComment";
import useToastMessage from "../../hooks/useToastMessage";
import { useQueryClient } from "@tanstack/react-query";
import useAccountId from "../../hooks/customDomainHooks";

export const Comments = ({ comments }) => {
  const [viewAllComments, setViewAllComments] = useState(false);
  const [isTruncated, setIsTruncated] = useState(true);
  const commentsToShow = viewAllComments ? comments : comments.slice(0, 1);
  const commentsReversed = [...commentsToShow].reverse();

  return (
    <VStack w={"100%"} spacing={2} alignItems={"flex-start"}>
      {comments.length > 1 && (
        <Text
          fontSize={"md"}
          fontWeight={"semibold"}
          color={"textSecondary"}
          cursor={"pointer"}
          _hover={{ textDecoration: "underline" }}
          onClick={() => setViewAllComments(!viewAllComments)}
        >
          {viewAllComments ? "View less" : "View more comments"}
        </Text>
      )}
      {commentsReversed.map((comment) => {
        return (
          <Comment
            comment={comment}
            key={comment.id}
            isTruncated={isTruncated}
            setIsTruncated={setIsTruncated}
          />
        );
      })}
    </VStack>
  );
};

const Comment = ({ comment, isTruncated, setIsTruncated }) => {
  const { currentProfile } = useContext(AccountContext);
  const deleteShoutoutCommentMutation = useDeleteShoutoutComment();
  const { accountId } = useAccountId();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const textRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const now = Date.now();
  const commentTime = new Date(comment.createdAt).getTime();
  const timeDiffInSeconds = Math.floor((commentTime - now) / 1000);

  useEffect(() => {
    if (textRef.current?.scrollHeight > textRef.current?.clientHeight) {
      setIsOverflowing(true);
    } else {
      setIsOverflowing(false);
    }
  }, [comment.text, isTruncated]);

  if (!currentProfile) return null;

  const canEditComment =
    currentProfile.id === comment.creator.id ||
    ["OWNER", "ADMIN", "PARTNER"].includes(currentProfile.role);

  const deleteShoutoutComment = async () => {
    try {
      await deleteShoutoutCommentMutation.mutateAsync({
        accountId,
        shoutoutCommentId: comment.id,
      });
      queryClient.invalidateQueries(["fetchShoutouts"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error deleting shoutout comment." });
    }
  };

  return (
    <HStack w={"100%"} align={"flex-start"} pr={6}>
      <Avatar
        size="sm"
        src={comment.creator.user?.photoUrl}
        name={comment.creator.name}
      />
      <Box position="relative">
        <Box p={2} bg={"gray.100"} borderRadius={"xl"} mr={2}>
          <HStack w={"100%"} justifyContent={"space-between"}>
            <Text fontSize={"xs"} fontWeight={"semibold"}>
              {comment.creator.name}
            </Text>
          </HStack>
          <Box
            ref={textRef}
            style={{
              display: "-webkit-box",
              WebkitLineClamp: isTruncated ? "2" : undefined,
              WebkitBoxOrient: "vertical",
              overflow: isTruncated ? "hidden" : undefined,
            }}
          >
            <Text style={{ overflowWrap: "anywhere", hyphens: "auto" }}>
              {comment.text}
            </Text>
          </Box>
          {isTruncated && isOverflowing && (
            <Text
              color="blue.500"
              cursor="pointer"
              onClick={() => setIsTruncated(false)}
            >
              See more
            </Text>
          )}
        </Box>
        {canEditComment && (
          <Box position="absolute" top={0} right={-10}>
            <Menu isOpen={isOpen} onClose={onClose}>
              <MenuButton
                as={IconButton}
                icon={<BiDotsVerticalRounded fontSize={20} color="gray" />}
                size="sm"
                variant="ghost"
                aria-label="Options"
                onClick={onOpen}
                _hover={{ background: "gray.100" }}
                borderRadius={"full"}
                isLoading={deleteShoutoutCommentMutation.isLoading}
              />
              <MenuList>
                <MenuItem onClick={deleteShoutoutComment}>
                  Delete comment
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        )}
        <Text color={"textSecondary"} fontSize={"2xs"}>
          <FormattedRelativeTime
            value={timeDiffInSeconds}
            numeric="auto"
            updateIntervalInSeconds={10}
            style="narrow"
          />
        </Text>
      </Box>
    </HStack>
  );
};
