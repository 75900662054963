import { Text, Stack } from "@chakra-ui/react";
import { FilterOneSelect } from "../../../components/fields/FilterOneSelect";
import { useMemo } from "react";

export const NotesFilter = ({
  noteOptionFilterValue,
  onSelection,
  showPrivateNoteOptionInFilter = true,
}) => {
  const options = useMemo(() => {
    const options = [
      { label: "Show all", value: "all" },
      { label: "Has notes", value: "hasNotes" }, // private note OR isUpdatedByUser is true
      { label: "No notes", value: "noNotes" }, // no private note AND isUpdatedByUser is false
    ];
    if (showPrivateNoteOptionInFilter) {
      options.push({ label: "Has private note", value: "hasPrivateNote" }); // isUpdatedByUser is true
    } else {
      if (noteOptionFilterValue === "hasPrivateNote") {
        onSelection("all");
      }
    }
    return options;
  }, [showPrivateNoteOptionInFilter, noteOptionFilterValue]);

  return (
    <Stack direction={{ base: "column", md: "row" }} alignItems={"baseline"}>
      <Text fontWeight={700} whiteSpace={"nowrap"}>
        Notes Filter:
      </Text>
      <FilterOneSelect
        options={options}
        value={noteOptionFilterValue}
        onChange={onSelection}
        buttonProps={{
          w: "130px",
        }}
      />
      {/* <Divider orientation="vertical" /> */}
    </Stack>
  );
};
