import { BubbleMenuProps } from "@tiptap/react";
import { FC } from "react";
import { Button, HStack, Icon } from "@chakra-ui/react";
import {
  MdFormatBold,
  MdFormatItalic,
  MdFormatUnderlined,
} from "react-icons/md";

export interface BubbleMenuItem {
  name: string;
  isActive: () => boolean;
  command: () => void;
  icon: typeof MdFormatBold;
}

type EditorBubbleMenuProps = Omit<BubbleMenuProps, "children"> | any;

export const BubbleMenuBaseOptions: FC<EditorBubbleMenuProps> = (props) => {
  const items: BubbleMenuItem[] = [
    {
      name: "bold",
      isActive: () => props.editor.isActive("bold"),
      command: () => props.editor.chain().focus().toggleBold().run(),
      icon: MdFormatBold,
    },
    {
      name: "italic",
      isActive: () => props.editor.isActive("italic"),
      command: () => props.editor.chain().focus().toggleItalic().run(),
      icon: MdFormatItalic,
    },
    {
      name: "underline",
      isActive: () => props.editor.isActive("underline"),
      command: () => props.editor.chain().focus().toggleUnderline().run(),
      icon: MdFormatUnderlined,
    },
  ];

  return (
    <HStack h={"100%"} gap={0}>
      {items.map((item, index) => (
        <Button
          key={index}
          onClick={item.command}
          p="2"
          fontSize="sm"
          variant="ghost"
          bg={item.isActive() ? "gray.100" : "white"}
          h={"100%"}
          borderRadius={0}
        >
          <Icon as={item.icon} fontSize={"24px"} />
        </Button>
      ))}
    </HStack>
  );
};
