import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, applyActionCode } from "firebase/auth";
import useToastMessage from "../../../hooks/useToastMessage";
import { useEffect, useState } from "react";
import AuthLayout from "../../components/AuthLayout";
import { Spinner, Stack, Text } from "@chakra-ui/react";

const auth = getAuth();

const RevertSecondFactorHandler = () => {
  const navigate = useNavigate();
  const { showErrorToast } = useToastMessage();
  const searchParams = new URLSearchParams(useLocation().search);
  const actionCode = searchParams.get("oobCode");
  const [isReverting, setIsReverting] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!actionCode) {
      navigate("/");
      return;
    }

    applyActionCode(auth, actionCode)
      .then(() => {
        setIsReverting(false);
      })
      .catch((error) => {
        setIsReverting(false);
        setIsError(true);
        showErrorToast({ message: "Error reverting second factor addition" });
        console.log("error", error);
      });
  }, [actionCode, navigate, showErrorToast]);

  if (isReverting) {
    return <div>Reverting second factor addition...</div>;
  }
  return (
    <AuthLayout>
      <Text fontSize={"2xl"} fontWeight={600} pb={4}>
        Remove 2FA
      </Text>
      <Stack spacing="6">{isReverting && <Spinner />}</Stack>
      {!isReverting && isError && (
        <Text>
          There was an error reverting the second factor addition. Please try
          again or contact support.
        </Text>
      )}
      {!isReverting && !isError && (
        <Text>
          Your second factor has been successfully removed. You can now login
          without the second factor.
        </Text>
      )}
    </AuthLayout>
  );
};

export default RevertSecondFactorHandler;
