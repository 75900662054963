import { Box, BoxProps, Button, HStack, Text, VStack } from "@chakra-ui/react";
import ArrowRight from "../../../assets/icons/arrow_right.svg?react";
import DrawnArrowUp from "../../../assets/icons/drawn_arrow_up.svg?react";
import { SkipButton } from "../components/SkipButton";

const WizardStepBottomLeft = ({
  incrementWizardStep,
  wizardName,
  title,
  text,
  isLastStep,
}: {
  incrementWizardStep: () => void;
  wizardName: string;
  title: string;
  text: string;
  isLastStep?: boolean;
  boxStyles?: BoxProps;
}) => {
  return (
    <Box maxW={"900px"} py={4} px={8}>
      <HStack alignItems="stretch" h="100%" spacing={8}>
        <VStack alignItems="flex-start" spacing={6}>
          <Text
            color={"white"}
            fontSize={"40px"}
            fontWeight={800}
            textAlign={"start"}
          >
            {title}
          </Text>
          <Text
            color={"white"}
            fontSize={"22px"}
            fontWeight={400}
            textAlign={"start"}
          >
            {text}
          </Text>
        </VStack>
        <Box display="flex" flexDirection="column" py={4}>
          {isLastStep ? (
            <Button onClick={incrementWizardStep}>Done</Button>
          ) : (
            <VStack
              spacing={4}
              justifyContent={"space-between"}
              h={"100%"}
              alignItems={"flex-end"}
            >
              <Button
                onClick={incrementWizardStep}
                leftIcon={<ArrowRight fill="#fff" />}
              >
                Continue
              </Button>
              <SkipButton wizardName={wizardName} />
            </VStack>
          )}
        </Box>
        <Box pt={4} style={{ transform: "scaleX(-1)" }}>
          <DrawnArrowUp />
        </Box>
      </HStack>
    </Box>
  );
};

export default WizardStepBottomLeft;
