import { Icon, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import SearchIcon from "../../assets/icons/search.svg?react";

const SearchBar = ({
  onSearch,
}: {
  onSearch: (searchTerm: string) => void;
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const isInitialRender = useRef(true);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    const timeoutId = setTimeout(() => {
      onSearch(searchTerm);
    }, 200);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  // useEffect(() => {
  //   inputRef.current?.focus(); // Focus the input on first render
  // }, []);

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <Icon as={SearchIcon} boxSize="5" />
      </InputLeftElement>
      <Input
        ref={inputRef}
        placeholder="Search..."
        id="search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        // Search on enter
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSearch(searchTerm);
          }
        }}
      />
    </InputGroup>
  );
};

export default SearchBar;
