import {
  UseMutationOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { OneOnOneSchedule } from "./types"; // Modify this import according to your actual type definitions

export const useCreateOneOnOneSchedule = (
  props?: UseMutationOptions<
    any,
    any,
    { accountId: string; data: any },
    unknown
  >
) =>
  useMutation<any, any, { accountId: string; data: any }, unknown>({
    mutationKey: ["createOneOnOneSchedule"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/oneOnOneSchedule`, data);
      return response.data;
    },
    ...props,
  });

export const useDeleteOneOnOneSchedule = (
  props?: UseMutationOptions<
    any,
    any,
    { accountId: string; oneOnOneScheduleId: string },
    unknown
  >
) =>
  useMutation<
    any,
    any,
    { accountId: string; oneOnOneScheduleId: string },
    unknown
  >({
    mutationKey: ["deleteOneOnOneSchedule"],
    mutationFn: async ({ accountId, oneOnOneScheduleId }) => {
      const response = await api.delete(
        `/${accountId}/oneOnOneSchedule/${oneOnOneScheduleId}`
      );
      return response.data;
    },
    ...props,
  });

export const useFetchOneOnOneSchedules = (
  accountId: string
): UseQueryResult<OneOnOneSchedule[]> => {
  return useQuery<OneOnOneSchedule[]>({
    queryKey: ["fetchOneOnOneSchedules"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/oneOnOneSchedule`);
      return response.data;
    },
  });
};

export const useUpdateOneOnOneSchedule = (
  props?: UseMutationOptions<
    any,
    any,
    {
      accountId: string;
      oneOnOneScheduleId: string;
      data: any;
      fromThisMeetingId: string;
    },
    unknown
  >
) =>
  useMutation<
    any,
    any,
    {
      accountId: string;
      oneOnOneScheduleId: string;
      data: any;
      fromThisMeetingId: string;
    },
    unknown
  >({
    mutationKey: ["updateOneOnOneSchedule"],
    mutationFn: async ({
      accountId,
      oneOnOneScheduleId,
      data,
      fromThisMeetingId,
    }) => {
      const response = await api.put(
        `/${accountId}/oneOnOneSchedule/${oneOnOneScheduleId}/${fromThisMeetingId}`,
        data
      );
      return response.data;
    },
    ...props,
  });
