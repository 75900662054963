import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import { get } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

export const PhoneInputField = ({
  field,
  leftAddon,
  inputStyle,
}: {
  field: any;
  leftAddon?: React.ReactNode;
  inputStyle?: any;
}) => {
  const form = useFormContext();
  const {
    formState: { errors },
    control,
  } = form;

  return (
    <Controller
      name={field.id}
      control={control}
      defaultValue={field.defaultValue ? field.defaultValue : ""}
      rules={{
        validate: (value) =>
          isValidPhoneNumber(value || "") || "Invalid phone number",
      }}
      render={({ field: { onChange, value } }) => (
        <FormControl
          isInvalid={!!get(errors, field.id)}
          isRequired={field.validation?.required}
        >
          {field.label && <FormLabel>{field.label}</FormLabel>}
          <InputGroup>
            {leftAddon}
            <PhoneInput
              id={field.id}
              placeholder={field.placeholder}
              value={value}
              onChange={onChange}
              inputComponent={Input}
              defaultCountry="US"
              {...inputStyle}
            />
          </InputGroup>
          <FormErrorMessage>
            <>{get(errors, field.id) && get(errors, `${field.id}.message`)}</>
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};
