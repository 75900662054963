import { useCallback, useContext, useState } from "react";
import { TipTapAiContext } from "../../../../context/TipTapAiContextComponent";
import { removeAIHighlight } from "../extensions/AiHighlight";
import { Editor } from "@tiptap/core";
import { AiTask } from "../../../../api/ai/ai";
import { getAfterText, getPrevText } from "../lib/editor";
import useAICompletion, { ObjectiveCompletion } from "../useAICompletion";
import useToastMessage from "../../../../hooks/useToastMessage";
import { AccountContext } from "../../../../context/AccountContextComponent";
import useSetQueryParam from "../../../../utils/setQueryParam";
import { useCreateKeyResult } from "../../../../api/okr/keyResult";
import useAccountId from "../../../../hooks/customDomainHooks";
import { DOMSerializer } from "@tiptap/pm/model";

export const useAiTaskSelection = ({
  editor,
  previewEditor,
  hasCompletion,
  redoCompletions,
  setCompletions,
  setRedoCompletions,
  customInstruction,
  isViewingOkrCompletion,
  setIsViewingOkrCompletion,
  setHasCompletion,
  setSelectedIndex,
  setCustomInstruction,
  textAreaRef,
  previousTask,
  setPreviousTask,
}: {
  editor: Editor;
  previewEditor?: Editor;
  hasCompletion: boolean;
  redoCompletions: string[];
  setCompletions: React.Dispatch<React.SetStateAction<string[]>>;
  setRedoCompletions: React.Dispatch<React.SetStateAction<string[]>>;
  customInstruction: string;
  isViewingOkrCompletion: boolean;
  setIsViewingOkrCompletion: React.Dispatch<React.SetStateAction<boolean>>;
  setHasCompletion: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  setCustomInstruction: React.Dispatch<React.SetStateAction<string>>;
  textAreaRef?: React.RefObject<HTMLTextAreaElement>;
  previousTask?: AiTask;
  setPreviousTask: React.Dispatch<React.SetStateAction<AiTask | undefined>>;
}) => {
  const { accountId } = useAccountId();
  const setQueryParam = useSetQueryParam();
  const { showErrorToast } = useToastMessage();
  const createKeyResultMutation = useCreateKeyResult();
  const { currentProfile } = useContext(AccountContext);
  const [completedAiText, setCompletedAiText] = useState("");
  const [previousCustomInstruction, setPreviousCustomInstruction] =
    useState<string>(""); // Used to remember the original instructions if doing "Try again"

  const { context, setOpenAiMenu, setOpenOKRAiMenu, setKey, isOneLine } =
    useContext(TipTapAiContext);

  const { isLoading, complete } = useAICompletion({
    context: context.context,
    editor,
    previewEditor,
    onComplete: (text, completedTask) => {
      if (completedTask === AiTask.PARSE_OKR) {
        handleParsedOkrs(text as object);
        return;
      }
      setHasCompletion(true);
      if (customInstruction) {
        setPreviousCustomInstruction(customInstruction);
      }
      setCustomInstruction("");
      setTimeout(() => {
        textAreaRef?.current?.focus();
      }, 100);
      setCompletedAiText(text as string);
      setCompletions((prev) => [...prev, text as string]);
    },
  });

  const createKeyResult = async (
    objectiveId: string,
    data: {
      title: string;
      type: string;
      goal?: number | string;
    }
  ) => {
    try {
      const typeToStatus = {
        NUMBER: 0,
        PERCENT: 0,
        DONE_NOT_DONE: "NOT_DONE",
        DATE: null,
      } as Record<string, any>;

      await createKeyResultMutation.mutateAsync({
        accountId: accountId || "",
        objectiveId: objectiveId,
        data: {
          // index: index + 1,
          title: data.title,
          ownerId: currentProfile?.id,
          metrics: {
            type: data.type,
            status: typeToStatus[data.type],
            goal: data.goal,
          },
        },
      });
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating Key Result" });
    }
  };

  const handleParsedOkrs = async (aiResponse: object) => {
    const parsedOkr = aiResponse as ObjectiveCompletion;

    if (!parsedOkr || !parsedOkr.objective) {
      showErrorToast({ message: "Error creating OKR" });
      return;
    }

    // Updating the objective this way isn't working because the editor is not getting re-rendered
    // await updateObjective(okr.objective, context.metaData.objectiveId);
    editor.chain().focus().setContent(parsedOkr.objective, true).run();
    for (let j = 0; j < parsedOkr.keyResults.length; j++) {
      const keyResult = parsedOkr.keyResults[j];
      await createKeyResult(context.metaData.objectiveId, {
        title: keyResult.result,
        type: keyResult.type,
        goal: keyResult.goal,
      });
    }

    context.metaData.setNewlyCreatedObjectiveId(context.metaData.objectiveId);
    setQueryParam("objectiveId", context.metaData.objectiveId);
    setOpenOKRAiMenu({ isOpen: false, autofocus: false });
    setKey((prev: number) => prev + 1);
    return;
  };

  const getContextFromEditor = useCallback(() => {
    const contextBefore = getPrevText(editor, {
      chars: 1000,
      offset: 1,
    });
    const contextAfter = getAfterText(editor, {
      chars: 1000,
      offset: 1,
    });

    // Get the selected range
    const { from, to } = editor.state.selection;
    // Get the text in the selected range
    // const text = editor.state.doc.textBetween(from, to);
    // Get the html in the selected range
    const slice = editor.state.doc.slice(from, to);

    // Serialize the slice to HTML
    const div = document.createElement("div");
    const fragment = DOMSerializer.fromSchema(
      editor.state.schema
    ).serializeFragment(slice.content);
    div.appendChild(fragment);

    const html = div.innerHTML;
    return {
      contextBefore,
      contextAfter,
      selectedText: html,
    };
  }, [editor]);

  const undoCompletion = useCallback(() => {
    setCompletions((prev) => {
      const newCompletions = prev.slice(0, -1);
      const lastCompletion =
        newCompletions.length > 0
          ? newCompletions[newCompletions.length - 1]
          : "";
      previewEditor?.chain().focus().setContent(lastCompletion, true).run();
      setRedoCompletions((redoPrev) => [...redoPrev, prev[prev.length - 1]]);
      return newCompletions;
    });
  }, [previewEditor, setCompletions, setRedoCompletions]);

  const redoCompletion = useCallback(() => {
    if (redoCompletions.length > 0) {
      const lastRedo = redoCompletions[redoCompletions.length - 1];
      setCompletions((prev) => [...prev, lastRedo]);
      previewEditor?.chain().focus().setContent(lastRedo, true).run();
      setRedoCompletions((prev) => prev.slice(0, -1));
    }
  }, [redoCompletions, previewEditor, setCompletions, setRedoCompletions]);

  const handleTaskSelection = useCallback(
    (task: AiTask) => {
      if (task === AiTask.CANCEL) {
        setOpenOKRAiMenu({ isOpen: false, autofocus: false });
        setOpenAiMenu(false);
        editor?.chain().unsetHighlight().run();
        removeAIHighlight(editor);
        // Set the focus back to the editor
        editor.chain().focus().run();
        return;
      }
      if (task === AiTask.REPLACE_SELECTION) {
        // Replace the selection with the AI completion
        editor
          .chain()
          .focus()
          .insertContent(
            isOneLine
              ? previewEditor?.getText() || ""
              : previewEditor?.getHTML() || ""
          ) // If one line, do getText, else getHTML
          .run();
        setOpenAiMenu(false);
        setOpenOKRAiMenu({ isOpen: false, autofocus: false });
        // Set the focus back to the editor
        editor.chain().focus().run();
        return;
      }
      if (task === AiTask.INSERT_BELOW) {
        // Insert the AI completion after the selection range
        const range = editor.state.selection;
        editor.chain().focus().setTextSelection(range.to).run();
        editor.chain().focus().insertContent(`\n${completedAiText}`).run();

        setOpenAiMenu(false);
        // Set the focus back to the editor
        editor.chain().focus().run();
        return;
      }
      if (task === AiTask.UNDO) {
        undoCompletion();
        return;
      }
      if (task === AiTask.REDO) {
        redoCompletion();
        return;
      }
      let selectedText = "";
      let contextBefore = "";
      let contextAfter = "";
      let instructions = "";

      // Set the task if doing Try again
      if (task === AiTask.TRY_AGAIN) {
        previewEditor?.commands.setContent("", true);
        task = previousTask || AiTask.IMPROVE;
        instructions = previousCustomInstruction;
        ({ contextBefore, contextAfter, selectedText } =
          getContextFromEditor());
        if (task === AiTask.AUTHOR_OKR) {
          contextBefore = context.metaData.objectiveSetId;
        }
      } else if (task === AiTask.CONTINUE_WRITING && hasCompletion) {
        // contextBefore = completedAiText;
        contextBefore = previewEditor?.getHTML() || "";
        previewEditor?.commands.insertContent(" ");
      } else if (
        (task === AiTask.LONGER || task === AiTask.SHORTER) &&
        hasCompletion
      ) {
        // selectedText = completedAiText;
        selectedText = previewEditor?.getHTML() || "";
        previewEditor?.commands.setContent("", true);
      } else if (task === AiTask.CUSTOM) {
        instructions = customInstruction;
        if (hasCompletion) {
          // selectedText = completedAiText;
          selectedText = previewEditor?.getHTML() || "";
          previewEditor?.commands.setContent("", true);
        }
      } else if (task === AiTask.AUTHOR_OKR) {
        instructions = customInstruction;
        contextBefore = context.metaData.objectiveSetId;
        selectedText = editor.getText();
        if (isViewingOkrCompletion && customInstruction.length > 0) {
          previewEditor?.commands.setContent("", true);
          // selectedText = completedAiText;
          selectedText = previewEditor?.getHTML() || "";
          task = AiTask.EDIT_OKR;
        }
      } else if (task === AiTask.ACCEPT_OKR) {
        task = AiTask.PARSE_OKR;
        selectedText = previewEditor?.getText() || "";
      } else {
        ({ contextBefore, contextAfter, selectedText } =
          getContextFromEditor());
      }

      complete({
        task: task,
        selectedText: selectedText,
        contextBefore: contextBefore,
        contextAfter: contextAfter,
        instructions: instructions,
        rangeToDelete: editor.state.selection,
      });
      setIsViewingOkrCompletion(
        task === AiTask.AUTHOR_OKR || task === AiTask.EDIT_OKR
      );
      setSelectedIndex(0);
      setPreviousTask(task);
    },
    [
      editor,
      complete,
      customInstruction,
      setOpenAiMenu,
      completedAiText,
      getContextFromEditor,
      hasCompletion,
      setOpenOKRAiMenu,
      context,
      isViewingOkrCompletion,
      previewEditor,
      previousCustomInstruction,
      undoCompletion,
      redoCompletion,
      setIsViewingOkrCompletion,
      setSelectedIndex,
      setPreviousTask,
      isOneLine,
      previousTask,
    ]
  );

  return {
    handleTaskSelection,
    isLoading,
  };
};
