import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import { BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import HomeIcon from "../../assets/icons/home.svg?react";

const PageBreadcrumbs = ({
  breadcrumbs,
}: {
  breadcrumbs: {
    name: string;
    href?: string;
    type?: string;
  }[];
}) => {
  const navigate = useNavigate();
  return (
    <Box display={"flex"} margin="auto">
      <Breadcrumb
        spacing={4}
        separator={<BsChevronRight />}
        fontSize={"md"}
        fontWeight={400}
        color={"textPrimary"}
        //style={{ padding: "0 !important" }}
        pt={2}
      >
        {breadcrumbs.map((breadcrumb, index) => {
          return (
            <BreadcrumbItem key={index}>
              {breadcrumb.href ? (
                <BreadcrumbLink
                  onClick={() => {
                    navigate(breadcrumb?.href || "");
                  }}
                >
                  {breadcrumb.type === "HOME" ? <HomeIcon /> : breadcrumb.name}
                </BreadcrumbLink>
              ) : (
                <ul>
                  <Text color={"textSecondary"}>{breadcrumb.name}</Text>
                </ul>
              )}
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </Box>
  );
};

export default PageBreadcrumbs;
