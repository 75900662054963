export const aRankStyle = {
  bg: "green.400",
  color: "white",
  borderColor: "green.400",
};
export const bRankStyle = {
  bg: "#FFD600",
  borderColor: "#FFD600",
};
export const cRankStyle = {
  bg: "red.400",
  color: "white",
  borderColor: "red.400",
};
