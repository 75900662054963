import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { AbcRankingPeriod } from "./types";

interface UpdateRankingPeriodProps {
  accountId: string;
  rankingPeriodId: string;
  data: any;
}

interface CreateRankingPeriodProps {
  accountId: string;
  data: any;
}

interface RankingPeriodMetrics {
  accountId: string;
  departments: { label: string }[];
  tenures: { label: string }[];
}

export const useFetchAbcRankingPeriods = (accountId: string) => {
  return useQuery<AbcRankingPeriod[]>({
    queryKey: ["fetchAbcRankingPeriods"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/abcRankingPeriods`);
      return response.data;
    },
  });
};

export const useUpdateAbcRankingPeriod = (
  props?: UseMutationOptions<
    any,
    Error,
    {
      data: any;
    }
  >
) =>
  useMutation({
    mutationKey: ["updateAbcRankingPeriod"],
    mutationFn: async ({
      accountId,
      rankingPeriodId,
      data,
    }: UpdateRankingPeriodProps) => {
      const response = await api.put(
        `/${accountId}/abcRankingPeriods/${rankingPeriodId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useCreateRankingPeriod = (
  props?: UseMutationOptions<
    any,
    Error,
    {
      data: any;
    }
  >
) =>
  useMutation({
    mutationKey: ["createRankingPeriod"],
    mutationFn: async ({ accountId, data }: CreateRankingPeriodProps) => {
      const response = await api.post(`/${accountId}/abcRankingPeriods`, data);
      return response.data;
    },
    ...props,
  });

export const useFetchLatestAbcRankingPeriodMetrics = (accountId: string) => {
  return useQuery<any>({
    queryKey: ["fetchLatestAbcRankingPeriodMetrics"],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/metrics/abcRankingPeriods/latest`
      );
      return response.data;
    },
  });
};

export const useFetchAllAbcRankingPeriodMetrics = ({
  accountId,
  departments,
  tenures,
}: RankingPeriodMetrics) => {
  return useQuery<any>({
    queryKey: ["fetchAllAbcRankingPeriodMetrics", departments, tenures],
    queryFn: async () => {
      const departmentQuery = departments
        ?.map((department) => `&department=${department.label}`)
        .join("&");

      const tenureQuery = tenures
        ?.map((tenure) => `&tenure=${encodeURIComponent(tenure.label)}`)
        .join("&");

      const response = await api.get(
        `/${accountId}/metrics/abcRankingPeriods/all?${departmentQuery}${tenureQuery}`
      );
      return response.data;
    },
  });
};

export const useFetchAbcRankingPeriodMetrics = (
  accountId: string,
  timePeriodId: string,
  departments: { label: string }[],
  tenures: { label: string }[]
) => {
  return useQuery<any>({
    queryKey: [
      "fetchAbcRankingPeriodMetrics",
      accountId,
      timePeriodId,
      departments,
      tenures,
    ],
    enabled: !!timePeriodId && timePeriodId !== "LAST_6_REPORTS",
    queryFn: async () => {
      const departmentQuery = departments
        .map((department) => `&department=${department.label}`)
        .join("&");

      const tenureQuery = tenures
        ?.map((tenure) => `&tenure=${encodeURIComponent(tenure.label)}`)
        .join("&");

      const response = await api.get(
        `/${accountId}/metrics/abcRankingPeriods/${timePeriodId}?${departmentQuery}${tenureQuery}`
      );
      return response.data;
    },
  });
};
