import {
  MultiFactorError,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getAuth,
  getMultiFactorResolver,
} from "firebase/auth";

export const sendVerificationCode = async (error: MultiFactorError) => {
  const auth = getAuth();
  const resolver = getMultiFactorResolver(auth, error);
  if (resolver.hints[0].factorId !== PhoneMultiFactorGenerator.FACTOR_ID) {
    console.error("Only phone number is supported for MFA.");
    throw new Error("Only phone number is supported for MFA.");
  }
  const recaptchaVerifier = new RecaptchaVerifier(
    auth,
    "recaptcha-container-id"
  );
  const phoneInfoOptions = {
    multiFactorHint: resolver.hints[0],
    session: resolver.session,
  };
  const phoneAuthProvider = new PhoneAuthProvider(auth);
  try {
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier
    );
    recaptchaVerifier.clear();
    return { verificationId, resolver };
  } catch (error) {
    recaptchaVerifier.clear();
    console.log(error);
    throw error;
  }
};
