import {
  Box,
  Center,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import * as React from "react";
import tbaFrame from "../assets/tba-frame.png";
import { ProfileButton } from "../components/account/ProfileButton";
import { ToggleButton } from "../components/common/Buttons/ToggleButton";
import { AccountContext } from "../context/AccountContextComponent";
import DashboardSidebar from "./sidebar/DashboardSidebar";
import PartnerDashboardSidebar from "./sidebar/PartnerDashboardSidebar";

export const Navbar = ({
  hideSidebar,
  hideCompanySettings,
  dashboardType,
}: {
  hideSidebar?: boolean;
  hideCompanySettings?: boolean;
  dashboardType?: string;
}) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { accountData, isLoading } = React.useContext(AccountContext);

  return (
    <Box
      bg={"white"}
      p={"12px"}
      position="fixed"
      width="100%"
      borderBottom={"1px solid"}
      borderColor={"gray.200"}
      zIndex="docked" // Give it a high z-index so it stays on top
    >
      <Center h={"100%"}>
        <Flex
          justify="space-between"
          w={"100%"}
          alignSelf={"center"}
          alignItems={"center"}
          h={"40px"}
        >
          <Center>
            {isLoading && dashboardType !== "PARTNER" ? (
              <Box h={"40px"}></Box>
            ) : (
              isDesktop && (
                <Image
                  src={
                    accountData?.logoImageUrl
                      ? accountData.logoImageUrl
                      : tbaFrame
                  }
                  alt="TBA Frame"
                  maxH={"60px"}
                  maxW={"240px"}
                />
              )
            )}
          </Center>
          <HStack>
            {/* <IconButton
              icon={<MdOutlineNotifications />}
              variant="ghost"
              fontSize={"xl"}
              size={"sm"}
              mr={4}
            /> */}
            <ProfileButton hideCompanySettings={hideCompanySettings} />
            {!isDesktop && !hideSidebar && (
              <Box
                w="10"
                p={8}
                fontSize="sm"
                onClick={onToggle}
                cursor="pointer"
              >
                <Center h="100%">
                  <ToggleButton
                    isOpen={isOpen}
                    aria-label="Open Menu"
                    
                    onClick={onToggle}
                  />
                </Center>
                <Drawer
                  isOpen={isOpen}
                  placement="left"
                  onClose={onClose}
                  isFullHeight
                >
                  <DrawerOverlay />
                  <DrawerContent maxWidth="64">
                    {dashboardType === "PARTNER" ? (
                      <PartnerDashboardSidebar />
                    ) : (
                      <DashboardSidebar />
                    )}
                  </DrawerContent>
                </Drawer>
              </Box>
            )}
          </HStack>
        </Flex>
      </Center>
    </Box>
  );
};
