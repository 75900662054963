import {
  Box,
  FormLabel,
  HStack,
  Link,
  Spinner,
  Stack,
  Switch,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedDate, FormattedTime } from "react-intl";
import { useParams } from "react-router-dom";
import { useUpdateEvent } from "../../../api/events/event";
import TipTapEditor from "../../../components/common/Editor/TipTapEditor";
import ImageUpload from "../../../components/common/UploadFile/ImageUpload";
import { DateField } from "../../../components/fields/DateField";
import { InputField } from "../../../components/fields/InputField";
import { NumberField } from "../../../components/fields/NumberField";
import { SwitchField } from "../../../components/fields/SwitchField";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";

const EditEventForm = ({
  event,
  setEditInvitesModalOpen,
  setShowPlanningTeamModal,
  setShowSignupsModal,
  setIsSavingEvent,
}) => {
  const { eventId } = useParams();
  const { accountId } = useAccountId();
  const updateEventMutation = useUpdateEvent();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();

  const defaultValues = {
    name: event.name,
    details: event.details,
    startTime: event.startTime,
    endTime: event.endTime,
    location: event.location,
    maxParticipants: event.maxParticipants,
    budget: event.budget,
    spend: event.spend,
    isPublished: event.isPublished,
  };

  const form = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = form;

  const currentEvent = watch();

  // Set the end time to 1 hour after the start time once start time has a time
  const autoSetEndTime = (startTime) => {
    if (startTime) {
      const startDate = new Date(startTime);
      if (startDate.getHours() !== 0 || startDate.getMinutes() !== 0) {
        // The time isn't midnight
        if (!getValues("endTime")) {
          const newEndTime = new Date(
            startDate.getTime() + 60 * 60 * 1000
          ).toISOString();
          setValue("endTime", newEndTime);
        }
      }
    }
  };

  const updateEvent = async (data) => {
    try {
      await updateEventMutation.mutateAsync({
        accountId,
        data,
        eventId,
      });
      queryClient.invalidateQueries(["fetchEvent", event.id]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating event" });
    }
  };

  const removeImageFromEvent = async () => {
    try {
      await updateEvent({ imagePath: null });
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error removing image from Event" });
    }
  };

  const canSubmit = () => {
    const { location, startTime, endTime, isPublished } = getValues();
    if (!isPublished) {
      return true;
    }

    if (isPublished && (!location || !startTime || !endTime)) {
      const currentValues = getValues();
      reset({ ...currentValues, isPublished: false });
      showErrorToast({
        message:
          "Location, start time, and end time must be set before publishing",
      });
      return false;
    }
    return true;
  };

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  // Only submit if there are no errors
  const submitForm = (event) => {
    if (!canSubmit()) {
      return;
    }
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateEvent)(event);
    }
  };

  useEffect(() => {
    setIsSavingEvent(updateEventMutation.isLoading);
  }, [updateEventMutation.isLoading]);

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(updateEvent)} onChange={debouncedSubmit}>
          <HStack px={4}>
            {!event.isPublished ? (
              <Tag bg="gray.200" py={"6px"} px={"12px"}>
                <Text fontWeight={700}>Draft</Text>
              </Tag>
            ) : (
              <Tag bg="green.200" py={"6px"} px={"12px"}>
                <Text fontWeight={700} color={"primary"}>
                  Published
                </Text>
              </Tag>
            )}
            <Text fontSize={"sm"}>
              Last saved {` `}
              <FormattedDate
                value={event.updatedAt}
                month="short"
                day="2-digit"
              />
              {` at `}
              <FormattedTime value={event.updatedAt} />{" "}
            </Text>
            {updateEventMutation.isLoading && <Spinner size={"sm"} />}
          </HStack>
          <Stack direction={{ base: "column", md: "row" }}>
            <Box
              p={{ base: 0, md: 4 }}
              w={{ base: "100%", md: "60%" }}
              order={{ base: 1, md: 0 }}
            >
              <VStack spacing={4} alignItems={"flex-start"}>
                <ImageUpload
                  imageUrl={event.imageUrl}
                  removeImage={removeImageFromEvent}
                  addImage={async (resp) => {
                    await updateEvent({ imagePath: resp.fileName });
                  }}
                />
                <InputField
                  field={{
                    id: "name",
                    placeholder: "Name",
                    label: "Name",
                  }}
                />
                <Text
                  alignSelf={"flex-start"}
                  color={"gray.800"}
                  fontWeight={500}
                  fontSize={"md"}
                >
                  Details
                </Text>
                <Box w={"100%"}>
                  <TipTapEditor
                    defaultValue={event.details}
                    onChange={(htmlContent) => {
                      setValue("details", htmlContent);
                      debouncedSubmit();
                    }}
                    minHeight={200}
                    makeImagesPublic={true}
                    aiContext={{
                      context: `We are writing a description for an event. You can use emojis if appropriate.
                      Title: '${currentEvent.name}'
                      Start Time: ${
                        currentEvent.startTime
                          ? new Date(currentEvent.startTime)
                          : ""
                      }
                      End Time: ${
                        currentEvent.endTime
                          ? new Date(currentEvent.endTime)
                          : ""
                      }
                      Location: ${currentEvent.location}
                      `,
                    }}
                  />
                </Box>
                <SwitchField
                  field={{
                    id: "isPublished",
                    label: "Status",
                  }}
                  leftLabel={"Draft"}
                  rightLabel={"Published"}
                />
              </VStack>
            </Box>
            <Box
              p={4}
              w={{ base: "100%", md: "40%" }}
              order={{ base: 0, md: 1 }}
            >
              <VStack alignItems={"flex-start"} spacing={6}>
                <Box w={"100%"} maxW={"300px"}>
                  <DateField
                    field={{
                      id: "startTime",
                      label: "Start Time",
                      // validation: {
                      //   required: true,
                      // },
                    }}
                    onDateChange={(e) => {
                      autoSetEndTime(e);
                      submitForm(e);
                    }}
                    dateFormat={"MMMM d, yyyy h:mm aaaa"}
                    showTimeSelect={true}
                    isFakeRequired={true}
                  />
                </Box>
                <Box w={"100%"} maxW={"300px"}>
                  <DateField
                    field={{
                      id: "endTime",
                      label: "End Time",
                      validation: {
                        // required: true,
                        validate: (value) => {
                          if (!value) {
                            return true;
                          }
                          const { startTime } = getValues();
                          return new Date(value) > new Date(startTime);
                        },
                      },
                    }}
                    onDateChange={submitForm}
                    dateFormat={"MMMM d, yyyy h:mm aaaa"}
                    showTimeSelect={true}
                    isFakeRequired={true}
                  />
                </Box>
                <Box w={"100%"} maxW={"300px"}>
                  <InputField
                    field={{
                      id: "location",
                      label: "Location",
                    }}
                    multiLine={true}
                    inputStyle={{ backgroundColor: "white" }}
                    isFakeRequired={true}
                  />
                </Box>
                <HStack alignItems={"flex-end"}>
                  <NumberField
                    field={{
                      id: "maxParticipants",
                      label: "Max Participants",
                      placeholder: "No limit",
                      min: 1,
                    }}
                    styleProps={{ maxWidth: "90px", backgroundColor: "white" }}
                  />
                  <Link
                    whiteSpace={"nowrap"}
                    onClick={() => {
                      setShowSignupsModal(true);
                    }}
                  >
                    {event._count.signups === 1 && "1 signup"}
                    {event._count.signups > 1 &&
                      `${event._count.signups} signups`}
                  </Link>
                </HStack>
                <VStack alignItems={"flex-start"}>
                  <FormLabel m={0}>Invitees</FormLabel>
                  <VStack alignItems={"flex-start"}>
                    <HStack>
                      <Switch
                        defaultChecked={event.isEveryoneInvited}
                        onChange={(e) => {
                          setValue("isEveryoneInvited", e.target.checked);
                        }}
                      />
                      <Text
                        color={!event.isEveryoneInvited && "gray.400"}
                        fontSize={"sm"}
                      >
                        Everyone is invited
                      </Text>
                    </HStack>
                    {!event.isEveryoneInvited && (
                      <HStack>
                        <Link
                          fontSize={"sm"}
                          onClick={() => setEditInvitesModalOpen(true)}
                          fontWeight={"semibold"}
                        >
                          Edit
                        </Link>
                        <Text fontSize={"sm"}>
                          {event._count.invitees === 0 && "0 people invited"}
                          {event._count.invitees === 1 && "1 person invited"}
                          {event._count.invitees > 1 &&
                            `${event._count.invitees} people invited`}
                        </Text>
                      </HStack>
                    )}
                  </VStack>
                  <HStack>
                    <Switch
                      defaultChecked={event.allowInviteesToSeeOtherInvitees}
                      onChange={(e) => {
                        setValue(
                          "allowInviteesToSeeOtherInvitees",
                          e.target.checked
                        );
                      }}
                    />
                    <Text
                      color={
                        !event.allowInviteesToSeeOtherInvitees && "gray.400"
                      }
                      fontSize={"sm"}
                    >
                      Allow invitees to see other invitees
                    </Text>
                  </HStack>
                </VStack>
                <VStack alignItems={"flex-start"}>
                  <FormLabel m={0}>Planning Team</FormLabel>
                  <HStack>
                    <Link
                      fontSize={"sm"}
                      onClick={() => setShowPlanningTeamModal(true)}
                      fontWeight={"semibold"}
                    >
                      Edit
                    </Link>
                    <Text fontSize={"sm"}>
                      {event.planningTeam.length === 0 && "Admins only"}
                      {event.planningTeam.length === 1 && "You and 1 person"}
                      {event.planningTeam.length > 1 &&
                        `You and ${event.planningTeam.length} other people`}
                    </Text>
                  </HStack>
                </VStack>
                <NumberField
                  field={{
                    id: "budget",
                    label: "Budget",
                    min: 0,
                    type: "currency",
                  }}
                  styleProps={{ maxWidth: "150px" }}
                />
                <NumberField
                  field={{
                    id: "spend",
                    label: "Actual Spend",
                    min: 0,
                    type: "currency",
                  }}
                  styleProps={{ maxWidth: "150px" }}
                />
              </VStack>
            </Box>
          </Stack>
        </form>
      </FormProvider>
    </>
  );
};

export default EditEventForm;
