import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";

interface ConfirmSetupIntentVariables {
  accountId: string;
  data: any;
}

interface CreateAccountSubscriptionVariables {
  accountId: string;
  data: any;
}

interface CancelSubscriptionVariables {
  accountId: string;
}

export const useConfirmSetupIntent = (
  props?: UseMutationOptions<any, unknown, ConfirmSetupIntentVariables>
) =>
  useMutation<any, unknown, ConfirmSetupIntentVariables>({
    mutationKey: ["confirmSetupIntent"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/billing/confirmSetupIntent`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useFetchSetupIntent = (
  accountId: string,
  queryOptions?: UseQueryOptions<any, unknown>
) => {
  return useQuery<any>({
    refetchOnWindowFocus: false,
    queryKey: ["fetchSetupIntent", accountId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/billing/setupIntent`);
      return response.data;
    },
    ...queryOptions,
  });
};

export const useCreateAccountSubscription = (
  props?: UseMutationOptions<any, unknown, CreateAccountSubscriptionVariables>
) => {
  return useMutation<any, unknown, CreateAccountSubscriptionVariables>({
    mutationKey: ["createAccountSubscription"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(
        `/${accountId}/billing/accountSubscription`,
        data
      );
      return response.data;
    },
    ...props,
  });
};

export const useCancelSubscription = (
  props?: UseMutationOptions<any, unknown, CancelSubscriptionVariables>
) => {
  return useMutation<any, unknown, CancelSubscriptionVariables>({
    mutationKey: ["cancelSubscription"],
    mutationFn: async ({ accountId }) => {
      const response = await api.post(
        `/${accountId}/billing/cancelSubscription`
      );
      return response.data;
    },
    ...props,
  });
};
