export const en = {
  // Culture Score page
  cultureScoreWeight:
    "Choose how much you want each category to factor into your overall score. Weights must add up to 100%",
  cultureScoreTrending:
    "Compares the average of current month's daily scores to the average of last month's daily scores",

  // Team Alignment page
  teamAlignmentDue:
    "The due date that will be displayed to managers. The reporting period is not automatically closed on this date.",
  teamAlignmentOpen:
    "Reports can only be edited while the reporting period is open",

  // Surveys > Manage Runs page
  surveyDueDate: "The due date displayed to employees taking the survey",
  surveyAllowResponses:
    "Survey results will only appear on Admin dashboard widgets and in reports after Allow Responses has been turned off",
  surveyMeasureEngagement:
    "Only surveys with Measure Engagement enabled will count toward the Survey Engagement measured attribute in the Culture Success Score",
};
