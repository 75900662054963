import { FormProvider, useForm } from "react-hook-form";
import { SelectField } from "../../../components/fields/SelectField";

export const MeetingStatusFilterSelect = ({
  statusOptions,
  onSelection,
  statusesSelected,
}) => {
  const form = useForm({
    mode: "onChange",
    defaultValues: {
      status: "",
    },
  });

  const clearStatus = () => {
    form.setValue("status", "");
  };

  return (
    <FormProvider {...form}>
      <form
        key={statusesSelected?.length}
        // Prevent form submission on enter key
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        <SelectField
          field={{
            id: "status",
            placeholder: "+ Status Filter",
          }}
          options={statusOptions}
          onSelectionChange={(e) => {
            clearStatus();
            onSelection(e);
          }}
          selectProps={{
            variant: "unstyled",
            components: {
              DropdownIndicator: () => null,
            },
          }}
          selectStyles={{
            control: (provided) => ({
              ...provided,
              cursor: "pointer",
              padding: "0px 4px 0px 4px",
              borderRadius: "10px",
              // border: "1px solid",
              _hover: {
                bg: "gray.100",
              },
              fontSize: "sm",
            }),
          }}
        />
      </form>
    </FormProvider>
  );
};
