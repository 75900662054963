import { Box, Button, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { BsIncognito } from "react-icons/bs";
import { MdChevronRight } from "react-icons/md";
import { FormattedDate } from "react-intl";
import TakeShortSurvey from "./TakeShortSurvey";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../../hooks/customDomainHooks";

const MySurveyStartButton = ({ surveyRun }) => {
  const navigate = useCustomDomainNavigate();
  const { accountId } = useAccountId();
  return (
    <>
      <HStack justifyContent={"space-between"} w={"100%"}>
        {surveyRun.dueDate ? (
          <Text fontSize={"sm"} color={"textSecondary"}>
            Due: {` `}
            <FormattedDate
              value={surveyRun.dueDate}
              year="numeric"
              month="short"
              day="2-digit"
            />
          </Text>
        ) : (
          <Box />
        )}
        <Button
          rightIcon={<MdChevronRight />}
          onClick={() => {
            navigate(`/${accountId}/surveys/take-survey/${surveyRun.id}`);
          }}
        >
          {surveyRun.surveyRunResponses?.length > 0 && "Continue"}
          {surveyRun.surveyRunResponses?.length === 0 && "Start"}
        </Button>
      </HStack>
    </>
  );
};

const SurveyWidget = ({ surveyRun }) => {
  return (
    <VStack alignItems={"flex-start"}>
      <Text fontWeight={"bold"} fontSize={"xl"}>
        {surveyRun.title}
      </Text>
      {surveyRun.isAnonymous && (
        <HStack>
          <Text color={"textSecondary"} fontSize={"xs"}>
            Responses are anonymous
          </Text>
          <Icon as={BsIncognito} color={"textSecondary"} />
        </HStack>
      )}
      <Text>{surveyRun.overview}</Text>
      {surveyRun.questions?.length === 1 ? (
        <TakeShortSurvey
          surveyRun={surveyRun}
          surveyRunResponse={surveyRun.surveyRunResponses[0] || {}}
        />
      ) : (
        <MySurveyStartButton surveyRun={surveyRun} />
      )}
    </VStack>
  );
};

export default SurveyWidget;
