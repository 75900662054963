import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../components/AuthLayout";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    // await sendPasswordReset(email);
    await axios.post(
      `${import.meta.env.VITE_BASE_API_URL}/public/sendPasswordResetEmail`,
      {
        email,
      }
    );
    setIsSubmitting(false);
    setIsSubmitted(true);
  };

  return (
    <AuthLayout>
      {isSubmitted ? (
        <Box>
          <VStack>
            <Text>Check your email for a link to reset your password.</Text>
            <Button
              w={"100%"}
              onClick={() => {
                navigate("/_auth/signin");
              }}
            >
              Sign In
            </Button>
            <Button onClick={handleSubmit} variant={"ghost"}>
              Send again
            </Button>
          </VStack>
        </Box>
      ) : (
        <Flex direction="column" m="2">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail Address"
                autoComplete="email"
              />
              <Text color={"textPrimary"}>
                Enter the email address you used to register
              </Text>
            </FormControl>
            <Button w="100%" my="2" type="submit" isLoading={isSubmitting}>
              Send password reset email
            </Button>
          </form>
          {/* <Box>
            Don't have an account?{" "}
            <Link
              onClick={() => {
                navigate("/_auth/register");
              }}
            >
              Signup now.
            </Link>
          </Box> */}
        </Flex>
      )}
    </AuthLayout>
  );
}
export default ResetPassword;
