import PageShell from "../../layout/PageShell";
import { Box, Center, Stack, Tag } from "@chakra-ui/react";
import InfoBox from "../../components/common/PageInfo/InfoBox";
import { useState } from "react";
import CultureScoreSeriesWidget from "../CultureDashboard/widgets/view/CultureScoreSeriesWidget";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../hooks/customDomainHooks";
import CultureScoreConfig from "./CultureScoreConfig";
import { CultureScoreIndicator } from "./CultureScoreIndicator";

const CultureScorePage = () => {
  const { accountId } = useAccountId();
  const [pageInfoEditMode, setPageInfoEditMode] = useState(false);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Culture Success Score" },
  ]);

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      headerComponent={
        <Box alignSelf={"flex-end"} pl={4}>
          <Tag size={"md"} colorScheme={"yellow"}>
            Viewable by Admins
          </Tag>
        </Box>
      }
      menuItems={[
        {
          type: "CREATE_PAGE_INFO",
          pageType: "CULTURE_SCORE",
        },
      ]}
      setPageInfoEditMode={setPageInfoEditMode}
    >
      <Box maxWidth={"1200px"}>
        <InfoBox
          pageInfoType={"CULTURE_SCORE"}
          setPageInfoEditMode={setPageInfoEditMode}
          pageInfoEditMode={pageInfoEditMode}
        />

        <Stack direction={{ base: "column", md: "row" }} spacing={6}>
          <Box w={"200px"} minW={"400px"}>
            <CultureScoreIndicator />
          </Box>
          <Box
            w={"100%"}
            minW={"400px"}
            bg={"white"}
            borderRadius={"md"}
            border={"1px"}
            borderColor={"gray.200"}
            boxShadow={"md"}
            paddingTop={12}
            pr={6}
          >
            <CultureScoreSeriesWidget />
          </Box>
        </Stack>
        <Center w={"100%"}>
          <CultureScoreConfig />
        </Center>
      </Box>
    </PageShell>
  );
};

export default CultureScorePage;
