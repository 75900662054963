export const reduceQuestionResponsesToEnpsScore = (
  questionResponses: {
    response: { value: number } | null;
  }[]
) => {
  const validQuestionResponses = questionResponses.filter(
    (questionResponse) => questionResponse !== null
  );

  // Get ENPS response values
  const questionResponseEnpsAverage =
    validQuestionResponses
      .map((questionResponse) => {
        const questionResponseResponse = questionResponse.response;
        const responseValue = questionResponseResponse?.value;
        if (responseValue === undefined) {
          return 0;
        }
        // ENPS score
        // 9 or 10 gets a 100, 7 or 8 gets a 50, 0-6 gets a 0
        switch (true) {
          case responseValue <= 6:
            return -100;
          case responseValue === 7 || responseValue === 8:
            return 0;
          case responseValue === 9 || responseValue === 10:
            return 100;
          default:
            return 0;
        }
      })
      .reduce((a: number, b: number) => a + b, 0) /
    validQuestionResponses.length;

  const formattedString = questionResponseEnpsAverage.toFixed(0);
  if (formattedString === "NaN") {
    return "N/A";
  } else {
    return formattedString;
  }
};
