import {
  Avatar,
  Box,
  Button,
  HStack,
  Link,
  Tag,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaRegComments } from "react-icons/fa";
import { FormattedDate } from "react-intl";
import { useUpdateOneOnOneMeeting } from "../../api/oneOnOne/oneOnOneMeeting";
import ReceiptIcon from "../../assets/icons/receipt.svg?react";
import LoadingBox from "../../components/common/LoadingBox";
import CustomTable from "../../components/table/CustomTableV2";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import useToastMessage from "../../hooks/useToastMessage";
import CreateOneOnOneButton from "./Buttons/CreateOneOnOneButton";
import {
  MEETING_STATUSES,
  MeetingStatusField,
} from "./Meeting/MeetingStatusField";
import { UpdateOneOnOneScheduleModal } from "./modals/UpdateOneOnOneScheduleModal";

export const OneOnOnesTable = ({
  oneOnOneMeetings,
  isLoadingOneOnOneMeetings,
  isErrorOneOnOneMeetings,
  errorOneOnOneMeetings,
  showCreateButton = true,
  showSearch = true,
  showNameColumn = true,
  tableInitialState,
  filterElements,
}) => {
  const { accountId } = useAccountId();
  const navigate = useCustomDomainNavigate();
  const updateMeetingMutation = useUpdateOneOnOneMeeting();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const [showEditModal, setShowEditModal] = useState(false);
  const [meetingData] = useState(null);
  const { currentProfile } = useContext(AccountContext);
  const currentProfileId = currentProfile.id;

  // This table will contain meetings that the current profile is invited to and has created

  const isCreatorOfSomeMeeting = oneOnOneMeetings?.some(
    (meeting) => meeting.creatorId === currentProfileId
  );

  const oneOnOneMeetingsColumns = useMemo(
    () => [
      ...(showNameColumn
        ? [
            {
              Header: "Name",
              accessor: (row) => {
                let profileToShow = row.invitee;
                const isCreator = row.creatorId === currentProfileId;
                if (!isCreator) {
                  profileToShow = row.creator;
                }
                return profileToShow.name;
              },
              Cell: ({ cell }) => {
                const oneOnOneMeeting = cell.row.original;
                const invitee = oneOnOneMeeting.invitee;
                const manager = oneOnOneMeeting.creator;
                return (
                  <Tooltip label={`${manager.name} & ${invitee.name}`}>
                    <Button
                      variant={"ghost"}
                      onClick={() =>
                        navigate(
                          `/${accountId}/one-on-ones/${oneOnOneMeeting.id}`
                        )
                      }
                      gap={2}
                      h={"90px"}
                      w={"170px"}
                    >
                      <VStack>
                        <HStack gap={3}>
                          <HStack mb={3}>
                            <Avatar
                              w="38px"
                              h="38px"
                              objectFit="cover"
                              src={manager?.user?.photoUrl}
                              name={manager?.name}
                            />
                          </HStack>
                          <FaRegComments
                            style={{ width: "24px", height: "20px" }}
                          />
                          <HStack mt={3}>
                            <Avatar
                              w="38px"
                              h="38px"
                              objectFit="cover"
                              src={invitee?.user?.photoUrl}
                              name={invitee.name}
                            />
                          </HStack>
                        </HStack>
                        <Text
                          color="textPrimary"
                          fontWeight={400}
                          w={"160px"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                        >
                          {manager?.name?.split(" ")[0]} &{" "}
                          {invitee?.name?.split(" ")[0]}
                        </Text>
                      </VStack>
                    </Button>
                  </Tooltip>
                );
              },
            },
          ]
        : []),
      {
        Header: "Date",
        accessor: "startDate",
        Cell: ({ row }) => {
          const date = row.original.startDate;
          return (
            <Link
              onClick={() => {
                navigate(`/${accountId}/one-on-ones/${row.original.id}`);
                // setMeetingData(row.original);
                // setShowEditModal(true);
              }}
            >
              <FormattedDate
                value={date}
                year="numeric"
                month="short"
                day="2-digit"
                hour="numeric"
                minute="numeric"
                weekday="long"
              />
            </Link>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          const form = useForm({
            mode: "onChange",
            defaultValues: {
              status: row.original.status,
            },
          });

          const {
            handleSubmit,
            formState: { errors },
          } = form;

          const updateMeeting = async (data) => {
            try {
              const meetingId = row.original.id;
              await updateMeetingMutation.mutateAsync({
                accountId,
                data: {
                  status: data.status,
                },
                meetingId,
              });
              queryClient.invalidateQueries([
                "fetchOneOnOneMeeting",
                meetingId,
              ]);
              queryClient.invalidateQueries(["fetchOneOnOneMeetings"]);
              queryClient.invalidateQueries(["fetchMyTeam"]);
            } catch (error) {
              console.log(error);
              showErrorToast({ message: "Error updating meeting" });
            }
          };

          const debouncedSubmit = useCallback(
            debounce((event) => {
              submitForm(event);
            }, 0),
            [handleSubmit, errors]
          );

          // Only submit if there are no errors
          const submitForm = (event) => {
            if (Object.keys(errors).length === 0) {
              handleSubmit(updateMeeting)(event);
            }
          };

          if (row.original.creatorId !== currentProfileId) {
            return (
              <VStack alignItems={"flex-start"} gap={4}>
                <Text
                  fontWeight={700}
                  fontSize={16}
                  color={MEETING_STATUSES[row.original.status].color}
                >
                  {MEETING_STATUSES[row.original.status].label}
                </Text>
                {row.original.isArchived && (
                  <Tag colorScheme="red">Deleted</Tag>
                )}
              </VStack>
            );
          }

          return (
            <VStack alignItems={"flex-start"} gap={1}>
              <FormProvider {...form}>
                <form onChange={debouncedSubmit}>
                  <Box w={150}>
                    <MeetingStatusField submit={debouncedSubmit} />
                  </Box>
                </form>
              </FormProvider>
              {row.original.isArchived && <Tag colorScheme="red">Deleted</Tag>}
            </VStack>
          );
        },
      },
      ...(isCreatorOfSomeMeeting
        ? [
            {
              Header: "Private Notes",
              accessor: "creatorPrivateNote",
              Cell: ({ row }) => {
                if (row.original.creatorId !== currentProfileId) {
                  return null;
                }
                const privateNote = row.original.creatorPrivateNote;
                const oneOnOneMeeting = row.original;

                return (
                  <Box justifyContent={"center"} display={"flex"}>
                    {privateNote && privateNote.content?.length > 0 ? (
                      <Button
                        variant={"ghost"}
                        onClick={() =>
                          navigate(
                            `/${accountId}/one-on-ones/${oneOnOneMeeting.id}`
                          )
                        }
                      >
                        <ReceiptIcon stroke={"#2F855A"} />
                      </Button>
                    ) : (
                      <Text color={"gray.400"} fontSize={"sm"}>
                        ---
                      </Text>
                    )}
                  </Box>
                );
              },
            },
          ]
        : []),
      // Hidden column
      {
        Header: "Shared Note",
        accessor: "sharedNote.content",
      },
    ],
    [oneOnOneMeetings]
  );

  return (
    <>
      <UpdateOneOnOneScheduleModal
        isOpen={showEditModal}
        setIsOpen={setShowEditModal}
        meetingData={meetingData}
      />

      {isLoadingOneOnOneMeetings && (
        <Box w={"100%"}>
          <LoadingBox height={"200px"} />
        </Box>
      )}
      {isErrorOneOnOneMeetings && (
        <Text color={"red.500"}>Error: {errorOneOnOneMeetings.message}</Text>
      )}
      {!isLoadingOneOnOneMeetings && oneOnOneMeetings && (
        <CustomTable
          columns={oneOnOneMeetingsColumns}
          data={oneOnOneMeetings}
          showSearchBar={showSearch}
          filterElements={filterElements}
          initialState={{
            sortBy: [
              {
                id: "startDate",
                desc: true,
              },
            ],
            hiddenColumns: ["sharedNote.content"],
            pageSize: 5,
            ...tableInitialState,
          }}
          rightElement={showCreateButton && <CreateOneOnOneButton />}
          doUsePagination={true}
          selectionType={"link"}
          boxStyle={{
            bg: "white",
            borderRadius: "10px",
            border: "1px",
            borderColor: "gray.200",
            p: 4,
            overflow: "auto",
          }}
          tableStyle={{
            size: "sm",
          }}
        />
      )}
    </>
  );
};
