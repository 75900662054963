import { Box, Container, Image, Text, VStack } from "@chakra-ui/react";
import BackgroundImageGallery from "../../components/common/BackgroundImageGallery";
import TBALogo from "../../assets/tba-frame.png";
import { useContext } from "react";
import { AccountContext } from "../../context/AccountContextComponent";

const ContactAdmin = () => {
  const { accountData } = useContext(AccountContext);

  const ownerProfile = accountData?.profiles?.find(
    (profile) => profile.role === "OWNER"
  );
  return (
    <Box h="100vh">
      <BackgroundImageGallery>
        <Container
          maxW="2xl"
          py={"8"}
          px={{ base: "4", sm: "10" }}
          bg={"white"}
          boxShadow={{ base: "none", sm: "xl" }}
          borderRadius={{ base: "none", sm: "5px" }}
          position="absolute"
        >
          <VStack justifyContent={"center"} w={"100%"} spacing={4}>
            <Image src={TBALogo} w={"328px"}></Image>
            <Text fontSize={"xl"} fontWeight={800}>
              Contact Admin
            </Text>

            <Text>
              There's a problem accessing this account. Please contact your
              admin.
            </Text>
            <Text fontWeight={700}>{ownerProfile.email}</Text>
          </VStack>
        </Container>
      </BackgroundImageGallery>
    </Box>
  );
};

export default ContactAdmin;
