import { HStack, Stack, Text, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useFetchAllSurveyRuns } from "../../../api/survey/surveyRun";
import { SelectField } from "../../../components/fields/SelectField";
import useAccountId from "../../../hooks/customDomainHooks";
import { SurveyReportFilters } from "./SurveyReportFilters";

export const SurveysReportConfig = ({
  setSurveyRunId,
  surveyRunMetrics,
  tenuresToFilter,
  setTenuresToFilter,
  departmentsToFilter,
  setDepartmentsToFilter,
  ranksToFilter,
  setRanksToFilter,
  viewMode,
  setViewMode,
}) => {
  const { accountId } = useAccountId();
  const {
    data: surveyRuns,
    isLoading: isLoadingSurveyRuns,
    isError: isErrorSurveyRuns,
    error: errorSurveyRuns,
  } = useFetchAllSurveyRuns(accountId || "");

  const surveyOptions = useMemo(() => {
    if (!surveyRuns) return [];

    const uniqueSurveys = surveyRuns
      .filter((surveyRun) => !surveyRun.isActive && !surveyRun.isArchived)
      .reduce((acc, current) => {
        const isDuplicate = acc.some(
          (survey) => survey.survey.id === current.survey.id
        );
        if (!isDuplicate) {
          acc.push(current);
        }
        return acc;
      }, []);

    return uniqueSurveys.map((surveyRun) => ({
      label: surveyRun.survey.title,
      value: surveyRun.survey.id,
    }));
  }, [surveyRuns]);

  const form = useForm({
    mode: "onChange",
  });

  const { handleSubmit, watch, setValue } = form;

  const surveyId = watch("surveyId");

  // Set the default survey run to the first survey run
  useEffect(() => {
    if (surveyOptions.length > 0) {
      const defaultSurveyId = surveyOptions[0]?.value;
      const defaultSurveyRunId =
        surveyRuns
          .filter((surveyRun) => !surveyRun.isActive && !surveyRun.isArchived)
          .find((run) => run.survey.id === defaultSurveyId)?.id || "";

      console.log("defaultSurveyRunId", defaultSurveyRunId);
      setValue("surveyId", defaultSurveyId, {
        shouldValidate: true,
      });
      setValue("surveyRunId", defaultSurveyRunId, {
        shouldValidate: true,
      });
      setSurveyRunId(defaultSurveyRunId);
    }
  }, [surveyOptions, setValue]);

  const onSubmit = (data) => {
    console.log(data);
    setSurveyRunId(data.surveyRunId);
  };

  if (isErrorSurveyRuns) {
    console.log(errorSurveyRuns);
    return <Text>Error loading surveys</Text>;
  }
  return (
    <VStack w={"100%"} alignItems={"flex-start"}>
      <Text fontWeight={800} fontSize={"xl"}>
        Survey Report
      </Text>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Wrap>
            <WrapItem alignItems={"center"}>
              <Text fontWeight={700}>Survey:</Text>
            </WrapItem>
            <WrapItem alignItems={"center"}>
              <SelectField
                field={{
                  id: "surveyId",
                  validation: { required: true },
                }}
                isLoading={isLoadingSurveyRuns}
                options={surveyOptions}
                selectStyles={{
                  input: (provided) => ({
                    ...provided,
                    color: "blue.500",
                    fontWeight: 700,
                  }),
                  control: (provided) => ({
                    ...provided,
                    color: "blue.500",
                    fontWeight: 700,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }),
                }}
                selectProps={{
                  variant: "unstyled",
                }}
              />
            </WrapItem>
            <WrapItem alignItems={"center"}>
              <Text fontWeight={700}>Run:</Text>
            </WrapItem>
            <WrapItem alignItems={"center"}>
              <SurveyRunSelect
                surveyId={surveyId}
                surveyRuns={surveyRuns}
                onSurveyRunSelect={handleSubmit(onSubmit)}
              />
            </WrapItem>
            {/* <WrapItem alignItems={"center"}>
              <Text fontSize={"xl"} fontWeight={"bold"}>{`Report `}</Text>
            </WrapItem> */}
          </Wrap>
        </form>
      </FormProvider>
      {surveyRunMetrics && (
        <HStack spacing={2}>
          <Text fontSize={"sm"}>
            Total Responses: {surveyRunMetrics.numSubmitted}
          </Text>
          <Text fontSize={"sm"}>•</Text>
          <Text fontSize={"sm"}>
            Response Rate:{" "}
            {surveyRunMetrics.numInvited !== 0
              ? (
                  surveyRunMetrics.numSubmitted / surveyRunMetrics.numInvited
                ).toFixed(2) * 100
              : 0}
            %
          </Text>
          {surveyRunMetrics?.surveyRun?.isAnonymous && (
            <Text fontSize={"sm"}>•</Text>
          )}
          {surveyRunMetrics?.surveyRun?.isAnonymous && (
            <Text fontSize={"sm"}>Anonymous Survey</Text>
          )}
        </HStack>
      )}
      <SurveyReportFilters
        tenuresToFilter={tenuresToFilter}
        setTenuresToFilter={setTenuresToFilter}
        departmentsToFilter={departmentsToFilter}
        setDepartmentsToFilter={setDepartmentsToFilter}
        ranksToFilter={ranksToFilter}
        setRanksToFilter={setRanksToFilter}
      />
      <ViewBySelect setViewMode={setViewMode} viewMode={viewMode} />
    </VStack>
  );
};

export const SurveyRunSelect = ({
  surveyId,
  onSurveyRunSelect,
  surveyRuns,
}) => {
  const surveyRunOptions = useMemo(() => {
    if (!surveyRuns) return [];

    return surveyRuns
      .filter(
        (surveyRun) =>
          !surveyRun.isActive &&
          !surveyRun.isArchived &&
          surveyRun?.survey?.id === surveyId
      )
      .map((surveyRun) => ({
        label: surveyRun.label,
        value: surveyRun.id,
      }));
  }, [surveyRuns, surveyId]);

  return (
    <>
      <SelectField
        field={{
          id: "surveyRunId",
          validation: { required: true },
        }}
        // isLoading={!surveyId}
        options={surveyRunOptions}
        key={surveyId}
        onSelectionChange={onSurveyRunSelect}
        selectStyles={{
          input: (provided) => ({
            ...provided,
            color: "blue.500",
            fontWeight: 700,
          }),
          control: (provided) => ({
            ...provided,
            color: "blue.500",
            fontWeight: 700,
            textDecoration: "underline",
            cursor: "pointer",
          }),
        }}
        selectProps={{
          variant: "unstyled",
        }}
      />
    </>
  );
};

export const ViewBySelect = ({ setViewMode }) => {
  const form = useForm({
    mode: "onChange",
    defaultValues: {
      viewBy: "respondentTable",
    },
  });

  return (
    <FormProvider {...form}>
      <form
        // key={selectedViewMode?.length}
        // Prevent form submission on enter key
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        <Stack direction={{ base: "column", sm: "row" }} alignItems={"center"}>
          <Text fontWeight={700} whiteSpace={"nowrap"}>
            View:
          </Text>
          <SelectField
            field={{
              id: "viewBy",
            }}
            options={[
              {
                label: "Graphs",
                value: "graph",
              },
              {
                label: "Question response table",
                value: "questionTable",
              },
              {
                label: "Respondent table",
                value: "respondentTable",
              },
            ]}
            onSelectionChange={(e) => {
              console.log(e);
              setViewMode(e.value);
            }}
            selectStyles={{
              input: (provided) => ({
                ...provided,
                color: "blue.500",
                fontWeight: 700,
              }),
              control: (provided) => ({
                ...provided,
                color: "blue.500",
                fontWeight: 700,
                textDecoration: "underline",
                cursor: "pointer",
              }),
            }}
            selectProps={{
              variant: "unstyled",
            }}
          />
        </Stack>
      </form>
    </FormProvider>
  );
};

export default SurveysReportConfig;
