import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Image,
  Stack,
  Tag,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useMemo } from "react";
import { MdCalendarMonth, MdLocationOn } from "react-icons/md";
import { FormattedDate, FormattedTime } from "react-intl";
import { useParams } from "react-router-dom";
import { useFetchEvent } from "../../api/events/event";
import {
  useCreateEventSignup,
  useDeleteEventSignup,
} from "../../api/events/eventSignup";
import { AvatarWithName } from "../../components/common/AvatarWithName";
import {
  AddToCalendarButton,
  createGoogleCalendarLink,
  createICalData,
} from "../../components/common/Buttons/AddToCalendarButton";
import HtmlViewer from "../../components/common/Editor/HtmlViewer";
import LoadingBox from "../../components/common/LoadingBox";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
} from "../../hooks/customDomainHooks";
import useToastMessage from "../../hooks/useToastMessage";
import PageShell from "../../layout/PageShell";
import EventMenu from "./EventMenu";
import { getEventImage } from "./getEventImage";

const ViewEventPage = () => {
  const { eventId } = useParams();
  const { accountId } = useAccountId();
  const {
    data: event,
    isLoading: isLoadingEvent,
    isError: isErrorFetchingEvent,
    error: errorFetchingEvent,
  } = useFetchEvent(accountId, eventId);
  const createEventSignupMutation = useCreateEventSignup();
  const deleteEventSignupMutation = useDeleteEventSignup();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();

  const { isLoading: isLoadingAccount, currentProfile } =
    useContext(AccountContext);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Events", href: `/${accountId}/events` },
    { name: "View Event" },
  ]);

  const createEventSignup = async () => {
    try {
      await createEventSignupMutation.mutateAsync({
        accountId,
        data: {
          eventId,
        },
      });
      queryClient.invalidateQueries(["fetchEvent", eventId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error signing up for event" });
    }
  };

  const deleteEventSignup = async (eventSignupId) => {
    try {
      await deleteEventSignupMutation.mutateAsync({
        accountId,
        eventSignupId,
      });
      queryClient.invalidateQueries(["fetchEvent", eventId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error removing sign up for event" });
    }
  };

  const canSeeSignups = useMemo(
    () =>
      event?.allowInviteesToSeeOtherInvitees ||
      ["ADMIN", "OWNER", "PARTNER"].includes(currentProfile?.role || "") ||
      event?.creator?.id === currentProfile?.id ||
      event?.planningTeam?.some((member) => member.id === currentProfile?.id),
    [currentProfile, event]
  );

  if (isLoadingEvent) {
    return <LoadingBox />;
  }
  if (isErrorFetchingEvent) {
    return <p>{errorFetchingEvent.message}</p>;
  }

  const datesAreSameDay = (date1, date2) => {
    if (!date1 || !date2) return false;
    const date1Day = new Date(date1).getDate();
    const date1Month = new Date(date1).getMonth();
    const date2Day = new Date(date2).getDate();
    const date2Month = new Date(date2).getMonth();
    return date1Day === date2Day && date1Month === date2Month;
  };

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      headerComponentRight={
        <EventMenu
          event={event}
          eventMenuItems={["EDIT", "COPY", "PUBLISH", "DELETE"]}
        />
      }
    >
      {isLoadingAccount && <LoadingBox />}
      <Stack direction={{ base: "column", md: "row" }}>
        <Box
          p={{ base: 0, md: 4 }}
          w={{ base: "100%", md: "60%" }}
          order={{ base: 1, md: 0 }}
        >
          <Center>
            <Image
              // h={"450px"}
              src={event.imageUrl ? event.imageUrl : getEventImage(event.id)}
              maxW={"700px"}
              w={"100%"}
              alt="Event image"
              borderRadius="lg"
            />
          </Center>
          <Center>
            <Text fontWeight={"bold"} fontSize={"2xl"} my={6}>
              {event.name}
            </Text>
          </Center>
          <HtmlViewer html={event.details} />
        </Box>
        <Box p={4} w={{ base: "100%", md: "40%" }} order={{ base: 0, md: 1 }}>
          <VStack alignItems={"flex-start"} spacing={8} minH={"350px"}>
            <HStack>
              <Icon as={MdCalendarMonth} fontSize={"xl"} />
              <Text fontWeight={"bold"} fontSize={"lg"}>
                {event.startTime ? (
                  <>
                    <FormattedDate
                      value={event.startTime}
                      year="numeric"
                      month="short"
                      day="2-digit"
                    />
                    {` `}
                    <FormattedTime value={event.startTime} />
                    {datesAreSameDay(event.startTime, event.endTime) ? (
                      <>
                        {` - `}
                        <FormattedTime
                          value={event.endTime}
                          timeZoneName="short"
                        />
                      </>
                    ) : (
                      <>
                        {` - `}
                        <FormattedDate
                          value={event.endTime}
                          year="numeric"
                          month="short"
                          day="2-digit"
                        />
                        {` `}
                        <FormattedTime
                          value={event.endTime}
                          timeZoneName="short"
                        />
                      </>
                    )}
                  </>
                ) : (
                  "Start time TBD"
                )}
              </Text>
            </HStack>
            <HStack>
              <Box>
                <Icon as={MdLocationOn} fontSize={"xl"} />
              </Box>
              <Text fontWeight={"bold"} fontSize={"lg"}>
                {event.location ? event.location : "Location TBD"}
              </Text>
            </HStack>
            {!event.myEventSignup && !event.eventIsFull && (
              <Tooltip label={!event.isPublished && "Event still in draft"}>
                <Button
                  onClick={createEventSignup}
                  isDisabled={!event.isPublished}
                  isLoading={createEventSignupMutation.isLoading}
                >
                  Sign me up!
                </Button>
              </Tooltip>
            )}
            {!event.myEventSignup && event.eventIsFull && (
              <Tooltip label={!event.isPublished && "Event still in draft"}>
                <Tag size="xl" p={2} colorScheme="yellow">
                  This event is full!
                </Tag>
              </Tooltip>
            )}
            {event.myEventSignup && (
              <VStack alignItems={"flex-start"} spacing={8}>
                <Stack
                  direction={{ base: "column", xl: "row" }}
                  alignItems={"flex-start"}
                >
                  <Tag size="xl" p={2} colorScheme="green">
                    You're signed up
                  </Tag>
                  <Button
                    onClick={() => {
                      deleteEventSignup(event.myEventSignup.id);
                    }}
                    isLoading={deleteEventSignupMutation.isLoading}
                    variant={"ghost"}
                    size={"xs"}
                  >
                    Remove me
                  </Button>
                </Stack>
                <AddToCalendarButton
                  googleLink={createGoogleCalendarLink({
                    title: event.name,
                    description: event.details,
                    location: event.location,
                    startTime: event.startTime,
                    endTime: event.endTime,
                  })}
                  iCalLink={`data:text/calendar;charset=utf8,${createICalData({
                    title: event.name,
                    description: event.details,
                    location: event.location,
                    startTime: event.startTime,
                    endTime: event.endTime,
                  })}`}
                  buttonProps={{
                    size: "md",
                  }}
                />
              </VStack>
            )}
          </VStack>
          {canSeeSignups && (
            <>
              <Text fontWeight={"bold"} fontSize={"lg"} pt={6} pb={4}>
                Invited{" "}
                {!event.allowInviteesToSeeOtherInvitees &&
                  "(viewable by planning team only)"}
              </Text>
              <Box
                h="500px"
                overflowY="auto"
                borderWidth={1}
                borderRadius="md"
                borderColor={"gray.200"}
                bg={"white"}
              >
                <Signups event={event} />
              </Box>
            </>
          )}
        </Box>
      </Stack>
    </PageShell>
  );
};

const Signups = ({ event }) => {
  const { currentProfile, accountData } = useContext(AccountContext);
  const { signups, invitees, isEveryoneInvited } = event;

  // Alphabetize profiles
  const profiles = accountData?.profiles
    ?.filter((profile) => profile.isActive)
    ?.sort((a, b) => a.name.localeCompare(b.name));

  const invitedList = isEveryoneInvited ? profiles : invitees;

  // Filter out the currentProfile to avoid it being duplicated in the list
  const invitedListWithoutCurrent = invitedList.filter(
    (profile) => profile.id !== currentProfile?.id
  );

  // Separate the signed-up profiles excluding the currentProfile
  const signedUpProfiles = invitedListWithoutCurrent.filter((profile) =>
    signups.some((signup) => signup.profile.id === profile.id)
  );

  // Filter the profiles that are not signed up, excluding the currentProfile
  const notSignedUpProfiles = invitedListWithoutCurrent.filter(
    (profile) => !signups.some((signup) => signup.profile.id === profile.id)
  );

  // Concatenate the currentProfile at the top, followed by signed-up profiles, then not signed-up profiles
  const sortedList = [
    ...(currentProfile.role !== "PARTNER" ? [currentProfile] : []),
    ...signedUpProfiles,
    ...notSignedUpProfiles,
  ];

  return (
    <VStack align="stretch">
      {sortedList.map((profile) => {
        // Check if the profile is the currentProfile
        const isCurrent = profile.id === currentProfile?.id;

        // Determine if the profile is signed up
        const isSignedUp = isCurrent
          ? signups.some((signup) => signup.profile.id === currentProfile.id)
          : signups.some((signup) => signup.profile.id === profile.id);

        return (
          <HStack
            key={profile.id}
            p={2}
            justifyContent="space-between"
            bg={isCurrent ? "blue.50" : "transparent"}
            px={4}
          >
            <AvatarWithName showCheck={isSignedUp} profile={profile} />
          </HStack>
        );
      })}
    </VStack>
  );
};

export default ViewEventPage;
