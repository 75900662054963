import { Button, Text, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { useSetEnterprisePlan } from "../../api/partner/partner";
import { DateField } from "../../components/fields/DateField";
import { InputField } from "../../components/fields/InputField";
import { SelectField } from "../../components/fields/SelectField";
import countries from "../../data/countries";
import useToastMessage from "../../hooks/useToastMessage";

const SetEnterprisePlanForm = ({ partnerAccount, partnerData, onDone }) => {
  const { showSuccessToast, showErrorToast } = useToastMessage();
  const setEnterprisePlanMutation = useSetEnterprisePlan();
  const queryClient = useQueryClient();
  const form = useForm({
    mode: "onChange",
    defaultValues: {
      accountId: partnerAccount?.id,
      pricePlanId: "",
      trialEndDate: "",
      address: {
        line1: "",
        line2: "",
        postal_code: "",
        country: "",
        state: "",
        city: "",
      },
    },
  });

  if (!partnerAccount) return null;
  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting, isValid },
  } = form;

  const setEnterprisePlan = async (data) => {
    const trialEndDate = data.trialEndDate;
    data.trialEndDate = trialEndDate.toISOString();
    try {
      const respData = await setEnterprisePlanMutation.mutateAsync({
        data,
      });
      console.log(respData);
      showSuccessToast("Subscription Plan Set");
      queryClient.invalidateQueries(["fetchPartnerAccounts"]);
      form.reset();
      onDone();
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error setting subscription." });
    }
  };

  const onSubmit = async (data) => {
    await setEnterprisePlan(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4} alignItems={"flex-start"}>
          <SelectField
            field={{
              placeholder: "Select plan",
              id: "pricePlanId",
              validation: { required: true },
              label: "Subscription Plan",
            }}
            options={partnerData.pricePlans.map((plan) => ({
              label: plan.description,
              value: plan.id,
            }))}
            onSelectionChange={async (value) => {
              console.log("Selected value:", value);
              if (!value) return;
              setValue("pricePlanId", value.value, { shouldValidate: true });
            }}
            isLoading={false}
            isClearable={true}
          />
          <DateField
            field={{
              id: `trialEndDate`,
              label: "Trial End Date",
              validation: { required: true },
            }}
          />
          <Text fontWeight={700}>Company Billing Address</Text>
          <InputField
            field={{
              id: "address.line1",
              label: "Address Line 1",
              validation: { required: true },
            }}
          />
          <InputField
            field={{
              id: "address.line2",
              label: "Address Line 2",
              validation: { required: false },
            }}
          />
          <InputField
            field={{
              id: "address.city",
              label: "City",
              validation: { required: true },
            }}
          />
          <InputField
            field={{
              id: "address.state",
              label: "State",
              validation: { required: true },
            }}
          />
          <InputField
            field={{
              id: "address.postal_code",
              label: "Postal Code",
              validation: { required: true },
            }}
          />
          <SelectField
            field={{
              id: "address.country",
              validation: { required: true },
              label: "Country",
            }}
            options={countries.map((country) => ({
              label: country.name,
              value: country.name,
            }))}
            onSelectionChange={async (value) => {
              if (!value) return;
              setValue("address.country", value.value, {
                shouldValidate: true,
              });
            }}
            isLoading={false}
            isClearable={true}
          />
          <Button
            type="submit"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            alignSelf={"flex-end"}
          >
            Set Subscription
          </Button>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default SetEnterprisePlanForm;
