import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import api from "../config";

export enum AiTask {
  IMPROVE = "IMPROVE",
  GRAMMAR = "GRAMMAR",
  SHORTER = "SHORTER",
  LONGER = "LONGER",
  CUSTOM = "CUSTOM",
  CONTINUE_WRITING = "CONTINUE_WRITING",
  WRITE_FOR_ME = "WRITE_FOR_ME",
  AUTHOR_OKR = "AUTHOR_OKR",
  EDIT_OKR = "EDIT_OKR",
  PARSE_OKR = "PARSE_OKR",

  // These tasks are handled locally and don't require a call to the API
  DONE = "DONE",
  CANCEL = "CANCEL",
  TRY_AGAIN_AUTHOR_OKR = "TRY_AGAIN_AUTHOR_OKR",
  TRY_AGAIN = "TRY_AGAIN",
  ACCEPT_OKR = "ACCEPT_OKR",
  REPLACE_SELECTION = "REPLACE_SELECTION",
  INSERT_BELOW = "INSERT_BELOW",
  UNDO = "UNDO",
  REDO = "REDO",
}

export const useGetAICompletion = (
  props?: UseMutationOptions<
    any,
    Error,
    {
      accountId: string;
      data: {
        task: AiTask;
        selectedText?: string;
        contextBefore?: string;
        contextAfter?: string;
        additionalContext?: string;
        instructions?: string;
      };
    }
  >
) =>
  useMutation({
    mutationKey: ["getAICompletion"],
    mutationFn: async (variables) => {
      const response = await api.post(
        `/${variables.accountId}/ai`,
        variables.data
      );
      return response.data;
    },
    ...props,
  });
