import {
  Box,
  HStack,
  Link,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Spinner,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  useCreateSlackIntegration,
  useListSlackChannels,
  useUninstallSlackIntegration,
  useUpdateSlackIntegration,
} from "../../../../api/integrations/slack";
import { SelectField } from "../../../../components/fields/SelectField";
import { SwitchField } from "../../../../components/fields/SwitchField";
import { DeleteSomethingModal } from "../../../../components/modals/DeleteSomethingModal";
import { AccountContext } from "../../../../context/AccountContextComponent";
import useAccountId from "../../../../hooks/customDomainHooks";
import useToastMessage from "../../../../hooks/useToastMessage";
import { SlackWelcomeModal } from "./SlackWelcomeModal";

export const SlackIntegration = () => {
  const { accountData } = useContext(AccountContext);
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const state = urlParams.get("state");
  const createSlackIntegration = useCreateSlackIntegration();
  const uninstallSlackIntegration = useUninstallSlackIntegration();
  const { showErrorToast, showSuccessToast } = useToastMessage();
  const { accountId } = useAccountId();
  const didRun = useRef(false);
  const queryClient = useQueryClient();
  const [isConnected, setIsConnected] = useState(false);
  const [
    isDeleteSlackIntegrationModalOpen,
    setIsDeleteSlackIntegrationModalOpen,
  ] = useState(false);
  const [isSlackWelcomeModalOpen, setIsSlackWelcomeModalOpen] = useState(false);
  const [isInstallingOrUninstalling, setIsInstallingOrUninstalling] =
    useState(false);

  const {
    isOpen: isPopoverOpen,
    onToggle: onPopoverToggle,
    onClose: onPopoverClose,
  } = useDisclosure({
    defaultIsOpen: false,
  });

  const currentUrlWithoutQueryParams =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;

  const redirectUrl = `${currentUrlWithoutQueryParams}?tab=Integrations`;

  const handleCreateSlackIntegration = async () => {
    try {
      setIsInstallingOrUninstalling(true);
      await createSlackIntegration.mutateAsync({
        accountId,
        data: {
          authCode: code,
          redirectUri: redirectUrl,
        },
      });
      showSuccessToast("Slack integration created");
      // remove query params from url
      window.history.replaceState({}, document.title, redirectUrl);
      setIsInstallingOrUninstalling(false);
      onPopoverToggle(); // Open the popover reminder to select a channel
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (e) {
      console.log(e);
      showErrorToast({ message: "Error creating slack integration" });
      setIsInstallingOrUninstalling(false);
    }
  };

  const handleUninstallSlackIntegration = async () => {
    try {
      setIsInstallingOrUninstalling(true);
      await uninstallSlackIntegration.mutateAsync({ accountId });
      showSuccessToast("Slack integration uninstalled");
      setIsInstallingOrUninstalling(false);
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (e) {
      console.log(e);
      showErrorToast({ message: "Error uninstalling slack integration" });
      setIsInstallingOrUninstalling(false);
    }
  };

  useEffect(() => {
    if (!code || !state) return;
    if (state === "slack") {
      if (!didRun.current) {
        handleCreateSlackIntegration();
        didRun.current = true;
      }
    }
  }, [code, state]);

  useEffect(() => {
    if (accountData.slackIntegration?.isActive) {
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
  }, [accountData.slackIntegration]);

  return (
    <VStack align={"flex-start"}>
      {isConnected && (
        <SlackWelcomeModal
          isOpen={isSlackWelcomeModalOpen}
          setIsOpen={setIsSlackWelcomeModalOpen}
        />
      )}

      <DeleteSomethingModal
        isOpen={isDeleteSlackIntegrationModalOpen}
        isDeleting={uninstallSlackIntegration.isLoading}
        setIsOpen={setIsDeleteSlackIntegrationModalOpen}
        headerText={"Remove Slack Integration"}
        bodyText={`Are you sure you want to remove the Slack integration?`}
        deleteFunction={async () => {
          await handleUninstallSlackIntegration();
        }}
        buttonText={"Remove Slack Integration"}
      />
      <HStack justifyContent={"space-between"} w={"100%"} alignItems={"center"}>
        <HStack gap={2}>
          <Text fontSize={"lg"}>Slack</Text>
          <SlackIcon />
        </HStack>
        {accountData.slackIntegration ? (
          <VStack alignItems={"flex-end"}>
            <Link
              onClick={() => setIsDeleteSlackIntegrationModalOpen(true)}
              variant={"ghost"}
              fontSize={"sm"}
              color={"red.500"}
            >
              Remove from Slack
            </Link>
          </VStack>
        ) : (
          <AddToSlackButton
            redirectUrl={redirectUrl}
            isLoading={isInstallingOrUninstalling}
          />
        )}
      </HStack>
      <Text fontSize="sm">
        Connect your Slack workspace to receive notifications about Shoutouts,
        Events, and Surveys. Choose which Slack Channels you want to receive
        notifications in.
      </Text>
      {isConnected && (
        <HStack w={"100%"} justifyContent={"space-between"}>
          <HStack justifyContent={"space-between"} w={"200px"}>
            <Text fontSize={"md"}>Notifications</Text>
            <SlackIntegrationSwitch
              isActive={accountData.slackIntegration?.isActive}
            />
          </HStack>
          <Link
            onClick={() => setIsSlackWelcomeModalOpen(true)}
            variant={"ghost"}
            fontSize={"sm"}
          >
            Let everyone know about this bot
          </Link>
        </HStack>
      )}
      {isConnected && (
        <Popover
          returnFocusOnClose={false}
          isOpen={isPopoverOpen}
          onClose={onPopoverClose}
          placement="right"
          closeOnBlur={true}
        >
          <PopoverContent
            style={{
              _focus: { boxShadow: "none" },
            }}
          >
            <PopoverArrow />
            <PopoverBody
              style={{
                _focus: { boxShadow: "none" },
              }}
            >
              Select a channel to receive shoutout notifications in.
            </PopoverBody>
          </PopoverContent>
          <PopoverAnchor>
            <Box>
              <SlackChannelSelections
                isConnected={isConnected}
                isActive={accountData.slackIntegration?.isActive}
                slackIntegrationData={accountData.slackIntegration}
                setIsSlackWelcomeModalOpen={setIsSlackWelcomeModalOpen}
              />
            </Box>
          </PopoverAnchor>
        </Popover>
      )}
    </VStack>
  );
};

const SlackIntegrationSwitch = ({ isActive }) => {
  const { accountId } = useAccountId();
  const updateSlackIntegrationMutation = useUpdateSlackIntegration();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();

  const handleUpdateSlackIntegration = async (data) => {
    try {
      console.log(data);
      await updateSlackIntegrationMutation.mutateAsync({
        accountId: accountId || "",
        data,
      });
      queryClient.invalidateQueries(["fetchAccount", accountId]);
    } catch (e) {
      console.log(e);
      showErrorToast({ message: "Error updating slack integration" });
    }
  };

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      isActive: isActive,
    },
  });

  const { handleSubmit } = form;

  const onSubmit = async (data) => {
    await handleUpdateSlackIntegration(data);
  };

  return (
    <FormProvider {...form}>
      <form onChange={handleSubmit(onSubmit)}>
        <SwitchField
          field={{
            id: `isActive`,
          }}
        />
      </form>
    </FormProvider>
  );
};

const SlackChannelSelections = ({
  isConnected,
  isActive,
  slackIntegrationData,
  setIsSlackWelcomeModalOpen,
}) => {
  const { accountId } = useAccountId();
  const { data: slackChannels, isLoading: isLoadingSlackChannels } =
    useListSlackChannels(accountId, isConnected);
  const updateSlackIntegrationMutation = useUpdateSlackIntegration();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();

  const handleUpdateSlackIntegration = async (data) => {
    try {
      const hasPreviouslyAddedChannel =
        !!slackIntegrationData?.shoutoutsChannelId;
      await updateSlackIntegrationMutation.mutateAsync({
        accountId: accountId || "",
        data,
      });
      queryClient.invalidateQueries(["fetchAccount", accountId]);

      if (!hasPreviouslyAddedChannel) {
        // open modal to send welcome message
        setIsSlackWelcomeModalOpen(true);
      }
    } catch (e) {
      console.log(e);
      showErrorToast({ message: "Error updating slack integration" });
    }
  };

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      shoutoutsChannelId: slackIntegrationData?.shoutoutsChannelId,
    },
  });

  const { handleSubmit } = form;

  const onSubmit = async (data) => {
    await handleUpdateSlackIntegration(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Tooltip
          label={"All Slack Notifications are off."}
          placement="right"
          isDisabled={isActive}
        >
          <Box>
            <SelectField
              isLoading={isLoadingSlackChannels}
              onSelectionChange={handleSubmit(onSubmit)}
              field={{
                label: "Shoutouts Channel",
                id: "shoutoutsChannelId",
                validation: {
                  required: false,
                },
              }}
              isClearable
              options={slackChannels?.map((channel) => ({
                value: channel.id,
                label: `# ${channel.name}`,
              }))}
              selectProps={{
                isDisabled: !isActive,
              }}
            />
          </Box>
        </Tooltip>
      </form>
    </FormProvider>
  );
};

const AddToSlackButton = ({ redirectUrl, isLoading }) => {
  const slackClientId = import.meta.env.VITE_REACT_APP_SLACK_CLIENT_ID;
  const scopes =
    "channels:history,chat:write,commands,channels:read,channels:join,reactions:read";

  return (
    <Box>
      <a
        href={`https://slack.com/oauth/v2/authorize?scope=${scopes}&client_id=${slackClientId}&redirect_uri=${redirectUrl}&state=slack`}
        style={{
          alignItems: "center",
          color: "#000",
          backgroundColor: "#fff",
          border: "1px solid #000",
          borderRadius: "10px",
          display: "inline-flex",
          fontFamily: "Lato, sans-serif",
          fontSize: "15px",
          fontWeight: "600",
          height: "40px",
          justifyContent: "center",
          textDecoration: "none",
          width: "180px",
        }}
      >
        {isLoading ? (
          <Spinner size={"sm"} />
        ) : (
          <>
            <Box mr={"12px"}>
              <SlackIcon />
            </Box>
            Add to Slack
          </>
        )}
      </a>
    </Box>
  );
};

export const SlackIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        height: "20px",
        width: "20px",
        // marginRight: "12px",
      }}
      viewBox="0 0 122.8 122.8"
    >
      <path
        d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
        fill="#e01e5a"
      ></path>
      <path
        d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
        fill="#36c5f0"
      ></path>
      <path
        d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
        fill="#2eb67d"
      ></path>
      <path
        d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
        fill="#ecb22e"
      ></path>
    </svg>
  );
};
