import { Box, Button, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import ObjectiveIcon from "../../assets/icons/archive.svg?react";
import { ObjectiveFilters } from "./Objectives/ObjectiveFilters";
import useOKRs from "./hooks/useOkrs";
import SearchBar from "../../components/common/SearchBar";
import { ObjectiveSetTitle } from "./ObjectiveSetTitle";
import { ObjectiveSet } from "./Objectives/ObjectiveSet";
import { ViewMyKrsOnlyCheckbox } from "./ViewMyKrsOnlyCheckbox";
import { useContext, useState } from "react";
import ExpandAllButton from "./ExpandAllButton";
import { AccountContext } from "../../context/AccountContextComponent";

const CompanyOkrs = () => {
  const {
    objectiveSets,
    isLoadingObjectives,
    isErrorObjectives,
    timePeriodsToFilter,
    setTimePeriodsToFilter,
    ownersToFilter,
    setOwnersToFilter,
    createObjective,
    searchObjectives,
    onlyShowMyKrs,
    setOnlyShowMyKrs,
    isCreatingObjective,
    newlyCreatedObjectiveId,
    setNewlyCreatedObjectiveId,
  } = useOKRs("company", "COMPANY", "");
  // Default to undefined so that the don't collapse all on first load.
  // This is needed for expanding certain objectives on load
  const [expandAll, setExpandAll] = useState(undefined);
  const { currentProfile } = useContext(AccountContext);
  // render count is required to trigger re-render of objective
  const [renderCount, setRenderCount] = useState(0);

  if (isErrorObjectives) {
    return (
      <Box w={"100%"} align={"center"}>
        <Text>Something went wrong</Text>
      </Box>
    );
  }

  const canCreateCompanyObjective = ["ADMIN", "OWNER", "PARTNER"].includes(
    currentProfile?.role
  );

  return (
    <VStack alignItems={"flex-start"} spacing={6} w={"100%"}>
      <HStack w={"100%"} spacing={6}>
        <SearchBar onSearch={(term) => searchObjectives(term)} />
        <VStack alignItems={"flex-end"}>
          <ViewMyKrsOnlyCheckbox
            onlyShowMyKrs={onlyShowMyKrs}
            setOnlyShowMyKrs={setOnlyShowMyKrs}
            setExpandAll={setExpandAll}
            expandAll={expandAll}
          />
          <ExpandAllButton expandAll={expandAll} setExpandAll={setExpandAll} />
        </VStack>
      </HStack>
      <ObjectiveFilters
        timePeriodsToFilter={timePeriodsToFilter}
        setTimePeriodsToFilter={setTimePeriodsToFilter}
        ownersToFilter={ownersToFilter}
        setOwnersToFilter={setOwnersToFilter}
        showOwnerFilter={false}
      />
      {isLoadingObjectives ||
        (objectiveSets === undefined && (
          <Box h={"100px"} w={"100%"} align={"center"}>
            <Spinner />
          </Box>
        ))}
      {!isLoadingObjectives && objectiveSets && (
        <VStack spacing={4} w={"100%"} minW={"500px"}>
          {!objectiveSets?.length && (
            <Box w={"100%"} align={"center"}>
              <Text color={"gray.600"}>
                {`There are no OKRs to display. If you expected to see some, check
                your filters. ${
                  canCreateCompanyObjective
                    ? `Or create one by clicking the button below!`
                    : ""
                }`}
              </Text>
            </Box>
          )}
          <Box
            w={"100%"}
            py={0}
            borderRadius={"md"}
            border={"1px"}
            borderColor={"#4285F433"}
            bg={"#4285F41F"}
            overflow={"hidden"}
          >
            {objectiveSets?.map((companyObjectiveSet) => (
              <Box w={"100%"} py={0} px={0} key={companyObjectiveSet.id}>
                <ObjectiveSetTitle objectiveSet={companyObjectiveSet} />
                <ObjectiveSet
                  objectiveSet={companyObjectiveSet}
                  objectiveOwnerType={"COMPANY"}
                  tabName={"COMPANY"}
                  key={companyObjectiveSet.id + renderCount}
                  newlyCreatedObjectiveId={newlyCreatedObjectiveId}
                  setNewlyCreatedObjectiveId={setNewlyCreatedObjectiveId}
                  createObjective={createObjective}
                  expandAll={expandAll}
                />
              </Box>
            ))}
          </Box>
        </VStack>
      )}

      {canCreateCompanyObjective && (
        <Button
          onClick={() => {
            setExpandAll(false);
            setRenderCount((prevCount) => prevCount + 1);
            createObjective();
          }}
          isLoading={isCreatingObjective}
          leftIcon={<ObjectiveIcon fill={"white"} />}
        >
          Add New Company OKR
        </Button>
      )}
    </VStack>
  );
};

export default CompanyOkrs;
