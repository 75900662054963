import { useQuery } from "@tanstack/react-query";
import api from "../config";

export const useFetchLatestQuestionFamilyResponseMetrics = (
  accountId: string,
  familyId: string
) => {
  return useQuery<any>({
    queryKey: ["fetchLatestQuestionFamilyResponseMetrics", familyId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/metrics/questionFamily/${familyId}`
      );
      return response.data;
    },
  });
};

export const useFetchLatestSingleQuestionSurveyResponseMetrics = (
  accountId: string,
  surveyId: string
) => {
  return useQuery<any>({
    queryKey: ["fetchLatestQuestionFamilyResponseMetrics", surveyId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/survey/${surveyId}/single-question-metrics`
      );
      return response.data;
    },
  });
};

export const useFetchTimeSeriesQuestionFamilyResponseMetrics = (
  accountId: string,
  familyId: string
) => {
  return useQuery<any>({
    queryKey: ["fetchTimeSeriesQuestionFamilyResponseMetrics", familyId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/metrics/questionFamily/${familyId}/all`
      );
      return response.data;
    },
  });
};
