import { Avatar, Box, Center, HStack, Text } from "@chakra-ui/react";

export const ObjectiveSetTitle = ({ objectiveSet }) => {
  const getOwner = (objectiveSet) => {
    switch (objectiveSet?.ownerType) {
      case "PROFILE":
        return objectiveSet?.owner?.name;
      case "TEAM":
        return objectiveSet?.team?.name;
      case "DEPARTMENT":
        return objectiveSet?.department?.name;
      default:
        return "Company OKRs";
    }
  };

  const ownerLabel = getOwner(objectiveSet);
  const timePeriodLabel = objectiveSet?.timePeriod?.label || "";

  return (
    <Box h={"24px"} alignItems={"center"}>
      <Center h={"100%"}>
        <HStack spacing={1}>
          {objectiveSet?.ownerType === "PROFILE" && (
            <Avatar
              key={objectiveSet?.owner?.user?.photoUrl}
              h={"22px"}
              w={"22px"}
              p={"1px"}
              src={objectiveSet?.owner?.user?.photoUrl}
              name={objectiveSet?.owner?.user?.name}
              style={{ maxHeight: "22px" }}
            />
          )}
          <Text textAlign={"center"} fontWeight={800} fontSize={"11px"}>
            {ownerLabel} - {timePeriodLabel}
          </Text>
        </HStack>
      </Center>
    </Box>
  );
};
