
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import EditUser from "./EditUser";
import { LocaleContextComponent } from "../../context/LocaleContextComponent";

export const UserModal = (props) => {
  const { isOpen, setIsOpen } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      size={"4xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>My Profile</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <LocaleContextComponent>
            <EditUser />
          </LocaleContextComponent>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
