import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { Team, TeamMembership } from "./types";

export const useFetchTeams = (accountId: string) => {
  return useQuery<Team[]>({
    queryKey: ["fetchTeams"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/teams`);
      return response.data;
    },
  });
};

export const useFetchTeam = (accountId: string, teamId: string) => {
  return useQuery<Team>({
    queryKey: ["fetchTeam", teamId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/teams/${teamId}`);
      return response.data;
    },
  });
};

export const useFetchMyTeam = (accountId: string) => {
  return useQuery<Team>({
    queryKey: ["fetchMyTeam"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/teams/myTeam`);
      return response.data;
    },
  });
};

export const useCreateTeam = (
  props?: UseMutationOptions<Team, Error, { accountId: string; data: any }>
) =>
  useMutation({
    mutationKey: ["createTeam"],
    mutationFn: async ({
      accountId,
      data,
    }: {
      accountId: string;
      data: any;
    }) => {
      const response = await api.post(`/${accountId}/teams`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateTeam = (
  props?: UseMutationOptions<
    Team,
    Error,
    { accountId: string; teamId: string; data: any }
  >
) =>
  useMutation({
    mutationKey: ["updateTeam"],
    mutationFn: async ({
      accountId,
      teamId,
      data,
    }: {
      accountId: string;
      teamId: string;
      data: any;
    }) => {
      const response = await api.put(`/${accountId}/teams/${teamId}`, data);
      return response.data;
    },
    ...props,
  });

export const useAddTeamMembers = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId: string; teamId: string; data: TeamMembership[] }
  >
) =>
  useMutation({
    mutationKey: ["addTeamMembers"],
    mutationFn: async ({
      accountId,
      teamId,
      data,
    }: {
      accountId: string;
      teamId: string;
      data: TeamMembership[];
    }) => {
      const response = await api.put(
        `/${accountId}/teams/${teamId}/addMembers`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useSetTeamMembers = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId: string; teamId: string; data: TeamMembership[] }
  >
) =>
  useMutation({
    mutationKey: ["setTeamMembers"],
    mutationFn: async ({
      accountId,
      teamId,
      data,
    }: {
      accountId: string;
      teamId: string;
      data: TeamMembership[];
    }) => {
      const response = await api.put(
        `/${accountId}/teams/${teamId}/setMembers`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useRemoveTeamMembers = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId: string; teamId: string; data: TeamMembership[] }
  >
) =>
  useMutation({
    mutationKey: ["removeTeamMembers"],
    mutationFn: async ({
      accountId,
      teamId,
      data,
    }: {
      accountId: string;
      teamId: string;
      data: TeamMembership[];
    }) => {
      const response = await api.delete(
        `/${accountId}/teams/${teamId}/removeMembers`,
        { data }
      );
      return response.data;
    },
    ...props,
  });
