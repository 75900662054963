import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import api from "../config";
import { Question } from "./types";

export const useUpdateQuestion = (
  props?: UseMutationOptions<
    any,
    unknown,
    { accountId: string; questionId: string; data: any },
    unknown
  >
) =>
  useMutation<
    Question,
    unknown,
    { accountId: string; questionId: string; data: any }
  >({
    mutationKey: ["updateQuestion"],
    mutationFn: async ({ accountId, questionId, data }) => {
      const response = await api.put(
        `/${accountId}/question/${questionId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useCreateQuestion = (
  props?: UseMutationOptions<
    any,
    unknown,
    { accountId: string; data: any },
    unknown
  >
) =>
  useMutation<Question, unknown, { accountId: string; data: any }>({
    mutationKey: ["createQuestion"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/question`, data);
      return response.data;
    },
    ...props,
  });

export const useDeleteQuestion = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId?: string; questionId: string }
  >
) =>
  useMutation({
    mutationKey: ["deleteQuestion"],
    mutationFn: async ({ accountId, questionId }) => {
      const response = await api.delete(`/${accountId}/question/${questionId}`);
      return response.data;
    },
    ...props,
  });
