import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { BiArchive, BiDotsVerticalRounded } from "react-icons/bi";
import { IoPersonAdd } from "react-icons/io5";
import { useRemoveDepartmentMembers } from "../../../api/departments/department";
import CustomTable from "../../../components/table/CustomTableV2";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import { AddDepartmentMemberModal } from "./modals/AddDepartmentMemberModal";

const DepartmentMembers = ({ members, allowEdit, departmentId }) => {
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const removeDepartmentMembersMutation = useRemoveDepartmentMembers();
  const { showErrorToast, showSuccessToast } = useToastMessage();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();

  const removeDepartmentMembers = async (profileIds) => {
    try {
      console.log(profileIds);
      const membersToRemove = profileIds.map((profileId) => {
        return {
          id: profileId,
        };
      });
      await removeDepartmentMembersMutation.mutateAsync({
        accountId,
        departmentId,
        data: { members: membersToRemove },
      });
      queryClient.invalidateQueries(["fetchDepartment", departmentId]);
      queryClient.invalidateQueries(["getMe"]);
      showSuccessToast(`Member removed from department`);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error removing member from department" });
    }
  };

  const editAndViewColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ cell }) => (
          <Box textAlign={"right"}>
            <Menu>
              <MenuButton
                isLoading={removeDepartmentMembersMutation.isLoading}
                as={IconButton}
                variant="outline"
                icon={<BiDotsVerticalRounded size={22} />}
              ></MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    console.log(cell.row.original.id);
                    removeDepartmentMembers([cell.row.original.id]);
                  }}
                  icon={
                    <Center>
                      <Icon as={BiArchive} fontSize={"xl"} />
                    </Center>
                  }
                  color={"red.500"}
                >
                  Remove
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        ),
      },
    ],
    []
  );

  const inviteButton = (
    <HStack alignItems={"flex-end"}>
      <Button
        w={"180px"}
        leftIcon={<IoPersonAdd />}
        onClick={() => setIsAddMemberModalOpen(true)}
      >
        Add Members
      </Button>
    </HStack>
  );

  return (
    <Box w={"100%"}>
      <AddDepartmentMemberModal
        isOpen={isAddMemberModalOpen}
        setIsOpen={setIsAddMemberModalOpen}
        currentMembers={members}
        departmentId={departmentId}
      />
      <Box>
        <CustomTable
          columns={editAndViewColumns}
          data={members}
          rightElement={allowEdit && inviteButton}
          doUsePagination={true}
          selectionType={"none"}
          boxStyle={{
            bg: "white",
            borderRadius: "10px",
            border: "1px",
            borderColor: "gray.200",
            p: 4,
            overflow: "auto",
          }}
          tableStyle={{
            size: "md",
          }}
          tableId={"viewDepartmentMembersTable"}
        />
      </Box>
    </Box>
  );
};

export default DepartmentMembers;
