import { Button, HStack, IconButton, Input, VStack } from "@chakra-ui/react";
import AddIcon from "../../assets/icons/add.svg?react";
import MinusIcon from "../../assets/icons/minus.svg?react";
import { debounce } from "lodash";
import { useCallback } from "react";

const ZoomButtons = ({ chart, top, right, filter }) => {
  const debounceFilter = useCallback(
    debounce((event) => {
      filter(event);
    }, 400),
    [filter]
  );

  return (
    <HStack
      position="absolute"
      top={top || "150px"}
      right={right || "4"}
      pl={8}
      alignItems={"flex-start"}
      justifyContent={"space-between"}
      w={"100%"}
    >
      <Input
        type="search"
        placeholder="Search"
        onChange={debounceFilter}
        w={"200px"}
        pl={4}
      />

      <VStack alignItems={"flex-end"}>
        <IconButton
          w={"40px"}
          h={"40px"}
          onClick={() => {
            chart?.zoomIn();
          }}
          icon={<AddIcon stroke={"#000000"} />}
          variant={"outline"}
          bg={"white"}
          size={"sm"}
        ></IconButton>
        <IconButton
          w={"40px"}
          h={"40px"}
          onClick={() => {
            chart?.zoomOut();
          }}
          icon={<MinusIcon stroke={"#000000"} />}
          variant={"outline"}
          bg={"white"}
          size={"sm"}
        ></IconButton>
        <Button
          w={"40px"}
          h={"40px"}
          onClick={() => {
            chart?.fit();
          }}
          icon={<MinusIcon stroke={"#000000"} />}
          variant={"outline"}
          bg={"white"}
          size={"sm"}
        >
          Fit
        </Button>
      </VStack>
    </HStack>
  );
};

export default ZoomButtons;
