import { Box } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { useFetchAllAbcRankingReportMetrics } from "../../../api/abcRankings/abcRankingReport";
import CustomTable from "../../../components/table/CustomTableV2";
import { AccountContext } from "../../../context/AccountContextComponent";
import useAccountId from "../../../hooks/customDomainHooks";

export const TeamAlignmentFullDataTable = ({
  departmentsToFilter,
  tenuresToFilter,
  timePeriod,
}) => {
  const { accountId } = useAccountId();
  const { aRankLabel, bRankLabel, cRankLabel } = useContext(AccountContext);

  const { data: employeeAbcRankingMetrics } =
    useFetchAllAbcRankingReportMetrics(
      accountId,
      departmentsToFilter,
      tenuresToFilter,
      timePeriod
    );

  const dataColumns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "employee.name",
      },
      {
        Header: "Ranking",
        accessor: "ranking",
      },
      {
        Header: "Department",
        accessor: "employee.department.name",
      },
      {
        Header: "Manager",
        accessor: "manager.name",
      },
      {
        Header: "Ranking Period",
        accessor: "rankingPeriod.period.label",
      },
    ];
  }, [employeeAbcRankingMetrics]);

  const formattedData = useMemo(() => {
    if (!employeeAbcRankingMetrics) return [];
    const rankingLabels = {
      A: aRankLabel,
      B: bRankLabel,
      C: cRankLabel,
      Unranked: "Unranked",
    };
    return employeeAbcRankingMetrics?.map((metric) => {
      return {
        ...metric,
        ranking: rankingLabels[metric.ranking] || "Unranked",
      };
    });
  }, [employeeAbcRankingMetrics, aRankLabel, bRankLabel, cRankLabel]);

  return (
    <>
      <Box w={"100%"}>
        <CustomTable
          columns={dataColumns}
          data={formattedData || []}
          // initialState={{
          //   sortBy: [{ name: "employee.name", desc: false }],
          // }}
          showSearchBar={true}
          showDownloadLink={true}
          csvFileName={`team_alignment`.replaceAll(" ", "_").toLowerCase()}
          csvMapping={{
            Name: "employee.name",
            Ranking: "ranking",
            Department: "employee.department.name",
            Manager: "manager.name",
            "Ranking Period": "rankingPeriod.period.label",
          }}
          doUsePagination={true}
          selectionType={"none"}
          boxStyle={{
            bg: "white",
            borderRadius: "10px",
            border: "1px",
            borderColor: "gray.200",
            p: 4,
            overflow: "auto",
          }}
          tableStyle={{
            size: "md",
          }}
          tableId={"teamAlignmentFullDataTable"}
        />
      </Box>
    </>
  );
};
