import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Text,
  Textarea,
  TextareaProps,
  VStack,
} from "@chakra-ui/react";
import { Editor } from "@tiptap/core";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import Magic from "../ui/MagicIcon";
import { MdArrowUpward } from "react-icons/md";
import ResizeTextarea from "react-textarea-autosize";
import { addAIHighlight, removeAIHighlight } from "../extensions/AiHighlight";
import { AiTask } from "../../../../api/ai/ai";
import {
  AiContextTask,
  TipTapAiContext,
} from "../../../../context/TipTapAiContextComponent";
import { AiAnimation } from "../ui/AiAnimation";
import ReturnIcon from "../../../../assets/icons/return.svg?react";
import TipTapEditor from "../TipTapEditor";
import { useAiMenuOptions } from "./useAiMenuOptions";
import { useAiTaskSelection } from "./useAiTaskSelection";

interface AskAiSelectorProps {
  editor: Editor;
  isOpen: boolean;
  autofocus?: boolean;
  placeholder?: string;
  setStayClosed?: (val: boolean) => void;
}

export const AskAiSelector: FC<AskAiSelectorProps> = ({
  editor,
  autofocus = true,
  placeholder = "Ask AI to edit or generate...",
  setStayClosed,
}) => {
  const [hasCompletion, setHasCompletion] = useState(false);
  const [customInstruction, setCustomInstruction] = useState<string>("");

  const { setOpenAiMenu, contextTask, setOpenOKRAiMenu, setKey } =
    useContext(TipTapAiContext);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isViewingOkrCompletion, setIsViewingOkrCompletion] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const firstButtonRef = useRef<HTMLButtonElement>(null);
  const [previewEditor, setPreviewEditor] = useState<Editor | undefined>(
    undefined
  );
  const [selectionIsEmpty, setSelectionIsEmpty] = useState(true);
  const [completions, setCompletions] = useState<string[]>([]);
  const [redoCompletions, setRedoCompletions] = useState<string[]>([]);
  const [previousTask, setPreviousTask] = useState<AiTask>();

  const { dynamicOptions } = useAiMenuOptions({
    editor,
    completions,
    redoCompletions,
    contextTask,
    hasCompletion,
    isViewingOkrCompletion,
    selectionIsEmpty,
    previousTask
  });

  const { handleTaskSelection, isLoading } = useAiTaskSelection({
    editor,
    previewEditor,
    hasCompletion,
    redoCompletions,
    setCompletions,
    setRedoCompletions,
    customInstruction,
    isViewingOkrCompletion,
    setIsViewingOkrCompletion,
    setHasCompletion,
    setSelectedIndex,
    setCustomInstruction,
    textAreaRef,
    previousTask,
    setPreviousTask
  });

  useEffect(() => {
    setSelectionIsEmpty(
      editor.state.doc
        .textBetween(editor.state.selection.from, editor.state.selection.to)
        .trim().length === 0
    );
  }, [editor.state.selection, editor.state.doc, selectionIsEmpty]);

  // Task Button Navigation only
  useEffect(() => {
    const navigationKeys = ["ArrowUp", "ArrowDown", "Enter", "Escape"];
    const optionsFlat = dynamicOptions.flatMap((group) => group.options);
    const onKeyDown = (e: KeyboardEvent) => {
      if (customInstruction.length > 0) return;
      if (previewEditor?.isFocused) return;

      if (navigationKeys.includes(e.key)) {
        e.preventDefault();
        if (e.key === "ArrowUp") {
          setSelectedIndex(
            (selectedIndex + optionsFlat.length - 1) % optionsFlat.length
          );
          return true;
        }
        if (e.key === "ArrowDown") {
          setSelectedIndex((selectedIndex + 1) % optionsFlat.length);
          return true;
        }
        if (e.key === "Enter") {
          handleTaskSelection(optionsFlat[selectedIndex].value);
          return true;
        }
        if (e.key === "Escape") {
          // clear selection
          editor
            ?.chain()
            .setTextSelection({
              from: editor.state.selection.to,
              to: editor.state.selection.to,
            })
            .run();
          editor.chain().focus().run();

          setOpenAiMenu(false);
          setOpenOKRAiMenu({ isOpen: false, autofocus: false });
          setKey((prev: number) => prev + 1);
          removeAIHighlight(editor);
          setStayClosed && setStayClosed(true);
          return true;
        }
        return false;
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [
    selectedIndex,
    setSelectedIndex,
    handleTaskSelection,
    dynamicOptions,
    customInstruction,
    setOpenAiMenu,
    setOpenOKRAiMenu,
    editor,
    setStayClosed,
    setKey,
    previewEditor,
  ]);

  // Focus the textarea when the component mounts
  useEffect(() => {
    setTimeout(() => {
      autofocus && textAreaRef.current?.focus();
    }, 500);
  }, [autofocus, textAreaRef]);

  return (
    <>
      <VStack
        id="ask-ai-selector"
        bg="#f5f9fd"
        rounded="md"
        border="1px solid"
        borderColor={"gray.300"}
        w={{
          base: "100%",
          md: "500px",
          xl: "650px",
        }}
        shadow={"md"}
      >
        <PreviewEditor setPreviewEditor={setPreviewEditor} />
        <HStack
          px={4}
          pb={2}
          pt={2}
          display={"flex"}
          alignItems={"flex-start"}
          w={"100%"}
        >
          {isLoading ? (
            <AiAnimation size={32} />
          ) : (
            <Icon as={Magic} color={"primary"} fontSize={"24px"} />
          )}

          <AutoResizeTextarea
            placeholder={
              isLoading
                ? "AI is thinking..."
                : hasCompletion
                ? "Tell AI what to do next"
                : placeholder
            }
            variant={"unstyled"}
            py={0}
            autoFocus={autofocus}
            ref={textAreaRef}
            onFocus={() => addAIHighlight(editor)}
            value={customInstruction}
            onChange={(e) => setCustomInstruction(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (customInstruction.trim().length === 0) {
                  return;
                }
                if (contextTask === AiContextTask.OKR_OBJECTIVE_TITLE) {
                  handleTaskSelection(AiTask.AUTHOR_OKR);
                  return true;
                } else {
                  handleTaskSelection(AiTask.CUSTOM);
                  return true;
                }
              }
            }}
          />

          <IconButton
            icon={<MdArrowUpward fontSize={"24px"} />}
            aria-label="Ask AI"
            h={"26px"}
            w={"26px"}
            minW={"26px"}
            borderRadius={"50%"}
            p={0}
            isDisabled={customInstruction.length === 0}
            onClick={() => {
              if (contextTask === AiContextTask.OKR_OBJECTIVE_TITLE) {
                handleTaskSelection(AiTask.AUTHOR_OKR);
                return true;
              } else {
                handleTaskSelection(AiTask.CUSTOM);
                return true;
              }
            }}
          />
        </HStack>
      </VStack>
      {!(customInstruction.length > 0) && !isLoading && (
        <Box
          bg="#f5f9fd"
          shadow="md"
          mt={1}
          rounded="lg"
          border="1px solid"
          borderColor={"gray.300"}
          w={"300px"}
        >
          <VStack gap={0} alignItems={"flex-start"}>
            {dynamicOptions.map((optionGroup, groupIndex) => (
              <VStack key={groupIndex} alignItems={"flex-start"} w={"100%"}>
                <Text fontSize={"xs"} fontWeight={500} pl={4} pt={2}>
                  {optionGroup.label}
                </Text>
                {optionGroup.options.map((item, index) => {
                  // Calculate the cumulative index for each button
                  const cumulativeIndex =
                    dynamicOptions
                      .slice(0, groupIndex)
                      .reduce((sum, group) => sum + group.options.length, 0) +
                    index;

                  return (
                    <Button
                      key={cumulativeIndex}
                      onClick={() => {
                        handleTaskSelection(item.value);
                      }}
                      variant={"ghost"}
                      w={"100%"}
                      borderRadius={0}
                      justifyContent={"flex-start"}
                      ref={cumulativeIndex === 0 ? firstButtonRef : null}
                      isActive={selectedIndex === cumulativeIndex}
                      h={"30px"}
                    >
                      <HStack justifyContent={"space-between"} w={"100%"}>
                        <HStack>
                          <Icon as={item.icon} color={"#2F855A"} />
                          <Text fontWeight={500} color={"#4A5568"}>
                            {item.label}
                          </Text>
                        </HStack>
                        <Icon
                          as={ReturnIcon}
                          opacity={selectedIndex === cumulativeIndex ? 1 : 0}
                        />
                      </HStack>
                    </Button>
                  );
                })}
                <Divider w={"100%"} />
              </VStack>
            ))}
          </VStack>
        </Box>
      )}
    </>
  );
};

const PreviewEditor = ({
  setPreviewEditor,
}: {
  setPreviewEditor: React.Dispatch<React.SetStateAction<Editor | undefined>>;
}) => {
  const [show, setShow] = useState(false);
  return (
    <Box display={show ? "" : "none"} width={"100%"}>
      <TipTapEditor
        defaultValue={""}
        onChange={(text) => {
          if (text.trim().length === 0) {
            setShow(false);
          } else {
            setShow(true);
          }
        }}
        useAi={false}
        isOneLine={false}
        showTopBar={false}
        variant="default"
        setEditor={setPreviewEditor}
        placeholder=" "
      />
    </Box>
  );
};

export const AutoResizeTextarea = React.forwardRef<
  HTMLTextAreaElement,
  TextareaProps
>((props, ref) => {
  return (
    <Textarea
      minH="unset"
      overflow="hidden"
      w="100%"
      resize="none"
      ref={ref}
      minRows={1}
      h={"20px"}
      as={ResizeTextarea}
      {...props}
    />
  );
});
