import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../config";
import { Department } from "./types";

interface FetchProps {
  accountId: string;
  departmentId?: string;
}

interface MemberPayload {
  accountId: string;
  departmentId: string;
  data: any;
}

interface MutationProps {
  onSuccess?: (data: Department) => void;
  onError?: (error: unknown) => void;
}

export const useFetchDepartments = (accountId: string) => {
  return useQuery<Department[]>({
    queryKey: ["fetchDepartments", accountId],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/department`);
      return response.data;
    },
  });
};

export const useFetchDepartment = ({ accountId, departmentId }: FetchProps) => {
  return useQuery<Department>({
    queryKey: ["fetchDepartment", departmentId],
    queryFn: async () => {
      const response = await api.get(
        `/${accountId}/department/${departmentId}`
      );
      return response.data;
    },
  });
};

export const useCreateDepartment = (props: MutationProps) =>
  useMutation<
    Department,
    unknown,
    {
      accountId: string;
      data: any;
    }
  >({
    mutationKey: ["createDepartment"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/department`, data);
      return response.data;
    },
    ...props,
  });

export const useUpdateDepartment = (props: MutationProps) =>
  useMutation<
    Department,
    unknown,
    {
      accountId: string;
      data: any;
      departmentId: string;
    }
  >({
    mutationKey: ["updateDepartment"],
    mutationFn: async ({ accountId, departmentId, data }) => {
      const response = await api.put(
        `/${accountId}/department/${departmentId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useAddDepartmentMembers = (props: MutationProps) =>
  useMutation<Department, unknown, MemberPayload>({
    mutationKey: ["addDepartmentMembers"],
    mutationFn: async ({ accountId, departmentId, data }) => {
      const response = await api.put(
        `/${accountId}/department/${departmentId}/addMembers`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useRemoveDepartmentMembers = (props: MutationProps) =>
  useMutation<Department, unknown, MemberPayload>({
    mutationKey: ["removeDepartmentMembers"],
    mutationFn: async ({ accountId, departmentId, data }) => {
      const response = await api.put(
        `/${accountId}/department/${departmentId}/removeMembers`,
        data
      );
      return response.data;
    },
    ...props,
  });
