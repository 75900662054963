import {
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { ObjectiveOwnerFilterSelect } from "./ObjectiveOwnerFilterSelect";
import { ObjectiveTimeFilterSelect } from "./ObjectiveTimeFilterSelect";

export const ObjectiveFilters = ({
  timePeriodsToFilter,
  setTimePeriodsToFilter,
  ownersToFilter,
  setOwnersToFilter,
  showOwnerFilter,
}) => {
  return (
    <Stack direction={{ base: "column", sm: "row" }}>
      <Text fontWeight={700} whiteSpace={"nowrap"}>
        Filtered by:
      </Text>
      <Wrap>
        {timePeriodsToFilter?.map((timePeriod) => (
          <WrapItem key={timePeriod.value}>
            <Tag
              borderRadius="full"
              variant="outline"
              colorScheme="blue"
              bg="#4285F414"
              w={"100px"}
            >
              <TagLabel>{timePeriod.label}</TagLabel>
              <TagCloseButton
                onClick={() => {
                  setTimePeriodsToFilter(
                    timePeriodsToFilter?.filter(
                      (tp) => tp.value !== timePeriod.value
                    )
                  );
                }}
              />
            </Tag>
          </WrapItem>
        ))}
        {ownersToFilter?.map((owner) => (
          <WrapItem key={owner.value}>
            <Tag
              borderRadius="full"
              variant="outline"
              colorScheme="blue"
              bg="#4285F414"
            >
              <TagLabel>{owner.label}</TagLabel>
              <TagCloseButton
                onClick={() => {
                  setOwnersToFilter(
                    ownersToFilter?.filter((o) => o.value !== owner.value)
                  );
                }}
              />
            </Tag>
          </WrapItem>
        ))}
        <WrapItem>
          <ObjectiveTimeFilterSelect
            timePeriodsSelected={timePeriodsToFilter}
            onSelection={(newTimePeriod) => {
              setTimePeriodsToFilter(
                timePeriodsToFilter?.concat(newTimePeriod)
              );
            }}
          />
        </WrapItem>
        {showOwnerFilter && (
          <WrapItem>
            <ObjectiveOwnerFilterSelect
              ownersSelected={ownersToFilter}
              onSelection={(newOwner) => {
                setOwnersToFilter(ownersToFilter?.concat(newOwner));
              }}
            />
          </WrapItem>
        )}
      </Wrap>
    </Stack>
  );
};
