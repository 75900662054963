import { Box, Button, HStack, Tag, Text } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { HiOutlineTemplate } from "react-icons/hi";
import {
  useCreateTemplate,
  useFetchOneOnOneTemplates,
  useUpdateTemplate,
} from "../../../api/oneOnOne/oneOnOneTemplate";
import LoadingBox from "../../../components/common/LoadingBox";
import { InputField } from "../../../components/fields/InputField";
import CustomTable from "../../../components/table/CustomTableV2";
import { AccountContext } from "../../../context/AccountContextComponent";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import { Template } from "./Template";
import { TemplateMenu } from "./TemplateMenu";

const OneOnOneTemplates = () => {
  const { accountId } = useAccountId();
  const {
    data: templates,
    isLoading: isLoadingTemplates,
    isError,
    error,
  } = useFetchOneOnOneTemplates(accountId || "");
  const createTemplateMutation = useCreateTemplate();
  const { currentProfile } = useContext(AccountContext);
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);

  function getNewTemplateTitle() {
    const profileFirstName = currentProfile.name.split(" ")[0];
    const defaultTitle = `${profileFirstName}'s 1-on-1 Template`;
    let maxNumber = 0; // To keep track of the highest number used in the title

    // Loop through the templates to find the highest number used in similar titles
    templates.forEach((template) => {
      if (template.title === defaultTitle) {
        maxNumber = Math.max(maxNumber, 1);
      } else if (template.title.startsWith(defaultTitle + " (")) {
        const titleNumber = parseInt(
          template.title.slice(defaultTitle.length + 2, -1)
        );
        if (!isNaN(titleNumber)) {
          maxNumber = Math.max(maxNumber, titleNumber);
        }
      }
    });

    // If no template with the default title, return it. Otherwise, append the next number
    if (maxNumber === 0) {
      return defaultTitle;
    } else {
      return `${defaultTitle} (${maxNumber + 1})`;
    }
  }

  const createTemplate = async () => {
    try {
      setIsCreatingTemplate(true);
      const title = getNewTemplateTitle();
      await createTemplateMutation.mutateAsync({
        accountId,
        data: {
          title: title,
          type: "Personal",
        },
      });
      setIsCreatingTemplate(false);
      queryClient.invalidateQueries(["fetchOneOnOneTemplates"]);
    } catch (error) {
      setIsCreatingTemplate(false);
      showErrorToast({ message: "Error creating template" });
      console.log(error);
    }
  };

  const duplicateTemplate = async (templateId) => {
    try {
      setIsCreatingTemplate(true);
      const templateToDuplicate = templates.find(
        (template) => template.id === templateId
      );
      if (!templateToDuplicate) {
        showErrorToast({ message: "Error duplicating template" });
        return;
      }

      let title = templateToDuplicate.title + " (Copy)";
      while (templates.some((template) => template.title === title)) {
        title += " (Copy)";
      }

      await createTemplateMutation.mutateAsync({
        accountId,
        data: {
          title: title,
          type: "Personal",
          content: templateToDuplicate.content,
        },
      });
      setIsCreatingTemplate(false);
      queryClient.invalidateQueries(["fetchOneOnOneTemplates"]);
    } catch (error) {
      setIsCreatingTemplate(false);
      showErrorToast({ message: "Error creating template" });
      console.log(error);
    }
  };

  const templateColumns = useMemo(
    () => [
      {
        Header: "Template",
        accessor: "title",
        Cell: ({ isRowExpanded, row }) => {
          const templateData = row.original;
          const isOwnerAdmin = ["OWNER", "ADMIN", "PARTNER"].includes(
            currentProfile?.role
          );

          const canEdit =
            currentProfile.id == templateData.creatorId ||
            (isOwnerAdmin && templateData.type === "Account");

          const otherTemplateTitles = templates
            ?.filter((t) => t.id !== row.original.id)
            ?.map((template) => template.title);

          if (canEdit && isRowExpanded) {
            return (
              <EditableTemplateTitle
                templateData={templateData}
                otherTemplateTitles={otherTemplateTitles}
              />
            );
          } else {
            return (
              <Text fontSize={"lg"} fontWeight={"bold"} noOfLines={1}>
                {templateData.title}
              </Text>
            );
          }
        },
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ value }) => {
          return (
            <HStack>
              <Text>{value}</Text>
              {value === "Personal" && (
                <Tag size={"md"} colorScheme={"yellow"}>
                  Visible only to you
                </Tag>
              )}
            </HStack>
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ cell }) => {
          return (
            <Box textAlign={"right"}>
              <TemplateMenu
                templateId={cell.value}
                canDelete={cell.row.original.type === "Personal"}
                duplicateTemplate={duplicateTemplate}
              />
            </Box>
          );
        },
      },
    ],
    [templates]
  );

  if (isLoadingTemplates) {
    return <LoadingBox />;
  }
  if (isError) {
    return <Text>Error: {error.message}</Text>;
  }

  return (
    <Box maxW={1300}>
      <Text fontSize={"2xl"} fontWeight={"bold"}>
        Templates
      </Text>
      <CustomTable
        selectionType={"row"}
        doUsePagination={true}
        columns={templateColumns}
        data={templates}
        boxStyle={{
          bg: "white",
          borderRadius: "10px",
          border: "1px",
          borderColor: "gray.200",
          p: 4,
          overflow: "auto",
        }}
        tableStyle={{
          size: "md",
        }}
        tableId={"viewTemplatesTable"}
        rightElement={
          <Button
            leftIcon={<HiOutlineTemplate />}
            onClick={createTemplate}
            isLoading={isCreatingTemplate}
          >
            Create Template
          </Button>
        }
        renderRowSubComponent={(row) => {
          return (
            <Box p={4}>
              <Template templateData={row.original} />
            </Box>
          );
        }}
      />
    </Box>
  );
};

const EditableTemplateTitle = ({ templateData, otherTemplateTitles }) => {
  const updateTemplateMutation = useUpdateTemplate();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const { accountId } = useAccountId();

  const form = useForm({
    mode: "onBlur",
    defaultValues: {
      title: templateData.title,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const updateTemplate = async (data) => {
    try {
      const templateId = templateData.id;
      await updateTemplateMutation.mutateAsync({
        accountId,
        data: {
          title: data.title,
        },
        templateId,
      });
      queryClient.invalidateQueries(["fetchOneOnOneTemplates"]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating template" });
    }
  };

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 0),
    [handleSubmit, errors]
  );

  // Only submit if there are no errors
  const submitForm = (event) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateTemplate)(event);
    }
  };

  return (
    <Box>
      <FormProvider {...form}>
        {/* This form is submitted on blur because otherwise it will unfocus on the input */}
        <form onBlur={debouncedSubmit}>
          <Box>
            <InputField
              field={{
                id: "title",
                placeholder: "Template Title",
                validation: {
                  required: {
                    value: true,
                    message: "Title is required",
                  },
                  validate(value) {
                    if (otherTemplateTitles.includes(value)) {
                      return "Title must be unique";
                    }
                    return true;
                  },
                },
              }}
              inputProps={{
                variant: "outline",
                width: "100%",
                // id: `objective-title-${objective.id}`,
                onClick(e) {
                  e.stopPropagation();
                },
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents the default action of creating a new line
                  }
                },
              }}
              inputStyle={{
                // color: "blue.500",
                fontWeight: 700,
                fontSize: "lg",
                // maxW: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "text",
              }}
            />
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default OneOnOneTemplates;
