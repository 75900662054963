import { Box, HStack, Link, Stack, Text, VStack } from "@chakra-ui/react";
import { useContext } from "react";
import EditIcon from "../../../assets/icons/circle_more.svg?react";
import { AccountContext } from "../../../context/AccountContextComponent";
import HtmlViewer from "../Editor/HtmlViewer";
import LinksList from "../LinksList";

export const ViewPageInfo = ({
  pageInfo,
  setPageInfoEditMode,
}: {
  pageInfo: any;
  setPageInfoEditMode: (editMode: boolean) => void;
}) => {
  const { currentProfile } = useContext(AccountContext);
  const canEditPageInfo = ["OWNER", "ADMIN", "PARTNER"].includes(
    currentProfile?.role || ""
  );

  return (
    <HStack justifyContent={"space-between"} w={"100%"}>
      <Stack
        flexDirection={{ base: "column", "2xl": "row" }}
        gap={8}
        w={"100%"}
      >
        <Box minW={"600px"} w={"100%"}>
          <VStack alignItems={"flex-start"} minW={"500px"}>
            <HStack spacing={4}>
              {pageInfo.title && (
                <Text fontSize={22} fontWeight={700} color={"black"}>
                  {pageInfo.title}
                </Text>
              )}
              {canEditPageInfo && (
                <Link
                  textDecoration={"none"}
                  onClick={() => {
                    setPageInfoEditMode(true);
                  }}
                >
                  <HStack spacing={2} align="center">
                    <EditIcon stroke={"green"} fill={"transparent"} />
                    <Text color={"primary"}>Edit Page Header</Text>
                  </HStack>
                </Link>
              )}
            </HStack>
            {pageInfo.content && (
              <HtmlViewer html={pageInfo.content} minHeight={"0px"} />
            )}
          </VStack>
        </Box>

        {pageInfo?.usefulLinks?.length > 0 && (
          <Box
            borderLeft={{ md: "1px" }}
            pl={{ md: 8 }}
            borderColor={{ md: "gray.300" }}
          >
            <ViewUsefulLinks usefulLinks={pageInfo.usefulLinks} />
          </Box>
        )}
      </Stack>
    </HStack>
  );
};

const ViewUsefulLinks = ({
  usefulLinks,
}: {
  usefulLinks: { label: string; url: string }[];
}) => {
  return (
    <VStack spacing={2} alignItems={"flex-start"} minW={"200px"} maxW={"300px"}>
      <Text fontWeight={"bold"}>Useful links</Text>
      <LinksList links={usefulLinks} />
    </VStack>
  );
};
