import { Box, BoxProps, Button, HStack, Text, VStack } from "@chakra-ui/react";
import ArrowRight from "../../../assets/icons/arrow_right.svg?react";
import { SkipButton } from "../components/SkipButton";

const WizardStep = ({
  incrementWizardStep,
  wizardName,
  title,
  text,
  isLastStep,
}: {
  incrementWizardStep: () => void;
  wizardName: string;
  title: string;
  text?: string;
  isLastStep?: boolean;
  boxStyles?: BoxProps
}) => {
  return (
    <Box>
      <Box w={"100%"} pl={"20px"}>
        <VStack alignItems={"flex-start"} spacing={6}>
          <Box w={"475px"}>
            <Text
              color={"white"}
              fontSize={"40px"}
              fontWeight={800}
              textAlign={"start"}
            >
              {title}
            </Text>
          </Box>
          {isLastStep ? (
            <Button onClick={incrementWizardStep}>Done</Button>
          ) : (
            <HStack spacing={4}>
              <Button
                onClick={incrementWizardStep}
                leftIcon={<ArrowRight fill="#fff" />}
              >
                Continue
              </Button>
              <SkipButton wizardName={wizardName} />
            </HStack>
          )}

          <Text
            color={"white"}
            fontSize={"22px"}
            fontWeight={400}
            textAlign={"start"}
          >
            {text}
          </Text>
        </VStack>
      </Box>
    </Box>
  );
};

export default WizardStep;
