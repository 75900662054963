import { Button, Stack, VStack } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useFetchEvent } from "../../../api/events/event";
import LoadingBox from "../../../components/common/LoadingBox";
import { AccountContext } from "../../../context/AccountContextComponent";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
  useCustomDomainNavigate,
} from "../../../hooks/customDomainHooks";
import PageShell from "../../../layout/PageShell";
import EventMenu from "../EventMenu";
import { EditEventInvitesModal } from "../modals/EditEventInvitesModal";
import { EditEventPlanningTeamModal } from "../modals/EditEventPlanningTeamModal";
import { ViewEventSignupsModal } from "../modals/ViewEventSignupsModal";
import EditEventForm from "./EditEventForm";

const EditEventPage = () => {
  const { eventId } = useParams();
  const { accountId } = useAccountId();
  const {
    data: event,
    isLoading: isLoadingEvent,
    isError: isErrorFetchingEvent,
    error: errorFetchingEvent,
  } = useFetchEvent(accountId, eventId);
  const { isLoading: isLoadingAccount, currentProfile } =
    useContext(AccountContext);
  const [showInvitesModal, setShowInvitesModal] = useState(false);
  const [showPlanningTeamModal, setShowPlanningTeamModal] = useState(false);
  const [showSignupsModal, setShowSignupsModal] = useState(false);
  const [, setIsSavingEvent] = useState(false);
  const navigate = useCustomDomainNavigate();

  const canEditEvent =
    ["ADMIN", "OWNER", "PARTNER"].includes(currentProfile?.role || "") ||
    event?.creator?.id === currentProfile?.id ||
    event?.planningTeam?.some((member) => member.id === currentProfile?.id);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Events", href: `/${accountId}/events` },
    { name: "Edit Event" },
  ]);

  if (isLoadingEvent) {
    return <LoadingBox />;
  }
  if (isErrorFetchingEvent) {
    return <p>{errorFetchingEvent.message}</p>;
  }
  if (!event) {
    return <p>Event not found</p>;
  }

  if (!canEditEvent) {
    return (
      <VStack p={4}>
        <p>
          You do not have permission to edit this event. You must be on the
          planning team of the event.
        </p>
        <Button
          variant={"outline"}
          onClick={() => {
            navigate(`/${accountId}/events/${eventId}/view`);
          }}
        >
          View Event
        </Button>
      </VStack>
    );
  }

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      headerComponentRight={
        <Stack direction={{ base: "column-reverse", md: "row" }}>
          <EventMenu
            event={event}
            eventMenuItems={["VIEW", "COPY", "DELETE"]}
          />
        </Stack>
      }
    >
      {isLoadingAccount && <LoadingBox />}
      <EditEventForm
        event={event}
        setEditInvitesModalOpen={setShowInvitesModal}
        setShowPlanningTeamModal={setShowPlanningTeamModal}
        setShowSignupsModal={setShowSignupsModal}
        setIsSavingEvent={setIsSavingEvent}
      />
      <EditEventInvitesModal
        isOpen={showInvitesModal}
        setIsOpen={setShowInvitesModal}
        currentInvited={event.invitees}
      />
      <EditEventPlanningTeamModal
        isOpen={showPlanningTeamModal}
        setIsOpen={setShowPlanningTeamModal}
        event={event}
      />
      <ViewEventSignupsModal
        isOpen={showSignupsModal}
        setIsOpen={setShowSignupsModal}
        currentSignups={event.signups}
      />
    </PageShell>
  );
};

export default EditEventPage;
