import { Box, Grid, GridItem, Icon, Text, Tooltip } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BsBarChartLine, BsMegaphone } from "react-icons/bs";
import { PiSmiley } from "react-icons/pi";
import { useIntl } from "react-intl";
import { useUpdateCultureScoreConfiguration } from "../../api/cultureScore/cultureScore";
import CultureScoreIcon from "../../assets/icons/culturescore.svg?react";
import TeamAlignmentIcon from "../../assets/icons/team-alignment.svg?react";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId from "../../hooks/customDomainHooks";
import useToastMessage from "../../hooks/useToastMessage";
import { CultureScoreMetricRow } from "./CultureScoreMetricRow";

const CultureScoreConfig = () => {
  const { accountId } = useAccountId();
  const { accountData } = useContext(AccountContext);
  const updateCultureScoreConfigMutation = useUpdateCultureScoreConfiguration();
  const { showErrorToast, showSuccessToast } = useToastMessage();
  const queryClient = useQueryClient();
  const intl = useIntl();

  const cultureScoreSettings = [
    {
      icon: <CultureScoreIcon size={26} />,
      label: "Employee Retention",
      module: "RETENTION",
      factors: [
        {
          id: "RETENTION_12_MONTH",
          label: "12 Month Retention",
          units: "% of employees retained",
          displayAs: "PERCENT",
          includeTargetMax: false,
          includeTargetMin: true,
          includeTarget: true,
        },
      ],
    },
    {
      icon: <PiSmiley size={30} />,
      label: "eNPS",
      module: "ENPS",
      factors: [
        {
          id: "ENPS_SURVEY_RUNS",
          label: "eNPS Score",
          units: "-100 to 100",
          displayAs: "NUMBER",
          includeTargetMax: true,
          includeTargetMin: true,
        },
      ],
    },
    {
      icon: <BsMegaphone size={26} />,
      label: "Shoutouts",
      module: "SHOUTOUTS",
      factors: [
        {
          id: "SHOUTOUTS_MONTHLY_UTILIZATION",
          label: "Monthly Utilization",
          units: "shoutouts per employee",
          displayAs: "FLOAT",
          includeTargetMax: true,
          includeTargetMin: true,
        },
        {
          id: "SHOUTOUTS_MONTHLY_UNIQUE_USERS",
          label: "Monthly Unique Users",
          units: "% of employees",
          displayAs: "PERCENT",
          includeTargetMax: false,
          includeTargetMin: true,
        },
      ],
    },
    {
      icon: <BsBarChartLine size={26} />,
      label: "Survey Engagement",
      module: "SURVEYS",
      factors: [
        {
          id: "SURVEY_PARTICIPATION",
          label: "Survey Engagement",
          units: "% responded",
          displayAs: "PERCENT",
          includeTargetMax: false,
          includeTargetMin: true,
        },
      ],
    },
    {
      icon: <Icon as={TeamAlignmentIcon} boxSize={26} />,
      label: "Team Alignment",
      module: "ABC_RANKING",
      factors: [
        {
          id: "ABC_RANKING_YEARLY_UTILIZATION",
          label: "Utilization",
          displayAs: "NUMBER",
          includeTargetMax: false,
          includeTargetMin: false,
        },
        {
          id: "ABC_RANKING_ONTIME_SUBMISSIONS",
          label: "On-Time Submissions",
          units: "% submitted on time",
          displayAs: "PERCENT",
          includeTargetMax: false,
          includeTargetMin: true,
        },
        {
          id: "ABC_RANKING_MOST_RECENT_COVERAGE",
          label: "Coverage",
          units: "% of employees receiving reviews",
          displayAs: "PERCENT",
          includeTargetMax: false,
          includeTargetMin: true,
        },
      ],
    },
    // {
    //   icon: <FiFlag size={26} />,
    //   label: "OKRs",
    //   description: "Scelerisque quis aliquet auctor nisl nulla.",
    //   type: "text",
    //   weight: "3%",
    //   trending: "flat",
    // },
  ];

  const updateCultureScoreConfig = async (data) => {
    try {
      await updateCultureScoreConfigMutation.mutateAsync({
        accountId,
        cultureScoreConfigId: accountData?.defaultCultureScoreConfiguration.id,
        data,
      });
      showSuccessToast("Culture success score weights updated.");
      queryClient.invalidateQueries(["fetchAccount", accountId]);
      queryClient.invalidateQueries(["fetchCultureScoreFactors"]);
    } catch (error) {
      console.log(error);
      showErrorToast({
        message: "Error updating Culture success score weights.",
      });
    }
  };

  const form = useForm({
    mode: "onChange",
    defaultValues: accountData?.defaultCultureScoreConfiguration?.weights,
  });

  const {
    formState: { errors, isDirty },
    handleSubmit,
  } = form;

  useEffect(() => {
    if (!isDirty) {
      form.reset(accountData?.defaultCultureScoreConfiguration?.weights);
    }
  }, [accountData?.defaultCultureScoreConfiguration?.weights]);

  const submitForm = async () => {
    const data = form.getValues();
    if (Object.keys(errors).length === 0) {
      await updateCultureScoreConfig({ weights: data });
    }
  };

  const debouncedSubmit = useCallback(
    debounce(() => {
      submitForm();
    }, 1000),
    [handleSubmit, errors]
  );

  return (
    <Box
      borderRadius={"md"}
      border={"1px"}
      borderColor={"gray.200"}
      boxShadow={"md"}
      mb={4}
      mt={6}
      overflow={"auto"}
      w={"100%"}
    >
      <FormProvider {...form} w={"100%"}>
        <form
          onChange={() => {
            form.trigger();
            debouncedSubmit();
          }}
          w={"100%"}
        >
          <Grid templateColumns="repeat(12, 1fr)" minWidth={"700px"}>
            <GridItem
              colSpan={6}
              p={4}
              bg={"white"}
              borderBottom={"1px"}
              borderColor={"gray.200"}
            >
              <Box pl={4}>
                <Text fontWeight={"bold"}>
                  What makes up a culture success score?
                </Text>
              </Box>
            </GridItem>

            <GridItem
              colSpan={2}
              py={4}
              bg={"white"}
              borderBottom={"1px"}
              borderColor={"gray.200"}
            >
              <Text fontWeight={"bold"}>Score</Text>
            </GridItem>

            <GridItem
              colSpan={2}
              py={4}
              bg={"white"}
              borderBottom={"1px"}
              borderColor={"gray.200"}
            >
              <Tooltip
                label={intl.formatMessage({
                  id: "cultureScoreWeight",
                })}
              >
                <Text fontWeight={"bold"}>Weight</Text>
              </Tooltip>
            </GridItem>

            <GridItem
              colSpan={1}
              py={4}
              bg={"white"}
              borderBottom={"1px"}
              borderColor={"gray.200"}
            >
              <Tooltip
                label={intl.formatMessage({
                  id: "cultureScoreTrending",
                })}
                placement="bottom-end"
              >
                <Text fontWeight={"bold"}>Trending</Text>
              </Tooltip>
            </GridItem>
            <GridItem
              colSpan={1}
              py={4}
              bg={"white"}
              borderBottom={"1px"}
              borderColor={"gray.200"}
            ></GridItem>

            {cultureScoreSettings.map((cultureScoreSetting, index) => (
              <GridItem
                colSpan={12}
                key={cultureScoreSetting.module}
                bg={"white"}
              >
                <CultureScoreMetricRow
                  cultureScoreSetting={cultureScoreSetting}
                  index={index}
                />
              </GridItem>
            ))}
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};

export default CultureScoreConfig;
