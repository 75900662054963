import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import api from "../config";
import { ABCRanking } from "./types";

export interface UpdateEmployeeAbcRankingData {
  accountId: string;
  reportId: string;
  employeeRankingId: string;
  data: {
    note: string;
    ranking: ABCRanking;
  };
}
export const useUpdateEmployeeAbcRanking = (
  props?: UseMutationOptions<any, unknown, UpdateEmployeeAbcRankingData>
) =>
  useMutation<any, unknown, UpdateEmployeeAbcRankingData>({
    mutationKey: ["updateEmployeeAbcRanking"],
    mutationFn: async ({ accountId, reportId, employeeRankingId, data }) => {
      const response = await api.put<any>(
        `/${accountId}/employeeAbcRanking/report/${reportId}/employeeRanking/${employeeRankingId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useSubmitEmployeeRanking = (
  props?: UseMutationOptions<
    any,
    unknown,
    { accountId: string; reportId: string }
  >
) =>
  useMutation<any, unknown, { accountId: string; reportId: string }>({
    mutationKey: ["submitEmployeeRanking"],
    mutationFn: async ({ accountId, reportId }) => {
      const response = await api.put<any>(
        `/${accountId}/abcRankingReports/${reportId}/submit`
      );
      return response.data;
    },
    ...props,
  });
