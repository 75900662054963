import { HStack, Link, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useAccountId from "../../../hooks/customDomainHooks";

export const KbChildPagesList = ({ childPages }) => {
  const navigate = useNavigate();
  const { accountId } = useAccountId();
  return (
    <>
      {childPages.length ? (
        <VStack w={"100%"} alignItems={"flex-start"}>
          {childPages.map((childPage, index) => (
            <HStack fontSize={"sm"} key={index} fontWeight={700}>
              <Text color={"primary"}>•</Text>
              <Link
                onClick={() => {
                  navigate(`/${accountId}/knowledge-base/${childPage.id}/view`);
                }}
              >
                {childPage.title}
              </Link>
            </HStack>
          ))}
        </VStack>
      ) : (
        <Text>No child pages</Text>
      )}
    </>
  );
};
