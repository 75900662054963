import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useCreateEvent } from "../../api/events/event";
import CalIcon from "../../assets/icons/cal.svg?react";
import LoadingBox from "../../components/common/LoadingBox";
import InfoBox from "../../components/common/PageInfo/InfoBox";
import { AccountContext } from "../../context/AccountContextComponent";
import useAccountId, {
  useBreadcrumbsWithCustomDomain,
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import useToastMessage from "../../hooks/useToastMessage";
import PageShell from "../../layout/PageShell";
import MyEvents from "./MyEvents";
import PastEvents from "./PastEvents";
import UpcomingEvents from "./UpcomingEvents";

const EventsPage = () => {
  const { accountId } = useAccountId();
  const { currentProfile, isLoading: isLoadingAccount } =
    useContext(AccountContext);
  const createEventMutation = useCreateEvent();
  const { showErrorToast } = useToastMessage();
  const navigate = useCustomDomainNavigate();
  const [pageInfoEditMode, setPageInfoEditMode] = useState(false);

  const breadcrumbs = useBreadcrumbsWithCustomDomain([
    { type: "HOME", href: `/${accountId}/home` },
    { name: "Events" },
  ]);

  if (isLoadingAccount) {
    return <LoadingBox />;
  }

  const allTabs = [
    {
      name: "Upcoming Events",
      content: <UpcomingEvents />,
      rolesAllowed: ["ADMIN", "OWNER", "PARTNER", "EMPLOYEE"],
    },
    {
      name: "My Events",
      content: <MyEvents />,
      rolesAllowed: ["ADMIN", "PARTNER", "OWNER", "EMPLOYEE"],
    },
    {
      name: "Past Events",
      content: <PastEvents />,
      rolesAllowed: ["ADMIN", "PARTNER", "OWNER"],
    },
  ];

  const profileRole = currentProfile?.role;

  const allowedTabs = allTabs.filter((tab) => {
    return tab.rolesAllowed.includes(profileRole);
  });

  const createEvent = async () => {
    try {
      const eventResp = await createEventMutation.mutateAsync({
        accountId,
        data: {
          name: "My Event",
        },
      });
      navigate(`/${accountId}/events/${eventResp.id}/edit`);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating event" });
    }
  };

  return (
    <PageShell
      breadcrumbs={breadcrumbs}
      menuItems={[
        {
          type: "CREATE_PAGE_INFO",
          pageType: "EVENTS",
        },
      ]}
      headerComponentRight={
        ["ADMIN", "OWNER", "PARTNER"].includes(profileRole) && (
          <Button
            leftIcon={<CalIcon fill={"white"} />}
            onClick={createEvent}
            isLoading={createEventMutation.isLoading}
          >
            Create Event
          </Button>
        )
      }
      setPageInfoEditMode={setPageInfoEditMode}
    >
      <InfoBox
        pageInfoType={"EVENTS"}
        setPageInfoEditMode={setPageInfoEditMode}
        pageInfoEditMode={pageInfoEditMode}
      />

      <Tabs>
        <TabList>
          {allowedTabs.map((tab) => (
            <Tab key={tab.name}>{tab.name}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {allowedTabs.map((tab) => (
            <TabPanel key={tab.name} px={{ base: 0, md: 0 }}>
              {tab.content}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </PageShell>
  );
};

export default EventsPage;
