import { Button, Center, Spinner, Text, VStack } from "@chakra-ui/react";
import { applyActionCode, getAuth, onAuthStateChanged } from "firebase/auth";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../api/config";
import useToastMessage from "../../../hooks/useToastMessage";
import AuthLayout from "../../components/AuthLayout";

function VerifyEmailHandler() {
  const { showErrorToast, showSuccessToast } = useToastMessage();
  const searchParams = new URLSearchParams(useLocation().search);
  const [isVerifying, setIsVerifying] = React.useState(true);
  const [isSendingVerificationEmail, setIsSendingVerificationEmail] =
    React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const actionCode = searchParams.get("oobCode");
  const navigate = useNavigate();
  const auth = getAuth();

  const checkIfVerified = async () => {
    // Need to refresh the token so that the emailVerified property is updated
    try {
      await auth.currentUser?.reload();
      await auth.currentUser?.getIdToken(true);
      if (auth.currentUser?.emailVerified) {
        navigate("/");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in
        const isVerified = await checkIfVerified();
        if (isVerified) {
          return;
        }
        verifyEmail();
      } else {
        // User is signed out
        verifyEmail();
      }
    });

    // Cleanup the subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  const verifyEmail = async () => {
    try {
      await applyActionCode(auth, actionCode || "");
      showSuccessToast("Email verified successfully");
      navigate("/");
    } catch (e) {
      console.error(e);
      setIsVerifying(false);
      showErrorToast({ message: "Failed to verify email" });
      setError("Failed to verify email, please try again.");
    }
  };

  return (
    <AuthLayout>
      <Center p={8}>
        <VStack spacing={4}>
          {isVerifying && <Spinner />}
          <Text>{error}</Text>
          <Button
            variant={"outline"}
            isLoading={isSendingVerificationEmail}
            onClick={async () => {
              try {
                setIsSendingVerificationEmail(true);
                await api.post("/users/sendVerificationEmail");
                setIsSendingVerificationEmail(false);
              } catch (error) {
                console.log(error);
                setIsSendingVerificationEmail(false);
                showErrorToast({
                  message:
                    "Error sending verification email. Please try again later.",
                });
              }
            }}
          >
            Resend Verification Email
          </Button>
        </VStack>
      </Center>
    </AuthLayout>
  );
}

export default VerifyEmailHandler;
