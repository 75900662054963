import {
  Box,
  Button,
  HStack,
  Spinner,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import ObjectiveIcon from "../../assets/icons/archive.svg?react";
import SearchBar from "../../components/common/SearchBar";
import ExpandAllButton from "./ExpandAllButton";
import { ObjectiveSetTitle } from "./ObjectiveSetTitle";
import { ObjectiveFilters } from "./Objectives/ObjectiveFilters";
import { ObjectiveSet } from "./Objectives/ObjectiveSet";
import { ObjectiveSortBy } from "./Objectives/ObjectiveSortBy";
import { ViewMyKrsOnlyCheckbox } from "./ViewMyKrsOnlyCheckbox";
import useOKRs from "./hooks/useOkrs";

const PersonalOkrs = () => {
  const {
    objectiveSets,
    isLoadingObjectives,
    isErrorObjectives,
    timePeriodsToFilter,
    setTimePeriodsToFilter,
    setOwnersToFilter,
    ownersToFilter,
    createObjective,
    searchObjectives,
    onlyShowMyKrs,
    setOnlyShowMyKrs,
    isCreatingObjective,
    newlyCreatedObjectiveId,
    setNewlyCreatedObjectiveId,
    orderBy,
    setOrderBy,
  } = useOKRs("my", "PROFILE", "timePeriod.startDate.desc");
  // Default to undefined so that the don't collapse all on first load.
  // This is needed for expanding certain objectives on load
  const [expandAll, setExpandAll] = useState(undefined);
  // render count is required to trigger re-render of objective
  const [renderCount, setRenderCount] = useState(0);

  if (isErrorObjectives) {
    return (
      <Box w={"100%"} align={"center"}>
        <Text>Something went wrong</Text>
      </Box>
    );
  }

  return (
    <VStack alignItems={"flex-start"} spacing={6} w={"100%"}>
      <HStack w={"100%"} spacing={6}>
        <SearchBar onSearch={(term) => searchObjectives(term)} />
        <VStack>
          <ViewMyKrsOnlyCheckbox
            onlyShowMyKrs={onlyShowMyKrs}
            setOnlyShowMyKrs={setOnlyShowMyKrs}
            setExpandAll={setExpandAll}
            expandAll={expandAll}
          />
          <ExpandAllButton expandAll={expandAll} setExpandAll={setExpandAll} />
        </VStack>
      </HStack>
      <HStack
        divider={<StackDivider borderColor={"gray.400"} />}
        spacing={4}
        alignItems={"flex-start"}
      >
        <ObjectiveSortBy orderBy={orderBy} setOrderBy={setOrderBy} />
        <ObjectiveFilters
          timePeriodsToFilter={timePeriodsToFilter}
          setTimePeriodsToFilter={setTimePeriodsToFilter}
          ownersToFilter={ownersToFilter}
          setOwnersToFilter={setOwnersToFilter}
          showOwnerFilter={true}
        />
      </HStack>
      {isLoadingObjectives && (
        <Box h={"100px"} w={"100%"} align={"center"}>
          <Spinner />
        </Box>
      )}

      {!isLoadingObjectives && objectiveSets && (
        <VStack w={"100%"} spacing={4} minW={"500px"}>
          {!objectiveSets?.length && (
            <Box w={"100%"} align={"center"}>
              <Text color={"gray.600"}>
                There are no OKRs to display. If you expected to see some, check
                your filters. Or create one by clicking the button below!
              </Text>
            </Box>
          )}
          <Box
            w={"100%"}
            py={0}
            borderRadius={"md"}
            border={"1px"}
            borderColor={"#4285F433"}
            bg={"#4285F41F"}
            overflow={"hidden"}
          >
            {objectiveSets?.map((personalObjectiveSet) => (
              <Box w={"100%"} py={0} px={0} key={personalObjectiveSet.id}>
                <ObjectiveSetTitle objectiveSet={personalObjectiveSet} />
                <ObjectiveSet
                  objectiveSet={personalObjectiveSet}
                  objectiveOwnerType={"PROFILE"}
                  tabName={"PERSONAL"}
                  key={personalObjectiveSet.id + renderCount}
                  newlyCreatedObjectiveId={newlyCreatedObjectiveId}
                  setNewlyCreatedObjectiveId={setNewlyCreatedObjectiveId}
                  createObjective={createObjective}
                  expandAll={expandAll}
                />
              </Box>
            ))}
          </Box>
        </VStack>
      )}

      <Button
        onClick={() => {
          setExpandAll(false);
          setRenderCount((prevCount) => prevCount + 1);
          createObjective();
        }}
        isLoading={isCreatingObjective}
        leftIcon={<ObjectiveIcon fill={"white"} />}
      >
        Add New OKR
      </Button>
    </VStack>
  );
};

export default PersonalOkrs;
