import { Box, StackDivider, VStack } from "@chakra-ui/react";
import { SlackIntegration } from "./Slack/SlackIntegration";
import { ApiKeys } from "./ApiKeys/ApiKeys";

const IntegrationsTab = () => {
  return (
    <Box>
      <VStack
        spacing={6}
        alignItems={"flex-start"}
        minW={"350px"}
        maxW={"800px"}
        divider={<StackDivider borderColor="gray.300" />}
      >
        <SlackIntegration />
        <ApiKeys />
      </VStack>
    </Box>
  );
};

export default IntegrationsTab;
