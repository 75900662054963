import {
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  useCreateKeyResult,
  useDeleteKeyResult,
  useUpdateKeyResult,
} from "../../../api/okr/keyResult";
import AddIcon from "../../../assets/icons/add.svg?react";
import SquareMoreIcon from "../../../assets/icons/square_more.svg?react";
import { InputField } from "../../../components/fields/InputField";
import { AccountContext } from "../../../context/AccountContextComponent";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";
import { KeyResultLabel } from "./KeyResultLabel";

export const KeyResultHeader = ({
  objective,
  keyResult,
  canEditKeyResult,
  canEditObjective,
  index,
  isExpanded,
  dragAttributes,
  dragListeners,
  setAccordionIndex,
  focusedKeyResultId,
  setFocusedKeyResultId,
  toggleAccordion,
}) => {
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const createKeyResultMutation = useCreateKeyResult();
  const updateKeyResultMutation = useUpdateKeyResult();
  const { currentProfile } = useContext(AccountContext);
  const [showEditIcons, setShowEditIcons] = useState(false);
  const inputRef = useRef(null);

  const defaultValues = keyResult;

  const form = useForm({
    mode: "onChange",
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = form;

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  const submitForm = (data) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateKeyResult)(data);
    }
  };

  useEffect(() => {
    if (inputRef.current && keyResult.id === focusedKeyResultId) {
      inputRef.current.focus();
    }
  }, [inputRef, keyResult.id, focusedKeyResultId]);

  const updateKeyResult = async (data) => {
    try {
      await updateKeyResultMutation.mutateAsync({
        accountId,
        objectiveId: objective.id,
        keyResultId: keyResult.id,
        data: {
          title: data.title,
        },
      });
      queryClient.invalidateQueries(["fetchKeyResults"]);
      queryClient.invalidateQueries(["fetchObjectives", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating Key Result" });
    }
  };

  useEffect(() => {
    if (keyResult && !isDirty) {
      reset(keyResult);
    }
  }, [keyResult]);

  const createKeyResult = async () => {
    try {
      const resp = await createKeyResultMutation.mutateAsync({
        accountId,
        objectiveId: objective.id,
        data: {
          index: index + 1,
          title: "",
          ownerType: "PROFILE",
          ownerId: currentProfile?.id,
          metrics: {
            type: "PERCENT",
            status: 0,
            goal: 100,
          },
        },
      });
      setAccordionIndex([index + 1]);
      queryClient.invalidateQueries(["fetchObjectives", accountId]);
      setFocusedKeyResultId(resp.id);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error creating Key Result" });
    }
  };

  // Submit the form when form values change
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (!name) return;
      debouncedSubmit();
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, debouncedSubmit]);

  return (
    <Box
      key={keyResult.id}
      onMouseEnter={() => setShowEditIcons(true)}
      onMouseLeave={() => setShowEditIcons(false)}
      h={"100%"}
      w={"100%"}
      py={2}
      pl={{ base: 0, md: 12 }}
    >
      <HStack w={"100%"} spacing={0} alignItems={"flex-start"}>
        <Box
          w={"120px"}
          cursor={"default"}
          onClick={(e) => {
            e.stopPropagation();
          }}
          pr={4}
        >
          {showEditIcons && (
            <HStack justifyContent={"end"}>
              {canEditObjective && (
                <IconButton
                  icon={<AddIcon stroke={"gray"} />}
                  variant={"ghost"}
                  p={0}
                  m={0}
                  size={"xs"}
                  _hover={{ bg: "none" }}
                  isLoading={createKeyResultMutation.isLoading}
                  onClick={() => {
                    createKeyResult();
                  }}
                />
              )}
              <KeyResultMenuButton
                keyResult={keyResult}
                objective={objective}
                canDeleteKr={
                  objective.keyResults.length > 1 && canEditKeyResult
                }
              />
            </HStack>
          )}
        </Box>
        <Box
          onClick={(e) => {
            e.stopPropagation();
            toggleAccordion();
          }}
        >
          <KeyResultLabel
            index={index}
            isExpanded={isExpanded}
            keyResult={keyResult}
            keyResultsOrder={objective.keyResultsOrder}
            progress={keyResult.progress}
            dragAttributes={dragAttributes}
            dragListeners={dragListeners}
            canEditObjective={canEditObjective}
          />
        </Box>
        <Box pl={2} w={"100%"}>
          <FormProvider {...form}>
            <form
              style={{ width: "100%" }}
              // On enter key press, create a new KR
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  // Don't create a new KR if slash command is open
                  const slashCommand = document.querySelector("#slash-command");
                  const askAi = document.querySelector("#ask-ai-selector");
                  if (slashCommand || askAi) {
                    return;
                  }
                  // Check if shift key is not pressed
                  e.preventDefault(); // Prevents the default action of creating a new line
                  createKeyResult();
                }
              }}
            >
              <InputField
                field={{
                  id: "title",
                  placeholder: canEditKeyResult ? "Add Key Result" : "",
                  aiPlaceholder: canEditKeyResult
                    ? 'Add Key Result or type "/" for AI'
                    : "",
                  isReadOnly: !canEditKeyResult,
                }}
                useAi={true}
                aiContext={{
                  context: `You are writing a Key Result for the Objective: ${objective.title}. 
                  Make the key result measurable and achievable. Give the response as text not html. 
                  Don't explain the KR, just the title of it.`,
                }}
                aiMultipleLines={true}
                inputProps={{
                  width: "100%",
                  minHeight: "45px",
                }}
                inputStyle={{
                  cursor: canEditKeyResult ? "text" : "pointer",
                  maxW: "100%",
                  pt: 0,
                  minHeight: "45px",
                  overflow: "hidden",
                }}
                ref={inputRef}
              />
            </form>
          </FormProvider>
        </Box>
        <Box w={"16px"}>
          {updateKeyResultMutation.isLoading && <Spinner size={"xs"} />}
        </Box>
      </HStack>
    </Box>
  );
};

const KeyResultMenuButton = ({ keyResult, objective, canDeleteKr }) => {
  const deleteKeyResultMutation = useDeleteKeyResult();
  const { accountId } = useAccountId();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();

  const deleteKeyResult = async () => {
    try {
      await deleteKeyResultMutation.mutateAsync({
        accountId,
        objectiveId: objective.id,
        keyResultId: keyResult.id,
      });
      queryClient.invalidateQueries(["fetchObjectives", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error deleting Key Result" });
    }
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<SquareMoreIcon stroke={"gray"} />}
        variant={"ghost"}
        p={0}
        m={0}
        size={"xs"}
        _hover={{ bg: "none" }}
        isLoading={deleteKeyResultMutation.isLoading}
      />
      <MenuList>
        <MenuItem
          onClick={() => {
            deleteKeyResult();
          }}
          isDisabled={!canDeleteKr}
        >
          Delete KR
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
