import { Box, Text } from "@chakra-ui/react";

const SurveyLayout = ({ question }) => {
  return (
    <Box p={2}>
      {question.element.type === "HEADER" && (
        <Text fontWeight={"semibold"} fontSize={"lg"}>
          {question.prompt}
        </Text>
      )}
      {question.element.type === "PARAGRAPH" && (
        <Text color={"gray.700"}>{question.prompt}</Text>
      )}
    </Box>
  );
};

export default SurveyLayout;
