import {
  Box,
  Button,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { SlackIcon } from "../../Account/Integrations/Slack/SlackIntegration";
import { useContext } from "react";
import { AccountContext } from "../../../context/AccountContextComponent";

export const LinkToSlackModal = (props) => {
  const { isOpen, setIsOpen } = props;
  const { accountData } = useContext(AccountContext);

  const urlToTbaBot = `slack://channel?team=${
    accountData.slackIntegration?.teamId ||
    accountData.slackIntegration?.enterpriseId
  }&id=${accountData.slackIntegration?.botUserId}`;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Text>Link to Slack</Text>
            <SlackIcon />
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack alignItems={"flex-start"}>
            <HStack gap={1}>
              <Text>{`1.) Open `}</Text>
              <Link href={urlToTbaBot}>The Best Answer app in Slack</Link>
            </HStack>
            <Text>{`2.) Send the following command to the bot:`}</Text>
            <Box
              padding={"12px"}
              width={"100%"}
              backgroundColor={"gray.100"}
              borderRadius={"10px"}
              borderColor={"gray.300"}
              borderWidth={"1px"}
            >
              <Text>{`/tba`}</Text>
            </Box>
            <Text>{`This is a slash command that will link your account. The app will send you a message back.`}</Text>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            onClick={() => {
              setIsOpen(false);
            }}
            variant="outline"
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
