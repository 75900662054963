import { Box, Center, HStack, Icon, Text } from "@chakra-ui/react";
import ArrowsOutIcon from "../../../assets/icons/arrows_out.svg?react";
import ArrowsInIcon from "../../../assets/icons/arrows_in.svg?react";
import { MdDragIndicator } from "react-icons/md";

export const ObjectiveLabel = ({
  index,
  objective,
  isExpanded,
  dragAttributes,
  dragListeners,
  canEditObjective,
  canExpand,
  onClick,
}) => {
  const getObjectiveIndex = () => {
    if (index === null) return "";
    if (index) return String(index);
    return String(objective.index + 1);
  };

  return (
    <Box
      bg={"black"}
      _hover={isExpanded ? { bg: "gray.500" } : { bg: "gray.600" }}
      borderRadius={"md"}
      borderRightRadius={canEditObjective ? "lg" : "md"}
      h={"33px"}
      w={"74px"}
      minW={"74px"}
      display={"flex"}
      onClick={() => {
        onClick && onClick();
      }}
      cursor={"pointer"}
    >
      <Box flex={"1"}>
        <Center h={"100%"}>
          <HStack spacing={"6px"}>
            {canExpand && isExpanded && <ArrowsInIcon fill={"white"} />}
            {canExpand && !isExpanded && <ArrowsOutIcon fill={"white"} />}
            <Text fontSize={"sm"} fontWeight={700} color={"white"}>
              O{getObjectiveIndex()}
            </Text>
          </HStack>
        </Center>
      </Box>
      {canEditObjective && (
        <Box
          {...dragAttributes}
          {...dragListeners}
          style={{ cursor: "grab" }}
          bg={"gray.400"}
          w={"14px"}
          h={"100%"}
          borderRightRadius={"md"}
        >
          <Center h={"100%"}>
            <Icon as={MdDragIndicator} fontSize={"sm"} />
          </Center>
        </Box>
      )}
    </Box>
  );
};
