import Lottie from "react-lottie";
import aiAnimation from "../../../../assets/animations/ai.json";

export const AiAnimation = ({ size }: { size?: number }) => {
  const aiAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: aiAnimation,
  };

  return (
    <Lottie
      options={aiAnimationOptions}
      height={size || 30}
      width={size || 30}
      // style={{
      //   padding: 1,
      // }}
    />
  );
};
