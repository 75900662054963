import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "../config";
import { OneOnOneTemplate } from "./types";

export const useFetchOneOnOneTemplates = (accountId: string) => {
  return useQuery<OneOnOneTemplate[]>({
    queryKey: ["fetchOneOnOneTemplates"],
    queryFn: async () => {
      const response = await api.get(`/${accountId}/oneOnOneTemplate`);
      return response.data;
    },
  });
};

export const useUpdateTemplate = (
  props?: UseMutationOptions<
    any,
    unknown,
    { accountId: string; data: any; templateId: string }
  >
) =>
  useMutation<
    any,
    unknown,
    { accountId: string; data: any; templateId: string }
  >({
    mutationKey: ["updateTemplate"],
    mutationFn: async ({ accountId, data, templateId }) => {
      const response = await api.put(
        `/${accountId}/oneOnOneTemplate/${templateId}`,
        data
      );
      return response.data;
    },
    ...props,
  });

export const useCreateTemplate = (
  props?: UseMutationOptions<any, unknown, { accountId: string; data: any }>
) =>
  useMutation<any, unknown, { accountId: string; data: any }>({
    mutationKey: ["createTemplate"],
    mutationFn: async ({ accountId, data }) => {
      const response = await api.post(`/${accountId}/oneOnOneTemplate`, data);
      return response.data;
    },
    ...props,
  });

export const useArchiveTemplate = (
  props?: UseMutationOptions<
    any,
    Error,
    { accountId: string; templateId: string }
  >
) =>
  useMutation<any, Error, { accountId: string; templateId: string }>({
    mutationKey: ["archiveTemplate"],
    mutationFn: async ({ accountId, templateId }) => {
      const response = await api.delete(
        `/${accountId}/oneOnOneTemplate/${templateId}`
      );
      return response.data;
    },
    ...props,
  });
