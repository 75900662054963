import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { ManageViewersModal } from "./modals/ManageViewersModal";
import { useState } from "react";

const ManageReportMenu = ({
  setViewMode,
  viewMode,
  canEdit,
  canManageReviewers,
  canManageViewers,
  rankingReport,
}) => {
  const [isManageViewersModalOpen, setIsManageViewersModalOpen] =
    useState(false);

  return (
    <Box>
      <ManageViewersModal
        isOpen={isManageViewersModalOpen}
        setIsOpen={setIsManageViewersModalOpen}
        rankingReport={rankingReport}
        canManageReviewers={canManageReviewers}
        canManageViewers={canManageViewers}
      />
      <Menu>
        <MenuButton
          as={IconButton}
          variant={"outline"}
          icon={<BiDotsVerticalRounded size={22} />}
        ></MenuButton>
        <MenuList>
          {canEdit && viewMode !== "EDIT" && (
            <MenuItem
              onClick={() => {
                setViewMode("EDIT");
              }}
            >
              Edit
            </MenuItem>
          )}
          {viewMode !== "VIEW" && (
            <MenuItem
              onClick={() => {
                setViewMode("VIEW");
              }}
            >
              View
            </MenuItem>
          )}
          {canManageReviewers && (
            <MenuItem
              onClick={() => {
                setIsManageViewersModalOpen(true);
              }}
            >
              Manage Reviewers
            </MenuItem>
          )}
          {canManageViewers && !canManageReviewers && (
            <MenuItem
              onClick={() => {
                setIsManageViewersModalOpen(true);
              }}
            >
              Manage Viewers
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default ManageReportMenu;
