import BlueStarIcon from "../assets/icons/blue_star.svg?react";
import GreenCheckIcon from "../assets/icons/green_check.svg?react";

export const STAGES = {
  DRAFT: { label: "Draft", color: "#4A5568", bg: "#4A55682E" },
  ACTIVE: { label: "Active", color: "#4285F4", bg: "#4285F42E" },
  COMPLETE: { label: "Completed", color: "#2F855A", bg: "#2F855A2E" },
  ARCHIVED: { label: "Archived", color: "#9747FF", bg: "#9747FF2E" },
};

export const PROGRESS_OPTIONS = {
  NEW: {
    label: "New",
    bg: "#3181CE2E",
    color: "#4285F4",
    icon: <BlueStarIcon />,
  },
  ON_TRACK: {
    label: "On Track",
    bg: "#2F855A2E",
    color: "primary",
    icon: <GreenCheckIcon />,
  },
  AT_RISK: { label: "At Risk", bg: "#EF00002E", color: "#E20000" },
  WILL_MISS: { label: "Will Miss", bg: "#D0BC0C2E", color: "#D0BC06" },
};

export const PHONE_NUMBER_LABELS = {
  HOME: "Home",
  WORK: "Work",
  MOBILE: "Mobile",
  OTHER: "Other",
};

export const ROLE_LABELS = {
  OWNER: "Owner",
  ADMIN: "Administrator",
  EMPLOYEE: "Employee",
} as {
  [key: string]: string;
};
