import {
  Box,
  Button,
  HStack,
  IconButton,
  Link,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiDuplicate, BiHide, BiShow } from "react-icons/bi";
import { MdOutlineKey } from "react-icons/md";
import { FormattedTime } from "react-intl";
import {
  useCreateApiKey,
  useGetApiKeys,
  useUpdateApiKey,
} from "../../../../api/integrations/apiKeys";
import LoadingBox from "../../../../components/common/LoadingBox";
import { InputField } from "../../../../components/fields/InputField";
import { SwitchField } from "../../../../components/fields/SwitchField";
import CustomTable from "../../../../components/table/CustomTableV2";
import useAccountId from "../../../../hooks/customDomainHooks";
import useToastMessage from "../../../../hooks/useToastMessage";
import { ApiKeyMenu } from "./ApiKeyMenu";

export const ApiKeys = () => {
  return (
    <VStack align={"flex-start"} w={"100%"}>
      <HStack justifyContent={"space-between"} w={"100%"} alignItems={"center"}>
        <Text fontSize={"lg"}>API Keys</Text>
        <CreateApiKeyButton />
      </HStack>
      <Box
        bg={"white"}
        borderRadius={"md"}
        boxShadow={"sm"}
        border={"1px"}
        borderColor={"gray.200"}
        width={"100%"}
        p={4}
        flexDirection={"row"}
        display={"flex"}
        gap={4}
        alignItems={"center"}
      >
        <ZapierIcon />
        <Text fontSize={"sm"}>
          Use API Keys to setup a Zapier integration to sync people from an HRIS
          system you're already using. Learn how to integrate with Zapier{" "}
          <Link
            href={
              "https://fallforward.notion.site/Using-Integrations-603f30a8faac4787be691f4cfe90de7b"
            }
            isExternal={true}
          >
            here
          </Link>
          .
        </Text>
      </Box>
      <ApiKeysTable />
    </VStack>
  );
};

const ZapierIcon = () => {
  // obtained from https://brand.zapier.com/
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        height: "20px",
        width: "70px",
      }}
      viewBox="0 0 500 136"
    >
      <path
        d="M117.414 92.8436L117.344 92.6204L161.801 51.921V32.311H90.4769V51.921H133.079L133.149 52.1089L89.1265 92.8436V112.454H162.517V92.8436H117.414Z"
        fill="#201515"
      />
      <path
        d="M206.296 30.4438C198.069 30.4438 191.364 31.6846 186.181 34.1662C181.354 36.3289 177.287 39.8922 174.509 44.3939C171.852 48.9252 170.183 53.9674 169.613 59.1894L191.254 62.2308C192.037 57.6199 193.603 54.3516 195.951 52.4258C198.621 50.4116 201.911 49.3939 205.251 49.5489C209.643 49.5489 212.829 50.7232 214.81 53.0716C216.771 55.4201 217.769 58.6728 217.769 62.9588V65.0959H196.961C186.369 65.0959 178.76 67.4444 174.134 72.1414C169.507 76.8384 167.198 83.0385 167.206 90.7416C167.206 98.6325 169.515 104.504 174.134 108.355C178.752 112.207 184.561 114.121 191.56 114.097C200.241 114.097 206.919 111.111 211.592 105.138C214.352 101.467 216.323 97.2655 217.381 92.7965H218.168L221.151 112.406H240.761V62.7475C240.761 52.4845 238.056 44.5349 232.647 38.8985C227.237 33.2621 218.454 30.4438 206.296 30.4438ZM213.154 91.6575C210.042 94.2525 205.968 95.5442 200.895 95.5442C196.714 95.5442 193.81 94.8123 192.182 93.3484C191.389 92.6558 190.757 91.797 190.333 90.833C189.909 89.8691 189.702 88.8235 189.728 87.7707C189.681 86.7778 189.838 85.7858 190.187 84.8552C190.536 83.9246 191.071 83.0748 191.759 82.3574C192.503 81.6595 193.381 81.1197 194.34 80.7706C195.299 80.4214 196.318 80.2701 197.337 80.3259H217.804V81.6763C217.846 83.5851 217.448 85.4779 216.642 87.2085C215.835 88.9391 214.642 90.4613 213.154 91.6575Z"
        fill="#201515"
      />
      <path
        d="M359.329 32.311H336.161V112.465H359.329V32.311Z"
        fill="#201515"
      />
      <path
        d="M496.109 32.311C489.565 32.311 484.406 34.6203 480.633 39.2391C477.924 42.5583 475.865 47.4628 474.456 53.9524H473.775L470.746 32.311H450.959V112.454H474.128V71.3548C474.128 66.1725 475.341 62.1996 477.768 59.4362C480.194 56.6728 484.672 55.2911 491.201 55.2911H499.996V32.311H496.109Z"
        fill="#201515"
      />
      <path
        d="M427.991 34.9311C422.582 31.7137 415.76 30.1089 407.524 30.1167C394.561 30.1167 384.415 33.8078 377.088 41.1899C369.76 48.572 366.097 58.7488 366.097 71.7204C365.91 79.5627 367.622 87.3338 371.087 94.3716C374.259 100.643 379.199 105.848 385.296 109.343C391.433 112.89 398.733 114.667 407.195 114.674C414.515 114.674 420.629 113.547 425.537 111.293C430.152 109.268 434.17 106.094 437.209 102.075C440.103 98.129 442.252 93.6879 443.55 88.9701L423.588 83.3806C422.748 86.4485 421.13 89.2474 418.891 91.5064C416.636 93.761 412.859 94.8883 407.559 94.8883C401.258 94.8883 396.561 93.0839 393.468 89.475C391.226 86.8682 389.817 83.1458 389.194 78.3196H443.856C444.079 76.0651 444.196 74.1745 444.196 72.648V67.6574C444.328 60.9531 442.943 54.3056 440.145 48.2119C437.526 42.6607 433.289 38.0313 427.991 34.9311ZM406.855 49.2217C416.249 49.2217 421.576 53.8443 422.836 63.0896H389.605C390.133 60.0048 391.411 57.0972 393.328 54.6233C396.373 51.0144 400.882 49.2139 406.855 49.2217Z"
        fill="#201515"
      />
      <path
        d="M347.993 0.00751876C346.25 -0.0517559 344.513 0.239929 342.885 0.865259C341.257 1.49059 339.772 2.43677 338.517 3.64761C337.28 4.87005 336.31 6.3364 335.669 7.95351C335.028 9.57063 334.73 11.3032 334.795 13.0415C334.732 14.791 335.031 16.5346 335.672 18.1635C336.314 19.7925 337.284 21.2718 338.522 22.5091C339.761 23.7464 341.241 24.7152 342.87 25.3551C344.5 25.995 346.244 26.2922 347.993 26.2282C349.731 26.2939 351.465 25.9968 353.082 25.3559C354.699 24.7151 356.165 23.7444 357.387 22.5058C358.599 21.2544 359.545 19.7709 360.168 18.1444C360.792 16.5179 361.08 14.7822 361.015 13.0415C361.095 11.3111 360.814 9.583 360.188 7.96756C359.563 6.35213 358.608 4.88487 357.384 3.65944C356.159 2.43401 354.693 1.47735 353.078 0.850567C351.463 0.223787 349.735 -0.0593172 348.005 0.0192726L347.993 0.00751876Z"
        fill="#201515"
      />
      <path
        d="M300.14 30.1167C292.25 30.1167 285.909 32.5943 281.118 37.5496C277.571 41.2211 274.969 46.701 273.309 53.9891H272.569L269.54 32.3477H249.753V135.494H272.921V93.0095H273.767C274.474 95.8422 275.465 98.5961 276.726 101.229C278.686 105.518 281.9 109.113 285.944 111.539C290.137 113.827 294.86 114.966 299.635 114.839C309.335 114.839 316.721 111.062 321.794 103.507C326.866 95.9529 329.403 85.4121 329.403 71.8848C329.403 58.8193 326.949 48.5916 322.04 41.2016C317.132 33.8117 309.832 30.1167 300.14 30.1167ZM302.489 88.195C299.788 92.1092 295.561 94.0663 289.807 94.0663C287.435 94.1758 285.07 93.7185 282.91 92.7322C280.749 91.7459 278.855 90.259 277.384 88.3947C274.385 84.6293 272.89 79.4743 272.898 72.9298V71.4033C272.898 64.757 274.393 59.6686 277.384 56.138C280.374 52.6075 284.515 50.8617 289.807 50.9009C295.663 50.9009 299.917 52.7601 302.571 56.4785C305.225 60.197 306.552 65.3833 306.552 72.0373C306.568 78.9184 305.221 84.3043 302.512 88.195H302.489Z"
        fill="#201515"
      />
      <path d="M80.1543 92.8433H0V112.477H80.1543V92.8433Z" fill="#FF4F00" />
    </svg>
  );
};

const CreateApiKeyButton = () => {
  const createApiKeyMutation = useCreateApiKey();
  const { accountId } = useAccountId();
  const [isInstallingOrUninstalling, setIsInstallingOrUninstalling] =
    useState(false);
  const { showErrorToast, showSuccessToast } = useToastMessage();
  const queryClient = useQueryClient();

  const handleCreateZapierIntegration = async (data) => {
    try {
      setIsInstallingOrUninstalling(true);
      await createApiKeyMutation.mutateAsync({
        accountId: accountId || "",
        data,
      });
      showSuccessToast("API Key created");
      setIsInstallingOrUninstalling(false);
      queryClient.invalidateQueries(["getApiKeys", accountId]);
    } catch (e) {
      console.log(e);
      showErrorToast({ message: "Error creating API Key" });
      setIsInstallingOrUninstalling(false);
    }
  };

  return (
    <Button
      onClick={() => {
        handleCreateZapierIntegration({ description: "My API Key" });
      }}
      isLoading={isInstallingOrUninstalling}
      variant={"outline"}
      leftIcon={<MdOutlineKey />}
      backgroundColor={"white"}
      w={"180px"}
    >
      Add API Key
    </Button>
  );
};

const ApiKeysTable = () => {
  const { accountId } = useAccountId();
  const { data: apiKeys, isLoading } = useGetApiKeys(accountId || "");

  const tableColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "description",
        Cell: ({ cell: { row } }) => {
          const description = row.original.description;
          const apiKeyId = row.original.id;

          return (
            <EditableApiKeyDescription
              description={description}
              apiKeyId={apiKeyId}
            />
          );
        },
      },
      {
        Header: "API Key",
        accessor: "key",
        Cell: ({ cell: { row } }) => {
          const apiKey = row.original.key;
          return <ApiKeyText apiKey={apiKey} />;
        },
      },
      {
        Header: "API Secret",
        accessor: "keySecret",
        Cell: ({ cell: { row } }) => {
          const secretKey = row.original.keySecret;
          return <ApiSecretButton secretKey={secretKey} />;
        },
      },
      {
        Header: "Created",
        accessor: "created",
        Cell: ({ cell: { row } }) => {
          const created = row.original.createdAt;
          return (
            <>
              <FormattedTime
                value={created}
                year="numeric"
                month="short"
                day="2-digit"
              />
            </>
          );
        },
      },
      {
        Header: "Status",
        accessor: "active",
        Cell: ({ cell: { row } }) => {
          const { showErrorToast } = useToastMessage();
          const { accountId } = useAccountId();
          const queryClient = useQueryClient();
          const updateApiKeyMutation = useUpdateApiKey();

          const updateApiKey = async (data) => {
            try {
              await updateApiKeyMutation.mutateAsync({
                accountId,
                apiKeyId: row.original.id,
                data,
              });
              queryClient.invalidateQueries(["getApiKeys", accountId]);
            } catch (error) {
              console.log(error);
              showErrorToast({ message: `Error updating API Key` });
            }
          };

          const form = useForm({
            mode: "onChange",
            defaultValues: {
              active: row.original.active,
            },
          });

          const { handleSubmit } = form;

          return (
            <HStack>
              <FormProvider {...form}>
                <form onChange={handleSubmit(updateApiKey)}>
                  <SwitchField
                    field={{
                      id: `active`,
                    }}
                  />
                </form>
              </FormProvider>
              <ApiKeyMenu apiKeyId={row.original.id} />
            </HStack>
          );
        },
      },
    ],
    [apiKeys]
  );

  if (isLoading) {
    return (
      <Box w={"100%"}>
        <LoadingBox height={200} />
      </Box>
    );
  }

  return (
    <>
      {apiKeys?.length > 0 && (
        <Box>
          <CustomTable
            selectionType={"link"}
            doUsePagination={true}
            columns={tableColumns}
            data={apiKeys}
            boxStyle={{
              bg: "white",
              borderRadius: "10px",
              border: "1px",
              borderColor: "gray.200",
              p: 4,
              overflow: "auto",
            }}
            tableStyle={{ size: "sm" }}
            tableId={"zapCredentialsTable"}
            showSearchBar={false}
          />
        </Box>
      )}
    </>
  );
};

const ApiSecretButton = ({ secretKey }) => {
  const { showSuccessToast } = useToastMessage();
  const [showKey, setShowKey] = useState(false);

  return (
    <HStack w={"100%"} justifyContent={"space-between"} gap={0}>
      <Text>{showKey ? secretKey : secretKey?.slice(0, 4) + "****"}</Text>
      <Tooltip label={"Copy API Secret"} placement={"top"}>
        <IconButton
          icon={<BiDuplicate />}
          variant={"ghost"}
          color={"primary"}
          size={"sm"}
          onClick={() => {
            navigator.clipboard.writeText(secretKey || "");
            showSuccessToast("API Secret copied to clipboard");
          }}
        />
      </Tooltip>
      <Tooltip
        label={showKey ? "Hide API Secret" : "Show API Secret"}
        placement={"top"}
      >
        <IconButton
          icon={showKey ? <BiHide /> : <BiShow />}
          variant={"ghost"}
          color={"primary"}
          size={"sm"}
          onClick={() => {
            setShowKey(!showKey);
          }}
        />
      </Tooltip>
    </HStack>
  );
};

const ApiKeyText = ({ apiKey }) => {
  const { showSuccessToast } = useToastMessage();
  const [showKey, setShowKey] = useState(false);

  return (
    <HStack w={"100%"} justifyContent={"space-between"} gap={0}>
      <Text>{showKey ? apiKey : apiKey?.slice(0, 4) + "****"}</Text>
      <Tooltip label={"Copy API Key"} placement={"top"}>
        <IconButton
          icon={<BiDuplicate />}
          variant={"ghost"}
          color={"primary"}
          size={"sm"}
          onClick={() => {
            navigator.clipboard.writeText(apiKey || "");
            showSuccessToast("API Key copied to clipboard");
          }}
        />
      </Tooltip>
      <Tooltip
        label={showKey ? "Hide API Key" : "Show API Key"}
        placement={"top"}
      >
        <IconButton
          icon={showKey ? <BiHide /> : <BiShow />}
          variant={"ghost"}
          color={"primary"}
          size={"sm"}
          onClick={() => {
            setShowKey(!showKey);
          }}
        />
      </Tooltip>
    </HStack>
  );
};

const EditableApiKeyDescription = ({ description, apiKeyId }) => {
  const updateApiKeyMutation = useUpdateApiKey();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastMessage();
  const { accountId } = useAccountId();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      description: description,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const updateApiKey = async (data) => {
    try {
      await updateApiKeyMutation.mutateAsync({
        accountId,
        data,
        apiKeyId,
      });
      queryClient.invalidateQueries(["getApiKeys", accountId]);
    } catch (error) {
      console.log(error);
      showErrorToast({ message: "Error updating API Key" });
    }
  };

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 500),
    [handleSubmit, errors]
  );

  // Only submit if there are no errors
  const submitForm = (event) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateApiKey)(event);
    }
  };

  return (
    <Box>
      <FormProvider {...form}>
        <form onBlur={debouncedSubmit}>
          <Box>
            <InputField
              field={{
                id: "description",
                placeholder: "Name",
                validation: {
                  required: {
                    value: true,
                    message: "Name is required",
                  },
                  validate() {
                    // if (otherTemplateTitles.includes(value)) {
                    //   return "Title must be unique";
                    // }
                    return true;
                  },
                },
              }}
              inputProps={{
                variant: "outline",
                width: "100%",
                // id: `objective-title-${objective.id}`,
                // onClick(e) {
                //   e.stopPropagation();
                // },
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents the default action of creating a new line
                  }
                },
              }}
              inputStyle={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "text",
              }}
            />
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};
