import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useSetQueryParam = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // The useCallback hook is used to memoize the function and ensure
  // it does not get recreated on every render unless necessary.
  const setQueryParam = useCallback(
    (key: string, value: string) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(key, value);

      // We create a new location object for navigation.
      // This ensures the current pathname and hash (if any) are preserved.
      const newLocation = {
        pathname: location.pathname,
        search: searchParams.toString(),
        hash: window.location.hash,
      };

      navigate(newLocation, { replace: true });
    },
    [navigate, location]
  );

  return setQueryParam;
};

export default useSetQueryParam;
