import { Box, Text } from "@chakra-ui/react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const BarGraph = ({
  data,
  colors,
  displayPercentages,
  height,
  xAxisProps,
}: {
  data: any[];
  colors?: any;
  displayPercentages?: boolean;
  height?: number;
  xAxisProps?: any;
}) => {
  // Normalize data to reflect percentages if displayPercentages is true
  const dataModified = data.map((item) => {
    if (displayPercentages) {
      const { label, ...rest } = item;
      const total = Object.values(rest).reduce(
        (a, b) => (a as number) + (b as number),
        0
      );
      const normalizedData = Object.fromEntries(
        Object.entries(rest).map(([key, value]) => [
          key,
          (value as number) / (total as number),
        ])
      );

      return {
        label,
        ...normalizedData,
      };
    } else {
      return item;
    }
  });

  const defaultColors = [
    "#FF6384",
    "#48BB78",
    "#FFCE56",
    "#FF9F40",
    "#36A2EB",
    "#4BC0C0",
    "#9966FF",
    "#FF3D67",
    "#70D4B4",
    "#B2912F",
    "#B778FF",
  ];

  // Tooltip formatter function
  const tooltipFormatter = (value: number) => {
    return displayPercentages ? `${(value * 100).toFixed(0)}%` : value;
  };

  const customizedAxisTick = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: any;
  }) => {
    // Adjust the positioning values as needed
    const offsetX = -45; // Half the width to center it. Adjust as needed.
    const offsetY = -2;

    return (
      <foreignObject x={x + offsetX} y={y + offsetY} width="90" height="50">
        <div
          style={{
            textAlign: "center",
            wordWrap: "break-word",
            fontSize: "14px",
            maxHeight: "45px",
            overflow: "hidden",
          }}
        >
          {payload.value}
        </div>
      </foreignObject>
    );
  };

  if (!dataModified.length) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={height || 290}
      >
        <Text>No data</Text>
      </Box>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={height || 290}>
      <BarChart
        data={dataModified}
        margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="label"
          angle={-45}
          textAnchor="end"
          {...xAxisProps}
          tick={customizedAxisTick}
        />
        <YAxis
          tickFormatter={(tick) =>
            displayPercentages ? `${(tick * 100).toFixed(0)}%` : tick
          }
          allowDecimals={displayPercentages ? true : false}
        />
        <Tooltip formatter={tooltipFormatter} isAnimationActive={false} />
        <Legend wrapperStyle={{ paddingTop: "16px", paddingBottom: "12px" }} />
        {Object.keys(dataModified[0]).map((key, index) => {
          if (key !== "label") {
            return (
              <Bar
                key={key}
                maxBarSize={40}
                dataKey={key}
                fill={
                  colors && colors[key]
                    ? colors[key]
                    : defaultColors[index % defaultColors.length]
                } // Using color from the colors prop if defined, otherwise using a default color
                stackId="a"
              />
            );
          }
          return null;
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarGraph;
