import { Box, Center, Icon, Text, VStack } from "@chakra-ui/react";
import { PiSmileyFill, PiSmileyMehFill } from "react-icons/pi";
import { useCultureScoreData } from "./useCultureScoreData";
import ThinkingIcon from "../../assets/icons/thinking.svg?react";
import useAccountId, {
  useCustomDomainNavigate,
} from "../../hooks/customDomainHooks";
import SampleDataWidget from "../CultureDashboard/widgets/view/SampleDataWidget";

export const getCultureScoreColor = (score) => {
  if (score === undefined) {
    return "gray.400";
  } else if (score < 40) {
    return "#F56565";
  } else if (score < 70) {
    return "#FFD600";
  } else {
    return "#48BB78";
  }
};

export const CultureScoreIndicator = ({ size, isClickable }) => {
  const { currentCultureScore: score, cultureScoreFactors } =
    useCultureScoreData();
  const navigate = useCustomDomainNavigate();
  const { accountId } = useAccountId();

  const smileySize = size === "small" ? 120 : 220;

  const getSmileyFace = (score) => {
    if (score === undefined) {
      return <Icon as={PiSmileyMehFill} boxSize={smileySize} color={"white"} />;
    } else if (score < 40) {
      return <Icon as={ThinkingIcon} boxSize={smileySize} />;
    } else if (score < 70) {
      return <Icon as={PiSmileyMehFill} boxSize={smileySize} color={"white"} />;
    } else {
      return <Icon as={PiSmileyFill} boxSize={smileySize} color={"white"} />;
    }
  };

  const getScoreText = (score) => {
    if (!score) {
      return (
        <Text fontSize={"md"} fontWeight={"bold"} color={"white"}>
          Culture Success Score not available yet.
        </Text>
      );
    }
    const scoreText = (
      <Text fontSize={"4xl"} fontWeight={"bold"} color={"white"}>
        {score?.toFixed(0)}/100
      </Text>
    );
    if (score < 50) {
      return scoreText;
    } else if (score < 80) {
      return scoreText;
    } else {
      return scoreText;
    }
  };

  if (score === undefined || score === 0 || cultureScoreFactors.length < 7) {
    return (
      <SampleDataWidget
        imageUrl={
          size === "small"
            ? "culture-score-widget.png"
            : "culture-score-widget-large.png"
        }
        text={
          "After a week of utilizing The Best Answer, you’ll receive a Culture success score."
        }
        width={"100%"}
        height={size === "small" ? "300px" : "382px"}
        size={size}
        isClickable={isClickable}
        accountId={accountId}
        borderRadius="md"
        boxShadow="md"
      />
    );
  }
  return (
    <Box
      bg={getCultureScoreColor(score)}
      border={"1px"}
      borderColor={"gray.200"}
      borderRadius={"md"}
      boxShadow={"md"}
      p={6}
      w={"100%"}
      onClick={() => {
        isClickable && navigate(`/${accountId}/culture-score`);
      }}
      cursor={isClickable ? "pointer" : "default"}
    >
      <Center>
        <VStack spacing={4}>
          <Text fontWeight={"700"} fontSize={"md"} color={"white"}>
            Today's Culture Success Score
          </Text>
          {getSmileyFace(score)}
          {getScoreText(score)}
        </VStack>
      </Center>
    </Box>
  );
};
