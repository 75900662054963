import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useFieldArray, useFormContext } from "react-hook-form";
import { MdClose, MdOutlineRadioButtonUnchecked } from "react-icons/md";
import DragIndicator from "../../../../components/common/Draggable/DragIndicator";
import { DraggableItem } from "../../../../components/common/Draggable/DraggableItem";
import { InputField } from "../../../../components/fields/InputField";
import QuestionTypeSelector from "../QuestionTypeSelector";

const RadioQuestion = ({ questionIndex, onChange }) => {
  const { control } = useFormContext();
  const {
    fields: choices,
    append: appendChoice,
    remove: removeChoice,
    move: moveChoice,
  } = useFieldArray({
    control,
    name: "element.choices",
  });

  return (
    <DndProvider backend={HTML5Backend}>
      <VStack w={"100%"} spacing={6}>
        <Stack
          w={"100%"}
          gap={4}
          spacing={0}
          flexDirection={{ base: "column", md: "row" }}
        >
          <InputField
            field={{
              id: `prompt`,
              placeholder: "Provide your question prompt",
              validation: {
                required: { value: true, message: "Prompt is required" },
              },
            }}
          />
          <QuestionTypeSelector
            questionIndex={questionIndex}
            onChange={onChange}
          />
        </Stack>
        {choices.map((choice, index) => (
          <DraggableItem
            key={choice.id}
            id={choice.id}
            index={index}
            moveItem={(dragIndex, hoverIndex) => {
              moveChoice(dragIndex, hoverIndex);
              onChange();
            }}
            setDrag={(drag) => {
              const dragHandle = document.getElementById(
                `drag-handle-${choice.id}`
              );
              if (dragHandle) {
                drag(dragHandle);
              }
            }}
            styleProps={{
              w: "100%",
            }}
          >
            {() => {
              return (
                <HStack key={choice.id} w={"100%"} className="draggableThing">
                  <DragIndicator id={choice.id} width={"15px"} />
                  <Icon as={MdOutlineRadioButtonUnchecked} fontSize={"2xl"} />
                  <InputField
                    field={{
                      id: `element.choices[${index}].label`,
                      placeholder: `Option ${index + 1}`,
                      validation: {
                        required: {
                          value: true,
                          message: "Option is required",
                        },
                      },
                    }}
                    inputStyle={{ variant: "flushed" }}
                  />
                  {choices.length > 1 && (
                    <IconButton
                      icon={<MdClose />}
                      variant="ghost"
                      fontSize={"xl"}
                      onClick={() => {
                        removeChoice(index);
                        onChange();
                      }}
                    />
                  )}
                </HStack>
              );
            }}
          </DraggableItem>
        ))}
        <HStack w={"100%"}>
          <Box w={"15px"} />
          <Icon as={MdOutlineRadioButtonUnchecked} fontSize={"2xl"} />
          <Button
            onClick={() => {
              appendChoice({ label: `Option ${choices.length + 1}` });
              onChange();
            }}
            alignSelf={"flex-start"}
          >
            Add Option
          </Button>
        </HStack>
      </VStack>
    </DndProvider>
  );
};

export default RadioQuestion;
