/* eslint-disable no-case-declarations */
import {
  Box,
  Button,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useUpdateKeyResult } from "../../../api/okr/keyResult";
import { DateField } from "../../../components/fields/DateField";
import { NumberField } from "../../../components/fields/NumberField";
import { SelectField } from "../../../components/fields/SelectField";
import useAccountId from "../../../hooks/customDomainHooks";
import useToastMessage from "../../../hooks/useToastMessage";

const getMetricsLabel = (metrics) => {
  if (!metrics) return "N/A";

  switch (metrics.type) {
    case "PERCENT":
      const cleanedPercentStatus = metrics.status ? metrics.status : 0;
      return `${cleanedPercentStatus}% / ${metrics.goal}%`;
    case "NUMBER":
      const cleanedNumberStatus = metrics.status ? metrics.status : 0;
      return `${cleanedNumberStatus} / ${metrics.goal}`;
    case "DATE":
      const cleanedDateGoal = `Target: ${new Date(
        metrics.goal
      ).toLocaleDateString()}`;
      const cleanedDateStatus = metrics.status
        ? new Date(metrics.status).toLocaleDateString()
        : "N/A";
      if (!metrics.status) return cleanedDateGoal;
      else return `Done on: ${cleanedDateStatus}`;
    case "DONE_NOT_DONE":
      return metrics.status === "DONE" ? "Done" : "Not Done";
    default:
      return "N/A"; // Handle any unknown type or default view here
  }
};

export const KeyResultGoalReadOnly = ({ keyResult }) => {
  return (
    <Button
      variant="ghost"
      cursor={"pointer"}
      _active={{ bg: "none" }}
      p={0}
      m={0}
      color={"primary"}
    >
      {getMetricsLabel(keyResult.metrics)}
    </Button>
  );
};
const KeyResultGoal = ({ objective, keyResult, canEditKeyResult }) => {
  const [isSaving, setIsSaving] = useState(false);

  return (
    <Box>
      <Popover isOpen={canEditKeyResult ? undefined : false}>
        <PopoverTrigger>
          <Button
            isLoading={isSaving}
            variant="ghost"
            _hover={{ textDecoration: canEditKeyResult ? "underline" : "none" }}
            cursor={canEditKeyResult ? "pointer" : "default"}
            _active={{ bg: "none" }}
            p={0}
            m={0}
            color={"primary"}
          >
            {getMetricsLabel(keyResult.metrics)}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
          borderRadius={"10px"}
          w={"250px"}
          p={3}
        >
          <PopoverArrow />
          <PopoverBody>
            <VStack alignItems={"center"} textAlign={"center"} fontWeight={700}>
              <EditKeyResultStatus
                objective={objective}
                keyResult={keyResult}
                setIsSaving={setIsSaving}
              />
              <EditKeyResultGoal keyResult={keyResult} objective={objective} />
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

const EditKeyResultStatus = ({ objective, keyResult, setIsSaving }) => {
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const updateKeyResultMutation = useUpdateKeyResult();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      metrics: keyResult.metrics,
    },
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = form;

  const debouncedSubmit = useCallback(
    debounce((event) => {
      submitForm(event);
    }, 1000),
    [handleSubmit, errors]
  );

  const submitForm = (data) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateKeyResult)(data);
    }
  };

  const shouldProgressBeDone = (data) => {
    const metrics = data.metrics;
    // If metrics.status equals DONE, return true
    // If metics.status equals metrics.goal (for NUMBER and PERCENT), return true
    // If metrics.status is any value and the type is DATE, return true
    if (metrics.status === "DONE") return true;
    if (metrics.type === "NUMBER" && metrics.status >= metrics.goal)
      return true;
    if (metrics.type === "PERCENT" && metrics.status >= metrics.goal)
      return true;
    if (metrics.status && metrics.type === "DATE") return true;
    return false;
  };

  const updateKeyResult = async (data) => {
    try {
      if (shouldProgressBeDone(data)) {
        data.progress = "DONE";
      }
      setIsSaving(true);
      await updateKeyResultMutation.mutateAsync({
        accountId,
        objectiveId: objective.id,
        keyResultId: keyResult.id,
        data: data,
      });
      await queryClient.invalidateQueries(["fetchObjectives", accountId]);
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      console.log(error);
      showErrorToast({ message: "Error updating Key Result" });
    }
  };

  useEffect(() => {
    if (keyResult) {
      reset({
        metrics: keyResult.metrics,
      });
    }
  }, [keyResult, reset]);

  const selectedType = watch("metrics.type");

  return (
    <FormProvider {...form}>
      <form
        onChange={debouncedSubmit}
        // Prevent form submission on enter key
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        <Box>
          {
            {
              NUMBER: <NumberStatusInput />,
              PERCENT: <PercentStatusInput />,
              DATE: <DateStatusInput onChange={submitForm} />,
              DONE_NOT_DONE: <DoneNotDoneStatusInput onChange={submitForm} />,
            }[selectedType]
          }
        </Box>
      </form>
    </FormProvider>
  );
};

const PercentStatusInput = () => {
  return (
    <HStack spacing={0}>
      <NumberField
        field={{
          id: "metrics.status",
          placeholder: "0",
        }}
        inputProps={{
          variant: "unstyled",
          w: "34px",
          p: 0,
        }}
        styleProps={{
          fontWeight: 800,
          padding: 0,
        }}
      />
      <Text fontWeight={800} fontSize={"xl"} color={"gray.300"}>
        %
      </Text>
    </HStack>
  );
};

const NumberStatusInput = () => {
  return (
    <NumberField
      field={{
        id: "metrics.status",
        placeholder: "0",
      }}
      inputProps={{
        variant: "unstyled",
        w: "100%",
        p: 0,
      }}
      styleProps={{
        fontWeight: 800,
        padding: 0,
        textAlign: "center",
      }}
    />
  );
};

const DateStatusInput = ({ onChange }) => {
  return (
    <Box maxW={"200px"} alignSelf={"flex-start"}>
      <DateField
        field={{
          id: `metrics.status`,
          validation: { required: false },
          label: "Done on:",
        }}
        onDateChange={onChange}
      />
    </Box>
  );
};

const DoneNotDoneStatusInput = ({ onChange }) => {
  return (
    <VStack>
      <SelectField
        field={{
          id: "metrics.status",
        }}
        onSelectionChange={onChange}
        options={[
          { value: "DONE", label: "Done" },
          { value: "NOT_DONE", label: "Not Done" },
        ]}
        selectStyles={{
          menu: (provided) => ({ ...provided, w: "100%" }),
        }}
        selectProps={{
          menuPosition: "",
        }}
      />
    </VStack>
  );
};

const EditKeyResultGoal = ({ keyResult, objective }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { accountId } = useAccountId();
  const { showErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const updateKeyResultMutation = useUpdateKeyResult();
  const [isSaving, setIsSaving] = useState(false);

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      metrics: keyResult.metrics,
    },
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const submitForm = (data) => {
    if (Object.keys(errors).length === 0) {
      handleSubmit(updateKeyResult)(data);
    }
  };

  const updateKeyResult = async (data) => {
    try {
      setIsSaving(true);
      await updateKeyResultMutation.mutateAsync({
        accountId,
        objectiveId: objective.id,
        keyResultId: keyResult.id,
        data: data,
      });
      await queryClient.invalidateQueries(["fetchObjectives", accountId]);
      onClose();
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      console.log(error);
      showErrorToast({ message: "Error updating Key Result" });
    }
  };

  // Watch for selected type changes
  const selectedType = watch("metrics.type");

  // Set default goals based on type
  useEffect(() => {
    if (!selectedType) return;
    const typeToDefault = {
      PERCENT: { goal: 100, status: 0 },
      NUMBER: { goal: 100, status: 0 },
      DATE: { goal: "", status: "" },
      DONE_NOT_DONE: { goal: "DONE", status: "NOT_DONE" },
    };
    setValue(`metrics.goal`, typeToDefault[selectedType].goal);
    setValue(`metrics.status`, typeToDefault[selectedType].status);
  }, [setValue, selectedType]);

  const renderGoalField = () => {
    switch (selectedType) {
      case "NUMBER":
        return (
          <NumberField
            field={{
              id: "metrics.goal",
              placeholder: "0",
              label: "Goal",
              validation: { required: true },
            }}
            inputProps={{
              w: "100%",
            }}
            styleProps={{
              height: "54px",
            }}
          />
        );
      case "PERCENT":
        return (
          <HStack spacing={4} alignItems={"center"} w={"100%"}>
            <NumberField
              field={{
                id: "metrics.goal",
                placeholder: "0",
                label: "Goal",
                validation: { required: true },
              }}
              inputProps={{
                w: "100%",
              }}
              styleProps={{
                height: "54px",
              }}
            />
            <Text fontWeight={800} fontSize={"xl"} color={"gray.500"}>
              %
            </Text>
          </HStack>
        );
      case "DATE":
        return (
          <Box maxW={"200px"} alignSelf={"flex-start"}>
            <DateField
              field={{
                id: `metrics.goal`,
                label: "Goal",
                validation: { required: true },
              }}
            />
          </Box>
        );
      case "DONE_NOT_DONE":
        return (
          <Box w={"100%"}>
            <VStack alignItems={"flex-start"}>
              <Text fontWeight={500}>Goal</Text>
              <Text pl={4}>Done</Text>
            </VStack>
          </Box>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (keyResult) {
      reset({
        metrics: keyResult.metrics,
      });
    }
  }, [keyResult]);

  const closeModal = () => {
    onClose();
    reset({
      metrics: keyResult.metrics,
    });
  };

  return (
    <>
      <Link onClick={onOpen} color={"primary"}>
        Edit Goal
      </Link>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <FormProvider {...form}>
            <form
              onSubmit={submitForm}
              // Prevent form submission on enter key
              onKeyDown={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <ModalHeader>Edit Key Result Goal</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack>
                  <SelectField
                    field={{
                      id: "metrics.type",
                      label: "Type",
                    }}
                    options={[
                      { value: "DATE", label: "Date" },
                      { value: "DONE_NOT_DONE", label: "Done / Not Done" },
                      { value: "NUMBER", label: "Number" },
                      { value: "PERCENT", label: "Percent" },
                    ]}
                  />
                  {renderGoalField()}
                </VStack>
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="blue"
                  mr={3}
                  type={"submit"}
                  isLoading={isSaving}
                >
                  Save
                </Button>
                <Button variant="ghost" onClick={closeModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </FormProvider>
        </ModalContent>
      </Modal>
    </>
  );
};

export default KeyResultGoal;
